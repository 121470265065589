import Button from '../../../design-system/atoms/Button.tsx';
import ClickToCopyButton from '../../../design-system/atoms/ClickToCopyButton.tsx';
import { Icon } from 'icons';
import IconButton from '../../../design-system/atoms/IconButton.tsx';
import OutlineButton from '../../../design-system/atoms/OutlineButton.tsx';
import AccessRestrictionPanel from '../../../design-system/atoms/AccessRestrictionPanel.js';
import { ModalPanel } from '../../../design-system/organisms/Modal.tsx';
import { zeckPublishPath } from '../../../services/Paths.ts';
import { Zeck } from '../../../types.ts';
import styles from './PreflightPublish.module.scss';
import SelectViewersButton from '../../../design-system/atoms/SelectViewersButton.tsx';

type ManageViewerPermissionsModalProps = {
  zeck: Zeck;
  canPublish: boolean;
  showAddPeopleModal: (x: boolean) => void;
  closeModal: () => void;
  showConfirmModal: () => void;
};

export default function PreflightPublish({
  zeck,
  canPublish,
  showAddPeopleModal,
  closeModal,
  showConfirmModal,
}: ManageViewerPermissionsModalProps) {
  const zeckHasBeenPublished = !!zeck.publishedAt;
  let publishedZeckUrl = '';
  if (zeck.publishedZeck) {
    publishedZeckUrl = `${window.location.origin}${zeckPublishPath(zeck)}`;
  }

  return (
    <>
      <ModalPanel>
        <div className={styles.preflightPublish__header}>
          <Icon name="publishArrow" className={styles.headerIcon} />
          <span>Publish Site to Viewers</span>
          <IconButton
            name="close"
            aria-label="close publish modal"
            className={styles.headerAction}
            onClick={closeModal}
          />
        </div>
        <AccessRestrictionPanel
          title="Only Viewers will be able to see your Published Site"
          button={
            <SelectViewersButton
              color="secondary"
              onClick={() => showAddPeopleModal(true)}
            />
          }
        />
        {zeckHasBeenPublished && (
          <div className={styles.preflightPublish__copyLinkSection}>
            <span>{publishedZeckUrl}</span>
            <ClickToCopyButton
              copyContent={publishedZeckUrl}
              size="medium"
              color="primary"
              data-testid="copy-published-zeck-link-button"
              className={styles.copyLinkButton}
            >
              Copy Link
            </ClickToCopyButton>
          </div>
        )}
        <div className={styles.preflightPublish__footer}>
          <OutlineButton size="medium" color="secondary" onClick={closeModal}>
            Cancel
          </OutlineButton>
          <Button
            size="medium"
            color="primary"
            onClick={showConfirmModal}
            disabled={!canPublish}
          >
            Publish Now
          </Button>
        </div>
      </ModalPanel>
    </>
  );
}
