import { Icon, IconName } from 'icons';
import React, { useRef } from 'react';
import { cx } from 'styling';
import mergeRefs from '../../junkDrawer/mergeRefs.ts';
import styles from './AddBlockMenuItem.module.scss';

type AddBlockMenuItemProps = {
  iconName: IconName;
  label: string;
  description: string;
  selected: boolean;
  selectedMenuRef?: React.RefObject<HTMLButtonElement>;
};

const AddBlockMenuItem: React.FC<
  AddBlockMenuItemProps & React.HTMLAttributes<HTMLButtonElement>
> = ({
  iconName,
  label,
  description,
  selected,
  selectedMenuRef,
  ...otherProps
}) => {
  const divRef = useRef<HTMLButtonElement>(null);

  return (
    <button
      ref={mergeRefs([divRef, selectedMenuRef])}
      {...otherProps}
      data-testid={`add-block-menu-item-${iconName}`}
      className={cx(styles.addBlockMenuItem, selected ? styles.selected : '')}
    >
      <div className={styles.addBlockMenuItem_image}>
        <Icon name={iconName} />
      </div>
      <div className={styles.addBlockMenuItem_text}>
        <div className={styles.addBlockMenuItem_label}>{label}</div>
        <div className={styles.addBlockMenuItem_description}>{description}</div>
      </div>
    </button>
  );
};

export default AddBlockMenuItem;
