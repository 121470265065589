import { BulletedListItemBlock } from 'editor-content/Block.js';
import React, { useCallback } from 'react';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import useTextBlockKeyboardEvents from '../selection/useTextBlockKeyboardEvents.ts';
import BulletedListItemEditable from './BulletedListItemEditable.tsx';
import EditableProps from './EditableProps.ts';
import { WithHoverNextToSelection } from './HoverNextToSelection.tsx';
import { indentBulletedListItem } from '../BodyEditor/indent.ts';

type BulletedListItemEditableWithKeyboardProps = {
  className?: string;
  onNavUp(): void;
  onNavDown(): void;
  onNavLeft(): void;
  onNavRight(): void;
  onSelectOut(): void;
  onEnter(): void;
  onTab?: (shiftKey: boolean) => void;
  formattingMenu: React.ReactNode;
} & EditableProps<BulletedListItemBlock>;

const BulletedListItemEditableWithKeyboard = React.forwardRef<
  HTMLElement,
  BulletedListItemEditableWithKeyboardProps
>(
  (
    {
      linkables,
      onSelect,
      value,
      onChange,
      selection,
      onNavUp,
      onNavDown,
      onNavLeft,
      onNavRight,
      onEnter,
      onSelectOut,
      formattingMenu,
      ...otherProps
    },
    ref,
  ) => {
    const handleTab = useCallback(
      (shiftKey: boolean) => {
        if (selection) {
          onChange(indentBulletedListItem(value, shiftKey ? -1 : 1), selection);
        }
      },
      [value, onChange, selection],
    );
    const { handleKeyDown, ref: keyboardEventRef } =
      useTextBlockKeyboardEvents<HTMLParagraphElement>({
        onEnter,
        onArrowUpOut() {
          onNavUp();
        },
        onArrowDownOut() {
          onNavDown();
        },
        onArrowLeftOut() {
          onNavLeft();
        },
        onArrowRightOut() {
          onNavRight();
        },
        onSelectOut() {
          onSelectOut();
        },
        onTab: handleTab,
      });

    return (
      <WithHoverNextToSelection
        hoverContent={formattingMenu}
        selection={selection}
      >
        {(selectionRef) => (
          <BulletedListItemEditable
            {...otherProps}
            {...{
              value,
              indent: value.indent,
              onChange,
              selection,
              onSelect,
              linkables,
              onKeyDown: handleKeyDown,
            }}
            ref={mergeRefs([ref, keyboardEventRef, selectionRef])}
          />
        )}
      </WithHoverNextToSelection>
    );
  },
);

export default BulletedListItemEditableWithKeyboard;
