import { v4 } from 'uuid';

type BaseBlock = {
  type: string;
  id: string;
};

export const baseBlock = <T extends Record<string, unknown>, S extends string>(
  type: S,
  id: string,
  attrs: T,
) => ({
  type,
  id,
  ...attrs,
});

export const generateBlockId = () => v4();

export const createBlock = <
  T extends Record<string, unknown>,
  S extends string,
>(
  type: S,
  attrs: T,
) => baseBlock(type, generateBlockId(), attrs);

export default BaseBlock;
