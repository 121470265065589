import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function ResolveIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <g id="material-symbols:check">
        <path
          id="Vector"
          d="M9.5501 17.9996L3.8501 12.2996L5.2751 10.8746L9.5501 15.1496L18.7251 5.97461L20.1501 7.39961L9.5501 17.9996Z"
          fill="#8F90A6"
        />
      </g>
    </svg>
  );
}
