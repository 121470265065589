import Point, { subtractVector } from '../../../domHelpers/Point.js';
import useMouseSelectionDeadzone from './useMouseSelectionDeadzone.js';
import Rect from '../../../domHelpers/Rect.js';
import { SelectionRange } from './useMouseSelection.js';

type RectGetter = () => Rect;

type WrappedHookType = ReturnType<typeof useMouseSelectionDeadzone>;

const useResolveCoordinatesRelativeToDocument =
  (wrappedHook: WrappedHookType) =>
  (
    getOrigin: () => Point,
    rectGetters: RectGetter[],
    onSelect: (selection: SelectionRange | null) => void,
  ) => {
    const resolvedRectGetters = rectGetters.map((getter): RectGetter => {
      return () => {
        const [startPoint, endPoint] = getter();
        const basisOrigin = getOrigin();
        return [
          subtractVector(startPoint, basisOrigin),
          subtractVector(endPoint, basisOrigin),
        ];
      };
    });

    const { selectionStart, selectionMove, selectionEnd, ...otherReturn } =
      wrappedHook(resolvedRectGetters, onSelect);
    return {
      selectionStart: (p: Point, deadzone: Rect | null) =>
        selectionStart(
          subtractVector(p, getOrigin()),
          deadzone && [
            subtractVector(deadzone[0], getOrigin()),
            subtractVector(deadzone[1], getOrigin()),
          ],
        ),
      selectionMove: (p: Point) =>
        selectionMove(subtractVector(p, getOrigin())),
      selectionEnd,
      ...otherReturn,
    };
  };

export default useResolveCoordinatesRelativeToDocument;
