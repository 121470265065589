import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function DoubleChevronLeftIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M11.41 16.59L6.83 12L11.41 7.41L10 6L4 12L10 18L11.41 16.59Z"
        fill="black"
      />
      <path
        d="M19.41 16.59L14.83 12L19.41 7.41L18 6L12 12L18 18L19.41 16.59Z"
        fill="black"
      />
    </svg>
  );
}
