import getIdFromSlug from '../../../../getIdFromSlug.ts';
import { Navigate } from 'react-router-dom';
import PublishedSectionPageView from './PublishedSectionPageView.tsx';
import { BrandKitResource } from '../../../../design-system/zeck/WithBrandKit.tsx';
import { UserAndCompany } from '../../../../userAndCompany/FetchUserAndCompany.tsx';
import useApi from '../../../../api/useApi.ts';
import useFetchInvalidate from '../../../../services/useFetch/useFetchInvalidate.ts';
import { VoteType } from 'editor-content/VoteBlock.js';
import { useRequiredParams } from '../../../../routing/useRequiredParams.ts';
import useRegisterViewEventsOnLoad from './useRegisterViewEventsOnLoad.ts';
import { PrevoteChoice } from '../../../../types/Prevote.ts';
import Vote from '../../../../types/Vote.ts';
import { foldResult, pipe } from '../../../../result/Result.ts';
import useDependentFetchInvalidate from '../../../../services/useFetch/useDependentFetchInvalidate.ts';
import RequestAccessPage from '../../../error/unauthorized/RequestAccessPage.js';
import usePageTracking from '../../../../services/usePageTracking.ts';
import useDocumentTitleFromResult from '../../../../junkDrawer/useDocumentTitleFromResult.ts';

type PublishedSectionPageProps = {
  onClickLogout: () => void;
  userAndCompany: UserAndCompany;
  brandKitResource: BrandKitResource;
};

const PublishedSectionPage = ({
  userAndCompany,
  brandKitResource,
  onClickLogout,
}: PublishedSectionPageProps) => {
  const { slug } = useRequiredParams('slug');
  const {
    fetchPublishedZeckFromSection,
    createVote,
    createPrevote,
    deletePrevote,
    getZeckPrevoteData,
  } = useApi();
  const id = getIdFromSlug(slug);

  const { result: publishedZeckResult, invalidate: invalidatePublishedZeck } =
    useFetchInvalidate(async () => fetchPublishedZeckFromSection(id), []);

  useRegisterViewEventsOnLoad({
    publishedZeckResult,
    userId: userAndCompany.user.id,
    companyId: userAndCompany.activeCompany.id,
    role: userAndCompany.activeCompany.role,
    boardDirector: userAndCompany.activeCompany.boardDirector,
  });
  usePageTracking('published_section', userAndCompany);
  useDocumentTitleFromResult(publishedZeckResult);

  const { result: prevoteDataResult, invalidate: invalidatePrevoteData } =
    useDependentFetchInvalidate(publishedZeckResult, (publishedZeck) =>
      getZeckPrevoteData(publishedZeck.zeckId),
    );

  switch (publishedZeckResult.type) {
    case 'loading':
      return null;
    case 'error':
      return (
        <RequestAccessPage
          type="section"
          requestedId={id}
          email={userAndCompany.user.email}
          onClickLogout={onClickLogout}
        />
      );
    case 'success':
  }

  const { data: publishedZeck } = publishedZeckResult;

  const section = publishedZeck.sections.find(
    ({ sectionId }) => sectionId === id,
  );

  if (!section) {
    return <Navigate to={`/company/${userAndCompany.activeCompany.id}`} />;
  }

  const prevoteData = pipe(
    prevoteDataResult,
    foldResult({
      success: (prevoteData) => prevoteData,
      loading: () => ({
        results: [],
        tallies: [],
        boardDirectorCount: 0,
        currentUserPrevotes: [],
        prevotedBlockIds: [],
      }),
      error: () => ({
        results: [],
        tallies: [],
        boardDirectorCount: 0,
        currentUserPrevotes: [],
        prevotedBlockIds: [],
      }),
    }),
  );

  const takeVote = async (
    blockVoteData: Pick<
      Vote,
      'title' | 'details' | 'approved' | 'voteType' | 'blockId' | 'sectionId'
    >,
  ) => {
    await invalidatePublishedZeck(async () => {
      await createVote({
        ...blockVoteData,
        zeckId: publishedZeck.zeckId,
        voteType: blockVoteData.voteType || VoteType.None,
      });
    });
  };

  const takePrevote = async ({
    blockId,
    choice,
    sectionId,
  }: {
    blockId: string;
    choice: PrevoteChoice;
    sectionId: string;
  }) => {
    await invalidatePrevoteData(async () => {
      await createPrevote({
        sectionId,
        blockId,
        choice,
      });
    });
  };

  const removePrevote = async (id: string) => {
    await invalidatePrevoteData(async () => {
      await deletePrevote(id);
    });
  };

  return (
    <PublishedSectionPageView
      brandKitResource={brandKitResource}
      zeck={publishedZeck}
      section={section}
      userAndCompany={userAndCompany}
      takeVote={takeVote}
      takePrevote={takePrevote}
      removePrevote={removePrevote}
      prevoteData={prevoteData}
    />
  );
};

export default PublishedSectionPage;
