import { AnimatePresence, motion } from 'framer-motion';
import HoversNextToBlocks from './HoversNextToBlocks.js';
import styles from './BlockHoverControls.module.scss';
import AddBlockButton from './AddBlockButton.js';
import { BlockDndHandle } from '../dragBlock/DragBlockExperience.js';
import React from 'react';
import { BlockDnd } from '../dragBlock/useDragBlock.js';
import { AddBlockBehavior } from './AddBlockExperience.js';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';

const BlockHoverControls = <BlockType extends { id: string }>({
  blocksWithEl,
  leftGutterRef,
  getEditorContainer,
  blockdnd,
  addBlockBehavior,
}: {
  blocksWithEl: ElementAndData<BlockType>[];
  getEditorContainer: () => HTMLElement | null;
  leftGutterRef: React.RefObject<HTMLElement>;
  blockdnd: Pick<BlockDnd, 'isDragging' | 'onDragStart'>;
  addBlockBehavior: AddBlockBehavior<BlockType>;
}) => {
  return (
    <AnimatePresence>
      {!blockdnd.isDragging && addBlockBehavior.showHoverButton && (
        <motion.div
          key="hovering"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.2 } }}
          exit={{ opacity: 0, transition: { duration: 0.1 } }}
        >
          <HoversNextToBlocks
            getEditorContainer={getEditorContainer}
            blocksWithEl={blocksWithEl}
            leftGutterRef={leftGutterRef}
          >
            {(hoveredIndex) => (
              <div className={styles.blockHoverActions}>
                <AddBlockButton
                  onClick={() => addBlockBehavior.onClickAddBlock(hoveredIndex)}
                />
                <BlockDndHandle
                  hoveredIndex={hoveredIndex}
                  blockdnd={blockdnd}
                />
              </div>
            )}
          </HoversNextToBlocks>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BlockHoverControls;
