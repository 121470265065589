import { Icon } from 'icons';
import styles from './RoleDropdown.module.scss';
import useBreakpoints from '../../services/useBreakpoints.ts';
import cx from 'classnames';
import { CompanyRole } from '../../types.ts';
import { PeopleListBoardDirectorToggle } from './PeopleListBoardDirectorToggle.tsx';

export type RoleOptionsProps = {
  onClose: () => void;
  onSelect: (role: CompanyRole) => void;
  onDelete: () => void;
  onResendInvitation: (() => void) | null;
  selectedRole: CompanyRole;
  disableRoleChange: boolean;
  boardDirectorConfig?: {
    onUpdateBoardDirector: (boardDirector: boolean) => void;
    isBoardDirector: boolean;
  };
};

export default function RoleOptions({
  onClose,
  onSelect,
  onDelete,
  onResendInvitation,
  disableRoleChange,
  boardDirectorConfig,
  selectedRole,
}: RoleOptionsProps) {
  const { isMobile } = useBreakpoints();

  return (
    <div
      className={cx(styles.roleOptions, isMobile && styles.roleOptions_mobile)}
      data-testid="roles"
    >
      <button
        className={styles.roleOption}
        onClick={() => {
          onSelect('admin');
          onClose();
        }}
        disabled={disableRoleChange}
        data-testid="role-option-admin"
      >
        <div className={styles.roleOptionName}>
          Admin
          {selectedRole === 'admin' && (
            <span data-testid="admin-selected">
              <Icon name="checkmark" />
            </span>
          )}
        </div>
        <div className={styles.roleOptionDescription}>
          Allow control of all Zeck content permissions, invite new members,
          grant others admin access, edit Brand Kit
        </div>
      </button>
      <button
        className={styles.roleOption}
        onClick={() => {
          onSelect('member');
          onClose();
        }}
        disabled={disableRoleChange}
        data-testid="role-option-member"
      >
        <div className={styles.roleOptionName}>
          Member
          {selectedRole === 'member' && (
            <span data-testid="member-selected">
              <Icon name="checkmark" />
            </span>
          )}
        </div>
        <div className={styles.roleOptionDescription}>
          Cannot access content permissions, invite members, change admin
          access, or edit Brand Kit
        </div>
      </button>
      <button
        className={styles.roleOption}
        onClick={() => {
          onSelect('legal_admin');
          onClose();
        }}
        disabled={disableRoleChange}
        data-testid="role-option-legal-admin"
      >
        <div className={styles.roleOptionName}>
          Legal Admin
          {selectedRole === 'legal_admin' && (
            <span data-testid="legal_admin-selected">
              <Icon name="checkmark" />
            </span>
          )}
        </div>
        <div className={styles.roleOptionDescription}>
          Allowed to edit votes and meeting minutes. Cannot invite members or
          change access.
        </div>
      </button>
      {boardDirectorConfig && (
        <button
          className={cx(styles.roleOption, styles.roleOption__boardDirector)}
          onClick={() => {
            boardDirectorConfig.onUpdateBoardDirector(
              !boardDirectorConfig.isBoardDirector,
            );
          }}
        >
          <div className={styles.roleOptionName}>
            Board Director
            <PeopleListBoardDirectorToggle
              isBoardDirector={boardDirectorConfig.isBoardDirector}
              onChange={boardDirectorConfig.onUpdateBoardDirector}
            />
          </div>
          <div className={styles.roleOptionDescription}>
            Board Directors can cast pre-votes on votes included in published
            Zecks.
          </div>
        </button>
      )}
      <div className={styles.roleOption__footer}>
        {onResendInvitation && (
          <button
            className={cx(styles.roleOption, styles.roleOptionName)}
            onClick={() => {
              onResendInvitation();
              onClose();
            }}
          >
            Resend Invitation
          </button>
        )}
        <button
          className={cx(styles.roleOption, styles.removeUser)}
          onClick={() => {
            onDelete();
            onClose();
          }}
          disabled={disableRoleChange}
        >
          Remove User
        </button>
      </div>
    </div>
  );
}
