import ContentSelection, {
  getEndOfSelection,
  getStartOfSelection,
} from '../../selection/contentSelection/ContentSelection.js';
import arrayIsNotEmpty from '../../../junkDrawer/arrayIsNotEmpty.js';
import {
  Editor,
  getTextFromNodes,
  NonHighlightTextNode,
  TextNode,
} from 'editor-content/TextNode.js';

function tryToSplitText(
  textNode: Editor.Text,
  offset: number,
): [Editor.Text, Editor.Text] | number {
  const string = textNode.text;

  // split should be within this text
  if (offset < string.length) {
    const before = string.slice(0, offset);
    const after = string.slice(offset);
    return [
      { ...textNode, text: before },
      { ...textNode, text: after },
    ];
  }

  return string.length;
}

function tryToSplitLink<
  T extends
    | Editor.ExternalLink
    | Editor.SectionLink
    | Editor.MeetingMinutesLink,
>(textNode: T, offset: number): [T, T] | number {
  const [nodeContentBefore, nodeContentAfter] = splitTextNodes(
    textNode.content,
    offset,
  );

  // if split is in this node, don't need to call split
  if (nodeContentBefore.length > 0 && nodeContentAfter.length > 0) {
    return [
      {
        ...textNode,
        content: nodeContentBefore,
      },
      {
        ...textNode,
        content: nodeContentAfter,
      },
    ];
  }

  // split is after this node, call split
  return getTextFromNodes(textNode.content).length;
}

function tryToSplitHighlight(
  textNode: Editor.Highlight,
  offset: number,
): [Editor.Highlight, Editor.Highlight] | number {
  const [nodeContentBefore, nodeContentAfter] = splitTextNodes(
    textNode.content,
    offset,
  );

  // if split is in this node, don't need to call split
  if (nodeContentBefore.length > 0 && nodeContentAfter.length > 0) {
    return [
      {
        ...textNode,
        content: nodeContentBefore,
      },
      {
        ...textNode,
        content: nodeContentAfter,
      },
    ];
  }

  // split is after this node, call split
  return getTextFromNodes(textNode.content).length;
}

export function splitTextNodes(
  textNodes: Editor.Text[],
  offset: number,
): [Editor.Text[], Editor.Text[]];
export function splitTextNodes(
  textNodes: NonHighlightTextNode[],
  offset: number,
): [NonHighlightTextNode[], NonHighlightTextNode[]];
export function splitTextNodes(
  textNodes: TextNode[],
  offset: number,
): [TextNode[], TextNode[]];
export function splitTextNodes(
  textNodes: TextNode[],
  offset: number,
): [TextNode[], TextNode[]] {
  // no nodes, no split
  if (!arrayIsNotEmpty(textNodes)) {
    return [[], []];
  }

  // split is before this node, don't need to split anything
  if (offset < 1) {
    return [[], textNodes];
  }

  const [textNode, ...restNodes] = textNodes;

  let lengthOfNode: number;

  switch (textNode.type) {
    case 'text': {
      const numOrSplit = tryToSplitText(textNode, offset);
      if (Array.isArray(numOrSplit)) {
        // we got em boys
        // stop advancing
        return [[numOrSplit[0]], [numOrSplit[1], ...restNodes]];
      }
      lengthOfNode = numOrSplit;
      break;
    }
    case 'external-link':
    case 'meeting-minutes-link':
    case 'section-link': {
      const numOrSplit = tryToSplitLink(textNode, offset);
      if (Array.isArray(numOrSplit)) {
        // we got em boys
        // stop advancing
        return [[numOrSplit[0]], [numOrSplit[1], ...restNodes]];
      }
      lengthOfNode = numOrSplit;
      break;
    }
    case 'highlight': {
      const numOrSplit = tryToSplitHighlight(textNode, offset);
      if (Array.isArray(numOrSplit)) {
        // we got em boys
        // stop advancing
        return [[numOrSplit[0]], [numOrSplit[1], ...restNodes]];
      }
      lengthOfNode = numOrSplit;
      break;
    }
  }

  const [before, after] = splitTextNodes(restNodes, offset - lengthOfNode);

  return [[textNode, ...before], after];
}

const splitTextNodesFromContentSelection = (
  textNodes: TextNode[],
  offsets: ContentSelection,
): [TextNode[], TextNode[], TextNode[]] => {
  const startOffset = getStartOfSelection(offsets);
  const endOffset = getEndOfSelection(offsets);

  const [before, rest] = splitTextNodes(textNodes, startOffset);
  const [middle, after] = splitTextNodes(rest, endOffset - startOffset);

  return [before, middle, after];
};

export default splitTextNodesFromContentSelection;
