import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function RightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <g clipPath="url(#clip0_97_0)">
        <path
          d="M8.59003 16.59L13.17 12L8.59003 7.41L10 6L16 12L10 18L8.59003 16.59Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_97_0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
