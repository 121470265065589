import {
  DndContext,
  DragOverlay,
  MouseSensor,
  pointerWithin,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useState } from 'react';
import { isSuccessResult } from '../../result/Result.js';
import { Result } from '../../services/useFetch/useFetch.ts';
import { ZeckFolderWithCount } from '../../types/ZeckFolder.ts';
import { ZeckLite } from '../../types/ZeckLite.ts';
import { ZeckListItem } from './ZeckList.tsx';
import ZeckCardDragOverlay from './card/ZeckCardDragOverlay.tsx';

// DndKit doesn't let us set null ids, so we use 'home' to represent dropping in the home folder
const HOME_DROPPABLE_ID = 'home';
export const useHomeFolderDroppable = () =>
  useDroppable({
    id: HOME_DROPPABLE_ID,
  });

export const useFolderDroppable = (folderId: string) =>
  useDroppable({
    id: folderId,
  });

type DragState =
  | {
      type: 'not-dragging';
    }
  | {
      type: 'dragging';
      data: ZeckLite;
    };

type WithFolderDragAndDropProps = {
  zecksResult: Result<ZeckLite[]>;
  foldersResult: Result<ZeckFolderWithCount[]>;
  onMoveZeck: (zeckId: string, folderId: string | null) => Promise<void>;
  children: React.ReactNode;
};
export const WithFolderDragAndDrop: React.FC<WithFolderDragAndDropProps> = ({
  zecksResult,
  foldersResult,
  onMoveZeck,
  children,
}) => {
  const [dragState, setDragState] = useState<DragState>({
    type: 'not-dragging',
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      autoScroll={false}
      collisionDetection={pointerWithin}
      onDragStart={(e) => {
        if (!isSuccessResult(zecksResult)) return;
        const draggedZeck = zecksResult.data.find(
          (zeck) => e.active.id === zeck.id,
        );
        if (!draggedZeck) return;
        setDragState({ type: 'dragging', data: draggedZeck });
      }}
      onDragEnd={(e) => {
        setDragState({ type: 'not-dragging' });
        if (!isSuccessResult(zecksResult) || !isSuccessResult(foldersResult))
          return;

        const active = e.active;
        const over = e.over;
        if (!(active && over)) return;

        const draggedZeck = zecksResult.data.find(
          (zeck) => active.id === zeck.id,
        );

        const droppedFolder =
          over.id === HOME_DROPPABLE_ID
            ? { id: null }
            : foldersResult.data.find((folder) => over.id === folder.id);

        if (!draggedZeck || !droppedFolder) return;

        onMoveZeck(draggedZeck.id, droppedFolder.id);
      }}
    >
      <DragOverlay>
        {dragState.type === 'dragging' && (
          <div style={{ pointerEvents: 'none', opacity: 0.5 }}>
            <ZeckCardDragOverlay zeck={dragState.data} />
          </div>
        )}
      </DragOverlay>
      {children}
    </DndContext>
  );
};

export const DraggableZeckListItem = ({
  id,
  children,
}: {
  id: string;
  children?: React.ReactNode;
}) => {
  const { listeners, setNodeRef, isDragging } = useDraggable({
    id,
  });

  const style = {
    ...(isDragging && {
      pointerEvents: 'none' as const,
      opacity: 0,
    }),
  };

  // note, we choose not to set attributes from useDraggable on the li because it only contains accessibility features
  // for use with the keyboard sensor. If we use KeyboardSensor, we'll have to bring them back
  // In the meantime, they interfere with selectors in e2e tests and make the tab navigation more confusing
  return (
    <ZeckListItem
      {...{
        ref: setNodeRef,
        ...listeners,
        style,
      }}
    >
      {children}
    </ZeckListItem>
  );
};
