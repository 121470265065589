import React from 'react';
import styles from './StatusIndicator.module.scss';
import { Icon, IconName } from 'icons';

type StatusIndicatorProps = {
  iconName: IconName;
  children: React.ReactNode;
};

const StatusIndicator = React.forwardRef<HTMLDivElement, StatusIndicatorProps>(
  function StatusIndicator({ iconName, children }, ref) {
    return (
      <div className={styles.statusIndicator} ref={ref}>
        <Icon name={iconName} />
        <span className={styles.statusIndicator__text}>{children}</span>
      </div>
    );
  },
);

export default StatusIndicator;
