import { ExportIcon } from 'icons';
import Modal, {
  ModalBody,
  ModalHeader,
} from '../../../design-system/organisms/Modal.tsx';
import { Signal } from '../../../signals/index.ts';
import { useEffect, useState } from 'react';
import Spinner from '../../../design-system/atoms/Spinner.tsx';
import { PublishedZeck } from '../../../types.ts';
import styles from './ExportPdfModal.module.scss';
import linkButtonStyles from '../../../design-system/atoms/LinkButton.module.scss';
import slugify from '@sindresorhus/slugify';
import cx from 'classnames';

export type Pdf = {
  zeck: PublishedZeck;
  url: Promise<string>;
};

type Props = {
  pdf$: Signal<Pdf | null>;
};

export default function ExportPdfModal({ pdf$ }: Props) {
  const pdf = pdf$.useValue();
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    setUrl(null);

    async function run() {
      if (pdf) {
        const url = await pdf.url;

        let ready = false;
        while (!ready) {
          await sleep(1000);
          const response = await fetch(url, { credentials: 'omit' });
          ready = response.ok; // 200 class response. TODO handle other non-404 responses with error message
        }

        setUrl(url);
      }
    }
    run();
  }, [pdf]);

  if (!pdf) {
    return null;
  }

  return (
    <Modal isOpen={Boolean(pdf)} className={styles.modal}>
      <ModalHeader
        className={styles.header}
        onClickClose={() => {
          pdf$.set(null);
        }}
      >
        <ExportIcon /> Export to PDF
      </ModalHeader>
      <ModalBody className={styles.body}>
        {url ? (
          <>
            <p className={styles.pdfTitle}>
              {slugify(pdf.zeck.name)}.pdf is ready.
            </p>
            <a
              href={url}
              className={cx(
                linkButtonStyles.linkButton,
                linkButtonStyles.linkButton_medium,
                styles.pdfLink,
              )}
            >
              Download Pdf
            </a>
          </>
        ) : (
          <>
            <Spinner />
            <p className={styles.spinnerTitle}>Generating PDF</p>
            <p className={styles.spinnerDescription}>This may take a minute</p>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

async function sleep(time: number) {
  await new Promise((resolve) => setTimeout(resolve, time));
}
