import { useParams } from 'react-router-dom';

export function useRequiredParams<ParamNames extends Array<string>>(
  ...paramNames: ParamNames
): { [ParamName in ParamNames[number]]: string } {
  const params = useParams();
  for (const paramName of paramNames) {
    if (!(paramName in params)) {
      throw new Error(`Missing routing param: ${paramName}`);
    }
  }
  return params as { [ParamName in ParamNames[number]]: string };
}
