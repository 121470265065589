import { CartaCapTableBlock } from 'editor-content/CartaBlock.ts';
import { TableBlockCellFormat } from 'editor-content/TableBlock.ts';
import {
  FormattedTableCell,
  FormattedTableData,
} from '../../table/formattedTableData.ts';

const baseFormat: TableBlockCellFormat = {
  alignHorizontal: 'right',
  alignVertical: 'top',
  wrap: 'wrap',
};

const leftFormat: TableBlockCellFormat = {
  ...baseFormat,
  alignHorizontal: 'left',
};

const bgFormat = {
  ...baseFormat,
  bgColor: '#F2F2F5',
};

const headerRowCells: FormattedTableCell[] = [
  '',
  'Principal',
  'Interest',
  'Outstanding Debt',
  'Amount Raised',
].map((value) => ({
  content: value,
  format: { ...baseFormat, border: { bottom: 'thin' } },
}));

const formattedConvertibleTableData = (
  cartaBlock: CartaCapTableBlock,
): FormattedTableData => {
  const convertibleRows = cartaBlock.data.capTable.convertibles.map(
    (convertible) => {
      const cells: FormattedTableCell[] = [
        { content: convertible.name, format: leftFormat },
        { content: convertible.principal, format: baseFormat },
        { content: convertible.interest, format: baseFormat },
        { content: convertible.outstandingDebt, format: baseFormat },
        { content: convertible.amountRaised, format: baseFormat },
      ];

      return { cells };
    },
  );

  const totalRowCells: FormattedTableCell[] = [
    { content: 'Total', format: { ...leftFormat, bgColor: '#F2F2F5' } },
    {
      content: cartaBlock.data.capTable.convertibleTotalPrincipal,
      format: bgFormat,
    },
    {
      content: cartaBlock.data.capTable.convertibleTotalInterest,
      format: bgFormat,
    },
    {
      content: cartaBlock.data.capTable.convertibleTotalOutstandingDebt,
      format: bgFormat,
    },
    {
      content: cartaBlock.data.capTable.convertibleTotalAmountRaised,
      format: bgFormat,
    },
  ];

  return {
    rows: [
      { cells: headerRowCells },
      ...convertibleRows,
      {
        cells: totalRowCells,
      },
    ],
  };
};

export default formattedConvertibleTableData;
