import { useState } from 'react';
import ResetPasswordRequestSent from './ResetPasswordRequestSent.tsx';
import ResetPasswordRequestForm from './ResetPasswordRequestForm.tsx';
import useApi from '../../api/useApi.ts';

const ResetPasswordRequestPage = () => {
  const { sendResetPasswordRequest } = useApi();

  const [emailSent, setEmailSent] = useState(false);

  const handleResetPasswordRequest = async (email: string) => {
    await sendResetPasswordRequest(email);
    setEmailSent(true);
  };

  if (emailSent) {
    return <ResetPasswordRequestSent />;
  }

  return (
    <ResetPasswordRequestForm
      onResetPasswordRequest={handleResetPasswordRequest}
    />
  );
};

export default ResetPasswordRequestPage;
