const getSelectionRangeSafely = (): Range | null => {
  const selection = window.getSelection();
  if (selection && selection.rangeCount > 0) {
    return selection.getRangeAt(0);
  }

  return null;
};

export default getSelectionRangeSafely;
