import { useCallback, useEffect, useRef, useState } from 'react';
import useZeckEditor from './useZeckEditor.ts';
import { Block } from 'editor-content/Block.js';
import styles from './ZeckEditable.module.scss';
import zeckStyles from '../../../design-system/zeck/ZeckStyles.module.scss';
import EditAppearanceButton from '../edit/EditAppearanceButton.tsx';
import { EditorContent } from '../edit/useEditorState.tsx';
import ZeckEditorSelection from './ZeckEditorSelection.ts';
import useElementAndDataArray from '../../../junkDrawer/useElementAndDataArray.ts';
import useMouseSelectionOnEditor from '../../../editor/selection/useMouseSelectionOnEditor.ts';
import mergeRefs from '../../../junkDrawer/mergeRefs.ts';
import SelectionCommentsExperience from './comments/components/SelectionCommentsExperience.js';
import BlockFormattingMenu from './BlockFormattingMenu.js';
import useHighlightHover from './comments/components/useHighlightHover.js';
import DetectsOutsideEditorClick from '../../../editor/domFacing/components/DetectsOutsideEditorClick.js';
import SelectionCommentWithActions from './comments/SelectionCommentWithActions.js';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import { Editor } from 'editor-content/TextNode.js';
import EditorLayout from '../../../design-system/zeck/EditorLayout.tsx';
import useSetBrowserSelectionWhenNull from '../../../editor/useSetBrowserSelectionWhenNull.ts';
import {
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
} from '../voting/VoteCapability.ts';
import ResetPrevotesExperience from './ResetPrevotesExperience.js';
import { IntegrationListItemData } from '../../../api/endpoints/createIntegrationApi.ts';
import Linkable from 'editor-content/html/Linkable.ts';
import { Company, CompanyLite, User } from '../../../types.ts';
import CopyPasteExperience from './CopyPasteExperience.js';
import AiControls from '../edit/AiControls/AiControls.tsx';
import {
  AddingBlockExperienceZeck,
  useAddBlockZeck,
} from '../../../editor/addBlock/zeck/AddBlockExperienceZeck.js';
import useFancyNav from './FancyNav/useFancyNav.js';
import useSelectionComments from './comments/SelectionCommentsBehavior.js';
import useKeepSelectionInView from '../scrolling/useKeepSelectionInView.js';
import useExclusiveBlockSelection from './selection/useExclusiveBlockSelection.js';
import cx from 'classnames';
import useDragBlock from '../../../editor/dragBlock/useDragBlock.js';
import {
  BlockDndCaret,
  BlockDndOverlay,
} from '../../../editor/dragBlock/DragBlockExperience.js';
import BlockHoverControls from '../../../editor/addBlock/BlockHoverControls.js';
import useUndoRedoOnEditor from './undoRedo/useUndoRedoOnEditor.js';
import editorKeyboardHandler from './editorKeyboardHandler.js';
import EditorScrollContainer from '../edit/EditorScrollContainer.js';
import { LinkTooltipBehavior } from '../links/LinkTooltips/useLinkTooltips.js';
import ZeckMultiblockFormattingExperience from '../../../editor/domFacing/components/ZeckMultiblockFormattingExperience.js';
import { createAiContext } from './AiChartFlow/createAiContext.ts';
import ZeckEditableContent from './ZeckEditableContent.tsx';
import BlockCommentsExperience from './comments/components/BlockCommentsExperience.js';

type ZeckEditableProps = {
  editorContent: EditorContent;
  setEditorContent(newContent: EditorContent): void;
  subscribeToEditTitle: (callback: () => void) => () => void;
  onEditAppearance(): void;
  integrationData: IntegrationListItemData;
  selectionComments: SelectionCommentWithActions[];
  zeckId: string;
  sectionId: string;
  company: Company | CompanyLite;
  user: User;
  createSelectionComment: (
    highlightId: string,
    content: CommentContentNode[],
    selection: Block | Editor.Highlight,
  ) => Promise<void>;
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
  prevotedBlockIds: string[];
  linkables: Linkable[];
  linkTooltipBehavior: Pick<LinkTooltipBehavior, 'onScroll'>;
};

const ZeckEditable = ({
  editorContent,
  setEditorContent,
  subscribeToEditTitle,
  onEditAppearance,
  selectionComments,
  user,
  company,
  zeckId,
  sectionId,
  integrationData,
  createSelectionComment,
  zeckFinalizeVoteCapability,
  zeckPrevoteCapability,
  prevotedBlockIds,
  linkables,
  linkTooltipBehavior,
}: ZeckEditableProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const getScrollContainer = useCallback(
    () => scrollContainerRef.current || null,
    [],
  );

  const [editorSelection, setEditorSelection] =
    useState<ZeckEditorSelection>(null);

  useEffect(() => {
    return subscribeToEditTitle(() => {
      setEditorSelection({
        target: 'title',
        anchorOffset: 0,
        focusOffset: editorContent.title.length,
      });
    });
  }, [subscribeToEditTitle, editorContent.title]);

  const { value, setValue } = useUndoRedoOnEditor({
    editorContent,
    editorSelection,
    setEditorContent,
    setEditorSelection,
  });

  const { bodyContent, bodySelection, ...editor } = useZeckEditor(
    value,
    setValue,
  );

  const documentRef = useRef<HTMLDivElement>(null);

  const blocksWithEl = useElementAndDataArray(bodyContent);

  const mouseSelectionBehavior = useMouseSelectionOnEditor(
    documentRef,
    blocksWithEl,
    editor.selectBody,
  );

  const fancyNavBehavior = useFancyNav({
    blocksWithEl,
    bodySelection,
    onSelectTitle: editor.selectTitle,
    onSelectHeadline: editor.selectHeadline,
    onSelectBody: editor.selectBody,
    onNavLeft: editor.navLeft,
    onNavRight: editor.navRight,
  });

  const selectionCommentsBehavior = useSelectionComments({
    editorState: value,
    setEditorState: setValue,
    selectionComments,
    createSelectionComment,
  });

  const blockdndBehavior = useDragBlock(
    blocksWithEl,
    getScrollContainer,
    editor.dropDraggedBlock,
    editor.loseFocus,
  );

  useHighlightHover();

  useSetBrowserSelectionWhenNull(() => documentRef.current, value.selection);

  useKeepSelectionInView(
    blocksWithEl,
    bodySelection,
    mouseSelectionBehavior.isSelecting,
    getScrollContainer,
  );

  const editorEventListenerRef = useExclusiveBlockSelection(bodySelection);

  const leftGutterRef = useRef<HTMLDivElement>(null);
  const addBlockBehavior = useAddBlockZeck({
    blocksWithEl: blocksWithEl,
    leftGutterRef: leftGutterRef,
    company: company,
    integrationData: integrationData,
    onAddNewBlock: editor.AddBlock.addNewBlock,
    onReplaceNewBlock: editor.AddBlock.replaceNewBlock,
    onConfigureComplexBlock: editor.loseFocus,
    onForwardSlash: editor.pressForwardSlash,
  });

  const aiContext = createAiContext({
    company,
    sectionId,
    userId: user.id,
  });

  return (
    <EditorScrollContainer
      sectionId={sectionId}
      disableScroll={addBlockBehavior.disableScroll}
      onScroll={linkTooltipBehavior.onScroll}
      ref={scrollContainerRef}
    >
      <DetectsOutsideEditorClick<HTMLDivElement>
        onOutsideClick={editor.loseFocus}
      >
        {(documentContainerRef) => (
          <EditorLayout
            className={cx(zeckStyles.zeck, styles.zeckEditable)}
            onClickGutter={editor.loseFocus}
            leftGutterRef={leftGutterRef}
            ref={mergeRefs([
              documentContainerRef,
              documentRef,
              editorEventListenerRef,
            ])}
            {...{
              tabIndex: 0,
              onKeyDown: editorKeyboardHandler(editor),
              onMouseDown: mouseSelectionBehavior.onMouseDown,
              onMouseMove: mouseSelectionBehavior.onMouseMove,
              onMouseUp: mouseSelectionBehavior.onMouseUp,
            }}
          >
            <SelectionCommentsExperience
              documentRef={documentRef}
              companyId={company.id}
              zeckId={zeckId}
              sectionId={sectionId}
              selectionCommentsBehavior={selectionCommentsBehavior}
              user={user}
            />
            <BlockFormattingMenu
              blocksWithEl={blocksWithEl}
              selection={editorSelection}
              company={company}
              onSetImageWidth={editor.setImageWidth}
              onSetImageAlign={editor.setImageAlign}
              onReplaceImage={editor.replaceImage}
              onReplaceTable={editor.replaceTable}
              onDeleteImage={editor.pressBackspace}
              onReplaceFile={editor.replaceFile}
              onDeleteFile={editor.pressBackspace}
              selectionComments={selectionComments}
              zeckId={zeckId}
              sectionId={sectionId}
              onAddSelectionComment={
                selectionCommentsBehavior.addCommentToThread
              }
              user={user}
              integrationData={integrationData}
              aiContext={aiContext}
            />
            <ZeckMultiblockFormattingExperience
              editorState={{ content: bodyContent, selection: bodySelection }}
              blocksWithEl={blocksWithEl}
              onIndent={editor.indent}
              onTurnInto={editor.turnInto}
            />
            <div className={styles.zeckEditable__editAppearanceButtonContainer}>
              <EditAppearanceButton
                onClick={onEditAppearance}
                className={styles.zeckEditable__editAppearanceButton}
              />
            </div>
            <ResetPrevotesExperience
              onEditBlock={editor.editBlock}
              prevotedBlockIds={prevotedBlockIds}
            >
              {(onEditBlock) => (
                <ZeckEditableContent
                  editor={{
                    ...editor,
                    editBlock: onEditBlock,
                    selectBody: (bodySelection) => {
                      if (mouseSelectionBehavior.isSelecting) return true;
                      return editor.selectBody(bodySelection);
                    },
                  }}
                  editorContent={editorContent}
                  editorSelection={editorSelection}
                  setBlockEl={(blockIndex) => (el) =>
                    blocksWithEl[blockIndex]?.setEl(el)
                  }
                  fancyNavBehavior={fancyNavBehavior}
                  selectionCommentsBehavior={selectionCommentsBehavior}
                  linkables={linkables}
                  zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
                  zeckPrevoteCapability={zeckPrevoteCapability}
                  aiContext={aiContext}
                  renderBlockControls={(block, blockIndex) => (
                    <>
                      <BlockDndCaret
                        blockdnd={blockdndBehavior}
                        currentIndex={blockIndex}
                      />
                      <span
                        className={
                          styles.zeckEditable__blockCommentButtonWrapper
                        }
                      >
                        <BlockCommentsExperience
                          {...{
                            block,
                            user,
                            getEl: () => blocksWithEl[blockIndex]?.getEl(),
                            selectionComments,
                            companyId: company.id,
                            zeckId,
                            sectionId,
                            onAddSelectionComment:
                              selectionCommentsBehavior.addCommentToThread,
                          }}
                        />
                      </span>
                    </>
                  )}
                />
              )}
            </ResetPrevotesExperience>

            <BlockDndCaret
              blockdnd={blockdndBehavior}
              currentIndex={blocksWithEl.length}
            />
            <div
              data-testid="add-block-end-click-area"
              style={{ height: 400 }}
              onClick={editor.addBlockAtEnd}
            />

            <CopyPasteExperience
              {...{
                onPasteBlocks: editor.pasteBlocks,
                onPasteText: editor.pasteText,
                onPastePlaintext: editor.pastePlaintext,
                onPasteImage: editor.pasteImage,
                onCut: editor.cut,
                onCopy: editor.copy,
                linkables,
                companyId: company.id,
              }}
            />
            <BlockHoverControls
              getEditorContainer={getScrollContainer}
              blocksWithEl={blocksWithEl}
              leftGutterRef={leftGutterRef}
              blockdnd={blockdndBehavior}
              addBlockBehavior={addBlockBehavior}
            />
            <AddingBlockExperienceZeck addBlockBehavior={addBlockBehavior} />
            <BlockDndOverlay blockdnd={blockdndBehavior} />
          </EditorLayout>
        )}
      </DetectsOutsideEditorClick>

      <AiControls
        editorContent={editorContent}
        sectionId={sectionId}
        userId={user.id}
        company={company}
        onInsertAbove={(newContent) => {
          editor.insertAiContentAbove(newContent, linkables);
        }}
        linkables={linkables}
      />
    </EditorScrollContainer>
  );
};

export default ZeckEditable;
