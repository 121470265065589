const getMultiEnvGoogleVariables = ():
  | { apiKey: string; clientId: string }
  | undefined => {
  try {
    const googleVariables = JSON.parse(
      import.meta.env.UI_GOOGLE_VARIABLES ?? '{}',
    );
    if (
      typeof googleVariables[window.location.hostname] !== 'undefined' &&
      typeof googleVariables[window.location.hostname].apiKey !== 'undefined' &&
      typeof googleVariables[window.location.hostname].clientId !== 'undefined'
    ) {
      return googleVariables[window.location.hostname];
    }
  } catch (e) {
    console.warn('Error loading google config', e);
  }
};

const getLocalEnvGoogleVariables = () => {
  if (
    import.meta.env.UI_GOOGLE_CLIENT_ID &&
    import.meta.env.UI_GOOGLE_API_KEY
  ) {
    return {
      clientId: import.meta.env.UI_GOOGLE_CLIENT_ID,
      apiKey: import.meta.env.UI_GOOGLE_API_KEY,
    };
  }
};

const googleVariables =
  getLocalEnvGoogleVariables() || getMultiEnvGoogleVariables();

export const clientId = googleVariables?.clientId;
export const apiKey = googleVariables?.apiKey;
