import cx from 'classnames';
import { useState } from 'react';
import IconButton from '../../../../../design-system/atoms/IconButton.tsx';
import Pill from '../../../../../design-system/atoms/Pill.tsx';
import PersonView from '../../../../../design-system/molecules/PersonView.tsx';
import formatTimeAgo from '../../../../../services/formatTimeAgo.ts';
import { UserAndPermission } from '../../../../../types.ts';
import { AnalyticsZeck } from '../AnalyticsTypes.ts';
import { SectionAnalyticsRow } from '../components/SectionAnalyticsRow.tsx';
import { Table, TableCell } from '../Table.tsx';
import { SectionWithTotals } from '../util/buildSortedSectionsWithTotals.ts';
import getHoursSpent from '../util/getHoursSpent.ts';
import styles from './AnalyticsRow.module.scss';
import { ZeckViewCell } from './ZeckViewCell.tsx';

type View = { userId: string; lastViewedAt: number };

const ViewerEngagementNav = ({
  isOpen,
  setOpen,
  viewed,
}: {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  viewed: boolean;
}) => {
  return (
    <IconButton
      name="navGroup"
      aria-label={`${isOpen ? 'Collapse' : 'Expand'} user analytics`}
      className={cx(
        styles.viewerEngagementTable__viewerNav,
        !viewed && styles.viewerEngagementTable__viewerNav__hidden,
        isOpen && styles['viewerEngagementTable__viewerNav--open'],
      )}
      onClick={() => setOpen((currentOpen: boolean) => !currentOpen)}
    />
  );
};

const ViewerEngagementDetails = ({
  isOpen,
  sortedSectionsWithTotals,
  companyId,
}: {
  isOpen: boolean;
  sortedSectionsWithTotals: SectionWithTotals[];
  companyId: string;
}) => {
  return (
    <div
      className={cx(
        styles.viewerEngagementDetails__wrapper,
        isOpen && styles['viewerEngagementDetails__wrapper--open'],
      )}
    >
      <Table className={styles.viewerEngagementDetails__table}>
        {sortedSectionsWithTotals.map((sectionWithTotal) => (
          <SectionAnalyticsRow
            companyId={companyId}
            isMobile={false}
            sectionWithTotal={sectionWithTotal}
            overallTotalTime={sortedSectionsWithTotals[0]?.total.totalTime || 1}
            key={sectionWithTotal.id}
            compactStyle={true}
          />
        ))}
      </Table>
    </div>
  );
};

export const AnalyticsRow = ({
  user,
  view,
  zeck,
  isMobile,
  secondsSpent,
  commentCount,
  zeckScrollGoLiveDate,
  zeckViewGoLiveDate,
  sortedSectionsWithTotals,
}: {
  user: UserAndPermission;
  view: View | undefined;
  zeck: AnalyticsZeck;
  isMobile: boolean;
  secondsSpent: number;
  commentCount: number;
  zeckViewGoLiveDate: Date;
  zeckScrollGoLiveDate: Date;
  sortedSectionsWithTotals: SectionWithTotals[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TableCell>
        <div className={styles.viewerEngagementTable__personViewWrapper}>
          {!isMobile && (
            <ViewerEngagementNav
              isOpen={isOpen}
              setOpen={setIsOpen}
              viewed={!!view}
            />
          )}
          <PersonView user={user} />
        </div>
      </TableCell>

      {!isMobile && (
        <ZeckViewCell
          zeck={zeck}
          dataTestId={`analytics-viewed-at-${user.id}`}
          goLiveDate={zeckViewGoLiveDate}
          contentType="Viewer"
        >
          {view ? formatTimeAgo(view?.lastViewedAt, true) : ''}
        </ZeckViewCell>
      )}

      <ZeckViewCell
        zeck={zeck}
        goLiveDate={zeckViewGoLiveDate}
        contentType="Viewer"
        alignRight={isMobile}
      >
        {view && <Pill label="Viewed" size="bubble" color="primary" />}
        {!view && <Pill label="Not Viewed" size="bubble" color="gray" />}
      </ZeckViewCell>

      {!isMobile && (
        <ZeckViewCell
          dataTestId={`analytics-view-time-${user.id}`}
          zeck={zeck}
          goLiveDate={zeckScrollGoLiveDate}
          contentType="Time Spent"
        >
          {getHoursSpent(secondsSpent)}
        </ZeckViewCell>
      )}

      {!isMobile && (
        <ZeckViewCell
          zeck={zeck}
          dataTestId={`analytics-comment-count-${user.id}`}
          goLiveDate={zeckViewGoLiveDate}
          contentType="Viewer"
          className={styles.viewerEngagementTable__commentCount}
        >
          {commentCount}
        </ZeckViewCell>
      )}

      {!isMobile && (
        <ViewerEngagementDetails
          isOpen={isOpen}
          sortedSectionsWithTotals={sortedSectionsWithTotals}
          companyId={zeck.companyId}
        />
      )}
    </>
  );
};
