import styles from './AnalyticsEmptyModal.module.scss';

const AnalyticsEmptyModal: React.FC<{
  title: string;
  body: string;
}> = ({ title, body }) => (
  <div
    data-testid="analytics-empty-modal"
    className={styles.analyticsModal__empty}
  >
    <div className={styles.analyticsModal__empty_message_wrapper}>
      <div className={styles.analyticsModal__empty_message_header}>{title}</div>

      <div className={styles.analyticsModal__empty_message_text}>{body}</div>
    </div>
  </div>
);

export default AnalyticsEmptyModal;
