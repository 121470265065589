import { useCallback } from 'react';
import { Icon } from 'icons';
import Loader from '../../../design-system/atoms/Loader.tsx';
import Modal from '../../../design-system/organisms/Modal.tsx';
import SelectionModal from '../../../design-system/organisms/SelectionModal.tsx';
import { Result } from '../../../services/useFetch/useFetch.ts';
import { ZeckFolderWithCount } from '../../../types/ZeckFolder.ts';
import sortFolders from '../sortFolders.ts';

type MoveZeckModalProps = {
  isOpen: boolean;
  onClose: () => void;
  currentFolderId: string | null;
  folders: Result<ZeckFolderWithCount[]>;
  onMove: (folderId: string | null) => Promise<void>;
};

type MovableFolder = { name: string; id: string | null };

const ModalIcon: React.FC<{ item: MovableFolder }> = ({ item }) => {
  if (item.id === null) {
    return <Icon name="home" />;
  }

  return <Icon name="folder" />;
};

const MoveZeckModal = ({
  isOpen,
  onClose,
  folders,
  currentFolderId,
  onMove,
}: MoveZeckModalProps) => {
  const onMoveFolder = useCallback(
    async (folder: MovableFolder) => {
      await onMove(folder.id);
      onClose();
    },
    [onClose, onMove],
  );

  if (folders.type === 'loading') {
    return <Loader size={'small'} />;
  }

  if (folders.type === 'error') {
    return null;
  }

  const movableFolders: MovableFolder[] = [
    { name: 'Home', id: null },
    ...sortFolders(folders.data),
  ].filter((folder) => folder.id !== currentFolderId);

  return (
    <Modal isOpen={isOpen} dark={true}>
      <SelectionModal
        Icon={ModalIcon}
        items={movableFolders}
        title="Move To"
        onSubmit={onMoveFolder}
        onCancel={onClose}
      />
    </Modal>
  );
};

export default MoveZeckModal;
