import { BodySelection, BodyState } from './BodyEditor.js';

export default function select(
  initialState: BodyState,
  selection: BodySelection | null,
): BodyState {
  return {
    content: initialState.content,
    selection,
  };
}
