import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import styles from './DatePicker.module.scss';
import libraryStyles from 'react-day-picker/dist/style.module.css';
import cx from 'classnames';
import { Icon } from 'icons';

export type DatePickerProps = {
  date: string | null;
  onChange: (date: string) => void;
  className?: string;
};

export default function DatePicker({
  date,
  onChange,
  className,
}: DatePickerProps) {
  // DayPicker has its day boundaries set at midnight machine time.
  // Also, see: https://tc39.es/ecma262/multipage/numbers-and-dates.html#sec-date.parse
  const dateInstance = date
    ? new Date(`${date}T00:00:00.000`) // The date at midnight machine time
    : new Date(); // Now

  return (
    <div data-testid="calendar" className={cx(styles.calendar, className)}>
      <DayPicker
        mode="single"
        defaultMonth={dateInstance}
        selected={date ? dateInstance : undefined}
        onSelect={(_: unknown, selectedDate: Date) => {
          // format (from date-fns) gives the date relative to machine time
          // selectedDate.toISOString() would give it relative to UTC, which
          // would be wrong
          onChange(format(selectedDate, 'yyyy-MM-dd'));
        }}
        formatters={{
          formatWeekdayName: (date) => format(date, 'EEEEE'),
        }}
        components={{
          IconLeft,
          IconRight,
        }}
        classNames={{
          ...libraryStyles,
          root: cx(libraryStyles.root, styles.dayPickerRoot),
          caption: cx(libraryStyles.caption, styles.monthAndNavigation),
          caption_label: cx(
            libraryStyles.caption_label,
            styles.month,
            'testid-selectedMonth',
          ),
          nav: cx(libraryStyles.nav, styles.monthNavigation),
          nav_button: cx(libraryStyles.nav_button, styles.monthNavigation),
          head_cell: cx(libraryStyles.head_cell, styles.headerCell),
          table: cx(libraryStyles.table, styles.table),
          cell: cx(libraryStyles.cell, styles.cell, 'testid-day'),
        }}
        modifiersClassNames={{
          today: cx(styles.today),
          selected: cx(styles.selectedDay, 'testid-selectedDay'),
        }}
      />
    </div>
  );
}

function IconLeft() {
  return <Icon name="left" />;
}

function IconRight() {
  return <Icon name="right" />;
}
