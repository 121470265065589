import FileInput from '../../../../design-system/atoms/FileInput.tsx';
import ReactModal from 'react-modal';
import styles from './ImageUploadModal.module.scss';
import { Company } from '../../../../types.ts';
import Modal from '../../../../design-system/organisms/Modal.tsx';
import useImageUpload, {
  IMAGE_FILE_TYPES,
} from '../../../../services/imageUpload.ts';

type ImageUploadModalProps = {
  company: Pick<Company, 'id'>;
  instructions?: string;
  onUploadSuccess(image: { guid: string; width: number; height: number }): void;
  onClose(): void;
  isOpen: boolean;
  showToast: (message: string, duration?: number) => void;
};

const ImageUploadModal = ({
  company,
  instructions,
  onUploadSuccess,
  onClose,
  isOpen,
  showToast,
  ...otherProps
}: ImageUploadModalProps &
  Omit<ReactModal.Props, 'isOpen' | 'overlayClassName'>) => {
  const uploadImage = useImageUpload();

  return (
    <Modal
      {...{
        ...otherProps,
        isOpen,
        dark: true,
        className: styles.imageUploadModal,
        testId: 'image-upload-modal',
        onRequestClose: onClose,
      }}
    >
      {instructions && (
        <div className={styles.imageUploadModal__instructions}>
          {instructions}
        </div>
      )}
      <FileInput
        onChange={async (e) => {
          const file = e.currentTarget.files && e.currentTarget.files[0];

          if (file) {
            await uploadImage(
              file,
              company.id,
              (errorMessage) => {
                showToast(errorMessage, 5000);
                onClose();
              },
              onUploadSuccess,
            );
          }
        }}
        accept={IMAGE_FILE_TYPES.join(',')}
      >
        Choose Image
      </FileInput>
    </Modal>
  );
};

export default ImageUploadModal;
