import getIdFromSlug from '../../getIdFromSlug.ts';
import { SigninPageProps } from '../auth/signin/SigninPage.tsx';
import RequestMagicLinkWorkflow from './RequestMagicLinkWorkflow.tsx';
import { Navigate, useParams } from 'react-router-dom';

export type RequestMagicLinkPageProps = {
  zeckUrl: boolean;
} & SigninPageProps;

export default function RequestMagicLinkPage({
  zeckUrl,
  onSignIn,
}: RequestMagicLinkPageProps) {
  const params = useParams();

  let identifier;
  if (params.slug) {
    if (zeckUrl) {
      identifier = {
        zeckId: getIdFromSlug(params.slug),
        path: window.location.pathname + window.location.hash,
      };
    } else {
      identifier = {
        sectionId: getIdFromSlug(params.slug),
        path: window.location.pathname + window.location.hash,
      };
    }
  } else {
    return <Navigate to="/404" />;
  }

  return <RequestMagicLinkWorkflow onSignIn={onSignIn} {...identifier} />;
}
