import { useEffect, useState } from 'react';
import { ValidCredentialResponse } from '../../../../../api/endpoints/createCredentialApi.ts';
import { Integration } from '../../../../../api/endpoints/createIntegrationApi.ts';
import Button from '../../../../../design-system/atoms/Button.tsx';
import { Icon } from 'icons';
import OutlineButton from '../../../../../design-system/atoms/OutlineButton.tsx';
import {
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalPanel,
} from '../../../../../design-system/organisms/Modal.tsx';
import GoogleSheetPicker from './google/GoogleSheetPicker.tsx';
import IntegrationLoader from './IntegrationLoader.tsx';
import styles from './ReconnectModalContent.module.scss';
import useLoadGoogleAPIs from './utils/google/useLoadGoogleAPIs.ts';
import { SheetMeta } from './integrationModalTypes.ts';

type MissingDataType = 'sheet' | 'rows';

const MissingSheetModalContent = ({
  integrationProviderMeta,
  onClose,
  onPickSheet,
  missingDataType,
}: {
  integrationProviderMeta: Integration['providerMeta'];
  credential: ValidCredentialResponse;
  onClose: () => void;
  onPickSheet: (
    sheet: gapi.client.sheets.Sheet,
    range: string | undefined,
  ) => void;
  missingDataType: MissingDataType;
}) => {
  const googleApis = useLoadGoogleAPIs();

  const [selectingSheet, setSelectingSheet] = useState(false);
  const [selectedSheet, setSelectedSheet] =
    useState<gapi.client.sheets.Spreadsheet | null>(null);

  const title =
    missingDataType === 'sheet'
      ? `Unable to find '${integrationProviderMeta.selectionName}'`
      : `'${integrationProviderMeta.selectionName}' has no data`;

  const errorBody =
    missingDataType === 'sheet'
      ? ` The sheet '${integrationProviderMeta.selectionName}' was not found in your file. It may have been renamed or deleted.`
      : `'${integrationProviderMeta.selectionName}' doesn't seem to have any data. Please try again or select a different sheet.`;

  useEffect(() => {
    if (!googleApis) return;

    const result = googleApis.gapi.client.sheets.spreadsheets.get({
      spreadsheetId: integrationProviderMeta.documentId,
    });
    result.then((sheet) => {
      setSelectedSheet(sheet.result ?? null);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!googleApis, integrationProviderMeta.documentId]);

  if (!selectingSheet)
    return (
      <ModalPanel className={styles.reconnectModal}>
        <ModalHeader className={styles.reconnectModal__header}>
          <Icon name="googleSpreadsheet" />
          {title}
        </ModalHeader>
        <ModalBody className={styles.reconnectModal__body}>
          <div data-testid="sheet-not-found-error">{errorBody}</div>
        </ModalBody>
        <ModalActions>
          <OutlineButton size="medium" color="secondary" onClick={onClose}>
            Close
          </OutlineButton>

          <Button
            size="medium"
            color="primary"
            onClick={() => setSelectingSheet(true)}
          >
            Select Another Sheet
          </Button>
        </ModalActions>
      </ModalPanel>
    );

  if (!googleApis || !selectedSheet) {
    return <IntegrationLoader />;
  }

  const sheets: SheetMeta[] =
    selectedSheet.sheets?.map((s) => s.properties as SheetMeta) ?? [];

  return (
    <GoogleSheetPicker
      {...{
        documentMeta: {
          documentId: selectedSheet.spreadsheetId ?? 'nope',
          documentName: selectedSheet.properties?.title ?? 'nope',
          sheets,
          namedRanges: selectedSheet.namedRanges ?? [],
        },
        onClose,
        gapi: gapi,
        onPickSheet,
      }}
    />
  );
};

export default MissingSheetModalContent;
