import { UserEngagementTotal } from '../../../../../api/endpoints/createAnalyticsApi.js';

export type SectionTotalResult = {
  totalTime: number;
  percentOfTotal: string;
};

const getSectionTotals = (totals: UserEngagementTotal[]) => {
  const sectionMap: { [key: string]: number } = {};
  let allTime = 0;

  totals.forEach((total) => {
    sectionMap[total.sectionId] =
      (sectionMap[total.sectionId] ?? 0) + total.seconds;
    allTime += total.seconds;
  });

  const sectionTotals: {
    [sectionId: string]: SectionTotalResult;
  } = {};

  for (const sectionId in sectionMap) {
    const sectionSeconds = sectionMap[sectionId] ?? 0;

    const percentOfTotal = (sectionSeconds / (allTime || 1)) * 100;

    sectionTotals[sectionId] = {
      totalTime: sectionSeconds,
      percentOfTotal: `${percentOfTotal.toFixed(1)}%`,
    };
  }

  return { totalTime: allTime, sectionTotals };
};

export default getSectionTotals;
