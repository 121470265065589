// pulled from io-ts
// allows you brand a type in the type system but not at runtime
// I don't know how it works

declare const _brand: unique symbol;

export interface Brand<B> {
  readonly [_brand]: B;
}

export declare type Branded<A, B> = A & Brand<B>;

// end io-ts stuff
interface SafeHTMLStringBrand {
  readonly SafeHTMLString: unique symbol; // use `unique symbol` here to ensure uniqueness across modules / packages
}

export type SafeHTMLString = Branded<string, SafeHTMLStringBrand>;

export const IPromiseThisHTMLStringIsSafeToRender = (
  s: string,
): SafeHTMLString => s as SafeHTMLString;
