import createUseEnterExitAnimation from './createUseEnterExitAnimation.js';

const useFadeInAnimation = createUseEnterExitAnimation(
  [
    { opacity: 0, visibility: 'hidden' },
    { opacity: 1, visibility: 'visible' },
  ],
  {
    duration: 200,
    iterations: 1,
    easing: 'linear',
    fill: 'both',
  },
);

export default useFadeInAnimation;
