import BaseAndExtent, {
  baseAndExtent,
} from '../editor/selection/contentSelection/BaseAndExtent.js';
import arrayIsNotEmpty from '../junkDrawer/arrayIsNotEmpty.js';
import {
  CommentContentNode,
  commentTagNode,
  commentTextNode,
} from 'editor-content/CommentContent.js';
import assertUnreachable from '../junkDrawer/assertUnreachable.js';
import ContentSelection from '../editor/selection/contentSelection/ContentSelection.js';
import {
  IPromiseThisHTMLStringIsSafeToRender,
  SafeHTMLString,
} from '../editor/useContentEditable/SafeHTMLString.js';

export interface TagElement extends HTMLElement {
  dataset: {
    nodetype: 'tag';
    userid: string;
    displayname: string;
  };
}

export const isTagElement = (el: HTMLElement): el is TagElement =>
  el.dataset.nodetype === 'tag';

function isText(node: Node): node is Text {
  return node.nodeType === Node.TEXT_NODE;
}

function filterNodes(nodes: Node[]): (Text | TagElement)[] {
  return nodes.filter(
    (node): node is Text | TagElement =>
      isText(node) ||
      (node.nodeType === Node.ELEMENT_NODE &&
        isTagElement(node as HTMLElement)),
  );
}

type Point = { node: Node; offset: number };

function getIndexFromPoint(point: Point, nodes: (Text | TagElement)[]): number {
  const pointInDOM = new Range();
  pointInDOM.setStart(point.node, point.offset);

  if (!arrayIsNotEmpty(nodes)) {
    return 0;
  }

  const [node, ...restNodes] = nodes;

  // is current parsing location after destination
  if (pointInDOM.comparePoint(node, 0) > -1) {
    return 0;
  }

  if (isText(node)) {
    // is within node
    if (point.node === node) {
      return point.offset;
    }

    const textContent = node.textContent || '';

    // the point is not the current node, move to the next node accumulate the content length of the node
    // node length == distance walked
    return getIndexFromPoint(point, restNodes) + textContent.length;
  } else if (isTagElement(node)) {
    if (pointInDOM.comparePoint(node, node.childNodes.length) > -1) {
      return 1;
    }

    // the point is not the current node, move to the next node accumulate the content length of the node == 1
    // node length == distance walked
    return getIndexFromPoint(point, restNodes) + 1;
  }

  // should not be reachable
  return 0;
}

function fromBaseAndExtent(
  selection: BaseAndExtent,
  containerElement: HTMLElement,
): ContentSelection {
  const nodes = filterNodes(Array.from(containerElement.childNodes));

  return {
    anchorOffset: getIndexFromPoint(
      {
        node: selection.anchorNode,
        offset: selection.anchorOffset,
      },
      nodes,
    ),
    focusOffset: getIndexFromPoint(
      {
        node: selection.focusNode,
        offset: selection.focusOffset,
      },
      nodes,
    ),
  };
}

function indexToPoint(
  textContentIndex: number,
  nodes: (Text | TagElement)[],
  container: HTMLElement,
): Point {
  if (textContentIndex === 0) {
    return {
      node: container,
      offset: 0,
    };
  }

  if (!arrayIsNotEmpty(nodes)) {
    return {
      node: container,
      offset: 0,
    };
  }

  const [node, ...restNodes] = nodes;

  if (isText(node)) {
    const textContent = node.textContent || '';
    const nodeLength = textContent.length;

    // make this inclusive so that we can represent selection in front of tag
    // (which cannot have selection inside it)
    // as being at the end of the previous text node
    if (textContentIndex < nodeLength) {
      return { node, offset: textContentIndex };
    }

    const point = indexToPoint(
      textContentIndex - nodeLength,
      restNodes,
      container,
    );
    if (point.node === container) {
      return {
        node: point.node,
        offset: point.offset + 1,
      };
    }
    return point;
  } else if (isTagElement(node)) {
    if (textContentIndex < 1) {
      return {
        node: container,
        offset: 0,
      };
    }

    const nodeLength = 1;
    const point = indexToPoint(
      textContentIndex - nodeLength,
      restNodes,
      container,
    );
    if (point.node === container) {
      return {
        node: point.node,
        offset: point.offset + 1,
      };
    }
    return point;
  }

  // should be impossible
  return {
    node: container,
    offset: 0,
  };
}

function toBaseAndExtent(
  selection: ContentSelection,
  container: HTMLElement,
): BaseAndExtent {
  const nodes = filterNodes(Array.from(container.childNodes));

  if (!arrayIsNotEmpty(nodes)) {
    return baseAndExtent(container, 0);
  }

  const anchorPoint = indexToPoint(selection.anchorOffset, nodes, container);
  const focusPoint = indexToPoint(selection.focusOffset, nodes, container);
  return baseAndExtent(
    anchorPoint.node,
    anchorPoint.offset,
    focusPoint.node,
    focusPoint.offset,
  );
}

function fromHTMLString(html: string): CommentContentNode[] {
  const p = new DOMParser();
  const d = p.parseFromString(html, 'text/html');

  return filterNodes(Array.from(d.body.childNodes)).map((childNode) => {
    if (childNode.nodeType === Node.TEXT_NODE) {
      return commentTextNode(childNode.textContent || '');
    } else if (childNode.nodeType === Node.ELEMENT_NODE) {
      const htmlChild = childNode as HTMLElement;

      if (isTagElement(htmlChild)) {
        return commentTagNode(
          htmlChild.dataset.userid,
          htmlChild.dataset.displayname,
        );
      }
    }

    return commentTextNode(''); // should never happen
  });
}

function toHTMLString(content: CommentContentNode[]): SafeHTMLString {
  const nodes = content.map((contentNode) => {
    switch (contentNode.type) {
      case 'text':
        return document.createTextNode(contentNode.text);
      case 'tag': {
        const tagEl = document.createElement('span');

        // behaves like a slug:
        tagEl.setAttribute('contenteditable', 'false');
        tagEl.dataset['nodetype'] = 'tag';
        tagEl.dataset['userid'] = contentNode.userId;
        tagEl.dataset['displayname'] = contentNode.displayName;
        tagEl.textContent = `@${contentNode.displayName}`;

        return tagEl;
      }
    }

    return assertUnreachable(contentNode);
  });

  const container = document.createElement('div');

  nodes.forEach((node) => {
    container.appendChild(node);
  });

  return IPromiseThisHTMLStringIsSafeToRender(container.innerHTML);
}

const CommentEditorAdapter = {
  filterNodes,
  fromBaseAndExtent,
  toBaseAndExtent,
  fromHTMLString,
  toHTMLString,
};

export default CommentEditorAdapter;
