import styles from './Spinner.module.scss';
import cx from 'classnames';

const Spinner = ({ className }: { className?: string }) => {
  return (
    <svg
      className={cx(styles.spinner, className)}
      width="44"
      height="44"
      viewBox="0 0 44 44"
    >
      <circle className={styles.spinner__path} cx="22" cy="22" r="20" />
    </svg>
  );
};

export const SmallSpinner = ({ className }: { className?: string }) => {
  return (
    <svg
      className={cx(styles.spinner, className)}
      width="22"
      height="22"
      viewBox="0 0 44 44"
    >
      <circle className={styles.spinner__path} cx="22" cy="22" r="20" />
    </svg>
  );
};

export default Spinner;
