import useUndoRedo from '../useUndoRedo.js';
import { useCallback, useMemo } from 'react';
import useUndoRedoKeybindings from '../useUndoRedoKeybindings.js';

const useUndoRedoOnEditor = <A, B>({
  editorContent,
  editorSelection,
  setEditorContent,
  setEditorSelection,
}: {
  editorContent: A;
  editorSelection: B;
  setEditorContent: (newContent: A) => void;
  setEditorSelection: (newSelection: B) => void;
}) => {
  const { undo, redo, setValue, value } = useUndoRedo(
    useMemo(
      () => ({
        content: editorContent,
        selection: editorSelection,
      }),
      [editorContent, editorSelection],
    ),
    useCallback(
      (newValue) => {
        setEditorContent(newValue.content);
        setEditorSelection(newValue.selection);
      },
      [setEditorSelection, setEditorContent],
    ),
  );
  useUndoRedoKeybindings(undo, redo);

  return {
    setValue,
    value,
  };
};

export default useUndoRedoOnEditor;
