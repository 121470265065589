import styles from './ConfirmPublish.module.scss';
import Button from '../../../design-system/atoms/Button.tsx';
import { Zeck } from '../../../types.ts';
import useBreakpoints from '../../../services/useBreakpoints.ts';
import DrawerMenu, {
  DrawerMenuBigButtonItem,
} from '../../../design-system/organisms/DrawerMenu.tsx';
import OutlineButton from '../../../design-system/atoms/OutlineButton.tsx';
import { Icon } from 'icons';
import { ModalPanel } from '../../../design-system/organisms/Modal.tsx';

type ConfirmPublishModalProps = {
  zeck: Zeck;
  open: boolean;
  onCancel: () => void;
  onPublish: () => void;
};

function ConfirmPublish({
  zeck,
  open,
  onPublish,
  onCancel,
}: ConfirmPublishModalProps) {
  const { isMobile } = useBreakpoints();

  if (isMobile) {
    return (
      <DrawerMenu isOpen={open} onRequestClose={onCancel}>
        <div className={styles.mobile_drawer}>
          <div className={styles.header}>Ready to Publish?</div>

          <div className={styles.bulletPoint}>
            <Icon name="checkmark" />
            <span className={styles.bulletPoint__text}>
              Viewers will immediately be able to access all sections of{' '}
              <span className={styles.zeckName}>{zeck.name}</span>.
            </span>
          </div>

          <div className={styles.mobile_drawer_footer}>
            <DrawerMenuBigButtonItem color="primary" onClick={onPublish}>
              Publish Now
            </DrawerMenuBigButtonItem>
            <DrawerMenuBigButtonItem color="normal" onClick={onCancel}>
              Cancel
            </DrawerMenuBigButtonItem>
          </div>
        </div>
      </DrawerMenu>
    );
  }
  return (
    <ModalPanel>
      <div className={styles.publishModal}>
        <div className={styles.header}>Ready to Publish?</div>

        <div className={styles.bulletPoint}>
          <Icon name="checkmark" />
          <span className={styles.bulletPoint__text}>
            Viewers will immediately be able to access all sections of{' '}
            <span className={styles.zeckName}>{zeck.name}</span>.
          </span>
        </div>

        <div className={styles.footer}>
          <OutlineButton size="medium" color="secondary" onClick={onCancel}>
            Cancel
          </OutlineButton>

          <Button
            size={'medium'}
            color={'primary'}
            onClick={() => {
              onPublish();
            }}
          >
            Yes, Publish Now
          </Button>
        </div>
      </div>
    </ModalPanel>
  );
}

export default ConfirmPublish;
