import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../../../../design-system/organisms/Modal.tsx';
import styles from './ConfirmDeleteCommentModal.module.scss';
import OutlineButton from '../../../../../design-system/atoms/OutlineButton.tsx';
import Button from '../../../../../design-system/atoms/Button.tsx';

type ConfirmDeleteCommentModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  disableButtons: boolean;
};

export default function ConfirmDeleteCommentModal({
  isOpen,
  onCancel,
  onConfirm,
  disableButtons,
}: ConfirmDeleteCommentModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      className={styles.modal}
      overlayClassName={styles.confirmDeleteCommentModal__overlay}
      onRequestClose={onCancel}
    >
      <ModalHeader
        onClickClose={(e) => {
          e.stopPropagation();
          onCancel();
        }}
      >
        Delete Comment?
      </ModalHeader>
      <ModalBody>
        Are you sure you want to delete this comment? You won’t be able to get
        it back.
      </ModalBody>
      <ModalActions>
        <OutlineButton
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
          color="secondary"
          size="medium"
          disabled={disableButtons}
        >
          Cancel
        </OutlineButton>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
          color="danger"
          size="medium"
          disabled={disableButtons}
        >
          Delete Comment
        </Button>
      </ModalActions>
    </Modal>
  );
}
