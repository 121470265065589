import createUseContentEditableWithSelection from './useContentEditableWithSelection.js';
import BlockEditorAdapter from '../pages/zeck/editor/BlockEditorAdapter.js';
import { TextNode } from 'editor-content/TextNode.js';
import ContentSelection from './selection/contentSelection/ContentSelection.js';
import { AppContext } from 'editor-content/textNodesToHtmlString.js';

const useContentEditableWithBlockTextNodes =
  createUseContentEditableWithSelection(BlockEditorAdapter);

const useMeetingMinutesContentEditable = <ElementType extends HTMLElement>(
  value: {
    content: TextNode[];
    selection: ContentSelection | null;
  },
  onChange: (newValue: {
    content: TextNode[];
    selection: ContentSelection;
  }) => void,
  onSelect: (newSelection: ContentSelection) => void,
  context: AppContext,
) =>
  useContentEditableWithBlockTextNodes<ElementType>(
    value,
    onChange,
    // we don't propagate nulled selection, because the zeck editor will handle selection exclusivity, that is
    // removing selection from one block and putting it in another
    (contentSelection) => contentSelection && onSelect(contentSelection),
    context,
  );

export default useMeetingMinutesContentEditable;
