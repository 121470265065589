import { useEffect } from 'react';
import { Result as ZeckResult } from '../result/Result.js';
import { Result } from '../services/useFetch/useFetch.js';

type Model = { name: string };
type ResultWithName = Result<Model> | ZeckResult<unknown, Model>;

function useDocumentTitleFromResult(model: ResultWithName, suffix = '') {
  useEffect(() => {
    if (model.type !== 'success' && model.type !== 'mutating') return;

    const originalTitle = document.title;

    const maybeSuffix = suffix ? ` ${suffix}` : '';

    document.title = model.data.name + `${maybeSuffix} - Zeck`;

    return () => {
      document.title = originalTitle; // This will be executed when the component is unmounted
    };
  }, [model, suffix]); // This effect runs whenever the title changes
}

export function useDocumentTitle(title: string) {
  useEffect(() => {
    const originalTitle = document.title;

    document.title = title + ' - Zeck';

    return () => {
      document.title = originalTitle; // This will be executed when the component is unmounted
    };
  }, [title]); // This effect runs whenever the title changes
}

export default useDocumentTitleFromResult;
