// Represents a state action as an action with effect
// That is: from an action that just changes state, to an action that changes state, and has a void effect/return type

export const convertToStateVoid =
  <ArgsType extends unknown[], ReturnType>(
    action: (...args: ArgsType) => ReturnType,
  ) =>
  (...args: ArgsType): [ReturnType, void] => {
    return [action(...args), undefined];
  };
