import { TextFormat } from 'editor-content/TextNode.js';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';

type FormatTextButton = {
  format: TextFormat;
  onToggleFormat: (formatName: keyof TextFormat) => void;
};

export const BoldButton: React.FC<FormatTextButton> = ({
  format,
  onToggleFormat,
}) => (
  <IconButton
    {...{
      name: 'bold',
      type: 'dark',
      'aria-label': format.bold ? 'make text not bold' : 'make text bold',
      onClick: () => onToggleFormat('bold'),
      active: format.bold,
    }}
  />
);

export const ItalicsButton: React.FC<FormatTextButton> = ({
  format,
  onToggleFormat,
}) => (
  <IconButton
    {...{
      name: 'italics',
      type: 'dark',
      'aria-label': format.italic ? 'make text not italic' : 'make text italic',
      onClick: () => onToggleFormat('italic'),
      active: format.italic,
    }}
  />
);

export const UnderlineButton: React.FC<FormatTextButton> = ({
  format,
  onToggleFormat,
}) => (
  <IconButton
    {...{
      name: 'underline',
      type: 'dark',
      'aria-label': format.underline
        ? 'make text not underlined'
        : 'make text underlined',
      onClick: () => onToggleFormat('underline'),
      active: format.underline,
    }}
  />
);
