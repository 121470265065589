export type CommentTagNode = {
  type: 'tag';
  userId: string;
  displayName: string;
};

export const commentTagNode = (
  userId: string,
  displayName: string,
): CommentTagNode => ({
  type: 'tag',
  userId,
  displayName,
});

export const isCommentTagNode = (
  node: CommentContentNode,
): node is CommentTagNode => node.type === 'tag';

export type CommentTextNode = {
  type: 'text';
  text: string;
};

export const commentTextNode = (text: string): CommentTextNode => ({
  type: 'text',
  text,
});

export type CommentContentNode = CommentTextNode | CommentTagNode;

export type CommentContent = CommentContentNode[];
export type CommentReplyContent = CommentTextNode[];

export const getTextFromCommentContent = (content: CommentContent): string =>
  content
    .map((node) => {
      switch (node.type) {
        case 'text':
          return node.text;
        case 'tag':
          return `@${node.displayName}`;
      }

      return '';
    })
    .join('');
