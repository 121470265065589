import cx from 'classnames';
import styles from './MeetingMinutesSignature.module.scss';

const MeetingMinutesSignature: React.FC<{
  signature: { signedName: string; signedTitle: string | null };
}> = (props) => (
  <>
    <div
      className={cx(
        styles.minutesPage__signature,
        styles.minutesPage__signatureSigned,
      )}
      data-testid="meetingMinutes__signatureWrapper"
    >
      {props.signature.signedName}
    </div>
    <div
      data-testid="signed-title"
      className={styles.minutesPage__signatureTitle}
    >
      {props.signature.signedTitle}
    </div>
  </>
);

export default MeetingMinutesSignature;
