import React from 'react';
import styles from './NumberedList.module.scss';
import cx from 'classnames';

type NumberedListProps = {
  children: React.ReactNode;
  className?: string;
};

const NumberedList = ({
  children,
  className,
  ...props
}: NumberedListProps & React.HTMLAttributes<HTMLUListElement>) => {
  return (
    <ol
      {...{
        className: cx(className, styles.numberedList),
        ...props,
      }}
    >
      {children}
    </ol>
  );
};

type NumberedListItemProps = {
  children?: React.ReactNode;
  className?: string;
  indent?: number;
};

export const NumberedListItem = React.forwardRef<
  HTMLLIElement,
  NumberedListItemProps & React.HTMLAttributes<HTMLLIElement>
>(({ children, className, indent, ...props }, forwardedRef) => {
  return (
    <li
      {...{
        ref: forwardedRef,
        className: cx(
          className,
          styles.numberedListItem,
          indent && styles.indent,
        ),
        ...props,
      }}
    >
      {children}
    </li>
  );
});

export default NumberedList;
