import styles from './PdfFrame.module.scss';

type PdfFrameProps = {
  pdfUrl: string;
};

const PdfFrame: React.FC<PdfFrameProps> = ({ pdfUrl }) => {
  return (
    <iframe
      src={`/frames/pdfViewer/index.html?pdfUrl=${pdfUrl}`}
      title="PDF Viewer"
      className={styles.pdfFrame}
      sandbox="allow-scripts"
    />
  );
};

export default PdfFrame;
