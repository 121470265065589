import Modal, {
  ModalHeader,
  ModalBody,
  ModalActions,
} from '../../../design-system/organisms/Modal.tsx';
import Button from '../../../design-system/atoms/Button.tsx';
import OutlineButton from '../../../design-system/atoms/OutlineButton.tsx';
import styles from './ConfirmResetPrevotesModal.module.scss';

type ConfirmResetPrevotesModalProps = {
  isOpen: boolean;
  onReset(): void;
  onCancel(): void;
};

export default function ConfirmResetPrevotesModal({
  isOpen,
  onReset,
  onCancel,
}: ConfirmResetPrevotesModalProps) {
  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader onClickClose={onCancel}>Reset Pre-Voting?</ModalHeader>
      <ModalBody>
        Board directors have already pre-voted. If you make edits to the vote
        content, you will wipe out all pre-votes cast so far.
      </ModalBody>
      <ModalActions>
        <OutlineButton onClick={onCancel} color="secondary" size="medium">
          Cancel
        </OutlineButton>
        <Button onClick={onReset} color="danger" size="medium">
          Yes, Reset Pre-Voting
        </Button>
      </ModalActions>
    </Modal>
  );
}
