import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function CopyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M15.7895 2H5.68421C4.75789 2 4 2.77727 4 3.72727V15.8182H5.68421V3.72727H15.7895V2ZM18.3158 5.45455H9.05263C8.12632 5.45455 7.36842 6.23182 7.36842 7.18182V19.2727C7.36842 20.2227 8.12632 21 9.05263 21H18.3158C19.2421 21 20 20.2227 20 19.2727V7.18182C20 6.23182 19.2421 5.45455 18.3158 5.45455ZM18.3158 19.2727H9.05263V7.18182H18.3158V19.2727Z"
        fill="#323232"
      />
    </svg>
  );
}
