import styles from './CompanyName.module.scss';
import cx from 'classnames';

type CompanyNameProps = {
  companyName: string;
  className?: string;
};

const CompanyName = ({ companyName, className }: CompanyNameProps) => {
  return (
    <div className={cx(styles.companyName, className)}>
      <div className={styles.companyName__icon}>{companyName.slice(0, 1)}</div>
      <div className={styles.companyName__name}>{companyName}</div>
    </div>
  );
};

export default CompanyName;
