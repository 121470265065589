import { MeetingMinutes } from '../../../api/endpoints/createMeetingMinutesApi.ts';
import { MeetingMinutesLinkable } from 'editor-content/html/MeetingMinutesLinkable.js';

export const makeMeetingMinutesLinkable = (
  meetingMinutes: Pick<MeetingMinutes, 'id' | 'title' | 'companyId'>,
): MeetingMinutesLinkable => ({
  type: 'meeting-minutes',
  itemId: meetingMinutes.id,
  title: meetingMinutes.title,
  url: `/company/${meetingMinutes.companyId}/minutes/${meetingMinutes.id}`,
});
