import usePrevious from '../junkDrawer/usePrevious.ts';
import { Result } from '../services/useFetch/useFetch.ts';
import { useEffect, useRef, useState } from 'react';

const WithResultLoading = <T,>(props: {
  result: Result<T>;
  duration: number;
  children: (value: T) => React.ReactNode;
  renderLoading: (out: boolean) => React.ReactNode;
  renderError?: () => React.ReactNode;
}) => {
  const {
    result,
    children,
    renderLoading,
    duration,
    renderError = () => null,
  } = props;

  const [shouldRenderLoader, setShouldRenderLoader] = useState(
    result.type === 'loading',
  );

  const removeByRef = useRef<number | null>(null);

  const previousResultType = usePrevious(result.type);
  useEffect(() => {
    const now = Date.now();
    if (result.type === 'success' && previousResultType === 'loading') {
      removeByRef.current = now + duration;

      const timeoutId = setTimeout(() => {
        setShouldRenderLoader(false);
      }, duration);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (removeByRef.current && removeByRef.current > now) {
      const timeoutId = setTimeout(() => {
        setShouldRenderLoader(false);
      }, removeByRef.current - now);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (result.type === 'loading' && previousResultType === 'success') {
      setShouldRenderLoader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.type, previousResultType]);

  switch (result.type) {
    case 'error':
      return <>{renderError()}</>;
    case 'loading':
    case 'success':
      return (
        <>
          {result.type === 'success' && children(result.data)}
          {shouldRenderLoader && renderLoading(result.type === 'success')}
        </>
      );
  }
};

export default WithResultLoading;
