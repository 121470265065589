import { initClient } from '@ts-rest/core';
import { aiContract } from 'api-client/aiContract.js';
import { version } from '../../version.ts';

const aiApiUrl =
  import.meta.env.UI_LOCAL_AI_URL ?? `https://api-${document.location.host}`;

const AiApiClient = initClient(aiContract, {
  baseUrl: aiApiUrl,
  baseHeaders: {
    Version: version,
  },
});

export default AiApiClient;
