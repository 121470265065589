import { isHighlight } from 'editor-content/TextNode.js';
import { isTextBlock } from 'editor-content/Block.js';
import { EditorContent } from '../edit/useEditorState.js';

const getSelectionFromContent = (
  content: EditorContent,
  selectionId: string,
) => {
  const maybeHighlight = content.headline
    .filter(isHighlight)
    .find((node) => node.ids.includes(selectionId));

  if (maybeHighlight) {
    return maybeHighlight;
  }

  for (const block of content.body) {
    if (block.id === selectionId) {
      return block;
    }

    if (isTextBlock(block)) {
      const maybeHighlight = block.content
        .filter(isHighlight)
        .find((node) => node.ids.includes(selectionId));

      if (maybeHighlight) {
        return maybeHighlight;
      }
    }
  }

  return null;
};

export default getSelectionFromContent;
