import {
  createContext,
  MouseEventHandler,
  useContext,
  useEffect,
  useRef,
} from 'react';

type EventRegistrar = {
  addOnClickListener: (handler: MouseEventHandler) => void;
  removeOnClickListener: (handler: MouseEventHandler) => void;
  handleClick: MouseEventHandler;
};

const RootEventListenerContext = createContext<
  Omit<EventRegistrar, 'handleClick'>
>({
  addOnClickListener: () => {
    // throw new Error('RootEventListenerContext not initialized');
  },
  removeOnClickListener: () => {
    // throw new Error('RootEventListenerContext not initialized');
  },
});

const createEventRegistrar = (): EventRegistrar => {
  const registeredClickHandlers: Set<MouseEventHandler> = new Set();

  return {
    addOnClickListener: (handler) => {
      registeredClickHandlers.add(handler);
    },
    removeOnClickListener: (handler) => {
      registeredClickHandlers.delete(handler);
    },
    handleClick: (e) => {
      for (const handler of registeredClickHandlers) {
        handler(e);
      }
    },
  };
};

export const RootEventListener: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const eventRegistrarRef = useRef<EventRegistrar>(createEventRegistrar());

  return (
    <div
      id="zeck-app-root"
      data-testid="root-event-listener"
      onClick={eventRegistrarRef.current.handleClick}
    >
      <RootEventListenerContext.Provider value={eventRegistrarRef.current}>
        {children}
      </RootEventListenerContext.Provider>
    </div>
  );
};

export const useRootClickListener = (listener: MouseEventHandler) => {
  const registrar = useContext(RootEventListenerContext);

  useEffect(() => {
    registrar.addOnClickListener(listener);

    return () => {
      registrar.removeOnClickListener(listener);
    };
  }, [listener, registrar]);
};
export default useRootClickListener;
