import React from 'react';
import AgendaItemCustomEditable from './AgendaItemCustomEditable.tsx';
import AgendaItemSectionEditable from './AgendaItemSectionEditable.tsx';
import { AgendaItem } from 'editor-content/AgendaBlock.js';
import Linkable from 'editor-content/html/Linkable.ts';

type AgendaItemEditableProps = {
  item: AgendaItem;
  timeZone: string | null;
  onChangeItem: (newItem: AgendaItem) => void;
  onChangeStartTime: (values: {
    startTime: number | null;
    timeZone: string;
  }) => void;
  onDeleteItem: () => void;
  linkables: Linkable[];
} & Omit<React.ComponentProps<'div'>, 'onChange' | 'children'>;

const AgendaItemEditable = React.forwardRef<
  HTMLDivElement,
  AgendaItemEditableProps
>(function AgendaItemEditable(
  {
    item,
    timeZone,
    onChangeItem,
    onChangeStartTime,
    onDeleteItem,
    linkables,
    ...otherProps
  },
  forwardedRef,
) {
  switch (item.type) {
    case 'custom':
      return (
        <AgendaItemCustomEditable
          {...otherProps}
          ref={forwardedRef}
          name={item.name}
          description={item.description}
          startTime={item.startTime}
          timeZone={timeZone}
          onClickDelete={onDeleteItem}
          onChangeName={(newName) => {
            onChangeItem({
              ...item,
              name: newName,
            });
          }}
          onChangeDescription={(newDescription) => {
            onChangeItem({
              ...item,
              description: newDescription,
            });
          }}
          onChangeStartTime={onChangeStartTime}
        />
      );
    case 'section': {
      const linkable = linkables.find(
        ({ itemId, type }) => itemId === item.sectionId && type === 'section',
      );
      if (!linkable) {
        return null;
      }

      return (
        <AgendaItemSectionEditable
          {...otherProps}
          ref={forwardedRef}
          title={linkable.title ?? 'Untitled'}
          description={item.description}
          startTime={item.startTime}
          timeZone={timeZone}
          onClickDelete={onDeleteItem}
          onChangeDescription={(newDescription) => {
            onChangeItem({
              ...item,
              description: newDescription,
            });
          }}
          onChangeStartTime={onChangeStartTime}
        />
      );
    }
  }

  return null;
});

export default AgendaItemEditable;
