import { getExternalLinkFromElement } from 'editor-content/html/ExternalLinkElement.ts';
import Linkable, { findLinkableForLink } from 'editor-content/html/Linkable.ts';
import {
  getMeetingMinutesLinkFromElement,
  isMeetingMinutesLinkElement,
} from 'editor-content/html/MeetingMinutesLinkElement.ts';
import {
  getSectionLinkFromElement,
  isSectionLinkElement,
} from 'editor-content/html/SectionLinkElement.ts';

type ContentType = 'section-link' | 'meeting-minutes-link';
export type LinkData =
  | { type: 'external'; url: string }
  | {
      type: 'internal';
      contentType: ContentType;
      status: 'not-found';
    }
  | {
      type: 'internal';
      content: Linkable;
      status: 'full' | 'restricted';
    }
  | { type: 'other' };

const getLinkableFromEl = (linkables: Linkable[], el: HTMLElement) => {
  const textNode = getTextNodeFromEl(el);

  if (!textNode) return undefined;

  return findLinkableForLink(textNode, linkables);
};

const getTextNodeFromEl = (el: HTMLElement) =>
  getSectionLinkFromElement(el, []) ?? getMeetingMinutesLinkFromElement(el, []);

export const getLinkDataFromElement = (
  element: HTMLElement,
  linkables: Linkable[],
): LinkData => {
  const isSectionLink = isSectionLinkElement(element);
  const isMeetingMinutesLink = isMeetingMinutesLinkElement(element);

  if (isSectionLink || isMeetingMinutesLink) {
    const linkable = getLinkableFromEl(linkables, element);

    const contentType: ContentType = isSectionLink
      ? 'section-link'
      : 'meeting-minutes-link';

    if (linkable) {
      return {
        type: 'internal',
        content: linkable,
        status: linkable.title ? 'full' : 'restricted',
      };
    }

    return {
      type: 'internal',
      contentType,
      status: 'not-found',
    };
  }

  const externalLink = getExternalLinkFromElement(element, []);
  if (externalLink) {
    return {
      type: 'external',
      url: externalLink.url,
    };
  }

  return {
    type: 'other',
  };
};
