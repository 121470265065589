import { useRef } from 'react';
import { getRectFromEl, isIntersecting } from '../../domHelpers/Rect.js';

function useDetectCollision<T extends HTMLElement>(
  els: (() => HTMLElement | undefined)[],
) {
  const targetElementRef = useRef<T>(null);

  const isColliding = () => {
    if (!targetElementRef.current) {
      return false;
    }
    const elements = els
      .map((getEl) => getEl())
      .filter((a): a is HTMLElement => !!a);

    const targetEl = targetElementRef.current;

    return elements.some((imageElement) =>
      isIntersecting(getRectFromEl(targetEl), getRectFromEl(imageElement)),
    );
  };

  return {
    targetElementRef,
    isColliding,
  };
}

export default useDetectCollision;
