import { createParagraphBlock } from 'editor-content/Block.ts';
import replaceSelectionWith from '../../pages/zeck/editor/BodyEditor/replaceSelectionWith.ts';
import { HydratedBlock } from '../../types/HydratedBlock.ts';
import { ParagraphBlockKeyboardBehavior } from '../blocks/paragraph/paragraphReplaceWithBulletStrategy.ts';
import { ContentPatch } from '../ContentPatch.js';
import { EditorConfiguration, EditorState } from '../EditorAction.js';
import ContentSelection from '../selection/contentSelection/ContentSelection.ts';

type ReplaceWithBulletBlockInterface<BlockType> = {
  replaceWithBullet: (
    selection: ContentSelection,
  ) => ContentPatch<BlockType[]> | void;
};

// generateBlockEditor is a function that returns a ReplaceWithBulletBlockInterface<BlockType> or void
const replaceWithBullet =
  <BlockType>({
    generateBlockEditor,
  }: EditorConfiguration<
    BlockType,
    ReplaceWithBulletBlockInterface<BlockType> | void
  >) =>
  (initialState: EditorState<BlockType>) =>
    replaceSelectionWith(initialState, {
      textSelection: (selectedBlock, selection) => {
        const targetBlockEditor = generateBlockEditor(selectedBlock);
        if (!targetBlockEditor) {
          return;
        }
        return targetBlockEditor.replaceWithBullet(selection);
      },
      blockSelection: (_initialState) => {
        return;
      },
    });

const generateBlockEditor = (
  block: HydratedBlock,
): ReplaceWithBulletBlockInterface<HydratedBlock> | void => {
  switch (block.type) {
    case 'paragraph':
      return {
        replaceWithBullet: (selection: ContentSelection) => {
          return ParagraphBlockKeyboardBehavior.replaceWithBullet(
            block,
            selection,
          );
        },
      };
  }
  return;
};
export const replaceWithBulletBody = replaceWithBullet<HydratedBlock>({
  generateBlockEditor: generateBlockEditor,
  createDefaultBlock: createParagraphBlock,
});

export default replaceWithBullet;
