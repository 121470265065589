import compact from 'lodash/compact.js';
import { useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import mergeRefs from '../../junkDrawer/mergeRefs.js';

type ReactModalWithScrollProps = ReactModal.Props & {
  onScroll: (e: Event) => void;
};

const ReactModalWithScroll: React.FC<ReactModalWithScrollProps> = ({
  onScroll,
  ...otherProps
}) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollCallbackRef = useRef(onScroll);

  useEffect(() => {
    scrollCallbackRef.current = onScroll;
  }, [onScroll]);

  return (
    <ReactModal
      {...otherProps}
      contentRef={mergeRefs(
        compact([
          (el) => (scrollContainerRef.current = el),
          otherProps.contentRef,
        ]),
      )}
      onAfterOpen={() => {
        scrollContainerRef.current?.addEventListener('scroll', (e) =>
          scrollCallbackRef.current(e),
        );
      }}
    ></ReactModal>
  );
};

export default ReactModalWithScroll;
