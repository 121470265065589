import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { AnalyticsSummary } from '../../../../../api/endpoints/createAnalyticsApi.ts';
import { useFeatureFlags } from '../../../../../feature-flags/FeatureFlagsContext.ts';
import { isPendingUser, UserAndPermission } from '../../../../../types.ts';
import AnalyticsEmptyModal from '../AnalyticsEmptyModal.tsx';
import { AnalyticsZeck } from '../AnalyticsTypes.ts';
import { Table, TableHeaderCell } from '../Table.tsx';
import { SectionWithTotals } from '../util/buildSortedSectionsWithTotals.ts';
import { AnalyticsRow } from './AnalyticsRow.tsx';
import styles from './ViewerEngagementModal.module.scss';
import ViewerEngagementSummary from './ViewerEngagementSummary.tsx';

const sortUsers = (a: UserAndPermission, b: UserAndPermission) => {
  if (isPendingUser(a) === isPendingUser(b)) {
    const sortKeyA = isPendingUser(a) ? a.email : a.firstName;
    const sortKeyB = isPendingUser(b) ? b.email : b.firstName;
    return sortKeyA.localeCompare(sortKeyB);
  }

  return isPendingUser(a) ? 1 : -1;
};

type ViewerEngagementModalPropsType = {
  zeck: AnalyticsZeck;
  analyticsSummary: AnalyticsSummary;
  users: UserAndPermission[];
  zeckScrollGoLiveDate: Date;
  zeckViewGoLiveDate: Date;
  modal?: boolean;
  userSortedSectionsWithTotals: { [userId: string]: SectionWithTotals[] };
};

const ViewerEngagementModal: React.FC<ViewerEngagementModalPropsType> = ({
  zeck,
  analyticsSummary,
  zeckScrollGoLiveDate,
  zeckViewGoLiveDate,
  users,
  modal,
  userSortedSectionsWithTotals,
}) => {
  const { showAllAnalytics } = useFeatureFlags();
  const isMobile = useMediaQuery({ maxWidth: 736 });

  const hasEngagementData =
    showAllAnalytics ||
    zeckScrollGoLiveDate < new Date(zeck.firstPublishedAt ?? 0);

  const shouldRenderUser = (user: UserAndPermission) => {
    return user.role === 'viewer';
  };

  const filteredUsers = users.filter(shouldRenderUser).sort(sortUsers);

  if (!filteredUsers.length) {
    return (
      <AnalyticsEmptyModal
        title="This Zeck doesn’t have Viewers yet."
        body="Add People as Viewers in the Manage Viewers tab."
      />
    );
  }

  const maybeFullHeightClass = modal
    ? !hasEngagementData
      ? styles.viewerEngagementTable__withoutSummary
      : styles.viewerEngagementTable__modal
    : null;

  return (
    <div className={styles.viewerEngagementTable__wrapper}>
      {hasEngagementData && (
        <ViewerEngagementSummary summary={analyticsSummary.engagementSummary} />
      )}

      <Table
        data-testid="analytics-modal"
        className={cx([styles.viewerEngagementTable, maybeFullHeightClass])}
      >
        <TableHeaderCell className={styles.viewerEngagementTable__viewerHeader}>
          Viewers
        </TableHeaderCell>
        {!isMobile && <TableHeaderCell>Last Viewed</TableHeaderCell>}
        <TableHeaderCell alignRight={isMobile}>Status</TableHeaderCell>
        {!isMobile && <TableHeaderCell>Time Spent</TableHeaderCell>}
        {!isMobile && <TableHeaderCell>Comments</TableHeaderCell>}

        {filteredUsers.map((user) => {
          return (
            <AnalyticsRow
              key={`analytics-row-${user.id}`}
              user={user}
              view={analyticsSummary.getUserView(user.id)}
              zeck={zeck}
              isMobile={isMobile}
              secondsSpent={analyticsSummary.userTotals[user.id] ?? 0}
              commentCount={analyticsSummary.userCommentTotals[user.id] ?? 0}
              zeckScrollGoLiveDate={zeckScrollGoLiveDate}
              zeckViewGoLiveDate={zeckViewGoLiveDate}
              sortedSectionsWithTotals={
                userSortedSectionsWithTotals[user.id] || []
              }
            />
          );
        })}
      </Table>
    </div>
  );
};

export default ViewerEngagementModal;
