import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const ASSET_CHART_ARTIFACT_TYPE = 'chart-artifact';

export const AssetTypeSchema = z.literal(ASSET_CHART_ARTIFACT_TYPE);

export type AssetType = z.infer<typeof AssetTypeSchema>;

const AuthHeadersSchema = z.object({
  authorization: z.string().startsWith('Bearer '),
});

export const assetContract = c.router({
  create: {
    summary: 'Create an asset',
    method: 'POST',
    path: '/company/:companyId/asset/:assetType',
    pathParams: z.object({
      companyId: z.string().uuid(),
      assetType: AssetTypeSchema,
    }),
    responses: {
      201: z.object({
        assetId: z.string().uuid(),
        writeUrl: z.string(),
      }),
      401: c.type(),
      403: c.type(),
      404: c.type(),
      426: c.type(),
      500: c.type(),
    },
    body: z.object({}),
    headers: AuthHeadersSchema,
    strictStatusCodes: true,
  },
});
