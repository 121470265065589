import ReactModal from 'react-modal';
import styles from '../../previewAndPublish/SectionPreviewDrawer.module.scss';
import { Icon } from 'icons';
import React, { useEffect, useRef } from 'react';
import ReactModalWithScroll from '../../../../design-system/organisms/ReactModalWithScroll.js';
import useLinkDrawer, { LinkDrawerPreviewable } from './useLinkDrawer.js';
import scrollToTop from '../../../../junkDrawer/scrollToTop.js';

type LinkDrawerProps = {
  renderContent: (
    linkable: LinkDrawerPreviewable,
    scrollContainerRef: React.RefObject<HTMLDivElement>,
  ) => React.ReactNode;
  onScrollInDrawer?: (linkable: LinkDrawerPreviewable) => void;
  linkDrawerState: ReturnType<typeof useLinkDrawer>;
};

export const LinkDrawer = ({
  // rename to behavior
  linkDrawerState,
  renderContent,
  onScrollInDrawer,
}: LinkDrawerProps) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const { closeDrawer, drawerState } = linkDrawerState;

  useEffect(() => {
    switch (drawerState.type) {
      case 'closed':
        break;
      case 'open':
        if (scrollContainerRef.current) {
          scrollToTop(scrollContainerRef.current);
        }
        break;
    }
  }, [drawerState]);

  const getDrawerContent = (): React.ReactNode => {
    switch (drawerState.type) {
      case 'closed':
        return null;
      case 'open': {
        return renderContent(drawerState.content, scrollContainerRef);
      }
    }
  };

  const handleDrawerScroll = () => {
    drawerState.type === 'open' &&
      onScrollInDrawer &&
      onScrollInDrawer(drawerState.content);
  };

  return (
    <>
      <ReactModalWithScroll
        {...{
          testId: 'section-drawer',
          isOpen: drawerState.type === 'open',
          onRequestClose: closeDrawer,
          closeTimeoutMS: !import.meta.env.TEST ? 200 : undefined,
          className: {
            base: styles.drawerMenu,
            afterOpen: styles.drawerMenu_afterOpen,
            beforeClose: styles.drawerMenu_beforeClose,
          } as ReactModal.Classes,
          contentRef: (el) => {
            scrollContainerRef.current = el;
          },
          overlayClassName: {
            base: styles.drawerMenu__overlay,
            afterOpen: styles.drawerMenu__overlay_afterOpen,
            beforeClose: styles.drawerMenu__overlay_beforeClose,
          } as ReactModal.Classes,
          ariaHideApp: false,
        }}
        onScroll={handleDrawerScroll}
      >
        <button
          aria-label="close preview"
          className={styles.drawerMenu__close}
          onClick={closeDrawer}
        >
          <Icon name="close" />
        </button>
        {getDrawerContent()}
      </ReactModalWithScroll>
    </>
  );
};

export default LinkDrawer;
