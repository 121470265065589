import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function FilterIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(styles, props.className)}
    >
      <g id="mdi:filter-variant">
        <path d="M6 13H18V11H6M3 6V8H21V6M10 18H14V16H10V18Z" fill="#8F90A6" />
      </g>
    </svg>
  );
}
