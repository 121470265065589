import styles from './SectionAppearanceSidebar.module.scss';
import useSidebarAnimation from '../../../../services/useSidebarAnimation.ts';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';
import cx from 'classnames';
import { useState } from 'react';
import ImageUploadModal from '../image/ImageUploadModal.tsx';
import UploadedImage from '../../UploadedImage.tsx';
import { Company, CompanyLite, Section } from '../../../../types.ts';
import useToast from '../../../../design-system/molecules/toast/useToast.ts';
import Toast from '../../../../design-system/molecules/toast/Toast.tsx';

type SectionAppearanceSidebarProps = {
  company: Company | CompanyLite;
  section: Section;
  show: boolean;
  onClose: () => void;
  onChangeSection: (section: Partial<Section> & { id: string }) => void;
};

export default function SectionAppearanceSidebar({
  company,
  section,
  show,
  onClose,
  onChangeSection,
}: SectionAppearanceSidebarProps) {
  const [showingImageUpload, setShowingImageUpload] = useState(false);
  const { elementRef, show: showSidebar } = useSidebarAnimation<HTMLDivElement>(
    'right',
    show,
  );

  const { showToast, ...toast } = useToast();

  return (
    <>
      {showSidebar && (
        <div
          data-testid="section-appearance-sidebar"
          ref={elementRef}
          className={styles.sectionAppearanceSidebar}
        >
          <div className={styles.sectionAppearanceSidebar__header}>
            <IconButton
              aria-label="Close Sidebar"
              name="close"
              onClick={onClose}
              className={styles.sectionAppearanceSidebar__close}
            />
            Section Appearance
          </div>
          <div className={styles.sectionAppearanceSidebar__sectionCover}>
            <span>Section Cover</span>
            <SectionCoverToggle
              enabled={section.coverImageEnabled}
              onChange={() => {
                if (!section.coverImageId) {
                  setShowingImageUpload(true);
                } else {
                  onChangeSection({
                    id: section.id,
                    coverImageEnabled: !section.coverImageEnabled,
                  });
                }
              }}
            />

            {section.coverImageEnabled && section.coverImageId && (
              <div
                onClick={() => {
                  setShowingImageUpload(true);
                }}
                className={
                  styles.sectionAppearanceSidebar__sectionCover__imageContainer
                }
              >
                <UploadedImage
                  data-testid="section-cover-image"
                  imageId={section.coverImageId}
                  spinnerSize="medium"
                  imagePreset="thumbnail"
                  className={
                    styles.sectionAppearanceSidebar__sectionCover__image
                  }
                />
              </div>
            )}
          </div>
          <ImageUploadModal
            isOpen={showingImageUpload}
            company={company}
            onUploadSuccess={({ guid }) => {
              onChangeSection({
                id: section.id,
                coverImageId: guid,
                coverImageEnabled: true,
              });
              setShowingImageUpload(false);
            }}
            onClose={() => {
              setShowingImageUpload(false);
            }}
            instructions="Images should be at least 1024px by 768px."
            showToast={showToast}
          />
        </div>
      )}

      <Toast {...toast} />
    </>
  );
}

// TODO: Reuse Toggle component
type SectionCoverToggleProps = {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
};

function SectionCoverToggle({ enabled, onChange }: SectionCoverToggleProps) {
  return (
    <div
      className={cx(
        styles.sectionCoverToggle,
        enabled && styles.sectionCoverToggle_enabled,
      )}
    >
      <input
        type="checkbox"
        aria-label="upload section cover"
        className={styles.sectionCoverToggle__checkbox}
        checked={enabled}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
    </div>
  );
}
