import useApi from '../../../../../api/useApi.ts';
import { isSuccessResult } from '../../../../../result/Result.ts';
import { sequenceSResult } from '../../../../../result/sequenceSResult.ts';
import useFetch, { Result } from '../../../../../services/useFetch/useFetch.ts';
import { UserAndPermission, Zeck } from '../../../../../types.ts';
import { Comment } from '../../../../../types/Comment.ts';

import {
  AnalyticsSummary,
  HydratedEngagementTotal,
  UserEngagementTotal,
  ZeckUserView,
} from 'app/api/endpoints/createAnalyticsApi.ts';
import { AnalyticsPublishedSection } from '../AnalyticsTypes.ts';
import getUserZeckEngagementTotals from './getUserZeckEngagementTotals.ts';
import getViewerEngagementSummary from './getViewerEngagementSummary.ts';

const filterAndHydrateEngagementTotals = (
  engagementTotals: UserEngagementTotal[],
  users: UserAndPermission[],
) => {
  const totals: HydratedEngagementTotal[] = [];
  engagementTotals.forEach((total) => {
    const user = users.find((u) => u.id === total.userId && isValidViewer(u));
    if (user) {
      totals.push({
        ...total,
        user,
      });
    }
  });
  return totals;
};

const isValidViewer = (user: UserAndPermission) => user.role === 'viewer';

type UseAnalyticsProps = {
  users: UserAndPermission[];
  zeck: Readonly<
    Pick<Zeck, 'id' | 'companyId' | 'firstPublishedAt'> & {
      publishedZeck: {
        sections: AnalyticsPublishedSection[];
      } | null;
    }
  >;
};

const getCommentCountsPerUser = (
  comments: Comment[],
): Record<string, number> => {
  return comments.reduce((acc: Record<string, number>, comment) => {
    acc[comment.userId] = (acc[comment.userId] ?? 0) + 1;
    return acc;
  }, {});
};

export const generateAnalyticsSummary = (
  userEngagementTotals: UserEngagementTotal[],
  users: UserAndPermission[],
  zeckViews: ZeckUserView[],
  comments: Comment[],
): AnalyticsSummary => {
  const hyrdatedTotals = filterAndHydrateEngagementTotals(
    userEngagementTotals,
    users,
  );

  const filteredZeckViews = zeckViews.filter((view) =>
    users.find((u) => u.id === view.userId),
  );
  const { userTotals } = getUserZeckEngagementTotals(hyrdatedTotals);
  const validViewerCount = users.filter(isValidViewer).length;

  return {
    engagementTotals: hyrdatedTotals,
    userTotals,
    zeckViews: filteredZeckViews,
    engagementSummary: getViewerEngagementSummary(userTotals, validViewerCount),
    userCommentTotals: getCommentCountsPerUser(comments),
    getUserView(userId: string) {
      return filteredZeckViews.find((view) => view.userId === userId);
    },
  };
};

const useAnalyticsData = (
  { zeck, users }: Pick<UseAnalyticsProps, 'zeck' | 'users'>,
  commentsResult: Result<{ [sectionId: string]: Comment[] }>,
): AnalyticsSummary | null => {
  const api = useApi();

  const userEngagementResult = useFetch(() => {
    return api.getZeckEngagementTotals(zeck.id);
  }, [zeck.id]);

  const zeckViewResult = useFetch(() => {
    return api.getZeckAnalytics(zeck.id);
  }, [zeck.id]);

  const combinedResult = sequenceSResult({
    zeckViews: zeckViewResult,
    userEngagementTotals: userEngagementResult,
    commentsResult: commentsResult,
  });

  if (!isSuccessResult(combinedResult)) {
    return null;
  }
  const allComments = Object.values(combinedResult.data.commentsResult).flat();

  return generateAnalyticsSummary(
    combinedResult.data.userEngagementTotals,
    users,
    combinedResult.data.zeckViews,
    allComments,
  );
};

export default useAnalyticsData;
