import { CartaCapTable } from 'editor-content/CartaBlock.ts';
import { makeMakeApiRequest } from '../makeMakeApiRequest.js';

export type CartaIssuer = {
  id: string;
  legalName: string;
};

const createCartaApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  getCartaIssuers: async (credentialId: string): Promise<CartaIssuer[]> => {
    return await makeLambdaApiRequest(
      `/carta/issuers?credentialId=${credentialId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  },
  getCapTable: async (
    issuerId: string,
    credentialId: string,
  ): Promise<CartaCapTable> => {
    return await makeLambdaApiRequest(
      `/carta/issuer/${issuerId}/cap-table?credentialId=${credentialId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  },
});

export default createCartaApi;
