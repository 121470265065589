import { makeMakeApiRequest } from '../makeMakeApiRequest.js';
import ZeckFolder, { ZeckFolderWithCount } from '../../types/ZeckFolder.js';

const createZeckFolderApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createZeckFolder: async (
    companyId: string,
    folderName: string,
  ): Promise<ZeckFolder> => {
    return await makeLambdaApiRequest(`/company/${companyId}/zeck-folder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ name: folderName }),
    });
  },

  deleteZeckFolder: async (folderId: string): Promise<void> => {
    return await makeLambdaApiRequest(`/zeck-folder/${folderId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  updateZeckFolder: async (
    folderId: string,
    folderName: string,
  ): Promise<ZeckFolder> => {
    return await makeLambdaApiRequest(`/zeck-folder/${folderId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ name: folderName }),
    });
  },

  getZeckFolders: async (companyId: string): Promise<ZeckFolderWithCount[]> => {
    const { zeckFolders } = await makeLambdaApiRequest(
      `/company/${companyId}/zeck-folder`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return zeckFolders;
  },
});

export default createZeckFolderApi;
