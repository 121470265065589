import {
  sectionEditPath,
  zeckCoverEditPath,
} from '../../../../services/Paths.js';
import TableOfContentsList from './ui/TableOfContentsList.js';
import ZeckWithActions from '../zeckCover/ZeckWithActions.js';
import SectionWithActions from '../zeckCover/SectionWithActions.js';
import { getTitleFromSection } from '../../../../types/Section.js';
import SectionItem from './ui/SectionItem.js';
import TableOfContentsLayout from './ui/TableOfContentsLayout.js';
import {
  APPENDIX_CONTAINER_ID,
  SectionOrder,
  TABLE_OF_CONTENTS_CONTAINER_ID,
} from './useReorderSections.js';
import TableOfContentsCoverItem from './ui/TableOfContentsCoverItem.js';
import {
  SortableItem,
  TableOfContentsListSortable,
  WithSectionDragAndDrop,
} from './SectionDragAndDrop.js';
import SidebarHeading from '../../../../design-system/molecules/SidebarHeading.js';
import IconButton from '../../../../design-system/atoms/IconButton.js';
import React, { useState } from 'react';
import { useListScrollPosition } from './useListScrollPosition.ts';

type TableOfContentsProps = {
  zeck: ZeckWithActions;
  currentSection?: SectionWithActions;
  onClickDeleteSection: (section: SectionWithActions) => void;
  onRenameActiveSection: () => void;
};

const TableOfContentsSectionItem = React.forwardRef<
  HTMLAnchorElement,
  {
    section: SectionWithActions;
    companyId: string;
    userIsAdmin: boolean;
    onRenameActiveSection: () => void;
    onClickMoveToAppendix: () => void;
    active: boolean;
    onClickDeleteSection: (section: SectionWithActions) => void;
  }
>(
  (
    {
      section,
      onRenameActiveSection,
      onClickMoveToAppendix,
      onClickDeleteSection,
      companyId,
      userIsAdmin,
      active,
    },
    ref,
  ) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
      <SectionItem
        name={getTitleFromSection(section)}
        to={sectionEditPath(section, companyId)}
        active={active}
        onRenameActiveSection={onRenameActiveSection}
        onRenameSection={(title) => {
          section.actions.update({ title });
        }}
        actions={[
          userIsAdmin && {
            iconName: 'arrowDown',
            color: 'normal',
            onClick: onClickMoveToAppendix,
            content: 'Move to Appendix',
          },
          userIsAdmin && {
            iconName: 'trash',
            color: 'danger',
            onClick: () => {
              setIsLoading(true);
              onClickDeleteSection(section);
            },
            content: 'Delete',
          },
        ]}
        ref={ref}
        loading={isLoading}
      />
    );
  },
);

const AppendixSectionItem = React.forwardRef<
  HTMLAnchorElement,
  {
    section: SectionWithActions;
    companyId: string;
    userIsAdmin: boolean;
    onRenameActiveSection: () => void;
    onClickMoveToTableOfContents: () => void;
    active: boolean;
    onClickDeleteSection: (section: SectionWithActions) => void;
  }
>(
  (
    {
      section,
      onRenameActiveSection,
      onClickMoveToTableOfContents,
      onClickDeleteSection,
      companyId,
      userIsAdmin,
      active,
    },
    ref,
  ) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
      <SectionItem
        name={getTitleFromSection(section)}
        to={sectionEditPath(section, companyId)}
        active={active}
        onRenameActiveSection={onRenameActiveSection}
        onRenameSection={(title) => section.actions.update({ title })}
        actions={[
          userIsAdmin && {
            iconName: 'arrowUp',
            color: 'normal',
            onClick: onClickMoveToTableOfContents,
            content: 'Move to Table of Contents',
          },
          userIsAdmin && {
            iconName: 'trash',
            color: 'danger',
            onClick: () => {
              setIsLoading(true);
              onClickDeleteSection(section);
            },
            content: 'Delete',
          },
        ]}
        ref={ref}
        loading={isLoading}
      />
    );
  },
);

const TableOfContents: React.FC<TableOfContentsProps> = ({
  zeck,
  currentSection,
  onClickDeleteSection,
  onRenameActiveSection,
}) => {
  const onReorder = (sectionOrder: SectionOrder) => {
    zeck.actions.reorderSections([
      ...sectionOrder.tableOfContents.map((id) => ({
        id,
        supplemental: false,
      })),
      ...sectionOrder.appendix.map((id) => ({
        id,
        supplemental: true,
      })),
    ]);
  };

  const scrollSessionStorageKey = `sidebar_position_${zeck.id}`;
  const { listRef, activeListItemRef, listScrollCallback } =
    useListScrollPosition(scrollSessionStorageKey);

  return (
    <WithSectionDragAndDrop
      sections={zeck.sections}
      currentSection={currentSection}
      onReorder={onReorder}
    >
      {({ sectionOrder, moveToContainer }) => (
        <TableOfContentsLayout ref={listRef} onScroll={listScrollCallback}>
          <TableOfContentsList>
            <TableOfContentsCoverItem to={zeckCoverEditPath(zeck)} />
          </TableOfContentsList>
          <TableOfContentsListSortable
            items={sectionOrder.tableOfContents}
            containerId={TABLE_OF_CONTENTS_CONTAINER_ID}
            disabled={!zeck.isAdmin}
            title={
              <SidebarHeading>
                Table of Contents
                <IconButton
                  name="plus"
                  aria-label="add section"
                  onClick={() => zeck.actions.createSection(false)}
                />
              </SidebarHeading>
            }
          >
            {sectionOrder.tableOfContents.map((id) => {
              const section = zeck.sections.find(
                (section) => section.id === id,
              );
              if (!section) return null;

              const isActive =
                !!currentSection && currentSection.id === section.id;

              return (
                <SortableItem key={id} id={id}>
                  <TableOfContentsSectionItem
                    {...{
                      section,
                      userIsAdmin: zeck.isAdmin,
                      active: isActive,
                      onRenameActiveSection,
                      onClickMoveToAppendix: () =>
                        moveToContainer(id, APPENDIX_CONTAINER_ID),
                      companyId: zeck.companyId,
                      onClickDeleteSection,
                      ref: isActive ? activeListItemRef : null,
                    }}
                  />
                </SortableItem>
              );
            })}
          </TableOfContentsListSortable>
          <TableOfContentsListSortable
            items={sectionOrder.appendix}
            containerId={APPENDIX_CONTAINER_ID}
            disabled={!zeck.isAdmin}
            title={
              <SidebarHeading>
                Appendix
                <IconButton
                  name="plus"
                  aria-label="add section to appendix"
                  onClick={() => zeck.actions.createSection(true)}
                />
              </SidebarHeading>
            }
          >
            {sectionOrder.appendix.map((id) => {
              const section = zeck.sections.find(
                (section) => section.id === id,
              );
              if (!section) return null;

              const isActive =
                !!currentSection && currentSection.id === section.id;

              return (
                <SortableItem key={id} id={id}>
                  <AppendixSectionItem
                    {...{
                      section,
                      userIsAdmin: zeck.isAdmin,
                      active: isActive,
                      onRenameActiveSection,
                      onClickMoveToTableOfContents: () =>
                        moveToContainer(id, TABLE_OF_CONTENTS_CONTAINER_ID),
                      companyId: zeck.companyId,
                      onClickDeleteSection,
                      ref: isActive ? activeListItemRef : null,
                    }}
                  />
                </SortableItem>
              );
            })}
          </TableOfContentsListSortable>
        </TableOfContentsLayout>
      )}
    </WithSectionDragAndDrop>
  );
};

export default TableOfContents;
