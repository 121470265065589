import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function ChevronDownIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M7.41 8.38999L6 9.79999L12 15.8L18 9.79999L16.59 8.38999L12 12.97L7.41 8.38999Z"
        fill="black"
      />
    </svg>
  );
}
