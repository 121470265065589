import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function CommentIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M20 2H4C2.89 2 2 2.9 2 4V18C2 19.1 2.89 20 4 20H9L12 23L15 20H20C21.1 20 22 19.1 22 18V4C22 2.9 21.1 2 20 2ZM20 18H14.17L13.58 18.59L12 20.17L10.41 18.58L9.83 18H4V4H20V18Z"
        fill="black"
      />
    </svg>
  );
}
