export default function replaceBlocksAt<T>(
  content: T[],
  newBlocks: T[],
  mergeStartIndex: number,
  blocksToMerge: number,
): T[] {
  if (mergeStartIndex > content.length) {
    throw new Error('Bad Merge Index');
  }

  if (blocksToMerge < 0 || blocksToMerge > content.length) {
    throw new Error('Bad Blocks to merge size');
  }
  const beforeReplacement = content.slice(0, mergeStartIndex);
  const afterReplacement = content.slice(
    mergeStartIndex + blocksToMerge,
    content.length,
  );

  return beforeReplacement.concat(newBlocks).concat(afterReplacement);
}
