import debounce from 'lodash/debounce.js';
import { useMemo, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useDebounce<T extends (...args: any[]) => void>(
  fn: T,
  wait: number,
  maxWait?: number,
) {
  const fnRef = useRef<T>(fn);
  fnRef.current = fn;

  return useMemo(() => {
    const res = debounce(
      (...args: Parameters<T>) => {
        fnRef.current(...args);
      },
      wait,
      maxWait ? { maxWait } : {},
    );

    return {
      call: res,
      flush: res.flush,
      cancel: res.cancel,
    };
  }, [wait, maxWait]);
}
