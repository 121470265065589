import { Atom, atom, WritableAtom } from 'jotai';
import ContentSelection, {
  isCollapsed,
} from '../../../../../editor/selection/contentSelection/ContentSelection.js';

type UIState =
  | {
      type: 'not-selected';
    }
  | { type: 'selected'; currentSelection: ContentSelection }
  | {
      type: 'form-open';
      targetSelection: ContentSelection;
    };

export const createPublishedCommentSelectionUIStateAtom =
  (): PublishedCommentSelectionUIStateAtom => {
    const uiStateAtom = atom<UIState>({ type: 'not-selected' });

    return atom(() => ({
      shouldShowToolbar: atom((get) => get(uiStateAtom).type === 'selected'),
      shouldShowForm: atom((get) => get(uiStateAtom).type === 'form-open'),
      selection: atom((get) => {
        const uiState = get(uiStateAtom);

        switch (uiState.type) {
          case 'not-selected':
            return null;
          case 'selected':
            return uiState.currentSelection;
          case 'form-open':
            return uiState.targetSelection;
        }
      }),

      changeSelection: atom(
        null,
        (_get, set, newSelection: ContentSelection | null) => {
          set(uiStateAtom, (uiState) => {
            switch (uiState.type) {
              case 'not-selected':
              case 'selected': {
                if (!newSelection || isCollapsed(newSelection))
                  return { type: 'not-selected' };

                return { type: 'selected', currentSelection: newSelection };
              }
              case 'form-open':
                return uiState;
            }
          });
        },
      ),
      openCommentForm: atom(null, (_get, set) => {
        set(uiStateAtom, (uiState) => {
          switch (uiState.type) {
            case 'not-selected':
            case 'form-open':
              return uiState;
            case 'selected':
              return {
                type: 'form-open',
                targetSelection: uiState.currentSelection,
              };
          }
        });
      }),
      closeCommentForm: atom(null, (_get, set) => {
        set(uiStateAtom, (uiState) => {
          switch (uiState.type) {
            case 'not-selected':
            case 'selected':
              return uiState;
            case 'form-open':
              return { type: 'not-selected' };
          }
        });
      }),
    }));
  };

export type PublishedCommentSelectionUIStateAtom = Atom<{
  shouldShowToolbar: Atom<boolean>;
  shouldShowForm: Atom<boolean>;

  // formState: Atom<FormClosed | FormOpen<ContentSelection>>;
  selection: Atom<ContentSelection | null>;

  // actions / events
  changeSelection: WritableAtom<
    unknown,
    [selection: ContentSelection | null],
    void
  >;
  openCommentForm: WritableAtom<unknown, [], void>;
  closeCommentForm: WritableAtom<unknown, [], void>;
}>;
