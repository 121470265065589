import CreateModal from '../../design-system/molecules/CreateModal.tsx';

type CreateZeckModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { zeckName: string }) => Promise<void>;
};

const CreateZeckModal: React.FunctionComponent<CreateZeckModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  ...otherProps
}) => {
  const onSubmitWrapper = async (zeckName: string) => {
    await onSubmit({ zeckName });
  };

  return (
    <CreateModal
      {...{
        title: 'Give your Zeck a name',
        placeholder: 'My Board Meeting',
        isOpen,
        onClose,
        onSubmit: onSubmitWrapper,
        ...otherProps,
      }}
    />
  );
};

export default CreateZeckModal;
