import useApi from '../../api/useApi.ts';
import { useEffect, useState } from 'react';
import styles from './CompanySearch.module.scss';
import useDebounce from '../zeck/edit/useDebounce.ts';
import InputText from '../../design-system/atoms/InputText.tsx';
import cx from 'classnames';

type CompanyListItem = { id: string; name: string };

const CompanySearch: React.FC<{
  selectedCompanyId: string | null;
  onSelectCompany: (company: CompanyListItem) => void;
}> = ({ onSelectCompany, selectedCompanyId }) => {
  const { getCompaniesByName } = useApi();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState<CompanyListItem[]>([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    if (selectedCompanyId === null) {
      setSearchString('');
    }
  }, [selectedCompanyId]);

  const { call: doSearch } = useDebounce(async () => {
    if (searchString === '') {
      setCompanies([]);
    } else {
      setCompanies(await getCompaniesByName(searchString));
      setIsDropdownOpen(true);
    }

    setIsLoading(false);
  }, 300);

  return (
    <div>
      <InputText
        label="Company Name"
        value={searchString}
        onChange={(newName) => {
          setIsLoading(true);
          setSearchString(newName);
          doSearch();
        }}
        onFocus={() => {
          setIsDropdownOpen(true);
        }}
      />
      {isDropdownOpen && (
        <div
          className={cx([
            styles.companySearch__dropdown,

            companies.length > 0 && styles.companySearch__results,
          ])}
        >
          {companies.length === 0 && (
            <div
              style={{
                backgroundColor: 'white',
                cursor: 'initial',
                paddingTop: 8,
              }}
            >
              {searchString.length < 3
                ? 'Type 3 or more characters to search...'
                : isLoading
                  ? ' Loading...'
                  : 'No matching companies'}
            </div>
          )}
          {companies.map(({ id, name }) => (
            <button
              key={id}
              onClick={() => {
                setSearchString(name);
                setIsDropdownOpen(false);
                onSelectCompany({ id, name });
              }}
            >
              {name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CompanySearch;
