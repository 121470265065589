import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function LinkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.81818 7C7.81818 6.44772 8.2659 6 8.81818 6H18V15.1818C18 15.7341 17.5523 16.1818 17 16.1818C16.4477 16.1818 16 15.7341 16 15.1818V9.41421L8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071C6.90237 16.3166 6.90237 15.6834 7.29289 15.2929L14.5858 8H8.81818C8.2659 8 7.81818 7.55228 7.81818 7Z"
      />
    </svg>
  );
}
