import { createContext, useContext } from 'react';
import { UserAndCompany } from '../userAndCompany/FetchUserAndCompany.tsx';
import { CompanyPermissions, User } from '../types.ts';

export type UserInfo = {
  isAdmin: boolean;
  activeCompanyId: string;
  permissions: CompanyPermissions;
  user: User;
};

const serviceContext = createContext<UserInfo | null>(null);

type WithUserInfoProps = {
  userAndCompany: UserAndCompany;
  children: React.ReactNode;
};

const WithUserInfo: React.FunctionComponent<WithUserInfoProps> = ({
  userAndCompany,
  children,
}) => {
  const userInfo: UserInfo = {
    isAdmin: userAndCompany.activeCompany.role === 'admin',
    activeCompanyId: userAndCompany.activeCompany.id,
    permissions: userAndCompany.activeCompany.permissions,
    user: userAndCompany.user,
  };

  return (
    <serviceContext.Provider value={userInfo}>
      {children}
    </serviceContext.Provider>
  );
};

export default WithUserInfo;

export function useUserInfo() {
  return useContext(serviceContext);
}
