import DetectsOutsideClick from '../junkDrawer/DetectsOutsideClick.tsx';
import { useState } from 'react';
import Button from '../design-system/atoms/Button.tsx';
import TimePicker from './TimePicker.tsx';
import styles from './TimeInput.module.scss';
import PopupForm from './PopupForm.tsx';
import TimeZoneForm from './TimeZoneForm.tsx';
import { getSystemTimezone } from './timestamp.ts';

type TimeFormProps = {
  time: number | null;
  timeZone: string | null;
  onSubmit: (values: { time: number | null; timeZone: string }) => void;
  onCancel: () => void;
};

const TimeForm: React.FC<TimeFormProps> = ({
  time,
  timeZone,
  onSubmit,
  onCancel,
}) => {
  const defaultedTimeZone = timeZone || getSystemTimezone();

  const [uiState, setUiState] = useState<'picking-time' | 'picking-time-zone'>(
    'picking-time',
  );
  const [selectedTime, setSelectedTime] = useState<number>(time ?? 720);
  const [selectedTimeZone, setSelectedTimeZone] = useState(defaultedTimeZone);

  return (
    <DetectsOutsideClick onClick={onCancel}>
      {(ref) => {
        switch (uiState) {
          case 'picking-time':
            return (
              <PopupForm
                ref={ref}
                data-testid="time-picker"
                actions={
                  <>
                    <Button
                      onClick={() => {
                        setUiState('picking-time-zone');
                      }}
                      size="small"
                      color="secondary"
                    >
                      Time Zone
                    </Button>
                    <Button
                      disabled={!selectedTime}
                      onClick={() => {
                        onSubmit({
                          time: selectedTime,
                          timeZone: selectedTimeZone,
                        });
                      }}
                      size="small"
                      color="primary"
                    >
                      OK
                    </Button>
                  </>
                }
              >
                <TimePicker
                  time={selectedTime}
                  onChange={setSelectedTime}
                  className={styles.timePicker}
                />
              </PopupForm>
            );
          case 'picking-time-zone':
            return (
              <TimeZoneForm
                onSubmit={(newTimeZone) => {
                  setSelectedTimeZone(newTimeZone);
                  setUiState('picking-time');
                }}
                onCancel={() => {
                  setUiState('picking-time');
                }}
                timeZone={selectedTimeZone}
              />
            );
        }
      }}
    </DetectsOutsideClick>
  );
};

export default TimeForm;
