import { VideoBlock } from 'editor-content/Block.js';
import parseVideoUrl from './editor/addBlock/parseVideoUrl.ts';
import styles from './Video.module.scss';

type VideoProps = {
  className?: string;
  block: VideoBlock;
};

export default function Video({ block, className }: VideoProps) {
  const parsedVideoUrl = parseVideoUrl(block.url);

  if (parsedVideoUrl?.provider === 'vimeo') {
    return (
      <div id={block.id} className={className} data-testid="video-block">
        <iframe
          title="Video"
          className={styles.player}
          src={`https://player.vimeo.com/video/${parsedVideoUrl.videoId}?byline=0&portrait=0`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  } else if (parsedVideoUrl?.provider === 'youtube') {
    return (
      <div id={block.id} className={className} data-testid="video-block">
        <iframe
          title="Video"
          className={styles.player}
          src={`https://www.youtube.com/embed/${parsedVideoUrl.videoId}?${parsedVideoUrl.queryString}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  }

  return null;
}
