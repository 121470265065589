import { FileBlock } from 'editor-content/Block.js';
import { isTextSelection } from '../../../../editor/selection/TextSelection.js';
import cond from '../../../../junkDrawer/cond.js';
import guard from '../../../../junkDrawer/guard.js';
import { hydratedIsFileBlock } from './hydratedBlockGuards.js';
import identity from '../../../../junkDrawer/identity.js';
import { BodyStateSelected } from './BodyEditor.js';

export function replaceFile(
  initialState: BodyStateSelected,
  newFile: Pick<FileBlock, 'guid' | 'filename'>,
): BodyStateSelected {
  const { content, selection } = initialState;
  if (!isTextSelection(selection)) return initialState;

  return {
    content: content.map(
      cond(
        (a, i) => i === selection.index,
        guard(
          hydratedIsFileBlock,
          (block) => ({
            ...block,
            guid: newFile.guid,
            filename: newFile.filename,
          }),
          identity,
        ),
        identity,
      ),
    ),
    selection,
  };
}
