import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function EscKeyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M7.79901 16H12.4013V15.0554H8.89631V12.8288H12.1207V11.8878H8.89631V9.67188H12.3587V8.72727H7.79901V16ZM18.0265 11.8771C17.8064 11.0284 17.1423 10.4744 15.9633 10.4744C14.7311 10.4744 13.8575 11.1243 13.8575 12.0902C13.8575 12.8643 14.3262 13.3793 15.349 13.6065L16.2723 13.8089C16.7978 13.9261 17.0429 14.1605 17.0429 14.5014C17.0429 14.924 16.5919 15.2543 15.8958 15.2543C15.2602 15.2543 14.8518 14.9808 14.724 14.4446L13.6977 14.6009C13.8752 15.5668 14.6778 16.1101 15.9029 16.1101C17.2204 16.1101 18.1331 15.4105 18.1331 14.4233C18.1331 13.6527 17.643 13.1768 16.6416 12.946L15.7751 12.7472C15.175 12.6051 14.9157 12.4027 14.9193 12.0334C14.9157 11.6143 15.3703 11.3161 15.974 11.3161C16.6345 11.3161 16.9399 11.6818 17.0642 12.0476L18.0265 11.8771ZM21.7981 16.1101C23.0871 16.1101 23.9217 15.3359 24.0388 14.2741H23.0055C22.8705 14.8636 22.4124 15.2116 21.8052 15.2116C20.9067 15.2116 20.3279 14.4624 20.3279 13.2727C20.3279 12.1044 20.9174 11.3693 21.8052 11.3693C22.4799 11.3693 22.8918 11.7955 23.0055 12.3068H24.0388C23.9252 11.206 23.0268 10.4744 21.7874 10.4744C20.2498 10.4744 19.2555 11.6321 19.2555 13.2976C19.2555 14.9418 20.2143 16.1101 21.7981 16.1101Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="31" height="23" rx="3.5" stroke="white" />
    </svg>
  );
}
