import { Cell, ValueType } from 'exceljs';

const cellHasNoContentsDueToMerge = (
  cell: Cell,
  valueTypes: typeof ValueType,
) => {
  return cell.type === valueTypes.Merge;
};

export default cellHasNoContentsDueToMerge;
