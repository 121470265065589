import ContentSelection, {
  isCollapsed,
} from '../../selection/contentSelection/ContentSelection.js';
import splitTextNodesFromContentSelection from './splitTextNodesFromContentSelection.js';
import {
  Editor,
  NonHighlightTextNode,
  TextFormat,
  TextNode,
} from 'editor-content/TextNode.js';

function applyFormatToTextNode(
  format: TextFormat,
  node: Editor.Text,
): Editor.Text;
function applyFormatToTextNode(
  format: TextFormat,
  node: NonHighlightTextNode,
): NonHighlightTextNode;
function applyFormatToTextNode(format: TextFormat, node: TextNode): TextNode;
function applyFormatToTextNode(format: TextFormat, node: TextNode): TextNode {
  switch (node.type) {
    case 'text':
      return {
        ...node,
        format: {
          ...node.format,
          ...format,
        },
      };
    case 'external-link':
    case 'meeting-minutes-link':
    case 'section-link':
      return {
        ...node,
        content: node.content.map((node) =>
          applyFormatToTextNode(format, node),
        ),
      };
    case 'highlight':
      return {
        ...node,
        content: node.content.map((node) =>
          applyFormatToTextNode(format, node),
        ),
      };
  }
}

const formatTextNodes = (
  textNodes: TextNode[],
  offsets: ContentSelection,
  format: TextFormat,
): TextNode[] => {
  if (isCollapsed(offsets)) {
    return textNodes;
  }

  const [before, middle, after] = splitTextNodesFromContentSelection(
    textNodes,
    offsets,
  );

  const formattedMiddle: TextNode[] = middle.map((node) =>
    applyFormatToTextNode(format, node),
  );

  return [...before, ...formattedMiddle, ...after];
};

export default formatTextNodes;
