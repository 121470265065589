import styles from './TagMenu.module.scss';
import { useEffect, useRef } from 'react';
import cx from 'classnames';

type TagMenuListProps = {
  children: React.ReactNode;
  title: React.ReactNode;
};

export const TagMenuList: React.FC<TagMenuListProps> = ({
  children,
  title,
}) => {
  return (
    <div>
      <div className={styles.tagMenuTitle}>{title}</div>
      <div className={styles.tagMenu_buttonList}>{children}</div>
    </div>
  );
};

type TagMenuItemProps = {
  isSelected: boolean;
  onClick: () => void;
  children: React.ReactNode;
};

export const TagMenuItem: React.FC<TagMenuItemProps> = ({
  isSelected,
  onClick,
  children,
}) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isSelected && ref.current) {
      ref.current.scrollIntoView(false);
    }
  }, [isSelected]);

  return (
    <button
      ref={ref}
      className={cx(
        styles.tagMenu_button,
        isSelected && styles.tagMenu_button_selected,
      )}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      data-testid={isSelected ? 'tagMenu__item--selected' : 'tagMenu__item'}
    >
      {children}
    </button>
  );
};

type TagMenuProps = {
  children: React.ReactNode;
};

const TagMenu: React.FC<TagMenuProps> = ({ children }) => {
  return <div className={styles.tagMenu}>{children}</div>;
};

export default TagMenu;
