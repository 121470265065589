import { isTextBlock } from 'editor-content/Block.js';
import { isHighlight, TextNode } from 'editor-content/TextNode.js';
import { EditorContent } from '../../edit/useEditorState.js';
import removeHighlightFromTextNodes, {
  unrollHighlight,
} from '../BodyEditor/removeHighlightFromTextNodes.ts';

function removeHighlightFromContent(
  highlightId: string,
  content: EditorContent,
): EditorContent {
  const newBody = content.body.map((block) => {
    if (isTextBlock(block)) {
      return {
        ...block,
        content: removeHighlightFromTextNodes(block.content, highlightId),
      };
    }
    return block;
  });
  const newHeadline: TextNode[] = [];
  content.headline.forEach((textNode) => {
    if (isHighlight(textNode)) {
      const newNodes = unrollHighlight(highlightId, textNode);
      newHeadline.push(...newNodes);
    } else {
      newHeadline.push(textNode);
    }
  });

  return {
    title: content.title,
    headline: newHeadline,
    body: newBody,
  };
}

export default removeHighlightFromContent;
