import { PublishedSection } from 'api-client/types.ts';
import cx from 'classnames';
import React from 'react';
import useBreakpoints from '../../../../services/useBreakpoints.ts';
import styles from './PageWithCommentsSidebar.module.scss';
import { CommentsState } from './commentsSidebar/useComments/useComments.ts';
import CommentsSidebar from './commentsSidebar/CommentsSidebar.tsx';

type PageWithCommentsSidebarProps = {
  children: React.ReactNode;
  commentsState: CommentsState;
  publishedSections: PublishedSection[];
};

const PageWithCommentsSidebar: React.FC<PageWithCommentsSidebarProps> = ({
  children,
  commentsState,
  publishedSections,
}) => {
  const { isMobile, isTablet } = useBreakpoints();
  const isFullScreenComments = isMobile || isTablet;

  const sidebarClass = cx([
    styles.commentsWithSidebar__comments,
    commentsState?.sidebarOpen && styles.commentsWithSidebar__comments_open,
    isFullScreenComments && styles.commentsWithSidebar__comments_fullscreen,
  ]);

  return (
    <div className={styles.commentsWithSidebar__wrapper}>
      {/* whole frickin' app right here 👇*/}
      <div className={styles.commentsWithSidebar__document}>{children}</div>

      <div data-testid="comments-sidebar" className={sidebarClass}>
        {!!commentsState && commentsState.sidebarOpen && (
          <CommentsSidebar
            isFullscreenMode={isFullScreenComments}
            commentsState={commentsState}
            publishedSections={publishedSections}
          />
        )}
      </div>
    </div>
  );
};

export default PageWithCommentsSidebar;
