import pick from 'lodash/pick.js';
import {
  ExternalSelectionType,
  MAX_TABLE_COLUMNS,
  MAX_TABLE_ROWS,
  TableBlock,
  TableBlockCell,
  TableBlockCellFormat,
  TableBlockMerge,
  TableBlockRow,
} from 'editor-content/TableBlock.js';
import { Editor, Text, TextFormat } from 'editor-content/TextNode.js';
import normalizeTableData from '../normalizeTableData.js';

export type SheetExternalInfo = {
  documentName: string;
  documentId: string;
  selectionName: string;
  selectionId: number;
  selectionType: ExternalSelectionType;
};

const getPopulatedCellLength = (row: gapi.client.sheets.RowData): number => {
  if (!row.values) {
    return 0;
  }

  // get last index with formattedValue
  return row.values.reduce((prevMaxIndex, cell, i) => {
    if (cell.formattedValue) {
      return i + 1;
    }
    return prevMaxIndex;
  }, 0);
};

const getPopulatedSheetDimensions = (
  rows: gapi.client.sheets.RowData[],
): [numRows: number, numCols: number] => {
  const rowLengths = rows.map(getPopulatedCellLength);

  // get maximum populated row length
  const spreadsheetActualNumCols = rowLengths.reduce(
    (prevMaxLength, rowLength) => {
      if (rowLength > prevMaxLength) {
        return rowLength;
      }

      return prevMaxLength;
    },
    0,
  );

  // get index of last populated row
  const spreadsheetActualNumRows = rowLengths.reduce(
    (prevMaxIndex, rowLength, i) => {
      if (rowLength !== 0) {
        return i + 1;
      }

      return prevMaxIndex;
    },
    0,
  );

  return [spreadsheetActualNumRows, spreadsheetActualNumCols];
};

function createMergesFromGoogleSheet(
  sheet: gapi.client.sheets.Sheet,
): TableBlockMerge[] {
  if (!sheet.merges) return [];

  const sheetData = sheet?.data?.[0];

  // named ranges have these values, need to offset merged ranges by them
  const mergeRowOffset = sheetData?.startRow ?? 0;
  const mergeColumnOffset = sheetData?.startColumn ?? 0;

  return sheet.merges
    .filter(
      (
        merge,
      ): merge is Required<Omit<gapi.client.sheets.GridRange, 'sheetId'>> =>
        typeof merge.startColumnIndex !== 'undefined' &&
        typeof merge.endColumnIndex !== 'undefined' &&
        typeof merge.startRowIndex !== 'undefined' &&
        typeof merge.endRowIndex !== 'undefined',
    )
    .map((merge) => ({
      startColumn: merge.startColumnIndex - mergeColumnOffset,
      startRow: merge.startRowIndex - mergeRowOffset,
      columnSpan: merge.endColumnIndex - merge.startColumnIndex,
      rowSpan: merge.endRowIndex - merge.startRowIndex,
    }));
}

function getBorderType(
  border: gapi.client.sheets.Border | undefined,
): 'thin' | 'thick' | undefined {
  if (border?.style === 'SOLID_THICK' || border?.style === 'DOUBLE') {
    return 'thick';
  }

  if (!border?.style || border?.style === 'NONE') {
    return undefined;
  }

  return 'thin';
}

function createCellFormatFromGoogleCell(
  cell: gapi.client.sheets.CellData,
): TableBlockCellFormat | undefined {
  if (!cell.effectiveFormat) return;

  const alignHorizontal = getCellAlignHorizontal(
    cell.effectiveFormat.horizontalAlignment,
  );
  const alignVertical = getCellAlignVertical(
    cell.effectiveFormat.verticalAlignment,
  );
  const wrap = getCellWrap(cell.effectiveFormat.wrapStrategy);

  const border = {
    top: getBorderType(cell.effectiveFormat.borders?.top),
    bottom: getBorderType(cell.effectiveFormat.borders?.bottom),

    left: getBorderType(cell.effectiveFormat.borders?.left),
    right: getBorderType(cell.effectiveFormat.borders?.right),
  };

  return {
    ...alignHorizontal,
    ...alignVertical,
    ...wrap,
    border,
  };
}

function getCellWrap(
  googleWrap: string | undefined,
): Pick<TableBlockCellFormat, 'wrap'> {
  switch (googleWrap) {
    case 'WRAP': {
      return {
        wrap: 'wrap',
      };
    }
    case 'CLIP': {
      return {
        wrap: 'clip',
      };
    }
    case 'LEGACY_WRAP': {
      return {
        wrap: 'wrap',
      };
    }
    default: {
      return {
        wrap: 'clip',
      };
    }
  }
}

function getCellAlignHorizontal(
  googleAlignment: string | undefined,
): Pick<TableBlockCellFormat, 'alignHorizontal'> {
  switch (googleAlignment) {
    case 'CENTER': {
      return {
        alignHorizontal: 'center',
      };
    }
    case 'LEFT': {
      return {
        alignHorizontal: 'left',
      };
    }
    case 'RIGHT': {
      return {
        alignHorizontal: 'right',
      };
    }
    default: {
      return {
        alignHorizontal: 'left',
      };
    }
  }
}

function getCellAlignVertical(
  googleAlignment: string | undefined,
): Pick<TableBlockCellFormat, 'alignVertical'> {
  switch (googleAlignment) {
    case 'MIDDLE': {
      return {
        alignVertical: 'middle',
      };
    }
    case 'TOP': {
      return {
        alignVertical: 'top',
      };
    }
    case 'BOTTOM': {
      return {
        alignVertical: 'bottom',
      };
    }
    default: {
      return {
        alignVertical: 'bottom',
      };
    }
  }
}

const getTextNodeFromGoogleCell = (
  cell: gapi.client.sheets.CellData,
): Editor.Text[] => {
  const format: TextFormat = pick(cell.effectiveFormat?.textFormat, [
    'bold',
    'italic',
    'underline',
  ]);

  return [Text(cell?.formattedValue ?? '', format)];
};

const DEFAULT_COLUMN_WIDTH = 100;
const getColumnWidths = (gridData: gapi.client.sheets.GridData | undefined) => {
  const columnWidths: Record<number, number> = {};

  (gridData?.columnMetadata ?? []).forEach((column, i) => {
    if (column?.pixelSize && column?.pixelSize !== DEFAULT_COLUMN_WIDTH) {
      columnWidths[i] = column.pixelSize;
    }
  });

  return columnWidths;
};

const DEFAULT_ROW_HEIGHT = 21;
const getRowHeights = (gridData: gapi.client.sheets.GridData | undefined) => {
  const rowHeights: Record<number, number> = {};

  (gridData?.rowMetadata ?? []).forEach((row, i) => {
    if (row?.pixelSize && row?.pixelSize !== DEFAULT_ROW_HEIGHT) {
      rowHeights[i] = row.pixelSize;
    }
  });

  return rowHeights;
};

export default function createTableFromGoogleSheet(
  sheet: gapi.client.sheets.Sheet,
): TableBlock['data'] {
  const rawGoogleRows = sheet?.data?.[0]?.rowData;

  if (!rawGoogleRows) {
    throw new Error('unable to translate google sheet data');
  }

  const googleRows = rawGoogleRows.slice(0, MAX_TABLE_ROWS).map((row) => ({
    ...row,
    values: (row?.values ?? []).slice(0, MAX_TABLE_COLUMNS),
  })); // limit to 150 rows and 30 columns

  const [spreadsheetActualNumRows, spreadsheetActualNumCols] =
    getPopulatedSheetDimensions(googleRows);

  const rows: TableBlockRow[] = [];

  for (let j = 0; j < spreadsheetActualNumRows; j++) {
    const cells: TableBlockCell[] = [];
    const googleCells = googleRows[j]?.values || [];

    for (let i = 0; i < spreadsheetActualNumCols; i++) {
      const cell = googleCells[i];

      let cellContent = TableBlockCell([Text('')]);
      if (cell) {
        cellContent = TableBlockCell(
          getTextNodeFromGoogleCell(cell),
          createCellFormatFromGoogleCell(cell),
        );
      }

      cells.push(cellContent);
    }

    rows.push(TableBlockRow(cells));
  }

  const merges = createMergesFromGoogleSheet(sheet);

  return normalizeTableData({
    columnWidths: getColumnWidths(sheet?.data?.[0]),
    rowHeights: getRowHeights(sheet?.data?.[0]),
    rows,
    merges,
  });
}
