import { makeMakeApiRequest } from '../makeMakeApiRequest.js';
import {
  Company,
  CompanyLite,
  MaybePendingUser,
  UserAndPermission,
  Zeck,
  ZeckLite,
  Section,
} from '../../types.ts';
import hydrateZeck from './hydrateZeck.js';
import hydratePublishedZeck from './hydratePublishedZeck.js';
import { MeetingMinutes } from './createMeetingMinutesApi.ts';
import HydratedPublishedZeck from './types/HydratedPublishedZeck.ts';

export type UserViewPermissionMap = { [id: string]: boolean };

const createZeckApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createZeck: async ({
    company,
    name,
    folderId,
  }: {
    company: Company | CompanyLite;
    name: string;
    folderId?: string;
  }): Promise<Pick<Zeck, 'id' | 'name' | 'companyId'>> => {
    return await makeLambdaApiRequest(`/company/${company.id}/zeck`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ name, folderId }),
    });
  },

  duplicateZeck: async (
    company: { id: string },
    id: string,
    duplicatePermissions: boolean,
  ): Promise<void> => {
    return await makeLambdaApiRequest(
      `/company/${company.id}/zeck/${id}/duplicate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          duplicatePermissions,
        }),
      },
    );
  },

  trashZeck: async (companyId: string, zeckId: string): Promise<void> => {
    await makeLambdaApiRequest(`/company/${companyId}/trash-zeck`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ zeckId }),
    });
  },

  fetchZeck: async (id: string): Promise<Zeck> => {
    return hydrateZeck(
      await makeLambdaApiRequest(`/zeck/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    );
  },

  updateZeck: async (
    zeckId: string,
    zeckPatch: Partial<Zeck> | Partial<ZeckLite>,
  ): Promise<void> => {
    await makeLambdaApiRequest(`/zeck/${zeckId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(zeckPatch),
    });
  },

  getPossibleZeckViewers: async ({
    zeckId,
    cursor,
    getAllResults,
  }: {
    zeckId: string;
    cursor?: string | null;
    getAllResults?: boolean;
  }): Promise<{
    models: UserAndPermission[];
    pagination: { cursor: string | null };
  }> => {
    const maybeCursor = cursor ? `?cursor=${cursor}` : '';
    const maybePageSize = getAllResults ? '?pageSize=100000' : '';
    const { users, pagination } = await makeLambdaApiRequest(
      `/zeck/${zeckId}/possible_viewer${maybeCursor}${maybePageSize}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return { models: users, pagination };
  },

  updateZeckViewer: async (
    zeckId: string,
    userId: string,
    isViewer: boolean,
  ): Promise<UserAndPermission[]> => {
    const { users } = await makeLambdaApiRequest(
      `/zeck/${zeckId}/possible_viewer`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ userId, viewer: isViewer }),
      },
    );
    return users;
  },

  async getZeckViewers(zeckId: string): Promise<Array<MaybePendingUser>> {
    const { viewers } = await makeLambdaApiRequest(`/zeck/${zeckId}/viewer`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return viewers;
  },

  reorderSections: async (
    zeckId: string,
    sections: Pick<Section, 'id' | 'supplemental'>[],
    companyId: string,
  ): Promise<void> => {
    await makeLambdaApiRequest(
      `/company/${companyId}/zeck/${zeckId}/reorder-sections`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(sections),
      },
    );
  },

  publishZeck: async (id: string): Promise<HydratedPublishedZeck> => {
    return hydratePublishedZeck(
      await makeLambdaApiRequest(`/zeck/${id}/published-zeck`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    );
  },

  getZeckMeetingMinutes: async (zeckId: string): Promise<MeetingMinutes[]> => {
    const { meetingMinutes } = await makeLambdaApiRequest(
      `/zeck/${zeckId}/meeting-minutes`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return meetingMinutes;
  },

  listTrashedZecks: async (
    company: Pick<Company, 'id'>,
  ): Promise<ZeckLite[]> => {
    const { zecks } = await makeLambdaApiRequest(
      `/company/${company.id}/trash-zeck`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return zecks;
  },

  restoreZeck: async (
    companyId: string,
    zeckId: string,
  ): Promise<ZeckLite[]> => {
    const { zecks } = await makeLambdaApiRequest(
      `/company/${companyId}/trash-zeck/${zeckId}/restore`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return zecks;
  },

  removeZeckFromTrash: async (
    companyId: string,
    zeckId: string,
  ): Promise<ZeckLite[]> => {
    const { zecks } = await makeLambdaApiRequest(
      `/company/${companyId}/trash-zeck/${zeckId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return zecks;
  },

  listZecks: async (
    company: Pick<Company, 'id'>,
    folderId?: string,
    showAll?: boolean,
  ): Promise<ZeckLite[]> => {
    const queryString = showAll
      ? '?showAll=true'
      : folderId
        ? `?folderId=${folderId}`
        : '';

    const { zecks } = await makeLambdaApiRequest(
      `/company/${company.id}/zeck${queryString}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return zecks;
  },
});

export default createZeckApi;
