import ReactSelect, {
  DropdownIndicatorProps,
  components,
  SingleValue,
} from 'react-select';
import styles from './Dropdown.module.scss';
import { Icon } from 'icons';
import { cx } from 'styling';

type Option = {
  value: string;
  label: string;
};

export type DropdownProps<ValueType extends string> = {
  options: Option[];
  value: ValueType | undefined;
  onChange: (val: ValueType) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  placeholder: string;
};

const DropdownIndicator = (props: DropdownIndicatorProps<Option, false>) => {
  return (
    <>
      <components.DropdownIndicator {...props}>
        <Icon name="chevronDown" className={styles.dropdown__arrow} />
      </components.DropdownIndicator>
    </>
  );
};

function Dropdown<ValueType extends string>({
  options,
  value,
  onChange,
  onMenuOpen,
  onMenuClose,
  placeholder,
  ...props
}: DropdownProps<ValueType>) {
  return (
    <div className={styles.dropdown__root} {...props}>
      <ReactSelect<Option>
        isMulti={false}
        isSearchable={false}
        options={options}
        onChange={(event: SingleValue<Option>) =>
          onChange(event?.value as ValueType)
        }
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        value={options.find((option) => option.value === value) || null}
        placeholder={placeholder}
        classNamePrefix={'dropdown'}
        classNames={{
          control: () => cx(styles.dropdown__control),
          placeholder: () => cx(styles.dropdown__placeholder),
          menu: () => cx(styles.dropdown__menu),
          container: () => cx(styles.dropdown__root),
          dropdownIndicator: () => cx(styles.dropdown__arrow),
          singleValue: () => cx(styles.dropdown__singleValue),
          option: (props) =>
            cx(
              styles.dropdown_option,
              (props.isSelected || props.isFocused) &&
                styles.dropdown__option_selected,
            ),
          indicatorSeparator: () => cx(styles.dropdown__indicatorSeparator),
          input: () => cx(styles.dropdown__input),
          valueContainer: () => cx(styles.dropdown__valueContainer),
          menuList: () => cx(styles.dropdown__menuList),
        }}
        components={{
          DropdownIndicator,
        }}
      />
    </div>
  );
}

export default Dropdown;
