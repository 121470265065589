import cx from 'classnames';
import React, { ReactElement } from 'react';
import IconLink from '../../../../../design-system/atoms/IconLink.js';
import { sectionPublishPath } from '../../../../../services/Paths.js';
import styles from './SectionAnalyticsRow.module.scss';
import { TableCell } from '../Table.js';
import { SectionTotalResult } from '../util/getSectionTotals.js';

const ContentEngagementPercent: React.FC<{
  widthMultiplier: number;
  children: React.ReactNode;
  isMobile: boolean;
}> = ({ widthMultiplier, isMobile, children }) => {
  if (isMobile) {
    return (
      <div className={styles.sectionTotal__percentLabel__mobile}>
        <div>{children}</div>
      </div>
    );
  }

  return (
    <div className={styles.sectionTotal__barWrap}>
      <div
        className={styles.sectionTotal__bar}
        data-width={widthMultiplier.toFixed(3)}
        style={{
          width: `calc(${widthMultiplier} * (100% - 58px))`,
        }}
        data-testid={`row-bar`}
      />
      <div
        className={styles.sectionTotal__percentLabel}
        style={{
          left: `${widthMultiplier}%`,
          marginLeft: widthMultiplier > 0 ? '8px' : 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export type SectionRowProps = {
  companyId: string;
  isMobile: boolean;
  sectionWithTotal: {
    total: SectionTotalResult;
    id: string;
    sectionId: string;
    title: string;
    commentCount: number;
  };
  overallTotalTime: number;
  compactStyle?: boolean;
};

export function SectionAnalyticsRow({
  companyId,
  isMobile,
  sectionWithTotal,
  overallTotalTime,
  compactStyle = false,
}: SectionRowProps): ReactElement<SectionRowProps> {
  const widthMultiplier = sectionWithTotal.total.totalTime / overallTotalTime;
  return (
    <div
      className={cx(
        styles.sectionAnalyticsRow,
        compactStyle && styles.sectionAnalyticsRow_compact,
      )}
      key={sectionWithTotal.id}
    >
      <TableCell
        className={cx(styles.engagementModal__sectionTitleCell)}
        data-testid={`row-title`}
      >
        <div className={styles.engagementModal__sectionTitle}>
          {sectionWithTotal.title}
        </div>
        <IconLink
          name="goTo"
          aria-label="go to site"
          href={sectionPublishPath(sectionWithTotal, companyId)}
          target="_blank"
          className={styles.engagementModal__sectionLink}
        />
      </TableCell>
      <TableCell
        className={styles.sectionTotal__percentCell}
        data-testid={`row-bar-with-label-${sectionWithTotal.sectionId}`}
      >
        <ContentEngagementPercent
          widthMultiplier={widthMultiplier}
          isMobile={isMobile}
        >
          {sectionWithTotal.total.percentOfTotal}
        </ContentEngagementPercent>
      </TableCell>
      {!isMobile && (
        <TableCell className={styles.sectionTotal__commentCountCell}>
          {sectionWithTotal.commentCount ?? 0}
        </TableCell>
      )}
    </div>
  );
}
