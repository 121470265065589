// TODO: Find a better name/directory for this

import BaseTextBlock from 'editor-content/BaseTextBlock.js';
import { getTextNodeAtOffset } from '../../../pages/zeck/editor/textNode/TextNode.js';
import ContentSelection, {
  isRightToLeftContentSelection,
} from './ContentSelection.js';

export function getSelectionFormat(
  block: BaseTextBlock,
  selection: ContentSelection | null,
) {
  if (!selection) {
    return {};
  }

  if (isRightToLeftContentSelection(selection)) {
    return (
      getTextNodeAtOffset(block.content, selection.anchorOffset - 1)?.format ||
      {}
    );
  }

  return (
    getTextNodeAtOffset(block.content, selection.anchorOffset)?.format || {}
  );
}
