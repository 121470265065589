import { ZeckFolderWithCount } from 'app/types/ZeckFolder.ts';
import styles from './FolderCardComponent.module.scss';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { zeckFolderPath } from '../../../services/Paths.ts';
import { ReactComponent as FolderIcon } from '../../../images/Folder40x40.svg';

const ZeckFolderComponent = ({ folder }: { folder: ZeckFolderWithCount }) => {
  const navigate = useNavigate();

  const navigateToFolder = useCallback(() => {
    navigate(zeckFolderPath(folder));
  }, [folder, navigate]);

  return (
    <div className={styles.folder__card} onClick={navigateToFolder}>
      <div>
        <FolderIcon className={styles.folder__icon} />
      </div>
      <div className={styles.folder_textContent}>
        <div className={styles.folder__name}>{folder.name}</div>
        <div className={styles.folder_zeckCount}>
          {folder.zeckCount} {folder.zeckCount === 1 ? 'Zeck' : 'Zecks'}
        </div>
      </div>
    </div>
  );
};

export default ZeckFolderComponent;
