import { HydratedEngagementTotal } from '../../../../../api/endpoints/createAnalyticsApi.js';

const getUserZeckEngagementTotals = (
  totalsPerSection: HydratedEngagementTotal[],
) => {
  const userMap: { [key: string]: number } = {};

  totalsPerSection.forEach((total) => {
    if (!userMap[total.userId]) {
      userMap[total.userId] = 0;
    }
    userMap[total.userId] = (userMap[total.userId] ?? 0) + total.seconds;
  });

  return {
    userTotals: userMap,
  };
};

export default getUserZeckEngagementTotals;
