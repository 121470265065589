import { useState } from 'react';
import styles from './ColorPickerDialog.module.scss';
import { HexColorPicker } from 'react-colorful';
import OutlineButton from '../atoms/OutlineButton.tsx';
import Button from '../atoms/Button.tsx';

function ColorPickerDialog({
  initialColor,
  onSubmit,
  onCancel,
}: {
  initialColor: string;
  onSubmit: (color: string) => void;
  onCancel: () => void;
}) {
  const [color, setColor] = useState(initialColor);

  return (
    <div className={styles.colorPickerDialog}>
      <div className={styles.colorPickerDialog__inputRow}>
        <span
          className={styles.colorPickerDialog__colorDisplay}
          style={{ backgroundColor: color }}
        />
        <label className={styles.colorPickerDialog__inputLabel}>
          Hex
          <input
            className={styles.colorPickerDialog__input}
            type="text"
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
          />
        </label>
      </div>
      <HexColorPicker
        color={color}
        onChange={(c) => {
          setColor(c);
        }}
      />
      <div className={styles.colorPickerDialog__controls}>
        <OutlineButton size={'medium'} onClick={onCancel} color="secondary">
          Cancel
        </OutlineButton>
        <Button
          size={'medium'}
          color={'primary'}
          onClick={() => {
            onSubmit(color);
          }}
        >
          Done
        </Button>
      </div>
    </div>
  );
}

export default ColorPickerDialog;
