import { logError } from '../../logging/logger.js';

const logRecord: Record<string, boolean> = {};

const logErrorOnce = (identifier: string, message: string) => {
  if (logRecord[identifier]) return;

  logError(`${identifier}: ${message}`);

  logRecord[identifier] = true;
};

export default logErrorOnce;
