import BrandKitTile from '../../design-system/molecules/BrandKitTile.tsx';
import { useState } from 'react';
import ImageUploadModal from '../zeck/edit/image/ImageUploadModal.tsx';
import UploadedImage from '../zeck/UploadedImage.tsx';
import styles from './BrandLogoTile.module.scss';
import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../design-system/organisms/Modal.tsx';
import OutlineButton from '../../design-system/atoms/OutlineButton.tsx';
import Button from '../../design-system/atoms/Button.tsx';
import { Company, CompanyLite } from '../../types.ts';
import useToast from '../../design-system/molecules/toast/useToast.ts';
import Toast from '../../design-system/molecules/toast/Toast.tsx';

export type BrandLogoTileProps = {
  company: Company | CompanyLite;
  brandLogoId: string | null;
  onUpdateBrandLogo: (imageId: string) => void;
  onDeleteBrandLogo: () => void;
};

export default function BrandLogoTile({
  company,
  brandLogoId,
  onUpdateBrandLogo,
  onDeleteBrandLogo,
}: BrandLogoTileProps) {
  const [showUpload, setShowUpload] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  function cancelDelete() {
    setShowConfirmDelete(false);
  }

  function confirmDelete() {
    setShowConfirmDelete(false);
    onDeleteBrandLogo();
  }

  const { showToast, ...toast } = useToast();

  return (
    <>
      <BrandKitTile
        data={brandLogoId}
        label="Primary Logo"
        onClick={() => setShowUpload(true)}
        onClickDelete={() => {
          setShowConfirmDelete(true);
        }}
      >
        {brandLogoId && (
          <UploadedImage
            imageId={brandLogoId}
            spinnerSize="medium"
            alt="brand logo"
            imagePreset="thumbnail"
            className={styles.image}
          />
        )}
      </BrandKitTile>

      <ImageUploadModal
        company={company}
        onUploadSuccess={({ guid }) => {
          setShowUpload(false);
          onUpdateBrandLogo(guid);
        }}
        onClose={() => setShowUpload(false)}
        showToast={showToast}
        isOpen={showUpload}
      />

      <Modal isOpen={showConfirmDelete} onRequestClose={cancelDelete}>
        <ModalHeader onClickClose={cancelDelete}>
          Delete Primary Logo?
        </ModalHeader>
        <ModalBody>
          Deleting the primary logo will remove it from all of your Zecks.
        </ModalBody>
        <ModalActions>
          <OutlineButton size="medium" onClick={cancelDelete} color="secondary">
            Cancel
          </OutlineButton>
          <Button size="medium" color="primary" onClick={confirmDelete}>
            Yes, Delete
          </Button>
        </ModalActions>
      </Modal>

      <Toast {...toast} />
    </>
  );
}
