import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function CloudIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="53"
      height="39"
      viewBox="0 0 53 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M13.6663 38.1667C10.1275 38.1667 7.10423 36.9417 4.59667 34.4917C2.08756 32.0417 0.833008 29.0473 0.833008 25.5084C0.833008 22.475 1.7469 19.7723 3.57467 17.4C5.40245 15.0278 7.79412 13.5112 10.7497 12.85C11.7219 9.27226 13.6663 6.37504 16.583 4.15837C19.4997 1.94171 22.8052 0.833374 26.4997 0.833374C31.0497 0.833374 34.909 2.41771 38.0777 5.58637C41.2479 8.7566 42.833 12.6167 42.833 17.1667C45.5163 17.4778 47.7431 18.6344 49.5133 20.6364C51.282 22.6399 52.1663 24.9834 52.1663 27.6667C52.1663 30.5834 51.1459 33.0629 49.105 35.1054C47.0626 37.1463 44.583 38.1667 41.6663 38.1667H13.6663Z"
        fill="#FAFAFC"
      />
    </svg>
  );
}
