import { Block } from 'editor-content/Block.js';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import { Editor } from 'editor-content/TextNode.js';
import { useState } from 'react';
import { v4 } from 'uuid';
import { isTextSelection } from '../../../../editor/selection/TextSelection.js';
import { EditorContent } from '../../edit/useEditorState.js';
import ZeckEditor from '../ZeckEditor/ZeckEditor.js';
import ZeckEditorSelection, {
  isBodySelection,
  isTitleSelection,
  ZeckBodyTextSelection,
  ZeckHeadlineSelection,
} from '../ZeckEditorSelection.js';
import getSelectionFromContent from '../getSelectionFromContent.js';
import SelectionCommentWithActions from './SelectionCommentWithActions.js';
import { TRANSIENT_HIGHLIGHT_ID } from './TransientHighlights.ts';
import getCurrentHighlightId from './getCurrentHighlightId.ts';
import removeHighlightFromContent from './removeHighlightFromContent.js';

export type SelectionCommentsBehavior = {
  isAddingNewComment: boolean;
  startNewComment: () => void;
  closeCommentMenu: () => void;
  addNewComment: (content: CommentContentNode[]) => Promise<void>;
  addCommentToThread: (
    highlightId: string,
    content: CommentContentNode[],
  ) => Promise<void>;
  selectionComments: SelectionCommentWithActions[];
  transientHighlightSelection:
    | ZeckHeadlineSelection
    | ZeckBodyTextSelection
    | null;
  currentHighlightIds: string[];
};

export type TransientHighlightSelection =
  | ZeckHeadlineSelection
  | ZeckBodyTextSelection;

type UIState =
  | { type: 'not-commenting' }
  | {
      type: 'commenting';
      selection: TransientHighlightSelection;
    };

const useSelectionComments = ({
  editorState,
  setEditorState,
  selectionComments,
  createSelectionComment,
}: {
  editorState: {
    content: EditorContent;
    selection: ZeckEditorSelection;
  };
  setEditorState: (editorState: {
    content: EditorContent;
    selection: ZeckEditorSelection;
  }) => void;
  selectionComments: SelectionCommentWithActions[];
  createSelectionComment: (
    highlightId: string,
    content: CommentContentNode[],
    selection: Block | Editor.Highlight,
  ) => Promise<void>;
}): SelectionCommentsBehavior => {
  const [uiState, setUIState] = useState<UIState>({ type: 'not-commenting' });

  const closeCommentMenu = () => {
    setUIState({ type: 'not-commenting' });
  };

  const startNewComment = () => {
    const selection = editorState.selection;

    if (!selection) return;
    if (isTitleSelection(selection)) return;
    if (isBodySelection(selection) && !isTextSelection(selection)) return;

    setEditorState({
      content: editorState.content,
      selection: null,
    });
    setUIState({ type: 'commenting', selection });
  };

  const createHighlightWithComment = async (content: CommentContentNode[]) => {
    const highlightId = v4();
    if (uiState.type !== 'commenting') return;
    const newContent = ZeckEditor.addHighlight(
      editorState.content,
      uiState.selection,
      highlightId,
    );

    if (!newContent) return;

    const selection = getSelectionFromContent(newContent.content, highlightId);

    if (!selection) return;

    await createSelectionComment(highlightId, content, selection);
    setEditorState(newContent);
    setUIState({ type: 'not-commenting' });
  };

  const addSelectionCommentToExistingHighlight = async (
    highlightId: string,
    content: CommentContentNode[],
  ) => {
    const selection = getSelectionFromContent(editorState.content, highlightId);

    if (!selection) {
      return;
    }

    await createSelectionComment(highlightId, content, selection);
  };

  const selectionCommentsWithDelete =
    selectionComments.map<SelectionCommentWithActions>((selectionComment) => ({
      ...selectionComment,
      actions: {
        ...selectionComment.actions,
        async remove() {
          const isLastComment =
            selectionComments.filter(
              ({ selectionId }) => selectionComment.selectionId === selectionId,
            ).length === 1;

          if (isLastComment) {
            setEditorState({
              content: removeHighlightFromContent(
                selectionComment.selectionId,
                editorState.content,
              ),
              selection: editorState.selection,
            });
          } else {
            await selectionComment.actions.remove();
          }
        },
      },
    }));

  const existingHighlightIds = getCurrentHighlightId(
    editorState.content,
    editorState.selection,
  );
  const highlightIds =
    uiState.type === 'commenting'
      ? [TRANSIENT_HIGHLIGHT_ID, ...existingHighlightIds]
      : existingHighlightIds;

  return {
    isAddingNewComment: uiState.type === 'commenting',
    closeCommentMenu,
    startNewComment,
    addNewComment: createHighlightWithComment,
    addCommentToThread: addSelectionCommentToExistingHighlight,
    currentHighlightIds: highlightIds,
    selectionComments: selectionCommentsWithDelete,
    transientHighlightSelection:
      uiState.type === 'commenting' ? uiState.selection : null,
  };
};

export default useSelectionComments;
