import Point from '../Point.js';

export type ViewportPolicy = 'none' | 'position' | 'position-x' | 'position-y';

export const withViewportPositionPolicy =
  (
    policy: ViewportPolicy,
    viewportRect: { width: number; height: number },
    viewportPadding: number,
  ) =>
  (
    containerRect: {
      width: number;
      height: number;
    },
    containerPos: Point,
  ): Point => {
    const x = containerPos[0];
    const y = containerPos[1];
    const containedX = Math.max(
      viewportPadding,
      Math.min(x, viewportRect.width - containerRect.width - viewportPadding),
    );
    const containedY = Math.max(
      viewportPadding,
      Math.min(y, viewportRect.height - containerRect.height - viewportPadding),
    );

    switch (policy) {
      case 'none':
        return [x, y];
      case 'position':
        return [containedX, containedY];
      case 'position-x':
        return [containedX, y];
      case 'position-y':
        return [x, containedY];
    }
  };
