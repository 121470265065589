import { Block, isTextBlock, TextBlock } from 'editor-content/Block.js';
import Linkable from 'editor-content/html/Linkable.ts';
import { Editor, TextFormat } from 'editor-content/TextNode.js';
import TextFormattingMenu from '../../../../design-system/organisms/TextFormattingMenu.tsx';
import { ToolbarGroup } from '../../../../design-system/organisms/Toolbar.tsx';
import ContentSelection, {
  isCollapsed,
} from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import { getSelectionFormat } from '../../../../editor/selection/contentSelection/getSelectionFormat.ts';
import { SelectionCommentsBehavior } from '../comments/SelectionCommentsBehavior.ts';
import createZeckEditorTurnIntoables from './createZeckEditorTurnIntoables.ts';
import {
  BoldButton,
  ItalicsButton,
  UnderlineButton,
} from './FormatTextButton.tsx';
import IndentFormattingActions from './IndentFormattingActions.tsx';

type TextSelectionFormattingMenuProps = {
  block: Block;
  selection: ContentSelection | null;
  onIndent: (amount: -1 | 1) => void;
  linkables: Linkable[];
  onToggleFormat: (formatName: keyof TextFormat) => void;
  onAddLink: (link: Editor.LinkType) => void;
  onTurnInto(type: TextBlock['type']): void;
  selectionCommentsBehavior: Pick<
    SelectionCommentsBehavior,
    'startNewComment' | 'isAddingNewComment'
  >;
};

const TextSelectionFormattingMenu: React.FC<
  TextSelectionFormattingMenuProps
> = ({
  block,
  selection,
  onIndent,
  linkables,
  onAddLink,
  selectionCommentsBehavior,
  onTurnInto,
  onToggleFormat,
}) => {
  if (!isTextBlock(block)) return null;
  if (!selection || isCollapsed(selection)) return null;
  if (selectionCommentsBehavior.isAddingNewComment) return null;

  const format = getSelectionFormat(block, selection);
  const turnIntoables = createZeckEditorTurnIntoables(onTurnInto);

  switch (block.type) {
    case 'paragraph': {
      return (
        <TextFormattingMenu
          linkables={linkables}
          onAddLink={onAddLink}
          onClickAddComment={selectionCommentsBehavior.startNewComment}
          blockDisplayName={'text'}
          turnIntoables={turnIntoables}
          otherActions={
            <ToolbarGroup>
              <BoldButton {...{ format, onToggleFormat }} />
              <ItalicsButton {...{ format, onToggleFormat }} />
              <UnderlineButton {...{ format, onToggleFormat }} />
            </ToolbarGroup>
          }
        />
      );
    }
    case 'label': {
      return (
        <TextFormattingMenu
          linkables={linkables}
          onAddLink={undefined}
          onClickAddComment={selectionCommentsBehavior.startNewComment}
          blockDisplayName={'label'}
          turnIntoables={turnIntoables}
          otherActions={null}
        />
      );
    }
    case 'bulleted-list-item': {
      return (
        <TextFormattingMenu
          linkables={linkables}
          onAddLink={onAddLink}
          onClickAddComment={selectionCommentsBehavior.startNewComment}
          blockDisplayName={'bulleted list'}
          turnIntoables={turnIntoables}
          otherActions={
            <>
              <ToolbarGroup>
                <BoldButton {...{ format, onToggleFormat }} />
                <ItalicsButton {...{ format, onToggleFormat }} />
                <UnderlineButton {...{ format, onToggleFormat }} />
              </ToolbarGroup>
              <ToolbarGroup>
                <IndentFormattingActions value={block} onIndent={onIndent} />
              </ToolbarGroup>
            </>
          }
        />
      );
    }
    case 'numbered-list-item': {
      return (
        <TextFormattingMenu
          linkables={linkables}
          onAddLink={onAddLink}
          blockDisplayName={'numbered list'}
          onClickAddComment={selectionCommentsBehavior.startNewComment}
          turnIntoables={turnIntoables}
          otherActions={
            <>
              <ToolbarGroup>
                <BoldButton {...{ format, onToggleFormat }} />
                <ItalicsButton {...{ format, onToggleFormat }} />
                <UnderlineButton {...{ format, onToggleFormat }} />
              </ToolbarGroup>
              <ToolbarGroup>
                <IndentFormattingActions value={block} onIndent={onIndent} />
              </ToolbarGroup>
            </>
          }
        />
      );
    }
    case 'heading': {
      return (
        <TextFormattingMenu
          linkables={linkables}
          onAddLink={onAddLink}
          blockDisplayName={'heading'}
          onClickAddComment={selectionCommentsBehavior.startNewComment}
          turnIntoables={turnIntoables}
          otherActions={
            <>
              <ToolbarGroup>
                <ItalicsButton {...{ format, onToggleFormat }} />
                <UnderlineButton {...{ format, onToggleFormat }} />
              </ToolbarGroup>
            </>
          }
        />
      );
    }
  }
};

export default TextSelectionFormattingMenu;
