import { createParagraphBlock, ParagraphBlock } from '../Block.js';
import { createHighlight, Text, TextNode } from '../TextNode.js';
import { VoteType } from '../VoteBlock.js';
import { VoteTemplateData } from './VoteTemplateData.js';

function generateVoteDisclaimer() {
  return createParagraphBlock([
    createHighlight([
      Text(
        '[**Your resolution may require additional language. We recommend you consult your legal advisor.**]',
      ),
    ]),
  ]);
}

const generateVoteApprovalSnippet = (
  approved: boolean | undefined,
  approvedText = 'approved',
  didNotApproveText = 'did not approve',
  prefixText = 'unanimously',
): TextNode[] => {
  const prefixString = prefixText ? `${prefixText} ` : '';

  if (approved !== undefined) {
    return [
      createHighlight([Text(prefixString)]),
      Text(approved ? approvedText : didNotApproveText),
    ];
  }

  return [
    createHighlight([
      Text(`${prefixString}[${approvedText}]/[${didNotApproveText}]`),
    ]),
  ];
};

const generateTitleAndDetails = (vote: VoteTemplateData) => {
  if (!vote.title) return [];
  return [
    createParagraphBlock([Text(vote.title, { bold: true })]),
    createParagraphBlock([Text(vote.details)]),
  ];
};

const generateVoteOtherContent = (vote: VoteTemplateData): ParagraphBlock[] => {
  const topicBlock = vote.title
    ? Text(vote.title.toLowerCase())
    : createHighlight([Text('[Vote]')]);
  const detailsBlocks = vote.details
    ? [createParagraphBlock([Text(vote.details ?? '')])]
    : [];

  return [
    createParagraphBlock([Text(vote.title ?? '', { bold: true })]),

    createParagraphBlock([
      createHighlight([Text('_________')]),
      Text(` initiated a discussion regarding `),
      topicBlock,
      Text(
        `.  A full discussion followed. Upon motion duly made and seconded, the Board of Directors `,
      ),
      ...generateVoteApprovalSnippet(vote.approved),
      Text(':'),
    ]),
    ...detailsBlocks,
    generateVoteDisclaimer(),
  ];
};

const generateVoteMeetingMinutesStockApprovalContent = (
  vote: VoteTemplateData,
) => {
  return [
    ...generateTitleAndDetails(vote),
    createParagraphBlock([
      Text('Approval of Stock Option Grants', { bold: true }),
    ]),

    createParagraphBlock([
      createHighlight([Text('_________ ')]),
      Text(
        ' initiated regarding proposed option grants.  A full discussion followed, including a discussion regarding the fair market value of the Company’s common stock for purposes of option pricing and IRS Rule 409A.  Upon motion duly made and seconded, the Board ',
      ),
      ...generateVoteApprovalSnippet(vote.approved),
      Text(
        ' the option grants set forth in the resolutions attached to these minutes.',
      ),
    ]),

    createParagraphBlock([Text('STOCK OPTION GRANTS', { bold: true })]),

    createParagraphBlock([
      Text(
        '1. The Board has discussed granting incentive or nonstatutory stock options to certain employees or consultants of the Company pursuant to its',
      ),
      Text(' Equity Incentive Plan', { bold: true }),
      Text(', as amended (the “'),
      Text('Plan', { bold: true, italic: true }),
      Text('”).'),
    ]),

    createParagraphBlock([
      Text(
        '2. In determining the fair market value of the Company’s Common Stock on the date of these resolutions, the Board considered the following factors:',
      ),
    ]),

    createParagraphBlock([
      Text(
        '(1) The Company has no material trade or business that it, or any predecessor to it, has conducted for a period of ten (10) years or more.',
      ),
    ]),

    createParagraphBlock([
      Text(
        '(2) The Board has no reasonable anticipation that the Company will undergo a change in control event within the next ninety (90) days or make a public offering of securities within the next one hundred eighty (180) days.',
      ),
    ]),

    createParagraphBlock([
      Text(
        '(3) The Company’s Common Stock is not subject to any put, call, or other right or obligation of the Company or other person to purchase such stock (other than a right of first refusal upon an offer to purchase by a third party that is unrelated to the Company or the award recipient and other than a right or obligation that constitutes a lapse restriction as defined in §1.83–3(i) of the Internal Revenue Code of 1986, as amended).',
      ),
    ]),

    createParagraphBlock([
      Text(
        '(4) The Board has received and reviewed a presentation, with accompanying analysis and supporting data, prepared by ',
      ),
      createHighlight([Text('_________________')]),
      Text(', '),
      createHighlight([
        Text(
          '[an independent appraisal firm] / [the Company’s Chief Financial Officer]',
          { bold: true },
        ),
      ]),
      Text(
        ', regarding the valuation of the Company and the Company’s Common Stock, and has taken into consideration the material facts and circumstances known to the Board as of the date of these resolutions, including but not limited to: (a) the earnings history and financial performance of the Company, (b) the current prospects and expected operating results for the Company, (c) the value of tangible and intangible assets of the Company, (d) recent material events in the Company’s operations (such as the resolution of material litigation or the issuance of a patent), (e) the market value of stock or equity interests in similar corporations and other entities engaged in trades or businesses substantially similar to those engaged in by the Company and whose stock or equity interests can be valued through nondiscretionary, objective means (such as through trading prices on an established securities market or an amount paid in an arm’s length private transaction), (f) recent arm’s length transactions involving the sale or transfer of the Company’s Common Stock or other equity interests, (g) control premiums or discounts for lack of marketability, and (h) and such other items as the Board deemed material as of the date of its determination in order to determine in good faith the fair market value of a share of the Common Stock of the Company.',
      ),
    ]),

    createParagraphBlock([
      Text('(5) The '),
      createHighlight([
        Text('[appraisal firm] / [individual]', { bold: true }),
      ]),
      Text(
        ' that prepared the above referenced 247865165 v1 presentation and the accompanying analysis and supporting data has at least five years of relevant experience in business valuation or appraisal, financial accounting, investment banking, private equity, secured lending, or other comparable experience in the Company’s line of business or industry.',
      ),
    ]),

    createParagraphBlock([Text('RESOLUTIONS', { bold: true })]),
    createParagraphBlock([
      Text(
        '3. That the Board hereby grants incentive or nonstatutory stock options to purchase shares of the Company’s Common Stock pursuant to the Plan to the individuals and in the amounts as set forth on ',
      ),
      Text('Exhibit A', { bold: true, italic: true }),
      Text(' to these resolutions;'),
    ]),
    createParagraphBlock([
      Text(
        '4. That, based on a determination of the applicable criteria under IRS Rule 409A, the exercise price of such options will be $',
      ),
      createHighlight([Text('___')]),
      Text(
        ' per share, which the Board hereby determines, after due consideration of the factors set forth above, to be the fair market value of the Company’s Common Stock on the date of these resolutions;',
      ),
    ]),
    createParagraphBlock([
      Text(
        '5. That the options granted hereby will vest and become exercisable indicated on ',
      ),
      Text('Exhibit A', { bold: true, italic: true }),
      Text(
        ' to these resolutions, subject to the optionee’s continued service on each such date;',
      ),
    ]),
    createParagraphBlock([
      Text(
        '6. That all of the foregoing grants of options will be made pursuant to an option agreement in substantially the form previously approved by the Board, with such ministerial changes as the Company’s Chief Executive Officer will deem necessary or appropriate;',
      ),
    ]),
    createParagraphBlock([
      Text(
        '7. That the options granted hereby and the Common Stock issuable upon exercise of the options will be offered and sold in accordance with applicable state and federal securities laws; and',
      ),
    ]),
    createParagraphBlock([
      Text(
        '8. That the officers of the Company are hereby authorized and directed on behalf of the Company to take such further action and execute such additional documents as each may deem necessary or appropriate to carry out the foregoing resolutions.',
      ),
    ]),
    createParagraphBlock([Text('MISCELLANEOUS RESOLUTIONS', { bold: true })]),
    createParagraphBlock([
      Text(
        '1. That the officers of the Company are each hereby authorized and directed to execute all documents and to take such action as any of such officers may deem necessary or advisable in order to carry out and perform the purposes of the foregoing resolutions.',
      ),
    ]),
    createParagraphBlock([
      Text(
        '2. That any actions prior to the date of the foregoing resolutions hereby taken by the person elected as the officers of the Company that are within the authority conferred thereby are hereby ratified, confirmed and approved as the acts of this Company.',
      ),
    ]),
    createParagraphBlock([Text('EXHIBIT A', { bold: true })]),
    createParagraphBlock([
      Text('LIST OF OPTIONS GRANTED UNDER THE EQUITY INCENTIVE PLAN, ', {
        bold: true,
      }),
      createHighlight([Text('[_____, __, 2023]', { bold: true })]),
    ]),
    createParagraphBlock([
      Text('Individual: '),
      createHighlight([Text('[First name] [Last name]', { bold: true })]),
      Text('\nTitle: '),
      createHighlight([Text('[Title]', { bold: true })]),
      Text('\nOptions to be Granted: '),
      createHighlight([Text('[Number of shares]', { bold: true })]),
      Text('\nOption Type: '),
      createHighlight([Text('[Option Type]', { bold: true })]),
      Text('\nSpecial Terms: '),
      createHighlight([Text('[Terms]', { bold: true })]),
      Text('\nExercise Price: $'),
      createHighlight([Text('[__]', { bold: true })]),
      Text('\nVesting Commencement Date: '),
      createHighlight([Text('[____,__]', { bold: true })]),
      Text('\nVesting Schedule: '),
      createHighlight([Text('[___]', { bold: true })]),
    ]),
    createParagraphBlock([
      Text('Individual: '),
      createHighlight([Text('[First name] [Last name]', { bold: true })]),
      Text('\nTitle: '),
      createHighlight([Text('[Title]', { bold: true })]),
      Text('\nOptions to be Granted: '),
      createHighlight([Text('[Number of shares]', { bold: true })]),
      Text('\nOption Type: '),
      createHighlight([Text('[Option Type]', { bold: true })]),
      Text('\nSpecial Terms: '),
      createHighlight([Text('[Terms]', { bold: true })]),
      Text('\nExercise Price: $'),
      createHighlight([Text('[__]', { bold: true })]),
      Text('\nVesting Commencement Date: '),
      createHighlight([Text('[____,__]', { bold: true })]),
      Text('\nVesting Schedule: '),
      createHighlight([Text('[___]', { bold: true })]),
    ]),
    createParagraphBlock([
      Text(
        '1/4th of the shares vest one year after the Vesting Commencement Date; the balance of the shares vest in a series of twelve (12) successive equal quarterly installments measured from the first anniversary of the Vesting Commencement Date.',
      ),
    ]),
  ];
};

const generateVoteMeetingMinutesApprovalContent = (vote: VoteTemplateData) => {
  return [
    ...generateTitleAndDetails(vote),
    createParagraphBlock([
      Text('Ratification of Previous Meeting Minutes', { bold: true }),
    ]),

    createParagraphBlock([
      Text('Upon motion duly made and seconded, the Board '),
      ...generateVoteApprovalSnippet(vote.approved),
      Text(' the minutes of the Board meeting dated '),
      createHighlight([Text('_______________, _____, 2023.')]),
    ]),
  ];
};

const generateVoteMeetingMinutesValuationApprovalContent = (
  vote: VoteTemplateData,
) => {
  return [
    ...generateTitleAndDetails(vote),
    createParagraphBlock([Text('Approval of 409A Valuation', { bold: true })]),
    createParagraphBlock([
      Text(
        '1.  The Board has discussed granting incentive or non-statutory stock options to certain employees, directors, advisors or consultants of the Company pursuant to the Company’s 20',
      ),
      createHighlight([Text('__')]),
      Text(' Equity Incentive Plan;', { bold: true }),
    ]),
    createParagraphBlock([
      Text(
        '2.  The Board has been provided for its consideration a valuation report in the form presented to the Board (the “',
      ),
      Text('Valuation Report', { bold: true, italic: true }),
      Text('”) that have been prepared by '),
      createHighlight([Text('_________________')]),
      Text(', (the “'),
      Text('Appraiser', { bold: true, italic: true }),
      Text(
        '”), pursuant to which the Appraiser has concluded that the fair market value of a share of the Company’s Common Stock (the “',
      ),
      Text('Common Stock', { bold: true, italic: true }),
      Text('”) as of '),
      createHighlight([Text('_____________')]),
      Text(' was $'),
      createHighlight([Text('____')]),
      Text(' per share (the “'),
      Text('Valuation', { bold: true, italic: true }),
      Text('”); and'),
    ]),
    createParagraphBlock([
      Text(
        '3.  The Board has considered the Valuation and Valuation Report, as well as all other available information and factors that the Board has determined to be appropriate for purposes of determining the fair market value of the Common Stock, so that the Board has a good faith basis for making a reasonable determination of the Company’s fair market value for purposes of complying with Section 409A of the Internal Revenue Code of 1986, as amended (the “',
      ),
      Text('Code', { bold: true, italic: true }),
      Text(
        '”), and the regulations promulgated thereunder, and the statutes, rules and regulations related to the granting of “incentive" stock options under Section 422 of the Code (including, among other factors, the value of the Company’s tangible and intangible assets, the present value of anticipated future cash flows, the public trading price and the private sale price of comparable companies, control premiums and discounts for the illiquid nature and lack of marketability of the common stock).',
      ),
    ]),
    createParagraphBlock([Text('RESOLUTIONS', { bold: true })]),
    createParagraphBlock([
      Text('4.  That the Board '),
      ...generateVoteApprovalSnippet(
        vote.approved,
        'approves and adopts',
        'did not approve or adopt',
        '',
      ),
      Text(' the Valuation Report;'),
    ]),
    createParagraphBlock([
      Text(
        '5.  That the Board determines, after taking into account the Valuation Report and the other factors that it has determined to be appropriate, that the fair market value of the Common Stock as of the date hereof is $',
      ),
      createHighlight([Text('____')]),
      Text(
        ' per share, which is the Valuation reflected in the Valuation Report;',
      ),
    ]),
    createParagraphBlock([
      Text(
        '6.  That the Board intends that, until such time as it adopts a subsequent determination of fair market value with respect to the Common Stock, the above determination of value will be used by the Company in connection with the granting by the Board of equity compensation awards including stock options, and for such other purposes as may arise in which a valuation is required; and',
      ),
    ]),
    createParagraphBlock([
      Text(
        '7.  That the officers of the Company are authorized and directed on behalf of the Company to take such further action and execute such additional documents as each may deem necessary or appropriate to carry out the foregoing resolutions.',
      ),
    ]),
  ];
};

const defaultVoteBlocks = () => {
  return [
    createParagraphBlock([
      Text('Approval of Previous Meeting Minutes', { bold: true }),
    ]),
    createParagraphBlock([
      createHighlight([
        Text(
          '[Upon motion duly made and seconded, the Board unanimously approved the minutes of the Board meeting dated _______________, _____.] / [There were no prior minutes to approve]',
        ),
      ]),
    ]),

    createParagraphBlock([
      Text('Approval of Stock Option Grants', { bold: true }),
    ]),

    createParagraphBlock([
      createHighlight([Text('_________')]),
      Text(
        ' initiated regarding proposed option grants.  A full discussion followed, including a discussion regarding the fair market value of the Company’s common stock for purposes of option pricing and IRS Rule 409A.  Upon motion duly made and seconded, the Board unanimously ',
      ),
      createHighlight([Text('[approved]/[did not approve]')]),
      Text(
        'the option grants set forth in the resolutions attached to these minutes.',
      ),
    ]),
  ];
};

export const generateEmptyVoteContent = (voteType: VoteType) => {
  return generateVoteContent([
    {
      voteType,
    },
  ]);
};

export const generateVoteContent = (
  votes: Array<VoteTemplateData>,
): ParagraphBlock[] => {
  if (votes.length == 0) {
    return defaultVoteBlocks();
  }

  return votes.flatMap((voteItem) => {
    switch (voteItem.voteType) {
      case VoteType.MeetingMinutesApproval: {
        return generateVoteMeetingMinutesApprovalContent(voteItem);
      }
      case VoteType.StockOptionApproval: {
        return generateVoteMeetingMinutesStockApprovalContent(voteItem);
      }
      case VoteType.ValuationApproval: {
        return generateVoteMeetingMinutesValuationApprovalContent(voteItem);
      }
      case VoteType.Other: {
        return generateVoteOtherContent(voteItem);
      }
      case VoteType.None: {
        return generateVoteOtherContent(voteItem);
      }
      default: {
        return generateVoteOtherContent(voteItem);
      }
    }
  });
};
