import { createParagraphBlock, isTextBlock } from 'editor-content/Block.js';
import pressShiftArrowDown, {
  PressShiftArrowDownBlockInterface,
} from '../../../../editor/actions/pressShiftArrowDown.js';
import pressShiftArrowUp, {
  PressShiftArrowUpBlockEditorInterface,
} from '../../../../editor/actions/pressShiftArrowUp.js';
import { replaceWithBulletBody } from '../../../../editor/actions/replaceWithBullet.ts';
import { replaceWithNumberedBody } from '../../../../editor/actions/replaceWithNumbered.ts';
import textBlockPressShiftArrowDownStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockPressShiftArrowDownStrategy.js';
import textBlockPressShiftArrowUpStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockPressShiftArrowUpStrategy.js';
import { dropDraggedBlock } from '../../../../editor/dragBlock/dropDraggedBlock.js';
import { EditorConfiguration } from '../../../../editor/EditorAction.js';
import { BlockSelection } from '../../../../editor/selection/BlockSelection.js';
import { TextSelection } from '../../../../editor/selection/TextSelection.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import pressEnter from '../pressEnter/pressEnterBody.js';
import addBlockAtEnd from './addBlockAtEnd.js';
import { addHighlight } from './addHighlight.js';
import { addLink } from './addLink.js';
import { zeckEditorCopy } from './copyPaste/copy.js';
import { zeckEditorCut } from './copyPaste/cut.js';
import pasteBlocks from './copyPaste/pasteBlocks.js';
import pastePlaintext from './copyPaste/pastePlaintext.js';
import pasteText from './copyPaste/pasteText.js';
import { pasteImage } from './copyPaste/pastImage.js';
import editBlock from './editBlock.js';
import {
  replaceImage,
  setImageAlign,
  setImageWidth,
} from './ImageFormattingActions.js';
import indent from './indent.js';
import insertAiContentAbove from './insertAiContentAbove.ts';
import { navLeft, navRight } from './nav.js';
import pressBackspaceBody from './pressBackspaceBody.js';
import pressDeleteBody from './pressDeleteBody.js';
import pressForwardSlash from './pressForwardSlashBody.js';
import { replaceFile } from './replaceFile.js';
import { replaceTable } from './replaceTable.js';
import select from './select.js';
import toggleFormat from './toggleFormat.js';
import turnInto from './turnInto.js';

export type BodySelection = TextSelection | BlockSelection;

export type BodyContent = HydratedBlock[];

export type BodyStateSelected = {
  content: BodyContent;
  selection: BodySelection;
};

export type BodyState = {
  content: BodyContent;
  selection: BodySelection | null;
};

export type BodyActionResult = BodyStateSelected | void;

const editorConfiguration: EditorConfiguration<
  HydratedBlock,
  PressShiftArrowDownBlockInterface & PressShiftArrowUpBlockEditorInterface
> = {
  generateBlockEditor: (block) => ({
    pressShiftArrowDown(selection) {
      if (isTextBlock(block)) {
        return textBlockPressShiftArrowDownStrategy(block, selection);
      }

      return true;
    },
    pressShiftArrowUp(selection) {
      if (isTextBlock(block)) {
        return textBlockPressShiftArrowUpStrategy(selection);
      }

      return true;
    },
  }),
  createDefaultBlock: createParagraphBlock,
};

const BodyEditor = {
  pasteText,
  pasteBlocks,
  pastePlaintext,
  cut: zeckEditorCut,
  copy: zeckEditorCopy,

  pressBackspace: pressBackspaceBody,
  pressDelete: pressDeleteBody,
  pressEnter: (initialState: BodyStateSelected) => {
    console.log('enter');
    return pressEnter(initialState);
  },
  pressForwardSlash,
  pressSpacebar(initialState: BodyStateSelected): void | BodyStateSelected {
    console.log('space');
    const ret = replaceWithBulletBody(initialState);
    if (!ret) {
      return replaceWithNumberedBody(initialState);
    }

    return ret;
  },

  navLeft,
  navRight,

  selectUp(initialState: BodyStateSelected): BodyStateSelected {
    const result = pressShiftArrowUp(editorConfiguration)(initialState);

    return result || initialState;
  },
  selectDown(initialState: BodyStateSelected): BodyStateSelected {
    const result = pressShiftArrowDown(editorConfiguration)(initialState);

    return result || initialState;
  },

  toggleFormat,

  addLink,

  addHighlight,

  setImageWidth,
  setImageAlign,
  replaceImage,

  pasteImage,

  replaceFile,

  replaceTable,

  turnInto,
  indent,

  editBlock,
  select,

  addBlockAtEnd,
  insertAiContentAbove,

  dropDraggedBlock: dropDraggedBlock<HydratedBlock>,
};

export default BodyEditor;
