import OutlineButton from '../../../../../design-system/atoms/OutlineButton.tsx';
import {
  ModalBody,
  ModalPanel,
} from '../../../../../design-system/organisms/Modal.tsx';
import styles from './AiErrorModal.module.scss';
import AiModalHeader from './AiModalHeader.tsx';

type AiErrorModalProps = {
  onClose: () => void;
  errorCode?: string;
};

const AiErrorModal: React.FC<AiErrorModalProps> = ({ onClose, errorCode }) => {
  return (
    <ModalPanel className={styles.modalPanel}>
      <AiModalHeader />

      <ModalBody className={styles.modalBody}>
        <div className={styles.bodyHeader}>
          {errorCode === 'ai_section_optimizer_token_length_error'
            ? 'The content is too large to be optimized. Please try again with less content'
            : 'Something went wrong. Please try again.'}
        </div>
        <OutlineButton
          onClick={onClose}
          className={styles.closeButton}
          size="small"
          color="secondary"
        >
          Close
        </OutlineButton>
      </ModalBody>
    </ModalPanel>
  );
};

export default AiErrorModal;
