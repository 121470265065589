import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function AddCommentIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M6.00004 14.6663C5.82323 14.6663 5.65366 14.5961 5.52864 14.4711C5.40361 14.3461 5.33337 14.1765 5.33337 13.9997V11.9997H2.66671C2.31309 11.9997 1.97395 11.8592 1.7239 11.6091C1.47385 11.3591 1.33337 11.02 1.33337 10.6663V2.66634C1.33337 2.31272 1.47385 1.97358 1.7239 1.72353C1.97395 1.47348 2.31309 1.33301 2.66671 1.33301H13.3334C13.687 1.33301 14.0261 1.47348 14.2762 1.72353C14.5262 1.97358 14.6667 2.31272 14.6667 2.66634V10.6663C14.6667 11.02 14.5262 11.3591 14.2762 11.6091C14.0261 11.8592 13.687 11.9997 13.3334 11.9997H9.26671L6.80004 14.473C6.66671 14.5997 6.50004 14.6663 6.33337 14.6663H6.00004ZM3.33337 3.99967V5.99967H12.6667V3.99967H3.33337ZM3.33337 7.33301V9.33301H8.66671V7.33301H3.33337Z"
        fill="#555770"
      />
    </svg>
  );
}
