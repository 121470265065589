import styles from './ThreeDotLoading.module.scss';
import cx from 'classnames';

type ThreeDotLoadingProps = {
  className?: string;
};

const ThreeDotLoading: React.FC<ThreeDotLoadingProps> = ({ className }) => {
  return (
    <div
      data-testid="three-dot-loader"
      className={cx(styles.threeDotLoading, className)}
    >
      <div className={styles.spinner} data-testid="spinner">
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default ThreeDotLoading;
