import { useRef } from 'react';

function useCommentsSectionScrollSync() {
  const commentSectionsRef = useRef<Record<string, HTMLElement | null>>({});
  const commentThreadElementsRef = useRef<Record<string, HTMLElement | null>>(
    {},
  );
  const commentsPanelRef = useRef<HTMLElement | null>(null);

  const setCommentsPanelRef = (el: HTMLElement | null) => {
    commentsPanelRef.current = el;
  };

  const setCommentThreadElementRef =
    (commentId: string) => (el: HTMLElement | null) => {
      if (el) {
        commentThreadElementsRef.current = {
          ...commentThreadElementsRef.current,
          [commentId]: el,
        };
      }
    };

  const setCommentSectionRef =
    (sectionId: string) => (el: HTMLElement | null) => {
      commentSectionsRef.current = {
        ...commentSectionsRef.current,
        [sectionId]: el,
      };
    };

  const sectionsContentRef = useRef<Record<string, HTMLElement | null>>({});
  const setSectionContentRef =
    (sectionId: string) => (el: HTMLElement | null) => {
      sectionsContentRef.current = {
        ...sectionsContentRef.current,
        [sectionId]: el,
      };
    };

  const zeckScrollContainerRef = useRef<HTMLElement | null>(null);

  const scrollSectionIntoViewIfNeeded = (sectionId: string) => {
    const sectionContentEl = sectionsContentRef.current[sectionId];
    const scrollContainerEl = zeckScrollContainerRef.current;
    if (!sectionContentEl || !scrollContainerEl) return;

    const scrollContainerRect = scrollContainerEl.getBoundingClientRect();
    const sectionContentRect = sectionContentEl.getBoundingClientRect();

    const top = scrollContainerRect.top + 200;
    const bottom = scrollContainerRect.bottom - 80;
    if (
      (sectionContentRect.bottom > top && sectionContentRect.bottom < bottom) ||
      (sectionContentRect.top > top && sectionContentRect.top < bottom) ||
      (sectionContentRect.top < bottom && sectionContentRect.bottom > top)
    )
      return;

    sectionContentEl.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  const scrollToCommentInZeck = (commentId: string, sectionId: string) => {
    const sectionContentEl = sectionsContentRef.current[sectionId];
    const scrollContainerEl = zeckScrollContainerRef.current;
    if (!sectionContentEl || !scrollContainerEl) return;

    const firstCommentHighlightEl = sectionContentEl.querySelector(
      `span[data-highlightids~="${commentId}"]`,
    );

    if (!firstCommentHighlightEl) return;

    firstCommentHighlightEl.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToComment = (commentId: string) => {
    const commentEl = commentThreadElementsRef.current[commentId];

    const panelEl = commentsPanelRef.current;
    if (!commentEl || !panelEl) {
      return;
    }

    const panelRect = panelEl.getBoundingClientRect();
    const commentRect = commentEl.getBoundingClientRect();
    const offset = commentRect.top - panelRect.top;

    panelEl.scrollBy({
      top: offset - 80,
      behavior: 'smooth',
    });
  };

  const scrollCommentsSectionIntoView = (sectionId: string) => {
    commentSectionsRef.current[sectionId]?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const setZeckScrollContainerRef = (el: HTMLElement) =>
    (zeckScrollContainerRef.current = el);

  return {
    setCommentSectionRef,
    setCommentElementRef: setCommentThreadElementRef,
    setSectionContentRef,
    setZeckScrollContainerRef,
    scrollSectionIntoViewIfNeeded,
    scrollCommentsSectionIntoView,
    scrollToComment,
    scrollToCommentInZeck,
    setCommentsPanelRef,
  };
}

export default useCommentsSectionScrollSync;
