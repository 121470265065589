import PageLayout from './PageLayout.tsx';
import useHomepageFolders from './useHomepageFolders.ts';

const PageLayoutFetchesFolders = (
  props: Omit<
    React.ComponentProps<typeof PageLayout>,
    'zeckFoldersResult' | 'onCreateFolder' | 'onUpdateFolder'
  >,
) => {
  const { foldersResult } = useHomepageFolders(
    props.userAndCompany.activeCompany.id,
  );

  return <PageLayout {...props} zeckFoldersResult={foldersResult} />;
};

export default PageLayoutFetchesFolders;
