import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function CheckmarkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M9.45455 15.4318L6.11364 12.0909L5 13.2045L9.45455 17.6591L19 8.11364L17.8864 7L9.45455 15.4318Z"
        fill="black"
      />
    </svg>
  );
}
