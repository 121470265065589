import { EditorStateGeneric } from '../../../../editor/EditorStateGeneric.js';
import { AvailableTag } from '../../../../types/AvailableTag.js';
import { isTextSelection } from '../../../../editor/EditorSelection.js';
import {
  isCollapsedSelection,
  TextSelection,
  textSelection,
} from '../../../../editor/selection/TextSelection.js';
import { getTextFromNodes, Text } from 'editor-content/TextNode.js';
import replaceSelectionWith from '../../../zeck/editor/BodyEditor/replaceSelectionWith.js';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.js';
import { isTextBlock } from 'editor-content/Block.js';
import { getSelectionFormat } from '../../../../editor/selection/contentSelection/getSelectionFormat.js';
import { textBlockInsertTextStrategy } from '../../../../editor/blocks/textBlocksStrategies/textBlockReplaceSelectedContentStrategy.js';
import FuzzySearch from 'fuzzy-search';
import { MeetingMinutesBlock } from 'editor-content/MeetingMinutes/MeetingMinutesBlock.ts';

function hasTagSnippetSelectionMatch({
  content,
  selection,
}: EditorStateGeneric<MeetingMinutesBlock>): {
  tagSelection: TextSelection | undefined;
  tagText: string | undefined;
} {
  if (!selection) return { tagSelection: undefined, tagText: undefined };

  if (!isTextSelection(selection) || !isCollapsedSelection(selection))
    return { tagSelection: undefined, tagText: undefined };

  const selectedBlock = content?.[selection.index];
  if (!selectedBlock) {
    return { tagSelection: undefined, tagText: undefined };
  }

  const blockText = getTextFromNodes(selectedBlock.content);
  const caretPosition = selection.offset.anchorOffset;

  const precedingText = blockText.slice(0, caretPosition);
  const whitespaceTagMatch = precedingText.match(/\s@/);
  const blockStartTagMatch = precedingText.match(/^@/);
  if (whitespaceTagMatch || blockStartTagMatch) {
    const tagStart =
      whitespaceTagMatch && typeof whitespaceTagMatch?.index !== 'undefined'
        ? whitespaceTagMatch.index + 1
        : 0;

    // if more than 20 characters from start of tag, return nothing to reduce performance impact of long fuzzy searches
    if (caretPosition - tagStart > 20) {
      return {
        tagSelection: undefined,
        tagText: undefined,
      };
    }

    return {
      tagSelection: textSelection(
        selection.index,
        contentSelection(tagStart, selection.offset.anchorOffset),
      ),
      tagText: blockText.slice(tagStart + 1, caretPosition),
    };
  }

  return {
    tagSelection: undefined,
    tagText: undefined,
  };
}

const InsertTag = {
  getMatchingTags(
    editorState: EditorStateGeneric<MeetingMinutesBlock>,
    availableTags: AvailableTag[],
  ): AvailableTag[] {
    const tagSnippetMatch = hasTagSnippetSelectionMatch(editorState);
    if (tagSnippetMatch.tagSelection) {
      const searcher = new FuzzySearch(availableTags, ['displayName'], {
        caseSensitive: false,
      });
      return searcher.search(tagSnippetMatch.tagText);
    }

    return [];
  },
  insertTag(
    editorState: EditorStateGeneric<MeetingMinutesBlock>,
    tag: AvailableTag,
  ): EditorStateGeneric<MeetingMinutesBlock> | void {
    const { tagSelection } = hasTagSnippetSelectionMatch(editorState);
    if (tagSelection) {
      return replaceSelectionWith(
        {
          content: editorState.content,
          selection: tagSelection,
        },
        {
          textSelection(selectedBlock, selection) {
            if (!isTextBlock(selectedBlock)) return;

            const format = getSelectionFormat(selectedBlock, selection);

            const { block, selection: contentSelection } =
              textBlockInsertTextStrategy(selectedBlock, selection, [
                Text(tag.displayName, format),
              ]);
            return {
              contentSubset: [block],
              selection: textSelection(0, contentSelection),
            };
          },

          blockSelection: () => {
            //
          },
        },
      );
    }

    return;
  },
};

export default InsertTag;
