import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styles from '../zeck/Image.module.scss';
import Loader from './Loader.js';

type ImageProps = {
  src: string | null;
  dimensions?: { width: number; height: number };
  className?: string;
} & React.ComponentProps<'div'>;

const Image = React.forwardRef<HTMLDivElement, ImageProps>(function Image(
  { className, src, dimensions, ...otherProps },
  forwardedRef,
) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const imageRef = useRef<HTMLImageElement>(null);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.onload = () => {
        setLoaded(true);
      };
    }
  }, []);

  return (
    <div
      {...otherProps}
      data-testid="uploaded-image-container"
      ref={forwardedRef}
      className={cx(
        styles.image_container,
        className,
        loaded && styles.image_container_loaded,
      )}
    >
      {isSafari && dimensions && !loaded && (
        <div
          style={{
            float: 'left',
            paddingTop: `${(dimensions?.height / dimensions?.width) * 100}%`,
            content: '',
          }}
        />
      )}

      <img
        data-testid="uploaded-image"
        ref={imageRef}
        className={cx(styles.image)}
        src={src || ''}
        data-loaded={loaded}
        {...dimensions}
        style={{
          transition: 'opacity 200ms',
          opacity: loaded ? '1' : '0',
        }}
        alt=""
      />

      {isSafari && dimensions && !loaded && (
        <div
          style={{
            display: 'block',
            content: ' ',
            clear: 'both',
          }}
        />
      )}

      {!loaded && <Loader size="medium" className={styles.spinner} />}
    </div>
  );
});

export default Image;
