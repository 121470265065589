import { Editor } from 'editor-content/TextNode.js';
import { isBlockSelection } from '../../../../editor/selection/BlockSelection.js';
import cond from '../../../../junkDrawer/cond.js';
import guard from '../../../../junkDrawer/guard.js';
import { hydratedIsTextBlock } from './hydratedBlockGuards.js';
import { updateTextBlock } from 'editor-content/Block.js';
import addLinkToTextNodes from './addLinkToTextNodes.js';
import identity from '../../../../junkDrawer/identity.js';
import { BodyStateSelected } from './BodyEditor.js';

export function addLink(
  initialState: BodyStateSelected,
  link: Editor.LinkType,
): BodyStateSelected {
  const { content, selection } = initialState;
  if (isBlockSelection(selection)) return initialState;

  // it seems like isTextBlock type guard, the not case is making it a Block instead of keeping it HydratedBlock
  return {
    content: content.map(
      cond(
        (a, i) => i === selection.index,
        guard(
          hydratedIsTextBlock,
          (block) =>
            updateTextBlock(
              block,
              addLinkToTextNodes(block.content, selection.offset, link),
            ),
          identity,
        ),
        identity,
      ),
    ),
    selection,
  };
}
