const editorKeyboardHandler =
  (editor: {
    pressBackspace: () => true | void;
    pressDelete: () => true | void;
    pressEnter: () => void;
    selectUp: () => void;
    selectDown: () => void;
  }) =>
  (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'Backspace':
        editor.pressBackspace() && e.preventDefault();
        return;
      case 'Delete':
        editor.pressDelete() && e.preventDefault();
        return;
      case 'Enter':
        if (e.getModifierState('Shift')) return;
        editor.pressEnter();
        return;
      case 'ArrowUp':
        if (e.getModifierState('Shift')) editor.selectUp();
        return;
      case 'ArrowDown':
        if (e.getModifierState('Shift')) editor.selectDown();
        return;
    }
  };

export default editorKeyboardHandler;
