import React, { useRef } from 'react';
import SelectableDiv from './SelectableDiv.tsx';
import BlockActions from './BlockActions.ts';
import { callHandlers } from '../../../../editor/domFacing/events/isKeyMatch.ts';
import handleNonTextBlockKeyEvents from './handleNonTextBlockKeyEvents.ts';
import Divider from '../../Divider.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import { DividerBlock } from 'editor-content/DividerBlock.js';

type DividerEditableWithKeyboardProps = {
  className?: string;
  isInBlockSelection: boolean;
  'data-testid'?: string;
} & BlockActions<DividerBlock>;

export default React.forwardRef<HTMLElement, DividerEditableWithKeyboardProps>(
  function DividerEditableWithKeyboard(
    {
      className,
      block,
      selection,
      onSelect,
      onNavLeft,
      onNavRight,
      onEnter,
      isInBlockSelection,
      'data-testid': dataTestid,
    },
    forwardedRef,
  ) {
    const ref = useRef<HTMLDivElement>(null);

    const eventTargetIsSelectableDiv = (
      e: React.KeyboardEvent<HTMLDivElement>,
    ) => e.target !== ref.current;

    return (
      <SelectableDiv
        ref={mergeRefs([forwardedRef, ref])}
        className={className}
        onSelect={onSelect}
        internalSelection={selection}
        isInBlockSelection={isInBlockSelection}
        onKeyDown={callHandlers([
          eventTargetIsSelectableDiv,
          callHandlers([
            handleNonTextBlockKeyEvents({
              onEnter,
              onNavLeft,
              onNavRight,
            }),
          ]),
        ])}
        data-testid={dataTestid}
      >
        <Divider block={block} />
      </SelectableDiv>
    );
  },
);
