import createUseEnterExitAnimation from './createUseEnterExitAnimation.ts';

const useDropdownAnimation = createUseEnterExitAnimation(
  [
    { opacity: 0, transform: 'translateY(-16px)' },
    { opacity: 1, transform: 'translateY(0)' },
  ],
  {
    duration: 200,
    iterations: 1,
    easing: 'linear',
    fill: 'both',
  },
);

export default useDropdownAnimation;
