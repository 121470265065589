import { TableBlock } from 'editor-content/TableBlock.js';
import React from 'react';
import Table from '../atoms/Table.tsx';
import formattedTableDataFromBlock from './table/formattedTableData.ts';

export type TableFromBlockProps = {
  className?: string;
  block: TableBlock;
  noBorder?: boolean;
};

const TableFromBlock: React.FC<TableFromBlockProps> = ({
  block,
  noBorder,
  className,
}) => {
  const tableData = formattedTableDataFromBlock(block);

  return <Table table={tableData} noBorder={noBorder} className={className} />;
};

export default TableFromBlock;
