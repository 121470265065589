import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function DownKeyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M12.2344 8.72727H11.3111V14.3097L9.17685 12.1754L8.54474 12.8146L11.7727 16.0426L15.0078 12.8146L14.3615 12.1754L12.2344 14.3097V8.72727Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="white" />
    </svg>
  );
}
