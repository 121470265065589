import { baseBlock, createBlock } from './BaseBlock.js';
import { Block } from './Block.js';

export type AgendaBlock = {
  type: 'agenda';
  id: string;
  title: string;
  description: string;
  startTime: number | null;
  timeZone: string | null;
  items: AgendaItem[];
};

export const isAgendaBlock = (b: Block): b is AgendaBlock =>
  b.type === 'agenda';

export type AgendaItem = AgendaItemSection | AgendaItemCustom;

export const AgendaItemSection = (
  sectionId: string,
  description: string,
  startTime?: number | null,
): AgendaItemSection => ({
  type: 'section',
  sectionId,
  description,
  startTime: typeof startTime === 'number' ? startTime : null,
});

export type AgendaItemSection = {
  type: 'section';
  sectionId: string;
  description: string;
  startTime: number | null;
};

export const isAgendaItemSection = (
  item: AgendaItem,
): item is AgendaItemSection => item.type === 'section';

export const AgendaItemCustom = (
  name: string,
  description: string,
  startTime?: number | null,
): AgendaItemCustom => ({
  type: 'custom',
  name,
  description,
  startTime: typeof startTime === 'number' ? startTime : null,
});

export type AgendaItemCustom = {
  type: 'custom';
  name: string;
  description: string;
  startTime: number | null;
};

export const AgendaBlock = (
  id: string,
  title: string,
  description: string,
  items: AgendaItem[],
  startTime: number | null | undefined = null,
  timeZone: string | null | undefined = null,
): AgendaBlock =>
  baseBlock('agenda', id, {
    title,
    description,
    startTime,
    timeZone,
    items,
  });

export const createAgendaBlock = (
  title: string,
  description: string,
  items: AgendaItem[],
  startTime: number | null | undefined = null,
  timeZone: string | null | undefined = null,
): AgendaBlock =>
  createBlock('agenda', {
    title,
    description,
    startTime,
    timeZone,
    items,
  });

export const changeAgendaBlockItems = (
  block: AgendaBlock,
  newItems: AgendaBlock['items'],
): AgendaBlock => ({
  ...block,
  items: newItems,
});
