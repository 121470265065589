import { SafeHTMLString } from './SafeHTMLString.js';
import ContentSelection from '../selection/contentSelection/ContentSelection.js';
import BaseAndExtent from '../selection/contentSelection/BaseAndExtent.js';
import { useEffect } from 'react';
import { setContentSelectionOnContainer } from './setContentSelectionOnContainer.js';

function useUpdateBrowserOnContentChange<ElementType extends HTMLElement>(
  ref: React.RefObject<ElementType | null>,
  value: SafeHTMLString,
  selection: ContentSelection | null,
  toBaseAndExtent: (
    selection: ContentSelection,
    el: ElementType,
  ) => BaseAndExtent,
) {
  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    el.setAttribute('contenteditable', 'true');
    if (el.innerHTML !== value) {
      el.innerHTML = value;

      setContentSelectionOnContainer(selection, el, toBaseAndExtent);
    }
    // this should only re-execute when value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
}

export default useUpdateBrowserOnContentChange;
