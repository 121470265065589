import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function PersonAddIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M10.0013 6.00008C11.4746 6.00008 12.668 4.80675 12.668 3.33341C12.668 1.86008 11.4746 0.666748 10.0013 0.666748C8.52797 0.666748 7.33463 1.86008 7.33463 3.33341C7.33463 4.80675 8.52797 6.00008 10.0013 6.00008ZM10.0013 2.00008C10.7346 2.00008 11.3346 2.60008 11.3346 3.33341C11.3346 4.06675 10.7346 4.66675 10.0013 4.66675C9.26797 4.66675 8.66797 4.06675 8.66797 3.33341C8.66797 2.60008 9.26797 2.00008 10.0013 2.00008ZM10.0013 7.33341C8.2213 7.33341 4.66797 8.22675 4.66797 10.0001V11.3334H15.3346V10.0001C15.3346 8.22675 11.7813 7.33341 10.0013 7.33341ZM6.0013 10.0001C6.14797 9.52008 8.20797 8.66675 10.0013 8.66675C11.8013 8.66675 13.868 9.52675 14.0013 10.0001H6.0013ZM4.0013 8.00008V6.00008H6.0013V4.66675H4.0013V2.66675H2.66797V4.66675H0.667969V6.00008H2.66797V8.00008H4.0013Z"
        fill="#59B7BA"
      />
    </svg>
  );
}
