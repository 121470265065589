import { useEffect, useState } from 'react';
import OutlineButton from '../../../design-system/atoms/OutlineButton.tsx';
import {
  ModalContainer,
  ModalPanel,
} from '../../../design-system/organisms/Modal.tsx';
import { Zeck } from '../../../types.ts';
import { AddZeckViewers } from './AddPeople.tsx';
import ConfirmPublish from './ConfirmPublish.tsx';
import PreflightPublish from './PreflightPublish.tsx';
import styles from './PublishFlow.module.scss';

type PublishFlowProps = {
  isOpen: boolean;
  zeck: Zeck;
  canPublish: boolean;
  onCancel: () => void;
  onPublish: () => void;
};

const PublishFlow = ({
  isOpen,
  zeck,
  canPublish,
  onCancel,
  onPublish,
}: PublishFlowProps) => {
  const [currentModalContent, setCurrentModalContent] = useState<
    'Preflight' | 'AddPeople' | 'Confirm'
  >('Preflight');

  useEffect(() => {
    setCurrentModalContent('Preflight');
  }, [zeck.id, zeck.companyId, isOpen]);

  let child;

  switch (currentModalContent) {
    case 'Preflight':
      child = (
        <PreflightPublish
          zeck={zeck}
          canPublish={canPublish}
          showAddPeopleModal={() => {
            setCurrentModalContent('AddPeople');
          }}
          closeModal={onCancel}
          showConfirmModal={() => {
            setCurrentModalContent('Confirm');
          }}
        />
      );
      break;
    case 'AddPeople':
      child = (
        <ModalPanel>
          <AddZeckViewers
            zeck={zeck}
            onCancel={() => {
              setCurrentModalContent('Preflight');
            }}
            actionElements={
              <OutlineButton
                size="small"
                color={'primary'}
                onClick={() => setCurrentModalContent('Preflight')}
              >
                Continue
              </OutlineButton>
            }
          />
        </ModalPanel>
      );
      break;
    case 'Confirm':
      child = (
        <ConfirmPublish
          zeck={zeck}
          open={isOpen}
          onCancel={() => {
            onCancel();
          }}
          onPublish={() => {
            onPublish();
          }}
        />
      );
      break;
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      onRequestClose={onCancel}
      className={styles.modal}
    >
      {child}
    </ModalContainer>
  );
};

export default PublishFlow;
