import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function PrintIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M14.6654 5.33333H1.33203V11.3333H3.9987V14H11.9987V11.3333H14.6654V5.33333ZM10.6654 12.6667H5.33203V9.33333H10.6654V12.6667ZM12.6654 8C12.2987 8 11.9987 7.7 11.9987 7.33333C11.9987 6.96667 12.2987 6.66667 12.6654 6.66667C13.032 6.66667 13.332 6.96667 13.332 7.33333C13.332 7.7 13.032 8 12.6654 8ZM11.9987 2H3.9987V4.66667H11.9987V2Z"
        fill="white"
      />
    </svg>
  );
}
