import {
  MAX_TABLE_COLUMNS,
  MAX_TABLE_ROWS,
  TableBlock,
  TableBlockCell,
  TableBlockCellBorderFormat,
  TableBlockCellFormat,
  TableBlockRow,
  TableBorderType,
} from 'editor-content/TableBlock.ts';
import { Border, Cell, Row, ValueType, Workbook, Worksheet } from 'exceljs';
import getTextNodesFromCell from './getTextNodesFromCell.js';
import getCellType from './getCellType.js';
import cellHasNoContentsDueToMerge from './cellHasNoContentDueToMerge.js';
import normalizeTableData from '../normalizeTableData.js';

const getVerticalAlign = (
  cell: Cell,
): Pick<TableBlockCellFormat, 'alignVertical'> => {
  switch (cell.alignment?.vertical) {
    case 'top':
      return {
        alignVertical: 'top',
      };
    case 'middle':
      return {
        alignVertical: 'middle',
      };
    case 'bottom':
      return {
        alignVertical: 'bottom',
      };
    default:
      return {
        alignVertical: 'bottom',
      };
  }
};

const getHorizontalAlign = (
  cell: Cell,
  valueTypes: typeof ValueType,
): Pick<TableBlockCellFormat, 'alignHorizontal'> => {
  switch (cell.alignment?.horizontal) {
    case 'left':
      return {
        alignHorizontal: 'left',
      };
    case 'center':
      return {
        alignHorizontal: 'center',
      };
    case 'right':
      return {
        alignHorizontal: 'right',
      };
    default:
      return {
        alignHorizontal:
          getCellType(cell) === valueTypes.Number ? 'right' : 'left',
      };
  }
};

const getCellWrap = (cell: Cell): Pick<TableBlockCellFormat, 'wrap'> => {
  switch (cell.alignment?.wrapText) {
    case true:
      return {
        wrap: 'wrap',
      };
    default:
      return {
        wrap: 'clip',
      };
  }
};

const getCellBorderValue = (
  borderValue: Partial<Border> | undefined,
): TableBorderType => {
  if (!borderValue?.style) {
    return undefined;
  }

  switch (borderValue.style) {
    case 'double':
      return 'thick';
    case 'thick':
      return 'thick';
    default:
      return 'thin';
  }
};

const getCellBorders = (
  cell: Cell,
  endCell?: Cell,
): TableBlockCellBorderFormat => {
  return {
    top: getCellBorderValue(cell?.border?.top),
    right: getCellBorderValue(cell?.border?.right ?? endCell?.border?.right),
    bottom: getCellBorderValue(cell?.border?.bottom ?? endCell?.border?.bottom),
    left: getCellBorderValue(cell?.border?.left),
  };
};

const getCellFormatting = (
  cell: Cell,
  valueTypes: typeof ValueType,
  endCell?: Cell,
): TableBlockCellFormat | undefined => {
  if (cellHasNoContentsDueToMerge(cell, valueTypes)) {
    return undefined;
  }

  if (
    !cell.alignment &&
    getCellType(cell) !== valueTypes.Number &&
    !cell?.style?.border
  ) {
    return undefined;
  }

  const alignVertical = getVerticalAlign(cell);
  const alignHorizontal = getHorizontalAlign(cell, valueTypes);
  const wrap = getCellWrap(cell);
  const border = getCellBorders(cell, endCell);

  return {
    ...alignVertical,
    ...alignHorizontal,
    ...wrap,
    border,
  };
};

type MergeData = {
  cell: Cell;
  otherCells: Cell[];
};

const getCellPosition = (cell: Cell) => ({
  row: parseInt(cell.row, 10),
  col: parseInt(cell.col, 10),
});

const processMergeData = (
  possibleMerges: MergeData[],
  activeRange: ActiveRange,
) => {
  return possibleMerges.map((possibleMerge) => {
    const { row: startRow, col: startColumn } = getCellPosition(
      possibleMerge.cell,
    );

    const endCell = possibleMerge.otherCells.reduce((endpoint, currentCell) => {
      const { row, col } = getCellPosition(currentCell);
      const { row: endpointRow, col: endPointCol } = getCellPosition(endpoint);

      if (row > endpointRow || col > endPointCol) {
        return currentCell;
      }

      return endpoint;
    }, possibleMerge.cell);

    const { col: maxCol, row: maxRow } = getCellPosition(endCell);

    return {
      startColumn: startColumn - activeRange.startColumn,
      columnSpan: maxCol - (startColumn - 1),
      startRow: startRow - activeRange.startRow,
      rowSpan: maxRow - (startRow - 1),
      endCell,
    };
  });
};

const updateMergeData = (cell: Cell, mergeData: MergeData[]) => {
  if (cell.isMerged) {
    // see if part of other merges
    let addedToMerge = false;

    mergeData.forEach((merge) => {
      if (cell.isMergedTo(merge.cell)) {
        merge.otherCells.push(cell);
        addedToMerge = true;
      }
    });

    if (!addedToMerge) {
      mergeData.push({
        cell,
        otherCells: [],
      });
    }
  }
};

export type WorksheetRange = {
  startRow: number; // 1 indexed
  endRow: number | null;
  startColumn: number; // 1 indexed
  endColumn: number | null;
};

type ActiveRange = {
  startRow: number; // 1 indexed
  endRow: number;
  startColumn: number; // 1 indexed
  endColumn: number;
};

const getActiveRange = (
  worksheet: Worksheet,
  range: WorksheetRange,
): ActiveRange => {
  const startRow = range.startRow;
  const endRow = Math.min(
    range.endRow ?? worksheet.rowCount,
    startRow + MAX_TABLE_ROWS - 1,
  );

  const startColumn = range.startColumn;
  const endColumn = Math.min(
    range.endColumn ?? worksheet.columnCount,
    startColumn + MAX_TABLE_COLUMNS - 1,
  );

  return { startRow, endRow, startColumn, endColumn };
};

const isRowOutsideActiveRange = (row: Row, range: ActiveRange): boolean => {
  if (row.number < range.startRow) {
    return true;
  }

  if (row.number > range.endRow) {
    return true;
  }

  return false;
};

const isCellOutsideActiveRange = (cell: Cell, range: ActiveRange): boolean => {
  const column = parseInt(cell.col, 10);
  if (column < range.startColumn) {
    return true;
  }

  if (column > range.endColumn) {
    return true;
  }

  return false;
};

const getColumnWidths = (
  worksheet: Worksheet,
  columnOffset: number,
): Record<number, number> => {
  // default excel column width is 8.43
  // equates to 64 pixels
  const pixelToCharRatio = 64 / 8.43;
  const columnWidths: Record<number, number> = {};

  if (!worksheet.columns) {
    return columnWidths;
  }

  worksheet.columns.forEach((column, i) => {
    if (column.isCustomWidth && column.width) {
      columnWidths[i - columnOffset] = Math.round(
        column.width * pixelToCharRatio,
      );
    }
  });

  return columnWidths;
};

export const getDefinedNameData = (
  workbook: Workbook,
  rangeName: string,
): { range: WorksheetRange; sheetName: string } | undefined => {
  const rangeString = workbook.definedNames.getRanges(rangeName)?.ranges?.[0];

  if (!rangeString) {
    return undefined;
  }

  const [sheetName, cellAddresses] = rangeString.split('!');
  if (!sheetName || !cellAddresses) {
    return undefined;
  }

  const parsedSheetName = sheetName.replaceAll("'", '');

  const worksheet = workbook.getWorksheet(parsedSheetName);
  const [startAddress, endAddress] = cellAddresses.split(':');
  if (!startAddress || !endAddress || !worksheet) {
    return undefined;
  }
  const startCell = worksheet.getCell(startAddress);
  const endCell = worksheet.getCell(endAddress);

  return {
    range: {
      startColumn: parseInt(startCell.col, 10),
      startRow: parseInt(startCell.row, 10),
      endColumn: parseInt(endCell.col, 10),
      endRow: parseInt(endCell.row, 10),
    },
    sheetName: parsedSheetName,
  };
};

export default function createTableFromExcelWorksheet(
  worksheet: Worksheet | undefined,
  valueTypes: typeof ValueType,
  initialRange: WorksheetRange = {
    startColumn: 1,
    endColumn: null,
    startRow: 1,
    endRow: null,
  },
): TableBlock['data'] {
  if (!worksheet) {
    return {
      rows: [],
      merges: [],
    };
  }

  const range = {
    ...initialRange,
    startColumn: Math.max(
      initialRange.startColumn,
      worksheet?.dimensions?.left ?? 0,
    ),
    startRow: Math.max(initialRange.startRow, worksheet?.dimensions?.top ?? 0),
  };

  const activeRange = getActiveRange(worksheet, range);
  const mergeData: MergeData[] = [];
  const rows: TableBlockRow[] = [];
  const rowHeights: Record<number, number> = {};

  worksheet.eachRow({ includeEmpty: true }, (row) => {
    if (isRowOutsideActiveRange(row, activeRange)) {
      return;
    }

    row.eachCell({ includeEmpty: true }, (cell) => {
      if (isCellOutsideActiveRange(cell, activeRange)) {
        return;
      }
      updateMergeData(cell, mergeData);
    });
  });
  const merges = processMergeData(mergeData, activeRange);

  worksheet.eachRow({ includeEmpty: true }, (row) => {
    if (isRowOutsideActiveRange(row, activeRange)) {
      return;
    }

    // don't believe the types, this can be and defaults to undefiend
    if (row.height) {
      // row height is in points, convert to pixels
      rowHeights[rows.length] = Math.round(row.height * 1.3333);
    }

    const cells: TableBlockCell[] = [];
    row.eachCell({ includeEmpty: true }, (cell) => {
      if (isCellOutsideActiveRange(cell, activeRange)) {
        return;
      }

      const textNodes = getTextNodesFromCell(cell, valueTypes);
      const { col, row } = getCellPosition(cell);
      const merge = merges.find(
        (merge) =>
          merge.startColumn === col - activeRange.startColumn &&
          merge.startRow === row - activeRange.startRow,
      );
      const newCell = getCellFormatting(cell, valueTypes, merge?.endCell);

      cells.push(TableBlockCell(textNodes, newCell));
    });

    rows.push(TableBlockRow(cells));
  });

  return normalizeTableData({
    rows,
    merges: merges.map((m) => ({
      startRow: m.startRow,
      startColumn: m.startColumn,
      rowSpan: m.rowSpan,
      columnSpan: m.columnSpan,
    })),
    columnWidths: getColumnWidths(worksheet, range.startColumn - 1),
    rowHeights,
  });
}
