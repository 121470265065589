import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function FullscreenCloseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M2 20.781L6.54077 16.2078L3.71674 13.3549H10.5837V20.271L7.75966 17.4268L3.2103 22L2 20.781Z"
        fill="#1C1C28"
      />
      <path
        d="M22 3.21895L17.4592 7.79219L20.2833 10.6451H13.4163V3.72901L16.2403 6.57324L20.7897 2L22 3.21895Z"
        fill="#1C1C28"
      />
    </svg>
  );
}
