import { useEffect } from 'react';
import { useRequiredParams } from '../../routing/useRequiredParams.js';

type AuthCompleteProps = {
  provider: string;
  credentialId: string;
};

const AuthCompleteComponent: React.FC<AuthCompleteProps> = ({
  provider,
  credentialId,
}) => {
  const localStorageKey = 'zeck_credential_result';

  useEffect(() => {
    window.localStorage.setItem(
      localStorageKey,
      JSON.stringify({ credentialId, timestamp: Date.now(), provider }),
    );

    window.close();
  }, [credentialId, localStorageKey, provider]);

  return <div>Done with authentication. This window can be closed.</div>;
};

const AuthCompletePage: React.FC = () => {
  const { credentialId, provider } = useRequiredParams(
    'provider',
    'credentialId',
  );

  return (
    <AuthCompleteComponent provider={provider} credentialId={credentialId} />
  );
};

export default AuthCompletePage;
