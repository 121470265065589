import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function ShrinkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <rect width="40" height="40" rx="20" fill="white" />
      <path
        d="M10 28.781L14.5408 24.2078L11.7167 21.3549H18.5837V28.271L15.7597 25.4268L11.2103 30L10 28.781Z"
        fill="#1C1C28"
      />
      <path
        d="M30 11.219L25.4592 15.7922L28.2833 18.6451H21.4163V11.729L24.2403 14.5732L28.7897 10L30 11.219Z"
        fill="#1C1C28"
      />
    </svg>
  );
}
