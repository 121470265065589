import React from 'react';
import buttonStyles from '../../atoms/Button.module.scss';
import styles from './VoteButton.module.scss';
import cx from 'classnames';
import ButtonBase from '../../atoms/ButtonBase.js';

const colorMap = Object.freeze({
  grey: styles.button_grey,
  red: styles.button_red,
  green: styles.button_green,
});

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  size: React.ComponentProps<typeof ButtonBase>['size'];
  color: keyof typeof colorMap;
  isFullWidth?: boolean;
};

const VoteButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(function Button({ children, className, size, color, ...props }, ref) {
  return (
    <ButtonBase
      {...{
        ref,
        className: cx(className, buttonStyles.button, colorMap[color]),
        size,
        ...props,
      }}
    >
      {children}
    </ButtonBase>
  );
});

export default VoteButton;
