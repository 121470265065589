import FeatureFlagsContext from './FeatureFlagsContext.ts';
import { getFeatures } from './Features.ts';

type FeatureFlagsProps = {
  children: React.ReactNode;
};

export default function FeatureFlags({ children }: FeatureFlagsProps) {
  return (
    <FeatureFlagsContext.Provider value={getFeatures()}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}
