import {
  ErrorCredentialResponse,
  ValidCredentialResponse,
} from '../../../../../../api/endpoints/createCredentialApi.ts';
import useApi from '../../../../../../api/useApi.ts';
import { useCallback, useEffect, useState } from 'react';
import openWindow from '../../../../../../junkDrawer/openWindow.ts';
import { logError } from '../../../../../../logging/logger.js';

const useAuthForCredential = (
  onSuccess: (
    credential: ValidCredentialResponse | ErrorCredentialResponse,
  ) => void,
) => {
  const { fetchCredentialResponse: fetchCredential } = useApi();

  const [isLoading, setIsLoading] = useState(false);

  const checkCredential = useCallback(
    async (id: string) => {
      const credential = await fetchCredential(id);

      onSuccess(credential);

      setIsLoading(false);
    },
    [fetchCredential, setIsLoading, onSuccess],
  );

  useEffect(() => {
    const cb = (event: StorageEvent) => {
      if (event.storageArea !== localStorage) return;
      if (event.key === 'zeck_credential_result') {
        try {
          const { credentialId } = JSON.parse(event.newValue || '');

          checkCredential(credentialId);
        } catch (e) {
          logError(e as Error);
        }
      }
    };

    window.addEventListener('storage', cb, false);

    return () => {
      return window.removeEventListener('storage', cb);
    };
  }, [checkCredential]);

  const doAuth = (credential: { id: string; authUrl: string }) => {
    setIsLoading(true);
    openWindow(
      credential.authUrl,
      '_blank',
      'toolbar=0,location=0,menubar=0,height=600,width=450',
    );
  };

  return {
    doAuth,
    isLoading,
  };
};

export default useAuthForCredential;
