import { baseBlock, createBlock } from './BaseBlock.js';
import { Block } from './Block.js';

export type DividerBlock = {
  type: 'divider';
  id: string;
};

export function isDividerBlock(block: Block): block is DividerBlock {
  return block.type === 'agenda';
}

export function DividerBlock(id: string): DividerBlock {
  return baseBlock('divider', id, {});
}

export function createDividerBlock(): DividerBlock {
  return createBlock('divider', {});
}
