import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function EyeCrossIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M11.9997 19C12.9457 19 13.8097 18.897 14.5977 18.719L12.8407 16.962C12.5677 16.983 12.2907 17 11.9997 17C6.64874 17 4.57573 13.154 4.07373 12C4.45068 11.1588 4.95978 10.3833 5.58173 9.70297L4.18373 8.30497C2.64573 9.97197 2.06273 11.651 2.05173 11.684C1.98276 11.8893 1.98276 12.1116 2.05173 12.317C2.07273 12.383 4.36673 19 11.9997 19ZM11.9997 4.99997C10.1627 4.99997 8.65373 5.39597 7.39573 5.98097L3.70673 2.29297L2.29273 3.70697L20.2927 21.707L21.7067 20.293L18.3877 16.974C21.0017 15.023 21.9347 12.359 21.9487 12.317C22.0177 12.1116 22.0177 11.8893 21.9487 11.684C21.9267 11.617 19.6327 4.99997 11.9997 4.99997ZM16.9717 15.558L14.6917 13.278C14.8817 12.888 14.9997 12.459 14.9997 12C14.9997 10.359 13.6407 8.99997 11.9997 8.99997C11.5407 8.99997 11.1117 9.11797 10.7227 9.30897L8.91473 7.50097C9.90725 7.16038 10.9504 6.99097 11.9997 6.99997C17.3507 6.99997 19.4237 10.846 19.9257 12C19.6237 12.692 18.7597 14.342 16.9717 15.558Z"
        fill="#1C1C28"
      />
    </svg>
  );
}
