type BaseAndExtent = {
  anchorNode: Node;
  anchorOffset: number;
  focusNode: Node;
  focusOffset: number;
};

export const baseAndExtent = (
  anchorNode: Node,
  anchorOffset: number,
  focusNode?: Node,
  focusOffset?: number,
): BaseAndExtent => {
  return {
    anchorNode,
    anchorOffset,
    focusNode: focusNode || anchorNode,
    focusOffset:
      typeof focusOffset !== 'undefined' ? focusOffset : anchorOffset,
  };
};

export default BaseAndExtent;
