import { makeMakeApiRequest, RequestError } from '../makeMakeApiRequest.js';
import { createConcurrencyManager } from '../ConcurrencyManager.js';
import { logError } from '../../logging/logger.ts';

const MAX_IMAGE_CONCURRENCY = 10;

// can increment this to cache bust download of resized images
const IMAGE_CACHE_VERSION = 1;

const ImageGetConcurrencyManager = createConcurrencyManager(
  MAX_IMAGE_CONCURRENCY,
);
const createImageApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => {
  return {
    createImage: async ({
      companyId,
      filename,
      contentType,
    }: {
      companyId: string;
      filename: string;
      contentType: string;
    }): Promise<{
      imageId: string;
      writeUrl: string;
    }> => {
      return makeLambdaApiRequest(`/company/${companyId}/image`, {
        method: 'POST',
        body: JSON.stringify({
          filename,
          contentType,
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },

    uploadImageFile: async (uploadUrl: string, file: File): Promise<void> => {
      const result = await fetch(uploadUrl, {
        method: 'PUT',
        body: file,
      });

      if (!result.ok) {
        throw new Error('Uploading image returned non-200 status');
      }
    },

    aiDescribeImage: async (guid: string): Promise<void> => {
      return await makeLambdaApiRequest(`/image/${guid}/ai-describe`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },

    getImage: async (
      guid: string,
      preset: 'large' | 'thumbnail' = 'large',
    ): Promise<string> => {
      const queryString = `?preset=${preset}&v=${IMAGE_CACHE_VERSION}`;

      const url = `/image/${guid}${queryString}`;

      return await ImageGetConcurrencyManager.withConcurrencyLimit(async () => {
        try {
          const blob: Blob = await makeLambdaApiRequest(
            url,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
            true,
          );

          return URL.createObjectURL(blob);
        } catch (e) {
          if (e instanceof RequestError && e.status === 401) {
            // auth expired, throw to re-auth
            throw e;
          }
          logError(e as Error);

          return '';
        }
      });
    },
  };
};

export default createImageApi;
