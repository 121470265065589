import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function PublishIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M16.2398 9.10986L10.5798 14.7699L7.74984 11.9399L6.33984 13.3499L10.5798 17.5899L17.6498 10.5199L16.2398 9.10986Z"
        fill="black"
      />
      <path
        d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM19 19H5V7H19V19Z"
        fill="black"
      />
    </svg>
  );
}
