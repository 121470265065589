// Render a list of text formatting options
// Let the caller know when one of them is selected
//
// Know the current selection so that:
// - it knows where it should be positioned

import HoverNextToPoint from '../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import { isCollapsed } from '../../selection/contentSelection/ContentSelection.ts';
import { Block, isTextBlock } from 'editor-content/Block.js';
import { BlockToBlockRenderable } from '../../blockToBlockRenderable.ts';
import { CoordinatorEvent, PublicEditorEvent } from '../../EditorEvents.ts';
import { TurnIntoable } from '../../../design-system/molecules/TurnIntoMenu.tsx';

export type TextFormattingMenuProps<AvailableBlock extends Block> = {
  dispatch: (
    event: PublicEditorEvent<AvailableBlock> | CoordinatorEvent<AvailableBlock>,
  ) => void;
  focusedBlock: BlockToBlockRenderable<AvailableBlock> | undefined;
  getSelectionRect: () => DOMRect | void;
  turnIntoables: TurnIntoable[];
};

export default function TextSelectionFormattingMenu<
  AvailableBlock extends Block,
>({
  dispatch,
  focusedBlock,
  turnIntoables,
  getSelectionRect,
}: TextFormattingMenuProps<AvailableBlock>) {
  if (
    !focusedBlock ||
    !focusedBlock.selection ||
    (isTextBlock(focusedBlock.block) && isCollapsed(focusedBlock.selection))
  ) {
    return null;
  }

  return (
    <HoverNextToPoint
      usePortal
      getPoint={(container) => {
        const containerRect = container.getBoundingClientRect();
        const rect = getSelectionRect();
        if (!rect) return null;

        return [
          (rect.left + rect.right) / 2 - containerRect.width / 2,
          rect.bottom + 16,
        ];
      }}
    >
      <focusedBlock.FormattingMenu
        block={focusedBlock.block as unknown as never}
        selection={focusedBlock.selection}
        dispatch={dispatch}
        turnIntoables={turnIntoables}
      />
    </HoverNextToPoint>
  );
}
