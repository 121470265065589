import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function YellowStarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M7.6248 6.39961L11.9998 0.724609L16.3748 6.39961L23.2248 8.69961L18.8998 14.8246L19.0748 21.6496L11.9998 19.6746L4.9248 21.6496L5.0998 14.7996L0.799805 8.69961L7.6248 6.39961Z"
        fill="#FFCC00"
      />
    </svg>
  );
}
