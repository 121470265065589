import useDocumentEventListener from '../../../junkDrawer/useDocumentEventListener.js';

export default function useCopyPasteHandlers({
  onPaste,
  onCut,
  onCopy,
}: {
  onPaste: (clipboardData: DataTransfer) => void;
  onCut: (clipboardData: DataTransfer) => boolean;
  onCopy: (clipboardData: DataTransfer) => boolean;
}) {
  useDocumentEventListener(
    'paste',
    (e) => {
      if (!e.clipboardData) return;

      onPaste(e.clipboardData);

      e.preventDefault();
    },
    [onPaste],
  );

  useDocumentEventListener(
    'cut',
    (e) => {
      if (!e.clipboardData) return;

      const handled = onCut(e.clipboardData);
      if (handled) {
        e.preventDefault();
      }
    },
    [onCut],
  );

  useDocumentEventListener(
    'copy',
    (e) => {
      if (!e.clipboardData) return;

      const handled = onCopy(e.clipboardData);
      if (handled) {
        e.preventDefault();
      }
    },
    [onCopy],
  );
}
