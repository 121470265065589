import Button from '../../design-system/atoms/Button.tsx';

const uiError = new Error('UI Error');
uiError.stack =
  'UI Error Stack \n   at Button.onClick (http://z.zeck.app/static/js/bundle.js:1:1)';

const TestPage = () => {
  return (
    <>
      <Button
        size="large"
        color="danger"
        onClick={() => {
          throw new Error('UI Error');
        }}
      >
        UI Error
      </Button>
    </>
  );
};

export default TestPage;
