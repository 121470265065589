import {
  createBulletedListItemBlock,
  createCartaCapTableBlock,
  createDividerBlock,
  createFileBlock,
  createHeadingBlock,
  createImageBlock,
  createLabelBlock,
  createNumberedListItemBlock,
  createParagraphBlock,
  createVideoBlock,
} from 'editor-content/Block.js';
import React from 'react';
import { ElementAndData } from '../../../junkDrawer/useElementAndDataArray.js';
import { HydratedBlock } from '../../../types/HydratedBlock.js';
import {
  apiKey,
  clientId,
} from '../../../pages/zeck/editor/addBlock/integrationsModal/utils/google/googleEnvVariables.js';
import ImageUploadModal from '../../../pages/zeck/edit/image/ImageUploadModal.js';
import FileUploadModal from '../../domFacing/components/FileUploadModal.js';
import EmbedVideoModal from '../../../pages/zeck/editor/addBlock/EmbedVideoModal.js';
import IntegrationsModal from '../../../pages/zeck/editor/addBlock/integrationsModal/IntegrationsModal.js';
import { compressTableBlock } from 'editor-content/TableBlock.js';
import { Company } from '../../../types/Company.js';
import { IntegrationListItemData } from '../../../api/endpoints/createIntegrationApi.js';
import { createOutstandingVoteBlock } from '../../../VoteBlockHydrated.js';
import { createAgendaBlock } from 'editor-content/AgendaBlock.js';
import { AddingBlockExperience, useAddBlock } from '../AddBlockExperience.js';
import Toast from '../../../design-system/molecules/toast/Toast.tsx';
import useToast from '../../../design-system/molecules/toast/useToast.ts';
import { AddBlockItem } from '../AddBlockConfiguration.js';
import { FocusedBlock } from '../../actions/pressForwardSlash.ts';

export const useAddBlockZeck = ({
  blocksWithEl,
  leftGutterRef,
  onAddNewBlock,
  onReplaceNewBlock,
  onConfigureComplexBlock,
  onForwardSlash,
  company,
  integrationData,
}: {
  blocksWithEl: ElementAndData<HydratedBlock>[];
  leftGutterRef: React.RefObject<HTMLElement>;
  onAddNewBlock: (targetIndex: number, newBlock: HydratedBlock) => void;
  onReplaceNewBlock: (
    newContent: HydratedBlock[],
    targetBlockId: string,
  ) => void;
  onConfigureComplexBlock: () => void;
  onForwardSlash: () => FocusedBlock | void;
  // props we don't like
  company: Pick<Company, 'id'>;
  integrationData: IntegrationListItemData;
}) => {
  const hasGoogleIntegration = !!clientId && !!apiKey;

  const { showToast, ...toast } = useToast();

  const options: (AddBlockItem<HydratedBlock> | false)[] = [
    {
      type: 'block',
      label: 'Heading',
      blockType: 'heading',
      description: 'A large text heading',
      iconName: 'h1',
      createNewBlock: () => [createHeadingBlock([])],
    },
    {
      type: 'block',
      label: 'Label',
      blockType: 'label',
      description: 'Label a section of content',
      iconName: 'label',
      createNewBlock: () => [createLabelBlock([])],
    },
    {
      type: 'complex-block',
      label: 'Image',
      blockType: 'image',
      description: 'A photo or graphic',
      iconName: 'image',
      renderAdding: ({ onAdd, onCancel }) => (
        <ImageUploadModal
          isOpen
          company={company}
          onUploadSuccess={({ guid, width, height }) => {
            onAdd([
              createImageBlock(guid, '', 'column', 'center', {
                width,
                height,
              }),
            ]);
          }}
          onClose={onCancel}
          showToast={showToast}
        />
      ),
    },
    {
      type: 'block',
      label: 'Bulleted List',
      blockType: 'bulleted-list-item',
      description: 'A simple list of items',
      iconName: 'bulletedList',
      createNewBlock: () => [createBulletedListItemBlock([], 0)],
    },
    {
      type: 'block',
      label: 'Numbered List',
      blockType: 'numbered-list-item',
      description: 'A list with numerical order',
      iconName: 'numberedList',
      createNewBlock: () => [createNumberedListItemBlock([], 0)],
    },
    hasGoogleIntegration && {
      type: 'complex-block',
      label: 'Google Sheets',
      blockType: 'googleSheets',
      description: 'Add content from a Sheets file',
      iconName: 'googleSpreadsheet',
      renderAdding: ({ onAdd, onCancel }) => (
        <IntegrationsModal
          isOpen={true}
          integrationData={integrationData}
          integrationType="google"
          onCreateTable={(table) => {
            onAdd([compressTableBlock(table)]);
          }}
          onAddCapTable={(capTable) => {
            onAdd([createCartaCapTableBlock(capTable)]);
          }}
          onClose={onCancel}
        />
      ),
    },

    {
      type: 'complex-block',
      label: 'Microsoft Excel',
      blockType: 'microsoftExcel',
      description: 'Add content from an Excel file',
      iconName: 'microsoftExcel',
      renderAdding: ({ onAdd, onCancel }) => (
        <IntegrationsModal
          isOpen={true}
          integrationData={integrationData}
          integrationType="excelUpload"
          onCreateTable={(table) => {
            onAdd([compressTableBlock(table)]);
          }}
          onAddCapTable={(capTable) => {
            onAdd([createCartaCapTableBlock(capTable)]);
          }}
          onClose={onCancel}
        />
      ),
    },
    {
      type: 'complex-block',
      label: 'Carta Cap Table',
      blockType: 'carta-cap-table',
      description: 'Add a summary cap table from Carta',
      iconName: 'carta',
      renderAdding: ({ onAdd, onCancel }) => (
        <IntegrationsModal
          isOpen={true}
          integrationData={integrationData}
          integrationType="cartaCapTable"
          onCreateTable={(table) => {
            onAdd([compressTableBlock(table)]);
          }}
          onAddCapTable={(capTable) => {
            onAdd([createCartaCapTableBlock(capTable)]);
          }}
          onClose={onCancel}
        />
      ),
    },
    {
      type: 'block',
      label: 'Divider',
      blockType: 'divider',
      description: 'A visual divider to break up content',
      iconName: 'divider',
      createNewBlock: () => [createDividerBlock()],
    },
    {
      type: 'complex-block',
      label: 'Video',
      blockType: 'video',
      description: 'Embed a Youtube or Vimeo video',
      iconName: 'video',
      renderAdding: ({ onAdd, onCancel }) => (
        <EmbedVideoModal
          isOpen={true}
          onEmbedVideo={({ url }) => {
            onAdd([createVideoBlock(url)]);
          }}
          onClose={onCancel}
        />
      ),
    },
    {
      type: 'complex-block',
      label: 'File',
      blockType: 'file',
      description: 'Upload a file',
      iconName: 'paperclip',
      renderAdding: ({ onAdd, onCancel }) => (
        <FileUploadModal
          isOpen={true}
          company={company}
          onUploadSuccess={(file) => {
            onAdd([createFileBlock(file.guid, file.filename)]);
          }}
          onRequestClose={onCancel}
        />
      ),
    },
    { type: 'separator', label: 'ZECK BLOCKS™' },
    {
      type: 'block',
      label: 'Vote',
      blockType: 'vote',
      description: 'Review, approve, and record votes',
      iconName: 'vote',
      createNewBlock: () => [createOutstandingVoteBlock('', '')],
    },
    {
      type: 'block',
      label: 'Meeting Agenda',
      blockType: 'agenda',
      description: 'Build an agenda from sections',
      iconName: 'calendar',
      createNewBlock: () => [createAgendaBlock('', '', [])],
    },
  ];

  const addBlockBehavior = useAddBlock({
    blocksWithEl,
    leftGutterRef,
    onAddNewBlock: (targetIndex) => {
      const newBlock = createParagraphBlock([]);
      onAddNewBlock(targetIndex, newBlock);
      return newBlock.id;
    },
    onReplaceNewBlock,
    onForwardSlash,
    onConfigureComplexBlock,
    addBlockOptions: options,
  });
  return {
    toast,
    ...addBlockBehavior,
  };
};

export const AddingBlockExperienceZeck = ({
  addBlockBehavior,
}: {
  addBlockBehavior: ReturnType<typeof useAddBlockZeck>;
}) => {
  return (
    <>
      <AddingBlockExperience addBlockBehavior={addBlockBehavior} />
      <Toast {...addBlockBehavior.toast} />
    </>
  );
};
