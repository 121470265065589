import styles from './MicrosoftDocumentNotFound.module.scss';
import {
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalPanel,
} from '../../../../../../design-system/organisms/Modal.tsx';
import OutlineButton from '../../../../../../design-system/atoms/OutlineButton.tsx';
import Button from '../../../../../../design-system/atoms/Button.tsx';
import { MicrosoftExcelIcon } from 'icons';

type MicrosoftDocumentNotFoundProps = {
  onClose: () => void;
  onContinue: () => void;
};

const MicrosoftDocumentNotFound: React.FC<MicrosoftDocumentNotFoundProps> = ({
  onClose,
  onContinue,
}) => {
  return (
    <ModalPanel className={styles.panel}>
      <ModalHeader className={styles.errorHeader}>
        <MicrosoftExcelIcon /> Unable to Refresh
      </ModalHeader>
      <ModalBody>
        The file or sheet for this table no longer exists, or you no longer have
        access to it.
      </ModalBody>
      <ModalActions className={styles.errorActions}>
        <OutlineButton onClick={onClose} size="medium" color="secondary">
          Cancel
        </OutlineButton>
        <Button onClick={onContinue} size="medium" color="primary">
          Select Another File
        </Button>
      </ModalActions>
    </ModalPanel>
  );
};

export default MicrosoftDocumentNotFound;
