import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function SignatureIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M22 18.2402H2V16.2402H22V18.2402ZM2.26 13.0702L5.09 10.2402L2.26 7.41024L3.67 6.00024L6.5 8.83024L9.33 6.00024L10.74 7.41024L7.91 10.2402L10.74 13.0702L9.33 14.4802L6.5 11.6502L3.67 14.4802L2.26 13.0702Z"
        fill="#5445FF"
      />
    </svg>
  );
}
