import cond from '../../../../junkDrawer/cond.js';

export default function updateBlockAt<B>(
  content: B[],
  index: number,
  newBlock: B,
): B[] {
  return content.map(
    cond(
      (block, i) => i === index,
      (_block) => newBlock,
      (block) => block,
    ),
  );
}
