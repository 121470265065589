import { useEffect } from 'react';
import useElementAndDataArray from '../../../junkDrawer/useElementAndDataArray.js';

const useScrollToSection = <Section extends { id: string }>(
  zeck: { sections: Section[] },
  currentSection?: Section,
) => {
  const sectionWithRef = useElementAndDataArray(zeck.sections);

  useEffect(() => {
    if (currentSection) {
      const maybeSectionWithRef = sectionWithRef.find(
        ({ data: section }) => section.id === currentSection.id,
      );
      if (maybeSectionWithRef) {
        const { getEl } = maybeSectionWithRef;
        getEl()?.scrollIntoView();
      }
    }

    // sectionWithRef is a new array with every render; so, this useEffect
    // will always be called.
    // We really only want to scroll when currentSection changes.
  }, [currentSection?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return sectionWithRef;
};

export default useScrollToSection;
