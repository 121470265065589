import React from 'react';
import cx from 'classnames';
import styles from './Loader.module.scss';

export type LoaderProps = {
  className?: string;
  size: 'small' | 'medium' | 'large' | 'extra-large';
};

const Loader = React.forwardRef<HTMLDivElement, LoaderProps>(function Loader(
  { className, size },
  forwardedRef,
) {
  return (
    <div
      data-testid="loader"
      ref={forwardedRef}
      className={cx(styles.spinner, className, styles[size])}
    >
      <div />
      <div />
      <div />
    </div>
  );
});

export default Loader;
