import useBreakpoints from '../../../../../services/useBreakpoints.ts';
import cx from 'classnames';
import styles from './Comments.module.scss';
import Button from '../../../../../design-system/atoms/Button.tsx';
import { Icon } from 'icons';
import { CommentsStateForSection } from './useComments/useComments.js';

export type CommentsFooterButtonProps = {
  commentsState: CommentsStateForSection;
  className?: string;
};

export function CommentsFooterButton({
  className,
  commentsState: { commentCount, openSectionComments, openNewComment },
}: CommentsFooterButtonProps) {
  const { isMobile } = useBreakpoints();

  return (
    <div className={cx(isMobile && styles.commentsFooterButton_mobile)}>
      <Button
        color="primary"
        size="large"
        onClick={openNewComment}
        className={cx(styles.comments__footerButton__leftButton)}
      >
        Share Your Thoughts or Questions
      </Button>

      <button
        aria-label="Show Comments"
        data-testid="show-comments"
        type="button"
        onClick={openSectionComments}
        className={cx(
          styles.comments__button,
          styles.comments__footerButton__rightButton,
          className,
        )}
      >
        <Icon name="comment" className={styles.comments__icon} />

        <span className={styles.comments__count}>
          {commentCount} {commentCount === 1 ? 'Comment' : 'Comments'}
        </span>
      </button>
    </div>
  );
}
