import { Navigate, RouteObject } from 'react-router-dom';
import EditorTestPage from '../EditorTestPage.tsx';
import PageLayoutFetchesFolders from '../pages/PageLayoutFetchesFolders.tsx';
import AccessRequestPage from '../pages/accessRequest/AccessRequestPage.tsx';
import AnalyticsPage from '../pages/analytics/AnalyticsPage.tsx';
import BrandKitPage from '../pages/brandKit/BrandKitPage.tsx';
import CompanySettingsPage from '../pages/companySettings/CompanySettingsPage.tsx';
import { NotFoundPage } from '../pages/error/notFound/NotFoundPage.tsx';
import AdminHomepageContainer from '../pages/home/AdminHomepageContainer.tsx';
import MemberHomepageContainer from '../pages/home/MemberHomepageContainer.tsx';
import AuthCompletePage from '../pages/integrations/AuthCompletePage.js';
import ZeckDashboardPage from '../pages/internalAdmin/ZeckDashboardPage.tsx';
import MagicLinkPage from '../pages/magicLink/MagicLinkPage.tsx';
import MeetingMinutesEditorPage from '../pages/meetingMinutes/edit/MeetingMinutesEditorPage.tsx';
import MeetingMinutesListPage from '../pages/meetingMinutes/list/MeetingMinutesListPage.tsx';
import MeetingMinutesPrintPage from '../pages/meetingMinutes/print/MeetingMinutesPrintPage.tsx';
import PeoplePage from '../pages/people/PeoplePage.tsx';
import UserProfilePage from '../pages/profile/UserProfilePage.tsx';
import VotingPage from '../pages/voting/VotingPage.tsx';
import EditSectionPage from '../pages/zeck/EditSectionPage.tsx';
import EditZeckCoverPage from '../pages/zeck/EditZeckCoverPage.tsx';
import EditZeckPage from '../pages/zeck/EditZeckPage.tsx';
import PreviewSectionPage from '../pages/zeck/previewAndPublish/preview/PreviewSectionPage.tsx';
import PreviewZeckPage from '../pages/zeck/previewAndPublish/preview/PreviewZeckPage.tsx';
import PublishedSectionPage from '../pages/zeck/previewAndPublish/publish/PublishedSectionPage.tsx';
import PublishedZeckPage from '../pages/zeck/previewAndPublish/publish/PublishedZeckPage.tsx';
import PageWithAnalytics, {
  PageWithAnalyticsParameters,
} from './PageWithAnalytics.tsx';

export default function makeAuthenticatedRoutes({
  userAndCompany,
  brandKitResource,
  signIn,
  signOut,
  signOutNoRedirect,
  setAccessToken,
}: PageWithAnalyticsParameters): RouteObject[] {
  const commonRoutes = [
    {
      path: '/',
      element: (
        <Navigate to={`/company/${userAndCompany.activeCompany.id}`} replace />
      ),
    },
    {
      path: '/signup',
      element: <Navigate to={`/company/${userAndCompany.activeCompany.id}`} />,
    },
    {
      path: '/signin',
      element: (
        <Navigate to={`/company/${userAndCompany.activeCompany.id}`} replace />
      ),
    },
    {
      path: '/login',
      element: (
        <Navigate to={`/company/${userAndCompany.activeCompany.id}`} replace />
      ),
    },
    {
      path: '/company/:companyId/s/:slug/edit',
      element: <EditSectionPage {...{ userAndCompany, onLogout: signOut }} />,
    },
    {
      path: '/company/:companyId/s/:slug/preview',
      element: (
        <PreviewSectionPage
          {...{ userAndCompany, brandKitResource, onLogout: signOut }}
        />
      ),
    },
    {
      path: '/company/:companyId/s/:slug',
      element: (
        <PublishedSectionPage
          {...{ userAndCompany, onClickLogout: signOut, brandKitResource }}
        />
      ),
    },
    {
      path: '/profile',
      element: (
        <UserProfilePage
          userAndCompany={userAndCompany}
          onLogout={signOut}
          onLogoutNoRedirect={signOutNoRedirect}
        />
      ),
    },
    {
      path: '/company/:companyId/:slug/cover/edit',
      element: <EditZeckCoverPage {...{ userAndCompany, brandKitResource }} />,
    },
    {
      path: '/company/:companyId/:slug/edit',
      element: <EditZeckPage {...{ userAndCompany, brandKitResource }} />,
    },
    {
      path: '/grant-access/:accessRequestId',
      element: <AccessRequestPage userAndCompany={userAndCompany} />,
    },
    {
      path: '/company/:companyId/:slug/preview',
      element: (
        <PreviewZeckPage
          {...{ method: 'preview', userAndCompany, brandKitResource }}
        />
      ),
    },
    {
      path: '/company/:companyId/:slug',
      element: (
        <PublishedZeckPage
          {...{ userAndCompany, onClickLogout: signOut, brandKitResource }}
        />
      ),
    },
    {
      path: '/magic-link/:id',
      element: (
        <PageWithAnalytics
          pageName="magic_link"
          userAndCompany={userAndCompany}
        >
          <MagicLinkPage
            onSignIn={signIn}
            setAccessToken={setAccessToken}
            existingSignin
          />
        </PageWithAnalytics>
      ),
    },
    {
      path: '/thecon',
      element: (
        <ZeckDashboardPage
          {...{
            user: userAndCompany.user,
            setActiveCompany: userAndCompany.setActiveCompany,
            userCompanies: userAndCompany.userCompanies,
          }}
        />
      ),
    },
    {
      path: '/auth-complete/:provider/:credentialId',
      element: <AuthCompletePage />,
    },
    { path: '*', element: <NotFoundPage to="/" /> },
    {
      path: '/company/:companyId/people',
      element: (
        <PeoplePage userAndCompany={userAndCompany} onClickLogout={signOut} />
      ),
    },
  ];

  const manageMinutesRoutes = [
    {
      path: '/company/:companyId/voting',
      element: (
        <PageLayoutFetchesFolders
          {...{
            title: 'voting',
            userAndCompany,
            onClickLogout: signOut,
            activePage: 'minutes_and_voting',
          }}
        >
          <VotingPage
            userAndCompany={userAndCompany}
            companyId={userAndCompany.activeCompany.id}
          />
        </PageLayoutFetchesFolders>
      ),
    },
    {
      path: '/minutes-list',
      element: (
        <Navigate
          to={`/company/${userAndCompany.activeCompany.id}/minutes`}
          replace
        />
      ),
    },
    {
      path: '/company/:companyId/minutes',
      element: (
        <PageLayoutFetchesFolders
          {...{
            title: 'meeting minutes',
            userAndCompany,
            onClickLogout: signOut,
            activePage: 'minutes_and_voting',
          }}
        >
          <MeetingMinutesListPage userAndCompany={userAndCompany} />
        </PageLayoutFetchesFolders>
      ),
    },
    {
      path: '/company/:companyId/minutes/:meetingMinutesId',
      element: <MeetingMinutesEditorPage userAndCompany={userAndCompany} />,
    },
    {
      path: '/company/:companyId/minutes/:meetingMinutesId/print',
      element: <MeetingMinutesPrintPage userAndCompany={userAndCompany} />,
    },
  ];
  const manageBrandRoutes = [
    {
      path: '/company/:companyId/brand-kit',
      element: (
        <BrandKitPage
          userAndCompany={userAndCompany}
          brandKitResource={brandKitResource}
          onClickLogout={signOut}
        />
      ),
    },
  ];

  const managePeopleRoutes = [
    {
      path: '/company/:companyId/people',
      element: (
        <PeoplePage userAndCompany={userAndCompany} onClickLogout={signOut} />
      ),
    },
  ];
  const manageZeckRoutes = [
    {
      path: '/editor',
      element: <EditorTestPage />,
    },
    {
      path: '/company/:companyId',
      element: (
        <AdminHomepageContainer
          {...{
            onClickLogout: signOut,
            userAndCompany: userAndCompany,
            source: 'home',
          }}
        />
      ),
    },
    {
      path: '/company/:companyId/:slug/analytics',
      element: (
        <AnalyticsPage
          userAndCompany={userAndCompany}
          onClickLogout={signOut}
        />
      ),
    },
    {
      path: '/company/:companyId/folder/:folderId',
      element: (
        <AdminHomepageContainer
          {...{
            onClickLogout: signOut,
            userAndCompany: userAndCompany,
            source: 'folder',
          }}
        />
      ),
    },

    {
      path: '/company/:companyId/settings',
      element: (
        <CompanySettingsPage
          userAndCompany={userAndCompany}
          onClickLogout={signOut}
        />
      ),
    },

    {
      path: '/company/:companyId/zeck-trash',
      element: (
        <AdminHomepageContainer
          {...{
            onClickLogout: signOut,
            userAndCompany: userAndCompany,
            source: 'zeck-trash',
          }}
        />
      ),
    },
  ];

  const memberRoutes = [
    {
      path: '/company/:companyId',
      element: (
        <MemberHomepageContainer
          {...{
            onClickLogout: signOut,
            userAndCompany: userAndCompany,
          }}
        />
      ),
    },
    {
      path: '/company/:companyId/folder/:folderId',
      element: (
        <MemberHomepageContainer
          {...{
            onClickLogout: signOut,
            userAndCompany: userAndCompany,
          }}
        />
      ),
    },
  ];

  return [
    ...(userAndCompany.activeCompany.permissions.canManageZecks
      ? manageZeckRoutes
      : memberRoutes),
    ...(userAndCompany.activeCompany.permissions.canManageBrand
      ? manageBrandRoutes
      : []),
    ...(userAndCompany.activeCompany.permissions.canManagePeople
      ? managePeopleRoutes
      : []),
    ...(userAndCompany.activeCompany.permissions.canManageMinutes
      ? manageMinutesRoutes
      : []),
    ...commonRoutes,
  ];
}
