import Point from './Point.js';

export type Rect = [startPoint: Point, endPoint: Point];
export const Rect = (x0: number, y0: number, x1: number, y1: number): Rect => [
  [x0, y0],
  [x1, y1],
];

export const getRectFromEl = (el: Element): Rect => {
  const rect = el.getBoundingClientRect();
  return [
    [rect.left, rect.top],
    [rect.right, rect.bottom],
  ];
};

export const isIntersecting = (rect1: Rect, rect2: Rect): boolean => {
  if (
    rect1.some((point) => point.some(isNaN)) ||
    rect2.some((point) => point.some(isNaN))
  ) {
    return false;
  }

  const left1 = Math.min(rect1[0][0], rect1[1][0]);
  const right1 = Math.max(rect1[0][0], rect1[1][0]);
  const top1 = Math.min(rect1[0][1], rect1[1][1]);
  const bottom1 = Math.max(rect1[0][1], rect1[1][1]);

  const left2 = Math.min(rect2[0][0], rect2[1][0]);
  const right2 = Math.max(rect2[0][0], rect2[1][0]);
  const top2 = Math.min(rect2[0][1], rect2[1][1]);
  const bottom2 = Math.max(rect2[0][1], rect2[1][1]);

  return !(
    right1 < left2 ||
    left1 > right2 ||
    bottom1 < top2 ||
    top1 > bottom2
  );
};

export default Rect;
