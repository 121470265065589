import { compact } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import useDocumentEventListener from '../../junkDrawer/useDocumentEventListener.ts';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import {
  AddBlockItem,
  ComplexBlockItem,
  SeparatorItem,
  SimpleBlockItem,
} from './AddBlockConfiguration.js';
import AddBlockMenuInfoLabel from './AddBlockMenuInfoLabel.tsx';
import AddBlockMenuItem from './AddBlockMenuItem.js';
import AddBlockMenuSeparator from './AddBlockMenuSeparator.js';
import AddingBlockMenu from './AddingBlockMenu.js';

type InfoLabelItem = {
  type: 'info';
  label: string;
};

type AddBlockOptions<BlockType> =
  | InfoLabelItem
  | SeparatorItem
  | SimpleBlockItem<BlockType>
  | ComplexBlockItem<BlockType>;

export function filterAddMenuOptions<BlockType>(
  compactedOptions: AddBlockItem<BlockType>[],
  blockFilter: string,
): AddBlockOptions<BlockType>[] {
  if (!blockFilter || !blockFilter.length) {
    return compactedOptions;
  }

  const filterRegex = new RegExp(blockFilter, 'i');
  const filtered = compactedOptions.filter(
    (block) =>
      block && block.label.match(filterRegex) && block.type !== 'separator',
  );

  const noResultsFoundLabel: InfoLabelItem = {
    type: 'info',
    label: 'No results found',
  };
  return filtered.length ? filtered : [noResultsFoundLabel];
}

export function buildAddableBlocks<BlockType>(
  filteredAddMenuOptions: AddBlockOptions<BlockType>[],
  cursor: number,
  selectedMenuRef: React.RefObject<HTMLButtonElement>,
  onPickBlockTypeSimple: (newContent: BlockType[]) => void,
  onPickBlockTypeComplex: (blockType: string) => void,
): React.ReactNode {
  return filteredAddMenuOptions.map((value, index) => {
    const selected = cursor == index;

    switch (value.type) {
      case 'info':
        return <AddBlockMenuInfoLabel key={value.label} label={value.label} />;
      case 'separator':
        return (
          <AddBlockMenuSeparator key={value.label}>
            {value.label}
          </AddBlockMenuSeparator>
        );

      case 'block':
        return (
          <AddBlockMenuItem
            key={value.label}
            iconName={value.iconName}
            onClick={() => {
              onPickBlockTypeSimple(value.createNewBlock());
            }}
            label={value.label}
            description={value.description}
            selected={selected}
            selectedMenuRef={selected ? selectedMenuRef : undefined}
          />
        );
      case 'complex-block':
        return (
          <AddBlockMenuItem
            key={value.label}
            iconName={value.iconName}
            onClick={() => {
              onPickBlockTypeComplex(value.blockType);
            }}
            label={value.label}
            description={value.description}
            selected={selected}
            selectedMenuRef={selected ? selectedMenuRef : undefined}
          />
        );
    }
  });
}

const AddingBlockMenuWithItems = <BlockType extends { id: string }>({
  targetBlockId,
  blocksWithEl,
  addBlockOptions,
  onPickBlockTypeSimple,
  onPickBlockTypeComplex,
  onCancel,
}: {
  targetBlockId: string;
  blocksWithEl: ElementAndData<BlockType>[];
  addBlockOptions: (AddBlockItem<BlockType> | false | null | undefined)[];
  onPickBlockTypeSimple: (newContent: BlockType[]) => void;
  onPickBlockTypeComplex: (blockType: string) => void;
  onCancel: () => void;
}) => {
  const [cursor, setCursor] = useState<number>(0);
  const selectedMenuRef = useRef<HTMLButtonElement>(null);
  const addMenuRef = useRef<HTMLDivElement>(null);
  const compactedOptions = compact(addBlockOptions);
  const [blockFilter, setBlockFilter] = useState<string>('');
  const [filteredAddMenuOptions, setFilteredAddMenuOptions] =
    useState<AddBlockOptions<BlockType>[]>(compactedOptions);

  // addable blocks are the blocks that are displayed in the menu, built from the filtered blocks
  const addableBlocks = buildAddableBlocks<BlockType>(
    filteredAddMenuOptions,
    cursor,
    selectedMenuRef,
    onPickBlockTypeSimple,
    onPickBlockTypeComplex,
  );

  useEffect(() => {
    const selectedMenuRect = selectedMenuRef.current?.getBoundingClientRect();
    const addMenuRect = addMenuRef.current?.getBoundingClientRect();

    if (!selectedMenuRect || !addMenuRect) {
      return;
    }
    if (selectedMenuRect.top < addMenuRect.top) {
      selectedMenuRef.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
    if (selectedMenuRect.bottom > addMenuRect.bottom) {
      selectedMenuRef.current?.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }
  }, [cursor]);

  useEffect(() => {
    const filteredOptions = filterAddMenuOptions(compactedOptions, blockFilter);
    if (filteredOptions.length === filteredAddMenuOptions.length) return;

    setFilteredAddMenuOptions(filteredOptions);
    setCursor(0);
  }, [
    compactedOptions,
    filteredAddMenuOptions,
    blockFilter,
    setFilteredAddMenuOptions,
  ]);

  useDocumentEventListener(
    'keydown',
    (e) => {
      const option = filteredAddMenuOptions[cursor];
      const isSkipBlockItem = (cursor: number): boolean => {
        const option = filteredAddMenuOptions[cursor];
        return !!option && option.type === 'separator';
      };
      switch (e.key) {
        case 'ArrowDown':
          setCursor((cursor) => {
            if (cursor === filteredAddMenuOptions.length - 1) {
              return 0;
            }
            return isSkipBlockItem(cursor + 1) ? cursor + 2 : cursor + 1;
          });
          e.stopPropagation();
          e.preventDefault();
          return;
        case 'ArrowUp':
          setCursor((cursor) => {
            if (cursor === 0) {
              return filteredAddMenuOptions.length - 1;
            }

            return isSkipBlockItem(cursor - 1) ? cursor - 2 : cursor - 1;
          });
          e.preventDefault();
          e.stopPropagation();
          return;
        case 'Enter':
          if (option && option.type === 'block') {
            onPickBlockTypeSimple(option.createNewBlock());
          } else if (option && option.type === 'complex-block') {
            onPickBlockTypeComplex(option.blockType);
          }
          e.preventDefault();
          e.stopPropagation();
          return;
        case 'Escape': // all other are keys cause an onCancel
          onCancel();
          e.stopPropagation();
          return;
        case 'Shift':
        case 'Meta':
        case 'Alt':
        case 'Control':
          return;
        case ' ':
          // if the key would result in a filter with a double space, cancel
          if (blockFilter[blockFilter.length - 1] === ' ') {
            onCancel();
            return;
          }
          setBlockFilter(blockFilter.concat(e.key.toLowerCase()));
          return;

        case 'Backspace':
          if (blockFilter.length) {
            setBlockFilter(blockFilter.slice(0, -1));
          } else {
            onCancel();
          }
          return;
      }

      // if key is alphanumeric, add it to the filter
      if (e.key.match(/^\w$/)) {
        setBlockFilter(blockFilter.concat(e.key.toLowerCase()));
        return;
      }

      onCancel();
    },
    [
      cursor,
      addBlockOptions,
      onPickBlockTypeSimple,
      onPickBlockTypeComplex,
      onCancel,
    ],
    true, // capture
  );

  return (
    <AddingBlockMenu
      addMenuRef={addMenuRef}
      blocksWithEl={blocksWithEl}
      targetBlockId={targetBlockId}
      onCancel={onCancel}
      addableBlocks={addableBlocks}
    />
  );
};

export default AddingBlockMenuWithItems;
