import { useState } from 'react';
import ReactModal from 'react-modal';
import styles from '../../pages/home/CreateZeckModal.module.scss';
import IconButton from '../../design-system/atoms/IconButton.tsx';
import Button from '../../design-system/atoms/Button.tsx';
import { Icon } from 'icons';
import useBreakpoints from '../../services/useBreakpoints.ts';

type ModalProps = {
  isOpen: boolean;
  onSubmit: (value: string) => Promise<void>;
  onClose: () => void;
  title: string;
  placeholder: string;
};

const CreateModal: React.FC<ModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
  title,
  placeholder,
  ...otherProps
}) => {
  const { isMobile } = useBreakpoints();

  return (
    <ReactModal
      {...{
        ...otherProps,
        onRequestClose: onClose,
        className: isMobile ? styles.modal_mobile : styles.modal,
        overlayClassName: styles.modalOverlay,
        isOpen,
      }}
    >
      <CreateModalBody
        onClose={onClose}
        onSubmit={onSubmit}
        title={title}
        placeholder={placeholder}
      />
    </ReactModal>
  );
};

export default CreateModal;

type CreateModalBodyProps = {
  onClose: () => void;
  onSubmit: (value: string) => Promise<void>;
  title: string;
  placeholder: string;
};

export function CreateModalBody({
  onClose,
  onSubmit,
  title,
  placeholder,
}: CreateModalBodyProps) {
  const { isMobile } = useBreakpoints();
  const [value, setValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <>
      {isMobile ? (
        <>
          <div className={styles.modal__header}>
            <IconButton
              {...{
                name: 'close',
                'aria-label': 'close modal',
                onClick: onClose,
              }}
            />
          </div>
          <div className={styles.modal__heading}>{title}</div>
        </>
      ) : (
        <div className={styles.modal__header}>
          <div className={styles.modal__heading}>{title}</div>
          <IconButton
            {...{
              name: 'close',
              'aria-label': 'close modal',
              onClick: onClose,
            }}
          />
        </div>
      )}
      <div data-testid="create-modal">
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setIsSubmitting(true);
            await onSubmit(value);
            setIsSubmitting(false);
          }}
        >
          <input
            {...{
              type: 'text',
              className: styles.specialInput,
              placeholder,
              'data-testid': 'create-modal-name-input',
              disabled: isSubmitting,
              onChange(e) {
                setValue(e.currentTarget.value);
              },
              value: value,
            }}
          />
          <Button
            className={styles.submitButton}
            color="primary"
            size="large"
            disabled={!value || isSubmitting}
          >
            OK <Icon name="checkmark" />
          </Button>
        </form>
      </div>
    </>
  );
}
