import { useNavigate } from 'react-router-dom';
import useApi from '../../api/useApi.ts';
import IconButton from '../../design-system/atoms/IconButton.tsx';
import getIdFromSlug from '../../getIdFromSlug.ts';
import { truncate } from '../../junkDrawer/truncate.ts';
import useDocumentTitleFromResult from '../../junkDrawer/useDocumentTitleFromResult.ts';
import { isSuccessResult } from '../../result/Result.ts';
import { useRequiredParams } from '../../routing/useRequiredParams.ts';
import { zeckHomePath } from '../../services/Paths.ts';
import useFetch from '../../services/useFetch/useFetch.ts';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';
import LoaderSpinnerOverlay from '../LoaderSpinnerOverlay.tsx';
import AnalyticsWrapper from '../zeck/edit/analytics/AnalyticsWrapper.tsx';
import styles from './AnalyticsPage.module.scss';

type AnalyticsPageProps = {
  userAndCompany: UserAndCompany;
  onClickLogout: () => void;
};

const AnalyticsPage: React.FC<AnalyticsPageProps> = () => {
  const { fetchZeck } = useApi();
  const { slug, companyId } = useRequiredParams('companyId', 'slug');
  const zeckId = getIdFromSlug(slug);
  const navigate = useNavigate();

  const zeckResult = useFetch(() => fetchZeck(zeckId), [zeckId, companyId]);

  useDocumentTitleFromResult(zeckResult, 'Analytics');

  if (!isSuccessResult(zeckResult)) {
    return <LoaderSpinnerOverlay />;
  }

  const zeck = zeckResult.data;
  const title = `${truncate(zeck.name, 25)} Analytics`;

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <div className={styles.header__close}>
          <IconButton
            name="close"
            aria-label="Back to Folder"
            onClick={() => {
              navigate(zeckHomePath(zeck));
            }}
          />
        </div>
        <div className={styles.header__title}>{title}</div>
      </div>
      <div className={styles.wrapper}>
        <AnalyticsWrapper zeck={zeck} />
      </div>
      <div className={styles.bottomSpacer}></div>
    </div>
  );
};

export default AnalyticsPage;
