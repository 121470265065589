import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function DangerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8686 4.4096L20.9166 18.5046C21.1904 18.9842 21.0236 19.595 20.544 19.8689C20.393 19.9551 20.2221 20.0005 20.0482 20.0005H3.95215C3.39986 20.0005 2.95215 19.5528 2.95215 19.0005C2.95215 18.8266 2.9975 18.6557 3.08374 18.5046L11.1318 4.4096C11.4056 3.92999 12.0164 3.76319 12.496 4.03703C12.6513 4.12569 12.7799 4.25434 12.8686 4.4096ZM11.5072 15.4225H12.4952L13.0022 9.01348L11.0002 9.00048L11.5072 15.4225ZM11.0912 18.1785V16.3845H12.9112V18.1785H11.0912Z"
        fill="#FF8800"
      />
    </svg>
  );
}
