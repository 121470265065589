import { ElementAndData } from '../useElementAndDataArray.js';
import Rect, { getRectFromEl } from '../../domHelpers/Rect.js';

export default function elAndDataToRect<T>(elAndData: ElementAndData<T>) {
  return {
    ...elAndData,
    getRect: (): Rect => {
      const el = elAndData.getEl();
      if (!el) {
        return [
          [0, 0],
          [0, 0],
        ];
      }

      return getRectFromEl(el);
    },
  };
}
