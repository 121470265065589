import { useMediaQuery } from 'react-responsive';

const useBreakpoints = () => {
  const isMobile = useMediaQuery({ maxWidth: 735 });
  const isTablet = useMediaQuery({ minWidth: 736, maxWidth: 1024 });

  return { isMobile, isTablet };
};

export default useBreakpoints;
