import values from 'lodash/values.js';
import mapValues from 'lodash/mapValues.js';
import {
  ErrorResult,
  isErrorResult,
  isLoadingResult,
  isMutatingResult,
  isSuccessResult,
  LoadingResult,
  MutatingResult,
  Result,
  SuccessResult,
} from './Result.js';

// This is too generic for typescript to be helpful. Or at least, too tricky for us to figure out
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const sequenceSResult: <
  Obj extends Record<string, Result<unknown, unknown>>,
>(
  obj: Obj,
) => Result<ObjectUnwrapResultError<Obj>, ObjectUnwrapResultSuccess<Obj>> = (
  obj,
) => {
  if (values(obj).some(isErrorResult)) {
    return ErrorResult(
      mapValues(obj, (result) =>
        isErrorResult(result) ? result.error : undefined,
      ),
    );
  }

  if (values(obj).some(isLoadingResult)) {
    return LoadingResult();
  }

  if (values(obj).some(isMutatingResult)) {
    return MutatingResult(
      mapValues(obj, (result) =>
        isMutatingResult(result) || isSuccessResult(result)
          ? result.data
          : undefined,
      ),
    );
  }

  return SuccessResult(
    mapValues(obj, (result) =>
      isSuccessResult(result) ? result.data : undefined,
    ),
  );
};

type ObjectUnwrapResultSuccess<
  Obj extends Record<string, Result<unknown, unknown>>,
> = {
  [K in keyof Obj]: Obj[K] extends Result<unknown, infer T> ? T : never;
};

// MESSAGE TO FUTURE GENERATIONS:
// We have made an arbitrary decision to collect the errors onto a similar structured object.
// This is slightly inconvenient b/c the types don't guarantee that one of the values will have error data.
// Fp-ts just takes the first error, which guarantees you have error data.
// Our decision is arbitrary because we aren't actually using this capability anywhere.
// Feel free to change it if you want it to be the other way
type ObjectUnwrapResultError<
  Obj extends Record<string, Result<unknown, unknown>>,
> = {
  [K in keyof Obj]: Obj[K] extends Result<infer E, unknown>
    ? E | undefined
    : never;
};
