import cx from 'classnames';
import styles from './TextButton.module.scss';

export type TextButtonProps = {
  className?: string;
  onClick: () => void;
  children: React.ReactNode;
};

export default function TextButton({
  className,
  onClick,
  children,
}: TextButtonProps) {
  return (
    <button className={cx(styles.button, className)} onClick={onClick}>
      {children}
    </button>
  );
}
