import { SectionLinkable } from 'editor-content/html/SectionLinkable.ts';
import {
  sectionEditPath,
  sectionPresentPath,
  sectionPreviewPath,
  sectionPublishPath,
} from '../../../services/Paths.js';
import { isPublishedSection } from '../../../types/PublishedSection.js';
import { PublishedSection, Section } from '../../../types.js';

const modeToPathLookup = {
  edit: sectionEditPath,
  preview: sectionPreviewPath,
  publish: sectionPublishPath,
  present: sectionPresentPath,
};

export function makeSectionLinkable(
  company: { id: string },
  section: Section | PublishedSection,
  mode: 'edit' | 'preview' | 'publish' | 'present',
): SectionLinkable {
  const sectionId = isPublishedSection(section)
    ? section.sectionId
    : section.id;

  return {
    type: 'section',
    itemId: sectionId,
    title: section.title,
    url: modeToPathLookup[mode](section, company.id),
  };
}
