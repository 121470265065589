import createUseEnterExitAnimation from './createUseEnterExitAnimation.js';

const useSidebarAnimation = <E extends HTMLElement>(
  side: 'left' | 'right',
  isOpen: boolean,
) =>
  createUseEnterExitAnimation(
    [
      {
        opacity: 0,
        ...(side === 'left' && { left: '-32px' }),
        ...(side === 'right' && { right: '-32px' }),
      },
      {
        opacity: 1,
        ...(side === 'left' && { left: '0' }),
        ...(side === 'right' && { right: '0' }),
      },
    ],
    {
      duration: 200,
      iterations: 1,
      easing: 'linear',
      fill: 'both',
    },
  )<E>(isOpen);

export default useSidebarAnimation;
