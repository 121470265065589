import { useEffect } from 'react';

export default function useDocumentEventListener<
  K extends keyof DocumentEventMap,
>(
  type: K,
  listener: (this: Document, ev: DocumentEventMap[K]) => unknown,
  deps: Array<unknown>,
  useCapture: boolean = false,
) {
  useEffect(() => {
    document.addEventListener(type, listener, useCapture);

    return () => {
      document.removeEventListener(type, listener, useCapture);
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
