import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function UpKeyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M11.3111 16H12.2344V10.4176L14.3686 12.5518L15.0007 11.9126L11.7727 8.68466L8.53764 11.9126L9.18395 12.5518L11.3111 10.4176V16Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="white" />
    </svg>
  );
}
