import styles from './VoteControls.module.scss';
import cx from 'classnames';
import useBreakpoints from '../../../services/useBreakpoints.js';

const VoteControlsRow: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  const { isMobile } = useBreakpoints();

  return (
    <div
      className={cx(
        className,
        styles.voteControls__row,
        isMobile && styles.voteControls__row_mobile,
      )}
    >
      {children}
    </div>
  );
};

export default VoteControlsRow;
