import { RefObject } from 'react';
import ContentSelection from '../selection/contentSelection/ContentSelection.js';
import BaseAndExtent from '../selection/contentSelection/BaseAndExtent.js';
import useDocumentEventListener from '../../junkDrawer/useDocumentEventListener.js';
import { getContentSelectionFromContainer } from './getContentSelectionFromContainer.js';

/** This hook is responsible for tracking selection within a container.
 * If selection changes to outside the container, it will call `onSelect` with `null`
 *
 * @param ref The container el to monitor selection within
 * @param onSelect Callback for when selection changes
 * @param fromBaseAndExtent Adapter method to translate browser selection to `ContentSelection` for the relevant content.
 */
const useOnBrowserSelectionChange = (
  ref: RefObject<HTMLElement>,
  onSelect: (selection: ContentSelection | null) => void,
  fromBaseAndExtent: (
    baseAndExtent: BaseAndExtent,
    containerElement: HTMLElement,
  ) => ContentSelection,
) => {
  useDocumentEventListener(
    'selectionchange',
    () => {
      const containerElement = ref.current;

      if (!containerElement) return;

      const newSelection = getContentSelectionFromContainer(
        containerElement,
        fromBaseAndExtent,
      );

      if (!newSelection) return onSelect(null);

      onSelect(newSelection);
    },
    [onSelect, ref],
  );
};

export default useOnBrowserSelectionChange;
