import React, { useRef } from 'react';
import SelectableDiv from './SelectableDiv.tsx';
import BlockActions from './BlockActions.ts';
import { VideoBlock } from 'editor-content/Block.js';
import { callHandlers } from '../../../../editor/domFacing/events/isKeyMatch.ts';
import handleNonTextBlockKeyEvents from './handleNonTextBlockKeyEvents.ts';
import Video from '../../Video.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';

type VideoEditableWithKeyboardProps = {
  className?: string;
  isInBlockSelection: boolean;
} & BlockActions<VideoBlock>;

const VideoEditableWithKeyboard = React.forwardRef<
  HTMLElement,
  VideoEditableWithKeyboardProps
>(function VideoEditableWithKeyboard(
  {
    className,
    block,
    selection,
    onSelect,
    onNavLeft,
    onNavRight,
    onEnter,
    isInBlockSelection,
  },
  forwardedRef,
) {
  const ref = useRef<HTMLDivElement>(null);

  const eventTargetIsSelectableDiv = (e: React.KeyboardEvent<HTMLDivElement>) =>
    e.target !== ref.current;

  return (
    <SelectableDiv
      ref={mergeRefs([forwardedRef, ref])}
      square
      className={className}
      onSelect={onSelect}
      internalSelection={selection}
      isInBlockSelection={isInBlockSelection}
      onKeyDown={callHandlers([
        eventTargetIsSelectableDiv,
        callHandlers([
          handleNonTextBlockKeyEvents({
            onEnter,
            onNavLeft,
            onNavRight,
          }),
        ]),
      ])}
    >
      <Video block={block} />
    </SelectableDiv>
  );
});

export default VideoEditableWithKeyboard;
