import { BlockSelection } from './BlockSelection.js';

export default function splitContentByBlockSelection<B>(
  content: B[],
  selection: BlockSelection,
): [beforeBlocks: B[], selectedBlocks: B[], afterBlocks: B[]] {
  const startOfSelection = Math.min(
    selection.focusIndex,
    selection.anchorIndex,
  );

  const endOfSelection = Math.max(selection.focusIndex, selection.anchorIndex);

  const blocksBefore = content.slice(0, startOfSelection);
  const blocksInside = content.slice(startOfSelection, endOfSelection + 1);
  const blocksAfter = content.slice(endOfSelection + 1);

  return [blocksBefore, blocksInside, blocksAfter];
}
