import ContentSelection from './contentSelection/ContentSelection.js';
import { BlockSelection } from './BlockSelection.js';

export type TextSelection = {
  index: number;
  offset: ContentSelection;
};

export const textSelection = (
  index: number,
  offset: ContentSelection,
): TextSelection => ({
  index,
  offset,
});

export function isTextSelection(
  selection: TextSelection | BlockSelection | null,
): selection is TextSelection {
  if (!selection) return false;

  return (
    (selection as TextSelection).index !== undefined &&
    (selection as TextSelection).offset !== undefined
  );
}

export function isCollapsedSelection(selection: TextSelection) {
  return selection.offset.focusOffset === selection.offset.anchorOffset;
}
