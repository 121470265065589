import {
  assertUnreachable,
  Block,
  bulletedListItemBlock,
  createBulletedListItemBlock,
  createParagraphBlock,
  isTextBlock,
  ParagraphBlock,
} from 'editor-content/Block.js';
import {
  createHighlight,
  Editor,
  Text,
  TextFormat,
  TextNode,
} from 'editor-content/TextNode.js';
import { ParagraphEditorColleague } from '../../editor/blocks/paragraph/ParagraphEditorColleague.js';
import { BulletedListItemEditorColleague } from '../../editor/blocks/bulletedListItem/BulletedListItemEditorColleague.js';
import { EditorCoordinator } from '../../editor/EditorCoordinator.js';
import EditorData from '../zeck/editor/EditorData.js';
import { CompanyLite } from '../../types.ts';
import arrayIsNotEmpty from '../../junkDrawer/arrayIsNotEmpty.js';
import { MeetingMinutesBlock } from 'editor-content/MeetingMinutes/MeetingMinutesBlock.ts';
import {
  MeetingMinutes,
  Signature,
} from '../../api/endpoints/createMeetingMinutesApi.ts';

export type MeetingMinutesBlockEditorColleagues =
  | ParagraphEditorColleague<MeetingMinutesBlock>
  | BulletedListItemEditorColleague<MeetingMinutesBlock>;

export type MeetingMinutesEditorCoordinator = EditorCoordinator<
  MeetingMinutesBlockEditorColleagues,
  MeetingMinutesBlock,
  ParagraphBlock
>;

/**
 * Creates the default empty block type for the editor.  This allows editors to
 * set their own default block type and for the editor to dynamically change
 * what block is created by an action based on the editor.
 *
 * @param content
 */
export const createDefaultBlock = (content: TextNode[]) => {
  return createParagraphBlock(content);
};

/**
 * Provides the configuration that maps Block Types to Editor Types
 * - Allows for Editors to swap out editor implementations for blocks if desired
 *
 * @param block
 */
export const meetingMinutesBlockToBlockEditor = (
  block: MeetingMinutesBlock,
): MeetingMinutesBlockEditorColleagues => {
  switch (block.type) {
    case 'paragraph':
      return new ParagraphEditorColleague<MeetingMinutesBlock>(
        block,
        createDefaultBlock,
      );
    case 'bulleted-list-item':
      return new BulletedListItemEditorColleague<MeetingMinutesBlock>(
        block,
        createDefaultBlock,
      );
    default:
      return assertUnreachable(block);
  }
};

export const filterMeetingMinutesBlocks = (
  blocks: Block[],
): MeetingMinutesBlock[] =>
  blocks
    .map((block) => {
      switch (block.type) {
        case 'paragraph':
        case 'bulleted-list-item':
          return block;
        default:
          if (isTextBlock(block)) {
            return createParagraphBlock(block.content);
          }
          return null;
      }
    })
    .filter((block): block is MeetingMinutesBlock => !!block);

export const filterMeetingMinuteEditorContent = (
  blockEditorData: EditorData<Block>,
): EditorData<MeetingMinutesBlock> => {
  if (blockEditorData.type === 'text') {
    return blockEditorData;
  }

  return {
    ...blockEditorData,
    content: filterMeetingMinutesBlocks(blockEditorData.content),
  };
};

export const turnIntoMeetingMinutesBlock = (
  block: MeetingMinutesBlock,
  turnIntoType: MeetingMinutesBlock['type'],
): MeetingMinutesBlock | void => {
  if (!isTextBlock(block)) return;

  switch (turnIntoType) {
    case 'paragraph':
      return ParagraphBlock(block.id, block.content);
    case 'bulleted-list-item':
      return bulletedListItemBlock(block.id, block.content, 0);
  }
};

const createTextHighlight = (
  textContent: string,
  format?: TextFormat,
): Editor.Highlight => createHighlight([Text(textContent, format)]);

/**
 * Function creating static initial meetings minutes
 */
export function buildMeetingMinutesDefaultContent(
  company: CompanyLite,
): Pick<MeetingMinutes, 'title' | 'content'> {
  const year = new Date().getFullYear();
  return {
    title: 'Q5 Board Meeting Minutes',
    content: [
      createParagraphBlock([
        Text(
          `Minutes of a Meeting of the Board of Directors of ${company.name}.`,
          {
            bold: true,
          },
        ),
      ]),

      createParagraphBlock([
        createTextHighlight('_____________'),
        Text(`, ${year}`),
      ]),
      createParagraphBlock([Text('')]),
      createParagraphBlock([Text('Time and Place of Meeting', { bold: true })]),
      createParagraphBlock([
        Text('A meeting of the Board of Directors (the '),
        Text('“Board”', { bold: true }),
        Text(`) of ${company.name}, a [Delaware] corporation (the `),
        Text('“Company”', { bold: true }),
        Text('), was held on '),
        createTextHighlight('___________ __'),
        Text(`, ${year} commencing at approximately `),
        createTextHighlight('______ _'),
        Text('.m. ET [at the offices of the Company in '),
        createTextHighlight('____, ____'),
        Text(']'),
        createTextHighlight('[via teleconference] [via video conference]'),
        Text('.'),
      ]),
      createParagraphBlock([Text('Call to Order; Roll Call', { bold: true })]),
      createParagraphBlock([
        Text(
          'The following directors, constituting a quorum, were present in person or by teleconference:',
        ),
      ]),
      createBulletedListItemBlock(
        [createTextHighlight('______________________')],
        0,
      ),
      createBulletedListItemBlock(
        [createTextHighlight('______________________')],
        0,
      ),
      createBulletedListItemBlock(
        [createTextHighlight('______________________')],
        0,
      ),
      createParagraphBlock([Text('Directors absent:')]),
      createBulletedListItemBlock(
        [createTextHighlight('______________________/[None]')],
        0,
      ),

      createParagraphBlock([Text('Others present:')]),
      createBulletedListItemBlock(
        [createTextHighlight('______________________/[None]')],
        0,
      ),

      createParagraphBlock([
        Text('Presiding Officer; Secretary', { bold: true }),
      ]),
      createParagraphBlock([
        createTextHighlight('_____________'),
        Text(' presided as Chairman of the meeting; '),
        createTextHighlight('______________'),
        Text(
          ' served as secretary of the meeting and kept the minutes.  A quorum of the Board being present, ',
        ),
        createTextHighlight('______________'),
        Text(' called the meeting to order at approximately '),
        createTextHighlight('____ _'),
        Text('.m. EST.'),
      ]),
      createParagraphBlock([
        Text('Approval of Previous Meeting Minutes', { bold: true }),
      ]),
      createParagraphBlock([
        createTextHighlight(
          '[Upon motion duly made and seconded, the Board unanimously approved the minutes of the Board meeting dated _______________, _____.] / [There were no prior minutes to approve]',
        ),
      ]),

      createParagraphBlock([
        Text('Approval of Stock Option Grants', { bold: true }),
      ]),

      createParagraphBlock([
        createTextHighlight('_________'),
        Text(
          ' initiated regarding proposed option grants.  A full discussion followed, including a discussion regarding the fair market value of the Company’s common stock for purposes of option pricing and IRS Rule 409A.  Upon motion duly made and seconded, the Board unanimously ',
        ),
        createTextHighlight('[approved]/[did not approve]'),
        Text(
          'the option grants set forth in the resolutions attached to these minutes.',
        ),
      ]),
      createParagraphBlock([Text('Agenda', { bold: true })]),
      createParagraphBlock([
        createTextHighlight('[CEO Summary]', { bold: true }),
      ]),

      createParagraphBlock([
        Text('The section, '),
        createTextHighlight('[CEO Summary]'),
        Text(
          ', was presented. A full discussion followed.  No formal action was taken or required.',
        ),
      ]),
      createParagraphBlock([
        createTextHighlight('[Financial Update]', { bold: true }),
      ]),
      createParagraphBlock([
        Text('The section, '),
        createTextHighlight('[Financial Update]'),
        Text(
          ', was presented. A full discussion followed.  No formal action was taken or required.',
        ),
      ]),

      createParagraphBlock([
        createTextHighlight('[Section 3]', { bold: true }),
      ]),
      createParagraphBlock([
        Text('The section, '),
        createTextHighlight('[Section 3]'),
        Text(
          ', was presented. A full discussion followed.  No formal action was taken or required.',
        ),
      ]),

      createParagraphBlock([
        createTextHighlight('[Section 4]', { bold: true }),
      ]),
      createParagraphBlock([
        Text('The section, '),
        createTextHighlight('[Section 4]'),
        Text(
          ', was presented. A full discussion followed.  No formal action was taken or required.',
        ),
      ]),

      createParagraphBlock([
        Text('[Next Meeting; Adjournment]', { bold: true }),
      ]),
      createParagraphBlock([
        Text(
          'There being no further business, the meeting was adjourned at approximately ',
        ),
        createTextHighlight('_____ _'),
        Text('.m. EST.  The next meeting was scheduled for '),
        createTextHighlight('___________'),
        Text(`, ${year} at `),
        createTextHighlight('_____ _'),
        Text('.m. EST via teleconference.'),
      ]),
      createParagraphBlock([Text('Respectfully submitted,')]),
    ],
  };
}

export const isSignedMinutes = (
  meetingMinutes: MeetingMinutes,
): meetingMinutes is MeetingMinutes & {
  signatures: [Signature, ...Signature[]];
} => {
  return (
    !!meetingMinutes.signatures && arrayIsNotEmpty(meetingMinutes.signatures)
  );
};
