import { Icon } from 'icons';
import styles from './AiChartWizardBadge.module.scss';

export const AiChartWizardBadge = () => {
  return (
    <div className={styles.aiChartWizardIconWrapper}>
      <Icon name="aiStar" className={styles.aiChartWizardIcon} />
    </div>
  );
};
