import { Comment } from 'api-client/types.ts';
import filterComments from './filterComments.js';

export default function isFilteredCommentsExisty(
  filterState: 'resolved' | 'starred' | null,
  commentsAndDMsBySection: {
    [sectionId: string]: Comment[];
  },
): boolean {
  const allSectionComments = Object.values(commentsAndDMsBySection).flat();
  const filteredComments = filterComments(allSectionComments);

  switch (filterState) {
    case 'resolved':
      return filteredComments.resolvedComments.length > 0;
    case 'starred':
      return filteredComments.starredComments.length > 0;
    case null:
      return filteredComments.activeComments.length > 0;
  }
}
