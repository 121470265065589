import { baseBlock, createBlock } from './BaseBlock.js';
import { BlockContent } from './Block.js';

export type NumberedListItemBlock = {
  type: 'numbered-list-item';
  id: string;
  content: BlockContent;
  indent: number;
};

export const numberedListItemBlock = (
  id: string,
  content: BlockContent,
  indent: number,
): NumberedListItemBlock =>
  baseBlock('numbered-list-item', id, { content, indent });

export const createNumberedListItemBlock = (
  content: BlockContent,
  indent: number,
): NumberedListItemBlock =>
  createBlock('numbered-list-item', { content, indent });
