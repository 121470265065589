import { Section } from '../../types.js';
import dehydrateBlock from './dehydrateBlock.js';
import HydratedSection from './types/HydratedSection.js';

export default function dehydrateSection(
  section: Partial<HydratedSection> & Pick<HydratedSection, 'id'>,
): Partial<Section> & Pick<Section, 'id'> {
  return {
    ...section,
    ...(section.body ? { body: section.body.map(dehydrateBlock) } : {}),
  };
}
