import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function WideWidthIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M20.0981 7.55454H3.90255C2.91283 7.55454 3.00284 7.99892 3.00284 8.4432V15.5524C3.00071 15.9968 2.91283 16.4411 3.90255 16.4411H20.0981C21.0878 16.4411 20.9979 15.9968 20.9979 15.5524V8.4432C20.9957 7.99888 20.9979 7.55454 20.0981 7.55454Z"
        fill="#8F90A6"
      />
      <path
        d="M17.4009 17.774H6.60177C5.61204 17.774 5.70206 18.6584 5.70206 19.107C5.70206 19.5556 5.61204 20 6.60177 20H17.4009C18.3907 20 18.2986 19.5513 18.2986 19.107C18.2986 18.6627 18.3907 17.774 17.4009 17.774Z"
        fill="#8F90A6"
      />
      <path
        d="M17.4009 4L6.60177 3.99999C5.61204 3.99999 5.70206 4.44431 5.70206 4.88865C5.70206 5.33298 5.61204 6.22161 6.60177 6.22161L17.4009 6.22162C18.2986 6.22153 18.2986 5.77728 18.2986 4.88865C18.2986 4.00001 17.8487 4 17.4009 4Z"
        fill="#8F90A6"
      />
    </svg>
  );
}
