import { assertUnreachable, Block, TextBlock } from 'editor-content/Block.ts';
import Linkable from 'editor-content/html/Linkable.ts';
import React, { ForwardedRef, RefObject, useEffect, useRef } from 'react';
import Agenda from '../../../design-system/zeck/Agenda.tsx';
import { BulletedListItem } from '../../../design-system/zeck/BulletedList.tsx';
import CartaCapTableView from '../../../design-system/zeck/CartaCapTableView.tsx';
import Heading from '../../../design-system/zeck/Heading.tsx';
import ImageView from '../../../design-system/zeck/ImageView.tsx';
import Label from '../../../design-system/zeck/Label.tsx';
import { NumberedListItem } from '../../../design-system/zeck/NumberedList.tsx';
import Paragraph from '../../../design-system/zeck/Paragraph.tsx';
import TableView from '../../../design-system/zeck/TableView.tsx';
import mergeRefs from '../../../junkDrawer/mergeRefs.ts';
import Divider from '../Divider.tsx';
import BlockEditorAdapter from '../editor/BlockEditorAdapter.ts';
import { WithHoverNextToSelectionAtom } from '../editor/editableBlocks/HoverNextToSelection.tsx';
import FileWithDownload from '../FileWithDownload.tsx';
import Video from '../Video.tsx';
import {
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
} from '../voting/VoteCapability.ts';
import VoteView from '../voting/VoteView.tsx';
import { CommentsStateForSection } from './publish/commentsSidebar/useComments/useComments.ts';
import PublishedCommentMenu from './publish/selectionComments/PublishedCommentMenu.tsx';
import CommentIndicator from './CommentIndicator.tsx';
import styles from './BlockViewable.module.scss';
import cx from 'classnames';
import { usePublishedCommentSelectionMenu } from './publish/selectionComments/usePublishedCommentSelectionMenu.js';
import { useAtomValue, useStore } from 'jotai';
import getProdCommentBlock from './publish/getProdCommentBlock.ts';
import useHandleHighlightClick from './publish/selectionComments/useHandleHighlightClick.ts';
import addHighlightToTextNodes from '../editor/BodyEditor/addHighlightToTextNodes.ts';
import useBreakpoints from '../../../services/useBreakpoints.ts';
import Chart from '../../../design-system/zeck/chart/chart.tsx';
import { Company } from '../../../types.ts';
import { useSelectedCommentHighlight } from './publish/useSelectedCommentHighlight.ts';

export type BlockViewableProps = {
  block: Block;
  key: React.Key;
  className?: string;
  commentsState?: CommentsStateForSection;
  scrollViewContainer: RefObject<HTMLElement>;
  linkables: Linkable[];
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
  preview?: boolean;
  company: Pick<Company, 'brandColorPrimary'>;
  onFilePreview?: (pdfUrl: string) => void;
};

const BlockViewable = React.forwardRef<HTMLElement, BlockViewableProps>(
  function PublishedBlockViewable(
    {
      block,
      className,
      scrollViewContainer,
      linkables,
      zeckPrevoteCapability,
      zeckFinalizeVoteCapability,
      commentsState,
      preview,
      company,
      onFilePreview,
    },
    forwardedRef,
  ) {
    const ref = useRef<HTMLElement>(null);

    switch (block.type) {
      case 'agenda': {
        return (
          <Agenda
            ref={mergeRefs([forwardedRef])}
            block={block}
            className={className}
            linkables={linkables}
          />
        );
      }
      case 'vote':
        return (
          <VoteView
            className={className}
            block={block}
            ref={mergeRefs([forwardedRef])}
            zeckPrevoteCapability={zeckPrevoteCapability}
            zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
          />
        );
      case 'bulleted-list-item':
        return (
          <BulletedListItemWithBlock
            className={className}
            block={block}
            commentsStateForSection={commentsState}
            indent={block.indent}
            linkables={linkables}
            ref={mergeRefs([forwardedRef])}
          />
        );
      case 'numbered-list-item':
        return (
          <NumberedListItemWithBlock
            className={className}
            block={block}
            linkables={linkables}
            indent={block.indent}
            commentsStateForSection={commentsState}
            ref={mergeRefs([forwardedRef])}
          />
        );
      case 'label':
        return (
          <LabelWithBlock
            className={className}
            block={block}
            linkables={linkables}
            commentsStateForSection={commentsState}
            ref={mergeRefs([forwardedRef])}
          />
        );
      case 'paragraph':
        return (
          <ParagraphWithBlock
            className={className}
            block={block}
            linkables={linkables}
            ref={mergeRefs([forwardedRef, ref])}
            commentsStateForSection={commentsState}
          />
        );
      case 'image':
        return (
          <ImageView
            commentsStateForSection={commentsState}
            className={className}
            block={block}
            preview={preview}
            ref={mergeRefs([forwardedRef])}
            scrollContainerRef={scrollViewContainer}
          />
        );
      case 'file':
        return (
          <FileWithDownload
            className={className}
            block={block}
            ref={mergeRefs([forwardedRef])}
            onPreview={onFilePreview}
          />
        );
      case 'heading':
        return (
          <HeadingWithBlock
            className={className}
            linkables={linkables}
            block={block}
            ref={mergeRefs([forwardedRef])}
            commentsStateForSection={commentsState}
          />
        );
      case 'video':
        return <Video className={className} block={block} />;
      case 'divider':
        return <Divider className={className} block={block} />;
      case 'table':
        return <TableView className={className} block={block} />;
      case 'carta-cap-table':
        return <CartaCapTableView className={className} block={block} />;
      case 'chart':
        return (
          <Chart
            className={className}
            block={block}
            company={company}
            onClick={() => {}}
            preview={false}
          />
        );
    }

    assertUnreachable(block);
  },
);

export default BlockViewable;

type WithTextBlockProps<B extends TextBlock> = {
  block: B;
  linkables: Linkable[];
  commentsStateForSection?: CommentsStateForSection;
  className?: string;
};

const withTextBlock = <B extends TextBlock, P>(
  Wrapped: React.ComponentType<P>,
) =>
  React.forwardRef(function WithBlock(
    {
      block,
      linkables,
      commentsStateForSection,
      className,
      ...otherProps
    }: P & WithTextBlockProps<B>,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const { blockWithUpdatedHighlights, orphanedCommentIds } = React.useMemo(
      () =>
        getProdCommentBlock(block, () =>
          commentsStateForSection?.forBlock(block.id),
        ),
      [block, commentsStateForSection],
    );

    const ref = useRef<HTMLElement>(null);
    const { isMobile } = useBreakpoints();

    const store = useStore();
    const publishedCommentSelectionUIStateAtom =
      usePublishedCommentSelectionMenu({ ref });
    const { selection, shouldShowForm } = useAtomValue(
      publishedCommentSelectionUIStateAtom,
    );

    const shouldShowFormValue = useAtomValue(shouldShowForm);

    useEffect(() => {
      const el = ref.current;
      const selectionValue = store.get(selection);
      const content =
        selectionValue && shouldShowFormValue
          ? addHighlightToTextNodes(
              blockWithUpdatedHighlights.content,
              selectionValue,
              'new-highlight',
            )
          : blockWithUpdatedHighlights.content;

      if (el) {
        el.innerHTML = BlockEditorAdapter.toHTMLString(content, {
          linkables,
        });
      }
    }, [
      store,
      blockWithUpdatedHighlights,
      linkables,
      shouldShowFormValue,
      selection,
    ]);

    const canAddNewComment = !!commentsStateForSection?.canAddNewComment;
    const showCommentIndicator =
      orphanedCommentIds.length > 0 && canAddNewComment;

    const handleHighlightClick = useHandleHighlightClick(
      commentsStateForSection,
    );

    const maybeSelectedCommentId =
      commentsStateForSection?.getSelectedCommentThreadId();
    useSelectedCommentHighlight(maybeSelectedCommentId ?? null);

    useEffect(() => {
      const el = ref.current;
      el?.addEventListener('click', handleHighlightClick);

      return () => {
        el?.removeEventListener('click', handleHighlightClick);
      };
    }, [handleHighlightClick, ref]);

    const isOrphanedCommentSelected = orphanedCommentIds.some((id) =>
      commentsStateForSection?.comments.some(
        (c) =>
          c.id === id &&
          c.id === commentsStateForSection.getSelectedCommentThreadId(),
      ),
    );

    const getOrphanedCommentClassName = () => {
      if (!isOrphanedCommentSelected) {
        return null;
      }

      switch (block.type) {
        case 'paragraph':
        case 'label':
          return styles.blockViewable__orphanedCommentSelected_paragraph;
        case 'bulleted-list-item':
        case 'numbered-list-item':
          return styles.blockViewable__orphanedCommentSelected_listItem;
        default:
          return styles.blockViewable__orphanedCommentSelected_heading;
      }
    };

    const orphanedCommentSelectedClassName = getOrphanedCommentClassName();

    const containerClassName = cx(
      orphanedCommentSelectedClassName,
      styles.blockViewable__container,
    );

    const usePortal = !shouldShowFormValue || !isMobile;
    return (
      <>
        <WithHoverNextToSelectionAtom
          selectionAtom={selection}
          usePortal={usePortal}
          hoverContent={
            canAddNewComment && (
              <PublishedCommentMenu
                commentsStateForSection={commentsStateForSection}
                block={block}
                publishedCommentSelectionUIStateAtom={
                  publishedCommentSelectionUIStateAtom
                }
              />
            )
          }
        >
          {(otherRef) => (
            <div className={containerClassName}>
              {orphanedCommentIds.map((id) => (
                <span key={`highlight-anchor-${id}`} data-highlightids={id} />
              ))}
              <Wrapped
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...({ ...otherProps } as any)}
                ref={mergeRefs([ref, otherRef, forwardedRef])}
                id={block.id}
                className={cx([styles.blockViewable, className])}
              />
              {showCommentIndicator && (
                <CommentIndicator
                  className={styles.blockViewable__commentIndicator}
                  commentsState={commentsStateForSection}
                  blockId={block.id}
                />
              )}
            </div>
          )}
        </WithHoverNextToSelectionAtom>
      </>
    );
  });

const BulletedListItemWithBlock = withTextBlock(BulletedListItem);
const NumberedListItemWithBlock = withTextBlock(NumberedListItem);
const ParagraphWithBlock = withTextBlock(Paragraph);
const HeadingWithBlock = withTextBlock(Heading);
const LabelWithBlock = withTextBlock(Label);
