export const findFirstTextNode = (containerNode: HTMLElement): Text | null => {
  const childNode = containerNode.firstChild;
  if (childNode) {
    if (childNode.nodeType === Node.TEXT_NODE) {
      return childNode as Text;
    } else if (childNode.nodeType === Node.ELEMENT_NODE) {
      return findFirstTextNode(childNode as HTMLElement);
    }
  }

  return null;
};

export const findLastTextNode = (containerNode: HTMLElement): Text | null => {
  const childNode = containerNode.lastChild;
  if (childNode) {
    if (childNode.nodeType === Node.TEXT_NODE) {
      return childNode as Text;
    } else if (childNode.nodeType === Node.ELEMENT_NODE) {
      return findLastTextNode(childNode as HTMLElement);
    }
  }

  return null;
};
