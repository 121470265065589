import loadScript from 'load-script2';

declare global {
  interface Window {
    // https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
    HubSpotConversations: {
      widget: {
        load(): void;
        remove(): void;
      };
    };
    hsConversationsSettings: {
      loadImmediately?: boolean;
    };
    hsConversationsOnReady: Array<() => void>;
  }
}

window.hsConversationsSettings = {
  loadImmediately: false,
};

async function loadHubSpot() {
  if (
    document.location.host === 'dev.zeck.app' ||
    document.location.host === 'localhost:3000'
  ) {
    return;
  }

  try {
    await loadScript('https://js.hs-scripts.com/21188576.js');
  } catch {
    // Ignore failures in loading
  }
}
const loaded = Promise.all([
  loadHubSpot(),
  new Promise<void>((resolve) => {
    if (window.HubSpotConversations) {
      resolve();
    } else {
      window.hsConversationsOnReady = [resolve];
    }
  }),
]);

let showing = false;

export async function show() {
  if (showing) {
    return;
  }

  await loaded;
  window.HubSpotConversations.widget.load();
  showing = true;
}

export async function hide() {
  if (!showing) {
    return;
  }

  await loaded;
  window.HubSpotConversations.widget.remove();
  showing = false;
}
