import { useEffect, useState } from 'react';
import useApi from '../api/useApi.ts';

export default function useFetchImage(guid: string) {
  const { getImage } = useApi();
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const src = await getImage(guid);
      setSrc(src);
    })();
  }, [guid, setSrc, getImage]);

  return src;
}
