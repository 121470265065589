import { findLastTextNode } from './RangeHelpers.js';

export const isOnLastLine = (
  containerNode: HTMLElement,
  range: Range,
): boolean => {
  const referenceRange = new Range();
  const lastTextNode = findLastTextNode(containerNode);
  if (!lastTextNode) {
    return true;
  }
  referenceRange.selectNodeContents(lastTextNode);
  referenceRange.collapse(false);

  return (
    referenceRange.getBoundingClientRect().y === range.getBoundingClientRect().y
  );
};
