import { TableBlock } from 'editor-content/TableBlock.js';

const normalizeTableData = (data: TableBlock['data']): TableBlock['data'] => {
  const tableWidth = Math.max(...data.rows.map((row) => row.cells.length));

  return {
    ...data,
    rows: data.rows.map((row) => {
      const missingCells = tableWidth - row.cells.length;

      if (missingCells <= 0) return row;

      const extraCells = new Array(missingCells).fill('');

      return {
        ...row,
        cells: row.cells.concat(extraCells),
      };
    }),
  };
};

export default normalizeTableData;
