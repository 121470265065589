import { fromElement, PositionStrategyRect } from './PositionStrategy.js';

export const rightAlignedBelow =
  (padding: number): PositionStrategyRect =>
  (targetRect, popoverRect) => {
    return [targetRect.right - popoverRect.width, targetRect.bottom + padding];
  };

export const rightAlignedBelowElement = (padding: number) =>
  fromElement(rightAlignedBelow(padding));
