import Pill from '../../design-system/atoms/Pill.tsx';
import { PeopleListResponsiveViewProps } from './PeopleList.tsx';
import RoleDropdown from './RoleDropdown.tsx';
import styles from './PeopleListMobile.module.scss';
import { PeopleListContainer } from './PeopleListContainer.tsx';
import { PeopleListItemLayout } from './PeopleListItemLayout.tsx';
import { PeopleListEmail } from './PeopleListEmail.tsx';
import { PeopleListUsername } from './PeopleListUsername.tsx';

const headers = [
  {
    label: 'Person',
    tooltip: null,
  },
  { label: 'User Type', tooltip: null },
];

const BoardDirectorPill = () => (
  <Pill
    size="small"
    color="gray"
    label="Board Director"
    className={styles.peopleList__directorPill}
  />
);

const PeopleListMobile = ({
  users,
  onUpdateBoardDirector,
  onUpdateRole,
  onResendInvitation,
  onDelete,
  currentUser,
}: PeopleListResponsiveViewProps) => {
  return (
    <PeopleListContainer headers={headers} isMobile>
      {users.map((user, index) => {
        if (user.pending) {
          return (
            <PeopleListItemLayout
              key={index}
              cells={[
                <div className={styles.peopleList__person} key="person">
                  <PeopleListEmail pending>{user.email}</PeopleListEmail>
                  {user.boardDirector && <BoardDirectorPill />}
                </div>,
                <RoleDropdown
                  key="role"
                  user={user}
                  onUpdateRole={(role) => onUpdateRole(user, role)}
                  onResendInvitation={() => onResendInvitation(user)}
                  onDelete={() => onDelete(user)}
                  onUpdateBoardDirector={(val) =>
                    onUpdateBoardDirector(user, val)
                  }
                />,
              ]}
            />
          );
        }

        return (
          <PeopleListItemLayout
            key={index}
            cells={[
              <div className={styles.peopleList__person} key="person">
                <PeopleListUsername
                  nameContent={`${user.firstName} ${user.lastName}`}
                />
                <PeopleListEmail>{user.email}</PeopleListEmail>
                {user.boardDirector && <BoardDirectorPill />}
              </div>,
              <RoleDropdown
                key="role"
                user={user}
                onUpdateRole={(role) => onUpdateRole(user, role)}
                onResendInvitation={() => onResendInvitation(user)}
                onDelete={() => onDelete(user)}
                onUpdateBoardDirector={(val) =>
                  onUpdateBoardDirector(user, val)
                }
                disableRoleChange={user.id === currentUser.id}
              />,
            ]}
          />
        );
      })}
    </PeopleListContainer>
  );
};

export default PeopleListMobile;
