import { useState } from 'react';
import OutlineButton from '../atoms/OutlineButton.tsx';
import { ModalActions, ModalBody, ModalHeader } from './Modal.tsx';
import styles from './SelectionModal.module.scss';
import cx from 'classnames';
import AsyncButton from '../molecules/AsyncButton.tsx';

type ItemType = { name: string; description?: string };

type SelectionModalProps<T extends ItemType> = {
  items: Array<T>;
  title: string;
  onSubmit: (item: T) => void | Promise<void>;
  onCancel: () => void;
  Icon?: React.FC<{ item: T }>;
};

export default function SelectionModal<T extends ItemType>({
  items,
  onSubmit,
  onCancel,
  Icon,
  title,
}: SelectionModalProps<T>): JSX.Element {
  const [selectedItem, setSelectItem] = useState<T | null>(null);

  return (
    <>
      <ModalHeader containerClassName={styles.header}>{title}</ModalHeader>

      <ModalBody className={styles.list} data-testid="selection-modal-body">
        {items.map((item, i) => (
          <button
            key={i}
            onClick={() => setSelectItem(item)}
            className={cx(
              styles.listItem,
              item === selectedItem && styles.selectedListItem,
            )}
            data-testid="zeck"
          >
            <div className={styles.itemName}>
              {Icon && (
                <div>
                  <Icon item={item} />
                </div>
              )}
              <div>{item.name}</div>
            </div>
            {item.description && (
              <div className={styles.listItemDescription}>
                {item.description}
              </div>
            )}
          </button>
        ))}
      </ModalBody>
      <ModalActions>
        <OutlineButton onClick={onCancel} size="medium" color="secondary">
          Cancel
        </OutlineButton>
        <AsyncButton
          onClick={async () => selectedItem && onSubmit(selectedItem)}
          disabled={!selectedItem}
          size="medium"
          color="primary"
        >
          Select
        </AsyncButton>
      </ModalActions>
    </>
  );
}
