import React from 'react';
import styles from './SidebarTitle.module.scss';
import cx from 'classnames';
import { CommentsStateForSection } from './publish/commentsSidebar/useComments/useComments.ts';
import { CommentsButton } from './publish/commentsSidebar/CommentsButton.js';

type SidebarTitleProps = {
  className?: string;
  title: string;
  readTime: number | null;
  commentsState: CommentsStateForSection | undefined;
};

const SidebarTitle = React.forwardRef<HTMLDivElement, SidebarTitleProps>(
  function SidebarTitle(
    { className, title, readTime, commentsState },
    forwardedRef,
  ) {
    return (
      <div
        className={cx(styles.sidebarTitle, className)}
        ref={forwardedRef}
        data-testid="sidebar-title"
      >
        <div className={styles.sidebarTitle__title}>{title}</div>
        {readTime !== null && (
          <div className={styles.sidebarTitle__readTime}>
            {readTime} min read
          </div>
        )}
        {commentsState && (
          <CommentsButton
            state={commentsState}
            className={styles.publishedSection__comments}
          />
        )}
      </div>
    );
  },
);

export default SidebarTitle;
