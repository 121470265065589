import { makeMakeApiRequest } from '../makeMakeApiRequest.js';

const createFileApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createFile: async ({
    companyId,
    filename,
    contentType,
  }: {
    companyId: string;
    filename: string;
    contentType: string;
  }): Promise<{ fileId: string; writeUrl: string }> => {
    return makeLambdaApiRequest(`/company/${companyId}/file`, {
      method: 'POST',
      body: JSON.stringify({
        filename,
        contentType,
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  uploadFile: async (uploadUrl: string, file: File): Promise<void> => {
    await fetch(uploadUrl, {
      method: 'PUT',
      body: file,
    });
  },

  getFile: async (guid: string): Promise<{ readUrl: string }> => {
    return makeLambdaApiRequest(`/file/${guid}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
});

export default createFileApi;
