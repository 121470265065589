import { useRef } from 'react';
import * as CSS from 'csstype';
import HoverNextToPoint from './HoverNextToPoint.tsx';
import Point from '../Point.ts';
import { ViewportPolicy } from './withViewportPositionPolicy.js';

type HoverNextToElementProps = {
  children: React.ReactNode;
  elementRef: React.RefObject<HTMLElement>;
  viewportPolicy?: ViewportPolicy;
  positionStrategy(
    element: HTMLElement,
    childElement: HTMLElement,
  ): Point | null;
  style?: CSS.Properties;
  usePortal?: boolean;
  onMouseLeave?: () => void;
  containerStyles?: React.CSSProperties;
};

const HoverNextToElement = ({
  children,
  viewportPolicy,
  elementRef,
  positionStrategy,
  style,
  usePortal,
  onMouseLeave = () => {
    return;
  },
  containerStyles,
}: HoverNextToElementProps) => {
  const prevOffsetFromElement = useRef<Point | null>(null);

  return (
    <HoverNextToPoint
      {...{
        usePortal,
        onMouseLeave,
        viewportPolicy,
        style,
        containerStyles,
        getPoint(containerEl) {
          const element = elementRef.current;
          if (!element) return prevOffsetFromElement.current;

          const offsetFromElement = positionStrategy(element, containerEl);
          prevOffsetFromElement.current = offsetFromElement;
          return offsetFromElement;
        },
      }}
    >
      {children}
    </HoverNextToPoint>
  );
};

export default HoverNextToElement;
