import { SectionLinkable } from './SectionLinkable.js';
import { MeetingMinutesLinkable } from './MeetingMinutesLinkable.js';
import { Editor } from '../TextNode.js';

type Linkable = MeetingMinutesLinkable | SectionLinkable;

export const isMeetingMinutesLinkable = (
  linkable: Linkable,
): linkable is MeetingMinutesLinkable => {
  return linkable.type === 'meeting-minutes';
};

export const isSectionLinkable = (
  linkable: Linkable,
): linkable is SectionLinkable => {
  return linkable.type === 'section';
};

export const findLinkableForLink = (
  link: Editor.SectionLink | Editor.MeetingMinutesLink,
  linkables: Linkable[],
): Linkable | undefined => {
  return linkables.find((linkable) => {
    switch (link.type) {
      case 'section-link':
        return linkable.itemId === link.sectionId;
      case 'meeting-minutes-link':
        return linkable.itemId === link.meetingMinutesId;
    }
  });
};

export default Linkable;
