import {
  createParagraphBlock,
  getTextFromBlock,
  isTextBlock,
} from 'editor-content/Block.js';
import { textSelection } from '../../../../editor/selection/TextSelection.js';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.js';
import { BodyContent, BodySelection, BodyStateSelected } from './BodyEditor.js';

const addBlockAtEnd = function (initialState: {
  content: BodyContent;
  selection: BodySelection | null;
}): BodyStateSelected {
  const content = initialState.content;
  const lastBlock = content[content.length - 1];

  if (
    !lastBlock ||
    !isTextBlock(lastBlock) ||
    getTextFromBlock(lastBlock).length > 0
  ) {
    return {
      content: [...content, createParagraphBlock([])],
      selection: textSelection(content.length, contentSelection(0)),
    };
  } else {
    return {
      content,
      selection: textSelection(content.length - 1, contentSelection(0)),
    };
  }
};

export default addBlockAtEnd;
