// https://developer.mozilla.org/en-US/docs/Web/API/CaretPosition
type CaretPosition = {
  offsetNode: Node;
  offset: number;

  getClientRect(): DOMRect;
};

interface DocumentWithCaretPositionFromPoint extends Document {
  // This function is at the time of this writing an experimental
  // feature, and as such is not available on all the browsers we
  // support. This interface and this function definition are so that
  // we can make the Typescript compiler happy, and to make the interface
  // of this experimental API crystal clear to devs.
  //
  // See more detail at https://developer.mozilla.org/en-US/docs/Web/API/Document/caretPositionFromPoint
  caretPositionFromPoint(x: number, y: number): CaretPosition;
}

function isDocumentWithCaretPositionFromPoint(
  obj: Document,
): obj is DocumentWithCaretPositionFromPoint {
  if ((obj as DocumentWithCaretPositionFromPoint).caretPositionFromPoint) {
    return true;
  }

  return false;
}

const getCaretRangeFromPoint = (x: number, y: number): Range => {
  // this will throw if point is offscreen.

  if (document.caretRangeFromPoint) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/caretRangeFromPoint
    const range = document.caretRangeFromPoint(x, y);
    if (!range) {
      throw new Error('Not able to find caret range');
    }
    return range;
  } else if (isDocumentWithCaretPositionFromPoint(document)) {
    const position = document.caretPositionFromPoint(x, y);

    if (!position) {
      throw new Error('Not able to find caret position');
    }

    const range = new Range();
    range.setStart(position.offsetNode, position.offset);
    return range;
  }

  throw new Error('Finding caret position is not supported');
};

export default getCaretRangeFromPoint;
