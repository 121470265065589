import React from 'react';
import cx from 'classnames';
import styles from './ImageLayout.module.scss';
import useBreakpoints from '../../services/useBreakpoints.ts';
import { ImageBlock } from 'editor-content/Block.js';

const imageClassFromWidth = (width: ImageBlock['width']) => {
  switch (width) {
    case 'column':
      return styles.imageLayout_columnWidth;
    case 'wide':
      return styles.imageLayout_wideWidth;
    case 'full-width':
      return styles.imageLayout_fullWidth;
  }
};

type ImageLayoutProps = {
  width: ImageBlock['width'];
  align: ImageBlock['align'];
  className?: string;
  image: React.ReactNode;
  caption?: React.ReactNode;
} & React.ComponentProps<'div'>;

const ImageLayout = React.forwardRef<HTMLDivElement, ImageLayoutProps>(
  function ImageLayout(
    { className, width, image, caption, align, ...otherProps },
    forwardedRef,
  ) {
    const { isMobile } = useBreakpoints();

    return (
      <div
        ref={forwardedRef}
        {...otherProps}
        className={cx(
          imageClassFromWidth(width),
          isMobile && styles.imageLayout_mobile,
          align === 'left'
            ? styles.imageLayout_leftAlign
            : styles.imageLayout_centerAlign,
          className,
        )}
      >
        <div className={cx(styles.imageLayout__image)}>{image}</div>
        {caption && (
          <div className={styles.imageLayout__caption}>{caption}</div>
        )}
      </div>
    );
  },
);

export default ImageLayout;
