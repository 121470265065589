import { EditorStateGeneric } from '../EditorStateGeneric.js';
import { isTextSelection } from '../EditorSelection.js';
import getSelectedBlock from '../../pages/zeck/editor/BodyEditor/getSelectedBlock.ts';
import ContentSelection from '../selection/contentSelection/ContentSelection.ts';

export type FocusedBlock = {
  blockId: string;
};

// This factory function is a generic function that takes a block editor object and returns a function that takes a selection and returns a focused block or void
// factoryFunctionThatGeneratesAPressForwardSlashFunction specific to a block type and the produced function allows appropriate calls through to the 'pressForwardSlash' function on the block editor object
const pressForwardSlash =
  <BlockType>(
    generateBlockEditor: (b: BlockType) => {
      pressForwardSlash: (selection: ContentSelection) => FocusedBlock | void;
    },
  ) =>
  ({
    content,
    selection,
  }: EditorStateGeneric<BlockType>):
    | [EditorStateGeneric<BlockType>, returnValue: FocusedBlock]
    | void => {
    if (!selection || !content) {
      return;
    }
    if (!(isTextSelection(selection) && selection.offset.anchorOffset === 0)) {
      return;
    }

    const currentBlock = getSelectedBlock(content, selection);
    if (!currentBlock) {
      return;
    }

    // if we meet the conditions for showing the Add Block Menu return focused block
    const focusedBlock = generateBlockEditor(currentBlock).pressForwardSlash(
      selection.offset,
    );
    if (!focusedBlock) {
      return;
    }
    return [{ content, selection }, focusedBlock];
  };

export default pressForwardSlash;
