import { Block, isTextBlock } from 'editor-content/Block.js';
import { TextSelection } from './TextSelection.js';
import { EditorSelection, isTextSelection } from '../EditorSelection.js';
import BlockEditorAdapter from '../../pages/zeck/editor/BlockEditorAdapter.js';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import isHTMLElement from '../../junkDrawer/isHTMLElement.js';
import { isBlockSelection } from './BlockSelection.js';
import ContentSelection from './contentSelection/ContentSelection.js';

export const textBlockGetSelectionRectStrategy = (
  selection: ContentSelection,
  blockEl: HTMLElement,
): DOMRect => {
  const baseAndExtent = BlockEditorAdapter.toBaseAndExtent(selection, blockEl);
  const domRange = new Range();
  domRange.setStart(baseAndExtent.anchorNode, baseAndExtent.anchorOffset);
  domRange.setEnd(baseAndExtent.focusNode, baseAndExtent.focusOffset);

  const rect = domRange.getBoundingClientRect();

  // collapsed selection in empty text block has 0 by 0 rect at 0,0 for some reason
  if (!(rect.x === 0 && rect.y === 0)) return rect;

  const ancestor = domRange.commonAncestorContainer;
  if (!isHTMLElement(ancestor)) return rect;

  return ancestor.getBoundingClientRect();
};

const nonTextBlockGetSelectionRectStrategy = (blockEl: HTMLElement): DOMRect =>
  blockEl.getBoundingClientRect();

const getSelectionRectForBlock = (
  block: Block,
  blockEl: HTMLElement,
  selection: TextSelection,
) => {
  if (isTextBlock(block)) {
    return textBlockGetSelectionRectStrategy(selection.offset, blockEl);
  }

  return nonTextBlockGetSelectionRectStrategy(blockEl);
};

const getSelectionRect = (
  selection: EditorSelection,
  blocksWithEl: ElementAndData<Block>[],
) => {
  if (isBlockSelection(selection)) {
    const selectedBlockRenderable = blocksWithEl[selection.focusIndex];

    if (!selectedBlockRenderable) return;

    const selectedBlockEl = selectedBlockRenderable?.getEl();
    if (!selectedBlockEl) return;

    return selectedBlockEl.getBoundingClientRect(); // get rect of block
  } else if (isTextSelection(selection)) {
    const selectedBlockRenderable = blocksWithEl[selection.index];

    if (!selectedBlockRenderable) return;
    if (!isTextBlock(selectedBlockRenderable.data)) return;
    // this is stupid and change it ^
    // overlaps with logic in getSelectionRectForBlock

    const selectedBlockEl = selectedBlockRenderable?.getEl();
    if (!selectedBlockEl) return;

    // this could be an injected block-specific dependency
    // as it is, the super-type of Block and the fact that it does not return
    // a generic type means that we get away with it knowing about all blocks
    return getSelectionRectForBlock(
      selectedBlockRenderable.data,
      selectedBlockEl,
      selection,
    );
  }
};

export default getSelectionRect;
