import pressForwardSlash from '../../../../editor/actions/pressForwardSlash.ts';
import getTextBlockLength from '../../../../editor/blocks/textBlocksStrategies/getTextBlockLength.ts';
import { HydratedBlock } from '../../../../types/HydratedBlock.ts';

export default pressForwardSlash<HydratedBlock>((block) => {
  if (
    block.type === 'paragraph' ||
    block.type === 'heading' ||
    block.type === 'label' ||
    block.type === 'bulleted-list-item' ||
    block.type === 'numbered-list-item'
  ) {
    return {
      pressForwardSlash: (_selection) => {
        if (getTextBlockLength(block) === 0) {
          return { blockId: block.id };
        }
        return;
      },
    };
  }
  return {
    pressForwardSlash: () => {
      return;
    },
  };
});
