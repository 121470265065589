import { fromElement, PositionStrategyRect } from './PositionStrategy.ts';

export const rightAlignedCentered =
  (padding: number): PositionStrategyRect =>
  (targetRect, popoverRect) => {
    return [
      targetRect.right + padding,
      targetRect.top + targetRect.height / 2 - popoverRect.height / 2,
    ];
  };

export const rightAlignedCenteredElement = (padding: number) =>
  fromElement(rightAlignedCentered(padding));
