import { useRef, useState } from 'react';

export const TOAST_DISMISS_TIMEOUT_MS = 1500;

export type ToastProps = {
  isOpen: boolean;
  message: string;
  onClose: () => void;
};

const useToast = () => {
  const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  return {
    isOpen,
    message,
    onClose: () => {
      setIsOpen(false);

      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    showToast: (message: string, duration = TOAST_DISMISS_TIMEOUT_MS) => {
      setIsOpen(true);
      setMessage(message);

      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        setIsOpen(false);
        timeoutRef.current = null;
      }, duration);
    },
  };
};

export default useToast;
