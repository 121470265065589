import {
  BlockContent,
  BulletedListItemBlock,
  createParagraphBlock,
  getTextFromBlock,
  HeadingBlock,
  LabelBlock,
  NumberedListItemBlock,
  ParagraphBlock,
} from 'editor-content/Block.js';
import {
  textBlockPressBackspaceStrategy,
  textBlockReceiveBackspaceStrategy,
} from '../../../../editor/blocks/textBlocksStrategies/BackspaceStrategies.js';
import ContentSelection, {
  contentSelection,
  isCollapsed,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import {
  isCompletedVoteBlock,
  VoteBlockHydrated,
} from '../../../../VoteBlockHydrated.js';
import pressBackspace, {
  PressBackspaceStrategyResult,
  ReceiveBackspaceStrategyResult,
} from './pressBackspace.js';

const nonTextBlockReceiveBackspaceStrategy = <BlockType>(
  _block: BlockType,
  _content: BlockContent,
): ReceiveBackspaceStrategyResult<BlockType> => {
  return {
    type: 'accept-selection',
    selection: contentSelection(0),
  };
};

const nonTextBlockPressBackspaceStrategy = <BlockType>(
  _selectedBlock: BlockType,
  _selection: ContentSelection,
): PressBackspaceStrategyResult<BlockType> => {
  return {
    type: 'remove',
  };
};

const listItemBlockPressBackspaceStrategy = (
  selectedBlock: BulletedListItemBlock | NumberedListItemBlock,
  selection: ContentSelection,
): PressBackspaceStrategyResult<
  BulletedListItemBlock | NumberedListItemBlock | ParagraphBlock
> => {
  if (
    isCollapsed(selection) &&
    selection.focusOffset === 0 &&
    getTextFromBlock(selectedBlock).length === 0
  ) {
    return {
      type: 'change',
      content: ParagraphBlock(selectedBlock.id, []),
      selection: selection,
    };
  }

  return textBlockPressBackspaceStrategy(selectedBlock, selection);
};

const labelBlockPressBackspaceStrategy = (
  selectedBlock: LabelBlock,
  selection: ContentSelection,
): PressBackspaceStrategyResult<LabelBlock | ParagraphBlock> => {
  if (isCollapsed(selection) && selection.focusOffset === 0) {
    return {
      type: 'change',
      content: ParagraphBlock(selectedBlock.id, selectedBlock.content),
      selection: selection,
    };
  }

  return textBlockPressBackspaceStrategy(selectedBlock, selection);
};

const headingBlockPressBackspaceStrategy = (
  selectedBlock: HeadingBlock,
  selection: ContentSelection,
): PressBackspaceStrategyResult<HeadingBlock | ParagraphBlock> => {
  if (isCollapsed(selection) && selection.focusOffset === 0) {
    return {
      type: 'change',
      content: ParagraphBlock(selectedBlock.id, selectedBlock.content),
      selection: selection,
    };
  }

  return textBlockPressBackspaceStrategy(selectedBlock, selection);
};

const voteBlockPressBackspaceStrategy = (
  selectedBlock: VoteBlockHydrated,
  selection: ContentSelection,
): PressBackspaceStrategyResult<VoteBlockHydrated> => {
  if (isCompletedVoteBlock(selectedBlock)) {
    return {
      type: 'change',
      content: selectedBlock,
      selection,
    };
  }

  return nonTextBlockPressBackspaceStrategy(selectedBlock, selection);
};

const generatePressBackspaceBlockEditor: (selectedBlock: HydratedBlock) => {
  pressBackspace: (
    selection: ContentSelection,
  ) => PressBackspaceStrategyResult<HydratedBlock>;
  receiveBackspace: (
    content: BlockContent,
  ) => ReceiveBackspaceStrategyResult<HydratedBlock>;
} = (selectedBlock) => {
  switch (selectedBlock.type) {
    case 'divider':
    case 'image':
    case 'file':
    case 'agenda':
    case 'video':
    case 'carta-cap-table':
    case 'chart':
    case 'table':
      return {
        pressBackspace: (selection: ContentSelection) =>
          nonTextBlockPressBackspaceStrategy(selectedBlock, selection),
        receiveBackspace: (content: BlockContent) =>
          nonTextBlockReceiveBackspaceStrategy(selectedBlock, content),
      };
    case 'vote':
      return {
        pressBackspace: (selection: ContentSelection) =>
          voteBlockPressBackspaceStrategy(selectedBlock, selection),
        receiveBackspace: (content: BlockContent) =>
          nonTextBlockReceiveBackspaceStrategy(selectedBlock, content),
      };
    case 'paragraph':
      return {
        pressBackspace: (selection: ContentSelection) =>
          textBlockPressBackspaceStrategy(selectedBlock, selection),
        receiveBackspace: (content: BlockContent) =>
          textBlockReceiveBackspaceStrategy(selectedBlock, content),
      };
    case 'label':
      return {
        pressBackspace: (selection: ContentSelection) =>
          labelBlockPressBackspaceStrategy(selectedBlock, selection),
        receiveBackspace: (content: BlockContent) =>
          textBlockReceiveBackspaceStrategy(selectedBlock, content),
      };
    case 'bulleted-list-item':
      return {
        pressBackspace: (selection: ContentSelection) =>
          listItemBlockPressBackspaceStrategy(selectedBlock, selection),
        receiveBackspace: (content: BlockContent) =>
          textBlockReceiveBackspaceStrategy(selectedBlock, content),
      };
    case 'numbered-list-item':
      return {
        pressBackspace: (selection: ContentSelection) =>
          listItemBlockPressBackspaceStrategy(selectedBlock, selection),
        receiveBackspace: (content: BlockContent) =>
          textBlockReceiveBackspaceStrategy(selectedBlock, content),
      };
    case 'heading':
      return {
        pressBackspace: (selection: ContentSelection) =>
          headingBlockPressBackspaceStrategy(selectedBlock, selection),
        receiveBackspace: (content: BlockContent) =>
          textBlockReceiveBackspaceStrategy(selectedBlock, content),
      };
  }
};

// eventually share the generateBlockEditor across other places like pressDelete, pressEnter,
// where we bind generic editor actions to block-specific strategies
export default pressBackspace<HydratedBlock>(
  generatePressBackspaceBlockEditor,
  createParagraphBlock,
);
