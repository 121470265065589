import { isHighlight, TextNode } from 'editor-content/TextNode.js';
import Linkable, { findLinkableForLink } from 'editor-content/html/Linkable.js';

const cleanupTextNodesForPaste = (
  nodes: TextNode[],
  linkables: Linkable[],
): TextNode[] => {
  return nodes
    .flatMap((node) => {
      if (isHighlight(node)) {
        return node.content;
      }

      return node;
    })
    .flatMap((node) => {
      switch (node.type) {
        case 'text':
        case 'external-link': {
          return node;
        }
        case 'section-link':
        case 'meeting-minutes-link': {
          if (findLinkableForLink(node, linkables)) {
            return node;
          }

          return node.content;
        }
      }
    });
};
export default cleanupTextNodesForPaste;
