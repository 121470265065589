import ContentSelection, {
  isCollapsed,
} from '../../selection/contentSelection/ContentSelection.js';
import splitTextNodesFromContentSelection from '../textNode/splitTextNodesFromContentSelection.js';
import { TextBlock } from 'editor-content/Block.js';
import { TextData } from '../../../pages/zeck/editor/EditorData.js';

const textBlockGetSelectedContentStrategy = (
  block: TextBlock,
  selection: ContentSelection,
): TextData | void => {
  if (isCollapsed(selection)) return;

  const [, textNodes] = splitTextNodesFromContentSelection(
    block.content,
    selection,
  );

  return { type: 'text', content: textNodes };
};
export default textBlockGetSelectedContentStrategy;
