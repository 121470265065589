import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function FullWidthIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M20.0981 5.07125H3.90255C2.91283 5.07125 3.00284 5.53543 3.00284 5.99951L3.00284 14.3537C3.00071 14.8179 2.91283 15.282 3.90255 15.282H20.0981C21.0878 15.282 20.9979 14.8179 20.9979 14.3537V5.99951C20.9957 5.53539 20.9979 5.07125 20.0981 5.07125Z"
        fill="#8F90A6"
      />
      <path
        d="M15.0986 16.6744H8.89971C7.90998 16.6744 8 17.5981 8 18.0668C8 18.5354 7.90998 18.9995 8.89971 18.9995H15.0986C16.0884 18.9995 15.9963 18.5309 15.9963 18.0668C15.9963 17.6026 16.0884 16.6744 15.0986 16.6744Z"
        fill="#8F90A6"
      />
    </svg>
  );
}
