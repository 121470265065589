import { RefCallback, useEffect, useRef } from 'react';

export type ElementAndData<T> = {
  data: T;
  getEl: () => HTMLElement | undefined;
  setEl: RefCallback<HTMLElement>;
};

const useElementAndDataArray = <T>(array: T[]): ElementAndData<T>[] => {
  const elementsArrayRef = useRef<(HTMLElement | undefined)[]>([]);

  useEffect(() => {
    elementsArrayRef.current = elementsArrayRef.current.slice(0, array.length);
  }, [array.length]);

  return array.map((data, i) => ({
    data: data,
    getEl: () => {
      return elementsArrayRef.current[i];
    },
    setEl: (el) => {
      if (el) {
        elementsArrayRef.current[i] = el;
      }
    },
  }));
};

export default useElementAndDataArray;
