import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function LockIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M16.5 9.25H15.75V7.75C15.75 5.68 14.07 4 12 4C9.93 4 8.25 5.68 8.25 7.75V9.25H7.5C6.675 9.25 6 9.925 6 10.75V18.25C6 19.075 6.675 19.75 7.5 19.75H16.5C17.325 19.75 18 19.075 18 18.25V10.75C18 9.925 17.325 9.25 16.5 9.25ZM9.75 7.75C9.75 6.505 10.755 5.5 12 5.5C13.245 5.5 14.25 6.505 14.25 7.75V9.25H9.75V7.75ZM16.5 18.25H7.5V10.75H16.5V18.25ZM12 16C12.825 16 13.5 15.325 13.5 14.5C13.5 13.675 12.825 13 12 13C11.175 13 10.5 13.675 10.5 14.5C10.5 15.325 11.175 16 12 16Z"
        fill="#1C1C28"
      />
    </svg>
  );
}
