import styles from './SpeechBubble.module.scss';
import cx from 'classnames';

type SpeechBubbleProps = {
  children: React.ReactNode;
  className?: string;
};

const SpeechBubble: React.FunctionComponent<SpeechBubbleProps> = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <div {...otherProps} className={cx(styles.speechBubble, className)}>
      {children}
    </div>
  );
};

export default SpeechBubble;
