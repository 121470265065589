const minutesToTimeString = (totalMinutes: number): string => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes - hours * 60);

  const hoursString = hours > 0 ? `${hours} hr${hours > 1 ? 's' : ''}` : '';
  const minutesString = `${minutes} min`;

  return [hoursString, minutesString].join(' ').trim();
};

export default minutesToTimeString;
