import { MouseEventHandler, useCallback, useRef, useState } from 'react';
import Tooltip from '../molecules/Tooltip.tsx';
import HoverNextToElement from '../../domHelpers/hoverNextTo/HoverNextToElement.tsx';
import { centeredBelowElement } from '../../domHelpers/hoverNextTo/positionStrategy/centeredBelow.ts';
import Point from '../../domHelpers/Point.ts';

type WithTooltipProps<E extends HTMLElement> = {
  text?: string;
  positionStrategy?: (
    element: HTMLElement,
    childElement: HTMLElement,
  ) => Point | null;
  children: (
    targetRef: React.Ref<E>,
    listeners: {
      onMouseEnter: MouseEventHandler;
      onMouseLeave: MouseEventHandler;
    },
    closeTooltip: () => void,
  ) => React.ReactNode;
  enabled?: boolean;
  tooltipClassName?: string;
  usePortal?: boolean;
};

export default function WithTooltip<E extends HTMLElement>({
  enabled = true,
  text,
  children,
  positionStrategy,
  tooltipClassName,
  usePortal,
}: WithTooltipProps<E>) {
  const targetRef = useRef<E>(null);
  const [showPreviewTooltip, setShowPreviewTooltip] = useState(false);

  const closeTooltip = useCallback(() => setShowPreviewTooltip(false), []);
  const openTooltip = useCallback(() => setShowPreviewTooltip(true), []);

  const toolTipOpen = showPreviewTooltip && !!text && enabled;
  return (
    <>
      {children(
        targetRef,
        {
          onMouseEnter: openTooltip,
          onMouseLeave: closeTooltip,
        },
        closeTooltip,
      )}
      {toolTipOpen && (
        <HoverNextToElement
          elementRef={targetRef}
          usePortal={usePortal}
          positionStrategy={positionStrategy ?? centeredBelowElement(8)}
        >
          <Tooltip className={tooltipClassName} data-testid="tooltip">
            {text}
          </Tooltip>
        </HoverNextToElement>
      )}
    </>
  );
}
