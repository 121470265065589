import AdminPanel, {
  AdminPanelField,
  AdminPanelFormContainer,
  AdminPanelSubmit,
} from '../adminPanel/AdminPanel.tsx';
import useApi from '../../../api/useApi.ts';
import { useState } from 'react';
import Toast from '../../../design-system/molecules/toast/Toast.tsx';
import CompanySearch from '../CompanySearch.tsx';

const AddGaryToCompany: React.FC = () => {
  const { addGaryToCompany } = useApi();
  const [selectedCompanyId, setSelectedCompanyId] = useState<null | string>(
    null,
  );
  const [showToast, setShowToast] = useState(false);

  return (
    <AdminPanel
      title="All Back Full"
      subtitle="(Add gary@zeck.app as Admin to a Company)"
    >
      <AdminPanelFormContainer>
        <AdminPanelField description="Choose the Company you want gary@zeck.app added as admin to">
          <CompanySearch
            selectedCompanyId={selectedCompanyId}
            onSelectCompany={function (company: {
              id: string;
              name: string;
            }): void {
              setSelectedCompanyId(company.id);
            }}
          />
        </AdminPanelField>

        <AdminPanelSubmit
          disabled={!selectedCompanyId}
          onClick={async () => {
            if (!selectedCompanyId) return;
            await addGaryToCompany(selectedCompanyId);
            setShowToast(true);
            setSelectedCompanyId(null);
          }}
        >
          Add as Admin
        </AdminPanelSubmit>
      </AdminPanelFormContainer>
      <Toast
        message={'Added Gary as admin. How much power should one man hold?'}
        isOpen={showToast}
        onClose={() => setShowToast(false)}
      />
    </AdminPanel>
  );
};

export default AddGaryToCompany;
