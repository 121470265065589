import React from 'react';
import usePaginatedFetchOnScroll from '../../../../../services/useFetch/usePaginatedFetchOnScroll.ts';
import styles from './InfiniteScrollingModal.module.scss';
import cx from 'classnames';

import Spinner, {
  SmallSpinner,
} from '../../../../../design-system/atoms/Spinner.js';

type InfiniteScrollingModalProps<T> = {
  fetchFunc: (
    cursor: string | null | undefined,
  ) => Promise<{ models: T[]; pagination: { cursor: string | null } }>;
  isUpdating: boolean;
  emptyMessage: string;
  renderRow: (row: T) => React.ReactNode;
  filterModelsFunc?: (models: T[]) => T[];
};

const LoadingSpinner = () => {
  return (
    <div
      className={styles.infiniteScrollModal__spinnerWrapper}
      data-testid="infinite-scroll-modal-spinner"
    >
      <Spinner />
    </div>
  );
};

const InlineSpinner = () => {
  return (
    <div
      className={styles.infiniteScrollModal__inlineSpinner}
      data-testid="inifinite-scroll-inline-spinner"
    >
      <SmallSpinner />
    </div>
  );
};

export const InfiniteScrollingList = <T extends { id: string }>({
  fetchFunc,
  emptyMessage,
  renderRow,
  isUpdating,
  filterModelsFunc,
  className,
}: InfiniteScrollingModalProps<T> & { className?: string }) => {
  const { models, queryState, ref } = usePaginatedFetchOnScroll<T>(fetchFunc, [
    fetchFunc,
  ]);

  const remainingModels = filterModelsFunc ? filterModelsFunc(models) : models;
  const isEmpty = remainingModels.length === 0 && !isUpdating;
  const isLoading = queryState === 'loading' || isUpdating;

  return (
    <>
      {isLoading ? (
        <div className={styles.infiniteScrollModal__emptyWrapper}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {isEmpty ? (
            <div className={styles.infiniteScrollModal__emptyWrapper}>
              {emptyMessage}
            </div>
          ) : (
            <ul
              data-testid="infinite-scrolling-list"
              className={cx(styles.infiniteScrollModal__table, className)}
            >
              <>
                {remainingModels.map((model) => (
                  <li key={model.id}>{renderRow(model)}</li>
                ))}
              </>

              <li
                ref={ref}
                className={styles.infiniteScrollModal__inlineSpinnerWrapper}
              >
                {queryState === 'fetchingNextPage' && <InlineSpinner />}
              </li>
            </ul>
          )}
        </>
      )}
    </>
  );
};

const InfiniteScrollingModal = <T extends { id: string }>({
  title,
  ...props
}: InfiniteScrollingModalProps<T> & { title: string }) => {
  return (
    <div className={styles.infiniteScrollModal}>
      {title && (
        <div className={styles.infiniteScrollModal__header}>{title}</div>
      )}

      <InfiniteScrollingList {...props} />
    </div>
  );
};

export default InfiniteScrollingModal;
