import { CommentsState } from './commentsSidebar/useComments/useComments.ts';
import { RefObject } from 'react';
import { Company, PublishedSection, PublishedZeck } from '../../../../types.ts';
import ZeckCover from '../../../../design-system/zeck/ZeckCover.tsx';
import useBreakpoints from '../../../../services/useBreakpoints.ts';
import cx from 'classnames';
import styles from '../SectionView.module.scss';
import useProvidesViewportHeight from '../useProvidesViewportHeight.ts';
import { isSupplemental } from '../../../../types/Section.ts';
import { ElementAndData } from '../../../../junkDrawer/useElementAndDataArray.ts';
import SectionView from '../SectionView.tsx';
import AppendixCard from '../AppendixCard.tsx';
import {
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
} from '../../voting/VoteCapability.js';
import Linkable from 'editor-content/html/Linkable.ts';
import useHighlightHover from '../../editor/comments/components/useHighlightHover.ts';

type ZeckViewProps = {
  zeck: PublishedZeck;
  commentsState: CommentsState;
  linkables: Linkable[];
  scrollContainerRef: RefObject<HTMLElement>;
  company: Pick<Company, 'name' | 'brandColorPrimary'>;
  sectionsWithRef: ElementAndData<PublishedSection>[];
  onFilePreview: (pdfUrl: string) => void;

  getZeckCapabilitiesForSection: (sectionId: string) => {
    zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
    zeckPrevoteCapability: ZeckPrevoteCapability | null;
  };
};

function PublishedZeckView({
  zeck,
  sectionsWithRef,
  company,
  commentsState,
  linkables,
  scrollContainerRef,
  getZeckCapabilitiesForSection,
  onFilePreview,
}: ZeckViewProps) {
  const { isMobile } = useBreakpoints();
  useProvidesViewportHeight(scrollContainerRef);

  const tableOfContentsSectionsWithRef = sectionsWithRef.filter(
    (sectionWithRef) => !isSupplemental(sectionWithRef.data),
  );
  const appendixSectionsWithRef = sectionsWithRef.filter((sectionWithRef) =>
    isSupplemental(sectionWithRef.data),
  );

  useHighlightHover();
  return (
    <div
      className={cx(styles.sectionView, isMobile && styles.sectionView__mobile)}
    >
      <ZeckCover
        zeck={zeck}
        companyName={company.name}
        navigation={() => {
          if (!scrollContainerRef.current) {
            return;
          }
          scrollContainerRef.current.scrollTo({
            top: scrollContainerRef.current.clientHeight,
            behavior: 'smooth',
          });
        }}
      />

      {tableOfContentsSectionsWithRef.map(({ data: section, setEl }) => {
        const { zeckFinalizeVoteCapability, zeckPrevoteCapability } =
          getZeckCapabilitiesForSection(section.sectionId);

        return (
          <SectionView
            key={section.id}
            section={section}
            ref={setEl}
            linkables={linkables}
            scrollContainerRef={scrollContainerRef}
            commentsStateForSection={commentsState?.forSection(section)}
            zeckPrevoteCapability={zeckPrevoteCapability}
            zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
            company={company}
            onFilePreview={onFilePreview}
          />
        );
      })}
      {appendixSectionsWithRef.length > 0 && <AppendixCard />}
      {appendixSectionsWithRef.map(({ data: section, setEl }) => {
        const { zeckFinalizeVoteCapability, zeckPrevoteCapability } =
          getZeckCapabilitiesForSection(section.sectionId);

        return (
          <SectionView
            key={section.id}
            section={section}
            ref={setEl}
            linkables={linkables}
            scrollContainerRef={scrollContainerRef}
            commentsStateForSection={commentsState?.forSection(section)}
            zeckPrevoteCapability={zeckPrevoteCapability}
            zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
            company={company}
            onFilePreview={onFilePreview}
          />
        );
      })}
    </div>
  );
}

export default PublishedZeckView;
