import Tabs from '../../../../design-system/organisms/Tabs.tsx';
import { AddZeckViewers } from '../../publishAndManageViewerPermissions/AddPeople.tsx';
import ZeckWithActions from '../zeckCover/ZeckWithActions.ts';
import AccessRequests from './AccessRequests.tsx';
import styles from './ZeckViewersModal.module.scss';

type ZeckViewersModalProps = {
  zeck: ZeckWithActions;
  onClose: () => void;
  showToast: (message: string, duration: number) => void;
};

const ZeckViewersModal = ({
  zeck,
  onClose,
  showToast,
}: ZeckViewersModalProps) => {
  return (
    <div className={styles.modalWrapper}>
      <h2 className={styles.modalHeader}>Manage Viewers</h2>
      <Tabs
        light={true}
        tabs={[
          {
            id: 'manage-viewers',
            displayName: 'Viewers',
            render: () => <AddZeckViewers zeck={zeck} onCancel={onClose} />,
          },
          {
            id: 'access-requests',
            displayName: 'Viewer Access Requests',
            render: () => (
              <AccessRequests
                showToast={showToast}
                zeck={zeck}
                onClose={onClose}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default ZeckViewersModal;
