import SessionLayout from '../../design-system/layouts/SessionLayout.tsx';
import styles from './ExpiredInvite.module.scss';
import useBreakpoints from '../../services/useBreakpoints.ts';
import cx from 'classnames';

export default function ExpiredInvite() {
  const { isMobile } = useBreakpoints();

  return (
    <SessionLayout>
      <span className={cx(styles.title, isMobile && styles.title_mobile)}>
        Invite Link Has Expired
      </span>
      <span className={styles.description}>
        This invite link has expired. Please contact the administrator of the
        company you’re trying to join and have them re-invite you.
      </span>
    </SessionLayout>
  );
}
