import { CompanyLite } from '../types.ts';
import { getCompanyIdFromPathname } from './getCompanyIdFromPathname.ts';

export class NoAccessToCompanyError extends Error {
  constructor() {
    super('No company matches id in URL');
  }
}

export default function determineActiveCompany(
  companies: CompanyLite[],
  pathname: string,
  localCompanyId: string | null,
): CompanyLite {
  const companyIdInURL = getCompanyIdFromPathname(pathname);

  let foundCompany: CompanyLite | undefined;
  if (companyIdInURL) {
    foundCompany = companies.find(
      (companyLite) => companyLite.id === companyIdInURL,
    );
  } else if (localCompanyId) {
    foundCompany = companies.find(
      (companyLite) => companyLite.id === localCompanyId,
    );

    if (!foundCompany) {
      foundCompany = companies[0];
    }
  } else if (!companyIdInURL && !localCompanyId) {
    foundCompany = companies[0];
  }

  if (!foundCompany) {
    throw new NoAccessToCompanyError();
  }

  return foundCompany;
}
