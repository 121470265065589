import React, { memo, MouseEventHandler, useRef, useState } from 'react';
import { TableOfContentsItemEditable } from '../../../../../design-system/atoms/TableOfContentsItem.tsx';
import IconButton from '../../../../../design-system/atoms/IconButton.tsx';
import HoverMenu, {
  HoverMenuButtonItem,
} from '../../../../../design-system/organisms/HoverMenu.tsx';
import { useNavigate } from 'react-router-dom';
import { IconName } from 'icons';
import mergeRefs from '../../../../../junkDrawer/mergeRefs.ts';

function within(x: number, y: number, range: number): boolean {
  return Math.abs(x - y) <= range;
}

type MenuAction = {
  iconName: IconName;
  color: 'danger' | 'normal';
  onClick: MouseEventHandler;
  content: string;
};

type SectionItemProps = {
  name: string;
  to: string;
  onRenameSection(newTitle: string): void;
  active: boolean;
  onRenameActiveSection: () => void;
  actions: (MenuAction | null | undefined | false)[];
  loading?: boolean;
};

const SectionItem = React.forwardRef<HTMLAnchorElement, SectionItemProps>(
  function SectionItem(
    {
      name,
      to,
      onRenameSection,
      onRenameActiveSection,
      active,
      actions,
      loading,
    },
    ref,
  ) {
    const itemRef = useRef<HTMLAnchorElement>(null);
    const kebabButtonRef = useRef<HTMLButtonElement>(null);
    const [showMenu, setShowMenu] = useState(false);
    const previousPosition = useRef<[number, number]>();
    const [isEditing, setIsEditing] = useState(false);

    const navigate = useNavigate();

    return (
      <>
        <TableOfContentsItemEditable
          {...{
            ref: mergeRefs([itemRef, ref]),
            to,
            active,
            onClick(e) {
              e.preventDefault();
              navigate(to);
            },
            hoverContent: (
              <IconButton
                ref={kebabButtonRef}
                name="kebab"
                aria-label="manage section"
                onClick={() => {
                  setShowMenu(true);
                }}
              />
            ),
            value: name,
            isEditing,
            loading,
            onSubmit(v) {
              setIsEditing(false);
              onRenameSection(v);
            },
          }}
        />
        <HoverMenu
          elementRef={itemRef}
          positionStrategy={(el) => {
            const rect = el.getBoundingClientRect();
            const position: [number, number] = [
              rect.x + rect.width - rect.height / 2,
              rect.y + rect.height / 2,
            ];
            if (
              previousPosition.current &&
              (!within(position[0], previousPosition.current[0], 5) ||
                !within(position[1], previousPosition.current[1], 5))
            ) {
              setShowMenu(false);
            }
            previousPosition.current = position;
            return position;
          }}
          onClose={() => {
            setShowMenu(false);
          }}
          isOpen={showMenu}
        >
          <HoverMenuButtonItem
            iconName="rename"
            color="normal"
            onClick={(e) => {
              e.stopPropagation();

              setShowMenu(false);
              if (active) {
                onRenameActiveSection();
              } else {
                setIsEditing(true);
              }
            }}
          >
            Rename
          </HoverMenuButtonItem>
          {actions
            .filter((a): a is MenuAction => !!a)
            .map(({ iconName, content, color, onClick }) => (
              <HoverMenuButtonItem
                key={content}
                {...{
                  iconName,
                  color,
                  onClick(e) {
                    e.stopPropagation();
                    setShowMenu(false);
                    onClick(e);
                  },
                }}
              >
                {content}
              </HoverMenuButtonItem>
            ))}
        </HoverMenu>
      </>
    );
  },
);

export default memo(SectionItem);
