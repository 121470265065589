import useApi from '../../../../../api/useApi.js';
import { foldResult, pipe } from '../../../../../result/Result.js';
import useFetch from '../../../../../services/useFetch/useFetch.js';
import ButtonWithLoading from '../../../../../design-system/molecules/ButtonWithLoading.js';
import { ReactComponent as GoogleSheetsLogo } from '../../../../../images/GoogleSheetsLogo.svg';
import styles from './PickCredential.module.scss';
import CredentialsList from './CredentialsList.tsx';
import useAddCredential from './useAddCredential.ts';
import {
  ErrorCredentialResponse,
  ValidCredentialResponse,
} from '../../../../../api/endpoints/createCredentialApi.ts';
import { ReactComponent as GoogleLogo } from '../../../../../images/GoogleLogo.svg';
import Loader from '../../../../../design-system/atoms/Loader.tsx';
import { MicrosoftExcelIcon, OneDriveIcon } from 'icons';
import ReconnectModalContent from './ReconnectModalContent.tsx';

type CredentialsListEmptyProps = {
  onClickAddAccount: () => void;
  isAddingCredential: boolean;
};

const GoogleCredentialsListEmpty: React.FC<CredentialsListEmptyProps> = ({
  onClickAddAccount,
  isAddingCredential,
}) => {
  return (
    <div className={styles.pickIntegration_credentialList__empty}>
      <GoogleSheetsLogo className={styles.pickIntegration_googleLogo} />
      <p className={styles.pickIntegration_subtitle}>
        Import reports and data directly into your Zeck.
      </p>
      <ButtonWithLoading
        size="medium"
        color="secondary"
        onClick={onClickAddAccount}
        isLoading={isAddingCredential}
        className={styles.pickIntegration_action}
      >
        <GoogleLogo className={styles.actionLogo} />
        Connect to Google
      </ButtonWithLoading>
    </div>
  );
};

const MicrosoftCredentialsListEmpty: React.FC<CredentialsListEmptyProps> = ({
  onClickAddAccount,
  isAddingCredential,
}) => {
  return (
    <div className={styles.pickIntegration_credentialList__empty}>
      <div className={styles.excelHeader}>
        <MicrosoftExcelIcon width="48" height="48" />
        <div className={styles.excelHeaderText}>Microsoft Excel</div>
      </div>

      <div className={styles.pickIntegration_subtitle}>
        Import reports and data directly into your Zeck.
      </div>

      <ButtonWithLoading
        size="medium"
        color="secondary"
        onClick={onClickAddAccount}
        isLoading={isAddingCredential}
        className={styles.pickIntegration_action}
      >
        <OneDriveIcon className={styles.actionLogo} />
        Connect to OneDrive
      </ButtonWithLoading>
    </div>
  );
};

type PickCredentialProps = {
  type: 'google' | 'microsoft';
  onErrorCredential: (credential: ErrorCredentialResponse) => void;
  onPickCredential: (credential: ValidCredentialResponse) => void;
  onCredentialResponse?: (
    credential: ErrorCredentialResponse | ValidCredentialResponse,
  ) => void;
  errorCredential?: ErrorCredentialResponse;
  onClose: () => void;
};

const isErrorCredential = (
  credential: ValidCredentialResponse | ErrorCredentialResponse,
): credential is ErrorCredentialResponse => credential.status === 'error';

const PickCredential: React.FC<PickCredentialProps> = ({
  errorCredential,
  onErrorCredential,
  onPickCredential,
  onCredentialResponse,
  type,
  onClose,
}) => {
  const { fetchAllCredentials, fetchCredentialResponse: fetchCredential } =
    useApi();

  const handleCredentialResponse = onCredentialResponse
    ? onCredentialResponse
    : (credential: ErrorCredentialResponse | ValidCredentialResponse) => {
        if (isErrorCredential(credential)) {
          onErrorCredential(credential);
        } else {
          onPickCredential(credential);
        }
      };

  const { addCredential, isAddingCredential } = useAddCredential(
    handleCredentialResponse,
    type,
  );

  const result = useFetch(() => fetchAllCredentials(type), []);

  if (errorCredential) {
    return (
      <ReconnectModalContent
        onReconnected={handleCredentialResponse}
        credential={errorCredential}
        onClose={onClose}
      />
    );
  }

  const CredentialsListEmpty =
    type === 'microsoft'
      ? MicrosoftCredentialsListEmpty
      : GoogleCredentialsListEmpty;

  return (
    <div className={styles.pickIntegration}>
      {pipe(
        result,
        foldResult({
          loading: () => (
            <div data-testid="credentials-spinner" className={styles.loader}>
              <Loader size="extra-large" />
            </div>
          ),
          error: () => <div>Error loading credentials.</div>,
          success: (credentials) => {
            if (credentials.length > 0) {
              return (
                <CredentialsList
                  credentials={credentials}
                  onClickAddAccount={addCredential}
                  isAddingCredential={isAddingCredential}
                  onPickCredential={async (id) => {
                    const credential = await fetchCredential(id);

                    handleCredentialResponse(credential);
                  }}
                  type={type}
                />
              );
            }

            return (
              <CredentialsListEmpty
                onClickAddAccount={addCredential}
                isAddingCredential={isAddingCredential}
              />
            );
          },
        }),
      )}
    </div>
  );
};

export default PickCredential;
