import { TextSelection } from './TextSelection.js';

export type BlockSelection = {
  anchorIndex: number;
  focusIndex: number;
};
export const blockSelection = (
  anchorIndex: number,
  focusIndex: number,
): BlockSelection => ({
  anchorIndex,
  focusIndex,
});

export const getStartOfBlockSelection = (selection: BlockSelection) =>
  Math.min(selection.anchorIndex, selection.focusIndex);

export const isBlockSelection = (
  selection: TextSelection | BlockSelection | null,
): selection is BlockSelection => {
  return (
    !!selection &&
    selection.hasOwnProperty('anchorIndex') &&
    selection.hasOwnProperty('focusIndex')
  );
};
export const indexIsInSelection = (
  selection: BlockSelection,
  index: number,
) => {
  const { anchorIndex, focusIndex } = selection;
  return (
    (anchorIndex <= index && index <= focusIndex) ||
    (focusIndex <= index && index <= anchorIndex)
  );
};

export const getStartOfSelection = (selection: BlockSelection): number =>
  Math.min(selection.focusIndex, selection.anchorIndex);

export const getEndOfSelection = (selection: BlockSelection): number =>
  Math.max(selection.focusIndex, selection.anchorIndex);
