import {
  bulletedListItemBlock,
  isTextBlock,
  numberedListItemBlock,
  TextBlock,
} from 'editor-content/Block.js';
import { BodyStateSelected } from './BodyEditor.js';
import splitContentByBlockSelection from '../../../../editor/selection/splitContentByBlockSelection.js';
import updateBlockAt from './updateBlockAt.js';
import { isBlockSelection } from '../../../../editor/selection/BlockSelection.js';
import { hydratedIsTextBlock } from './hydratedBlockGuards.js';

export default function turnInto(
  initialState: BodyStateSelected,
  newBlockType: TextBlock['type'],
): BodyStateSelected {
  const { content, selection } = initialState;

  if (isBlockSelection(selection)) {
    const [beforeBlocks, selectedBlocks, afterBlocks] =
      splitContentByBlockSelection(content, selection);
    if (!selectedBlocks.every(hydratedIsTextBlock))
      return {
        content,
        selection,
      };

    return {
      content: [
        ...beforeBlocks,
        ...selectedBlocks.map(convertBlock(newBlockType)),
        ...afterBlocks,
      ],
      selection,
    };
  }

  const selectedBlock = content[selection.index];

  if (!selectedBlock) return { content, selection };
  if (!isTextBlock(selectedBlock)) return { content, selection };

  return {
    content: updateBlockAt(
      content,
      selection.index,
      convertBlock(newBlockType)(selectedBlock),
    ),
    selection,
  };
}

const convertBlock = (newType: TextBlock['type']) => (block: TextBlock) => {
  if (newType === 'bulleted-list-item') {
    return bulletedListItemBlock(block.id, block.content, 0);
  }

  if (newType === 'numbered-list-item') {
    return numberedListItemBlock(block.id, block.content, 0);
  }

  return {
    ...block,
    type: newType,
  };
};
