import BaseBlock from 'editor-content/BaseBlock.ts';
import {
  BulletedListItemBlock,
  createBulletedListItemBlock,
  getTextFromBlock,
  ParagraphBlock,
} from 'editor-content/Block.ts';
import { HydratedBlock } from '../../../types/HydratedBlock.ts';
import { ContentPatch } from '../../ContentPatch.ts';
import ContentSelection, {
  contentSelection,
} from '../../selection/contentSelection/ContentSelection.ts';
import { textSelection } from '../../selection/TextSelection.ts';

export const paragraphReplaceWithBulletStrategy = <BlockType extends BaseBlock>(
  block: ParagraphBlock,
  selection: ContentSelection,
): ContentPatch<[BlockType | BulletedListItemBlock]> | void => {
  if (selection.anchorOffset !== 1) {
    return;
  }
  const text = getTextFromBlock(block);

  if (text !== '-') {
    return;
  }

  return {
    contentSubset: [createBulletedListItemBlock([], 0)],
    selection: textSelection(0, contentSelection(0)),
  };
};

export type BlockKeyboardBehavior<B extends HydratedBlock> = {
  replaceWithBullet: (
    block: B,
    selection: ContentSelection,
  ) => ContentPatch<HydratedBlock[]> | void;
};

export const ParagraphBlockKeyboardBehavior: BlockKeyboardBehavior<ParagraphBlock> =
  {
    replaceWithBullet: (block, selection) => {
      return paragraphReplaceWithBulletStrategy(block, selection);
    },
  };

export default ParagraphBlockKeyboardBehavior;
