import { useState } from 'react';

type UIState<PasteInfo> =
  | {
      type: 'initial';
    }
  | {
      type: 'confirming';
      data: PasteInfo;
    }
  | { type: 'loading' };

const usePasteContentProcessing = <PasteInfo>(
  PasteHandler: {
    onPasteContent: (pasteInfo: PasteInfo) => void;
  },
  ContentHandler: {
    needsConfirmation: (pasteInfo: PasteInfo) => boolean;
    needsCopy: (pasteInfo: PasteInfo) => boolean;
    performCopy: (pasteInfo: PasteInfo) => Promise<PasteInfo>;
  },
) => {
  const [uiState, setUiState] = useState<UIState<PasteInfo>>({
    type: 'initial',
  });

  return {
    state: uiState.type,
    handlePasteContent: (pasteInfo: PasteInfo) => {
      if (!ContentHandler.needsConfirmation(pasteInfo)) {
        PasteHandler.onPasteContent(pasteInfo);
        return;
      }

      setUiState({
        type: 'confirming',
        data: pasteInfo,
      });
    },

    handleConfirmPaste: async () => {
      if (uiState.type !== 'confirming') return;

      if (ContentHandler.needsCopy(uiState.data)) {
        setUiState({
          type: 'loading',
        });

        const newPasteInfo = await ContentHandler.performCopy(uiState.data);

        PasteHandler.onPasteContent(newPasteInfo);

        setUiState({
          type: 'initial',
        });
      } else {
        PasteHandler.onPasteContent(uiState.data);

        setUiState({
          type: 'initial',
        });
      }
    },

    handleCancelPaste: () => {
      setUiState({
        type: 'initial',
      });
    },
  };
};

export default usePasteContentProcessing;
