import { useEffect, useRef } from 'react';
import { isBlockSelection } from '../../../../editor/selection/BlockSelection.js';
import { EditorSelection } from '../../../../editor/EditorSelection.js';

const useExclusiveBlockSelection = (selection: EditorSelection) => {
  const editorEventListenerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (isBlockSelection(selection)) {
      document.getSelection()?.removeAllRanges();

      editorEventListenerRef.current?.focus();
    }
  }, [selection, editorEventListenerRef]);

  return editorEventListenerRef;
};

export default useExclusiveBlockSelection;
