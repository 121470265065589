import { useState } from 'react';

export default function useHover() {
  const [isHovered, setIsHovered] = useState(false);

  return {
    listeners: {
      onMouseEnter() {
        setIsHovered(true);
      },
      onMouseLeave() {
        setIsHovered(false);
      },
    },
    isHovered,
  };
}
