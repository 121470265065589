import useApi from '../../api/useApi.ts';
import { Result } from '../../services/useFetch/useFetch.js';
import { ZeckLite } from '../../types/ZeckLite.js';
import { ZeckFolderWithCount } from '../../types/ZeckFolder.js';
import {
  chainResult,
  foldResult,
  mapResult,
  pipe,
} from '../../result/Result.js';
import { CompanyLite } from '../../types/CompanyLite.js';

export function useZeckTrashActions(
  zecksResult: Result<ZeckLite[]>,
  foldersResult: Result<ZeckFolderWithCount[]>,
  invalidateZecks: (
    action: () => Promise<void>,
    optimisticData?: ZeckLite[],
  ) => Promise<void>,
  company: Pick<CompanyLite, 'id'>,
  showToast: (message: string) => void,
) {
  const { restoreZeck: restoreZeckApi, removeZeckFromTrash } = useApi();
  return pipe(
    zecksResult,
    chainResult((zecks) =>
      pipe(
        foldersResult,
        mapResult((folders) => ({
          zecks,
          folders,
        })),
      ),
    ),
    foldResult({
      success: ({ zecks, folders }) => ({
        restoreZeck: async (zeckId: string) => {
          const optimisticZecks = zecks.filter((z) => z.id !== zeckId);

          await invalidateZecks(async () => {
            await restoreZeckApi(company.id, zeckId);
          }, optimisticZecks);

          let destinationFolder = 'Home';
          const zeck = zecks.find((z) => z.id === zeckId);

          const folder = folders.find((f) => f.id === zeck?.folderId);
          destinationFolder = folder?.name ?? 'Home';

          showToast(`${zeck?.name} Restored to ${destinationFolder}`);
        },
        destroyZeck: async (zeckId: string) => {
          const optimisticZecks = zecks.filter((z) => z.id !== zeckId);

          await invalidateZecks(async () => {
            await removeZeckFromTrash(company.id, zeckId);
          }, optimisticZecks);

          const zeck = zecks.find((z) => z.id === zeckId);
          if (!zeck) return;

          showToast(`${zeck.name} Has Been Deleted.`);
        },
      }),
      loading: () => ({
        restoreZeck: async () => {},
        destroyZeck: async () => {},
      }),
      error: () => ({
        restoreZeck: async () => {},
        destroyZeck: async () => {},
      }),
    }),
  );
}
