import { Result } from '../services/useFetch/useFetch.js';
import { useEffect, useRef } from 'react';
import usePrevious from '../junkDrawer/usePrevious.js';
import { isLoadingResult, isSuccessResult } from './Result.js';

const useExecuteOnLoad = <ResultType>({
  result,
  onLoad,
}: {
  result: Result<ResultType>;
  onLoad: (result: ResultType) => void;
}) => {
  const onLoadRef = useRef(onLoad);
  onLoadRef.current = onLoad;

  const previous = usePrevious(result);

  useEffect(() => {
    if (
      isSuccessResult(result) &&
      (previous === null || isLoadingResult(previous))
    ) {
      onLoadRef.current(result.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);
};

export default useExecuteOnLoad;
