import cx from 'classnames';
import styles from './InputError.module.scss';

const InputError: React.FC<
  React.ComponentProps<'div'> & {
    size?: 'small' | 'normal';
  }
> = ({ size = 'normal', ...props }) => {
  return (
    <div
      {...props}
      className={cx(
        props.className,
        styles.inputError,
        size === 'small' && styles.inputError_small,
      )}
    >
      {props.children}
    </div>
  );
};

export default InputError;
