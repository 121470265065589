import { Section } from '../../types.js';

export default function getDefaultSectionViewContent<
  S extends Pick<Section, 'title'>,
>(section: S): S {
  return {
    ...section,
    title: section.title || 'Untitled',
  };
}
