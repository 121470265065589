import { ElementAndData } from '../../../junkDrawer/useElementAndDataArray.js';
import { HydratedBlock } from '../../../types/HydratedBlock.js';
import { BulletedListItemBlock, TextBlock } from 'editor-content/Block.js';
import MultiblockFormattingExperience from '../../multiblockFormatting/MultiblockFormattingExperience.js';
import { EditorStateGeneric } from '../../EditorStateGeneric.js';
import IconButton from '../../../design-system/atoms/IconButton.js';
import { ToolbarGroup } from '../../../design-system/organisms/Toolbar.js';
import createZeckEditorTurnIntoables from '../../../pages/zeck/editor/editableBlocks/createZeckEditorTurnIntoables.js';

const ZeckMultiblockFormattingExperience: React.FC<{
  editorState: EditorStateGeneric<HydratedBlock>;
  blocksWithEl: ElementAndData<HydratedBlock>[];
  onTurnInto: (type: TextBlock['type']) => void;
  onIndent: (indent: -1 | 1) => void;
}> = ({ editorState, blocksWithEl, onTurnInto, onIndent }) => {
  return (
    <MultiblockFormattingExperience
      editorState={editorState}
      blocksWithEl={blocksWithEl}
      turnIntoables={createZeckEditorTurnIntoables(onTurnInto)}
      renderAdditionalActions={(selectedBlocks) => {
        const allBulletListItemBlocks = selectedBlocks.every(
          (block): block is BulletedListItemBlock =>
            block.type === 'bulleted-list-item',
        );

        const canDedent =
          allBulletListItemBlocks &&
          !selectedBlocks.every((block) => block.indent === 0);
        const canIndent =
          allBulletListItemBlocks &&
          !selectedBlocks.every((block) => block.indent === 1);

        if (!canDedent && !canIndent) return null;

        return (
          <ToolbarGroup>
            {canDedent && (
              <IconButton
                name="indentDecrease"
                type="dark"
                onClick={() => onIndent(-1)}
                aria-label="decrease text indent"
              />
            )}
            {canIndent && (
              <IconButton
                name="indentIncrease"
                type="dark"
                onClick={() => onIndent(1)}
                aria-label="increase text indent"
              />
            )}
          </ToolbarGroup>
        );
      }}
    />
  );
};

export default ZeckMultiblockFormattingExperience;
