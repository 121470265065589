export type Trivia = {
  question: string;
  answer: string;
};

const trivia: Trivia[] = [
  {
    question:
      'In the meantime, try to think of the first number with the letter A in it when written.',
    answer: 'One thousand',
  },
  {
    question:
      'In the meantime, what movie is this line from: “You cannot make friends with the rock stars.”',
    answer: 'Almost Famous',
  },
  {
    question:
      'Quick trivia while you wait…Who was Commodus’s dad? Think Gladiator.',
    answer: 'Marcus Aurelius',
  },
  {
    question:
      'Who was the first sitting US President to travel outside the US?',
    answer: 'Theodore Roosevelt',
  },
  {
    question:
      'Quick trivia while you wait…In 1512 which ceiling was presented to the public for the first time?',
    answer: 'The Sistine Chapel',
  },
  {
    question: 'While you wait…Which notable book was first titled The Whale?',
    answer: 'Moby Dick',
  },
  {
    question:
      'In the meantime…Mammoth Cave is the longest cave system in the world. What state is it located in?',
    answer: 'Kentucky',
  },
  {
    question: 'Quick trivia while you wait…Who was married to Anne Hathaway?',
    answer: 'William Shakespeare',
  },
  {
    question: 'Which team won the first SuperBowl?',
    answer: 'The Green Bay Packers',
  },
  {
    question:
      'Quick trivia while you wait…What’s the second largest (area, not population) country in the world?',
    answer: 'Canada',
  },
  {
    question: '"Correct. Dinner Out is a Go." What movie?',
    answer: 'Spy Game',
  },
];

export default trivia;
