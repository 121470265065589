import { Editor, TextNode } from './TextNode.js';

type Monoid<T> = {
  concat: (a: T, b: T) => T;
  empty: T;
};

function assertUnreachable(arg: never): never {
  throw new Error("Didn't expect to get here with:" + arg);
}

export default function textNodesTo<T, C>(
  config: {
    text: (node: Editor.Text, context: C) => T;
    externalLink: (
      node: Editor.ExternalLink,
      context: C,
    ) => (innerContent: T) => T;
    sectionLink: (
      node: Editor.SectionLink,
      context: C,
    ) => (innerContent: T) => T;
    meetingMinutesLink: (
      node: Editor.MeetingMinutesLink,
      context: C,
    ) => (innerContent: T) => T;
    highlight: (node: Editor.Highlight, context: C) => (innerContent: T) => T;
  },
  Monoid: Monoid<T>,
): (textNodes: TextNode[], context: C) => T {
  function textNodesToT(textNodes: TextNode[], context: C): T {
    return textNodes
      .map((textNode) => {
        switch (textNode.type) {
          case 'text':
            return config.text(textNode, context);
          case 'external-link':
            return config.externalLink(
              textNode,
              context,
            )(textNodesToT(textNode.content, context));
          case 'section-link':
            return config.sectionLink(
              textNode,
              context,
            )(textNodesToT(textNode.content, context));
          case 'meeting-minutes-link':
            return config.meetingMinutesLink(
              textNode,
              context,
            )(textNodesToT(textNode.content, context));
          case 'highlight':
            return config.highlight(
              textNode,
              context,
            )(textNodesToT(textNode.content, context));
          default:
            return assertUnreachable(textNode);
        }
      })
      .reduce(Monoid.concat, Monoid.empty);
  }

  return textNodesToT;
}
