import { version } from '../version.js';
import * as Sentry from '@sentry/react';

export const makeMakeApiRequest =
  (apiRootUrl: string) =>
  async (path: string, options: RequestInit, blob = false) => {
    let response: Response;
    const method = options.method || 'GET';
    try {
      response = await fetch(`${apiRootUrl}${path}`, {
        ...options,
        method,
        headers: { ...options.headers, Version: version },
      });
    } catch (error) {
      // connectivity error
      Sentry.captureException(error, {
        fingerprint: ['fetch-error'],
        tags: {
          method,
          url: apiRootUrl + path,
        },
      });

      throw error;
    }

    if ([200, 201].includes(response.status)) {
      try {
        if (blob) {
          return await response.blob();
        }
        return await response.json();
      } catch (e) {
        // connectivity or parse error
        Sentry.captureException(e, {
          tags: {
            method,
            url: apiRootUrl + path,
          },
        });

        throw e;
      }
    }

    if (response.status === 204 || response.status === 304) {
      return;
    }

    let responseBody = null;
    try {
      responseBody = await response.json();
    } catch (e) {
      // no body
    }

    throw new RequestError(
      method,
      path,
      response.status,
      responseBody?.errorBody,
      responseBody?.userMessage,
    );
  };

export function hasStatus(obj: unknown): obj is { status: number } {
  return !!obj && (obj as { status: number }).status !== undefined;
}

export class RequestError extends Error {
  status: number;
  body: unknown;
  userMessage?: string;

  constructor(
    method: string,
    path: string,
    status: number,
    body: unknown,
    userMessage?: string,
  ) {
    let bodyMessage = `unknown error: ${body}`;
    if (body) {
      try {
        bodyMessage = JSON.stringify(body);
      } catch {}
    }
    super(
      `Request Failed: ${method} ${path} > ${status}; body: ${bodyMessage}`,
    );
    this.status = status;
    this.body = body;
    this.userMessage = userMessage;
  }
}
