import LinkButton from '../../../design-system/atoms/LinkButton.tsx';
import {
  AccessPageContent,
  AccessPageLayout,
} from '../../../design-system/organisms/AccessPage.js';

export const NotFoundPage = ({ to }: { to: string }) => {
  return (
    <AccessPageLayout>
      <AccessPageContent
        title={"This Page Doesn't Exist."}
        description={
          'Fastest person to click the button gets a beautiful invisible medallion.'
        }
        actions={
          <LinkButton size="large" to={to} isFullWidth>
            Take Me to Home
          </LinkButton>
        }
      />
    </AccessPageLayout>
  );
};
