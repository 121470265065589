import { Zeck } from '../../../app/types.js';
import hydratePublishedZeck from './hydratePublishedZeck.js';
import hydrateSection from './hydrateSection.js';
import HydratedZeck from './types/HydratedZeck.js';

function hydrateZeck(zeck: Zeck): HydratedZeck {
  const publishedZeck = zeck.publishedZeck;

  return {
    ...zeck,
    publishedZeck: publishedZeck && hydratePublishedZeck(publishedZeck),
    sections: zeck.sections.map(hydrateSection),
  };
}

export default hydrateZeck;
