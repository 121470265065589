import { RefObject, useEffect } from 'react';

const useViewportHeight = (scrollContainer: RefObject<HTMLElement> | void) => {
  useEffect(() => {
    const element =
      scrollContainer && scrollContainer.current
        ? scrollContainer.current
        : document.documentElement;

    // this should maybe be debounced
    const listener = () => {
      const vh = element.clientHeight;
      element.style.setProperty('--viewportHeight', `${vh}px`);
    };

    listener();

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [scrollContainer]);
};

export default useViewportHeight;
