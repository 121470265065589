import Point from '../../Point.js';

export type PositionStrategyRect = (
  targetRect: DOMRect,
  popoverRect: DOMRect,
) => Point | null;

export const fromElement = (
  strat: PositionStrategyRect,
): PositionStrategyElem => {
  return (targetElement, popoverElement) => {
    const targetElementRect = targetElement.getBoundingClientRect();
    const popoverElementRect = popoverElement.getBoundingClientRect();
    return strat(targetElementRect, popoverElementRect);
  };
};

export type BoundingClientRectEle = {
  getBoundingClientRect: HTMLElement['getBoundingClientRect'];
};

type PositionStrategyElem = (
  targetElement: BoundingClientRectEle,
  popoverElement: BoundingClientRectEle,
) => Point | null;

export default PositionStrategyElem;
