export default function changeBlockAt<T>(
  content: T[],
  index: number,
  newBlock: T,
): T[] {
  return content.map((block, i) => {
    if (i === index) {
      return newBlock;
    }
    return block;
  });
}
