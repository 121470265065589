type BaseAndExtent = {
  anchorNode: Node;
  anchorOffset: number;
  focusNode: Node;
  focusOffset: number;
};

type CharacterOffset = {
  anchorOffset: number;
  focusOffset: number;
};

function* allTextNodes(element: HTMLElement): Generator<Text, void> {
  for (const childNode of element.childNodes) {
    if (childNode.nodeType === Node.TEXT_NODE) {
      yield childNode as Text;
    } else if (childNode.nodeType === Node.ELEMENT_NODE) {
      yield* allTextNodes(childNode as HTMLElement);
    }
  }
}

const findNodeAndOffset = (
  containerElement: HTMLElement,
  charOffset: number,
): [Node, number] => {
  const textNodes = Array.from(allTextNodes(containerElement));

  let traveledLength = 0;

  for (const textNode of textNodes) {
    const newLength = traveledLength + textNode.length;
    if (newLength >= charOffset) {
      return [textNode, charOffset - traveledLength];
    }

    traveledLength = newLength;
  }

  const lastTextNode = textNodes[textNodes.length - 1];

  if (!lastTextNode) {
    return [containerElement, 0];
  }

  return [lastTextNode, lastTextNode.length];
};

export const characterOffsetToBaseAndExtent = (
  containerElement: HTMLElement,
  contentSelection: CharacterOffset,
): BaseAndExtent => {
  const { anchorOffset, focusOffset } = contentSelection;

  const [anchorNode, anchorNodeOffset] = findNodeAndOffset(
    containerElement,
    anchorOffset,
  );
  const [focusNode, focusNodeOffset] = findNodeAndOffset(
    containerElement,
    focusOffset,
  );
  return {
    anchorNode,
    anchorOffset: anchorNodeOffset,
    focusNode,
    focusOffset: focusNodeOffset,
  };
};

export const baseAndExtentToCharacterOffset = (
  containerElement: HTMLElement,
  baseAndExtent: BaseAndExtent,
): CharacterOffset => {
  const anchorRange = new Range();
  anchorRange.setStartBefore(containerElement);
  anchorRange.setEnd(baseAndExtent.anchorNode, baseAndExtent.anchorOffset);
  const anchorOffset = anchorRange.toString().length;

  const focusRange = new Range();
  focusRange.setStartBefore(containerElement);
  focusRange.setEnd(baseAndExtent.focusNode, baseAndExtent.focusOffset);
  const focusOffset = focusRange.toString().length;
  return {
    anchorOffset,
    focusOffset,
  };
};
