const defaultTestimonial = {
  quote:
    'Zeck is light speed faster for me as CEO, more readable for my board members, and the engagement tools dramatically improve the quality of our meetings.',
  imageClass: 'josh',
  name: 'Josh Trautwein',
  title: 'Founder & CEO',
  company: 'About Fresh',
} as const;

const testimonials = [
  defaultTestimonial,
  {
    quote:
      'Zeck is an amazing tool that lets us spend time on the things that will actually impact our business',

    imageClass: 'kerry',

    name: 'Kerry Michaels',
    title: 'Founder & CEO',
    company: 'William Murray Golf',
  },
  {
    quote:
      'We used to spend way too much time putting our board decks together. Zeck flipped that entirely. Now, we can focus on meaningful, strategic content for discussion.',

    imageClass: 'kyle',
    name: 'Kyle Hoff',
    title: 'CEO',
    company: 'Floyd',
  },
] as const;

export const testimonialRandomizer = () => {
  const randomIndex = Math.floor(Math.random() * testimonials.length);
  return testimonials[randomIndex] ?? defaultTestimonial;
};
