import cx from 'classnames';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ClickToCopyIcon from '../../../../design-system/atoms/ClickToCopyIcon.js';
import { FloatingSidebarHeader } from '../../../../design-system/molecules/FloatingSidebar.js';
import readTimeForZeck from '../../../../readTime/readTimeForZeck.js';
import {
  sectionEditPath,
  zeckHomePath,
  zeckPublishPath,
} from '../../../../services/Paths.js';
import { useSignal } from '../../../../signals/index.js';
import styles from './EditorSidebar.module.scss';
import WithTooltip from '../../../../design-system/organisms/WithTooltip.tsx';
import SectionWithActions from '../zeckCover/SectionWithActions.js';
import ZeckWithActions from '../zeckCover/ZeckWithActions.js';
import DeletedSections from './DeletedSections.js';
import { GoToSite } from './GoToSite.js';
import Publish from './Publish.js';
import TableOfContents from './TableOfContents.js';
import ZeckMenu from './ui/ZeckMenu.js';
import ZeckSettingsModal, {
  ManageZeckModalActiveType,
} from './ZeckSettingsModal.tsx';

type SidebarProps = {
  isOpen: boolean;
  onClose: () => void;
  zeck: ZeckWithActions;
  currentSection?: SectionWithActions;
  onRenameActiveSection: () => void;
};

function EditorSidebar({
  isOpen,
  onClose,
  zeck,
  currentSection,
  onRenameActiveSection,
}: SidebarProps) {
  const modalType$ = useSignal<ManageZeckModalActiveType>('analytics');
  const modalOpen$ = useSignal<boolean>(false);

  const navigate = useNavigate();
  const restoreSection = useCallback(
    async (sectionId: string) => {
      const section = await zeck.actions.restoreSection(sectionId);

      navigate(sectionEditPath(section, zeck.companyId));
    },
    [navigate, zeck],
  );

  const setOpenModal = (type: ManageZeckModalActiveType) => {
    modalType$.set(type);
    modalOpen$.set(true);
  };

  let publishedZeckUrl = '';

  if (zeck.publishedZeck) {
    publishedZeckUrl = `${window.location.origin}${zeckPublishPath(zeck)}`;
  }

  return (
    <div
      className={cx(styles.zeckSidebar, isOpen && styles.zeckSidebar_open)}
      data-testid="sidebar"
    >
      <FloatingSidebarHeader onClose={onClose} homeHref={zeckHomePath(zeck)} />

      <div className={styles.zeckSidebar__body}>
        <div className={styles.zeckTitle}>
          <h1>{zeck.name}</h1>
          {zeck.isAdmin && <ZeckMenu setOpenModal={setOpenModal} />}
        </div>

        {zeck.isAdmin && (
          <div className={styles.readTime}>
            {readTimeForZeck(zeck)} min total read time
          </div>
        )}

        <div className={styles.zeckActions}>
          {zeck.isAdmin && (
            <Publish zeck={zeck} currentSection={currentSection} />
          )}
          {(zeck.isAdmin || zeck.isViewer) && (
            <GoToSite
              {...{
                iconName: 'goTo',
                href: zeckPublishPath(zeck),
                target: '_blank',
                disabled: !zeck.publishedAt,
              }}
            />
          )}
          {zeck.isAdmin && (
            <WithTooltip<HTMLButtonElement> text="Copy link to Published Site">
              {(ref, listeners) => (
                <ClickToCopyIcon
                  copyContent={publishedZeckUrl}
                  name="copyLink"
                  aria-label="copy published zeck link"
                  data-testid="copy-published-zeck-link-icon-button"
                  disabled={publishedZeckUrl === ''}
                  ref={ref}
                  {...listeners}
                />
              )}
            </WithTooltip>
          )}
        </div>

        <TableOfContents
          zeck={zeck}
          currentSection={currentSection}
          onClickDeleteSection={(sectionToDelete) =>
            sectionToDelete.actions.remove()
          }
          onRenameActiveSection={onRenameActiveSection}
        />

        {zeck.isAdmin && (
          <DeletedSections zeckId={zeck.id} onRestore={restoreSection} />
        )}
      </div>

      <ZeckSettingsModal
        zeck={zeck}
        modalPanel$={modalType$}
        modalOpen$={modalOpen$}
      />
    </div>
  );
}

export default memo(EditorSidebar);
