import { AiStarIcon } from 'icons';
import { ModalHeader } from '../../../../../design-system/organisms/Modal.tsx';
import styles from './AiModalHeader.module.scss';

type AiModalHeaderProps = {
  children?: React.ReactNode;
};

const AiModalHeader: React.FC<AiModalHeaderProps> = ({ children }) => (
  <ModalHeader className={styles.modalHeader}>
    <AiStarIcon />{' '}
    <span className={styles.primaryTitle}>Zeck AI Free Beta</span>
    {children}
  </ModalHeader>
);

export default AiModalHeader;
