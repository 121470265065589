export default function replaceBlockAt<T>(
  content: T[],
  newBlocks: T[],
  index: number,
): T[] {
  return content.flatMap((block, i) => {
    if (i === index) {
      return newBlocks;
    }
    return block;
  });
}
