import { getSectionLinkFromElement } from 'editor-content/html/SectionLinkElement.ts';
import { getHighlightFromElement } from 'editor-content/html/HighlightElement.ts';
import { getExternalLinkFromElement } from 'editor-content/html/ExternalLinkElement.ts';
import {
  isHighlight,
  isText,
  NonHighlightTextNode,
  Text,
  TextFormat,
  TextNode,
} from 'editor-content/TextNode.js';
import { getMeetingMinutesLinkFromElement } from 'editor-content/html/MeetingMinutesLinkElement.js';

const elementToFormat = (element: HTMLElement): TextFormat => {
  switch (element.tagName) {
    case 'B':
      return { bold: true };
    case 'I':
      return { italic: true };
    case 'U':
      return { underline: true };
    default:
      return {};
  }
};

const applyFormatToTextNode = <T extends TextNode>(
  textNode: T,
  format: TextFormat,
): T => {
  if (isText(textNode)) {
    return {
      ...textNode,
      format: {
        ...textNode.format,
        ...format,
      },
    };
  }

  return {
    ...textNode,
    content: textNode.content.map((node) =>
      applyFormatToTextNode(node, format),
    ),
  };
};

const elementToTextNodes = (element: {
  childNodes: NodeListOf<ChildNode>;
}): TextNode[] => {
  return Array.from(element.childNodes).flatMap((childNode): TextNode[] => {
    if (childNode.nodeType === Node.TEXT_NODE) {
      // is text node
      return [Text(childNode.textContent || '')];
    } else if (childNode.nodeType === Node.ELEMENT_NODE) {
      const htmlChild = childNode as HTMLElement;

      const childTextNodes = elementToTextNodes(htmlChild);
      const textNode =
        getExternalLinkFromElement(htmlChild, childTextNodes.filter(isText)) ??
        getMeetingMinutesLinkFromElement(
          htmlChild,
          childTextNodes.filter(isText),
        ) ??
        getSectionLinkFromElement(htmlChild, childTextNodes.filter(isText)) ??
        getHighlightFromElement(
          htmlChild,
          childTextNodes.filter(
            (node): node is NonHighlightTextNode => !isHighlight(node),
          ),
        );

      if (textNode) return [textNode];
      // is formatted text node
      // get child nodes and apply format to all nodes
      return childTextNodes.map((textNode) =>
        applyFormatToTextNode(textNode, elementToFormat(htmlChild)),
      );
    }

    return [];
  });
};

export default function htmlStringToTextNodes(html: string): TextNode[] {
  const p = new DOMParser();
  const d = p.parseFromString(html, 'text/html');
  return elementToTextNodes(d.body);
}
