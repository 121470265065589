import { Section } from '../types.js';
import getIdFromSlug from '../getIdFromSlug.js';
import { slugify } from '../services/Paths.js';

export const getTitleFromSection = (
  section: Pick<Section, 'title'>,
): string => {
  return section.title.trim() || 'Untitled';
};

export const getSlugFromSection = (
  section: Pick<Section, 'id' | 'title'>,
): string => {
  const titleSlug = slugify(getTitleFromSection(section).slice(0, 16));
  const { id } = section;
  return `${titleSlug}-${id}`;
};

export const getSectionIdFromSlug = getIdFromSlug;

export const isSupplemental = ({
  supplemental,
}: Pick<Section, 'supplemental'>) => supplemental;
