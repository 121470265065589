import { SidebarLink } from '../../../design-system/molecules/SidebarItem.tsx';
import styles from './Sidebar.module.scss';

const CurrentUserSidebarItem: React.FC<{
  className?: string;
  firstName: string;
  lastName: string;
  email: string;
  collapse?: boolean;
  active: boolean;
}> = ({ active, className, firstName, lastName, email, collapse }) => {
  const subLabelClass = active
    ? styles.accountInfo__sublabelActive
    : styles.accountInfo__sublabel;

  const iconClass = active
    ? styles.accountInfo__iconActive
    : styles.accountInfo__icon;
  return (
    <SidebarLink
      {...{
        className,
        isEditing: false,
        href: '/profile',
        icon: (
          <span className={iconClass}>
            {firstName.slice(0, 1)}
            {lastName.slice(0, 1)}
          </span>
        ),
        label: (
          <div className={styles.accountInfo__label}>
            {firstName} {lastName}
          </div>
        ),
        sublabel: <div className={subLabelClass}>{email}</div>,
        iconOnly: collapse,
        active,
      }}
    />
  );
};

export default CurrentUserSidebarItem;
