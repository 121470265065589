import { useRef, useState } from 'react';
import { CompanyRole, CompanyUser } from '../../types.ts';
import IconButton from '../../design-system/atoms/IconButton.tsx';
import HoverMenu from '../../design-system/organisms/HoverMenu.tsx';
import styles from './RoleDropdown.module.scss';
import RoleOptions from './RoleOptions.tsx';
import useBreakpoints from '../../services/useBreakpoints.ts';
import DrawerMenu from '../../design-system/organisms/DrawerMenu.tsx';

type RoleDropdownProps = {
  user: CompanyUser;
  onUpdateRole: (role: CompanyRole) => void;
  onDelete: () => void;
  onResendInvitation: () => void;
  onUpdateBoardDirector?: (boardDirector: boolean) => void;
  disableRoleChange?: boolean;
};

const rolePhrases = {
  admin: 'Admin',
  member: 'Member',
  legal_admin: 'Legal Admin',
};

export default function RoleDropdown({
  user,
  onUpdateRole,
  onDelete,
  onResendInvitation,
  onUpdateBoardDirector,
  disableRoleChange = false,
}: RoleDropdownProps) {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownButtonRef = useRef<HTMLLabelElement>(null);
  const { isMobile } = useBreakpoints();

  const disableChange = disableRoleChange && !isMobile;

  if (disableChange) {
    return (
      <label ref={dropdownButtonRef} className={styles.dropdownButton}>
        <span data-testid="person-role" className={styles.disabled_role}>
          {rolePhrases[user.role]}
          {user.pending ? ' (Invited)' : ''}
        </span>
      </label>
    );
  }

  return (
    <>
      <label ref={dropdownButtonRef} className={styles.dropdownButton}>
        <span data-testid="person-role">
          {rolePhrases[user.role]}
          {user.pending ? ' (Invited)' : ''}
        </span>
        <IconButton
          name="chevronDown"
          aria-label="manage role"
          onClick={() => {
            setShowDropdown(true);
          }}
        />
      </label>

      {isMobile ? (
        <DrawerMenu
          isOpen={showDropdown}
          onRequestClose={() => {
            setShowDropdown(false);
          }}
        >
          <div>
            <div className={styles.drawerHeader}>
              <span>User Type</span>
              <IconButton
                name="close"
                aria-label="close drawer"
                onClick={() => {
                  setShowDropdown(false);
                }}
              />
            </div>

            <RoleOptions
              onClose={() => {
                setShowDropdown(false);
              }}
              onSelect={onUpdateRole}
              onDelete={onDelete}
              onResendInvitation={user.pending ? onResendInvitation : null}
              selectedRole={user.role}
              boardDirectorConfig={
                onUpdateBoardDirector
                  ? {
                      onUpdateBoardDirector,
                      isBoardDirector: user.boardDirector,
                    }
                  : undefined
              }
              disableRoleChange={disableRoleChange}
            />
          </div>
        </DrawerMenu>
      ) : (
        <HoverMenu
          className={styles.hoverMenu}
          elementRef={dropdownButtonRef}
          positionStrategy={(buttonElement, dropdownElement) => {
            const buttonCoordinates = buttonElement.getBoundingClientRect();
            const dropdownCoordinates = dropdownElement.getBoundingClientRect();
            return [
              buttonCoordinates.x -
                dropdownCoordinates.width +
                buttonCoordinates.width,
              buttonCoordinates.y + buttonCoordinates.height + 8,
            ];
          }}
          isOpen={showDropdown}
          onClose={() => {
            setShowDropdown(false);
          }}
        >
          <RoleOptions
            onClose={() => {
              setShowDropdown(false);
            }}
            onSelect={onUpdateRole}
            onDelete={onDelete}
            onResendInvitation={user.pending ? onResendInvitation : null}
            selectedRole={user.role}
            disableRoleChange={false}
          />
        </HoverMenu>
      )}
    </>
  );
}
