import { VoteDetails } from '../vote/Vote.tsx';
import styles from './PrevoteTally.module.scss';
import {
  PrevoteResult,
  PrevoteTally,
} from '../../../pages/zeck/voting/VoteCapability.js';

const PrevoteTally: React.FC<{
  prevoteTally: PrevoteTally;
  prevoteResults?: PrevoteResult;
  className?: string;
}> = ({ prevoteResults, prevoteTally, className }) => {
  return (
    <VoteDetails className={className}>
      <span className={styles.voteCounts}>
        <span data-testid="prevote-tally">
          {prevoteTally.votedBoardDirectorCount} out of{' '}
          {prevoteTally.boardDirectorCount} board directors have pre-voted.
        </span>
        {prevoteResults && (
          <span
            className={styles.voteCounts__results}
            data-testid="prevote-result"
          >
            <span data-testid="prevote-approve">
              <span className={styles.voteCounts__approve}>Approved: </span>
              {prevoteResults.approvedCount}
            </span>

            <span data-testid="prevote-not-approve">
              <span className={styles.voteCounts__notApprove}>
                Not Approved:{' '}
              </span>
              {prevoteResults.notApprovedCount}
            </span>

            <span data-testid="prevote-abstain">
              <span className={styles.voteCounts__abstain}>Abstained: </span>
              {prevoteResults.abstainedCount}
            </span>
          </span>
        )}
      </span>
    </VoteDetails>
  );
};

export default PrevoteTally;
