import throttle from 'lodash/throttle.js';
import { useMemo, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useThrottle<T extends (...args: any[]) => void>(
  fn: T,
  wait: number,
) {
  const fnRef = useRef<T>(fn);
  fnRef.current = fn;

  const throttledFn = useMemo(
    () =>
      throttle((...args: Parameters<T>) => {
        fnRef.current(...args);
      }, wait),
    [wait],
  );

  return (...args: Parameters<T>) => {
    throttledFn(...args);
  };
}
