import { IconName } from 'icons';
import ToolbarDropdownMenu, {
  ToolbarDropdownMenuItem,
} from '../organisms/ToolbarDropdownMenu.tsx';

export type TurnIntoable = {
  displayName: string;
  iconName: IconName;
  onTurnInto: () => void;
};

type TurnIntoMenuProps = {
  className?: string;
  turnIntoables: TurnIntoable[];
  onClickMenuItem: () => void;
};

export default function TurnIntoMenu({
  turnIntoables,
  onClickMenuItem,
  className,
}: TurnIntoMenuProps) {
  return (
    <ToolbarDropdownMenu className={className} data-testid="turn-into-dropdown">
      {turnIntoables.map(({ iconName, displayName, onTurnInto }, i) => (
        <ToolbarDropdownMenuItem
          key={i}
          {...{
            iconName,
            onClick: () => {
              onTurnInto();
              onClickMenuItem();
            },
          }}
        >
          {displayName}
        </ToolbarDropdownMenuItem>
      ))}
    </ToolbarDropdownMenu>
  );
}
