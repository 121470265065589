import { AccessRequest, AccessRequestGrantResponse } from '../../types.ts';
import { makeMakeApiRequest } from '../makeMakeApiRequest.js';

const createAccessRequestApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  grantAccessRequest: async (
    accessRequestId: string,
  ): Promise<AccessRequestGrantResponse> => {
    return makeLambdaApiRequest(`/access-request/${accessRequestId}/grant`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  fetchPendingAccessRequests: async (
    zeckId: string,
    cursor: string | null,
  ): Promise<{
    models: AccessRequest[];
    pagination: { cursor: string | null };
  }> => {
    const maybeCursor = cursor ? `?cursor=${cursor}` : '';

    const result = await makeLambdaApiRequest(
      `/zeck/${zeckId}/access-requests${maybeCursor}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return {
      models: result.accessRequests,
      pagination: { cursor: result.cursor },
    };
  },
  requestAccessRequest: async (body: {
    type: 'zeck' | 'section';
    requestedId: string;
    path: string;
  }): Promise<void> => {
    return makeLambdaApiRequest(`/access-request`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
});

export default createAccessRequestApi;
