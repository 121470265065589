import { TextBlock } from 'editor-content/Block.js';
import ContentSelection, {
  isCollapsed,
} from '../../selection/contentSelection/ContentSelection.js';
import getTextBlockLength from './getTextBlockLength.js';

export default function textBlockPressArrowRightStrategy(
  selection: ContentSelection | null,
  block: TextBlock,
) {
  if (!selection || !isCollapsed(selection)) {
    return false;
  }

  return selection.anchorOffset >= getTextBlockLength(block);
}
