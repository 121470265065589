import qs from 'query-string';

export const features = [
  'demoFlag',
  'editorDebug',
  'showAllAnalytics',
] as const;

export type Features = (typeof features)[number];
export type FeaturesLookup = { [feature in Features]: boolean };

export function makeFeaturesLookup(enabledFeatures: Array<string>) {
  const lookup: { [f: string]: boolean } = {};

  for (const feature of features) {
    lookup[feature] = false;
  }

  for (const feature of enabledFeatures) {
    if (feature in lookup) {
      lookup[feature] = true;
    }
  }

  return lookup as FeaturesLookup;
}

export const getFeatures = () => {
  const queryStringParams = qs.parse(document.location.search);
  return makeFeaturesLookup(Object.keys(queryStringParams));
};
