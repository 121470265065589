import useLocalStorageState from '../services/useLocalStorageState.js';
import { useEffect } from 'react';
import { decodeAccessToken } from './jwt.js';

type ValidatedAccessToken = {
  accessToken: string;
  exp: number;
};

function isExpired(exp: number | null) {
  return exp !== null && exp * 1000 < Date.now();
}

export function validateAccessToken(
  accessToken: string | null,
): ValidatedAccessToken | null {
  const data = decodeAccessToken(accessToken);

  if (!accessToken || !data || !data.exp || isExpired(data.exp)) {
    return null;
  }

  return { accessToken, exp: data.exp * 1000 };
}

// use a fudge factor of 30 minutes to account for clock skew
const FUDGE_FACTOR = 30 * 60 * 1000;

const useAccessToken = () => {
  const [accessToken, setAccessToken] = useLocalStorageState('accessToken');
  const validatedAccessToken = validateAccessToken(accessToken);

  useEffect(() => {
    if (!validatedAccessToken) {
      setAccessToken(null);
      return;
    }
  }, [validatedAccessToken, setAccessToken]);

  function isTokenExpired() {
    return (
      validatedAccessToken &&
      validatedAccessToken.exp &&
      Date.now() > validatedAccessToken.exp * 1000 - FUDGE_FACTOR
    );
  }

  return {
    accessToken: validatedAccessToken?.accessToken ?? null,
    setAccessToken,
    isTokenExpired,
  };
};

export default useAccessToken;
