import styles from './ViewPageLayout.module.scss';

type ViewPageLayoutProps = {
  bannerSlot?: React.ReactNode;
  headerSlot: React.ReactNode;
  children: React.ReactNode;
  sidebarSlot: React.ReactNode;
  scrollContainerRef?: React.Ref<HTMLDivElement>;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
};

const ViewPageLayout = ({
  bannerSlot,
  headerSlot,
  children,
  sidebarSlot,
  scrollContainerRef,
  onScroll,
}: ViewPageLayoutProps) => {
  return (
    <div className={styles.viewPage}>
      {bannerSlot}
      <div className={styles.viewPage__body}>
        <div className={styles.viewPage__header}>{headerSlot}</div>
        <div
          data-testid="scrollable"
          ref={scrollContainerRef}
          className={styles.viewPage__content}
          onScroll={onScroll}
        >
          {children}
        </div>
        {sidebarSlot}
      </div>
    </div>
  );
};

export default ViewPageLayout;
