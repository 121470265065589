import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.js';
import { ElementAndData } from '../../../../junkDrawer/useElementAndDataArray.js';
import {
  isTextSelection,
  TextSelection,
} from '../../../../editor/selection/TextSelection.js';
import { isImageBlock } from 'editor-content/Block.js';
import {
  getColumnMatchedContentSelectionFromBottom,
  getColumnMatchedContentSelectionFromTop,
} from '../../../../editor/selection/contentSelection/fancyNav/getColumnMatchedContentSelection.js';
import { BlockSelection } from '../../../../editor/selection/BlockSelection.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';

const getSelectionRange = (): Range => {
  const selection = window.getSelection();
  if (selection && selection.rangeCount > 0) {
    return selection.getRangeAt(0);
  }

  throw new Error('No selection');
};

export const fancyNavUp = (prevBlockEl: HTMLElement): ContentSelection => {
  const currentRange = getSelectionRange();

  return getColumnMatchedContentSelectionFromBottom(currentRange, prevBlockEl);
};

export const fancyNavDown = (nextBlockEl: HTMLElement): ContentSelection => {
  const currentRange = getSelectionRange();

  return getColumnMatchedContentSelectionFromTop(currentRange, nextBlockEl);
};

function useFancyNavBody(
  blocksWithEl: ElementAndData<HydratedBlock>[],
  selection: BlockSelection | TextSelection | null,
  onNavUpOut: () => void,
  onSelect: (selection: BlockSelection | TextSelection) => void,
  moveToEndOfPreviousBlock: () => void,
  moveToStartOfNextBlock: () => void,
) {
  const onNavUp = () => {
    if (!selection) return;
    if (!isTextSelection(selection)) return;

    const i = selection.index;

    if (i === 0) {
      onNavUpOut();
      return;
    }

    const prevBlockWithEl = blocksWithEl[selection.index - 1];

    if (!prevBlockWithEl) return;
    const { getEl, data: prevBlock } = prevBlockWithEl;

    if (isImageBlock(prevBlock)) {
      moveToEndOfPreviousBlock();
      return;
    }

    try {
      const prevBlockEl = getEl();

      if (prevBlockEl) {
        return onSelect({
          index: i - 1,
          offset: fancyNavUp(prevBlockEl),
        });
      }
    } catch {}

    moveToEndOfPreviousBlock();
  };

  const onNavDown = () => {
    if (!selection) return;
    if (!isTextSelection(selection)) return;

    const i = selection.index;

    if (i >= blocksWithEl.length - 1) {
      return;
    }

    const nextBlockWithEl = blocksWithEl[selection.index + 1];

    if (!nextBlockWithEl) return;
    const { getEl, data: nextBlock } = nextBlockWithEl;

    if (isImageBlock(nextBlock)) {
      moveToStartOfNextBlock();
      return;
    }

    try {
      const nextBlockEl = getEl();

      if (nextBlockEl) {
        return onSelect({
          index: i + 1,
          offset: fancyNavDown(nextBlockEl),
        });
      }
    } catch {}

    moveToStartOfNextBlock();
  };

  return {
    onNavUp,
    onNavDown,
  };
}

export default useFancyNavBody;
