import type { Call, Pipe, Objects, Strings } from 'hotscript';
import lodashCapitalize from 'lodash/upperFirst.js';
import * as icons from './icons/index.js';
import React, { SVGProps } from 'react';
import lowerFirst from 'lodash/lowerFirst.js';

export * from './icons/index.js';

export type IconName = Pipe<
  typeof icons,
  [Objects.Keys, Strings.Replace<'Icon', ''>, Strings.Uncapitalize]
>;

type Props = {
  name: IconName;
} & SVGProps<SVGSVGElement>;

export function Icon({ name, ...props }: Props) {
  const IconComponent = icons[`${capitalize(name)}Icon`];
  return <IconComponent {...props} />;
}

function capitalize<Str extends string>(str: Str) {
  return lodashCapitalize(str) as Call<Strings.Capitalize, Str>;
}

export const ICON_NAMES = Object.keys(icons).map((iconName) =>
  lowerFirst(iconName.replace('Icon', '')),
);
