import { useEffect, useRef as useRefDefault } from 'react';

export const useListScrollPosition = (
  storageKey: string,
  useRef = useRefDefault,
): {
  listRef: React.RefObject<HTMLDivElement>;
  activeListItemRef: React.RefObject<HTMLAnchorElement>;
  listScrollCallback: React.UIEventHandler<HTMLDivElement>;
} => {
  const listRef = useRef<HTMLDivElement>(null);
  const activeListItemRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (activeListItemRef.current && listRef.current) {
      const itemTop = activeListItemRef.current.offsetTop;
      const itemBottom = itemTop + activeListItemRef.current.offsetHeight;

      const parentTop = listRef.current.offsetTop;
      const parentBottom = parentTop + listRef.current.offsetHeight;

      const sessionValue = parseInt(
        window.sessionStorage.getItem(storageKey) ?? '0',
        10,
      );

      // see if saved scroll will show active item, prevents list from jumping on re-renders
      if (
        sessionValue &&
        sessionValue + parentTop <= itemTop &&
        sessionValue + parentBottom >= itemBottom
      ) {
        return listRef.current.scroll({ top: sessionValue }); // saved scroll position will show active item
      }

      if (itemBottom > parentBottom) {
        activeListItemRef.current.scrollIntoView(); // active item off screen
      }
    }
  }, [storageKey]);

  const listScrollCallback: React.UIEventHandler<HTMLDivElement> = (e) => {
    window.sessionStorage.setItem(
      storageKey,
      e.currentTarget.scrollTop.toString(),
    );
  };

  return {
    listRef,
    activeListItemRef,
    listScrollCallback,
  };
};
