import {
  createNumberedListItemBlock,
  getTextFromBlock,
  NumberedListItemBlock,
  ParagraphBlock,
} from 'editor-content/Block.ts';
import { HydratedBlock } from '../../../types/HydratedBlock.ts';
import { ContentPatch } from '../../ContentPatch.ts';
import ContentSelection, {
  contentSelection,
} from '../../selection/contentSelection/ContentSelection.ts';
import { textSelection } from '../../selection/TextSelection.ts';

export const paragraphReplaceWithNumberedStrategy = (
  block: ParagraphBlock,
  selection: ContentSelection,
): ContentPatch<[NumberedListItemBlock]> | void => {
  if (selection.anchorOffset !== 2) {
    return;
  }
  const text = getTextFromBlock(block);

  if (text !== '1.') {
    return;
  }

  return {
    contentSubset: [createNumberedListItemBlock([], 0)],
    selection: textSelection(0, contentSelection(0)),
  };
};

type BlockKeyboardBehavior<B extends HydratedBlock> = {
  replaceWithNumbered: (
    block: B,
    selection: ContentSelection,
  ) => ContentPatch<HydratedBlock[]> | void;
};

const ParagraphBlockKeyboardBehavior: BlockKeyboardBehavior<ParagraphBlock> = {
  replaceWithNumbered: (block, selection) => {
    return paragraphReplaceWithNumberedStrategy(block, selection);
  },
};

export default ParagraphBlockKeyboardBehavior;
