export default function refToRefCallback<T>(
  ref: React.MutableRefObject<T> | React.LegacyRef<T>,
): React.RefCallback<T> {
  return (value) => {
    if (typeof ref === 'function') {
      ref(value);
    } else if (ref && typeof ref === 'object') {
      (ref as React.MutableRefObject<T | null>).current = value;
    }
  };
}
