import React from 'react';
import { BulletedListItem } from '../../../../design-system/zeck/BulletedList.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import {
  BulletedListItemBlock,
  updateTextBlock,
} from 'editor-content/Block.js';
import useZeckContentEditable from './useZeckContentEditable.ts';
import EditableProps from './EditableProps.ts';

const BulletedListItemEditable = React.forwardRef<
  HTMLElement,
  {
    indent: number;
    className?: string;
  } & EditableProps<BulletedListItemBlock>
>(function UnorderedListItemEditable(
  { value, selection, onChange, onSelect, linkables, ...otherProps },
  forwardedRef,
) {
  const contentEditableProps = useZeckContentEditable(
    {
      content: value.content,
      selection,
    },
    ({ content, selection }) => {
      onChange(updateTextBlock(value, content), selection);
    },
    onSelect,
    { linkables },
  );
  return (
    <BulletedListItem
      {...{
        ...otherProps,
        ...contentEditableProps,
        ref: mergeRefs([contentEditableProps.ref, forwardedRef]),
      }}
    />
  );
});

export default BulletedListItemEditable;
