import { makeMakeApiRequest } from './makeMakeApiRequest.js';
import { Company } from '../types/Company.js';
import { CompanyUser } from '../types/User.js';
import { CompanyRole } from '../types/CompanyLite.js';

const createPermissionsApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  updateCompanyPermissionRole: async (
    company: Pick<Company, 'id'>,
    member: Pick<CompanyUser, 'id'>,
    role: CompanyRole,
  ): Promise<void> => {
    await makeLambdaApiRequest(
      `/company/${company.id}/user/${member.id}/company-permission`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ role }),
      },
    );
  },

  updateCompanyPermissionBoardDirector: async (
    company: Pick<Company, 'id'>,
    member: Pick<CompanyUser, 'id'>,
    boardDirector: boolean,
  ): Promise<void> => {
    await makeLambdaApiRequest(
      `/company/${company.id}/user/${member.id}/company-permission`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ boardDirector }),
      },
    );
  },

  deleteCompanyPermission: async (company: Company, member: CompanyUser) => {
    await makeLambdaApiRequest(
      `/company/${company.id}/user/${member.id}/company-permission`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  },
});

export default createPermissionsApi;
