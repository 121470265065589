import styles from './EmptyZeckIllustration.module.scss';

const EmptyZeckIllustration = () => {
  return (
    <div className={styles.illustration}>
      <div className={styles.box} />
      <div className={styles.box} />
      <div className={styles.box} />
    </div>
  );
};

export default EmptyZeckIllustration;
