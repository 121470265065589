import { useState } from 'react';
import Button from '../../../design-system/atoms/Button.tsx';
import OutlineButton from '../../../design-system/atoms/OutlineButton.tsx';
import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../../design-system/organisms/Modal.tsx';
import styles from './EditVoteModal.module.scss';
import EditVoteResultInput from './EditVoteResultInput.tsx';

type EditVoteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  voteTitle: string;
  voteDetails: string;
  voteApproved: boolean;
  onSave: (block: {
    title: string;
    details: string;
    approved: boolean;
  }) => void;
};

export default function EditVoteModal({
  isOpen,
  onClose,
  voteTitle,
  voteDetails,
  voteApproved,
  onSave,
}: EditVoteModalProps) {
  const [title, setTitle] = useState(voteTitle);
  const [details, setDetails] = useState(voteDetails);
  const [approved, setApproved] = useState(voteApproved);

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader onClickClose={onClose}>Edit Vote</ModalHeader>
      <ModalBody className={styles.modalBody}>
        <textarea
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          aria-label="vote title"
          className={styles.voteTitle}
        />
        <textarea
          value={details}
          onChange={(event) => setDetails(event.target.value)}
          aria-label="vote details"
          className={styles.voteDetails}
        />
        <EditVoteResultInput value={approved} onChange={setApproved} />
      </ModalBody>
      <ModalActions className={styles.modalActions}>
        <OutlineButton size="medium" onClick={onClose} color="secondary">
          Cancel
        </OutlineButton>
        <Button
          size="medium"
          color="danger"
          onClick={() => {
            onSave({ title, details, approved });
          }}
        >
          Save
        </Button>
      </ModalActions>
    </Modal>
  );
}
