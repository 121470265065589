import fetchItemFromOneDrive from './fetchItemFromOneDrive.ts';

export type FileBufferWithModified = {
  buffer: ArrayBuffer;
  lastModified?: Date;
};

export const getArrayBufferOfOneDriveItem = async (
  accessToken: string,
  itemId: string,
  driveId?: string,
): Promise<FileBufferWithModified> => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  const { fileContents, lastModified } = await fetchItemFromOneDrive(
    itemId,
    options,
    driveId,
  );

  const buffer = await fileContents.arrayBuffer();

  return {
    buffer,
    lastModified,
  };
};
