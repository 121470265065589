import debounce from 'lodash/debounce.js';
import { useCallback, useMemo, useState } from 'react';

function useHistoryStack<T>() {
  const [changeHistory, setChangeHistory] = useState<T[]>([]);

  const pushState = useCallback((newState: T) => {
    setChangeHistory((prevState) => [newState, ...prevState].slice(0, 50));
  }, []);

  const popState = useCallback((): T | undefined => {
    const [lastState, ...newHistory] = changeHistory;
    setChangeHistory(newHistory);

    return lastState;
  }, [changeHistory]);

  const clearState = useCallback(() => {
    setChangeHistory([]);
  }, []);

  return {
    pushState,
    popState,
    clearState,
  };
}

export default function useUndoRedo<T>(
  value: T,
  setValue: (newValue: T) => void,
): { undo(): void; redo(): void; setValue(newValue: T): void; value: T } {
  const { pushState, popState } = useHistoryStack<T>();
  const {
    pushState: pushRedoState,
    popState: popRedoState,
    clearState: clearRedoState,
  } = useHistoryStack<T>();

  const debouncedPushState = useMemo(
    () =>
      debounce(pushState, 400, {
        leading: true,
        trailing: false,
      }),
    [pushState],
  );

  const undo = useCallback(() => {
    const prevValue = popState();

    if (!prevValue) return;
    // reset timers so future calls are tracked
    debouncedPushState.cancel();

    pushRedoState(value);
    setValue(prevValue);
  }, [popState, debouncedPushState, pushRedoState, setValue, value]);

  const redo = useCallback(() => {
    const prevValue = popRedoState();

    if (!prevValue) return;
    debouncedPushState.cancel();

    pushState(value);
    setValue(prevValue);
  }, [popRedoState, debouncedPushState, pushState, setValue, value]);

  const newSetValue = useCallback(
    (newValue: T) => {
      debouncedPushState(value);
      setValue(newValue);
      clearRedoState();
    },
    [debouncedPushState, setValue, value, clearRedoState],
  );

  return {
    undo,
    redo,
    setValue: newSetValue,
    value,
  };
}
