import styles from './TopBarLayout.module.scss';
import cx from 'classnames';

type TopBarLayoutProps = {
  children: React.ReactNode;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  className?: string;
};

const TopBarLayout = ({
  children,
  leftSlot,
  rightSlot,
  className,
}: TopBarLayoutProps) => {
  return (
    <div data-testid="topbar" className={cx(styles.topBarLayout, className)}>
      {leftSlot && <div className={styles.topBarLayout__left}>{leftSlot}</div>}
      <div className={styles.topBarLayout__center}>{children}</div>
      {rightSlot && (
        <div className={styles.topBarLayout__right}>{rightSlot}</div>
      )}
    </div>
  );
};

export default TopBarLayout;
