import ReactDOM from 'react-dom';

const HOVER_LAYER_ID = 'hover-layer-portal';

export const isWithinHoverLayer = (el: Element) =>
  el.closest(`#${HOVER_LAYER_ID}`);

export const HoverPortal: React.FunctionComponent<{
  children: Parameters<typeof ReactDOM.createPortal>[0];
}> = ({ children }) => {
  const hoverLayer = document.getElementById(HOVER_LAYER_ID);
  if (hoverLayer) {
    return ReactDOM.createPortal(children, hoverLayer);
  }
  return <>{children}</>;
};

const HoverLayer: React.FunctionComponent = () => <div id={HOVER_LAYER_ID} />;
export default HoverLayer;
