import { allTextNodes, Editor, TextNode } from 'editor-content/TextNode.js';

export { default as textNodesToHtmlString } from 'editor-content/textNodesToHtmlString.js';
export { default as htmlStringToTextNodes } from './htmlStringToTextNodes.js';

export const TEXT_NODE_SECTIONID_ATTRIBUTE_NAME = 'data-sectionid';
export const TEXT_NODE_TYPE_ATTRIBUTE_NAME = 'data-nodetype';

export const getTextNodeAtOffset = (
  textNodes: TextNode[],
  offset: number,
): Editor.Text | undefined => {
  let travelledDistance = 0;
  const texts = Array.from(allTextNodes(textNodes));
  for (const text of texts) {
    travelledDistance = travelledDistance + text.text.length;
    if (travelledDistance > offset) {
      return text;
    }
  }

  if (travelledDistance === offset) {
    return texts[texts.length - 1];
  }
};
