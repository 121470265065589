import { useState } from 'react';
import styles from './SignupPage.module.scss';
import SignupForm, { UserSignupFormData } from './SignupForm.tsx';
import SessionLayout from '../../../design-system/layouts/SessionLayout.tsx';
import { useNavigate, useLocation } from 'react-router-dom';
import useOnComponentMount from '../../../services/useOnComponentMount.ts';
import useBreakpoints from '../../../services/useBreakpoints.ts';
import cx from 'classnames';
import { Invitation } from '../../../api/createApi.ts';
import useApi from '../../../api/useApi.ts';
import { SignUpData } from '../../../api/ApiSession.tsx';
import { hasStatus } from '../../../api/makeMakeApiRequest.ts';

type SignupPageParameters = {
  onSignUp: (
    userData: SignUpData,
  ) => Promise<'SUCCESS' | 'INVALID_CREDENTIALS' | 'EMAIL_TAKEN'>;
};

// 9 characters and a mix of letters and numbers
const SignupPage = ({ onSignUp }: SignupPageParameters) => {
  const { validateInvitationCode } = useApi();
  const { isMobile } = useBreakpoints();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(useLocation().search);
  const invitationCode = queryParams.get('invitationCode');

  const [invitation, setInvitation] = useState<Invitation | null>(null);

  useOnComponentMount(() => {
    (async () => {
      try {
        if (invitationCode) {
          setInvitation(await validateInvitationCode(invitationCode));
        } else {
          navigate('/404');
        }
      } catch (error) {
        if (
          hasStatus(error) &&
          (error.status === 403 || error.status === 404)
        ) {
          navigate('/expired-invite');
        } else {
          throw error;
        }
      }
    })();
  });

  if (!invitationCode || !invitation) {
    return null;
  }

  const handleSubmit = async (userData: UserSignupFormData) => {
    const result = await onSignUp({
      ...userData,
      invitationCode,
    });
    if (result === 'SUCCESS') {
      navigate('/');
    }
    return result;
  };

  return (
    <SessionLayout>
      <h1 className={cx(isMobile ? styles.title_mobile : styles.title)}>
        Complete Your Sign Up
      </h1>
      {invitation.signupType === 'EXISTING_COMPANY' && (
        <p className={styles.subtitle}>
          Join{' '}
          <span className={styles.companyName}>{invitation.companyName}</span>{' '}
          on Zeck
        </p>
      )}
      <div className={cx(isMobile ? styles.form_mobile : styles.form)}>
        <SignupForm invitation={invitation} onSubmit={handleSubmit} />
      </div>
    </SessionLayout>
  );
};

export default SignupPage;
