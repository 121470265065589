import { uniqBy } from 'lodash';
import { AnalyticsSummary } from '../../../../../api/endpoints/createAnalyticsApi.ts';
import minutesToTimeString from '../../../../../junkDrawer/minutesToTimeString.ts';
import {
  AnalyticsBreakdown,
  AnalyticsBreakdownSection,
  AnalyticsBreakdownTitle,
  AnalyticsBreakdownValue,
} from './AnalyticsBreakdown.tsx';

type ContentEngagementSummaryProps = {
  analyticsSummary: AnalyticsSummary;
  totalCommentCount: number;
};

const ContentEngagementSummary: React.FC<ContentEngagementSummaryProps> = ({
  analyticsSummary,
  totalCommentCount,
}) => {
  const userEngagementSummary = analyticsSummary.engagementSummary;

  const totalSectionCount = uniqBy(
    analyticsSummary.engagementTotals,
    'sectionId',
  ).length;

  const averageTimeSpent = totalSectionCount
    ? userEngagementSummary.totalMinutesSpent / totalSectionCount
    : 0;

  return (
    <AnalyticsBreakdown>
      <AnalyticsBreakdownSection>
        <AnalyticsBreakdownTitle>
          Total Time Spent Reviewing
        </AnalyticsBreakdownTitle>

        <AnalyticsBreakdownValue data-testid="total-time-spent">
          {minutesToTimeString(userEngagementSummary.totalMinutesSpent)}
        </AnalyticsBreakdownValue>
      </AnalyticsBreakdownSection>

      <AnalyticsBreakdownSection>
        <AnalyticsBreakdownTitle>
          Avg Time Spent Per Section
        </AnalyticsBreakdownTitle>

        <AnalyticsBreakdownValue data-testid="average-time-spent">
          {minutesToTimeString(averageTimeSpent)}
        </AnalyticsBreakdownValue>
      </AnalyticsBreakdownSection>

      <AnalyticsBreakdownSection>
        <AnalyticsBreakdownTitle>Total Comments</AnalyticsBreakdownTitle>

        <AnalyticsBreakdownValue data-testid="total-comment-count">
          {totalCommentCount}
        </AnalyticsBreakdownValue>
      </AnalyticsBreakdownSection>
    </AnalyticsBreakdown>
  );
};

export default ContentEngagementSummary;
