import useBreakpoints from '../../services/useBreakpoints.ts';
import DrawerMenu from './DrawerMenu.tsx';
import styles from './ConfirmationModal.module.scss';
import cx from 'classnames';
import Modal, { ModalBody, ModalHeader } from './Modal.tsx';
import OutlineButton from '../atoms/OutlineButton.tsx';
import Button from '../atoms/Button.tsx';

type ConfirmationModalProps = {
  isOpen: boolean;
  title: string;
  onClose(): void;
  onClickConfirm(): void;
  body: React.ReactNode;
  confirmText: string;
  confirmTextForMobile?: string;
};

const ConfirmModal = ({
  isOpen,
  onClose,
  confirmText,
  confirmTextForMobile,
  onClickConfirm,
  body,
  title,
}: ConfirmationModalProps) => {
  const { isMobile } = useBreakpoints();
  if (isMobile) {
    return (
      <DrawerMenu
        {...{
          isOpen,
          onRequestClose: onClose,
          testId: 'mobile-confirmation',
        }}
      >
        <div className={styles.drawer__header}>{body}</div>
        <button
          className={cx(styles.drawer__action, styles.drawer__action_danger)}
          onClick={onClickConfirm}
        >
          {confirmTextForMobile ?? confirmText}
        </button>
        <button className={styles.drawer__action} onClick={onClose}>
          Cancel
        </button>
      </DrawerMenu>
    );
  }

  return (
    <Modal
      {...{
        isOpen,
        onRequestClose: onClose,
        className: styles.modal,
      }}
    >
      <ModalHeader onClickClose={onClose}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <div className={styles.modal__actions}>
        <OutlineButton size="medium" onClick={onClose} color="secondary">
          Cancel
        </OutlineButton>
        <Button size="medium" color="danger" onClick={onClickConfirm}>
          {confirmText}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
