import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function ColumnWidthIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M17.1411 7.55454H6.85948C5.91699 7.55454 6.00271 7.99892 6.00271 8.4432V15.5524C6.00068 15.9968 5.91699 16.4411 6.85948 16.4411H17.1411C18.0836 16.4411 17.998 15.9968 17.998 15.5524V8.4432C17.9959 7.99888 17.998 7.55454 17.1411 7.55454Z"
        fill="white"
      />
      <path
        d="M17.1411 17.774H6.85948C5.91699 17.774 6.00271 18.6584 6.00271 19.107C6.00271 19.5556 5.91699 20 6.85948 20H17.1411C18.0836 20 17.9959 19.5513 17.9959 19.107C17.9959 18.6627 18.0836 17.774 17.1411 17.774Z"
        fill="white"
      />
      <path
        d="M17.1411 4H6.85948C5.91699 4 6.00271 4.44432 6.00271 4.88866C6.00271 5.333 5.91699 6.22162 6.85948 6.22162H17.1411C17.9959 6.22153 17.9959 5.77728 17.9959 4.88865C17.9959 4.00001 17.5675 4 17.1411 4Z"
        fill="white"
      />
    </svg>
  );
}
