import Modal, {
  ModalHeader,
  ModalBody,
  ModalActions,
} from '../../design-system/organisms/Modal.tsx';
import Button from '../../design-system/atoms/Button.tsx';
import OutlineButton from '../../design-system/atoms/OutlineButton.tsx';
import styles from './ConfirmDeleteUser.module.scss';
import { CompanyUser } from '../../types.ts';
import { useCallback, useEffect, useState } from 'react';

type ConfirmDeleteUserProps = {
  isOpen: boolean;
  user: CompanyUser;
  onDelete(): void;
  onCancel(): void;
};

export default function ConfirmDeleteUser({
  isOpen,
  user,
  onDelete,
  onCancel,
}: ConfirmDeleteUserProps) {
  const [buttonEnabled, setButtonEnabled] = useState(true);

  useEffect(() => {
    setButtonEnabled(true);
  }, [isOpen]);

  const onClickDelete = useCallback(() => {
    setButtonEnabled(false);
    onDelete();
  }, [onDelete]);

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader onClickClose={onCancel}>Delete User?</ModalHeader>
      <ModalBody>
        Permanently remove <em>{user.email}</em> from your company? They will no
        longer have access to any Zecks.
      </ModalBody>
      <ModalActions>
        <OutlineButton onClick={onCancel} color="secondary" size="small">
          Cancel
        </OutlineButton>
        <Button
          onClick={onClickDelete}
          color="danger"
          size="small"
          disabled={!buttonEnabled}
        >
          Yes, Delete
        </Button>
      </ModalActions>
    </Modal>
  );
}
