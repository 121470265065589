import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function FolderSolidIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="34"
      height="28"
      viewBox="0 0 34 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M13.6668 0.666504H3.66683C1.8335 0.666504 0.350163 2.1665 0.350163 3.99984L0.333496 23.9998C0.333496 25.8332 1.8335 27.3332 3.66683 27.3332H30.3335C32.1668 27.3332 33.6668 25.8332 33.6668 23.9998V7.33317C33.6668 5.49984 32.1668 3.99984 30.3335 3.99984H17.0002L13.6668 0.666504Z"
        fill="#C7C9D9"
      />
    </svg>
  );
}
