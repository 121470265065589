import { compact, isError, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import {
  ErrorWithContext,
  logError,
  startTransaction,
} from '../../../../../../../logging/logger.ts';

type MicrosoftFile = {
  name: string;
  remoteItem: {
    id: string;
    parentReference?: {
      driveId?: string;
    };
  };
};

export const useLoadMicrosoftFiles = (accessToken: string) => {
  const [files, setFiles] = useState<MicrosoftFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    setLoading(true);
    setError(null);

    const fetchFiles = async () => {
      try {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append('Authorization', bearer);

        const options = {
          method: 'GET',
          headers: headers,
        };

        const transaction = startTransaction(
          'fetch_microsoft_graph_recent_files',
        );

        const time = Date.now();

        const urls = [
          'https://graph.microsoft.com/v1.0/me/drive/recent?$filter=endswith(name,%20%27xlsx%27)', // recent files for business and personal, includes shared only for business
          'https://graph.microsoft.com/v1.0/me/drive/sharedWithMe', // shared for business and personal, needed for personal shared files
        ];

        const data = await Promise.all(
          urls.map((url) => {
            return fetch(url, options)
              .then((response) => {
                if (response.status !== 200) {
                  throw new ErrorWithContext(
                    'Unable to communicate with Microsoft OneDrive. Please try again later.',
                    {
                      status: response.status,
                      statusText: response.statusText,
                    },
                    'OneDriveDownloadError',
                  );
                }

                return response;
              })
              .then((response) => response.json())
              .finally(() => {
                const duration = Date.now() - time;

                if (duration > 3000) {
                  logError(
                    new Error(
                      `fetch_microsoft_graph_files ${url} took: ${duration}ms. This is longer than the threshold of 3000ms`,
                    ),
                  );
                }

                transaction?.finish();
              });
          }),
        );

        const fileArray: Array<MicrosoftFile | null> = uniqBy(
          [...(data[0]?.value ?? []), ...(data[1]?.value ?? [])]
            .sort((a, b) => {
              if (a.lastModifiedDateTime && b.lastModifiedDateTime) {
                if (a.lastModifiedDateTime > b.lastModifiedDateTime) {
                  return -1;
                }
                if (a.lastModifiedDateTime < b.lastModifiedDateTime) {
                  return 1;
                }
              }

              return 0;
            })
            .map((item: Partial<MicrosoftFile>) => {
              if (
                item.name &&
                item.remoteItem?.id &&
                item.name.match(/\.xlsx$/)
              ) {
                return {
                  name: item.name,
                  remoteItem: {
                    id: item.remoteItem.id,
                    parentReference: {
                      driveId: item.remoteItem?.parentReference?.driveId,
                    },
                  },
                };
              }

              return null;
            }),
          (item) => item?.remoteItem.id,
        );
        setFiles(compact(fileArray));
      } catch (e) {
        const error = isError(e) ? e : new Error('Unknown error');
        logError(error);
        setError(error.message ?? 'Error loading Microsoft files');
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, [accessToken]);

  return { files, loading, error };
};
