// AiChartFlow -creates a chart from a table from user prompt

import { ChartBlockData } from 'editor-content/ChartBlock.ts';
import { isError } from 'lodash';
import { useEffect } from 'react';
import { logError } from '../../../../logging/logger.ts';
import { Company } from '../../../../types.ts';
import styles from './AiChartFlow.module.scss';
import {
  AiChartFlowError,
  AiChartFlowErrorState,
} from './AiChartFlowError.tsx';
import {
  AiChartFlowLoading,
  AiChartFlowLoadingState,
} from './AiChartFlowLoading.tsx';
import {
  AiChartFlowPrompting,
  AiChartFlowPromptingState,
} from './AiChartFlowPrompting.tsx';
import {
  AiChartFlowReviewing,
  AiChartFlowReviewingState,
} from './AiChartFlowReviewing.tsx';
import {
  AiChartGenerateResponse,
  isAiChartGenerateResponseError,
} from '../../../../api/endpoints/createAiApi.ts';
// call the ai service, get the chart and add to the editor
export type AiChartFlowProps = {
  generateAiChart: (prompt: string) => Promise<AiChartGenerateResponse>;
  onClose: () => void;
  company: Pick<Company, 'brandColorPrimary'>;
  setForceTableSelection: (force: boolean) => void;
  recordAnalytics: (type: 'accept' | 'discard') => Promise<void>;
  onAccept: (
    chartConfig: ChartBlockData,
    base64ImageData: string,
  ) => Promise<void>;
  chartState: AiChartFlowState;
  setChartState: (state: AiChartFlowState) => void;
};

export type AiChartFlowState =
  | AiChartFlowPromptingState
  | AiChartFlowLoadingState
  | AiChartFlowReviewingState
  | AiChartFlowErrorState;

export const AiChartFlow: React.FC<AiChartFlowProps> = ({
  generateAiChart,
  onClose,
  company,
  setForceTableSelection,
  recordAnalytics,
  onAccept,
  chartState,
  setChartState,
}) => {
  useEffect(() => {
    setForceTableSelection(true);

    return onClose;
  }, [setForceTableSelection, onClose]);

  return (
    <div
      className={styles.chartCreationContainer}
      data-testid="ai-chart-wizard"
    >
      {chartState.type === 'prompting' && (
        <AiChartFlowPrompting
          onSubmitChartPrompt={async (prompt) => {
            setChartState({
              type: 'loading',
            });

            try {
              const chartResponse = await generateAiChart(prompt);

              const newState = isAiChartGenerateResponseError(chartResponse)
                ? {
                    type: 'error' as const,
                    errorType: chartResponse.errorType,
                  }
                : {
                    type: 'reviewing' as const,
                    chart: chartResponse,
                  };

              setChartState(newState);

              setForceTableSelection(false);
            } catch (e) {
              if (isError(e)) {
                logError(e);
              }

              setChartState({
                type: 'error',
                errorType: 'unknown',
              });
            }
          }}
        />
      )}

      {chartState.type === 'loading' && <AiChartFlowLoading />}

      {chartState.type === 'error' && (
        <AiChartFlowError onClose={onClose} errorType={chartState.errorType} />
      )}

      {chartState.type === 'reviewing' && (
        <AiChartFlowReviewing
          chart={chartState.chart}
          company={company}
          onDiscard={async () => {
            onClose();
            await recordAnalytics('discard');
          }}
          onAccept={async (base64ImageData) => {
            await onAccept(chartState.chart, base64ImageData);
            onClose();
            await recordAnalytics('accept');
          }}
        />
      )}
    </div>
  );
};
