import { TableBorderType } from 'editor-content/TableBlock.ts';
import { FormattedTableCell } from '../zeck/table/formattedTableData.ts';
import styles from './TableCell.module.scss';
import compact from 'lodash/compact.js';
import cx from 'classnames';

type TableCellProps = {
  cell: FormattedTableCell;
};

const getCellWrapClassName = (cell: FormattedTableCell): string | undefined => {
  switch (cell?.format?.wrap) {
    case 'wrap':
      return styles.cell__wrap;
    case 'clip':
      return styles.cell__clip;
    default:
      return undefined;
  }
};

const getClassNameFromBorder = (
  border: TableBorderType,
  position: 'top' | 'right' | 'bottom' | 'left',
) => {
  if (!border) {
    return undefined;
  }
  return styles[`cell__border__${position}__${border}`];
};

const horizontalAlignClassNames = {
  left: styles.cell__alignHorizontal__left,
  center: styles.cell__alignHorizontal__center,
  right: styles.cell__alignHorizontal__right,
  none: undefined,
} as const;

const alignVerticalClassNames = {
  top: styles.cell__alignVertical__top,
  middle: styles.cell__alignVertical__middle,
  bottom: styles.cell__alignVertical__bottom,
  none: undefined,
} as const;

const getCellBorderClassNames = (cell: FormattedTableCell): Array<string> => {
  return compact([
    getClassNameFromBorder(cell.format?.border?.top, 'top'),
    getClassNameFromBorder(cell.format?.border?.bottom, 'bottom'),
    getClassNameFromBorder(cell.format?.border?.right, 'right'),
    getClassNameFromBorder(cell.format?.border?.left, 'left'),
  ]);
};

const TableCell: React.FC<TableCellProps> = ({ cell }) => {
  const cellStyle = {
    ...(cell.style ?? {}),
    ...(cell.format?.bgColor ? { backgroundColor: cell.format.bgColor } : {}),
  };

  return (
    <td
      style={cellStyle}
      className={cx(
        styles.cell,
        horizontalAlignClassNames[cell.format?.alignHorizontal ?? 'none'],
        alignVerticalClassNames[cell.format?.alignVertical ?? 'none'],
        getCellBorderClassNames(cell),
      )}
      colSpan={cell.colspan}
      rowSpan={cell.rowspan}
    >
      <div
        className={cx(getCellWrapClassName(cell))}
        dangerouslySetInnerHTML={{
          __html: cell.content,
        }}
      />
    </td>
  );
};

export default TableCell;
