import { TextNode } from 'editor-content/TextNode.js';

export type BlockData<BlockType> = {
  type: 'block';
  content: BlockType[];
};

export const BlockData = <BlockType>(
  content: BlockType[],
): BlockData<BlockType> => ({ type: 'block', content });

export type TextData = {
  type: 'text';
  content: TextNode[];
};

export const TextData = (content: TextNode[]): TextData => ({
  type: 'text',
  content,
});

type EditorData<BlockType> = BlockData<BlockType> | TextData;

export default EditorData;
