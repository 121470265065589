export type Store<StateType> = {
  set: (newState: StateType) => void;
  get: () => StateType;
};

export const createStore = <StateType>(
  initialState: StateType,
): Store<StateType> => {
  let state = initialState;

  return {
    set(newState) {
      state = newState;
    },

    get() {
      return state;
    },
  };
};
