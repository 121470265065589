import React, { useCallback } from 'react';
import useApi from '../../../../api/useApi.ts';
import { Icon } from 'icons';
import OutlineButton from '../../../../design-system/atoms/OutlineButton.tsx';
import PersonView from '../../../../design-system/molecules/PersonView.tsx';
import Tooltip from '../../../../design-system/molecules/Tooltip.tsx';
import { isSuccessResult } from '../../../../result/Result.ts';
import useFetch from '../../../../services/useFetch/useFetch.ts';

import {
  AccessRequest,
  UserAndPermission,
  isPendingUser,
} from '../../../../types.ts';
import ZeckWithActions from '../zeckCover/ZeckWithActions.ts';
import styles from './AccessRequestList.module.scss';
import { InfiniteScrollingList } from './ui/InfiniteScrollingModal.tsx';
import cx from 'classnames';

const VerifiedUserEmail: React.FC<{ email: string }> = ({ email }) => {
  return (
    <div className={styles.accessRequestList__verified_email_cell}>
      <div className={styles.accessRequestList__verified_email_icon}>
        <Icon name="mail" />
      </div>

      <div className={styles.accessRequestList__verified_email_text}>
        {email}

        <Tooltip className={styles.accessRequestList__verified_email_tooltip}>
          {email}
        </Tooltip>
      </div>
    </div>
  );
};

const AccessRequestListRow = ({
  users,
  accessRequest,
  onGrantAccessRequest,
}: {
  users: UserAndPermission[];
  accessRequest: AccessRequest;
  onGrantAccessRequest: (id: string) => Promise<void>;
}) => {
  const matchingUser = users.find((user) => user.email === accessRequest.email);

  if (matchingUser && !isPendingUser(matchingUser)) {
    return (
      <div className={styles.accessRequestList__row}>
        <div>
          <PersonView user={matchingUser} />
        </div>

        <div></div>
        <OutlineButton
          data-testid={`grant-access-button-${accessRequest.id}`}
          color="primary"
          size="medium"
          onClick={() => onGrantAccessRequest(accessRequest.id)}
        >
          Grant Access
        </OutlineButton>
      </div>
    );
  }

  return (
    <div className={styles.accessRequestList__row}>
      <VerifiedUserEmail email={accessRequest.email} />

      <div className={styles.accessRequestList__verified_email_cell}>
        <div>
          <Icon name="checkmark" />{' '}
        </div>
        <div>Email Verified</div>
      </div>

      <OutlineButton
        data-testid={`grant-access-button-${accessRequest.id}`}
        color="primary"
        size="medium"
        onClick={() => onGrantAccessRequest(accessRequest.id)}
      >
        Grant Access
      </OutlineButton>
    </div>
  );
};

const AccessRequestList: React.FC<{
  zeck: Pick<ZeckWithActions, 'id' | 'settings' | 'actions'>;
  showToast: (message: string, duration: number) => void;
  className?: string;
}> = ({ zeck, showToast, className }) => {
  const {
    fetchPendingAccessRequests,
    grantAccessRequest,
    getPossibleZeckViewers,
  } = useApi();
  const [grantedIds, getGrantedIds] = React.useState<string[]>([]);
  const [isUpdating, setIsUpdating] = React.useState(false);

  const onGrantAccessRequest = async (accessRequestId: string) => {
    setIsUpdating(true);
    const result = await grantAccessRequest(accessRequestId);
    getGrantedIds((prev) => [...prev, accessRequestId]);
    showToast(
      `${result.requesterEmail} has been granted access. An email notification has been sent to them.`,
      5000,
    );
    setIsUpdating(false);
  };

  const filterAccessRequests = (accessRequests: AccessRequest[]) => {
    return accessRequests.filter(
      (accessRequest) => !grantedIds.includes(accessRequest.id),
    );
  };

  const zeckUsersResult = useFetch(
    () => getPossibleZeckViewers({ zeckId: zeck.id, getAllResults: true }),
    [zeck.id],
  );

  const zeckUsers = isSuccessResult(zeckUsersResult)
    ? zeckUsersResult.data.models
    : [];

  const fetchPendingAccessRequestsForZeck = useCallback(
    (cursor: string | null | undefined) => {
      return fetchPendingAccessRequests(zeck.id, cursor ?? null);
    },
    [fetchPendingAccessRequests, zeck.id],
  );

  return (
    <div className={cx(styles.accessRequestList, className)}>
      <div className={styles.header}>Viewer Access Requests</div>

      <InfiniteScrollingList
        fetchFunc={fetchPendingAccessRequestsForZeck}
        filterModelsFunc={filterAccessRequests}
        emptyMessage="There are no access requests for this Zeck."
        isUpdating={isUpdating}
        renderRow={(accessRequest) => (
          <AccessRequestListRow
            users={zeckUsers}
            accessRequest={accessRequest}
            onGrantAccessRequest={onGrantAccessRequest}
          />
        )}
      />
    </div>
  );
};

export default AccessRequestList;
