import React from 'react';
import styles from './PopupForm.module.scss';

type PopupFormProps = {
  actions: React.ReactNode;
  children: React.ReactNode;
} & React.ComponentProps<'div'>;

const PopupForm = React.forwardRef<HTMLDivElement, PopupFormProps>(
  function PopupForm({ children, actions, ...props }, forwardedRef) {
    return (
      <div ref={forwardedRef} {...props} className={styles.popupForm}>
        {children}
        <div className={styles.popupForm_actions}>{actions}</div>
      </div>
    );
  },
);

export default PopupForm;
