import {
  PressBackspaceStrategyResult,
  ReceiveBackspaceStrategyResult,
} from '../../../pages/zeck/editor/BodyEditor/pressBackspace.js';
import { BlockContent, updateTextBlock } from 'editor-content/Block.js';
import ContentSelection, {
  contentSelection,
  isCollapsed,
} from '../../selection/contentSelection/ContentSelection.js';
import getTextBlockLength from './getTextBlockLength.js';
import BaseTextBlock from 'editor-content/BaseTextBlock.js';

export const textBlockPressBackspaceStrategy = <
  BlockType extends BaseTextBlock,
>(
  selectedBlock: BlockType,
  selection: ContentSelection,
): PressBackspaceStrategyResult<BlockType> => {
  if (isCollapsed(selection)) {
    if (selection.focusOffset === 0) {
      if (getTextBlockLength(selectedBlock) === 0) {
        return {
          type: 'remove',
        };
      }

      return {
        type: 'merge',
        content: selectedBlock.content,
      };
    }

    // we let the contenteditable handle this b/c we don't want to deal with special stuff
    // like emojis with skin tone modifiers, or other zero-width joiners

    return;
  }

  // delete text selection
  return;
};

export const textBlockReceiveBackspaceStrategy = <
  BlockType extends BaseTextBlock,
>(
  block: BlockType,
  content: BlockContent,
): ReceiveBackspaceStrategyResult<BlockType> => {
  return {
    type: 'accept-merge',
    content: updateTextBlock(block, [...block.content, ...content]),
    selection: contentSelection(getTextBlockLength(block)),
  };
};
