import { Block } from 'editor-content/Block.js';
import { writeEditorDataToClipboard } from '../pages/zeck/editor/copyPasteHandlers.js';
import EditorData from '../pages/zeck/editor/EditorData.js';
import { extractZeckClipboardData } from '../pages/zeck/editor/ZeckClipboardData.js';
import {
  PasteBlocksEvent,
  PasteTextEvent,
  ReplaceSelectedContentWithPlaintextEvent,
} from './EditorEvents.js';
import { getSafePlaintextFromClipboardData } from '../junkDrawer/getSafePlaintextFromClipboardData.ts';
import cleanupTextNodesForPaste from '../pages/zeck/editor/cleanupTextNodesForPaste.ts';
import { cleanupBlocksForPaste } from '../pages/zeck/editor/cleanupBlocksForPaste.js';

type EditorType = {
  cut: () => void | EditorData<Block>;
  copy: () => void | EditorData<Block>;
  dispatch: (
    event:
      | PasteBlocksEvent<Block>
      | PasteTextEvent
      | ReplaceSelectedContentWithPlaintextEvent,
  ) => boolean;
};

export default class CopyPaste {
  #editor: EditorType;
  #companyId: string;

  constructor(editor: EditorType, companyId: string) {
    this.#editor = editor;
    this.#companyId = companyId;
  }

  setClipboardDataOnEvent = (
    selectedContent: EditorData<Block> | void,
    e: ClipboardEvent,
  ) => {
    if (!selectedContent) return;
    if (!e.clipboardData) return;

    writeEditorDataToClipboard(
      selectedContent,
      this.#companyId,
      e.clipboardData,
    );

    e.preventDefault();
  };

  addHandlers(element: {
    addEventListener: (
      type: 'copy' | 'paste' | 'cut',
      callback: (e: ClipboardEvent) => void,
    ) => void;
    removeEventListener: (
      type: 'copy' | 'paste' | 'cut',
      callback: (e: ClipboardEvent) => void,
    ) => void;
  }) {
    const copyListener = (e: ClipboardEvent) => {
      this.setClipboardDataOnEvent(this.#editor.copy(), e);
    };
    element.addEventListener('copy', copyListener);

    const cutListener = (e: ClipboardEvent) => {
      this.setClipboardDataOnEvent(this.#editor.cut(), e);
    };
    element.addEventListener('cut', cutListener);

    const pasteListener = (e: ClipboardEvent) => {
      if (!e.clipboardData) return;
      const zeckClipboardData = extractZeckClipboardData(e.clipboardData);
      if (zeckClipboardData) {
        // pass through editor data later instead of switching
        switch (zeckClipboardData.data.type) {
          case 'block':
            this.#editor.dispatch({
              type: 'pasteBlocks',
              data: cleanupBlocksForPaste(zeckClipboardData.data.content, []),
            });
            break;
          case 'text':
            this.#editor.dispatch({
              type: 'pasteText',
              data: cleanupTextNodesForPaste(
                zeckClipboardData.data.content,
                [],
              ),
            });
        }
      } else {
        this.#editor.dispatch({
          type: 'replaceSelectedContentWithPlaintext',
          data: { content: getSafePlaintextFromClipboardData(e.clipboardData) },
        });
      }
      e.preventDefault();
    };
    element.addEventListener('paste', pasteListener);

    return () => {
      element.removeEventListener('copy', copyListener);
      element.removeEventListener('paste', pasteListener);
      element.removeEventListener('cut', cutListener);
    };
  }
}
