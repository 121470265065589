import { makeMakeApiRequest } from '../makeMakeApiRequest.js';

const createTheConApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  duplicateZeckToCompany: async (zeckId: string, companyId: string) => {
    await makeLambdaApiRequest('/duplicate-zeck-to-company', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ zeckId, companyId }),
    });
  },

  addGaryToCompany: async (id: string): Promise<void> => {
    await makeLambdaApiRequest(`/company/${id}/gary`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
});

export default createTheConApi;
