import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import titleize from 'titleize';
import { startOfDay } from 'date-fns';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

const oneDay = 24 * 60 * 60 * 1000;

function formatTimeAgo(timestamp: number, precise = false) {
  const startOfToday = precise ? Date.now() : startOfDay(Date.now()).valueOf();

  if (!precise) {
    if (timestamp >= startOfToday) {
      return 'Today';
    }

    if (timestamp >= startOfToday - oneDay) {
      return 'Yesterday';
    }
  }

  const formattedTime = timeAgo.format(timestamp);

  return titleize(
    Array.isArray(formattedTime) ? formattedTime[0] : formattedTime,
  );
}

export default formatTimeAgo;
