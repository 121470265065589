import { RefCallback, useCallback, useEffect, useRef } from 'react';

const useElementArrayRef = () => {
  const elementsArrayRef = useRef<HTMLElement[]>([]);
  const runningCountRef = useRef<number>(0);

  useEffect(() => {
    elementsArrayRef.current = elementsArrayRef.current.slice(
      0,
      runningCountRef.current,
    );
    runningCountRef.current = 0;
  });

  return {
    getElementAt: useCallback((i: number): HTMLElement | undefined => {
      return elementsArrayRef.current[i];
    }, []),

    setElementAt: useCallback((i: number): RefCallback<HTMLElement> => {
      return (el) => {
        if (el) {
          runningCountRef.current++;
          elementsArrayRef.current[i] = el;
        }
      };
    }, []),
  };
};

export default useElementArrayRef;
