import { DependencyList, useEffect } from 'react';

function useAnimationLoop(fn: () => void, deps: DependencyList): void {
  useEffect(() => {
    let handle: number;

    function anim() {
      fn();

      handle = requestAnimationFrame(anim);
    }

    handle = requestAnimationFrame(anim);

    return () => {
      cancelAnimationFrame(handle);
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useAnimationLoop;
