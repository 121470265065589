import { Navigate } from 'react-router-dom';

import styles from './ZeckDashboardPage.module.scss';
import CreateCompany from './createCompany/CreateCompany.tsx';
import DuplicateZeckToCompany from './duplicateZeckToCompany/DuplicateZeckToCompany.tsx';
import AddToSampleZeck from './addToSampleZeck/AddToSampleZeck.tsx';
import AddGaryToCompany from './addGaryToCompany/AddGaryToCompany.tsx';
import ZeckLogo from './DashboardPageLogo.tsx';
import DeleteCompanyData from './DeleteCompanyData.tsx';
import { CompanyLite } from '../../types/CompanyLite.ts';

type ZeckDashboardPageProps = {
  user: { id: string; email: string };
  setActiveCompany: (company: { id: string }) => void;
  userCompanies: CompanyLite[];
};

const ZeckDashboardPage: React.FC<ZeckDashboardPageProps> = ({
  user,
  setActiveCompany,
  userCompanies,
}) => {
  const isUserInTheCon = userCompanies.find((company) =>
    company.name.match(/^thecon/),
  );
  const isZeckEmail = user.email.match(/@zeck.app$/);
  if (!(isUserInTheCon && isZeckEmail)) return <Navigate to={'/404'} />;

  return (
    <div className={styles.pageWrap}>
      <div className={styles.header}>
        <ZeckLogo className={styles.logo} />
      </div>
      <div className={styles.container}>
        <h1 className={styles.title}>The Con</h1>
        <div className={styles.panelList}>
          <AddToSampleZeck />
          <CreateCompany setActiveCompany={setActiveCompany} hasSeparator />
          <DuplicateZeckToCompany />
          <AddGaryToCompany />
          <DeleteCompanyData user={user} />
        </div>
      </div>
    </div>
  );
};

export default ZeckDashboardPage;
