import { useCallback } from 'react';
import { VoteType } from 'editor-content/VoteBlock.js';
import Modal from '../../../../design-system/organisms/Modal.tsx';
import SelectionModal from '../../../../design-system/organisms/SelectionModal.tsx';

type ResolutionTemplateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onInsert: (voteType: VoteType) => void;
};

const templates = [
  {
    name: 'Approval of Previous Meeting Minutes',
    description: "Simple board approval of your last meeting's minutes",
    voteType: VoteType.MeetingMinutesApproval,
  },
  {
    name: 'Approval of Stock Option Grant',
    description: 'Board approval of option grants',
    voteType: VoteType.StockOptionApproval,
  },
  {
    name: 'Approval of 409A Valuation',
    description: 'Board approval of latest 409A valuation report',
    voteType: VoteType.ValuationApproval,
  },
  {
    name: 'Generic Approval',
    description: 'Simple board approval that can be customized',
    voteType: VoteType.Other,
  },
];

const ResolutionTemplateModal = ({
  isOpen,
  onClose,
  onInsert,
}: ResolutionTemplateModalProps) => {
  const onSelectTemplate = useCallback(
    (template: (typeof templates)[0]) => {
      if (!template) {
        return;
      }

      onInsert(template.voteType);
      onClose();
    },
    [onClose, onInsert],
  );

  return (
    <Modal isOpen={isOpen} dark={true}>
      <SelectionModal
        items={templates}
        title="Choose a Resolution Template"
        onSubmit={onSelectTemplate}
        onCancel={onClose}
      />
    </Modal>
  );
};

export default ResolutionTemplateModal;
