type Point = [x: number, y: number];

export const subtractVector = ([x0, y0]: Point, [x1, y1]: Point): Point => [
  x0 - x1,
  y0 - y1,
];

export const addVector = ([x0, y0]: Point, [x1, y1]: Point): Point => [
  x0 + x1,
  y0 + y1,
];

export default Point;
