import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function AlertIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8684 4.40911L20.9164 18.5041C21.1902 18.9838 21.0234 19.5946 20.5438 19.8684C20.3928 19.9546 20.2219 20 20.048 20H3.95197C3.39968 20 2.95197 19.5523 2.95197 19C2.95197 18.8261 2.99732 18.6552 3.08356 18.5041L11.1316 4.40911C11.4054 3.9295 12.0162 3.7627 12.4958 4.03655C12.6511 4.1252 12.7797 4.25385 12.8684 4.40911ZM11.507 15.422H12.495L13.002 9.01299L11 8.99999L11.507 15.422ZM11.091 18.178V16.384H12.911V18.178H11.091Z"
        fill="#FF8800"
      />
    </svg>
  );
}
