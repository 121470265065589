import cx from 'classnames';
import styles from './MeetingMinutesListEmpty.module.scss';
import useBreakpoints from '../../../services/useBreakpoints.ts';
import { ReactComponent as NoMeetingMinutesImage } from '../../../images/NoMinutesPlaceholder.svg';
import Button from '../../../design-system/atoms/Button.tsx';
import { Icon } from 'icons';

type MeetingMinutesListEmptyProps = {
  onCreateMeetingMinutes: () => void;
};
const MeetingMinutesListEmpty: React.FC<MeetingMinutesListEmptyProps> = ({
  onCreateMeetingMinutes,
}) => {
  const { isMobile } = useBreakpoints();

  return (
    <div
      data-testid="empty-meeting-minutes-list"
      className={cx(
        styles.meetingMinutes,
        isMobile && styles.meetingMinutes_mobile,
      )}
    >
      <div className={styles.meetingMinutes__emptyContainer}>
        <div>
          <NoMeetingMinutesImage />
        </div>
        <div className={styles.meetingMinutes__emptyTextContainer}>
          <p>
            <strong>Create Meeting Minutes</strong>
          </p>
          <p className={styles.meetingMinutes__emptyDescription}>
            You Don’t Have Any Meeting Minutes Yet. Click the Button Below to
            Generate Your First Set of Minutes.
          </p>
        </div>

        <Button
          {...{
            className: styles.meetingMintues_createButton,
            size: 'large',
            color: 'primary',
            onClick: onCreateMeetingMinutes,
          }}
        >
          <Icon name="plus" />
          <span data-testid="create-new-minutes-button">
            Create New Minutes
          </span>
        </Button>
      </div>
    </div>
  );
};

export default MeetingMinutesListEmpty;
