import { useEffect, useId, useRef } from 'react';
import { eachMinuteOfInterval, endOfDay, startOfDay } from 'date-fns';
import styles from './TimePicker.module.scss';
import cx from 'classnames';
import {
  formatMinutesFromMidnight,
  getMinutesFromMidnight,
} from './timestamp.ts';
import { Picker, PickerItem } from './Picker.tsx';
import ManualTimePicker from './ManualTimePicker.tsx';

type TimePickerProps = {
  time: number;
  onChange: (time: number) => void;
  className?: string;
};

const MINUTES_INTERVAL = 15;

function scrollTopCentered(container: HTMLElement): number {
  return container.scrollHeight / 2 - container.offsetHeight / 2;
}

function scrollTopForCurrentSelection(container: HTMLElement) {
  const radioButton =
    container.querySelector<HTMLInputElement>('input[checked]');
  if (!radioButton) return scrollTopCentered(container);

  const label = (radioButton.labels || [])[0];

  if (!label) return scrollTopCentered(container);

  return label.offsetTop - 0.5 * (container.offsetHeight - label.offsetHeight);
}

function useCenterCurrentSelection() {
  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!container.current) return;

    container.current.scrollTop = scrollTopForCurrentSelection(
      container.current,
    );
  }, []);
  return container;
}

export default function TimePicker({
  time,
  onChange,
  className,
}: TimePickerProps) {
  const radioGroupName = useId();

  const now = Date.now();
  const times = eachMinuteOfInterval(
    { start: startOfDay(now), end: endOfDay(now) },
    { step: MINUTES_INTERVAL },
  ).map((date) => {
    const value = getMinutesFromMidnight(date);
    return {
      date,
      label: formatMinutesFromMidnight(value),
      value,
    };
  });

  const container = useCenterCurrentSelection();

  return (
    <div>
      <ManualTimePicker time={time} onChange={onChange} />
      <Picker
        data-testid="time"
        ref={container}
        className={cx(styles.timePicker, className)}
      >
        {times.map(({ label, value }) => {
          const selected = time !== null && time === value;

          return (
            <PickerItem
              key={value}
              selected={selected}
              onSelect={() => {
                onChange(value);
              }}
              radioGroupName={radioGroupName}
            >
              {label}
            </PickerItem>
          );
        })}
      </Picker>
    </div>
  );
}
