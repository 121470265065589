import { useEffect, useState } from 'react';
import {
  IntegrationListItem,
  IntegrationListItemData,
} from '../../api/endpoints/createIntegrationApi.ts';
import useApi from '../../api/useApi.ts';

export const useFetchIntegrationsForSection = (
  sectionId: string,
  integrationIds: string[],
): IntegrationListItemData => {
  const { getSectionIntegrations } = useApi();
  const [integrations, setIntegrations] = useState<IntegrationListItem[]>([]);
  useEffect(() => {
    const cb = async () => {
      setIntegrations([]);
      if (integrationIds.length === 0) {
        return; // no integrations in section to fetch
      }

      const data = await getSectionIntegrations(sectionId);
      setIntegrations(data);
    };

    cb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId, setIntegrations, integrationIds.join('_')]);

  const addIntegration = (integration: IntegrationListItem) => {
    setIntegrations((prev) => [...prev, integration]);
  };

  const updateIntegration = (integration: IntegrationListItem) => {
    setIntegrations((prev) =>
      prev.map((i) => (i.id === integration.id ? integration : i)),
    );
  };

  return {
    integrations,
    addIntegration,
    updateIntegration,
  };
};
