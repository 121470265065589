import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function FolderMoveIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <g clipPath="url(#clip0_12706_271809)">
        <path
          d="M13.3335 3.99984H8.00016L6.66683 2.6665H2.66683C1.9335 2.6665 1.34016 3.2665 1.34016 3.99984L1.3335 11.9998C1.3335 12.7332 1.9335 13.3332 2.66683 13.3332H13.3335C14.0668 13.3332 14.6668 12.7332 14.6668 11.9998V5.33317C14.6668 4.59984 14.0668 3.99984 13.3335 3.99984ZM13.3335 11.9998H2.66683V5.33317H13.3335V11.9998ZM7.9935 5.99984L7.0535 6.93984L8.10683 7.99984H5.3335V9.33317H8.10683L7.04683 10.3932L7.9935 11.3332L10.6668 8.67317L7.9935 5.99984Z"
          fill="#1C1C28"
        />
      </g>
      <defs>
        <clipPath id="clip0_12706_271809">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
