import refToRefCallback from './refToRefCallback.js';

const mergeRefs = <T>(
  refs: (React.Ref<T> | undefined | null)[],
): React.RefCallback<T> => {
  return (value) => {
    refs
      .filter((a): a is React.Ref<T> => !!a)
      .forEach((ref) => refToRefCallback(ref)(value));
  };
};

export default mergeRefs;
