import Linkable, {
  isMeetingMinutesLinkable,
  isSectionLinkable,
} from 'editor-content/html/Linkable.ts';
import { useState } from 'react';
import BlackBox from '../atoms/BlackBox.tsx';
import styles from './AddLinkMenu.module.scss';
import { SectionList, SectionListItem } from './SectionList.tsx';
import Tabs from './Tabs.tsx';
import { MeetingMinutesLinkable } from 'editor-content/html/MeetingMinutesLinkable.ts';
import { SectionLinkable } from 'editor-content/html/SectionLinkable.ts';

type AddLinkMenuProps = {
  invalidLink: boolean;
  onAddExternalLink(v: string): void;
  onAddSectionLink(v: string): void;
  onAddMeetingMinutesLink(v: string): void;
  linkables: Linkable[];
};

const getAddableMeetingMinuteLinkables = (
  linkables: Linkable[],
): MeetingMinutesLinkable[] =>
  linkables.filter(isMeetingMinutesLinkable).filter((l) => l.title);

const getAddableSectionLinks = (linkables: Linkable[]): SectionLinkable[] =>
  linkables.filter(isSectionLinkable).filter((l) => l.title);

const AddLinkMenu = ({
  invalidLink,
  onAddSectionLink,
  onAddExternalLink,
  onAddMeetingMinutesLink,
  linkables,
}: AddLinkMenuProps) => {
  const [inputState, setInputState] = useState('');
  const meetingMinutesLinkables = getAddableMeetingMinuteLinkables(linkables);
  const sectionLinks = getAddableSectionLinks(linkables);

  const maybeMeetingMinutesLinkableTab =
    meetingMinutesLinkables.length > 0
      ? [
          {
            id: 'meeting-minutes',
            displayName: 'Meeting Minutes',
            render: () => (
              <div>
                <h1 className={styles.sectionList__heading}>
                  Link To Meeting Minutes
                </h1>
                <SectionList className={styles.sectionList}>
                  {meetingMinutesLinkables.map((meetingMinutesLinkable) => (
                    <SectionListItem
                      key={meetingMinutesLinkable.itemId}
                      onClick={() => {
                        onAddMeetingMinutesLink(meetingMinutesLinkable.itemId);
                      }}
                    >
                      {meetingMinutesLinkable.title}
                    </SectionListItem>
                  ))}
                </SectionList>
              </div>
            ),
          },
        ]
      : [];
  return (
    <BlackBox data-testid="add-link-menu" className={styles.addLinkMenu}>
      <Tabs
        tabs={[
          {
            id: 'external',
            displayName: 'Web Address',
            render: () => (
              <div>
                {invalidLink && (
                  <span className={styles.addLinkMenu__validationError}>
                    The link seems to have unsupported characters.
                  </span>
                )}
                <input
                  className={styles.addLinkMenu__input}
                  ref={(e) => e?.focus()}
                  type="url"
                  value={inputState}
                  onChange={(e) => {
                    setInputState(e.currentTarget.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                      e.preventDefault();
                      onAddExternalLink(inputState);
                    }
                  }}
                  onPaste={(e) => {
                    e.stopPropagation();
                  }}
                  placeholder="Paste or type a link"
                />
              </div>
            ),
          },

          {
            id: 'section',
            displayName: 'Section',
            render: () => (
              <div>
                <h1 className={styles.sectionList__heading}>Link To Section</h1>
                <SectionList className={styles.sectionList}>
                  {sectionLinks.map((sectionLink) => (
                    <SectionListItem
                      key={sectionLink.itemId}
                      onClick={() => {
                        onAddSectionLink(sectionLink.itemId);
                      }}
                    >
                      {sectionLink.title}
                    </SectionListItem>
                  ))}
                </SectionList>
              </div>
            ),
          },

          ...maybeMeetingMinutesLinkableTab,
        ]}
      />
    </BlackBox>
  );
};

export default AddLinkMenu;
