import useDocumentEventListener from '../../../junkDrawer/useDocumentEventListener.js';
import {
  callHandlers,
  handleKey,
} from '../../../editor/domFacing/events/isKeyMatch.js';

// TODO: Follow the convention of using a returned boolean to see if we preventDefault
export default function useUndoRedoKeybindings(
  onUndo: () => void,
  onRedo: () => void,
) {
  useDocumentEventListener(
    'keydown',
    callHandlers([
      handleKey({ key: 'z', ctrlKey: true }, (e) => {
        onUndo();
        e.preventDefault();
      }),
      handleKey(
        {
          key: 'Z',
          ctrlKey: true,
          shiftKey: true,
        },
        (e) => {
          onRedo();
          e.preventDefault();
        },
      ),
      handleKey({ key: 'y', ctrlKey: true }, (e) => {
        onRedo();
        e.preventDefault();
      }),
      handleKey({ key: 'z', metaKey: true }, (e) => {
        onUndo();
        e.preventDefault();
      }),
      handleKey(
        {
          key: 'z',
          metaKey: true,
          shiftKey: true,
        },
        (e) => {
          onRedo();
          e.preventDefault();
        },
      ),
      handleKey(
        {
          key: 'Z',
          metaKey: true,
          shiftKey: true,
        },
        (e) => {
          onRedo();
          e.preventDefault();
        },
      ),
    ]),
    [onUndo, onRedo],
  );
}
