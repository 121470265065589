import { Icon } from 'icons';
import styles from './AccessRestrictionPanel.module.scss';

type AccessRestrictionPanelProps = {
  title: string;
  button?: React.ReactNode;
  children?: React.ReactNode;
};

const AccessRestrictionPanel: React.FunctionComponent<
  AccessRestrictionPanelProps
> = ({ button, children, title }) => {
  return (
    <div className={styles.accessRestrictionPanel__panel}>
      <div className={styles.content}>
        <div className={styles.titleAndText}>
          <div className={styles.title}>
            <Icon name="secure" className={styles.headerIcon} />
            <span>{title}</span>
          </div>
          <span>{children}</span>
        </div>
        {button}
      </div>
    </div>
  );
};

export default AccessRestrictionPanel;
