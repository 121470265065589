import Point from '../../domHelpers/Point.js';
import Rect from '../../domHelpers/Rect.js';

const getDropIndex: (
  rectGetters: (() => Rect | undefined)[],
  editorRect: Rect,
  mousePos: Point,
) => number | null = (rectGetters, editorRect, mousePos) => {
  if (mousePos[0] < editorRect[0][0] || mousePos[0] > editorRect[1][0]) {
    return null;
  }

  for (const [i, getRect] of rectGetters.entries()) {
    const rect = getRect();
    if (!rect) continue;

    if (mousePos[1] < rect[1][1]) {
      return i;
    }
  }

  // or maybe should pass in the end click area as a ref
  return rectGetters.length;
};

export default getDropIndex;
