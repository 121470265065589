import { HydratedBlock } from '../../../types/HydratedBlock.js';

export default function withBlockKeys(blocks: HydratedBlock[]) {
  let imageCount = 0;
  let videoCount = 0;
  let tableCount = 0;

  return blocks.map((block, i) => {
    let key;
    switch (block.type) {
      case 'image':
        key = `${block.guid}-${imageCount++}`;
        break;
      case 'video': {
        key = `${block.url}-${videoCount++}`;
        break;
      }
      case 'table':
        key = `${block.id}-${tableCount++}`;
        break;
      case 'chart':
        key = `${block.guid}`;
        break;
      default:
        key = `${block.type}-${i}`;
        break;
    }

    return {
      block,
      key,
    };
  });
}
