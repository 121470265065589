type Rect = { x: number; y: number; width: number; height: number };
type ScrollOffset = {
  top: number;
};
export const getScrollOffsetToPutTextSelectionInView: (
  rectToView: Rect, // client rect
  scrollContainerRect: Rect, // client rect
  scrollContainerScrollOffset: ScrollOffset,
  options?: {
    offset: number;
  },
) => ScrollToOptions | null = (
  rectToView,
  scrollContainerRect,
  scrollContainerScrollOffset,
  options = { offset: 64 },
) => {
  const scrollContainerBottomEdge =
    scrollContainerRect.y + scrollContainerRect.height;
  const rectBottomEdge = rectToView.y + rectToView.height;
  const rectExtendsOffBottom = rectBottomEdge >= scrollContainerBottomEdge;

  if (rectExtendsOffBottom) {
    const rectMustMoveThisMuchY =
      scrollContainerBottomEdge - options.offset - rectBottomEdge;
    // vector representing rect movement (up is negative, down is positive)

    return {
      top: scrollContainerScrollOffset.top - rectMustMoveThisMuchY,
    };
  }

  const scrollContainerTopEdge = scrollContainerRect.y;
  const rectTopEdge = rectToView.y;

  const rectExtendsOffTop = rectTopEdge <= scrollContainerTopEdge;

  if (rectExtendsOffTop) {
    const rectMustMoveThisMuchY =
      scrollContainerTopEdge + options.offset - rectTopEdge;

    return {
      top: scrollContainerScrollOffset.top - rectMustMoveThisMuchY,
    };
  }

  return null;
};

export const getScrollOffsetToPutBlockInView: (
  rectToView: Rect, // client rect
  scrollContainerRect: Rect, // client rect
  scrollContainerScrollOffset: ScrollOffset,
  options?: {
    offset: number;
  },
) => ScrollToOptions | null = (
  rectToView,
  scrollContainerRect,
  scrollContainerScrollOffset,
  options = { offset: 128 },
) => {
  const scrollOffset = scrollContainerScrollOffset.top;
  const scrollContainerBottomEdge =
    scrollContainerRect.y + scrollContainerRect.height;

  const rectBottomEdge = rectToView.y + rectToView.height;
  const scrollContainerTopEdge = scrollContainerRect.y;

  const biggerThanScrollView = rectToView.height > scrollContainerRect.height;

  // we check whether rect is within "view area" which is defined as scroll container with padding
  // in other words: we scroll if rect is offscreen, or just peeking in by a small amount
  const rectIsBelowViewingArea =
    rectToView.y >= scrollContainerBottomEdge - options.offset;
  const rectIsAboveViewingArea =
    rectBottomEdge <= scrollContainerTopEdge + options.offset;

  if (rectIsBelowViewingArea) {
    if (biggerThanScrollView) {
      const rectMustMoveThisMuchY =
        scrollContainerBottomEdge +
        options.offset -
        rectToView.y -
        scrollContainerRect.height;

      return {
        top: scrollOffset - rectMustMoveThisMuchY,
      };
    }
    const rectMustMoveThisMuchY =
      scrollContainerBottomEdge - options.offset - rectBottomEdge;

    return {
      top: scrollOffset - rectMustMoveThisMuchY,
    };
  }

  if (rectIsAboveViewingArea) {
    if (biggerThanScrollView) {
      const rectMustMoveThisMuchY =
        scrollContainerTopEdge -
        rectBottomEdge +
        scrollContainerRect.height -
        options.offset;

      return {
        top: scrollOffset - rectMustMoveThisMuchY,
      };
    }

    const rectMustMoveThisMuchY =
      scrollContainerTopEdge + options.offset - rectToView.y;

    return {
      top: scrollOffset - rectMustMoveThisMuchY,
    };
  }

  return null;
};
