import { useCallback, useState } from 'react';
import { Icon } from 'icons';
import Pill from '../../../design-system/atoms/Pill.tsx';
import MaybeLink from '../../../design-system/molecules/MaybeLink.tsx';
import TransparentInput from '../../../design-system/molecules/TransparentInput.tsx';
import MeetingMinutesCardMenu from './components/MeetingMinutesCardMenu.tsx';
import styles from './MeetingMinutesCard.module.scss';
import { meetingMinutesPath } from '../../../services/Paths.ts';
import { MeetingMinutesListItem } from '../../../api/endpoints/createMeetingMinutesApi.ts';

type MeetingMinutesCardProps = {
  meetingMinutes: MeetingMinutesListItem;
  zeckTitle?: string | null;
  companyId: string;
  onDeleteMeetingMinutes: (meetingMinutesId: string) => Promise<void>;
  onRenameMeetingMinutes: (
    minutes: MeetingMinutesListItem,
    newName: string,
  ) => Promise<void>;
  onApproveMeetingMinutes: (
    meetingMinutesId: string,
    approved: boolean,
  ) => Promise<void>;
  onDuplicateMeetingMinutes: (
    meetingMinutesId: string,
    keepZeck: boolean,
  ) => Promise<void>;
};

const MeetingMinutesCard: React.FC<MeetingMinutesCardProps> = ({
  meetingMinutes,
  zeckTitle,
  onDeleteMeetingMinutes,
  onApproveMeetingMinutes,
  onRenameMeetingMinutes,
  onDuplicateMeetingMinutes,
}) => {
  const [renaming, setRenaming] = useState(false);
  const [meetingMinutesTitle, setMeetingMinutesTitle] = useState(
    meetingMinutes.title,
  );

  const onStartRenaming = useCallback(() => setRenaming(true), []);

  const onRename = async (newName: string) => {
    setMeetingMinutesTitle(newName);
    setRenaming(false);
    await onRenameMeetingMinutes(meetingMinutes, newName);
  };

  return (
    <div data-testid="meeting-minutes-card" className={styles.card}>
      <MaybeLink
        enabled={!renaming}
        className={styles.link}
        to={meetingMinutesPath(meetingMinutes)}
      >
        <div className={styles.meetingMinutes__titleColumn}>
          <Icon name="page" className={styles.meetingMinutes__icon} />

          <div className={styles.content}>
            {renaming && (
              <TransparentInput
                className={styles.titleInput}
                value={meetingMinutesTitle}
                onSubmit={onRename}
                aria-label="Rename meeting minutes"
              />
            )}
            {!renaming && (
              <span
                data-testid="meeting-minutes-card-title"
                className={styles.title}
              >
                {meetingMinutesTitle || 'untitled'}
              </span>
            )}
            {zeckTitle && (
              <span
                data-testid="meeting-minutes-card-zeck-title"
                className={styles.zeckTitle}
              >
                {zeckTitle}
              </span>
            )}
          </div>
        </div>
        {(meetingMinutes.approved || meetingMinutes.hasSignatures) && (
          <div className={styles.meetingMinutes__statusColumn}>
            {meetingMinutes.approved && (
              <Pill
                label="approved"
                color="green"
                uppercase
                className={styles.statusPill}
              />
            )}
            {meetingMinutes.hasSignatures && (
              <Pill
                label="signed"
                color="green"
                uppercase
                className={styles.statusPill}
              />
            )}
          </div>
        )}
      </MaybeLink>

      <MeetingMinutesCardMenu
        meetingMinutes={meetingMinutes}
        onDeleteMeetingMinutes={onDeleteMeetingMinutes}
        onApproveMeetingMinutes={onApproveMeetingMinutes}
        onDuplicateMeetingMinutes={onDuplicateMeetingMinutes}
        onRenameMeetingMinutes={onStartRenaming}
        className={styles.meetingMinutes__kebabTrigger}
      />
    </div>
  );
};

export default MeetingMinutesCard;
