import React from 'react';
import cx from 'classnames';
import styles from './Picker.module.scss';

type PickerProps = {
  children: React.ReactNode;
  className?: string;
} & React.ComponentProps<'div'>;

export const Picker = React.forwardRef<HTMLDivElement, PickerProps>(
  function Picker({ className, children, ...otherProps }, forwardedRef) {
    return (
      <div
        ref={forwardedRef}
        {...otherProps}
        className={cx(styles.timePicker, className)}
      >
        {children}
      </div>
    );
  },
);

type PickerItemProps = {
  children: React.ReactNode;
  radioGroupName: string;
  selected?: boolean;
  onSelect: () => void;
};

export const PickerItem: React.FC<PickerItemProps> = ({
  children,
  selected,
  radioGroupName,
  onSelect,
}) => {
  return (
    <label className={cx(styles.time, selected && styles.selectedTime)}>
      <input
        type="radio"
        name={radioGroupName}
        checked={selected}
        onChange={() => {
          onSelect();
        }}
      />
      {children}
    </label>
  );
};
