import React from 'react';
import styles from './Button.module.scss';
import cx from 'classnames';
import ButtonBase from './ButtonBase.tsx';

const colorMap = Object.freeze({
  primary: styles.button_primary,
  secondary: styles.button_secondary,
  danger: styles.button_danger,
  green: styles.button_green,
  transparent: styles.button_transparent,
});

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  size: React.ComponentProps<typeof ButtonBase>['size'];
  color: keyof typeof colorMap;
  isFullWidth?: boolean;
};

const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(function Button({ children, className, size, color, ...props }, ref) {
  return (
    <ButtonBase
      {...{
        ref,
        className: cx(className, styles.button, colorMap[color]),
        size,
        ...props,
      }}
    >
      {children}
    </ButtonBase>
  );
});

export default Button;
