import { isTextSelection } from '../../../../editor/selection/TextSelection.js';
import { BodyStateSelected } from './BodyEditor.js';
import {
  BulletedListItemBlock,
  NumberedListItemBlock,
} from 'editor-content/Block.js';
import getSelectedBlock from './getSelectedBlock.js';
import changeBlockAt from './changeBlockAt.js';
import { indexIsInSelection } from '../../../../editor/selection/BlockSelection.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';

export const indentBulletedListItem = (
  block: BulletedListItemBlock,
  amount: 1 | -1,
): BulletedListItemBlock => {
  return {
    ...block,
    indent: Math.max(Math.min(block.indent + amount, 1), 0),
  };
};

export const indentNumberedListItem = (
  block: NumberedListItemBlock,
  amount: 1 | -1,
): NumberedListItemBlock => {
  return {
    ...block,
    indent: Math.max(Math.min(block.indent + amount, 1), 0),
  };
};

const indentBlock = (block: HydratedBlock, amount: 1 | -1): HydratedBlock => {
  switch (block.type) {
    case 'bulleted-list-item':
      return indentBulletedListItem(block, amount);
    case 'numbered-list-item':
      return indentNumberedListItem(block, amount);
    case 'paragraph':
    case 'label':
    case 'heading':
    case 'image':
    case 'file':
    case 'vote':
    case 'agenda':
    case 'video':
    case 'divider':
    case 'table':
    case 'carta-cap-table':
    case 'chart':
      return block;
  }
};

export default function indent(
  initialState: BodyStateSelected,
  amount: 1 | -1,
): BodyStateSelected {
  const { content, selection } = initialState;

  if (isTextSelection(selection)) {
    const selectedBlock = getSelectedBlock(content, selection);
    if (!selectedBlock) {
      return { content, selection };
    }

    return {
      content: changeBlockAt(
        content,
        selection.index,
        indentBlock(selectedBlock, amount),
      ),
      selection,
    };
  }

  return {
    content: content.map((block, i) => {
      if (!indexIsInSelection(selection, i)) return block;

      return indentBlock(block, amount);
    }),
    selection,
  };
}
