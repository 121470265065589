import { BrandKitResource } from '../../../design-system/zeck/WithBrandKit.tsx';
import { CompanyLite } from '../../../types.ts';
import UploadedImage from '../UploadedImage.tsx';
import styles from './BrandLogo.module.scss';

export type BrandLogoProps = {
  company: CompanyLite;
  brandKitResource: BrandKitResource;
};

export default function BrandLogo({
  company,
  brandKitResource,
}: BrandLogoProps) {
  if (
    brandKitResource.result.type === 'success' &&
    brandKitResource.result.data.brandLogoId
  ) {
    return (
      <UploadedImage
        imageId={brandKitResource.result.data.brandLogoId}
        spinnerSize="small"
        alt="company logo"
        className={styles.brandLogoImage}
      />
    );
  } else {
    return <span>{company.name}</span>;
  }
}
