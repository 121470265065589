import { FormattedTableData } from '../zeck/table/formattedTableData.ts';
import TableRow from './TableRow.tsx';
import cx from 'classnames';
import styles from './Table.module.scss';
import React from 'react';

type TableProps = {
  table: FormattedTableData;
  noBorder?: boolean;
  className?: string;
};

const Table = React.memo(
  React.forwardRef<HTMLTableElement, TableProps>(function Table(
    { table, noBorder, className },
    forwardRef,
  ) {
    return (
      <table
        className={cx(
          styles.table,
          noBorder && styles.table_noBorder,
          className,
        )}
        ref={forwardRef}
      >
        <tbody>
          {table.rows.map((row, rowIndex) => (
            <TableRow key={rowIndex} row={row} />
          ))}
        </tbody>
      </table>
    );
  }),
);

export default Table;
