import textBlockGetSelectedContentStrategy from './textBlockGetSelectedContentStrategy.js';
import { TextBlock } from 'editor-content/Block.js';
import ContentSelection from '../../selection/contentSelection/ContentSelection.js';

const textBlockCopyStrategy = <BlockType extends TextBlock>(
  block: BlockType,
  selection: ContentSelection,
) => {
  return textBlockGetSelectedContentStrategy(block, selection);
};

export default textBlockCopyStrategy;
