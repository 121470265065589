import { makeMakeApiRequest } from '../makeMakeApiRequest.ts';

const createDeletionRequestApi = (
  makeApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createDeletionRequest: async (companyId: string, companyName: string) => {
    return await makeApiRequest('/deletion/delete-request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ companyId, companyName }),
    });
  },
  listDeletionRequests: async () => {
    return await makeApiRequest('/deletion/delete-requests', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  cancelDeletionRequest: async (companyId: string, jobId: string) => {
    return await makeApiRequest('/deletion/delete-request/cancel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ companyId, jobId }),
    });
  },
});

export default createDeletionRequestApi;
