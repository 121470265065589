import { useState } from 'react';
import Toggle from '../atoms/toggle/Toggle.tsx';
import styles from './LoadingToggle.module.scss';
import ThreeDotLoading from './ThreeDotLoading.tsx';

type ToggleProps = {
  label: string;
  on: boolean;
  onChange: (enabled: boolean) => Promise<void>;
  disabled?: boolean;
};

const LoadingToggle = ({
  label,
  on,
  onChange,
  disabled = false,
}: ToggleProps) => {
  const [saving, setSaving] = useState(false);

  const onToggle = async (enabled: boolean) => {
    setSaving(true);
    await onChange(enabled);
    setSaving(false);
  };

  if (saving) {
    return (
      <div className={styles.loadingWrapper}>
        <ThreeDotLoading />
      </div>
    );
  }

  return (
    <Toggle label={label} on={on} onChange={onToggle} disabled={disabled} />
  );
};

export default LoadingToggle;
