import useFadeInAnimation from '../../../../../services/useFadeInAnimation.ts';
import styles from './CommentDeleteConfirmation.module.scss';
import Button from '../../../../../design-system/atoms/Button.tsx';
import useBreakpoints from '../../../../../services/useBreakpoints.ts';
import cx from 'classnames';
import OutlineButton from '../../../../../design-system/atoms/OutlineButton.tsx';
import { CommentsSidebarModalPortal } from '../CommentsSidebarModalLayer.js';

type CommentDeleteConfirmationProps = {
  commentTypeLabel: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

function CommentDeleteConfirmation({
  isOpen,
  onCancel,
  onConfirm,
  commentTypeLabel,
}: CommentDeleteConfirmationProps) {
  const { isMobile, isTablet } = useBreakpoints();
  const isFullScreenComments = isMobile || isTablet;
  const { elementRef, show } = useFadeInAnimation<HTMLDivElement>(isOpen);

  if (!show) {
    return null;
  }

  return (
    <CommentsSidebarModalPortal>
      <div
        ref={elementRef}
        className={cx(
          styles.modal,
          isFullScreenComments && styles.modal_mobile,
        )}
      >
        <span className={styles.title}>Delete {commentTypeLabel}?</span>
        <span className={styles.description}>
          Are you sure you want to delete this {commentTypeLabel.toLowerCase()}?
          You won’t be able to get it back.
        </span>
        <div className={styles.buttons}>
          <OutlineButton
            size="medium"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation(); // don't trigger autoscroll
              // if you can think of a better way to model this be my guest

              onCancel();
            }}
            data-testid="cancel-delete-comment"
          >
            Cancel
          </OutlineButton>
          <Button
            size="medium"
            color="danger"
            onClick={(e) => {
              e.stopPropagation(); // don't trigger autoscroll
              onConfirm();
            }}
            data-testid="confirm-delete-comment"
          >
            Delete {commentTypeLabel}
          </Button>
        </div>
      </div>
    </CommentsSidebarModalPortal>
  );
}

export default CommentDeleteConfirmation;
