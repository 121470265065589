import React, { useRef } from 'react';
import VoteEditable from './VoteEditable.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import SelectableDiv from './SelectableDiv.tsx';
import BlockActions from './BlockActions.ts';
import { callHandlers } from '../../../../editor/domFacing/events/isKeyMatch.ts';
import handleNonTextBlockKeyEvents from './handleNonTextBlockKeyEvents.ts';
import { VoteBlockHydrated } from '../../../../VoteBlockHydrated.ts';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import {
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
} from '../../voting/VoteCapability.ts';

type VoteEditableWithKeyboardProps = {
  className?: string;
  isInBlockSelection: boolean;
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
} & BlockActions<VoteBlockHydrated>;

const VoteEditableWithKeyboard = React.forwardRef<
  HTMLElement,
  VoteEditableWithKeyboardProps
>(function VoteEditableWithKeyboard(
  {
    className,
    block,
    onChange,
    selection,
    isInBlockSelection,
    onSelect,
    onNavLeft,
    onNavRight,
    onEnter,
    onNavUp: _onNavUp,
    onNavDown: _onNavDown,
    onSelectOut: _onSelectOut,
    zeckFinalizeVoteCapability,
    zeckPrevoteCapability,
  },
  forwardedRef,
) {
  const ref = useRef<HTMLDivElement>(null);

  const eventTargetIsSelectableDiv = (e: React.KeyboardEvent<HTMLDivElement>) =>
    e.target !== ref.current;

  const onChangeWithSelection = (v: VoteBlockHydrated) =>
    onChange(v, contentSelection(0));

  return (
    <SelectableDiv
      ref={ref}
      expand
      className={className}
      onSelect={onSelect}
      internalSelection={selection}
      isInBlockSelection={isInBlockSelection}
      onKeyDown={callHandlers([
        eventTargetIsSelectableDiv,
        callHandlers([
          handleNonTextBlockKeyEvents({
            onEnter,
            onNavLeft,
            onNavRight,
          }),
        ]),
      ])}
      data-testid="vote-selection-container"
    >
      <VoteEditable
        ref={mergeRefs([forwardedRef])}
        block={block}
        onChange={onChangeWithSelection}
        zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
        zeckPrevoteCapability={zeckPrevoteCapability}
      />
    </SelectableDiv>
  );
});

export default VoteEditableWithKeyboard;
