import { SignInData } from '../api/ApiSession.tsx';
import { BrandKitResource } from '../design-system/zeck/WithBrandKit.tsx';
import usePageTracking, { PagesToTrack } from '../services/usePageTracking.ts';
import { UserAndCompany } from '../userAndCompany/FetchUserAndCompany.tsx';

export type PageWithAnalyticsParameters = {
  userAndCompany: UserAndCompany;
  brandKitResource: BrandKitResource;
  signIn: (userLoginData: SignInData) => Promise<string | null>;
  signOut: () => void;
  signOutNoRedirect: () => void;
  setAccessToken: (token: string) => void;
  forceTracking?: boolean;
};

const PageWithAnalytics: React.FC<{
  children: React.ReactNode;
  pageName: PagesToTrack;
  userAndCompany: UserAndCompany;
  forceTracking?: boolean;
}> = ({ children, pageName, userAndCompany, forceTracking }) => {
  usePageTracking(pageName, userAndCompany, forceTracking);

  return <>{children}</>;
};

export default PageWithAnalytics;
