import cx from 'classnames';
import React from 'react';
import styles from './PublishedCommentLayout.module.scss';

type PublishedCommentLayoutProps = {
  className?: string;
  children: React.ReactNode;
};

const PublishedCommentLayout: React.FC<PublishedCommentLayoutProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <article
      {...props}
      className={cx(styles.publishedCommentLayout, className)}
    >
      {children}
    </article>
  );
};

export default PublishedCommentLayout;
