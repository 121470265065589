import { useEffect, useState } from 'react';
import { apiKey } from './googleEnvVariables.ts';

export type GoogleApis = {
  gapi: typeof gapi;
  google: typeof google;
};
const useLoadGoogleAPIs = (
  loadedCb?: (googleApis: GoogleApis) => void,
): GoogleApis | null => {
  // if gapi and google exist, don't add scripts again
  const [apis, setApis] = useState<Partial<GoogleApis>>({});

  // load google auth stuff
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;

    script.onload = () => {
      setApis((old) => ({
        ...old,
        google: window.google,
      }));
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // gapi
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.async = true;
    script.defer = true;

    script.onload = () => {
      gapi.load('client:picker', () => {
        gapi.load('client', async () => {
          await gapi.client.init({
            apiKey,
            discoveryDocs: [
              'https://sheets.googleapis.com/$discovery/rest?version=v4',
            ],
          });

          setApis((old) => ({
            ...old,
            gapi: window.gapi,
          }));
        });
      });
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  if (apis.gapi && apis.google) {
    const googleApis = {
      gapi: apis.gapi,
      google: apis.google,
    };

    if (typeof loadedCb === 'function') {
      loadedCb(googleApis);
    }

    return googleApis;
  }

  return null;
};

export default useLoadGoogleAPIs;
