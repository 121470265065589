import { BlockSelection } from './selection/BlockSelection.js';
import { TextSelection } from './selection/TextSelection.js';

export type EditorSelection = TextSelection | BlockSelection | null;

export function isBlockSelection(
  selection: EditorSelection,
): selection is BlockSelection {
  return !!selection && !isTextSelection(selection);
}

export function isTextSelection(
  selection: EditorSelection,
): selection is TextSelection {
  return (
    !!selection &&
    selection.hasOwnProperty('index') &&
    selection.hasOwnProperty('offset')
  );
}
