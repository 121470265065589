import { MouseEventHandler } from 'react';
import styles from './LinkTooltip.module.scss';
import { Icon, IconName } from 'icons';
import cx from 'classnames';

type LinkTooltipProps = {
  children: React.ReactNode;
  href: string;
  iconName: IconName;
  iconColor?: 'red' | 'gray';
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

const LinkTooltip = ({
  children,
  href,
  iconName,
  iconColor,
  onClick,
}: LinkTooltipProps) => {
  const iconStyle =
    iconColor === 'red'
      ? cx([styles.LinkTooltip__icon, styles.LinkTooltip__icon_red])
      : iconColor === 'gray'
        ? cx([styles.LinkTooltip__icon, styles.LinkTooltip__icon_gray])
        : styles.LinkTooltip__icon;

  return (
    <div className={styles.LinkTooltip}>
      <Icon name={iconName} className={iconStyle} />
      <a
        data-testid="link-tooltip"
        target="_blank"
        rel="noreferrer"
        href={href}
        className={styles.LinkTooltip__text}
        onClick={onClick}
      >
        {children}
      </a>
    </div>
  );
};
export default LinkTooltip;
