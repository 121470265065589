import { getTextFromNodes, TextNode } from 'editor-content/TextNode.js';
import arrayIsNotEmpty from '../../../../junkDrawer/arrayIsNotEmpty.js';

export default function getHighlightIdsAtOffset(
  textNodes: TextNode[],
  offset: number,
): string[] {
  if (!arrayIsNotEmpty(textNodes)) {
    return [];
  }

  if (offset < 0) {
    return [];
  }

  const [textNode, ...restNodes] = textNodes;

  switch (textNode.type) {
    case 'text': {
      const length = textNode.text.length;
      if (length > offset) {
        return [];
      }
      return getHighlightIdsAtOffset(restNodes, offset - length);
    }
    case 'section-link':
    case 'external-link':
    case 'meeting-minutes-link': {
      const length = getTextFromNodes(textNode.content).length;
      if (length > offset) {
        return [];
      }
      return getHighlightIdsAtOffset(restNodes, offset - length);
    }
    case 'highlight': {
      const length = getTextFromNodes(textNode.content).length;
      if (length >= offset) {
        return textNode.ids;
      }
      return getHighlightIdsAtOffset(restNodes, offset - length);
    }
  }
}
