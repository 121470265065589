import { EngagementSummary } from 'app/api/endpoints/createAnalyticsApi.ts';

const getViewerEngagementSummary = (
  userTotals: Record<string, number>,
  totalViewerCount: number,
): EngagementSummary => {
  const totalMinutesSpent = Object.values(userTotals).reduce(
    (total, seconds) => total + Math.ceil(seconds / 60),
    0,
  );
  const uniqueUserViewsCount = Object.keys(userTotals).length;

  const viewedPercent = uniqueUserViewsCount
    ? totalViewerCount
      ? uniqueUserViewsCount / totalViewerCount
      : 1
    : 0;

  const averageTimeSpent = totalMinutesSpent / (totalViewerCount || 1);

  return {
    viewedPercent,
    totalMinutesSpent,
    averageTimeSpent,
  };
};

export default getViewerEngagementSummary;
