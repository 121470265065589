import Button from './design-system/atoms/Button.tsx';
import SessionLayout from './design-system/layouts/SessionLayout.tsx';
import { User } from './types.ts';
import styles from './NoCompanyHomePage.module.scss';

type NoCompanyHomePageProps = {
  user: User;
  onLogout: () => void;
};

export default function NoCompanyHomePage({
  user,
  onLogout,
}: NoCompanyHomePageProps) {
  return (
    <SessionLayout>
      <div className={styles.title}>
        You are not connected to any companies on Zeck.
      </div>
      <Button
        onClick={onLogout}
        size="large"
        color="primary"
        className={styles.logout}
      >
        Log Out
      </Button>
      <div className={styles.description}>
        You're currently logged in as {user.email}. You might need to sign in
        with a different email, or contact the company’s owner to request an
        invite.
      </div>
    </SessionLayout>
  );
}
