import {
  CommentContentNode,
  commentTextNode,
} from 'editor-content/CommentContent.js';
import arrayIsNotEmpty from '../junkDrawer/arrayIsNotEmpty.js';

const splitCommentContentNodes = (
  nodes: CommentContentNode[],
  offset: number,
): [CommentContentNode[], CommentContentNode[]] => {
  if (offset <= 0) {
    return [[], nodes];
  }

  if (!arrayIsNotEmpty(nodes)) {
    return [[], []];
  }

  const [currentNode, ...restNodes] = nodes;

  switch (currentNode.type) {
    case 'text': {
      const text = currentNode.text;
      const currentNodeLength = text.length;

      if (offset === currentNodeLength) {
        return [[commentTextNode(text)], [...restNodes]];
      }

      if (offset < currentNodeLength) {
        return [
          [commentTextNode(text.slice(0, offset))],
          [commentTextNode(text.slice(offset)), ...restNodes],
        ];
      }

      const split = splitCommentContentNodes(
        restNodes,
        offset - currentNodeLength,
      );

      return [[currentNode, ...split[0]], split[1]];
    }
    case 'tag': {
      if (offset === 1) {
        return [[currentNode], restNodes];
      }

      const split = splitCommentContentNodes(restNodes, offset - 1);

      return [[currentNode, ...split[0]], split[1]];
    }
  }
};

export default splitCommentContentNodes;
