const moveItem = <T>(arr: T[], startIndex: number, endIndex: number): T[] => {
  if (startIndex === endIndex) {
    return arr;
  }

  const item = arr[startIndex];

  if (!item) {
    throw new Error('invalid index');
  }

  if (startIndex < endIndex) {
    const itemsBefore = arr.slice(0, startIndex);
    const itemsBetween = arr.slice(startIndex + 1, endIndex);
    const itemsAfter = arr.slice(endIndex);

    // console.log('aft', { itemsBefore, itemsBetween, itemsAfter });

    return [...itemsBefore, ...itemsBetween, item, ...itemsAfter];
  }

  const itemsBefore = arr.slice(0, endIndex);
  const itemsBetween = arr.slice(endIndex, startIndex);
  const itemsAfter = arr.slice(startIndex + 1);

  // console.log('bef', { itemsBefore, itemsBetween, itemsAfter });

  return [...itemsBefore, item, ...itemsBetween, ...itemsAfter];
};

export default moveItem;
