import cx from 'classnames';
import styles from './Toggle.module.scss';

type ToggleProps = {
  label: string;
  on: boolean;
  onChange: (enabled: boolean) => void;
  disabled?: boolean;
};

const Toggle = ({ label, on, onChange, disabled = false }: ToggleProps) => {
  return (
    <div
      className={cx(
        styles.Toggle,
        on && styles.Toggle_on,
        disabled && styles.Toggle_disabled,
      )}
    >
      <input
        type="checkbox"
        aria-label={label}
        className={styles.Toggle__checkbox}
        checked={on}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
    </div>
  );
};

export default Toggle;
