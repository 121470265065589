import ContentSelection from '../../../editor/selection/contentSelection/ContentSelection.ts';

const getContentSelection = (
  originalText: string,
  newText: string,
  originalSelection: ContentSelection,
): ContentSelection | null => {
  // Calculate the start and end positions regardless of direction
  const start = Math.min(
    originalSelection.anchorOffset,
    originalSelection.focusOffset,
  );
  const end = Math.max(
    originalSelection.anchorOffset,
    originalSelection.focusOffset,
  );

  // Extract the selected text from the original string
  const selectedText = originalText.slice(start, end);

  // If the text at the original position is unchanged, return original selection
  if (newText.slice(start, end) === selectedText) {
    return originalSelection;
  }

  // Find all occurrences of the selected text in the new text
  const occurrences: number[] = [];
  let pos = newText.indexOf(selectedText);

  while (pos !== -1) {
    occurrences.push(pos);
    pos = newText.indexOf(selectedText, pos + 1);
  }

  // If we found exactly one occurrence, return its position
  if (occurrences.length === 1 && occurrences[0] != undefined) {
    const newStart = occurrences[0];

    return {
      anchorOffset: newStart,
      focusOffset: newStart + selectedText.length,
    };
  }

  // Return null if multiple occurrences found
  return null;
};

export default getContentSelection;
