import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function SendMailIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <g clipPath="url(#clip0_14747_27755)">
        <path
          d="M7.35603 9.14369L1.07031 6.28655L15.9275 0.572266L10.2132 15.4294L7.35603 9.14369ZM7.35603 9.14369L10.7846 5.71512"
          stroke="white"
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
          fillOpacity={0}
        />
      </g>
      <defs>
        <clipPath id="clip0_14747_27755">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
