import { useState } from 'react';
import styles from './ZeckViewTableOfContents.module.scss';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Icon } from 'icons';
import useEnterExitAnimation from '../../services/useFadeInAnimation.ts';

type ZeckViewTableOfContentsProps = {
  children: React.ReactNode;
};

const ZeckViewTableOfContents = ({
  children,
}: ZeckViewTableOfContentsProps) => {
  return <ol className={styles.tableOfContents}>{children}</ol>;
};

type ZeckViewTableOfContentsItemProps = {
  children: React.ReactNode;
  to: string;
  onClick: () => void;
};

export const ZeckViewTableOfContentsItem = ({
  to,
  children,
  onClick,
}: ZeckViewTableOfContentsItemProps) => {
  return (
    <li className={styles.tableOfContentsItem}>
      <Link
        to={to}
        className={cx(
          styles.tableOfContentsLink,
          styles.tableOfContentsItem__text,
        )}
        onClick={onClick}
      >
        {children}
      </Link>
    </li>
  );
};

type ZeckViewTableOfContentsCollapseProps = {
  title: string;
  children: React.ReactNode;
};

export const ZeckViewTableOfContentsItemCollapse = ({
  title,
  children,
}: ZeckViewTableOfContentsCollapseProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { show, elementRef } = useEnterExitAnimation<HTMLOListElement>(isOpen);

  return (
    <li className={styles.tableOfContentsCollapse}>
      <button
        className={cx(
          styles.tableOfContentsButton,
          styles.tableOfContentsItem__text,
        )}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <Icon
          name="chevronDown"
          className={cx(
            styles.collapseIcon,
            isOpen && styles.collapseIcon_open,
          )}
        />
      </button>

      {show && (
        <ol ref={elementRef} className={styles.tableOfContentsCollapse__list}>
          {children}
        </ol>
      )}
    </li>
  );
};

type ZeckViewTableOfContentsHeadingProps = {
  children: React.ReactNode;
};

export const ZeckViewTableOfContentsHeading = ({
  children,
}: ZeckViewTableOfContentsHeadingProps) => {
  return <h1 className={styles.tableOfContentsHeading}>{children}</h1>;
};

export default ZeckViewTableOfContents;
