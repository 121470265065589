import BaseTextBlock from 'editor-content/BaseTextBlock.js';
import { TextFormat } from 'editor-content/TextNode.js';
import ContentSelection from '../../selection/contentSelection/ContentSelection.js';
import formatTextNodes from '../textNode/formatTextNodes.js';
import { updateTextBlock } from 'editor-content/Block.js';
import { getSelectionFormat } from '../../selection/contentSelection/getSelectionFormat.js';

export default function textBlockToggleFormatStrategy<
  Block extends BaseTextBlock,
>(
  block: Block,
  selection: ContentSelection,
  formatName: keyof TextFormat,
): Block {
  const selectionFormat = getSelectionFormat(block, selection);

  const format = { [formatName]: !selectionFormat[formatName] };

  return updateTextBlock(
    block,
    formatTextNodes(block.content, selection, format),
  );
}
