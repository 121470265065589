import styles from './AiChartFlowReviewing.module.scss';
import { Company } from '../../../../types.ts';
import { ChartBlockData } from 'editor-content/ChartBlock.ts';
import Button from '../../../../design-system/atoms/Button.tsx';
import { CheckmarkIcon, TrashIcon } from 'icons';
import Chart from '../../../../design-system/zeck/chart/chart.tsx';
import { v4 } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { ButtonSizedLoader } from '../../../../design-system/molecules/LoadingButton.tsx';

export type AiChartFlowReviewingState = {
  type: 'reviewing';
  chart: ChartBlockData;
};

type AiChartFlowReviewingProps = {
  chart: ChartBlockData;
  company: Pick<Company, 'brandColorPrimary'>;
  onDiscard: () => void;
  onAccept: (base64ImageData: string) => void;
};

export const AiChartFlowReviewing: React.FC<AiChartFlowReviewingProps> = ({
  chart,
  company,
  onDiscard,
  onAccept,
}) => {
  const [bufferingAccept, setBufferingAccept] = useState(false);
  const [blockId] = useState<string>(v4());
  const imagePromiseRef = useRef<Promise<string>>();
  const resolveImageRef = useRef<(data: string) => void>();

  useEffect(() => {
    // Create new promise if it doesn't exist
    if (!imagePromiseRef.current) {
      imagePromiseRef.current = new Promise<string>((resolve) => {
        resolveImageRef.current = resolve;
      });
    }
  }, []);

  return (
    <>
      <Chart
        block={{ id: blockId, data: { chartData: chart } }}
        company={company}
        className={styles.previewChart}
        onClick={() => {}}
        preview
        onHasImage={(data: string) => {
          resolveImageRef.current?.(data);
        }}
        showWarning
      />

      <div className={styles.buttonMenu}>
        <Button
          onClick={async () => {
            setBufferingAccept(true);

            const imageData = await imagePromiseRef.current;
            if (imageData) {
              onAccept(imageData);
            }
          }}
          size="medium"
          color="transparent"
          className={styles.button}
        >
          {bufferingAccept ? (
            <ButtonSizedLoader />
          ) : (
            <CheckmarkIcon className={styles.buttonIcon} />
          )}
          Accept
        </Button>

        <Button
          onClick={() => {
            onDiscard();
          }}
          size="medium"
          color="transparent"
          className={styles.button}
        >
          <TrashIcon className={styles.buttonIcon} />
          Discard
        </Button>
      </div>
    </>
  );
};
