import Linkable from 'editor-content/html/Linkable.js';
import { useState } from 'react';

export type LinkDrawerPreviewable = Linkable | { type: 'pdf'; pdfUrl: string };

type LinkDrawerState =
  | { type: 'closed' }
  | { type: 'open'; content: LinkDrawerPreviewable };

const useLinkDrawer = () => {
  const [uiState, setUIState] = useState<LinkDrawerState>({
    type: 'closed',
  });

  return {
    closeDrawer() {
      setUIState({ type: 'closed' });
    },
    openDrawer(linkContent: LinkDrawerPreviewable) {
      setUIState({ type: 'open', content: linkContent });
    },
    openFilePreview(pdfUrl: string) {
      setUIState({
        type: 'open',
        content: { type: 'pdf', pdfUrl: encodeURIComponent(pdfUrl) },
      });
    },
    drawerState: uiState,
  };
};

export default useLinkDrawer;
