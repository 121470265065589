import styles from './AnalyticsBreakdown.module.scss';
import cx from 'classnames';

type AnalyticsBreakdownProps = {
  children?: React.ReactNode;
  className?: string;
};

export const AnalyticsBreakdownTitle: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  return <div className={styles.summaryPanelTitle} {...props} />;
};

export const AnalyticsBreakdownValue: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  return <div className={styles.summaryPanelValue} {...props} />;
};

export const AnalyticsBreakdownSection: React.FC<AnalyticsBreakdownProps> = ({
  children,
  className,
}) => {
  return <div className={cx([styles.summaryPanel, className])}>{children}</div>;
};

export const AnalyticsBreakdown: React.FC<AnalyticsBreakdownProps> = ({
  children,
}) => {
  return <div className={styles.summary}>{children}</div>;
};
