import React from 'react';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import Label from '../../../../design-system/zeck/Label.tsx';
import { LabelBlock, updateTextBlock } from 'editor-content/Block.js';
import useZeckContentEditable from './useZeckContentEditable.ts';
import EditableProps from './EditableProps.ts';

const LabelEditable = React.forwardRef<
  HTMLElement,
  {
    className?: string;
  } & EditableProps<LabelBlock>
>(function LabelEditable(
  { value, onChange, selection, onSelect, linkables, ...otherProps },
  forwardedRef,
) {
  const contentEditableProps = useZeckContentEditable(
    {
      content: value.content,
      selection,
    },
    ({ content, selection }) => {
      onChange(updateTextBlock(value, content), selection);
    },
    onSelect,
    { linkables },
  );
  return (
    <Label
      {...{
        ...otherProps,
        ...contentEditableProps,
        ref: mergeRefs([contentEditableProps.ref, forwardedRef]),
      }}
    />
  );
});

export default LabelEditable;
