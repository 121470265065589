import styles from './FloatingSidebar.module.scss';
import IconButton from '../atoms/IconButton.tsx';
import IconLink from '../atoms/IconLink.tsx';
import { useNavigate } from 'react-router-dom';
import DetectsOutsideClick from '../../junkDrawer/DetectsOutsideClick.tsx';
import WithTooltip from '../organisms/WithTooltip.tsx';
import { AnimatePresence, motion } from 'framer-motion';

type FloatingSidebarHeaderProps = {
  homeHref: string;
  onClose: () => void;
};

export function FloatingSidebarHeader({
  onClose,
  homeHref,
}: FloatingSidebarHeaderProps) {
  const navigate = useNavigate();

  return (
    <div className={styles.sidebar__header}>
      <WithTooltip<HTMLAnchorElement> text="Back to Company">
        {(ref, listeners) => (
          <IconLink
            name="close"
            href={homeHref}
            onClick={(e) => {
              e.preventDefault();
              navigate(homeHref);
            }}
            aria-label="Back to Company"
            ref={ref}
            {...listeners}
          />
        )}
      </WithTooltip>
      <IconButton
        name="doubleChevronLeft"
        aria-label="close sidebar"
        onClick={onClose}
      />
    </div>
  );
}

type FloatingSidebarProps = {
  isOpen: boolean;
  onRequestClose(): void;
  children: React.ReactNode;
  footerContent?: React.ReactNode;
  homeHref: string;
};

export default function FloatingSidebar({
  isOpen,
  onRequestClose,
  children,
  footerContent,
  homeHref,
}: FloatingSidebarProps) {
  return (
    <DetectsOutsideClick<HTMLDivElement>
      {...{
        onClick: () => {
          onRequestClose();
        },
      }}
    >
      {(containerRef) => (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              key="sidebar"
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ duration: 0.2 }}
              ref={containerRef}
              className={styles.sidebar}
              data-testid="sidebar"
            >
              <FloatingSidebarHeader
                onClose={onRequestClose}
                homeHref={homeHref}
              />
              <div className={styles.sidebar__content}>{children}</div>
              {footerContent && (
                <div className={styles.sidebar__footer}>{footerContent}</div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </DetectsOutsideClick>
  );
}
