import { Block } from 'editor-content/Block.js';
import { HydratedBlock } from '../../types/HydratedBlock.js';
import pick from 'lodash/pick.js';

const dehydrateBlock = (block: HydratedBlock): Block => {
  if (block.type !== 'vote') {
    return block;
  }

  return pick(block, [
    'id',
    'type',
    'title',
    'details',
    'supportingDocuments',
    'voteType',
    'prevoteEnabled',
  ]);
};

export default dehydrateBlock;
