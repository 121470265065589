import useBreakpoints from '../../services/useBreakpoints.ts';
import styles from './ResetPasswordPage.module.scss';
import cx from 'classnames';
import SessionLayout from '../../design-system/layouts/SessionLayout.tsx';
import Button from '../../design-system/atoms/Button.tsx';

type ResetPasswordExpiredProps = {
  onResendRequest(): void;
};

const ResetPasswordExpired = ({
  onResendRequest,
}: ResetPasswordExpiredProps) => {
  const { isMobile } = useBreakpoints();

  return (
    <SessionLayout>
      <h1 className={cx(isMobile ? styles.title_mobile : styles.title)}>
        Reset Link Has Expired
      </h1>
      <p className={styles.resetPassword__description}>
        The password reset link emailed to you has expired. Click ‘Resend Reset
        Password Link’ below and we’ll email you a new one.
      </p>
      <Button
        {...{
          className: styles.resetPassword__submit,
          size: 'large',
          color: 'primary',
          onClick: () => onResendRequest(),
        }}
      >
        Resend Reset Password Link
      </Button>
    </SessionLayout>
  );
};

export default ResetPasswordExpired;
