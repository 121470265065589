import { useCallback } from 'react';
import { CommentsStateForSection } from '../commentsSidebar/useComments/useComments.ts';

const useHandleHighlightClick = (
  commentsStateForSection?: CommentsStateForSection,
) => {
  return useCallback(
    (event: MouseEvent) => {
      const findSpanWithHighlights = (element: HTMLElement) => {
        const isHighlightSpan = (el: HTMLElement | null) =>
          el?.tagName.toLowerCase() === 'span' && el?.dataset.highlightids;

        return isHighlightSpan(element)
          ? element
          : isHighlightSpan(element.parentElement)
            ? element.parentElement
            : null;
      };

      const containsLink = (element: HTMLElement) => {
        const linksInElement = element.getElementsByTagName('a');
        return linksInElement.length !== 0;
      };

      const highlightSpan = findSpanWithHighlights(event.target as HTMLElement);
      if (!highlightSpan || !highlightSpan.dataset.highlightids) return;
      if (containsLink(highlightSpan)) return;

      event.stopPropagation();
      const [firstHighlightId] = highlightSpan.dataset.highlightids.split(' ');

      if (firstHighlightId) {
        commentsStateForSection?.openComment(firstHighlightId);
      }
    },
    [commentsStateForSection],
  );
};

export default useHandleHighlightClick;
