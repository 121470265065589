import { Block } from 'editor-content/Block.js';
import EditorData from './EditorData.js';

type ZeckClipboardData = {
  zeck: 'zeck';
  data: EditorData<Block>;
  sourceCompanyId: string;
};

export function createZeckClipboardData(
  data: EditorData<Block>,
  sourceCompanyId: string,
) {
  const value: ZeckClipboardData = {
    zeck: 'zeck',
    data,
    sourceCompanyId,
  };
  return JSON.stringify(value);
}

export function extractZeckClipboardData(
  dataTransfer: Pick<DataTransfer, 'getData'>,
): ZeckClipboardData | null {
  try {
    const json = JSON.parse(dataTransfer.getData('application/json'));
    if (isZeckClipboardData(json)) return json;

    return null;
  } catch {
    return null;
  }
}

export function isZeckClipboardData(s: unknown): s is ZeckClipboardData {
  return typeof s === 'object' && s !== null && s.hasOwnProperty('zeck');
}
