import Button from '../atoms/Button.tsx';
import { ButtonSizedLoader } from './LoadingButton.tsx';

type ButtonWithLoadingProps = React.ComponentProps<typeof Button> & {
  isLoading: boolean;
};

const ButtonWithLoading: React.FC<ButtonWithLoadingProps> = ({
  isLoading,
  children,
  ...props
}) => {
  return (
    <Button {...props} disabled={props.disabled || isLoading}>
      <span style={isLoading ? { opacity: 0 } : {}}>{children}</span>
      {isLoading && (
        <ButtonSizedLoader
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
          }}
        />
      )}
    </Button>
  );
};

export default ButtonWithLoading;
