import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function AlignCenterIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <g clipPath="url(#clip0_8645_217021)">
        <path
          d="M7 17V19H17V17H7ZM3 15H21V13H3V15ZM7 9V11H17V9H7ZM3 5V7H21V5H3Z"
          fill="#8F90A6"
        />
      </g>
      <defs>
        <clipPath id="clip0_8645_217021">
          <rect width="24" height="24" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
