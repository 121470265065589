import styles from '../../../../pages/zeck/edit/topbar/EditorTopBar.module.scss';
import TopBarLayout from '../../../../design-system/layouts/TopBarLayout.tsx';
import SavedStatusIndicator from '../../../zeck/edit/SavedStatusIndicator.tsx';
import StatusIndicator from '../../../../design-system/molecules/StatusIndicator.tsx';
import cx from 'classnames';
import Link from '../../../../design-system/atoms/Link.tsx';
import { Icon } from 'icons';

type MeetingMinutesTopBarProps = {
  title: string;
  isSaved: boolean;
  minutesListLink: string;
};

const MeetingMinutesTopBar: React.FC<MeetingMinutesTopBarProps> = ({
  title,
  isSaved,
  minutesListLink,
}) => {
  return (
    <TopBarLayout
      className={styles.editorTopBar__header}
      {...{
        leftSlot: (
          <div className={styles.editorTopBar__headerStatusContainer}>
            <Link
              data-testid="meeting-minutes-close-button"
              className={styles.editorTopBar__headerStatusIcon}
              href={minutesListLink}
            >
              <Icon name="close" aria-label="Minutes List" />
            </Link>

            <div className={cx(styles.editorTopBar__headerStatusSection)}>
              <StatusIndicator iconName="lock">Secure</StatusIndicator>
              <SavedStatusIndicator isSaved={isSaved} />
            </div>
          </div>
        ),
      }}
    >
      <h1
        data-testid="meeting-minutes-title"
        className={styles.editorTopBar__headerSectionTitle}
      >
        {title}
      </h1>
    </TopBarLayout>
  );
};

export default MeetingMinutesTopBar;
