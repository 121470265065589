import Toast from '../../design-system/molecules/toast/Toast.tsx';
import { ToastProps } from '../../design-system/molecules/toast/useToast.ts';
import WithResultLoading from '../../result/WithResultLoading.tsx';
import { Result } from '../../services/useFetch/useFetch.ts';
import { ZeckLite } from '../../types.ts';
import { ZeckFolderWithCount } from '../../types/ZeckFolder.ts';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';
import LoaderSpinnerOverlay from '../LoaderSpinnerOverlay.tsx';
import PageLayout from '../PageLayout.tsx';
import { WithFolderDragAndDrop } from './FolderDragAndDrop.tsx';

type HomepageProps = {
  activePage: string;
  pageTitle: string;

  zecksResult: Result<ZeckLite[]>;
  renderZecks: (zeck: ZeckLite[]) => React.ReactNode; // just all the methods go in here

  // sidebar
  onMoveZeck: (zeckId: string, folderId: string | null) => Promise<void>;
  onClickCreateZeck?: () => void;
  userAndCompany: UserAndCompany;
  onClickLogout: () => void;

  onUpdateFolder?: (folderId: string, folderName: string) => void;
  onCreateFolder?: (folderName: string) => Promise<void>;
  onDeleteFolder?: (folderId: string) => Promise<void>;
  foldersResult: Result<ZeckFolderWithCount[]>;

  // toast
  toast: ToastProps;
};

const AdminHomepage = ({
  userAndCompany,
  onClickLogout,
  onClickCreateZeck,
  onMoveZeck,
  zecksResult,
  onCreateFolder,
  onUpdateFolder,
  onDeleteFolder,
  foldersResult,
  toast,
  activePage,
  pageTitle,
  renderZecks,
}: HomepageProps) => {
  return (
    <WithFolderDragAndDrop
      zecksResult={zecksResult}
      onMoveZeck={onMoveZeck}
      foldersResult={foldersResult}
    >
      <PageLayout
        {...{
          title: pageTitle,
          userAndCompany,
          onClickLogout,
          onClickCreateZeck: onClickCreateZeck,
          activePage,
          zeckFoldersResult: foldersResult,
          onUpdateFolder,
          onDeleteFolder,
          onCreateFolder,
        }}
      >
        <WithResultLoading
          result={zecksResult}
          duration={400}
          renderLoading={(out) => <LoaderSpinnerOverlay out={out} />}
        >
          {renderZecks}
        </WithResultLoading>
        <Toast {...toast} />
      </PageLayout>
    </WithFolderDragAndDrop>
  );
};

export default AdminHomepage;
