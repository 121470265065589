import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function UnderlineIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M11.5 17.4444C14.5736 17.4444 17.0714 15.0533 17.0714 12.1111V5H14.75V12.1111C14.75 13.8267 13.2921 15.2222 11.5 15.2222C9.70786 15.2222 8.25 13.8267 8.25 12.1111V5H5.92857V12.1111C5.92857 15.0533 8.42643 17.4444 11.5 17.4444ZM5 19.2222V21H18V19.2222H5Z"
        fill="black"
      />
    </svg>
  );
}
