import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function H1Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M5.96601 12.6721C5.90201 12.6721 5.84867 12.6934 5.80601 12.7361C5.77401 12.7787 5.75801 12.8267 5.75801 12.8801V17.3761C5.75801 17.5041 5.72067 17.6001 5.64601 17.6641C5.58201 17.7174 5.47534 17.7441 5.32601 17.7441H3.31001C3.18201 17.7441 3.09134 17.7174 3.03801 17.6641C2.98467 17.6107 2.95801 17.5254 2.95801 17.4081V6.17607C2.95801 5.9734 3.04334 5.87207 3.21401 5.87207H5.50201C5.67267 5.87207 5.75801 5.96807 5.75801 6.16007V10.2561C5.75801 10.4374 5.83801 10.5281 5.99801 10.5281H9.69401C9.86468 10.5281 9.95001 10.4427 9.95001 10.2721V6.16007C9.95001 5.96807 10.03 5.87207 10.19 5.87207H12.478C12.6593 5.87207 12.75 5.9734 12.75 6.17607V17.4081C12.75 17.6321 12.6327 17.7441 12.398 17.7441H10.366C10.2167 17.7441 10.11 17.7174 10.046 17.6641C9.98201 17.6107 9.95001 17.5147 9.95001 17.3761V12.8801C9.95001 12.8161 9.92868 12.7681 9.88601 12.7361C9.85401 12.6934 9.80067 12.6721 9.72601 12.6721H5.96601Z"
        fill="#1C1C28"
      />
      <path
        d="M21.1656 15.6801C21.3576 15.6801 21.4536 15.7601 21.4536 15.9201V17.4721C21.4536 17.5467 21.427 17.6107 21.3736 17.6641C21.3203 17.7174 21.2403 17.7441 21.1336 17.7441H14.8136C14.611 17.7441 14.5096 17.6534 14.5096 17.4721V15.9201C14.5096 15.7601 14.6003 15.6801 14.7816 15.6801H16.4456C16.563 15.6801 16.6216 15.6427 16.6216 15.5681V8.84807C16.6216 8.76274 16.6056 8.7094 16.5736 8.68807C16.5416 8.65607 16.483 8.65074 16.3976 8.67207L14.8456 9.13607L14.7336 9.15207C14.5736 9.15207 14.4936 9.0454 14.4936 8.83207V7.77607C14.4936 7.6694 14.5096 7.59474 14.5416 7.55207C14.5736 7.49874 14.6376 7.4454 14.7336 7.39207L17.4056 6.06407C17.555 5.9894 17.6723 5.9414 17.7576 5.92007C17.8536 5.88807 17.9603 5.87207 18.0776 5.87207H19.1816C19.2563 5.87207 19.315 5.89874 19.3576 5.95207C19.4003 6.0054 19.4216 6.07474 19.4216 6.16007V15.5201C19.4216 15.6267 19.4696 15.6801 19.5656 15.6801H21.1656Z"
        fill="#1C1C28"
      />
    </svg>
  );
}
