import { FormattedTableRow } from '../zeck/table/formattedTableData.ts';
import TableCell from './TableCell.tsx';

type TableRowProps = {
  row: FormattedTableRow;
};

const TableRow: React.FC<TableRowProps> = ({ row }) => {
  return (
    <tr style={row.style}>
      {row.cells.map((cell, i) => (
        <TableCell key={i} cell={cell} />
      ))}
    </tr>
  );
};

export default TableRow;
