import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function NavGroupIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <g id="Group">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9013 7.13345C14.0535 7.22118 14.1799 7.34743 14.2677 7.49952C14.3556 7.6516 14.4019 7.82414 14.4019 7.99978C14.4019 8.17542 14.3556 8.34797 14.2677 8.50005C14.1799 8.65213 14.0535 8.77839 13.9013 8.86612L3.93199 14.6221C3.77997 14.7099 3.60753 14.7561 3.432 14.7561C3.25646 14.7561 3.08402 14.7099 2.932 14.6221C2.77998 14.5344 2.65374 14.4081 2.56597 14.2561C2.4782 14.1041 2.43199 13.9317 2.43199 13.7561V2.24412C2.43199 2.06858 2.4782 1.89614 2.56597 1.74412C2.65374 1.59211 2.77998 1.46587 2.932 1.37811C3.08402 1.29034 3.25646 1.24414 3.432 1.24414C3.60753 1.24414 3.77997 1.29035 3.93199 1.37812L13.9013 7.13412V7.13345Z"
          fill="#555770"
        />
      </g>
    </svg>
  );
}
