import React from 'react';
import styles from './IconButton.module.scss';
import cx from 'classnames';
import { Icon, IconName } from 'icons';

export type IconButtonProps = {
  className?: string;
  name: IconName;
  type?: 'normal' | 'filled' | 'dark';
  'aria-label': string;
  active?: boolean;
  disabled?: boolean;
};

const getIconStyle = (type: IconButtonProps['type']) => {
  switch (type) {
    case 'normal':
      return null;
    case 'filled':
      return styles.iconButton_filled;
    case 'dark':
      return styles.iconButton_dark;
  }
};

const IconButton = React.forwardRef<
  HTMLButtonElement,
  IconButtonProps & React.HTMLAttributes<HTMLButtonElement>
>(function IconButton(
  {
    className,
    name,
    type = 'normal',
    active = false,
    disabled = false,
    ...otherProps
  },
  forwardedRef,
) {
  if (disabled) {
    return (
      <button
        {...otherProps}
        disabled
        aria-disabled
        type="button"
        ref={forwardedRef}
        className={cx(
          className,
          styles.iconButton,
          getIconStyle(type),
          active && styles.iconButton_active,
        )}
      >
        <Icon name={name} />
      </button>
    );
  }

  return (
    <button
      {...otherProps}
      type="button"
      ref={forwardedRef}
      className={cx(
        className,
        styles.iconButton,
        getIconStyle(type),
        active && styles.iconButton_active,
      )}
    >
      <Icon name={name} />
    </button>
  );
});

export default IconButton;
