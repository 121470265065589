import { RefObject, useMemo, useState } from 'react';
import useBreakpoints from '../../services/useBreakpoints.ts';
import { IconName } from 'icons';
import styles from './DrawerMenu.module.scss';
import DrawerMenu, {
  DrawerMenuButtonItem,
  DrawerMenuLinkItem,
} from './DrawerMenu.tsx';
import {
  fromElement,
  PositionStrategyRect,
} from '../../domHelpers/hoverNextTo/positionStrategy/PositionStrategy.js';
import HoverMenu, {
  HoverMenuButtonItem,
  HoverMenuLinkItem,
} from './HoverMenu.tsx';

type KebabMenuBase = {
  label: string;
  color?: 'danger' | 'normal';
  disabled?: boolean;
  iconName: IconName;
  hideInDrawer?: boolean;
  hide?: boolean;
};

type KebabMenuButton = KebabMenuBase & {
  onClick: () => void;
};

type KebabMenuButtonWithModal = KebabMenuBase & {
  Modal: React.FC<{ isOpen: boolean; onClose: () => void }>;
};

type KebabMenuLink = KebabMenuBase & {
  href: string;
};

type KebabItemWithoutModals = KebabMenuButton | KebabMenuLink;

export type KebabItem = KebabItemWithoutModals | KebabMenuButtonWithModal;

const isKebabMenuButton = (item: KebabItem): item is KebabMenuButton => {
  return (item as KebabMenuButton).onClick !== undefined;
};

const isKebabMenuButtonWithModal = (
  item: KebabItem,
): item is KebabMenuButtonWithModal => {
  return (item as KebabMenuButtonWithModal).Modal !== undefined;
};

const KebabMenuItem = ({ item }: { item: KebabItemWithoutModals }) => {
  if (isKebabMenuButton(item)) {
    return (
      <HoverMenuButtonItem
        iconName={item.iconName}
        color={item.color ?? 'normal'}
        disabled={item.disabled}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          item.onClick();
        }}
      >
        {item.label}
      </HoverMenuButtonItem>
    );
  }

  return (
    <HoverMenuLinkItem
      iconName={item.iconName}
      color={item.color ?? 'normal'}
      to={item.href}
      disabled={item.disabled}
    >
      {item.label}
    </HoverMenuLinkItem>
  );
};

const DrawerMenuItem = ({ item }: { item: KebabItemWithoutModals }) => {
  if (isKebabMenuButton(item)) {
    return (
      <DrawerMenuButtonItem
        iconName={item.iconName}
        color={item.color ?? 'normal'}
        onClick={item.onClick}
      >
        {item.label}
      </DrawerMenuButtonItem>
    );
  }

  return (
    <DrawerMenuLinkItem
      iconName={item.iconName}
      color={item.color ?? 'normal'}
      to={item.href}
      disabled={item.disabled}
    >
      {item.label}
    </DrawerMenuLinkItem>
  );
};

const getDrawerMenuItems = (items: KebabItemWithoutModals[]) => {
  return items.filter((item) => !item.hideInDrawer);
};

type KebabMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  enableDrawer?: boolean;
  drawerHeading?: string;
  elementRef: RefObject<HTMLElement>;
  positionStrategy?: PositionStrategyRect;
  items: KebabItem[];
  className?: string;
  usePortal?: boolean;
} & Omit<
  React.ComponentProps<typeof HoverMenu>,
  'children' | 'positionStrategy'
>;

const defaultMenuOffset = (rect: DOMRect): [number, number] => [
  rect.x + rect.width - rect.height / 2,
  rect.y + rect.height / 2,
];

/**
 * Renders a kebab menu.
 *
 * @param isOpen - Whether the menu is open or not.
 * @param onClose - Callback to close the menu.
 * @param enableDrawer - Use a drawer menu on mobile
 * @param drawerHeading - Heading to display on top of the drawer menu.
 * @param elementRef - Ref to the element that the menu is positioned next to
 * @param items - Items to display in the menu.
 * @param positionStrategy - Function to calculate the position of the menu
 */
const KebabMenu = ({
  elementRef,
  isOpen,
  items: hideableItems,
  onClose,
  drawerHeading,
  enableDrawer,
  positionStrategy,
  ...props
}: KebabMenuProps) => {
  const { isMobile } = useBreakpoints();
  const [openModalTitle, setOpenModalTitle] = useState<string | null>(null);

  const items = useMemo(() => {
    return hideableItems.filter((item) => !item.hide);
  }, [hideableItems]);

  const itemsHandlingModals: KebabItemWithoutModals[] = useMemo(() => {
    return items.map((item) => {
      if (isKebabMenuButtonWithModal(item)) {
        return {
          ...item,
          Modal: undefined,
          onClick: () => {
            onClose();
            setOpenModalTitle(item.label);
          },
        };
      }
      return item;
    });
  }, [items, onClose]);

  const getMenuOffset = positionStrategy ?? defaultMenuOffset;

  return (
    <>
      {enableDrawer && isMobile ? (
        <DrawerMenu isOpen={isOpen} onRequestClose={onClose}>
          <div className={styles.drawerMenu__header}>
            <div className={styles.drawerMenu__heading}>{drawerHeading}</div>
            <button
              onClick={onClose}
              className={styles.drawerMenu__closeButton}
            >
              Done
            </button>
          </div>
          <div className={styles.drawerMenu__body}>
            {getDrawerMenuItems(itemsHandlingModals).map((item, i) => (
              <DrawerMenuItem key={i} item={item} />
            ))}
          </div>
        </DrawerMenu>
      ) : (
        <HoverMenu
          {...props}
          elementRef={elementRef}
          onClose={onClose}
          positionStrategy={fromElement(getMenuOffset)}
          isOpen={isOpen}
        >
          {itemsHandlingModals.map((item, i) => (
            <KebabMenuItem key={i} item={item} />
          ))}
        </HoverMenu>
      )}

      {items.filter(isKebabMenuButtonWithModal).map((item, i) => {
        return (
          <item.Modal
            key={i}
            isOpen={openModalTitle === item.label}
            onClose={() => setOpenModalTitle(null)}
          />
        );
      })}
    </>
  );
};

export default KebabMenu;
