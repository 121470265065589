import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function StampIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M0.666748 9.66671V13.6667H11.3334V9.66671C11.3334 8.93337 10.7334 8.33337 10.0001 8.33337H2.00008C1.26675 8.33337 0.666748 8.93337 0.666748 9.66671ZM10.0001 11H2.00008V9.66671H10.0001V11ZM6.00008 0.333374C4.16008 0.333374 2.66675 1.82671 2.66675 3.66671L6.00008 8.33337L9.33342 3.66671C9.33342 1.82671 7.84008 0.333374 6.00008 0.333374ZM6.00008 6.33337L4.00008 3.66671C4.00008 2.56004 4.89341 1.66671 6.00008 1.66671C7.10675 1.66671 8.00008 2.56004 8.00008 3.66671L6.00008 6.33337Z"
        fill="#555770"
      />
    </svg>
  );
}
