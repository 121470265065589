import { ChartBlock, createChartBlock } from 'editor-content/ChartBlock.ts';
import { dataUrlToFile } from './dataUrlToFile.ts';
import { TableBlock, tableCellUncompress } from 'editor-content/TableBlock.ts';
import { AiChartFlowProps, AiChartFlowState } from './AiChartFlow.tsx';
import useApi from '../../../../api/useApi.ts';
import { useCallback, useState } from 'react';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import { AiContext } from './createAiContext.ts';
import { ASSET_CHART_ARTIFACT_TYPE } from 'api-client/assetContract.ts';

type GenerateAiChartFlowProps = {
  onInsertChartBlock: (chart: ChartBlock) => void;
  tableBlock: TableBlock;
  selection: ContentSelection | null;
  aiContext: AiContext;
  setAddingChart: (value: boolean) => void;
};

export type GenerateAiChartFlowResult = AiChartFlowProps & {
  forceTableSelection: boolean;
  isChartWizardVisible: boolean;
};

export const useGenerateAiChartFlowProps = ({
  onInsertChartBlock,
  tableBlock,
  selection,
  aiContext,
  setAddingChart,
}: GenerateAiChartFlowProps): GenerateAiChartFlowResult => {
  // TODO: does this belong here?
  const { aiChartGenerate, addZeckEvent, createAsset, uploadAsset } = useApi();

  const [chartState, setChartState] = useState<AiChartFlowState>({
    type: 'prompting',
  });

  const [forceTableSelection, setForceTableSelection] = useState(false);

  const generateDataArray = () => {
    return tableBlock.data.rows.map((row) =>
      row.cells.map(
        (cell) => tableCellUncompress(cell).content?.[0]?.text ?? '',
      ),
    );
  };

  const isBlockSelected = selection !== null;
  const prompting = chartState.type === 'prompting';

  const isChartWizardVisible = prompting ? isBlockSelected : true;

  const onClose = useCallback(() => {
    setAddingChart(false);
    setChartState({
      type: 'prompting', // initial state reset
    });
    setForceTableSelection(false);
  }, [setAddingChart, setChartState, setForceTableSelection]);

  return {
    company: aiContext.company,
    generateAiChart: async (currentMessage: string) =>
      aiChartGenerate({
        data: generateDataArray(),
        currentMessage,
        companyId: aiContext.company.id,
        sectionId: aiContext.sectionId,
        userId: aiContext.userId,
      }),
    forceTableSelection,
    setForceTableSelection,
    onClose,
    recordAnalytics: async (type: 'accept' | 'discard') => {
      await addZeckEvent({
        userId: aiContext.userId,
        companyId: aiContext.company.id,
        contentType: 'SECTION',
        contentTypeId: aiContext.sectionId,
        eventType: type === 'accept' ? 'AI_CHART_ACCEPT' : 'AI_CHART_DISCARD',
        payload: {
          tableBlockId: tableBlock.id,
        },
        firedAt: new Date().getTime(),
      });
    },
    onAccept: async (chartData, base64ImageData) => {
      const { assetId, writeUrl } = await createAsset({
        companyId: aiContext.company.id,
        assetType: ASSET_CHART_ARTIFACT_TYPE,
      });
      const file = await dataUrlToFile(base64ImageData, 'chart.png');
      await uploadAsset(writeUrl, file);

      const chartBlock = createChartBlock(
        assetId,
        chartData,
        generateDataArray(),
      );

      onInsertChartBlock(chartBlock);
    },
    chartState,
    setChartState,
    isChartWizardVisible,
  };
};
