import styles from './PeopleListContainer.module.scss';
import cx from 'classnames';
import WithTooltip from '../../design-system/organisms/WithTooltip.tsx';
import { Icon } from 'icons';

type HeaderCellConfig = {
  label: string;
  tooltip: string | null;
};

type PeopleListContainerProps = {
  headers: HeaderCellConfig[];
  children: React.ReactNode;
  isMobile: boolean;
};

export const PeopleListContainer: React.FunctionComponent<
  PeopleListContainerProps
> = ({ headers, children, isMobile }) => {
  return (
    <table
      data-testid="people-list"
      className={cx(styles.peopleList, isMobile && styles.peopleList__mobile)}
    >
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th
              className={cx(
                styles.peopleList__cell,
                styles.peopleList__cellHeader,
              )}
              key={i}
            >
              <div className={styles.peopleList__cellHeaderInner}>
                {header.label}
                {header.tooltip && (
                  <WithTooltip<HTMLDivElement> text={header.tooltip}>
                    {(ref, listeners) => (
                      <div
                        className={styles.headerTooltipTrigger}
                        ref={ref}
                        {...listeners}
                      >
                        <Icon name="help" />
                      </div>
                    )}
                  </WithTooltip>
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
