import timeZones from './timeZones.json';
import { getTimezoneOffset } from 'date-fns-tz';
import {
  hoursToMinutes,
  millisecondsToHours,
  millisecondsToMinutes,
} from 'date-fns';
import { Picker, PickerItem } from './Picker.tsx';
import { useId } from 'react';
import styles from './TimeZonePicker.module.scss';

function formatTimeZoneName(name: string) {
  const match = name.match(/^(\w*)\/(.*)$/);
  if (!match) return name;
  const region = match[1];
  const city = match[2] || '';
  const cityWithSpaces = city.replaceAll('_', ' ');

  return `${region} - ${cityWithSpaces}`;
}

type TimeZoneItemProps = {
  name: string;
};

const TimeZoneItem: React.FC<TimeZoneItemProps> = ({ name }) => {
  const timezoneOffsetMs = getTimezoneOffset(name);
  const offsetHours = millisecondsToHours(timezoneOffsetMs);
  const offsetMinutes =
    millisecondsToMinutes(timezoneOffsetMs) - hoursToMinutes(offsetHours);

  return (
    <div>
      (GMT{offsetHours >= 0 ? '+' : ''}
      {offsetHours}:{`${offsetMinutes}`.padStart(2, '0')}){' '}
      {formatTimeZoneName(name)}
    </div>
  );
};

type TimeZonePickerProps = {
  value: string;
  onChange: (newValue: string) => void;
};

const TimeZonePicker: React.FC<TimeZonePickerProps> = ({ value, onChange }) => {
  const radioGroupName = useId();

  const specialTimeZones = [
    'America/Detroit',
    'America/New_York',
    'America/Chicago',
    'America/Boise',
    'America/Los_Angeles',
  ];

  return (
    <div className={styles.timeZonePicker}>
      <Picker>
        {specialTimeZones.map((name) => (
          <PickerItem
            key={name}
            radioGroupName={radioGroupName}
            onSelect={() => onChange(name)}
            selected={value === name}
          >
            <TimeZoneItem name={name} />
          </PickerItem>
        ))}
        <hr />
        {timeZones.map((name) => (
          <PickerItem
            key={name}
            radioGroupName={radioGroupName}
            onSelect={() => onChange(name)}
            selected={value === name}
          >
            <TimeZoneItem name={name} />
          </PickerItem>
        ))}
      </Picker>
    </div>
  );
};

export default TimeZonePicker;
