import React from 'react';
import styles from './DeleteConfirmationModal.module.scss';
import Modal, {
  ModalActions,
  ModalHeader,
} from '../../../../design-system/organisms/Modal.tsx';
import Button from '../../../../design-system/atoms/Button.tsx';
import OutlineButton from '../../../../design-system/atoms/OutlineButton.tsx';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';

type DeleteConfirmationModalProps = {
  onDelete: () => void;
  onCancelDelete: () => void;
  showConfirmation: boolean;
};

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  onDelete,
  onCancelDelete,
  showConfirmation,
}) => {
  const handleCancelKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onCancelDelete();
      e.stopPropagation();
    }
  };

  const handleConfirmKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onDelete();
      e.stopPropagation();
    }
  };

  return (
    <Modal
      isOpen={showConfirmation}
      className={styles.deleteConfirmationModal__modal}
    >
      <ModalHeader className={styles.deleteConfirmationModal__header}>
        <div>Delete Agenda?</div>
        <div>
          <IconButton
            name="close"
            aria-label="close modal"
            onClick={onCancelDelete}
            onKeyDown={handleCancelKeyDown}
          />
        </div>
      </ModalHeader>
      <ModalActions>
        <OutlineButton
          size={'medium'}
          color={'secondary'}
          onClick={onCancelDelete}
          onKeyDown={handleCancelKeyDown}
        >
          Cancel
        </OutlineButton>
        <Button
          size={'medium'}
          color={'danger'}
          onClick={onDelete}
          onKeyDown={handleConfirmKeyDown}
          ref={(e) => e?.focus()}
        >
          Yes, Delete
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteConfirmationModal;
