import BaseTextBlock from 'editor-content/BaseTextBlock.js';
import ContentSelection, {
  contentSelection,
} from '../../selection/contentSelection/ContentSelection.js';
import getTextBlockLength from './getTextBlockLength.js';

export default function textBlockGetEndOfBlockSelectionStrategy(
  block: BaseTextBlock,
): ContentSelection {
  return contentSelection(getTextBlockLength(block));
}
