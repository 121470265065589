import ReactModal from 'react-modal';
import styles from './DrawerMenu.module.scss';
import { Icon, IconName } from 'icons';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

type DrawerMenuProps = {
  isOpen: boolean;
  onRequestClose(): void;
  children: ReactModal.Props['children'];
};

const DrawerMenu = ({
  isOpen,
  onRequestClose,
  children,
  ...otherProps
}: DrawerMenuProps) => {
  return (
    <ReactModal
      {...{
        ...otherProps,
        isOpen,
        onRequestClose,
        closeTimeoutMS: !import.meta.env.TEST ? 200 : undefined,
        className: {
          base: styles.drawerMenu,
          afterOpen: styles.drawerMenu_afterOpen,
          beforeClose: styles.drawerMenu_beforeClose,
        } as ReactModal.Classes,
        overlayClassName: {
          base: styles.drawerMenu__overlay,
          afterOpen: styles.drawerMenu__overlay_afterOpen,
          beforeClose: styles.drawerMenu__overlay_beforeClose,
        } as ReactModal.Classes,
        ariaHideApp: false,
      }}
    >
      {children}
    </ReactModal>
  );
};

type DrawerMenuItemProps = {
  children: React.ReactNode;
  iconName: IconName;
  className?: string;
  color: 'primary' | 'danger' | 'normal';
  disabled?: boolean;
};

type DrawerMenuButtonItemProps = {
  onClick(e: React.MouseEvent): void;
} & DrawerMenuItemProps;

const colorToClassName = (color: DrawerMenuButtonItemProps['color']) => {
  switch (color) {
    case 'primary':
      return styles.drawerMenuItem_primary;
    case 'danger':
      return styles.drawerMenuItem_danger;
    case 'normal':
      return null;
  }
};

export const DrawerMenuButtonItem = ({
  iconName,
  children,
  onClick,
  className,
  color,
  disabled,
  ...otherProps
}: DrawerMenuButtonItemProps) => {
  return (
    <button
      className={cx(styles.drawerMenuItem, colorToClassName(color), className)}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      <Icon name={iconName} className={styles.drawerMenuItem__icon} />
      {children}
    </button>
  );
};

type DrawerMenuBigButtonItemProps = {
  children: React.ReactNode;
  className?: string;
  color: 'primary' | 'danger' | 'normal';
  disabled?: boolean;
  onClick: () => void;
};

export const DrawerMenuBigButtonItem = ({
  children,
  onClick,
  className,
  color,
  disabled,
}: DrawerMenuBigButtonItemProps) => {
  return (
    <button
      className={cx(
        styles.drawerMenuItem,
        styles.drawerMenuBigButtonItem,
        colorToClassName(color),
        className,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

type DrawerMenuLinkItemProps = {
  to: string;
} & DrawerMenuItemProps;

export const DrawerMenuLinkItem = ({
  iconName,
  children,
  className,
  color,
  to,
  disabled,
}: DrawerMenuLinkItemProps) => {
  const navigate = useNavigate();

  if (disabled) {
    return (
      <div
        className={cx(
          styles.drawerMenuItem,
          styles.drawerMenuItem_disabled,
          className,
        )}
      >
        <Icon name={iconName} className={styles.drawerMenuItem__icon} />
        {children}
      </div>
    );
  }

  return (
    <a
      href={to}
      className={cx(styles.drawerMenuItem, colorToClassName(color), className)}
      onClick={(e) => {
        navigate(to);
        e.preventDefault();
      }}
    >
      <Icon name={iconName} className={styles.drawerMenuItem__icon} />
      {children}
    </a>
  );
};

export default DrawerMenu;
