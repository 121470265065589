import { User } from 'api-client/types.js';
import { CompanyLite } from '../types/CompanyLite.js';
import { makeMakeApiRequest } from './makeMakeApiRequest.js';

const createUserApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  getUser: async (): Promise<User> => {
    return await makeLambdaApiRequest(`/user`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  updateUser: async (firstName: string, lastName: string) => {
    return await makeLambdaApiRequest(`/user`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        user: {
          firstName,
          lastName,
        },
      }),
    });
  },
  getUserCompanies: async (): Promise<CompanyLite[]> => {
    const { companies } = await makeLambdaApiRequest('/user/companies', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return companies;
  },
});

export default createUserApi;
