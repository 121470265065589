import { RefObject, useRef, MouseEvent } from 'react';

const useAllowsInsideClick = <E extends HTMLElement>(
  onClick: (e: MouseEvent<E>) => void,
): [RefObject<E>, (e: MouseEvent<E>) => void] => {
  const ref = useRef<E>(null);

  return [
    ref,
    (e: MouseEvent<E>) => {
      const containerEl = ref.current;

      if (
        containerEl &&
        e.target instanceof Element &&
        e.target.closest('a,button,input') !== containerEl
      ) {
        e.preventDefault();

        return;
      }

      onClick(e);
    },
  ];
};

export default useAllowsInsideClick;
