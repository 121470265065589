import {
  FileBlock,
  ImageBlock,
  isFileBlock,
  isImageBlock,
  isTextBlock,
  TextBlock,
} from 'editor-content/Block.js';
import { isTableBlock, TableBlock } from 'editor-content/TableBlock.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';

export const hydratedIsTextBlock = (block: HydratedBlock): block is TextBlock =>
  isTextBlock(block);
export const hydratedIsImageBlock = (
  block: HydratedBlock,
): block is ImageBlock => isImageBlock(block);
export const hydratedIsFileBlock = (block: HydratedBlock): block is FileBlock =>
  isFileBlock(block);
export const hydratedIsTableBlock = (
  block: HydratedBlock,
): block is TableBlock => isTableBlock(block);
