import Point from '../../domHelpers/Point.js';
import Rect from '../../domHelpers/Rect.js';
import { cubic, toUnit } from './interpolation.js';

/**
 * Creates virtual scroll areas at the top and bottom 10% of the scrollContainer.
 * Returns scroll amount in positive or negative unit interval [-1,1] (cubic easing)
 * or null if no scroll.
 *
 *
 * @param scrollContainerRect
 * @param mousePos
 */
const getScrollNeeded = (
  scrollContainerRect: Rect,
  mousePos: Point,
): number | null => {
  const scrollContainerTop = scrollContainerRect[0][1];
  const scrollContainerBottom = scrollContainerRect[1][1];

  const scrollRegionHeight = 0.1 * (scrollContainerBottom - scrollContainerTop);

  const scrollUpRegionBoundaryY = scrollRegionHeight + scrollContainerTop;

  const scrollDownRegionBoundaryY = scrollContainerBottom - scrollRegionHeight;

  const mousePosY = mousePos[1];

  if (mousePosY <= scrollUpRegionBoundaryY) {
    return -cubic(
      0,
      1,
      toUnit(scrollUpRegionBoundaryY, scrollContainerTop, mousePosY),
    );
  }

  if (mousePosY >= scrollDownRegionBoundaryY) {
    return cubic(
      0,
      1,
      toUnit(scrollDownRegionBoundaryY, scrollContainerBottom, mousePosY),
    );
  }

  return null;
};

export default getScrollNeeded;
