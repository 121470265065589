import { useEffect, useRef, useState } from 'react';
import styles from './TransparentInput.module.scss';
import cx from 'classnames';
import {
  handleKey,
  stopPropagation,
} from '../../editor/domFacing/events/isKeyMatch.ts';

type TransparentInputProps = {
  value: string;
  className?: string;
  onSubmit(v: string): void;
  'aria-label': string;
} & Omit<
  React.ComponentProps<'input'>,
  'value' | 'onChange' | 'onBlur' | 'onKeyDown' | 'type' | 'onSubmit'
>;

const TransparentInput = ({
  value,
  onSubmit,
  className,
  ...otherProps
}: TransparentInputProps) => {
  const [currentValue, onChange] = useState(value);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.select();
  }, []);

  return (
    <input
      {...otherProps}
      type="text"
      ref={inputRef}
      value={currentValue}
      data-testid="transparent-input"
      onChange={(e) => {
        onChange(e.currentTarget.value);
      }}
      onBlur={() => {
        onSubmit(currentValue);
      }}
      onKeyDown={handleKey(
        { key: 'Enter' },
        stopPropagation(() => onSubmit(currentValue)),
      )}
      // allow selecting text without triggering drag-n-drop
      onMouseDown={(e) => e.stopPropagation()}
      className={cx(styles.TransparentInput, className)}
    />
  );
};

export default TransparentInput;
