import React, { SVGProps } from 'react';

export function OneDriveIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_12579_272340)">
        <path
          d="M14.4992 10.3754L19.4527 5.63828C18.4238 1.62617 14.3373 -0.792063 10.3251 0.23693C8.51847 0.700277 6.94927 1.82022 5.92383 3.37819C6.00041 3.37631 14.4992 10.3754 14.4992 10.3754Z"
          fill="#0364B8"
        />
        <path
          d="M9.15177 4.2702L9.15158 4.27104C8.20506 3.68481 7.11347 3.37495 6.00011 3.37647C5.97442 3.37647 5.94911 3.37806 5.92343 3.37834C2.61185 3.4194 -0.0395198 6.13732 0.00144493 9.449C0.0163215 10.6502 0.391657 11.8193 1.07881 12.8047L8.4998 11.8753L14.1893 7.28762L9.15177 4.2702Z"
          fill="#0078D4"
        />
        <path
          d="M19.4524 5.63826C19.3429 5.6305 19.2332 5.62641 19.1234 5.62598C18.4589 5.62533 17.8013 5.76106 17.1914 6.02475L17.1912 6.02428L14.1895 7.28744L17.4987 11.3752L23.4147 12.8133C24.6914 10.4432 23.805 7.48692 21.4349 6.21017C20.8224 5.8803 20.1465 5.68523 19.4524 5.63826Z"
          fill="#1490DF"
        />
        <path
          d="M1.0791 12.8046C1.63115 13.5983 2.3671 14.2466 3.22409 14.6941C4.08109 15.1417 5.03367 15.3752 6.0005 15.3747H19.1236C20.0035 15.3749 20.8671 15.1369 21.6226 14.686C22.3781 14.235 22.9975 13.5879 23.4149 12.8134L14.1896 7.28735L1.0791 12.8046Z"
          fill="#28A8EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_12579_272340">
          <rect width="24" height="15.4672" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
