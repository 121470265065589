import styles from './VoteResult.module.scss';
import { Icon } from 'icons';
import cx from 'classnames';
import formatVotedAt from './formatVotedAt.ts';
import EditVoteModal from '../zeck/voting/EditVoteModal.tsx';
import ConfirmEditVoteModal from '../zeck/voting/ConfirmEditVoteModal.tsx';
import { useState } from 'react';
import useApi from '../../api/useApi.ts';
import Vote from '../../types/Vote.ts';
import useBreakpoints from '../../services/useBreakpoints.ts';
import VoteResultMenu from './VoteResultMenu.tsx';
import ConfirmDeleteVoteModal from '../zeck/voting/ConfirmDeleteVoteModal.tsx';

type VoteResultProps = {
  voteId: string;
  sectionId: string;
  companyId: string;
  title: string;
  details: string;
  approved: boolean;
  votedAt: number;
  onVoteUpdated: (updatedVote: Vote) => void;
  onVoteDeleted: (deletedVoteId: string) => void;
};

const VoteResult: React.FunctionComponent<VoteResultProps> = ({
  voteId,
  sectionId,
  companyId,
  title,
  details,
  approved,
  votedAt,
  onVoteUpdated,
  onVoteDeleted,
}) => {
  const { deleteVote, updateVote } = useApi();

  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [pendingEdits, setPendingEdits] = useState<null | {
    title: string;
    details: string;
    approved: boolean;
  }>(null);

  const { isMobile } = useBreakpoints();

  const showEditMenu = !isMobile;

  const onEditVote = () => {
    setEditing(true);
  };

  const onDeleteVote = () => {
    setDeleting(true);
  };

  return (
    <div className={styles.voteResult}>
      <div className={styles.voteResult__heading}>
        <div className={styles.voteResult__titleAndIcon}>
          <Icon name="vote" className={styles.voteResult__headingIcon} />
          <div className={styles.voteResult__title}>{title || 'untitled'}</div>
        </div>
        {showEditMenu && (
          <VoteResultMenu onEditVote={onEditVote} onDeleteVote={onDeleteVote} />
        )}
      </div>
      <div className={styles.voteResult__body}>
        {details && <div className={styles.voteResult__details}>{details}</div>}
        <div className={styles.voteResult__footer}>
          <div className={styles.voteResult__resultAndVotedAt}>
            {approved ? (
              <div
                className={cx(
                  styles.voteResult__pill,
                  styles.voteResult__pill_approved,
                )}
              >
                approved
              </div>
            ) : (
              <div
                className={cx(
                  styles.voteResult__pill,
                  styles.voteResult__pill_notApproved,
                )}
              >
                not approved
              </div>
            )}
            <div className={styles.voteResult__votedAt}>
              {formatVotedAt(votedAt)}
            </div>
          </div>
        </div>
      </div>
      <EditVoteModal
        isOpen={editing}
        onClose={() => {
          setEditing(false);
        }}
        voteTitle={title}
        voteApproved={approved}
        voteDetails={details}
        onSave={(edits) => {
          setEditing(false);
          setPendingEdits(edits);
        }}
      />
      <ConfirmEditVoteModal
        isOpen={Boolean(pendingEdits)}
        onCancel={() => {
          setPendingEdits(null);
        }}
        onSave={async () => {
          setPendingEdits(null);
          if (!pendingEdits) {
            return;
          }
          const updatedVote = await updateVote({
            voteId,
            sectionId,
            title: pendingEdits.title,
            details: pendingEdits.details,
            approved: pendingEdits.approved,
          });
          onVoteUpdated(updatedVote);
        }}
      />
      <ConfirmDeleteVoteModal
        isOpen={deleting}
        onCancel={() => {
          setDeleting(false);
        }}
        onConfirm={async () => {
          await deleteVote({
            voteId,
            companyId,
          });
          onVoteDeleted(voteId);
          setDeleting(false);
        }}
      />
    </div>
  );
};

export default VoteResult;
