import styles from './ManagementPage.module.scss';
import cx from 'classnames';
import useBreakpoints from '../services/useBreakpoints.ts';
import Link from '../design-system/atoms/Link.tsx';

type ManagementPageProps = {
  title: string;
  children: React.ReactNode;
};

export default function ManagementPage({
  title,
  children,
}: ManagementPageProps) {
  return (
    <ManagementPageShell>
      <ManagementPageTitle title={title} />
      <ManagementPageContent>{children}</ManagementPageContent>
    </ManagementPageShell>
  );
}

type ManagementPageShellProps = {
  children: React.ReactNode;
};

export function ManagementPageShell({ children }: ManagementPageShellProps) {
  const { isMobile } = useBreakpoints();
  return (
    <div
      className={cx(
        styles.managementPage,
        isMobile && styles.managementPage__mobile,
      )}
      data-testid="management-page-content"
    >
      {children}
    </div>
  );
}

type ManagementPageTitleProps = {
  title: string;
  className?: string;
};

export function ManagementPageTitle({
  title,
  className,
}: ManagementPageTitleProps) {
  const { isMobile } = useBreakpoints();
  if (isMobile) {
    return null;
  }

  return (
    <h1 className={cx(styles.managementPage__title, className)}>{title}</h1>
  );
}

export type ManagementPageTab = {
  name: string;
} & (
  | {
      active: true;
    }
  | {
      active?: false;
      link: string;
    }
);

export function ManagementPageTabs({ tabs }: { tabs: ManagementPageTab[] }) {
  return (
    <div
      data-testid="management-page-toolbar"
      className={styles.managementPage__tabbar}
    >
      {tabs.map((tab) => {
        return (
          <div
            key={tab.name}
            className={cx(
              styles.managementPage__tab,
              tab.active && styles.managementPage__tabbar__active,
            )}
          >
            {tab.active ? tab.name : <Link href={tab.link}>{tab.name}</Link>}
          </div>
        );
      })}
    </div>
  );
}

type ManagementPageContentProps = {
  children: React.ReactNode;
  className?: string;
};

export function ManagementPageContent({
  children,
  className,
}: ManagementPageContentProps) {
  return (
    <div className={cx(styles.managementPage__content, className)}>
      {children}
    </div>
  );
}
