import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import isTouchDeviceFlow from '../../services/isTouchDeviceFlow.js';
import useLocalStorageState from '../../services/useLocalStorageState.ts';
import TouchDeviceWarningModal from './TouchDeviceWarningModal.tsx';

const transformEditPathToPreview = (path: string) => {
  if (path.endsWith('/cover/edit')) {
    return path.replace(/\/cover\/edit$/, '/preview');
  }

  return path.replace(/\/edit$/, '/preview');
};

const withTouchRedirect =
  <P extends { [key: string]: unknown }>(Wrapped: React.ComponentType<P>) =>
  (props: P) => {
    const location = useLocation();
    const [hasSeenWarning, setHasSeenWarning] = useLocalStorageState(
      'seenEditorTouchDeviceWarning',
    );
    const [isWarningOpen, setIsWarningOpen] = useState(
      hasSeenWarning !== 'yes',
    );

    if (isTouchDeviceFlow()) {
      if (isWiderThan(800)) {
        return (
          <>
            <Wrapped {...props} />
            <TouchDeviceWarningModal
              isOpen={isWarningOpen}
              onClose={() => {
                setHasSeenWarning('yes');
                setIsWarningOpen(false);
              }}
            />
          </>
        );
      } else {
        return <Navigate to={transformEditPathToPreview(location.pathname)} />;
      }
    } else {
      return <Wrapped {...props} />;
    }
  };

export default withTouchRedirect;

export function isWiderThan(width: number) {
  return window.matchMedia(`(min-width: ${width}px)`).matches;
}
