import { Company } from '../../../types.ts';
import { useState } from 'react';
import Modal from '../../../design-system/organisms/Modal.tsx';
import FileInput from '../../../design-system/atoms/FileInput.tsx';
import styles from './FileUploadModal.module.scss';
import Spinner from '../../../design-system/atoms/Spinner.tsx';
import useApi from '../../../api/useApi.ts';

type FileUploadModalProps = {
  company: Pick<Company, 'id'>;
  onUploadSuccess(file: { guid: string; filename: string }): void;
  isOpen: boolean;
  onRequestClose(): void;
};

export default function FileUploadModal({
  isOpen,
  company,
  onUploadSuccess,
  onRequestClose,
}: FileUploadModalProps) {
  const { createFile, uploadFile } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <Modal
      isOpen={isOpen}
      dark
      onRequestClose={onRequestClose}
      className={styles.fileUploadModal}
    >
      {isLoading ? (
        <div>
          <Spinner className={styles.fileUploadModal__waitSpinner} />
          <p className={styles.fileUploadModal__waitInstructions}>
            Please wait until the file upload is complete before navigating to
            another page or closing the browser. Doing so will interrupt the
            upload.
          </p>
        </div>
      ) : (
        <div>
          {errorMessage && (
            <p className={styles.fileUploadModal__errorMessage}>
              {errorMessage}
            </p>
          )}
          <FileInput
            onChange={async (e) => {
              setErrorMessage(null);

              const file = e.currentTarget.files && e.currentTarget.files[0];

              if (file) {
                if (file.size > 2 ** 20 * 500) {
                  setErrorMessage(
                    `Your file is too large. Files must be 500 MB or less. Can you choose another file or reach out to us and we'll be ready to help?`,
                  );
                  return;
                }
                setIsLoading(true);
                const filename = file.name;

                const { fileId, writeUrl } = await createFile({
                  companyId: company.id,
                  filename,
                  contentType: file.type,
                });

                try {
                  await uploadFile(writeUrl, file);
                } catch (e) {
                  setErrorMessage(
                    'There was an issue uploading this file, please try again.',
                  );
                  setIsLoading(false);
                  return;
                }
                setIsLoading(false);

                onUploadSuccess({
                  guid: fileId,
                  filename: filename,
                });
              }
            }}
          >
            Choose File
          </FileInput>
        </div>
      )}
    </Modal>
  );
}
