import styles from './Table.module.scss';
import cx from 'classnames';

type TableCellProps = React.ComponentProps<'div'> & { alignRight?: boolean };

export const TableCell: React.FC<TableCellProps> = ({
  children,
  alignRight,
  className,
  ...props
}) => (
  <div
    {...props}
    className={cx(
      styles.table__cell,
      alignRight ? styles.table__cell__aligned_right : undefined,
      className,
    )}
  >
    {children}
  </div>
);

export const TableHeaderCell: React.FC<TableCellProps> = ({
  children,
  alignRight,
  className,
  ...props
}) => (
  <TableCell
    {...props}
    className={cx(
      styles.table__headerCell,
      alignRight ? styles.table__headerCell__aligned_right : undefined,
      className,
    )}
  >
    {children}
  </TableCell>
);

export const Table: React.FC<React.ComponentProps<'div'>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div {...props} className={cx(styles.table, className)}>
      {children}
    </div>
  );
};
