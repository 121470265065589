import { useRef, useState } from 'react';
import styles from './TimeInput.module.scss';
import { Icon } from 'icons';
import cx from 'classnames';
import { formatMinutesFromMidnight, getSystemTimezone } from './timestamp.ts';
import TimeForm from './TimeForm.tsx';
import HoverNextToElement from '../domHelpers/hoverNextTo/HoverNextToElement.tsx';
import { leftAlignedBelowElement } from '../domHelpers/hoverNextTo/positionStrategy/leftAlignedBelow.js';

export type TimeInputProps = {
  label: string;
  time: number | null;
  timeZone: string | null;
  onChange: (values: { time: number | null; timeZone: string }) => void;
};

export default function TimeInput({
  label,
  time,
  timeZone,
  onChange,
}: TimeInputProps) {
  const defaultedTimeZone = timeZone || getSystemTimezone();

  const [isOpen, setIsOpen] = useState(false);
  const timeButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className={styles.container}>
      <button
        ref={timeButtonRef}
        aria-label={label}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
        className={cx(styles.button, time === null && styles.empty)}
      >
        {time === null ? label : formatMinutesFromMidnight(time)}
      </button>

      <button
        className={styles.deleteButton}
        aria-label="unset time"
        onClick={() =>
          onChange({
            time: null,
            timeZone: defaultedTimeZone,
          })
        }
      >
        <Icon name="delete" />
      </button>

      {isOpen && (
        <HoverNextToElement
          elementRef={timeButtonRef}
          viewportPolicy="none"
          positionStrategy={leftAlignedBelowElement}
        >
          <TimeForm
            time={time}
            timeZone={timeZone}
            onSubmit={(values) => {
              onChange(values);
              setIsOpen(false);
            }}
            onCancel={() => setIsOpen(false)}
          />
        </HoverNextToElement>
      )}
    </div>
  );
}
