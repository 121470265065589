import isEmpty from 'lodash/isEmpty.js';
import pick from 'lodash/pick.js';
import pickBy from 'lodash/pickBy.js';
import identity from 'lodash/identity.js';
import { baseBlock, createBlock } from './BaseBlock.js';
import { Block } from './Block.js';
import { Editor, Text } from './TextNode.js';

export type TableBlock = {
  type: 'table';
  id: string;
  data: {
    rows: TableBlockRow[];
    merges?: TableBlockMerge[];
    columnWidths?: Record<string, number>;
    rowHeights?: Record<string, number>;
  };
  integrationId?: string;
};

export type ExternalSelectionType = 'sheet';

export type TableBlockMerge = {
  startRow: number;
  startColumn: number;
  columnSpan: number;
  rowSpan: number;
};

export type TableBlockRow = {
  type: 'table-row';
  cells: TableBlockCell[];
};

export type TableBlockCellUncompressed = {
  type: 'table-cell';
  content: Editor.Text[];
  format?: TableBlockCellFormat;
};

export type TableBlockCellString = string;

export type TableBlockCell = TableBlockCellUncompressed | TableBlockCellString;

export type TableBorderType = 'thin' | 'thick' | undefined;

export type TableBlockCellBorderFormat = {
  top?: TableBorderType;
  bottom?: TableBorderType;
  left?: TableBorderType;
  right?: TableBorderType;
};

export type TableBlockCellFormat = {
  alignHorizontal?: 'left' | 'right' | 'center';
  alignVertical?: 'top' | 'middle' | 'bottom';
  wrap?: 'wrap' | 'clip';
  border?: TableBlockCellBorderFormat;
  bgColor?: string;
};

export const TableBlock = (
  id: string,
  data: TableBlock['data'],
  integrationId?: string,
): TableBlock =>
  baseBlock('table', id, {
    integrationId,
    data,
  });

export const TableBlockRow = (
  cells: TableBlockRow['cells'],
): TableBlockRow => ({
  type: 'table-row',
  cells,
});

export const TableBlockCell = (
  value: TableBlockCellUncompressed['content'],
  format?: TableBlockCellFormat,
): TableBlockCellUncompressed => ({
  type: 'table-cell',
  content: value,
  format,
});

export const createTableBlock = (
  data: TableBlock['data'],
  integrationId?: string,
): TableBlock =>
  createBlock('table', {
    integrationId,
    data,
  });

export const createTableBlockRowFromStrings = (
  values: string[],
): TableBlockRow =>
  TableBlockRow(values.map((content) => TableBlockCell([Text(content)])));

export const isTableBlock = (block: Block): block is TableBlock =>
  block.type === 'table';

export const tableCellIsString = (
  cell: TableBlockCell,
): cell is TableBlockCellString => typeof cell === 'string';

const deleteFalsyFields = (obj: object | undefined): object => {
  return pickBy(obj, identity);
};

const compressTextNode = (input: Editor.Text): Editor.Text => {
  const effectiveFormat: Editor.Text['format'] = deleteFalsyFields(
    input.format,
  );

  if (isEmpty(effectiveFormat)) {
    return {
      type: input.type,
      text: input.text,
    };
  }

  return { ...input, format: effectiveFormat };
};

const addNonDefaultValueToCurrent = <T>(
  input: T,
  current: T,
  key: string,
  defaultValue: string,
): T => {
  return {
    ...current,
    ...pickBy(
      pick(input, [key]),
      (val) => identity(val) && val !== defaultValue,
    ),
  };
};

const compressCellBorder = (
  border?: TableBlockCellBorderFormat,
): TableBlockCellBorderFormat => {
  return deleteFalsyFields(border);
};

const compressCellFormat = (
  format?: TableBlockCellFormat,
): TableBlockCellFormat => {
  if (!format) {
    return {};
  }

  let effectiveFormat: TableBlockCellFormat = {};

  const effectiveBorder = compressCellBorder(format?.border);

  if (!isEmpty(effectiveBorder)) {
    effectiveFormat.border = effectiveBorder;
  }

  effectiveFormat = addNonDefaultValueToCurrent(
    format,
    effectiveFormat,
    'wrap',
    'clip',
  );
  effectiveFormat = addNonDefaultValueToCurrent(
    format,
    effectiveFormat,
    'alignHorizontal',
    'left',
  );
  effectiveFormat = addNonDefaultValueToCurrent(
    format,
    effectiveFormat,
    'alignVertical',
    'bottom',
  );

  return effectiveFormat;
};

const compressCellContents = (
  contents: TableBlockCellUncompressed['content'],
): TableBlockCellUncompressed['content'] => {
  return contents.map((textNode) => compressTextNode(textNode));
};

export const tableCellCompress = (cell: TableBlockCell): TableBlockCell => {
  if (tableCellIsString(cell)) {
    return cell;
  }

  const compressedCellContent = compressCellContents(cell.content);
  const compressedCellFormat = compressCellFormat(cell.format);

  if (!isEmpty(compressedCellFormat)) {
    return {
      type: 'table-cell',
      content: compressedCellContent,
      format: compressedCellFormat,
    };
  }

  const hasTextFormatting =
    compressedCellContent.filter((textNode) => !isEmpty(textNode.format))
      .length > 0;

  if (hasTextFormatting || cell.content.length > 1) {
    return {
      type: 'table-cell',
      content: compressedCellContent,
    };
  }

  // return string
  return cell.content?.[0]?.text ?? '';
};

const compressTableBlockRow = (row: TableBlockRow): TableBlockRow => {
  return {
    ...row,
    cells: row.cells.map(tableCellCompress),
  };
};

export const compressTableBlock = (block: TableBlock): TableBlock => {
  return {
    ...block,
    data: {
      ...block.data,
      rows: block.data.rows.map(compressTableBlockRow),
    },
  };
};

export const tableCellUncompress = (
  cell: TableBlockCell,
): TableBlockCellUncompressed => {
  const defaultFormatting = {
    alignHorizontal: 'left' as const,
    alignVertical: 'bottom' as const,
    wrap: 'clip' as const,
  };

  if (tableCellIsString(cell)) {
    return TableBlockCell([Text(cell)], defaultFormatting);
  }

  return TableBlockCell(cell.content, {
    ...defaultFormatting,
    ...(cell.format || {}),
  });
};

export const MAX_TABLE_COLUMNS = 30;
export const MAX_TABLE_ROWS = 150;
