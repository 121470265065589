import {
  TableBlock,
  TableBlockCellFormat,
  tableCellUncompress,
} from 'editor-content/TableBlock.ts';
import { textToHtmlString } from 'editor-content/textNodesToHtmlString.js';

export type FormattedTableCell = {
  content: string;
  format?: TableBlockCellFormat;
  colspan?: number;
  rowspan?: number;
  style?: { minWidth: string; maxWidth: string };
};

export type FormattedTableRow = {
  cells: FormattedTableCell[];
  style?: { height: string };
};

export type FormattedTableData = {
  rows: FormattedTableRow[];
};

const isFormattedCell = (
  cell: FormattedTableCell | null,
): cell is FormattedTableCell => cell !== null;

const formattedTableDataFromBlock = (
  tableBlock: TableBlock,
): FormattedTableData => {
  const formattedRows = tableBlock.data.rows.map((row, rowIndex) => {
    const formattedCells = row.cells
      .map((compressedCell, columnIndex) => {
        const mergeOrigin = tableBlock.data.merges?.find(
          (merge) =>
            merge.startRow === rowIndex && merge.startColumn === columnIndex,
        );

        const overlappingMerge = tableBlock.data.merges?.some((merge) => {
          const isCurrentCellWithinMerge =
            columnIndex >= merge.startColumn &&
            columnIndex < merge.startColumn + merge.columnSpan &&
            rowIndex >= merge.startRow &&
            rowIndex < merge.startRow + merge.rowSpan;

          const isNotMergeOrigin = !(
            merge.startRow === rowIndex && merge.startColumn === columnIndex
          );

          return isCurrentCellWithinMerge && isNotMergeOrigin;
        });

        if (overlappingMerge) {
          return null;
        }

        const cell = tableCellUncompress(compressedCell);

        const formattedCell: FormattedTableCell = {
          content: cell.content.map(textToHtmlString).join(''),
          format: cell.format,
        };

        const foundWidth = tableBlock.data.columnWidths?.[columnIndex];

        if (foundWidth) {
          formattedCell.style = {
            maxWidth: `${foundWidth + 28}px`,
            minWidth: `${foundWidth - 28}px`,
          };
        }

        if (mergeOrigin) {
          formattedCell.colspan = mergeOrigin.columnSpan;
          formattedCell.rowspan = mergeOrigin.rowSpan;
        }

        return formattedCell;
      })
      .filter(isFormattedCell);

    const maybeHeight = tableBlock.data.rowHeights?.[rowIndex];
    const maybeStyle = maybeHeight
      ? {
          style: { height: `${maybeHeight}px` },
        }
      : {};
    return { cells: formattedCells, ...maybeStyle };
  });

  return {
    rows: formattedRows,
  };
};

export default formattedTableDataFromBlock;
