import { BlockSelection, isBlockSelection } from './BlockSelection.js';
import { isTextSelection, TextSelection } from './TextSelection.js';
import { EditorSelection } from '../EditorSelection.js';

export default function matchSelectionType(actions: {
  blockSelectionAction: (selection: BlockSelection) => boolean;
  textSelectionAction: (selection: TextSelection) => boolean;
}): (selection: EditorSelection) => boolean {
  return (selection: EditorSelection) => {
    if (isTextSelection(selection)) {
      return actions.textSelectionAction(selection);
    } else if (isBlockSelection(selection)) {
      return actions.blockSelectionAction(selection);
    }

    return false;
  };
}
