import { SectionTotalResult } from './getSectionTotals.ts';
import { AnalyticsPublishedSection } from '../AnalyticsTypes.ts';

export type SectionWithTotals = AnalyticsPublishedSection & {
  commentCount: number;
  total: SectionTotalResult;
};

export const buildSortedSectionsWithTotals = (
  publishedSections: AnalyticsPublishedSection[],
  totals: {
    totalTime: number;
    sectionTotals: {
      [sectionId: string]: SectionTotalResult;
    };
  },
  commentCounts: {
    [sectionId: string]: number;
  },
): SectionWithTotals[] =>
  publishedSections
    .map((section) => {
      const total = totals.sectionTotals[section.sectionId] ?? {
        sectionId: section.sectionId,
        totalTime: 0,
        percentOfTotal: '0.0%',
      };

      return {
        ...section,
        commentCount: commentCounts[section.sectionId] ?? 0,
        total,
      };
    })
    .sort((a, b) => b.total.totalTime - a.total.totalTime);
