import { RefObject, useMemo } from 'react';
import BlockEditorAdapter from '../../../editor/BlockEditorAdapter.js';
import { createPublishedCommentSelectionUIStateAtom } from './PublishedCommentSelectionUIStateAtom.js';
import { useAtomValue, useSetAtom } from 'jotai';
import useOnBrowserSelectionChange from '../../../../../editor/useContentEditable/useOnBrowserSelectionChange.js';

export const usePublishedCommentSelectionMenu = ({
  ref,
}: {
  ref: RefObject<HTMLElement>;
}) => {
  const publishedCommentSelectionUIStateAtom = useMemo(
    createPublishedCommentSelectionUIStateAtom,
    [],
  );

  const atoms = useAtomValue(publishedCommentSelectionUIStateAtom);
  const changeSelection = useSetAtom(atoms.changeSelection);

  useOnBrowserSelectionChange(
    ref,
    changeSelection,
    BlockEditorAdapter.fromBaseAndExtent,
  );

  return publishedCommentSelectionUIStateAtom;
};
