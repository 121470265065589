import { Prevote } from '../../../types/Prevote.ts';
import OutlineButton from '../../atoms/OutlineButton.tsx';
import styles from './ClearPrevoteButton.module.scss';

const ClearPrevoteButton: React.FC<{
  onRemovePrevote: ((id: string) => Promise<void>) | null;
  currentUserPrevote: Pick<Prevote, 'id'>;
}> = ({ onRemovePrevote, currentUserPrevote }) => (
  <OutlineButton
    disabled={!onRemovePrevote}
    onClick={
      onRemovePrevote ? () => onRemovePrevote(currentUserPrevote.id) : () => {}
    }
    size="medium"
    color="secondary"
    className={styles.removePrevoteButton}
  >
    Clear<span className={styles.mobileHidden}>&nbsp;My Pre-Vote</span>
  </OutlineButton>
);

export default ClearPrevoteButton;
