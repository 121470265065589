import { UserEngagementTotal } from '../../../../../api/endpoints/createAnalyticsApi.ts';
import { AnalyticsPublishedSection } from '../AnalyticsTypes.ts';

export const getValidSectionTotals = (
  totals: UserEngagementTotal[],
  publishedSections: AnalyticsPublishedSection[],
) => {
  const zeckSectionIds = publishedSections.map((section) => section.sectionId);

  return totals.filter(
    (totalItem) => zeckSectionIds.indexOf(totalItem.sectionId) !== -1,
  );
};
