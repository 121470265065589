import ContentSelection, {
  contentSelection,
} from '../../selection/contentSelection/ContentSelection.js';
import { ContentPatch } from '../../ContentPatch.js';
import splitTextNodesFromContentSelection from '../textNode/splitTextNodesFromContentSelection.js';
import textBlockReplaceSelectedContentStrategy from './textBlockReplaceSelectedContentStrategy.js';
import { Text } from 'editor-content/TextNode.js';
import {
  newTextBlockOfType,
  TextBlock,
  updateTextBlock,
} from 'editor-content/Block.js';
import isNullish from '../../../junkDrawer/isNullish.js';

const textBlockReplaceSelectedContentWithPlaintextStrategy = <
  BlockType extends TextBlock,
>(
  currentBlock: BlockType,
  selection: ContentSelection,
  plaintext: string,
): ContentPatch<BlockType[]> | void => {
  // trimstart to prevent leading empty spaces from causing issues after block is updated
  const lines = plaintext.split('\n').map((line) => line.trimStart());

  if (lines.length === 1) {
    return textBlockReplaceSelectedContentStrategy<BlockType, BlockType>(
      currentBlock,
      selection,
      { type: 'text', content: lines.map((s) => Text(s)) },
    );
  }

  const [beforeContent, , afterContent] = splitTextNodesFromContentSelection(
    currentBlock.content,
    selection,
  );

  const newBlocks = lines.map((string, i) => {
    // first line of text gets start of split block
    if (i === 0) {
      return updateTextBlock(currentBlock, [...beforeContent, Text(string)]);
      // last line of text gets end of split block
    } else if (i === lines.length - 1) {
      return newTextBlockOfType(currentBlock, [Text(string), ...afterContent]);
    }

    // in-between lines of text get their own blocks
    return newTextBlockOfType(currentBlock, [Text(string)]);
  });

  const lastLine = lines[lines.length - 1];
  if (isNullish(lastLine)) return;

  return {
    contentSubset: newBlocks,
    selection: {
      index: lines.length - 1,
      offset: contentSelection(lastLine.length),
    },
  };
};

export default textBlockReplaceSelectedContentWithPlaintextStrategy;
