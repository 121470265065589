import { useEffect } from 'react';
import { CartaIssuer } from '../../../../../../api/endpoints/createCartaApi.ts';
import { ErrorCredentialResponse } from '../../../../../../api/endpoints/createCredentialApi.ts';
import useApi from '../../../../../../api/useApi.ts';
import Button from '../../../../../../design-system/atoms/Button.tsx';
import SelectionModal from '../../../../../../design-system/organisms/SelectionModal.tsx';
import { ReactComponent as CartaLogo } from '../../../../../../images/CartaLogo.svg';
import { ZeckCommunicationLoader } from '../IntegrationLoader.tsx';
import useAddCredential from '../useAddCredential.ts';
import styles from './CartaIntegrationModalFlow.module.scss';
import Modal from '../../../../../../design-system/organisms/Modal.tsx';
import { CartaCapTable } from 'editor-content/CartaBlock.ts';

type CartaIntegrationModalFlowProps = {
  onAddCapTable: (capTable: CartaCapTable) => void;
  uiState: CartaIntegrationUIState;
  setUIState: (uiState: CartaIntegrationUIState) => void;
  onClose: () => void;
};

export type CartaIntegrationUIState =
  | {
      type: 'SELECT_CREDENTIAL';
      integrationType: 'cartaCapTable';
      errorCredential?: ErrorCredentialResponse;
    }
  | {
      type: 'LOADING_COMPANIES';
      integrationType: 'cartaCapTable';
      accessToken: string;
      credential: Credential;
    }
  | {
      type: 'SELECT_COMPANY';
      integrationType: 'cartaCapTable';
      issuers: CartaIssuer[];
      credential: Credential;
    }
  | {
      type: 'LOADING_CAP_TABLE';
      integrationType: 'cartaCapTable';
      issuer: CartaIssuer;
      credential: Credential;
    };

const LoadingCompanies: React.FC<{
  uiState: { credential: Credential };
  setUIState: (uiState: CartaIntegrationUIState) => void;
}> = ({ uiState, setUIState }) => {
  const { getCartaIssuers } = useApi();

  useEffect(() => {
    const loadCompanies = async () => {
      const issuers = await getCartaIssuers(uiState.credential.id);

      setUIState({
        type: 'SELECT_COMPANY',
        integrationType: 'cartaCapTable',
        issuers,
        credential: uiState.credential,
      });
    };

    loadCompanies();
  }, [getCartaIssuers, setUIState, uiState.credential, uiState.credential.id]);

  return <ZeckCommunicationLoader />;
};

const LoadingCapTable: React.FC<{
  uiState: { issuer: CartaIssuer; credential: Credential };
  setUIState: (uiState: CartaIntegrationUIState) => void;
  onAddCapTable: (capTable: CartaCapTable) => void;
  onClose: () => void;
}> = ({ uiState, setUIState, onAddCapTable, onClose }) => {
  const { getCapTable } = useApi();

  useEffect(() => {
    const loadCapTable = async () => {
      const capTable = await getCapTable(
        uiState.issuer.id,
        uiState.credential.id,
      );
      onAddCapTable(capTable);
      onClose();
    };

    loadCapTable();
  }, [getCapTable, onAddCapTable, onClose, setUIState, uiState]);

  return <ZeckCommunicationLoader />;
};

export const CartaIntegrationModalFlow: React.FC<
  CartaIntegrationModalFlowProps
> = ({ uiState, onClose, setUIState, onAddCapTable }) => {
  console.debug({ uiState, onClose });

  const { addCredential, isAddingCredential } = useAddCredential(
    (credential) => {
      if (credential.status === 'valid') {
        setUIState({
          type: 'LOADING_COMPANIES',
          integrationType: 'cartaCapTable',
          accessToken: credential.accessToken,
          credential,
        });
      }
    },
    'carta',
  );

  if (uiState.type === 'LOADING_COMPANIES') {
    return <LoadingCompanies uiState={uiState} setUIState={setUIState} />;
  }

  if (uiState.type === 'LOADING_CAP_TABLE') {
    return (
      <LoadingCapTable
        uiState={uiState}
        setUIState={setUIState}
        onAddCapTable={onAddCapTable}
        onClose={onClose}
      />
    );
  }

  if (uiState.type === 'SELECT_COMPANY') {
    const selectItems = uiState.issuers.map((issuer) => ({
      name: issuer.legalName,
      id: issuer.id,
    }));

    return (
      <Modal dark isOpen={true}>
        <SelectionModal
          items={selectItems}
          title="Select Company"
          onSubmit={function (item) {
            setUIState({
              type: 'LOADING_CAP_TABLE',
              integrationType: 'cartaCapTable',
              issuer: { id: item.id, legalName: item.name },
              credential: uiState.credential,
            });
          }}
          onCancel={onClose}
        />
      </Modal>
    );
  }

  return (
    <div className={styles.cartaContainer}>
      <CartaLogo className={styles.cartaLogo} />
      {isAddingCredential}

      <div className={styles.cartaByline}>
        Import your Carta cap table directly into your Zeck.
      </div>

      <Button
        size="small"
        color="secondary"
        className={styles.connectCartaButton}
        onClick={addCredential}
      >
        Connect to Carta
      </Button>
    </div>
  );
};
