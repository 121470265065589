import { makeMakeApiRequest } from '../makeMakeApiRequest.js';

const createPasswordApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  sendResetPasswordRequest: (email: string): Promise<void> => {
    return makeLambdaApiRequest('/user/reset-password-request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ email: email }),
    });
  },

  validateResetPasswordToken: async (
    resetPasswordToken: string,
  ): Promise<{ status: string; email: string | null }> => {
    const { data } = await makeLambdaApiRequest(
      '/user/reset-password-validate',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          resetPasswordToken,
        }),
      },
    );
    return data;
  },

  sendResetPassword: (
    resetPasswordToken: string,
    password: string,
  ): Promise<void> => {
    return makeLambdaApiRequest('/user/reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        resetPasswordToken,
        password,
      }),
    });
  },
});

export default createPasswordApi;
