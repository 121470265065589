import React, { useState } from 'react';
import SectionAppearanceSidebar from './edit/sectionAppearance/SectionAppearanceSidebar.tsx';
import useEditorState from './edit/useEditorState.tsx';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';
import ZeckEditable from './editor/ZeckEditable.tsx';
import withEditorSave from './edit/withEditorSave.tsx';
import EditPage from './edit/EditPage.tsx';
import SelectionCommentWithActions from './editor/comments/SelectionCommentWithActions.ts';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import { Editor } from 'editor-content/TextNode.js';
import { Block } from 'editor-content/Block.js';
import ZeckWithActions from './edit/zeckCover/ZeckWithActions.ts';
import SectionWithActions from './edit/zeckCover/SectionWithActions.ts';
import { IntegrationListItemData } from '../../api/endpoints/createIntegrationApi.ts';
import {
  BlockPrevoteResult,
  BlockPrevoteTally,
  generateZeckVoteCapabilities,
} from './voting/VoteCapability.ts';
import { Prevote } from '../../types/Prevote.ts';
import Linkable from 'editor-content/html/Linkable.ts';
import useLinkTooltips from './links/LinkTooltips/useLinkTooltips.js';
import LinkTooltips from './links/LinkTooltips/LinkTooltips.js';

const ZeckEditableWithSave = withEditorSave(ZeckEditable);

type EditSectionPageLoadingProps = {
  zeck: ZeckWithActions;
  section: SectionWithActions;
  userAndCompany: UserAndCompany;
};

export const EditSectionPageLoading: React.FunctionComponent<
  EditSectionPageLoadingProps
> = ({ zeck, section, userAndCompany }) => {
  const [editingAppearance, setEditingAppearance] = useState(false);

  return (
    <EditPage
      {...{
        isSaved: true,
        zeck,
        section,
        onRenameActiveSection: () => {
          //
        },
      }}
    >
      <SectionAppearanceSidebar
        section={section}
        onChangeSection={section.actions.update}
        show={editingAppearance}
        onClose={() => setEditingAppearance(false)}
        company={userAndCompany.activeCompany}
      />
    </EditPage>
  );
};

type SectionPageProps = {
  zeck: ZeckWithActions;
  section: SectionWithActions;
  linkables: Linkable[];
  integrationData: IntegrationListItemData;
  selectionComments: SelectionCommentWithActions[];
  createSelectionComment: (
    highlightId: string,
    content: CommentContentNode[],
    selection: Block | Editor.Highlight,
  ) => Promise<void>;
  userAndCompany: UserAndCompany;
  prevoteData: {
    results: BlockPrevoteResult[];
    tallies: BlockPrevoteTally[];
    boardDirectorCount: number;
    currentUserPrevotes: Prevote[];
    prevotedBlockIds: string[];
  };
};

const EditSectionPageView: React.FunctionComponent<SectionPageProps> = ({
  zeck,
  section,
  linkables,
  integrationData,
  userAndCompany,
  selectionComments,
  createSelectionComment,
  prevoteData,
}) => {
  const editPageActions = useEditorState({
    section,
    onChangeSection: section.actions.update,
  });

  const [editingAppearance, setEditingAppearance] = useState(false);

  const { zeckFinalizeVoteCapability, zeckPrevoteCapability } =
    generateZeckVoteCapabilities({
      userIsAdmin: userAndCompany.activeCompany.role === 'admin',
      userCanManageMinutes:
        userAndCompany.activeCompany.permissions.canManageMinutes,
      userIsBoardDirector: userAndCompany.activeCompany.boardDirector,
      prevoteData,
      takePrevote: null,
      takeVote: null,
      removePrevote: null,
    });

  const linkTooltipBehavior = useLinkTooltips({
    linkables: linkables,
  });

  return (
    <EditPage
      {...{
        isSaved: editPageActions.isSaved,
        zeck,
        section,
        onRenameActiveSection: editPageActions.editTitle,
        ...linkTooltipBehavior.linkListeners,
      }}
    >
      <ZeckEditableWithSave
        key={section.id}
        {...{
          linkables,
          integrationData,
          ...editPageActions,
          onEditAppearance() {
            setEditingAppearance(true);
          },
          selectionComments,
          zeckId: zeck.id,
          sectionId: section.id,
          user: userAndCompany.user,
          company: userAndCompany.activeCompany,
          createSelectionComment,
          zeckFinalizeVoteCapability,
          zeckPrevoteCapability,
          prevotedBlockIds: prevoteData.prevotedBlockIds,
          linkTooltipBehavior,
        }}
      />
      <SectionAppearanceSidebar
        section={section}
        onChangeSection={section.actions.update}
        show={editingAppearance}
        onClose={() => setEditingAppearance(false)}
        company={userAndCompany.activeCompany}
      />
      <LinkTooltips
        linkTooltipState={linkTooltipBehavior.linkTooltipState}
        hideNotFoundTooltip={false}
      />
    </EditPage>
  );
};

export default EditSectionPageView;
