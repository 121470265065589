import styles from './EditZeckCoverPage.module.scss';
import ZeckCover from '../../../../design-system/zeck/ZeckCover.tsx';
import EditAppearanceButton from '../EditAppearanceButton.tsx';
import EditAppearanceSidebar from './EditAppearanceSidebar.tsx';
import { useState } from 'react';
import { UserAndCompany } from '../../../../userAndCompany/FetchUserAndCompany.tsx';
import ZeckWithActions from './ZeckWithActions.ts';

type EditZeckCoverPageProps = {
  userAndCompany: UserAndCompany;
  zeck: ZeckWithActions;
};

export default function EditZeckCover({
  zeck,
  userAndCompany,
}: EditZeckCoverPageProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className={styles.page}>
      <ZeckCover companyName={userAndCompany.activeCompany.name} zeck={zeck} />
      {userAndCompany.activeCompany.permissions.canManageZecks && (
        <>
          <EditAppearanceButton
            onClick={() => {
              setSidebarOpen(true);
            }}
            className={styles.editAppearanceButton}
          />
          <EditAppearanceSidebar
            company={userAndCompany.activeCompany}
            zeck={zeck}
            isOpen={sidebarOpen}
            onCloseSidebar={() => {
              setSidebarOpen(false);
            }}
          />
        </>
      )}
    </div>
  );
}
