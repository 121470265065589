import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.ts';

export function AiPencilIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <g id="AI Essentials Icon Set">
        <g id="icon">
          <path
            d="M5.83125 13.6459L5.89125 13.5259C6.14125 13.0409 6.53625 12.6459 7.02125 12.3959L7.14125 12.3359C7.61625 12.0959 7.61625 11.4159 7.14125 11.1709L7.02125 11.1109C6.53625 10.8609 6.14125 10.4659 5.89125 9.98086L5.83125 9.86086C5.59125 9.38586 4.91125 9.38586 4.66625 9.86086L4.60625 9.98086C4.35625 10.4659 3.96125 10.8609 3.47625 11.1109L3.35625 11.1709C2.88125 11.4109 2.88125 12.0909 3.35625 12.3359L3.47625 12.3959C3.96125 12.6459 4.35625 13.0409 4.60625 13.5259L4.66625 13.6459C4.90625 14.1209 5.58625 14.1209 5.83125 13.6459Z"
            fill="#C7C9D9"
          />
          <path
            d="M20.1462 17.6709L20.0262 17.6109C19.5412 17.3609 19.1462 16.9659 18.8962 16.4809L18.8362 16.3609C18.5962 15.8859 17.9162 15.8859 17.6712 16.3609L17.6112 16.4809C17.3612 16.9659 16.9662 17.3609 16.4812 17.6109L16.3612 17.6709C15.8862 17.9109 15.8862 18.5909 16.3612 18.8359L16.4812 18.8959C16.9662 19.1459 17.3612 19.5409 17.6112 20.0259L17.6712 20.1459C17.9112 20.6209 18.5912 20.6209 18.8362 20.1459L18.8962 20.0259C19.1462 19.5409 19.5412 19.1459 20.0262 18.8959L20.1462 18.8359C20.6213 18.5959 20.6213 17.9159 20.1462 17.6709Z"
            fill="#C7C9D9"
          />
          <path
            d="M6.21125 7.39086L6.37125 7.47086C6.87125 7.72586 7.27125 8.12586 7.52625 8.62586L7.60625 8.78586C7.88125 9.32586 8.43125 9.66086 9.03625 9.66086C9.64125 9.66086 10.1913 9.32586 10.4713 8.78586L10.5513 8.62586C10.8063 8.12586 11.2063 7.72586 11.7063 7.47086L11.8663 7.39086C12.4062 7.11586 12.7413 6.56586 12.7413 5.96086C12.7413 5.35586 12.4062 4.80586 11.8663 4.52586L11.7063 4.44586C11.2063 4.19086 10.8063 3.79086 10.5513 3.29086L10.4713 3.13086C10.1963 2.59086 9.64625 2.25586 9.03625 2.25586C8.42625 2.25586 7.88125 2.59086 7.60125 3.13086L7.52125 3.29086C7.26625 3.79086 6.86625 4.19086 6.36625 4.44586L6.20625 4.52586C5.66625 4.80086 5.33125 5.35086 5.33125 5.96086C5.33125 6.57086 5.66625 7.11586 6.20625 7.39586L6.21125 7.39086ZM6.89125 5.86086L7.05125 5.78086C7.83625 5.38086 8.46125 4.75586 8.86125 3.97086L8.94125 3.81086C8.94125 3.81086 8.97125 3.75086 9.03625 3.75086C9.10125 3.75086 9.12625 3.79086 9.13125 3.81086L9.21125 3.97086C9.61125 4.75586 10.2363 5.38086 11.0213 5.78086L11.1813 5.86086C11.1813 5.86086 11.2413 5.89086 11.2413 5.95586C11.2413 6.02086 11.1963 6.04586 11.1813 6.05086L11.0213 6.13086C10.2363 6.53086 9.61125 7.15586 9.21125 7.94086L9.13125 8.10086C9.13125 8.10086 9.10125 8.16086 9.03625 8.16086C8.97125 8.16086 8.94625 8.11586 8.94125 8.10086L8.86125 7.94086C8.46125 7.15586 7.83625 6.53086 7.05125 6.13086L6.89125 6.05086C6.89125 6.05086 6.83125 6.02086 6.83125 5.95586C6.83125 5.89086 6.87625 5.86586 6.89125 5.86086Z"
            fill="#C7C9D9"
          />
          <path
            d="M21.1462 6.32586L20.1762 5.35586C19.7862 4.96586 19.2663 4.75086 18.7163 4.75086C18.1663 4.75086 17.6462 4.96586 17.2562 5.35586L6.87125 15.7409C6.87125 15.7409 6.86125 15.7559 6.85625 15.7659C6.79625 15.8309 6.74625 15.9009 6.71125 15.9809V15.9909L5.26625 19.4359C5.04125 19.9709 5.16625 20.5809 5.58125 20.9909C5.85125 21.2559 6.20625 21.3959 6.57125 21.3959C6.76125 21.3959 6.95625 21.3559 7.14125 21.2759L10.5163 19.7959L10.5363 19.7859C10.6163 19.7509 10.6813 19.7009 10.7413 19.6459C10.7463 19.6409 10.7563 19.6359 10.7663 19.6309L21.1512 9.24586C21.5412 8.85586 21.7562 8.33586 21.7562 7.78586C21.7562 7.23586 21.5412 6.71586 21.1512 6.32586H21.1462ZM10.2312 18.0409L8.46125 16.2709L16.2413 8.49086L18.0113 10.2609L10.2312 18.0409ZM20.0862 8.18586L19.0712 9.20086L17.3012 7.43086L18.3163 6.41586C18.5263 6.20086 18.9012 6.20086 19.1162 6.41586L20.0862 7.38586C20.1912 7.49086 20.2512 7.63586 20.2512 7.78586C20.2512 7.93586 20.1912 8.07586 20.0862 8.18586Z"
            fill="#C7C9D9"
          />
        </g>
      </g>
    </svg>
  );
}
