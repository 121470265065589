import { isTextBlock, TextBlock } from 'editor-content/Block.ts';
import { CommentWithActions } from './commentsSidebar/useComments/useComments.ts';
import { removeHighlights } from './removeHighlights.ts';
import getContentSelection from '../getContentSelection.ts';
import { getTextFromNodes, TextNode } from 'editor-content/TextNode.ts';
import addHighlightToTextNodes from '../../editor/BodyEditor/addHighlightToTextNodes.ts';

const getProdCommentBlock = <B extends TextBlock>(
  block: B,
  getComments?: () => CommentWithActions[] | undefined,
): {
  blockWithUpdatedHighlights: B;
  commentCount: number;
  orphanedCommentIds: string[];
} => {
  const blockWithoutHighlights: B = {
    ...block,
    content: removeHighlights(block.content),
  };

  const commentsForBlock = getComments?.();

  if (!commentsForBlock)
    return {
      blockWithUpdatedHighlights: blockWithoutHighlights,
      commentCount: 0,
      orphanedCommentIds: [],
    };

  const selectionComments = commentsForBlock.filter(
    (c) => c.selection && c.selection.snippetSelection && c.selection.block,
  );

  let contentWithoutHighlights: TextNode[] = blockWithoutHighlights.content;
  const orphanedCommentIds: string[] = [];

  selectionComments.forEach((comment) => {
    const commentBlock = comment.selection?.block;
    const commentSelection = comment.selection?.snippetSelection;
    if (!commentBlock || !commentSelection || !isTextBlock(commentBlock))
      return;

    const updatedContentSelection = getContentSelection(
      getTextFromNodes(commentBlock.content),
      getTextFromNodes(blockWithoutHighlights.content),
      commentSelection,
    );

    if (!updatedContentSelection) {
      orphanedCommentIds.push(comment.id);
      return;
    }

    contentWithoutHighlights = addHighlightToTextNodes(
      contentWithoutHighlights,
      updatedContentSelection,
      comment.id,
    );
  });

  const newBlock: B = {
    blockWithoutHighlights,
    content: contentWithoutHighlights,
  } as unknown as B;

  return {
    blockWithUpdatedHighlights: newBlock,
    commentCount: selectionComments.length,
    orphanedCommentIds,
  };
};

export default getProdCommentBlock;
