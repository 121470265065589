import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useApi from '../../api/useApi.ts';
import useOnComponentMount from '../../services/useOnComponentMount.ts';
import ResetPasswordRequestSent from '../resetPasswordRequest/ResetPasswordRequestSent.tsx';
import ResetPasswordExpired from './ResetPasswordExpired.tsx';
import ResetPasswordForm from './ResetPasswordForm.tsx';
import ResetPasswordInvalid from './ResetPasswordInvalid.tsx';
import ResetPasswordSuccess from './ResetPasswordSuccess.tsx';

const ResetPasswordPage = () => {
  const {
    validateResetPasswordToken,
    sendResetPassword,
    sendResetPasswordRequest,
  } = useApi();
  const [validateResponse, setResetPasswordTokenStatus] = useState<{
    status: string;
    email: string | null;
  }>({ status: 'pending', email: null });
  const [resetPasswordSucceeded, setResetPasswordSucceeded] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get('token');

  useOnComponentMount(() => {
    (async () => {
      if (token) {
        try {
          setResetPasswordTokenStatus(await validateResetPasswordToken(token));
          return;
        } catch {}
      }

      setResetPasswordTokenStatus({
        status: 'invalid',
        email: null,
      });
    })();
  });

  const handleResetPassword = async (password: string) => {
    if (token) {
      await sendResetPassword(token, password);
      setResetPasswordSucceeded(true);
    }
    // FIXME handle failure
  };

  const handleResendRequest = () => {
    // FIXME call existing send-request directly from here? or...
    if (validateResponse.email) {
      // FIXME we know we have an email, but typescript complains
      sendResetPasswordRequest(validateResponse.email);
      // FIXME handle failure
      setEmailSent(true);
    }
  };

  switch (validateResponse.status) {
    case 'pending':
      return null;
    case 'expired':
      if (emailSent) {
        return <ResetPasswordRequestSent />;
      } else {
        return <ResetPasswordExpired onResendRequest={handleResendRequest} />;
      }
    case 'ok':
      if (resetPasswordSucceeded) {
        return <ResetPasswordSuccess />;
      } else {
        return <ResetPasswordForm onResetPassword={handleResetPassword} />;
      }
    default:
      return <ResetPasswordInvalid />;
  }
};

export default ResetPasswordPage;
