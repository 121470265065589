import Toolbar, { ToolbarButton, ToolbarGroup } from './Toolbar.tsx';
import IconButton from '../atoms/IconButton.tsx';

type FormattingMenuProps = {
  onClickColumn: () => void;
  onClickWide: () => void;
  onClickFullWidth: () => void;
  onClickLeftAlign: () => void;
  onClickCenterAlign: () => void;
  onClickReplaceImage: () => void;
  onClickDeleteImage: () => void;
  onClickComment: () => void;
  columnActive?: boolean;
  wideActive?: boolean;
  fullWidthActive?: boolean;
  leftAlignActive: boolean;
  centerAlignActive: boolean;
  canAlign: boolean;
  className?: string;
};

const FormattingMenu = ({
  onClickColumn,
  onClickWide,
  onClickFullWidth,
  onClickLeftAlign,
  onClickCenterAlign,
  onClickReplaceImage,
  onClickDeleteImage,
  onClickComment,
  columnActive,
  wideActive,
  fullWidthActive,
  leftAlignActive,
  centerAlignActive,
  canAlign,
  className,
}: FormattingMenuProps) => {
  return (
    <Toolbar className={className}>
      <ToolbarGroup>
        <IconButton
          {...{
            name: 'columnWidth',
            type: 'dark',
            'aria-label': 'make image column width',
            onClick: onClickColumn,
            active: columnActive,
          }}
        />
        <IconButton
          {...{
            name: 'wideWidth',
            type: 'dark',
            'aria-label': 'make image wide width',
            onClick: onClickWide,
            active: wideActive,
          }}
        />
        <IconButton
          {...{
            name: 'fullWidth',
            type: 'dark',
            'aria-label': 'make image full width',
            onClick: onClickFullWidth,
            active: fullWidthActive,
          }}
        />
      </ToolbarGroup>
      <ToolbarGroup>
        <IconButton
          {...{
            name: 'trash',
            type: 'dark',
            'aria-label': 'delete image',
            onClick: onClickDeleteImage,
            active: false,
          }}
        />
        <IconButton
          {...{
            name: 'replace',
            type: 'dark',
            'aria-label': 'replace image',
            onClick: onClickReplaceImage,
            active: false,
          }}
        />
      </ToolbarGroup>
      <ToolbarGroup>
        <ToolbarButton
          iconName="speechBubbleWithText"
          {...{
            onClick() {
              onClickComment();
            },
          }}
        >
          Comment
        </ToolbarButton>
      </ToolbarGroup>
      {canAlign && (
        <ToolbarGroup>
          {!leftAlignActive && (
            <IconButton
              {...{
                name: 'alignLeft',
                type: 'dark',
                'aria-label': 'make image left aligned',
                onClick: () => {
                  onClickLeftAlign();
                },
                active: false,
              }}
            />
          )}
          {!centerAlignActive && (
            <IconButton
              {...{
                name: 'alignCenter',
                type: 'dark',
                'aria-label': 'make image center aligned',
                onClick: () => {
                  onClickCenterAlign();
                },
                active: false,
              }}
            />
          )}
        </ToolbarGroup>
      )}
    </Toolbar>
  );
};

export default FormattingMenu;
