import useApi from '../api/useApi.js';
import useDownsampleScrollEvents from './useDownsampleScrollEvents.js';
import { CompanyRole } from '../types/CompanyLite.js';
import { logError } from '../logging/logger.ts';
import { useMemo } from 'react';

const SCROLL_EVENT_SAMPLE_WINDOW_MS = 1000 * 30;

const useAnalyticsEvents = ({
  userId,
  companyId,
  companyRole,
  boardDirector,
}: {
  userId: string;
  companyId: string;
  companyRole: CompanyRole;
  boardDirector: boolean;
}) => {
  const { addZeckEvent } = useApi();

  const downsampledScrollInSection = useDownsampleScrollEvents(
    ({
      sectionId,
      zeckId,
      timestamp,
    }: {
      sectionId: string;
      zeckId: string;
      timestamp: number;
    }) => {
      addZeckEvent({
        userId,
        contentTypeId: sectionId,
        contentType: 'SECTION',
        eventType: 'SCROLL',
        firedAt: timestamp,
        payload: {
          zeckId,
          companyRole,
          userAgent: window.navigator.userAgent,
          boardDirector,
        },
        companyId,
      }).catch((e) => {
        logError(
          new Error(
            `exception occurred while registering section scroll: ${e.message}`,
          ),
        );
      });
    },
    SCROLL_EVENT_SAMPLE_WINDOW_MS,
  );

  return useMemo(() => {
    return {
      trackScrollInSection: (data: { zeckId: string; sectionId: string }) => {
        downsampledScrollInSection({
          sectionId: data.sectionId,
          zeckId: data.zeckId,
          timestamp: Date.now(),
        });
      },
      trackViewZeck: (zeckId: string) => {
        addZeckEvent({
          companyId,
          contentTypeId: zeckId,
          contentType: 'ZECK',
          userId,
          eventType: 'VIEW',
          firedAt: Date.now(),
          payload: {
            companyRole,
            userAgent: window.navigator.userAgent,
            boardDirector,
          },
        }).catch((e) => {
          logError(
            new Error(
              `exception occurred while registering published zeck view: ${e.message}`,
            ),
          );
        });
      },
    };
  }, [
    downsampledScrollInSection,
    addZeckEvent,
    companyId,
    userId,
    companyRole,
    boardDirector,
  ]);
};

export default useAnalyticsEvents;
