import { Editor, Highlight, NonHighlightTextNode } from '../TextNode.js';
import h from 'hyperscript';

export const HIGHLIGHT_ELEMENT_NODE_TYPE = 'highlight';

interface HighlightElement extends HTMLElement {
  dataset: {
    nodetype: typeof HIGHLIGHT_ELEMENT_NODE_TYPE;
    highlightids: string;
  };
}

export const createHighlightElement = (
  node: Editor.Highlight,
  innerHTML: string,
) => {
  const highlight = h('span', {
    'data-nodetype': HIGHLIGHT_ELEMENT_NODE_TYPE,
    'data-highlightids': node.ids.join(' '),
  });

  highlight.innerHTML = innerHTML;

  return highlight;
};

export const getHighlightFromElement = (
  el: HTMLElement,
  content: NonHighlightTextNode[],
) => {
  if (isHighlightElement(el)) {
    return Highlight(el.dataset.highlightids.split(' '), content);
  }

  return null;
};

const isHighlightElement = (el: HTMLElement): el is HighlightElement =>
  el.dataset.nodetype === HIGHLIGHT_ELEMENT_NODE_TYPE;

export default HighlightElement;
