import { makeMakeApiRequest } from '../makeMakeApiRequest.ts';
import { Prevote, PrevoteChoice } from '../../types/Prevote.ts';
import {
  BlockPrevoteResult,
  BlockPrevoteTally,
} from '../../pages/zeck/voting/VoteCapability.ts';

const createPrevoteApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createPrevote: async ({
    blockId,
    sectionId,
    choice,
  }: {
    blockId: string;
    sectionId: string;
    choice: PrevoteChoice;
  }): Promise<Prevote> => {
    return await makeLambdaApiRequest(`/section/${sectionId}/prevote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ blockId, sectionId, choice }),
    });
  },

  getZeckPrevoteData: async (
    zeckId: string,
  ): Promise<{
    tallies: BlockPrevoteTally[];
    results: BlockPrevoteResult[];
    currentUserPrevotes: Prevote[];
    boardDirectorCount: number;
    prevotedBlockIds: string[];
  }> => {
    return await makeLambdaApiRequest(`/zeck/${zeckId}/prevote`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  deletePrevote: async (prevoteId: string): Promise<void> => {
    return await makeLambdaApiRequest(`/prevote/${prevoteId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
});

export default createPrevoteApi;
