import React from 'react';
import styles from './MeetingMinutesPageLayout.module.scss';

type MeetingMinutesPageLayoutProps = {
  headerSlot: React.ReactNode;
  scrollContainerRef?: React.Ref<HTMLDivElement>;
  children?: React.ReactNode;
};
const MeetingMinutesPageLayout = React.forwardRef<
  HTMLDivElement,
  MeetingMinutesPageLayoutProps
>(function MeetingMinutesPageLayout(
  { children, headerSlot, scrollContainerRef },
  forwardedRef,
) {
  return (
    <div ref={forwardedRef} className={styles.meetingMinutesPageLayout}>
      <header className={styles.meetingMinutesPageLayout__header}>
        {headerSlot}
      </header>
      <div
        ref={scrollContainerRef}
        className={styles.meetingMinutesPageLayout__main}
      >
        {children}
      </div>
    </div>
  );
});

export default MeetingMinutesPageLayout;
