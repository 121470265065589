import { ErrorWithContext } from '../../../../../../../logging/logger.ts';

export default async function fetchItemFromOneDrive(
  itemId: string,
  options: { method: string; headers: Headers },
  driveId?: string,
) {
  const urlBase = driveId
    ? `https://graph.microsoft.com/v1.0/drives/${driveId}`
    : `https://graph.microsoft.com/v1.0/me/drive`;

  const downloadUrlData = await fetch(
    `${urlBase}/items/${itemId}?select=id,@microsoft.graph.downloadUrl,lastModifiedDateTime`,
    options,
  ).then((response) => response.json());

  const downloadUrl = downloadUrlData['@microsoft.graph.downloadUrl'];

  if (!downloadUrl || !downloadUrl.startsWith('https://')) {
    throw new ErrorWithContext(
      'Error communicating with Microsoft OneDrive. Please try again later.',
      { downloadUrl },
      'OneDriveDownloadUrlError',
    );
  }

  const fileContents = await fetch(downloadUrl);

  if (!fileContents.ok) {
    throw new ErrorWithContext(
      'Error downloading file from Microsoft OneDrive. Please try again later.',
      { status: fileContents.status, statusText: fileContents.statusText },
      'OneDriveDownloadError',
    );
  }

  const lastModified = downloadUrlData?.lastModifiedDateTime
    ? new Date(downloadUrlData?.lastModifiedDateTime)
    : undefined;

  return { fileContents, lastModified };
}
