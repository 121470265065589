import getTextBlockLength from './getTextBlockLength.js';
import splitTextNodesFromContentSelection from '../textNode/splitTextNodesFromContentSelection.js';
import { getTextFromNodes } from 'editor-content/TextNode.js';
import { newTextBlockOfType, updateTextBlock } from 'editor-content/Block.js';
import ContentSelection from '../../selection/contentSelection/ContentSelection.js';
import BaseTextBlock from 'editor-content/BaseTextBlock.js';

const splitBlock = <BlockType extends BaseTextBlock>(
  block: BlockType,
  selection: ContentSelection,
): [beforeSplit: BlockType[], afterSplit: BlockType[]] => {
  if (getTextBlockLength(block) === 0) {
    return [[], []];
  }

  const [beforeContent, , afterContent] = splitTextNodesFromContentSelection(
    block.content,
    selection,
  );

  const afterContentIsEmpty = getTextFromNodes(afterContent).length === 0;
  const beforeContentIsEmpty = getTextFromNodes(beforeContent).length === 0;

  // at start of block (collapsed or expanded)
  if (beforeContentIsEmpty) {
    return [[], [updateTextBlock(block, afterContent)]];
  }

  // at end of block (collapsed or expanded)
  if (afterContentIsEmpty) {
    return [[updateTextBlock(block, beforeContent)], []];
  }

  // in middle of block
  return [
    [updateTextBlock(block, beforeContent)],
    [newTextBlockOfType(block, afterContent)],
  ];
};

export default splitBlock;
