import textNodesToHtmlString from '../textNodesToHtmlString.js';
import h from 'hyperscript';
import { MeetingMinutesBlock } from './MeetingMinutesBlock.js';

const generateMeetingMinutesHtml = (meetingMinutes: {
  content: MeetingMinutesBlock[];
}) => {
  return meetingMinutes.content
    .map((block) => {
      const blockContentHTML = textNodesToHtmlString(block.content, {
        linkables: [],
      });
      switch (block.type) {
        case 'paragraph': {
          return `<p>${blockContentHTML}</p>`;
        }
        case 'bulleted-list-item': {
          const bullet = h('li', {
            style: `margin-left: ${block.indent * 16}px`,
          });
          bullet.innerHTML = blockContentHTML;
          return bullet.outerHTML;
        }
      }
    })
    .join('');
};

export default generateMeetingMinutesHtml;
