import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './TableOfContentsItem.module.scss';
import mergeRefs from '../../junkDrawer/mergeRefs.ts';
import useAllowsInsideClick from '../../services/useAllowsInsideClick.tsx';
import TransparentInput from '../molecules/TransparentInput.tsx';
import useHover from '../../junkDrawer/useHover.ts';
import { ButtonSizedLoader } from '../molecules/LoadingButton.tsx';

type TableOfContentsItemProps = {
  active: boolean;
  additionalContent?: ReactNode;
  href: string;
  onClick(e: React.MouseEvent): void;
  children?: React.ReactNode;
} & React.ComponentProps<'a'>;

const TableOfContentsItem = React.forwardRef<
  HTMLAnchorElement,
  TableOfContentsItemProps
>(
  (
    { active, href, onClick, additionalContent, children, ...otherProps },
    forwardedRef,
  ) => {
    return (
      <a
        {...{
          ref: forwardedRef,
          className: cx(
            styles.tableOfContentsItem,
            active && styles.tableOfContentsItem_active,
          ),
          ...otherProps,
          draggable: false,
          href,
          onClick,
          'data-testid': active ? 'sidebar__activeItem' : undefined,
        }}
      >
        <div className={styles.childrenContainer}>{children}</div>
        {additionalContent}
      </a>
    );
  },
);

type TableOfContentsItemEditableProps = {
  active: boolean;
  value: string;
  hoverContent: ReactNode;
  to: string;
  onClick(e: React.MouseEvent): void;
  isEditing: boolean;
  onSubmit(v: string): void;
  loading?: boolean;
};

export const TableOfContentsItemEditable = React.forwardRef<
  HTMLAnchorElement,
  TableOfContentsItemEditableProps
>(
  (
    { active, value, isEditing, onSubmit, to, onClick, hoverContent, loading },
    forwardedRef,
  ) => {
    const { isHovered, listeners: hoverListeners } = useHover();

    const [ref, onClickContainer] =
      useAllowsInsideClick<HTMLAnchorElement>(onClick);

    let additionalContent = loading ? (
      <div className={styles.loadingButton}>
        <div>
          <ButtonSizedLoader />
        </div>
      </div>
    ) : null;

    additionalContent =
      additionalContent ??
      (isHovered ? (
        <span className={styles.tableOfContentsItem__hoverContent}>
          {hoverContent}
        </span>
      ) : null);

    return (
      <TableOfContentsItem
        {...{
          ref: mergeRefs([ref, forwardedRef]),
          href: to,
          className: cx(
            styles.tableOfContentsItem,
            active && styles.tableOfContentsItem_active,
            loading && styles.tableOfContentsItem_loading,
          ),
          active,
          onClick: loading ? (e) => e.preventDefault() : onClickContainer,
          ...hoverListeners,
          additionalContent,
        }}
      >
        {isEditing ? (
          <TransparentInput
            value={value}
            aria-label="section title"
            onSubmit={onSubmit}
          />
        ) : (
          value
        )}
      </TableOfContentsItem>
    );
  },
);

export default TableOfContentsItem;
