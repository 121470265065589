import textBlockReplaceSelectedContentStrategy from './textBlockReplaceSelectedContentStrategy.js';
import textBlockGetSelectedContentStrategy from './textBlockGetSelectedContentStrategy.js';
import ContentSelection from '../../selection/contentSelection/ContentSelection.js';
import { TextBlock } from 'editor-content/Block.js';
import { ContentPatch } from '../../../pages/zeck/editor/BodyEditor/replaceSelectionWith.js';
import EditorData from '../../../pages/zeck/editor/EditorData.js';

const textBlockCutStrategy = <BlockType extends TextBlock>(
  block: BlockType,
  selection: ContentSelection,
):
  | [newValue: ContentPatch<BlockType[]>, returnValue: EditorData<BlockType>]
  | void => {
  const result = textBlockReplaceSelectedContentStrategy(block, selection, {
    type: 'text',
    content: [],
  });
  const selectedContent = textBlockGetSelectedContentStrategy(block, selection);

  if (!result || !selectedContent) return;

  return [result, selectedContent];
};

export default textBlockCutStrategy;
