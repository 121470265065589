import { AssetType } from 'api-client/assetContract.js';
import { RequestError } from '../makeMakeApiRequest.js';
import AssetApiClient from './AssetApiClient.ts.ts';

const createAssetApi = (
  ApiClient: typeof AssetApiClient,
  accessToken: string | null,
) => ({
  createAsset: async ({
    companyId,
    assetType,
  }: {
    companyId: string;
    assetType: AssetType;
  }) => {
    const response = await ApiClient.create({
      params: { companyId, assetType },
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      body: {},
    });

    switch (response.status) {
      case 201: {
        return response.body;
      }
      // adapting from ts-rest returning error codes
      // to our code that expects thrown errors
      default: {
        throw new RequestError('createAsset', '', response.status, response);
      }
    }
  },

  uploadAsset: async (uploadUrl: string, file: File): Promise<void> => {
    const result = await fetch(uploadUrl, {
      method: 'PUT',
      body: file,
    });

    if (!result.ok) {
      throw new Error('Uploading asset returned non-200 status');
    }
  },
});

export default createAssetApi;
