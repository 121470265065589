import React, { ReactNode } from 'react';
import styles from './Paragraph.module.scss';
import cx from 'classnames';

type ParagraphProps = {
  children?: ReactNode;
  className?: string;
};

const Paragraph = React.forwardRef<HTMLParagraphElement, ParagraphProps>(
  function Paragraph(props, forwardedRef) {
    return (
      <p
        {...{
          ...props,
          ref: forwardedRef,
          className: cx(styles.paragraph, props.className),
        }}
      >
        {props.children}
      </p>
    );
  },
);

export default Paragraph;
