const cond =
  <Args extends unknown[], B, C>(
    test: (...args: Args) => boolean,
    left: (...args: Args) => B,
    right: (...args: Args) => C,
  ): ((...args: Args) => B | C) =>
  (...args) =>
    test(...args) ? left(...args) : right(...args);

export default cond;
