import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.ts';

export function TextInsertIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M14.4845 4.86871H21.3459C21.7779 4.86871 22.1191 4.53699 22.1191 4.10499C22.1191 3.68328 21.7775 3.35156 21.3459 3.35156H14.4845C14.0525 3.35156 13.7208 3.68328 13.7208 4.10499C13.7208 4.53699 14.0525 4.86871 14.4845 4.86871ZM3.03436 11.5476C3.76722 11.5476 4.18936 11.0354 4.18936 10.222V8.30328C4.18936 8.08213 4.29007 7.95185 4.53093 7.95185H7.26307V8.95599C7.26307 9.74971 8.06664 10.051 8.66922 9.56885L11.3915 7.39942C11.8029 7.06771 11.8029 6.49513 11.3915 6.17371L8.66879 3.99442C8.04607 3.48228 7.26307 3.79342 7.26307 4.60728V5.65213H4.30979C2.89336 5.65213 1.87893 6.52599 1.87893 7.95228V10.2224C1.87893 11.0358 2.30064 11.548 3.03393 11.548M14.4849 10.132H21.3455C21.7775 10.132 22.1186 9.80028 22.1186 9.36828C22.1186 8.94656 21.7771 8.61485 21.3455 8.61485H14.4845C14.0525 8.61485 13.7208 8.94656 13.7208 9.36828C13.7208 9.80028 14.0529 10.132 14.4849 10.132ZM2.64222 15.3944H21.3451C21.4461 15.3955 21.5463 15.3766 21.6399 15.3388C21.7335 15.3009 21.8188 15.2449 21.8907 15.174C21.9626 15.1031 22.0197 15.0187 22.0589 14.9256C22.098 14.8325 22.1183 14.7326 22.1186 14.6316C22.1186 14.2094 21.7771 13.8781 21.3455 13.8781H2.64222C2.21022 13.8781 1.87893 14.2094 1.87893 14.6316C1.87893 15.0533 2.21022 15.3944 2.64179 15.3944M2.64179 20.6483H21.3446C21.7766 20.6483 22.1182 20.3166 22.1182 19.8948C22.1182 19.4731 21.7766 19.1316 21.3451 19.1316H2.64222C2.54178 19.1307 2.44217 19.1498 2.34921 19.1879C2.25624 19.2259 2.17178 19.2821 2.10073 19.3531C2.02969 19.4241 1.97349 19.5085 1.93541 19.6014C1.89733 19.6944 1.87813 19.794 1.87893 19.8944C1.87893 20.3166 2.21022 20.6483 2.64179 20.6483Z"
        fill="#5445FF"
      />
    </svg>
  );
}
