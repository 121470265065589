export const createConcurrencyManager = (
  maxConcurrentCalls: number,
  intervalMs = 200,
) => {
  let numCalls = 0;

  function registerCallStart() {
    numCalls = numCalls + 1;
  }

  function registerCallComplete() {
    numCalls = numCalls - 1;
  }

  function hasConcurrencyCapacity() {
    return numCalls < maxConcurrentCalls;
  }

  const withConcurrencyLimit = async <T>(fn: () => Promise<T>): Promise<T> => {
    await new Promise<void>((res) => {
      if (hasConcurrencyCapacity()) {
        registerCallStart();
        res();
        return;
      }

      const intervalId = setInterval(() => {
        if (hasConcurrencyCapacity()) {
          registerCallStart();
          res();

          clearInterval(intervalId);
          return;
        }
      }, intervalMs);
    });

    const result = await fn();

    registerCallComplete();

    return result;
  };

  return {
    withConcurrencyLimit,
  };
};
