import { ComponentProps, useState } from 'react';
import styles from './InputPassword.module.scss';
import Input from './Input.tsx';
import IconButton from './IconButton.tsx';

const InputPassword: React.FunctionComponent<
  Omit<ComponentProps<typeof Input>, 'type'>
> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={styles.inputPassword}>
      <Input {...props} type={showPassword ? 'text' : 'password'} />
      <IconButton
        aria-label="reveal"
        name={showPassword ? 'eyeCross' : 'eye'}
        tabIndex={-1}
        className={styles.inputPassword__reveal}
        onClick={(e) => {
          e.preventDefault();
          setShowPassword(!showPassword);
        }}
      />
    </div>
  );
};

export default InputPassword;
