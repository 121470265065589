import { logError } from '../../../logging/logger.ts';

const getPermittedCompanyDeleteEmails = (
  hostName = window.location.hostname,
): string[] => {
  try {
    const permittedCompanyDeleteEmails = JSON.parse(
      import.meta.env.UI_DELETE_COMPANY_EMAIL_WHITELIST ?? '{}',
    );
    if (typeof permittedCompanyDeleteEmails[hostName] !== 'undefined') {
      return permittedCompanyDeleteEmails[hostName];
    }
    return [];
  } catch (e) {
    logError(e as Error);
    return [];
  }
};

export default getPermittedCompanyDeleteEmails;
