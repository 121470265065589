import React from 'react';
import styles from './TableOfContentsLayout.module.scss';

type TableOfContentsLayoutProps = {
  children: React.ReactNode;
  onScroll: React.UIEventHandler<HTMLDivElement>;
};

const TableOfContentsLayout = React.forwardRef<
  HTMLDivElement,
  TableOfContentsLayoutProps
>(({ children, onScroll }, ref) => {
  return (
    <div
      className={styles.tableOfContents__contents}
      ref={ref}
      onScroll={onScroll}
      data-testid="sidebar__scrollContainer"
    >
      {children}
    </div>
  );
});

export default TableOfContentsLayout;
