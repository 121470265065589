import { Block } from 'editor-content/Block.js';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import { User } from './types.ts';

type SelectionComment = {
  id: string;
  sectionId: string;
  selectionId: string;
  user: Pick<User, 'id' | 'firstName' | 'lastName'>;
  content: CommentContentNode[];
  updatedAt: number;
  createdAt: number;
};

export const isSameSelection = (
  selectionComment: SelectionComment,
  block: Block,
): boolean => selectionComment.selectionId === block.id;

export default SelectionComment;
