import { format } from 'date-fns';
import { Icon } from 'icons';
import styles from './DateTimeView.module.scss';
import { formatInTimeZone } from 'date-fns-tz';
import { getSystemTimezone } from './timestamp.ts';

type DateTimeViewProps = {
  timestamp: number;
};

export default function DateTimeView({ timestamp }: DateTimeViewProps) {
  return (
    <div className={styles.dateTimeView}>
      <Icon name="date" />
      <span>{format(timestamp, 'MMMM do, y')}</span>
      <span>⋅</span>
      <span>
        {formatInTimeZone(timestamp, getSystemTimezone(), 'h:mmaaa zzz')}
      </span>
    </div>
  );
}
