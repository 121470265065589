import React from 'react';
import styles from './ZeckEditMenu.module.scss';
import { Icon, IconName } from 'icons';
import cx from 'classnames';
import mergeRefs from '../../../junkDrawer/mergeRefs.ts';
import useDropdownAnimation from '../../../services/useDropdownAnimation.tsx';
import HoverNextToElement from '../../../domHelpers/hoverNextTo/HoverNextToElement.tsx';
import DetectsOutsideClick from '../../../junkDrawer/DetectsOutsideClick.tsx';

export type ZeckEditMenuProps = {
  element: React.RefObject<HTMLElement>;
  positionStrategy: React.ComponentProps<
    typeof HoverNextToElement
  >['positionStrategy'];
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
};

function ZeckEditMenu({
  element,
  positionStrategy,
  isOpen,
  onClose,
  children,
  className,
  ...otherProps
}: ZeckEditMenuProps) {
  const { elementRef, show } = useDropdownAnimation(isOpen);

  if (!show) {
    return null;
  }

  return (
    <HoverNextToElement {...{ elementRef: element, positionStrategy }}>
      <DetectsOutsideClick<HTMLDivElement>
        onClick={() => {
          isOpen && onClose();
        }}
      >
        {(detectClickRef) => (
          <div
            {...otherProps}
            className={cx(styles.zeckEditMenu, className)}
            ref={mergeRefs([detectClickRef, elementRef])}
          >
            {children}
          </div>
        )}
      </DetectsOutsideClick>
    </HoverNextToElement>
  );
}

type ZeckEditMenuItemProps = {
  iconName: IconName;
  label: React.ReactNode;
  sublabel: React.ReactNode;
  disabled?: boolean;
  onClick?: React.HTMLAttributes<HTMLElement>['onClick'];
};

export const ZeckEditMenuItem = React.forwardRef<
  HTMLButtonElement,
  ZeckEditMenuItemProps
>(function ZeckEditMenuItem(
  { iconName, label, sublabel, disabled, ...otherProps },
  forwardedRef,
) {
  return (
    <button
      {...{
        ...otherProps,
        ref: forwardedRef,
        className: styles.zeckEditMenuItem,
        disabled,
      }}
    >
      <Icon name={iconName} className={styles.zeckEditMenuItem__icon} />
      <div className={styles.zeckEditMenuItem__label}>{label}</div>
      <div className={styles.zeckEditMenuItem__sublabel}>{sublabel}</div>
    </button>
  );
});

export const ZeckEditMenuLinkItem = ({
  iconName,
  label,
  sublabel,
  disabled,
  ...otherProps
}: ZeckEditMenuItemProps) => {
  if (disabled) {
    return (
      <div
        {...{
          className: cx(
            styles.zeckEditMenuItem,
            styles.zeckEditMenuItem_disabled,
          ),
        }}
      >
        <Icon name={iconName} className={styles.zeckEditMenuItem__icon} />
        <div className={styles.zeckEditMenuItem__label}>{label}</div>
        <div className={styles.zeckEditMenuItem__sublabel}>{sublabel}</div>
      </div>
    );
  }
  return (
    <a
      {...{
        ...otherProps,
        className: styles.zeckEditMenuItem,
      }}
    >
      <Icon name={iconName} className={styles.zeckEditMenuItem__icon} />
      <div className={styles.zeckEditMenuItem__label}>{label}</div>
      <div className={styles.zeckEditMenuItem__sublabel}>{sublabel}</div>
    </a>
  );
};

export const ZeckEditMenuDivider = () => (
  <hr className={styles.zeckEditMenuDivider} />
);

export default ZeckEditMenu;
