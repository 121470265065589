import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function DividerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <line
        x1="-8.74228e-08"
        y1="12"
        x2="24"
        y2="12"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}
