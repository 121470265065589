import React from 'react';
import styles from './BlackBox.module.scss';
import cx from 'classnames';

type BlackBoxProps = React.ComponentProps<'div'>;

const BlackBox = React.forwardRef<HTMLDivElement, BlackBoxProps>(
  ({ children, className, ...props }: BlackBoxProps, ref) => {
    return (
      <div ref={ref} {...props} className={cx(styles.BlackBox, className)}>
        {children}
      </div>
    );
  },
);

export default BlackBox;
