import { createHighlightElement } from './html/HighlightElement.js';
import { Editor } from './TextNode.js';
import h from 'hyperscript';
import { createSectionLinkElement } from './html/SectionLinkElement.js';
import { createExternalLinkElement } from './html/ExternalLinkElement.js';
import { createMeetingMinutesLinkElement } from './html/MeetingMinutesLinkElement.js';
import Linkable from './html/Linkable.js';
import textNodesTo from './textNodesTo.js';

export type AppContext = {
  linkables: Linkable[];
  //mode: 'edit' | 'preview' | 'publish'
};

export function textToHtmlString(textNode: Editor.Text) {
  let innerEl: HTMLElement | string = textNode.text;

  if (textNode.format?.underline) {
    innerEl = h('u', innerEl);
  }

  if (textNode.format?.italic) {
    innerEl = h('i', innerEl);
  }

  if (textNode.format?.bold) {
    innerEl = h('b', innerEl);
  }

  return h('div', innerEl).innerHTML;
}

const textNodesToHtmlString = textNodesTo<string, AppContext>(
  {
    text: textToHtmlString,
    externalLink: (node) => (innerContent) =>
      createExternalLinkElement(node, innerContent).outerHTML,
    sectionLink: (node, context) => (innerContent: string) =>
      createSectionLinkElement(node, context, innerContent).outerHTML,
    meetingMinutesLink: (node, context) => (innerContent) =>
      createMeetingMinutesLinkElement(node, context, innerContent).outerHTML,
    highlight: (node) => (innerContent) =>
      createHighlightElement(node, innerContent).outerHTML,
  },
  {
    empty: '',
    concat: (a, b) => a.concat(b),
  },
);
/**
 * This should only be used within adaptors. Prefer using BlockEditorAdaptor.toHTMLString to use
 * "directly".
 */
export default textNodesToHtmlString;
