import styles from './CompanyDropdownMenu.module.scss';
import CompanyName from '../../CompanyName.tsx';
import HoverMenu from '../../../../design-system/organisms/HoverMenu.tsx';
import { useRef, useState } from 'react';
import { UserAndCompany } from '../../../../userAndCompany/FetchUserAndCompany.tsx';
import { CompanyMenuButton } from './CompanyMenuButton.js';

type CompanyDropdownProps = {
  userAndCompany: UserAndCompany;
};

export default function CompanyDropdownMenu({
  userAndCompany,
}: CompanyDropdownProps) {
  const [showOtherCompanies, setShowOtherCompanies] = useState(false);
  const dropdownButtonRef = useRef<HTMLLabelElement>(null);
  const renderHoverMenu = userAndCompany.userCompanies.length > 1;

  return (
    <>
      <CompanyMenuButton
        ref={dropdownButtonRef}
        companyName={userAndCompany.activeCompany.name}
        onClickDropdown={
          userAndCompany.userCompanies.length > 1
            ? () => {
                setShowOtherCompanies(true);
              }
            : undefined
        }
      />
      <HoverMenu
        elementRef={dropdownButtonRef}
        positionStrategy={(buttonElement) => {
          const buttonCoordinates = buttonElement.getBoundingClientRect();
          return [
            buttonCoordinates.x + buttonCoordinates.width - 24,
            buttonCoordinates.y + buttonCoordinates.height + 8,
          ];
        }}
        isOpen={showOtherCompanies && renderHoverMenu}
        onClose={() => {
          setShowOtherCompanies(false);
        }}
      >
        <span className={styles.companyMenu__title}>Switch Company</span>
        <div
          className={styles.companyMenu__companies}
          data-testid="other-companies"
        >
          {userAndCompany.userCompanies.map(
            (company) =>
              company.id !== userAndCompany.activeCompany.id && (
                <button
                  key={company.id}
                  data-testid="other-company"
                  className={styles.companyMenu__company}
                  onClick={() => {
                    userAndCompany.setActiveCompany(company);
                  }}
                >
                  <CompanyName
                    companyName={company.name}
                    className={styles.companyMenu__companyName}
                  />
                </button>
              ),
          )}
        </div>
      </HoverMenu>
    </>
  );
}
