type KeyConfig = {
  key: string;
  ctrlKey?: boolean;
  altKey?: boolean;
  metaKey?: boolean;
  shiftKey?: boolean;
};

const isKeyMatch = <
  E extends {
    readonly key: string;
    readonly ctrlKey: boolean;
    readonly altKey: boolean;
    readonly metaKey: boolean;
    readonly shiftKey: boolean;
  },
>(
  keyConfig: KeyConfig,
  e: E,
) => {
  return (
    e.key === keyConfig.key &&
    e.ctrlKey === !!keyConfig.ctrlKey &&
    e.altKey === !!keyConfig.altKey &&
    e.metaKey === !!keyConfig.metaKey &&
    e.shiftKey === !!keyConfig.shiftKey
  );
};

export const handleKey =
  <
    E extends {
      readonly key: string;
      readonly ctrlKey: boolean;
      readonly altKey: boolean;
      readonly metaKey: boolean;
      readonly shiftKey: boolean;
    },
  >(
    keyConfig: KeyConfig,
    handler: (e: E) => void,
  ) =>
  (e: E): boolean => {
    if (isKeyMatch(keyConfig, e)) {
      handler(e);
      return true;
    }

    return false;
  };

export const callHandlers =
  <E>(handlers: ((e: E) => boolean)[]) =>
  (e: E): boolean => {
    for (const handler of handlers) {
      if (handler(e)) return true;
    }

    return false;
  };

type Handler<E> = (e: E) => boolean | void;

// this is genericized in this way to work across react and dom handlers
export const stopPropagation =
  <E extends { stopPropagation: () => void }>(
    handler: Handler<E>,
  ): Handler<E> =>
  (e) => {
    e.stopPropagation();
    return handler(e);
  };

export const preventDefault =
  <E extends { preventDefault: () => void }>(handler: Handler<E>): Handler<E> =>
  (e) => {
    e.preventDefault();
    return handler(e);
  };

export const preventDefaultIfHandled =
  <E extends { preventDefault: () => void }>(handler: Handler<E>): Handler<E> =>
  (e) => {
    const result = handler(e);
    if (result) e.preventDefault();
    return result;
  };

export default isKeyMatch;
