import PreviewSectionPageView from './PreviewSectionPageView.tsx';
import useFetchZeckForSection from '../../useFetchZeckForSection.ts';
import { useRequiredParams } from '../../../../routing/useRequiredParams.ts';
import { getSectionIdFromSlug } from '../../../../types/Section.ts';
import UnauthorizedPage from '../../../error/unauthorized/UnauthorizedPage.tsx';
import { Navigate } from 'react-router-dom';
import { UserAndCompany } from '../../../../userAndCompany/FetchUserAndCompany.js';
import { BrandKitResource } from '../../../../design-system/zeck/WithBrandKit.js';
import { fromResultM } from '../../../../services/useFetch/useFetch.js';
import { foldResult, pipe } from '../../../../result/Result.js';
import useApi from '../../../../api/useApi.js';
import useDependentFetch from '../../../../services/useFetch/useDependentFetch.js';
import useDocumentTitleFromResult from '../../../../junkDrawer/useDocumentTitleFromResult.js';
import usePageTracking from '../../../../services/usePageTracking.ts';

const useFetchMeetingMinutesForZeck = (
  zeckResult: ReturnType<typeof useFetchZeckForSection>['zeckResult'],
) => {
  const { getZeckMeetingMinutes } = useApi();

  return useDependentFetch(fromResultM(zeckResult), (zeck) =>
    getZeckMeetingMinutes(zeck.id),
  );
};

const useFetchPrevoteDataForZeck = (
  zeckResult: ReturnType<typeof useFetchZeckForSection>['zeckResult'],
) => {
  const { getZeckPrevoteData } = useApi();

  return useDependentFetch(fromResultM(zeckResult), (zeck) =>
    getZeckPrevoteData(zeck.id),
  );
};

type PreviewSectionPageProps = {
  userAndCompany: UserAndCompany;
  onLogout: () => void;
  brandKitResource: BrandKitResource;
};

const PreviewSectionPage: React.FC<PreviewSectionPageProps> = ({
  userAndCompany,
  onLogout,
  brandKitResource,
}) => {
  const { slug } = useRequiredParams('slug');
  const sectionId = getSectionIdFromSlug(slug);
  const { zeckResult } = useFetchZeckForSection(sectionId);
  useDocumentTitleFromResult(zeckResult);
  usePageTracking('section_preview', userAndCompany);

  const meetingMinutesResult = useFetchMeetingMinutesForZeck(zeckResult);
  const prevoteDataResult = useFetchPrevoteDataForZeck(zeckResult);

  const meetingMinutes = pipe(
    meetingMinutesResult,
    foldResult({
      success: (meetingMinutes) => meetingMinutes,
      loading: () => [],
      error: () => [],
    }),
  );

  const prevoteData = pipe(
    prevoteDataResult,
    foldResult({
      success: (prevoteData) => prevoteData,
      loading: () => ({
        results: [],
        tallies: [],
        boardDirectorCount: 0,
        currentUserPrevotes: [],
        prevotedBlockIds: [],
      }),
      error: () => ({
        results: [],
        tallies: [],
        boardDirectorCount: 0,
        currentUserPrevotes: [],
        prevotedBlockIds: [],
      }),
    }),
  );

  switch (zeckResult.type) {
    case 'loading':
      return null;
    case 'error':
      return <Navigate to="/404" />;
    case 'success':
  }
  const { data: zeck } = zeckResult;

  const section = zeck.sections.find(
    ({ id }) => id === getSectionIdFromSlug(slug),
  );

  if (!section) {
    // We presume here that the user does not have access to this section of the
    // zeck, and thus needs to redirect.
    return (
      <UnauthorizedPage
        email={userAndCompany.user.email}
        onClickLogout={onLogout}
      />
    );
  }

  return (
    <PreviewSectionPageView
      {...{
        brandKitResource,
        zeck,
        section,
        userAndCompany,
        meetingMinutes,
        prevoteData,
      }}
    />
  );
};
export default PreviewSectionPage;
