import styles from './PrintInstructions.module.scss';
import cx from 'classnames';

const PrintInstructions: React.FC<{
  children?: React.ReactNode;
  className?: string;
}> = ({ children, className }) => (
  <div className={cx(styles.printInstructions, className)}>
    <div>
      <h1 className={styles.printInstructions__heading}>Print to PDF</h1>
      <p className={styles.printInstructions__description}>
        For the best output, use the following settings when saving to PDF from
        your browser’s print feature:
      </p>
      <ul className={styles.printInstructions__instructions}>
        <li>ENABLE Background Graphics</li>
        <li>DISABLE Headers and Footers</li>
      </ul>
    </div>
    {children}
  </div>
);

export default PrintInstructions;
