import { useState } from 'react';
import styles from './SectionPreviewBanner.module.scss';
import Button from '../../../../design-system/atoms/Button.tsx';
import { Icon } from 'icons';
import useBreakpoints from '../../../../services/useBreakpoints.ts';
import cx from 'classnames';
import { Zeck, Section } from '../../../../types.ts';
import isZeckChangedSinceLastPublish from '../../../../services/isZeckChangedSinceLastPublish.ts';
import PublishFlow from '../../publishAndManageViewerPermissions/PublishFlow.tsx';

export type PreviewBannerProps = {
  zeck: Zeck & {
    actions: { publish: () => Promise<void> };
  };
  section?: Section;
  onClickExitPreview(): void;
};

const PreviewBanner = ({
  zeck,
  section,
  onClickExitPreview,
}: PreviewBannerProps) => {
  const { isMobile, isTablet } = useBreakpoints();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const isChangedSincePublish = isZeckChangedSinceLastPublish(zeck, section);

  if (isMobile) {
    return (
      <div
        className={cx(
          styles.sectionPreviewBanner,
          styles.sectionPreviewBanner_small,
        )}
      >
        {zeck.isAdmin && (
          <div className={styles.sectionPreviewBanner__left}>
            {(!zeck.publishedZeck || isChangedSincePublish) && (
              <Button
                size="medium"
                color="primary"
                onClick={() => {
                  setConfirmModalOpen(true);
                }}
              >
                {zeck.publishedZeck ? 'Publish Changes' : 'Publish'}
              </Button>
            )}
          </div>
        )}

        <div className={styles.sectionPreviewBanner__right}>
          <button
            className={styles.exitPreviewButton}
            onClick={onClickExitPreview}
          >
            <Icon name="close" className={styles.exitPreviewButton_icon} />
            Exit Preview
          </button>
        </div>
        <PublishFlow
          zeck={zeck}
          isOpen={confirmModalOpen}
          canPublish={isChangedSincePublish}
          onCancel={() => {
            setConfirmModalOpen(false);
          }}
          onPublish={() => {
            setConfirmModalOpen(false);
            zeck.actions.publish();
          }}
        />
      </div>
    );
  }

  if (isTablet) {
    return (
      <div
        className={cx(
          styles.sectionPreviewBanner,
          styles.sectionPreviewBanner_small,
        )}
      >
        {zeck.isAdmin && (
          <div className={styles.sectionPreviewBanner__left}>
            {!zeck.publishedZeck && (
              <>
                You haven’t published yet.
                <Button
                  size="medium"
                  color="primary"
                  className={styles.sectionPreviewBanner__publishButton}
                  onClick={() => {
                    setConfirmModalOpen(true);
                  }}
                >
                  Publish
                </Button>
              </>
            )}

            {zeck.publishedZeck && !isChangedSincePublish && (
              <>Your published version is identical to this preview.</>
            )}

            {zeck.publishedZeck && isChangedSincePublish && (
              <>
                You have unpublished changes.
                <Button
                  size="medium"
                  color="primary"
                  className={styles.sectionPreviewBanner__publishButton}
                  onClick={() => {
                    setConfirmModalOpen(true);
                  }}
                >
                  Publish Changes
                </Button>
              </>
            )}
          </div>
        )}

        <div className={styles.sectionPreviewBanner__right}>
          <button
            className={styles.exitPreviewButton}
            onClick={onClickExitPreview}
          >
            <Icon name="close" className={styles.exitPreviewButton_icon} />
            Exit Preview
          </button>
        </div>
        <PublishFlow
          zeck={zeck}
          isOpen={confirmModalOpen}
          canPublish={isChangedSincePublish}
          onCancel={() => {
            setConfirmModalOpen(false);
          }}
          onPublish={() => {
            setConfirmModalOpen(false);
            zeck.actions.publish();
          }}
        />
      </div>
    );
  }

  return (
    <div className={styles.sectionPreviewBanner}>
      <div className={styles.sectionPreviewBanner__left}></div>
      {zeck.isAdmin && (
        <div>
          {!zeck.publishedZeck && (
            <>
              You haven’t published yet. Members won’t be able to view until you
              publish.
              <Button
                size="medium"
                color="primary"
                className={styles.sectionPreviewBanner__publishButton}
                onClick={() => {
                  setConfirmModalOpen(true);
                }}
              >
                Publish
              </Button>
            </>
          )}

          {zeck.publishedZeck && !isChangedSincePublish && (
            <>Your published version is identical to this preview.</>
          )}

          {zeck.publishedZeck && isChangedSincePublish && (
            <>
              Changes have been made since you last hit publish. Members won't
              be able to see the changes until you publish.
              <Button
                size="medium"
                color="primary"
                className={styles.sectionPreviewBanner__publishButton}
                onClick={() => {
                  setConfirmModalOpen(true);
                }}
              >
                Publish Changes
              </Button>
            </>
          )}
        </div>
      )}

      <div className={styles.sectionPreviewBanner__right}>
        <button
          className={styles.exitPreviewButton}
          onClick={onClickExitPreview}
        >
          <Icon name="close" className={styles.exitPreviewButton_icon} />
          Exit Preview
        </button>
      </div>
      {zeck.isAdmin && (
        <PublishFlow
          zeck={zeck}
          isOpen={confirmModalOpen}
          canPublish={isChangedSincePublish}
          onCancel={() => {
            setConfirmModalOpen(false);
          }}
          onPublish={() => {
            setConfirmModalOpen(false);
            zeck.actions.publish();
          }}
        />
      )}
    </div>
  );
};

export default PreviewBanner;
