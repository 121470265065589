import { JwtPayload, jwtDecode } from 'jwt-decode';

export function decodeAccessToken(accessToken: string | null) {
  if (accessToken === null) {
    return null;
  }

  try {
    const result = jwtDecode<JwtPayload>(accessToken);
    return result;
  } catch {
    return null;
  }
}
