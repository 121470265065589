import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function SidebarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M4.63636 4H19.3636C20.2636 4 21 4.84706 21 5.88235V18.1176C21 19.1529 20.2636 20 19.3636 20H4.63636C3.73636 20 3 19.1529 3 18.1176V5.88235C3 4.84706 3.73636 4 4.63636 4ZM19.3636 18.1176V5.88235H12V18.1176H19.3636ZM4.63636 18.1176H10.4118V5.88235H4.63636V18.1176ZM9.35294 6.82353H5.45455V8.23529H9.35294V6.82353ZM9.35294 9.17647H5.45455V10.5882H9.35294V9.17647ZM9.35294 11.5294H5.45455V12.9412H9.35294V11.5294Z"
        fill="black"
      />
    </svg>
  );
}
