import cx from 'classnames';
import styles from './Link.module.scss';
import { Link as RouterLink } from 'react-router-dom';

type LinkProps = {
  children: React.ReactNode;
  className?: string;
  href: string;
};

const Link = ({
  children,
  className,
  href,
  ...props
}: LinkProps & React.HTMLAttributes<HTMLAnchorElement>) => {
  return (
    <RouterLink {...props} to={href} className={cx(styles.link, className)}>
      {children}
    </RouterLink>
  );
};

export default Link;
