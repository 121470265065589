import { createBlock } from './BaseBlock.js';

type ShareClass = {
  name: string;
  authorizedShares: string;
  outstandingShares: string;
  outstandingOwnershipPercent: string;
  fullyDilutedShares: string;
  fullyDilutedOwnershipPercent: string;
  amountRaised: string;
};

type OptionPool = {
  name: string;
  fullyDilutedShares: string;
  authorizedShares: string;
  outstandingEquityAwardDerivatives: string;
  equityAwardOwnershipPercent: string;
  availableShares: string;
  availableSharesOwnershipPercent: string;
};

type Convertible = {
  name: string;
  principal: string;
  interest: string;
  outstandingDebt: string;
  amountRaised: string;
};

export type CartaCapTable = {
  fullyDilutedShares: string;
  outstandingShares: string;
  amountRaised: string;
  shareClasses: ShareClass[];
  optionPools: OptionPool[];
  convertibles: Convertible[];
  convertibleTotalPrincipal: string;
  convertibleTotalInterest: string;
  convertibleTotalOutstandingDebt: string;
  convertibleTotalAmountRaised: string;
};

export type CartaCapTableBlock = {
  id: string;
  type: 'carta-cap-table';
  data: {
    capTable: CartaCapTable;
    synchedAt: string;
  };
};

export const createCartaCapTableBlock = (
  capTable: CartaCapTable,
  synchedAt = new Date().toISOString(),
): CartaCapTableBlock =>
  createBlock('carta-cap-table', {
    data: {
      capTable,
      synchedAt,
    },
  });
