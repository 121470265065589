import { ChartIcon, GearIcon, PeopleIcon } from 'icons';
import { useCallback } from 'react';
import Toast from '../../../../design-system/molecules/toast/Toast.tsx';
import useToast from '../../../../design-system/molecules/toast/useToast.ts';
import {
  ModalContainer,
  ModalSidebarItem,
  ModalWithSidebar,
} from '../../../../design-system/organisms/Modal.js';
import { Signal } from '../../../../signals/index.js';
import ZeckSettings from '../../settings/ZeckSettings.tsx';
import AnalyticsWrapper from '../analytics/AnalyticsWrapper.tsx';
import ZeckWithActions from '../zeckCover/ZeckWithActions.ts';
import styles from './ZeckSettingsModal.module.scss';
import ZeckViewersModal from './ZeckViewersModal.tsx';

type ZeckSettingsProps = {
  zeck: ZeckWithActions;
  modalPanel$: Signal<ManageZeckModalActiveType>;
  modalOpen$: Signal<boolean>;
};

export type ManageZeckModalActiveType =
  | 'analytics'
  | 'manageViewers'
  | 'settings';

type ModalContentProps = {
  modalPanel$: Signal<ManageZeckModalActiveType>;
  zeck: ZeckWithActions;
  closeModal: () => void;
  showToast: (message: string, duration: number) => void;
};

const ModalContent: React.FC<ModalContentProps> = ({
  modalPanel$,
  zeck,
  closeModal,
  showToast,
}) => {
  const modalPanel = modalPanel$.useValue();

  return (
    <ModalWithSidebar
      sidebar={
        <>
          <ModalSidebarItem
            icon={<PeopleIcon />}
            active={modalPanel === 'manageViewers'}
            onClick={() => modalPanel$.set('manageViewers')}
            label="Manage Viewers"
          />
          <ModalSidebarItem
            icon={<ChartIcon />}
            active={modalPanel === 'analytics'}
            onClick={() => modalPanel$.set('analytics')}
            label="Analytics"
          />
          <ModalSidebarItem
            icon={<GearIcon />}
            active={modalPanel === 'settings'}
            onClick={() => modalPanel$.set('settings')}
            label="Settings"
          />
        </>
      }
      className={styles.settingsModal}
    >
      {modalPanel === 'analytics' && (
        <AnalyticsWrapper zeck={zeck} modal={true} />
      )}
      {modalPanel === 'manageViewers' && (
        <ZeckViewersModal
          zeck={zeck}
          onClose={closeModal}
          showToast={showToast}
        />
      )}
      {modalPanel === 'settings' && <ZeckSettings zeck={zeck} />}
    </ModalWithSidebar>
  );
};

export default function ZeckSettingsModal({
  zeck,
  modalPanel$,
  modalOpen$,
}: ZeckSettingsProps) {
  const open = modalOpen$.useValue();

  const closeModal = useCallback(() => {
    modalOpen$.set(false);
  }, [modalOpen$]);

  const { showToast, ...toast } = useToast();
  return (
    <>
      <ModalContainer
        isOpen={open}
        onRequestClose={closeModal}
        className={styles.addNewViewersContent}
      >
        <ModalContent
          modalPanel$={modalPanel$}
          zeck={zeck}
          closeModal={closeModal}
          showToast={showToast}
        />
      </ModalContainer>

      <Toast {...toast} />
    </>
  );
}
