import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function SpeechBubbleWithTextIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M18.4 4H5.6C4.712 4 4 4.72857 4 5.61905V16.9524C4 17.8429 4.712 18.5714 5.6 18.5714H9.6L12 21L14.4 18.5714H18.4C19.28 18.5714 20 17.8429 20 16.9524V5.61905C20 4.72857 19.28 4 18.4 4ZM18.4 16.9524H13.736L13.264 17.43L12 18.709L10.728 17.4219L10.264 16.9524H5.6V5.61905H18.4V16.9524Z"
        fill="white"
      />
      <path d="M7.2 8.04762V9.66667H16.8V8.04762H7.2Z" fill="white" />
      <path d="M7.2 11.2857H13.736V12.9048H7.2V11.2857Z" fill="white" />
    </svg>
  );
}
