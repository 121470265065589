import styles from './SectionView.module.scss';

function AppendixCard() {
  return (
    <div className={styles.appendixCard}>
      <div className={styles.appendixCard__title}>Appendix</div>
    </div>
  );
}

export default AppendixCard;
