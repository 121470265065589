import useApi from '../../../api/useApi.ts';
import { useState } from 'react';
import InputText from '../../../design-system/atoms/InputText.tsx';
import Toast from '../../../design-system/molecules/toast/Toast.tsx';
import AdminPanel, {
  AdminPanelField,
  AdminPanelFormContainer,
  AdminPanelSubmit,
} from '../adminPanel/AdminPanel.tsx';

const DuplicateZeckToCompany: React.FC = () => {
  const { duplicateZeckToCompany } = useApi();

  const [formState, setFormState] = useState({
    zeckUrl: '',
    companyUrl: '',
  });
  const [invalidZeckUrl, setInvalidZeckUrl] = useState(false);

  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const setFormStateValue =
    (fieldName: keyof typeof formState) => (value: string) =>
      setFormState((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));

  return (
    <AdminPanel
      title={'Re-Verify Our Range to Target'}
      subtitle={'(Dupe a Zeck Across Companies)'}
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          if (!formState.zeckUrl.includes('/cover/edit')) {
            setInvalidZeckUrl(true);
            return;
          }

          await duplicateZeckToCompany(
            formState.zeckUrl.replace('/cover/edit', '').slice(-36),
            formState.companyUrl.slice(-36),
          );

          setShowSuccessToast(true);
        }}
      >
        <AdminPanelFormContainer>
          <AdminPanelField description="Enter the Cover Page URL of the Zeck to be duplicated to a different company">
            <InputText
              error={
                invalidZeckUrl &&
                'Zeck URL is not valid. Ensure it is for the Zeck cover'
              }
              label="Zeck URL"
              value={formState.zeckUrl}
              onChange={setFormStateValue('zeckUrl')}
            />
          </AdminPanelField>
          <AdminPanelField description="Enter the URL of the Company homescreen where you want it duped">
            <InputText
              label="Company URL"
              value={formState.companyUrl}
              onChange={setFormStateValue('companyUrl')}
            />
          </AdminPanelField>
          <AdminPanelSubmit>Duplicate Zeck</AdminPanelSubmit>
        </AdminPanelFormContainer>
      </form>

      <Toast
        message={`wow it worked ${String.fromCodePoint(0x1f354)}`}
        isOpen={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
      />
    </AdminPanel>
  );
};

export default DuplicateZeckToCompany;
