import { TableBlock, compressTableBlock } from 'editor-content/TableBlock.js';
import { useEffect, useRef, useState } from 'react';
import {
  CredentialResponse,
  ValidCredentialResponse,
} from '../../../../api/endpoints/createCredentialApi.ts';
import {
  IntegrationListItem,
  IntegrationListItemData,
} from '../../../../api/endpoints/createIntegrationApi.ts';
import useApi from '../../../../api/useApi.ts';
import { ModalContainer } from '../../../../design-system/organisms/Modal.tsx';
import createTableFromGoogleSheet from '../addBlock/integrationsModal/utils/google/createTableFromGoogleSheet.ts';
import useLoadGoogleAPIs, {
  GoogleApis,
} from '../addBlock/integrationsModal/utils/google/useLoadGoogleAPIs.ts';

import { ZeckCommunicationLoader } from '../addBlock/integrationsModal/IntegrationLoader.tsx';
import MissingSheetModalContent from '../addBlock/integrationsModal/MissingSheetModalContent.tsx';
import ReconnectModalContent from '../addBlock/integrationsModal/ReconnectModalContent.tsx';
import { makeTokenObjectFromCredential } from '../addBlock/integrationsModal/utils/google/googleUtils.ts';
import { GoogleSyncingState } from './InteractiveState.ts';

type GoogleTableSyncFlowProps = {
  block: TableBlock;
  getEl: () => HTMLElement | undefined;
  onReplaceTable: (block: TableBlock) => void;
  integrationData: IntegrationListItemData;
  onSyncComplete: () => void;
  integration: IntegrationListItem;
};

const GoogleTableSyncFlow: React.FC<GoogleTableSyncFlowProps> = ({
  block,
  integrationData,
  onReplaceTable,
  onSyncComplete,
  integration,
}) => {
  const initialized = useRef(false);
  const [interactiveState, setInteractiveState] = useState<GoogleSyncingState>({
    type: 'syncing',
  });
  const { updateIntegration, fetchCredentialResponse: fetchCredential } =
    useApi();

  const googleApiRef = useRef<GoogleApis | null>(null);
  const googleApis = useLoadGoogleAPIs((googleApis) => {
    googleApiRef.current = googleApis;
  });

  const { updateIntegration: updateClientIntegration } = integrationData;

  useEffect(() => {
    (async () => {
      // fetch credential for integration with token
      if (!initialized.current) {
        initialized.current = true;
        const credentials = await fetchCredential(integration.credentialId);
        handleCredentialResponse(credentials, integration);
      }
    })();
  });

  function updateTableBlockFromSheet(
    sheet: gapi.client.sheets.Sheet,
    credential: ValidCredentialResponse,
  ) {
    let updatedTableBlock: TableBlock['data'] | undefined;
    try {
      updatedTableBlock = createTableFromGoogleSheet(sheet);
    } catch {}

    const attemptedSheet = sheet.properties?.title ?? 'Sheet';
    if (!updatedTableBlock) {
      setInteractiveState({
        type: 'not-found',
        credential: credential,
        notFoundType: 'rows',
        attemptedSheet: attemptedSheet,
      });
      return;
    }

    onReplaceTable(
      compressTableBlock(
        TableBlock(block.id, updatedTableBlock, block.integrationId),
      ),
    );

    onSyncComplete();
  }

  async function updateTableWithGoogleCredential(
    credential: ValidCredentialResponse,
    integration: IntegrationListItem,
  ) {
    let gapi: typeof window.gapi;
    if (!googleApis) {
      // this kinda sucks but possible to sync before google apis are loaded so using this to wait for them
      while (!googleApiRef?.current?.gapi) {
        await new Promise((r) => setTimeout(r, 1));
      }

      gapi = googleApiRef.current.gapi;
    } else {
      gapi = googleApis.gapi;
    }

    gapi.auth.setToken(makeTokenObjectFromCredential(credential.accessToken));

    let sheetData: gapi.client.sheets.Sheet | undefined;

    try {
      const sheetResponse = await gapi.client.sheets.spreadsheets.get({
        spreadsheetId: integration.providerMeta.documentId,
        ranges: [integration.providerMeta.selectionName],
        includeGridData: true,
      });

      sheetData = sheetResponse?.result?.sheets?.[0];
    } catch {}

    if (!sheetData) {
      setInteractiveState({
        type: 'not-found',
        notFoundType: 'sheet',
        credential: credential,
        attemptedSheet: integration.providerMeta.selectionName,
      });
      return;
    }

    updateTableBlockFromSheet(sheetData, credential);
  }

  const handleCredentialResponse = async (
    credential: CredentialResponse,
    integration: IntegrationListItem,
  ) => {
    if (credential.status == 'error') {
      setInteractiveState({
        type: 'error',
        provider: 'google',
        credential,
        errorType: 'credentialError',
      });
      return;
    }

    await updateTableWithGoogleCredential(credential, integration);
  };

  return (
    <>
      <ModalContainer isOpen>
        {interactiveState.type === 'syncing' && <ZeckCommunicationLoader />}
        {/* could eventually move this to the google integration flow */}
        {interactiveState.type === 'not-found' && (
          <MissingSheetModalContent
            integrationProviderMeta={integration.providerMeta}
            missingDataType={interactiveState.notFoundType}
            onPickSheet={async (sheet, rangeName) => {
              const returnedIntegration = await updateIntegration({
                ...integration,
                providerMeta: {
                  documentId: integration.providerMeta.documentId,
                  documentName:
                    sheet.properties?.title ??
                    integration.providerMeta.documentName,
                  selectionName:
                    rangeName ??
                    sheet.properties?.title ??
                    integration.providerMeta.selectionName,
                  selectionType: rangeName ? 'range' : 'sheet',
                  selectionId: sheet.properties?.sheetId ?? 0,
                },
              });

              updateClientIntegration({
                ...returnedIntegration,
                userId: integration.userId,
              });

              updateTableBlockFromSheet(sheet, interactiveState.credential);
            }}
            credential={interactiveState.credential}
            onClose={onSyncComplete}
          />
        )}

        {interactiveState.type === 'error' &&
          interactiveState.provider == 'google' && (
            <ReconnectModalContent
              onReconnected={async (credential) => {
                await handleCredentialResponse(credential, integration);
              }}
              credential={interactiveState.credential}
              onClose={onSyncComplete}
            />
          )}
      </ModalContainer>
    </>
  );
};

export default GoogleTableSyncFlow;
