import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function DoubleChevronRightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <g clipPath="url(#clip0_14223_57453)">
        <path
          d="M12.59 7.41L17.17 12L12.59 16.59L14 18L20 12L14 6L12.59 7.41Z"
          fill="#8F90A6"
        />
        <path
          d="M4.59 7.41L9.17 12L4.59 16.59L6 18L12 12L6 6L4.59 7.41Z"
          fill="#8F90A6"
        />
      </g>
      <defs>
        <clipPath id="clip0_14223_57453">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 -1 24 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
