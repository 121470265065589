import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function HelpIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M7.33398 11.9997H8.66732V10.6663H7.33398V11.9997ZM8.00065 1.33301C4.32065 1.33301 1.33398 4.31967 1.33398 7.99967C1.33398 11.6797 4.32065 14.6663 8.00065 14.6663C11.6807 14.6663 14.6673 11.6797 14.6673 7.99967C14.6673 4.31967 11.6807 1.33301 8.00065 1.33301ZM8.00065 13.333C5.06065 13.333 2.66732 10.9397 2.66732 7.99967C2.66732 5.05967 5.06065 2.66634 8.00065 2.66634C10.9407 2.66634 13.334 5.05967 13.334 7.99967C13.334 10.9397 10.9407 13.333 8.00065 13.333ZM8.00065 3.99967C6.52732 3.99967 5.33398 5.19301 5.33398 6.66634H6.66732C6.66732 5.93301 7.26732 5.33301 8.00065 5.33301C8.73398 5.33301 9.33398 5.93301 9.33398 6.66634C9.33398 7.99967 7.33398 7.83301 7.33398 9.99967H8.66732C8.66732 8.49967 10.6673 8.33301 10.6673 6.66634C10.6673 5.19301 9.47398 3.99967 8.00065 3.99967Z"
        fill="#8F90A6"
      />
    </svg>
  );
}
