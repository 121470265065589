import { ButtonSizedLoader } from './LoadingButton.tsx';
import OutlineButton from '../atoms/OutlineButton.tsx';

type ButtonWithLoadingProps = React.ComponentProps<typeof OutlineButton> & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading: boolean;
};

const ButtonWithLoading: React.FC<ButtonWithLoadingProps> = ({
  onClick,
  isLoading,
  children,
  ...props
}) => {
  return (
    <OutlineButton {...props} onClick={onClick}>
      <span style={isLoading ? { opacity: 0 } : {}}>{children}</span>
      {isLoading && <ButtonSizedLoader style={{ position: 'absolute' }} />}
    </OutlineButton>
  );
};

export default ButtonWithLoading;
