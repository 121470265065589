import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function LabelIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M8.59133 6.878C8.59133 6.70067 8.66599 6.612 8.81533 6.612H10.8173C10.9667 6.612 11.0413 6.696 11.0413 6.864V15.026C11.0413 15.0913 11.0507 15.138 11.0693 15.166C11.0973 15.194 11.1393 15.208 11.1953 15.208H15.6613C15.8293 15.208 15.9133 15.278 15.9133 15.418V16.748C15.9133 16.8227 15.89 16.8833 15.8433 16.93C15.7967 16.9767 15.7313 17 15.6473 17H8.89933C8.78733 17 8.70799 16.9767 8.66133 16.93C8.61466 16.8833 8.59133 16.8087 8.59133 16.706V6.878Z"
        fill="#8F90A6"
      />
    </svg>
  );
}
