import { Icon } from 'icons';
import useApi from '../../../../../api/useApi.js';
import Button from '../../../../../design-system/atoms/Button.js';
import Modal, {
  ModalPanel,
} from '../../../../../design-system/organisms/Modal.js';
import { Section, Zeck } from '../../../../../types.js';
import AddPeople from '../../../publishAndManageViewerPermissions/AddPeople.js';
import styles from './AddContributorsModal.module.scss';

type AddContributorsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  zeck: Zeck;
  section: Section;
};

const AddContributorsModal = ({
  isOpen,
  onClose,
  zeck,
  section,
}: AddContributorsModalProps) => {
  const {
    getSectionContributorPermissions,
    updateSectionContributorPermission,
  } = useApi();

  const onUpdatePerson = async (userId: string, enabled: boolean) => {
    await updateSectionContributorPermission({
      companyId: zeck.companyId,
      zeckId: zeck.id,
      sectionId: section.id,
      userId,
      enabled,
    });
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.modal}>
      <ModalPanel className={styles.addContributors}>
        <AddPeople
          zeckId={zeck.id}
          onCancel={onClose}
          fetchPeopleWithRoles={async ({ zeckId, cursor }) =>
            await getSectionContributorPermissions(
              zeck.companyId,
              zeckId,
              section.id,
              cursor,
            )
          }
          description={
            <div className={styles.addPeopleHeader}>
              <Icon name="people" />
              <span>Add/Remove Contributors to {section.title}</span>
            </div>
          }
          accessWarning="Contributors will be able to edit this Section."
          accessDescription="Contributors will have access to this section and will able to add content and make edits."
          updatePerson={onUpdatePerson}
          actionsElement={
            <Button
              className={styles.addContributorButton}
              onClick={onClose}
              size="small"
              color="primary"
            >
              Done
            </Button>
          }
        />
      </ModalPanel>
    </Modal>
  );
};

export default AddContributorsModal;
