import omit from 'lodash/omit.js';
import { Link, LinkProps } from 'react-router-dom';

type MaybeLinkProps = Omit<LinkProps, 'enabled'> & {
  enabled: boolean;
};

const MaybeLink = (props: MaybeLinkProps) => {
  if (props.enabled) {
    return <Link {...omit(props, 'enabled')}>{props.children}</Link>;
  }

  return <div className={props.className}>{props.children}</div>;
};

export default MaybeLink;
