import { useCallback, useEffect, useState } from 'react';

const useLocalStorageState = (
  itemKey: string,
): [string | null, (newVal: string | null) => void] => {
  const readValue = useCallback(() => localStorage.getItem(itemKey), [itemKey]);

  const initialValue = readValue();
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === itemKey) {
        setState(readValue());
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [itemKey, readValue]);

  const setValue = (newValue: string | null) => {
    if (newValue === null) {
      localStorage.removeItem(itemKey);
    } else {
      localStorage.setItem(itemKey, newValue);
    }
    setState(newValue);
  };

  return [state, setValue];
};

export default useLocalStorageState;
