import styles from './PeopleListEmail.module.scss';
import cx from 'classnames';

type PeopleListEmailProps = {
  pending?: boolean;
  children: React.ReactNode;
};

export const PeopleListEmail: React.FunctionComponent<PeopleListEmailProps> = ({
  children,
  pending,
}) => {
  return (
    <span
      data-testid="person-email"
      className={cx(styles.email, pending && styles.email_pending)}
    >
      {children}
    </span>
  );
};
