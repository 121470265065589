import h from 'hyperscript';
import { Editor, MeetingMinutesLink } from '../TextNode.js';
import { AppContext } from '../textNodesToHtmlString.js';

interface MeetingMinutesLinkElement extends HTMLAnchorElement {
  dataset: {
    nodetype: 'meeting-minutes-link';
    meetingminutesid: string;
  };
}

export const createMeetingMinutesLinkElement = (
  node: Editor.MeetingMinutesLink,
  context: AppContext,
  innerHTML: string,
) => {
  const minutesLink = context.linkables.find(
    ({ itemId }) => node.meetingMinutesId === itemId,
  );

  const dataItemMissing = minutesLink
    ? {}
    : {
        'data-itemMissing': true,
      };

  const link = h('a', {
    href: '#',
    target: '_blank',
    rel: 'noreferrer',
    'data-meetingminutesid': node.meetingMinutesId,
    'data-nodetype': 'meeting-minutes-link',
    ...dataItemMissing,
  });

  link.innerHTML = innerHTML;

  return link;
};

export const getMeetingMinutesLinkFromElement = (
  element: HTMLElement,
  content: Editor.Text[],
) => {
  if (isMeetingMinutesLinkElement(element)) {
    return MeetingMinutesLink(element.dataset.meetingminutesid, content);
  }

  return null;
};

export const isMeetingMinutesLinkElement = (
  el: HTMLElement,
): el is MeetingMinutesLinkElement =>
  el.dataset.nodetype === 'meeting-minutes-link';

export default MeetingMinutesLinkElement;
