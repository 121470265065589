import isArray from 'lodash/isArray.js';
import isObject from 'lodash/isObject.js';
import includes from 'lodash/includes.js';
import omitBy from 'lodash/omitBy.js';
import isEqualWith from 'lodash/isEqualWith.js';
import { isVoteBlock } from 'editor-content/VoteBlock.js';
import { EditorContent } from './useEditorState.js';

const ignoreVoteMethods = (content: EditorContent) => {
  const { title, headline, body } = content;

  return {
    title,
    headline,
    body: body.map((block) => {
      if (!isVoteBlock(block)) return block;

      return {
        ...block,
        takeVote: null,
      };
    }),
  };
};

export const comparisonFuncOmitUndefined = <
  T extends Record<string | number, unknown>,
>(
  a: T,
  b: T,
): boolean | undefined => {
  if (isArray(a) || isArray(b)) return;
  if (!isObject(a) || !isObject(b)) return;

  if (!includes(a, undefined) && !includes(b, undefined)) return;

  // Call recursively, after filtering all undefined properties
  return isEqualWith(
    omitBy(a, (value) => value === undefined),
    omitBy(b, (value) => value === undefined),
    comparisonFuncOmitUndefined,
  );
};

const isEditorContentEqual = (a: EditorContent, b: EditorContent): boolean => {
  return isEqualWith(
    ignoreVoteMethods(a),
    ignoreVoteMethods(b),
    comparisonFuncOmitUndefined,
  );
};

export default isEditorContentEqual;
