import { createParagraphBlock, ParagraphBlock } from 'editor-content/Block.js';
import splitTextNodesFromContentSelection from '../textNode/splitTextNodesFromContentSelection.js';

import { ContentPatch } from '../../ContentPatch.js';
import ContentSelection from '../../selection/contentSelection/ContentSelection.js';

export default function paragraphPressEnterStrategy(
  block: ParagraphBlock,
  selection: ContentSelection,
): ContentPatch<[ParagraphBlock, ParagraphBlock]> {
  const [beforeSelectionTextNodes, , afterSelectionTextNodes] =
    splitTextNodesFromContentSelection(block.content, selection);

  return {
    contentSubset: [
      ParagraphBlock(block.id, beforeSelectionTextNodes),
      createParagraphBlock(afterSelectionTextNodes),
    ],
    selection: {
      index: 1,
      offset: {
        anchorOffset: 0,
        focusOffset: 0,
      },
    },
  };
}
