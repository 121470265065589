import { Icon } from 'icons';
import styles from './VoteControlHeading.module.scss';
import WithTooltip from '../../organisms/WithTooltip.tsx';

const VoteControlHeading: React.FC<{
  children: React.ReactNode;
  tooltip: string;
}> = ({ children, tooltip }) => {
  return (
    <div className={styles.voteControl__heading}>
      <div className={styles.voteControl__label}>{children}</div>

      <WithTooltip<HTMLDivElement> text={tooltip}>
        {(ref, listeners) => (
          <div className={styles.voteControl__tooltip} ref={ref} {...listeners}>
            <Icon name="help" />
          </div>
        )}
      </WithTooltip>
    </div>
  );
};

export default VoteControlHeading;
