import Point from '../../../domHelpers/Point.js';
import { useState } from 'react';
import Rect, { isIntersecting } from '../../../domHelpers/Rect.js';
import useThrottle from '../../../pages/zeck/edit/useThrottle.js';

export type SelectionRange = {
  anchorIndex: number;
  focusIndex: number;
};

type SelectionState =
  | { type: 'not-selecting' }
  | { type: 'selecting'; startPoint: Point };

function useMouseSelection(
  rectGetters: (() => Rect)[],
  onSelect: (selection: SelectionRange | null) => void,
) {
  const [selectionState, setSelectionState] = useState<SelectionState>({
    type: 'not-selecting',
  });

  const onSelectThrottled = useThrottle(onSelect, 1000 / 15);

  return {
    selectionStart(point: Point) {
      setSelectionState({
        type: 'selecting',
        startPoint: point,
      });
    },
    selectionMove(point: Point) {
      if (selectionState.type !== 'selecting') return;

      const { startPoint } = selectionState;

      let firstIntersectingRectIndex: number | undefined;
      let lastIntersectingRectIndex: number | undefined;

      rectGetters.forEach((getter, i) => {
        const intersecting = isIntersecting([startPoint, point], getter());
        if (firstIntersectingRectIndex === undefined && intersecting) {
          firstIntersectingRectIndex = i;
          lastIntersectingRectIndex = i;

          return;
        }

        if (intersecting) {
          lastIntersectingRectIndex = i;
        }
      });

      if (
        firstIntersectingRectIndex === undefined ||
        lastIntersectingRectIndex === undefined
      ) {
        onSelectThrottled(null);
      } else {
        onSelectThrottled(
          startPoint[1] < point[1]
            ? {
                anchorIndex: firstIntersectingRectIndex,
                focusIndex: lastIntersectingRectIndex,
              }
            : {
                anchorIndex: lastIntersectingRectIndex,
                focusIndex: firstIntersectingRectIndex,
              },
        );
      }
    },
    selectionEnd() {
      // don't cause rerenders if we don't need to
      if (selectionState.type === 'not-selecting') return;
      setSelectionState({ type: 'not-selecting' });
    },
    isSelecting: selectionState.type === 'selecting',
  };
}

export default useMouseSelection;
