import { EditorContent } from '../../edit/useEditorState.js';
import ZeckEditorSelection from '../ZeckEditorSelection.js';
import {
  TitleContent,
  TitleSelection,
  TitleState,
} from '../TitleEditor/TitleEditor.js';
import {
  HeadlineContent,
  HeadlineSelection,
  HeadlineState,
} from '../HeadlineEditor/HeadlineEditor.js';
import {
  BodyContent,
  BodySelection,
  BodyState,
} from '../BodyEditor/BodyEditor.js';
import { pipe } from '../../../../result/Result.js';

export type ZeckEditorState = {
  content: EditorContent;
  selection: ZeckEditorSelection;
};

export const applyTitleContent =
  (titleContent: TitleContent) =>
  (editorState: ZeckEditorState): ZeckEditorState => ({
    content: {
      ...editorState.content,
      title: titleContent,
    },
    selection: editorState.selection,
  });

export const applyTitleSelection =
  (titleSelection: TitleSelection | null) =>
  (editorState: ZeckEditorState): ZeckEditorState => ({
    content: editorState.content,
    selection: titleSelection && {
      target: 'title',
      ...titleSelection,
    },
  });

export const applyTitleState =
  (titleState: TitleState) =>
  (editorState: ZeckEditorState): ZeckEditorState =>
    pipe(
      editorState,
      applyTitleContent(titleState.content),
      applyTitleSelection(titleState.selection),
    );

export const applyHeadlineContent =
  (headlineContent: HeadlineContent) =>
  (editorState: ZeckEditorState): ZeckEditorState => ({
    content: {
      ...editorState.content,
      headline: headlineContent,
    },
    selection: editorState.selection,
  });

export const applyHeadlineSelection =
  (headlineSelection: HeadlineSelection | null) =>
  (editorState: ZeckEditorState): ZeckEditorState => ({
    content: editorState.content,
    selection: headlineSelection && {
      target: 'headline',
      ...headlineSelection,
    },
  });

export const applyHeadlineState =
  (headlineState: HeadlineState) =>
  (editorState: ZeckEditorState): ZeckEditorState =>
    pipe(
      editorState,
      applyHeadlineContent(headlineState.content),
      applyHeadlineSelection(headlineState.selection),
    );

export const applyBodyContent =
  (bodyContent: BodyContent) =>
  (editorState: ZeckEditorState): ZeckEditorState => ({
    content: {
      ...editorState.content,
      body: bodyContent,
    },
    selection: editorState.selection,
  });

export const applyBodySelection =
  (bodySelection: BodySelection | null) =>
  (editorState: ZeckEditorState): ZeckEditorState => ({
    content: editorState.content,
    selection: bodySelection && {
      target: 'body',
      ...bodySelection,
    },
  });

export const applyBodyState =
  (bodyState: BodyState) =>
  (editorState: ZeckEditorState): ZeckEditorState =>
    pipe(
      editorState,
      applyBodyContent(bodyState.content),
      applyBodySelection(bodyState.selection),
    );

export const deselect = (editorState: ZeckEditorState): ZeckEditorState => ({
  content: editorState.content,
  selection: null,
});
