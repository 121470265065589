import normalizeTextNodes from 'editor-content/normalizeTextNodes.ts';
import { Editor, isHighlight, TextNode } from 'editor-content/TextNode.js';

function removeHighlightFromTextNodes(
  textNodes: TextNode[],
  highlightId: string,
): TextNode[] {
  const newContent: TextNode[] = [];
  textNodes.forEach((textNode) => {
    if (isHighlight(textNode)) {
      const newNodes = unrollHighlight(highlightId, textNode);
      newContent.push(...newNodes);
    } else {
      newContent.push(textNode);
    }
  });

  return normalizeTextNodes(newContent);
}

export function unrollHighlight(
  highlightId: string,
  highlightNode: Editor.Highlight,
): TextNode[] {
  let newNodes: TextNode[] = [];
  const filteredHighlightIds = highlightNode.ids.filter((id) => {
    return id !== highlightId;
  });
  if (filteredHighlightIds.length === 0) {
    newNodes = highlightNode.content;
  } else {
    newNodes.push({
      type: 'highlight',
      ids: filteredHighlightIds,
      content: highlightNode.content,
    });
  }

  return newNodes;
}

export default removeHighlightFromTextNodes;
