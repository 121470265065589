import Point from '../Point.js';
import PositionStrategyElem, {
  BoundingClientRectEle,
} from './positionStrategy/PositionStrategy.js';

const withinContainer =
  (
    containerRef: React.RefObject<HTMLElement>,
    positionStrategy: (
      targetElement: BoundingClientRectEle,
      popoverElement: BoundingClientRectEle,
    ) => Point | null,
  ): PositionStrategyElem =>
  (targetElement, popoverElement) => {
    const whereItWouldHaveBeen = positionStrategy(
      targetElement,
      popoverElement,
    );

    if (whereItWouldHaveBeen === null) {
      return whereItWouldHaveBeen;
    }

    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();

      if (
        containerRect.bottom < whereItWouldHaveBeen[1] ||
        containerRect.top > whereItWouldHaveBeen[1] + 16 // a little leeway for if the input is slightly out of bounds
      ) {
        return null;
      }
    }
    return whereItWouldHaveBeen;
  };

export default withinContainer;
