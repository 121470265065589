import { useCallback, useState } from 'react';
import KebabMenuButton from '../../design-system/organisms/KebabMenuButton.tsx';
import { ReactComponent as TrashBinImage } from '../../images/TrashBin.svg';
import defaultImagePath from '../../images/defaultZeckCoverBackground.jpg';
import formatTimeAgo from '../../services/formatTimeAgo.ts';
import { ZeckLite } from '../../types.ts';
import UploadedImage from '../zeck/UploadedImage.tsx';
import ZeckList, { ZeckListTitle, ZeckListWrapper } from './ZeckList.tsx';
import styles from './ZeckTrashPage.module.scss';
import zeckCardStyles from './card/ZeckCard.module.scss';
import ConfirmationModal from '../../design-system/organisms/ConfirmationModal.tsx';

type ZeckTrashPageProps = {
  zecks: ZeckLite[];
  restoreZeck: (zeckId: string) => Promise<void>;
  destroyZeck: (zeckId: string) => Promise<void>;
};

const ZeckCoverImage = ({ coverImageId }: { coverImageId: string | null }) => {
  if (coverImageId) {
    return (
      <UploadedImage
        imageId={coverImageId}
        spinnerSize="medium"
        alt="zeck cover"
        imagePreset="thumbnail"
      />
    );
  }

  return <img src={defaultImagePath} alt="zeck cover" />;
};

const DeletedZeckCard = ({
  zeck,
  restoreZeck,
  destroyZeck,
}: {
  zeck: ZeckLite;
  restoreZeck: ZeckTrashPageProps['restoreZeck'];
  destroyZeck: ZeckTrashPageProps['destroyZeck'];
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const openMenu = useCallback(() => setMenuOpen(true), []);
  const closeMenu = useCallback(() => setMenuOpen(false), []);

  const deletedString = zeck.trashedAt
    ? `Deleted ${formatTimeAgo(zeck.trashedAt)}`
    : '';

  return (
    <li data-testid="zeck-card" className={zeckCardStyles.zeckCard}>
      <div className={zeckCardStyles.zeckCard__cover}>
        <ZeckCoverImage coverImageId={zeck.coverImageId} />
      </div>
      <div className={zeckCardStyles.zeckCard__bottom}>
        <div className={zeckCardStyles.zeckCard__caption}>{zeck.name}</div>
        <div className={zeckCardStyles.zeckCard__subcaption}>
          {deletedString}
        </div>
        <div className={zeckCardStyles.zeckCard__right}>
          <KebabMenuButton
            onOpen={openMenu}
            isOpen={menuOpen}
            onClose={closeMenu}
            items={[
              {
                iconName: 'restore',
                label: 'Restore',
                onClick: () => {
                  restoreZeck(zeck.id);
                },
              },
              {
                iconName: 'trash',
                label: 'Delete Permanently',
                color: 'danger',
                Modal: ({ isOpen, onClose }) => (
                  <ConfirmationModal
                    isOpen={isOpen}
                    title={'Delete Zeck?'}
                    onClose={onClose}
                    onClickConfirm={() => destroyZeck(zeck.id)}
                    body={
                      <>
                        Permanently delete <i>{zeck.name}?</i>
                        <div>You won't be able to get it back.</div>
                      </>
                    }
                    confirmText={'Yes, Delete'}
                  />
                ),
              },
            ]}
            ariaLabel={'manage zeck'}
            drawerHeading="Manage Zeck"
            enableDrawer={true}
          />
        </div>
      </div>
    </li>
  );
};

const DeletedZeckList = ({
  zecks,
  restoreZeck,
  destroyZeck,
}: {
  zecks: ZeckLite[];
  restoreZeck: ZeckTrashPageProps['restoreZeck'];
  destroyZeck: ZeckTrashPageProps['destroyZeck'];
}) => {
  return (
    <ZeckList>
      {zecks.map((zeck) => (
        <DeletedZeckCard
          key={zeck.id}
          zeck={zeck}
          restoreZeck={restoreZeck}
          destroyZeck={destroyZeck}
        />
      ))}
    </ZeckList>
  );
};

const DeletedZecksEmpty = () => {
  return (
    <div className={styles.emptyZeckMessage}>
      <div>
        <TrashBinImage />
      </div>
      <div className={styles.zeckEmptyTitle}>Trash is Empty</div>
      <div className={styles.zeckEmptyMessage}>
        There are no Zecks in the Trash Bin
      </div>
    </div>
  );
};

const ZecksTrashedHomepageContent = ({
  restoreZeck,
  destroyZeck,
  zecks,
}: ZeckTrashPageProps) => {
  return (
    <ZeckListWrapper>
      <ZeckListTitle title="Deleted Zecks" />
      {zecks.length === 0 ? (
        <DeletedZecksEmpty />
      ) : (
        <DeletedZeckList
          zecks={zecks}
          restoreZeck={restoreZeck}
          destroyZeck={destroyZeck}
        />
      )}
    </ZeckListWrapper>
  );
};

export default ZecksTrashedHomepageContent;
