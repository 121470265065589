import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MagicLink } from '../../api/endpoints/createMagicLinkApi.ts';
import { RequestError } from '../../api/makeMakeApiRequest.ts';
import useApi from '../../api/useApi.ts';
import { SigninPageProps } from '../auth/signin/SigninPage.tsx';
import MagicLinkSignUpPage from './MagicLinkSignUpPage.tsx';
import RequestMagicLinkWorkflow from './RequestMagicLinkWorkflow.tsx';
import { useRequiredParams } from '../../routing/useRequiredParams.ts';

export type MagicLinkProps = {
  setAccessToken: (token: string) => void;
  existingSignin?: boolean;
} & SigninPageProps;

type UiState =
  | 'page-load'
  | 'validating-magic-link'
  | 'valid-magic-link'
  | 'pending-user-magic-link'
  | 'expired-magic-link'
  | 'invalid-magic-link';

export default function MagicLinkPage({
  onSignIn,
  setAccessToken,
  existingSignin = false,
}: MagicLinkProps) {
  const [uiState, setUiState] = useState<UiState>('page-load');
  const [magicLink, setMagicLink] = useState<MagicLink>();
  const { getMagicLink, magicLinkSignUp } = useApi();
  const navigate = useNavigate();
  const params = useRequiredParams('id');

  useEffect(() => {
    if (uiState !== 'page-load') {
      return;
    }

    (async () => {
      try {
        setUiState('validating-magic-link');
        const magicLink = await getMagicLink(params.id);
        setMagicLink(magicLink);

        if (magicLink.expired) {
          setUiState('expired-magic-link');
        } else if (magicLink.pendingUser) {
          setUiState('pending-user-magic-link');
        } else {
          setUiState('valid-magic-link');
        }
      } catch (e) {
        const error = e as RequestError;
        if (error.status === 404 || error.status === 400) {
          setUiState('invalid-magic-link');
        }
      }
    })();
  }, [uiState, params.id, getMagicLink]);

  useEffect(() => {
    if (uiState !== 'invalid-magic-link') {
      return;
    }

    navigate('/not-found');
  }, [uiState, navigate]);

  useEffect(() => {
    if (
      uiState !== 'valid-magic-link' ||
      !magicLink ||
      !magicLink.accessToken
    ) {
      return;
    }

    setAccessToken(magicLink.accessToken);
    window.location.assign(`${window.location.origin}${magicLink.path}`);
  }, [uiState, magicLink, setAccessToken]);

  if (uiState === 'pending-user-magic-link' && magicLink) {
    return (
      <MagicLinkSignUpPage
        companyName={magicLink.companyName}
        onSubmit={async (firstName, lastName) => {
          const token = await magicLinkSignUp(
            magicLink.id,
            firstName,
            lastName,
          );
          navigate(magicLink.path);
          setAccessToken(token);
        }}
      />
    );
  }

  if (uiState === 'expired-magic-link' && magicLink) {
    const redirectPath = magicLink.path;

    async function signInAndRedirect(
      ...args: Parameters<SigninPageProps['onSignIn']>
    ): ReturnType<SigninPageProps['onSignIn']> {
      const accessToken = await onSignIn(...args);
      navigate(redirectPath);
      return accessToken;
    }

    if (existingSignin) {
      navigate(redirectPath); // you already were signed in but re-used a magic link
    } else if (magicLink.zeckId) {
      return (
        <RequestMagicLinkWorkflow
          onSignIn={signInAndRedirect}
          zeckId={magicLink.zeckId}
          path={magicLink.path}
          error="If you’re already trying to sign in from a link, the link may have expired. Please re-enter your email address and we’ll send you a new one."
        />
      );
    } else if (magicLink.sectionId) {
      return (
        <RequestMagicLinkWorkflow
          onSignIn={signInAndRedirect}
          sectionId={magicLink.sectionId}
          path={magicLink.path}
          error="If you’re already trying to sign in from a link, the link may have expired. Please re-enter your email address and we’ll send you a new one."
        />
      );
    }
  }

  return null;
}
