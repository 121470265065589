import ReactDOM from 'react-dom';

const PORTAL_ID = 'comments-sidebar-modal-portal';

export const CommentsSidebarModalPortal: React.FunctionComponent<{
  children: Parameters<typeof ReactDOM.createPortal>[0];
}> = ({ children }) => {
  const hoverLayer = document.getElementById(PORTAL_ID);
  if (hoverLayer) {
    return ReactDOM.createPortal(children, hoverLayer);
  }
  return <>{children}</>;
};

const CommentsSidebarModalLayer: React.FunctionComponent = () => (
  <div id={PORTAL_ID} />
);
export default CommentsSidebarModalLayer;
