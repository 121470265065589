import { useCallback, useState } from 'react';
import styles from './InputCheckbox.module.scss';
import cx from 'classnames';
import ThreeDotLoading from '../molecules/ThreeDotLoading.tsx';

type InputCheckboxProps = {
  label: string | React.ReactNode;
  value: boolean;
  onChange: (v: boolean) => Promise<void>;
  className?: string;
  backgroundColor?: 'white';
  disabled?: boolean;
};

const InputAutoSaveCheckbox: React.FunctionComponent<InputCheckboxProps> = ({
  label,
  value,
  onChange,
  className,
  backgroundColor,
  disabled,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const backgroundStyle =
    backgroundColor === 'white'
      ? styles.inputCheckbox__input__bg_white
      : undefined;

  const onCheckboxChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsSaving(true);
      await onChange(e.currentTarget.checked);
      setIsSaving(false);
    },
    [onChange],
  );

  return (
    <label className={cx(styles.inputCheckbox, className)}>
      <span className={styles.inputCheckbox__input__wrapper}>
        {isSaving ? (
          <ThreeDotLoading />
        ) : (
          <>
            <input
              {...{
                type: 'checkbox',
                disabled,
                className: styles.inputCheckbox__input,
                checked: value,
                onChange: onCheckboxChange,
              }}
            />

            <span
              className={cx(styles.inputCheckbox__control, backgroundStyle)}
            />
          </>
        )}
      </span>

      {label}
    </label>
  );
};

export default InputAutoSaveCheckbox;
