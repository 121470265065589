import React from 'react';
import cx from 'classnames';
import styles from './PublishedCommentInputLayout.module.scss';

const PublishedCommentInputLayout: React.FC<{
  className?: string;
  children: React.ReactNode;
  footerSlot?: React.ReactNode;
  mini?: boolean;
}> = ({ children, footerSlot, className, mini }) => {
  return (
    <div className={cx(styles.commentInputLayout, className)}>
      <div
        className={cx([
          styles.commentInputLayout__main,
          mini ? styles.commentInputLayout__main_mini : null,
        ])}
      >
        {children}
      </div>
      {footerSlot && (
        <div className={styles.commentInputLayout__footer}>{footerSlot}</div>
      )}
    </div>
  );
};

export default PublishedCommentInputLayout;
