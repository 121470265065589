import React, { useCallback } from 'react';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import styles from './ImageWithHover.module.scss';
import cx from 'classnames';
import useFetchImage from '../../../../services/useFetchImage.tsx';
import ImageBlockLayout from '../../../../design-system/atoms/ImageLayout.tsx';
import Image from '../../../../design-system/atoms/Image.tsx';
import ImageCaption from '../../../../design-system/atoms/ImageCaption.tsx';
import SelectableDiv from './SelectableDiv.tsx';
import BlockActions from './BlockActions.ts';
import { ImageBlock } from 'editor-content/Block.js';
import handleNonTextBlockKeyEvents from './handleNonTextBlockKeyEvents.ts';
import {
  callHandlers,
  handleKey,
} from '../../../../editor/domFacing/events/isKeyMatch.ts';
import usePlaintextEditable from '../usePlaintextEditable.ts';
import keyDownHandlerLetsInputsWork from './keyDownHandlerLetsInputsWork.ts';
import { getSafePlaintextFromClipboardData } from '../../../../junkDrawer/getSafePlaintextFromClipboardData.ts';

type ImageEditableWithKeyboardProps = {
  isInBlockSelection: boolean;
  className?: string;
} & BlockActions<ImageBlock>;

const ImageEditableWithKeyboard = React.forwardRef<
  HTMLDivElement,
  ImageEditableWithKeyboardProps
>(function ImageEditableWithKeyboard(
  {
    block,
    onChange,
    onEnter,
    onSelect,
    onNavLeft,
    onNavRight,
    selection,
    isInBlockSelection,
    className,
  },
  forwardedRef,
) {
  const captionEditableProps = usePlaintextEditable<HTMLDivElement>(
    block.caption,
    (caption) => {
      onChange({ ...block, caption }, contentSelection(0));
    },
  );

  const isDirectlySelected = !!selection;

  const { guid, dimensions, width, align } = block;

  const src = useFetchImage(guid);

  return (
    <ImageBlockLayout
      {...{
        className,
        width,
        align,
        image: (
          <SelectableDiv
            data-testid="selectable-image-container"
            isInBlockSelection={isInBlockSelection}
            internalSelection={selection}
            onSelect={onSelect}
            shouldFocus={useCallback(() => {
              return (
                document.activeElement !== captionEditableProps.ref.current
              );
            }, [captionEditableProps.ref])}
            {...{
              onKeyDown: handleNonTextBlockKeyEvents({
                onEnter,
                onNavLeft,
                onNavRight,
              }),
            }}
          >
            <Image ref={forwardedRef} src={src} dimensions={dimensions} />
          </SelectableDiv>
        ),
        caption: (
          <ImageCaption
            {...{
              ...captionEditableProps,
              onFocus() {
                onSelect(contentSelection(0));
              },
              onCopy(e) {
                e.stopPropagation();
              },
              onPaste(e) {
                e.stopPropagation();
                e.preventDefault();
                if (!e.clipboardData) return;

                const text = getSafePlaintextFromClipboardData(e.clipboardData);
                const values = text.split('\n');
                document.execCommand('insertText', false, values.join(' '));
              },
              className: cx(
                styles.imageCaption,
                isDirectlySelected && styles.imageCaption_selected,
              ),
              'aria-label': 'image caption',
              'data-placeholder':
                'Click here to add an image caption (optional)',
              onKeyDown: callHandlers([
                handleKey({ key: 'Enter' }, (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEnter();
                }),
                handleKey({ key: 'ArrowUp' }, onNavLeft),
                handleKey({ key: 'ArrowDown' }, onNavRight),
                keyDownHandlerLetsInputsWork,
              ]),
            }}
          />
        ),
      }}
    />
  );
});

export default ImageEditableWithKeyboard;
