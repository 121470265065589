import { makeMakeApiRequest } from '../makeMakeApiRequest.js';

export type MagicLink = {
  id: string;
  companyId: string;
  companyName: string;
  path: string;
} & (
  | { zeckId: string; sectionId: null }
  | { zeckId: null; sectionId: string }
) &
  (
    | { expired: true; pendingUser: false; accessToken: null }
    | { expired: false; pendingUser: true; accessToken: null }
    | { expired: false; pendingUser: false; accessToken: string }
  );

const createMagicLinkApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
) => ({
  createMagicLink: async (
    identifier:
      | { zeckId: string; path: string; email: string }
      | { sectionId: string; path: string; email: string },
  ): Promise<void> => {
    return await makeLambdaApiRequest('/magic-link', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(identifier),
    });
  },
  getMagicLink: async (magicLinkId: string): Promise<MagicLink> => {
    return await makeLambdaApiRequest(`/magic-link/${magicLinkId}`, {
      method: 'GET',
    });
  },
  magicLinkSignUp: async (
    magicLinkId: string,
    firstName: string,
    lastName: string,
  ): Promise<string> => {
    const { accessToken } = await makeLambdaApiRequest(
      `/magic-link/${magicLinkId}/sign-up`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, lastName }),
      },
    );
    return accessToken;
  },
});

export default createMagicLinkApi;
