export type SuccessResult<T> = {
  type: 'success';
  data: T;
};

export type MutatingResult<T> = {
  type: 'mutating';
  data: T;
};

export type LoadingResult = {
  type: 'loading';
};

export type ErrorResult<E> = {
  type: 'error';
  error: E;
};

export type Result<E, T> =
  | SuccessResult<T>
  | LoadingResult
  | MutatingResult<T>
  | ErrorResult<E>;

export const mapResult =
  <T1, T2>(mapperFn: (a: T1) => T2) =>
  <E>(result: Result<E, T1>): Result<E, T2> => {
    switch (result.type) {
      case 'loading':
      case 'error':
        return result;
      case 'mutating':
      case 'success':
        return {
          ...result,
          data: mapperFn(result.data),
        };
    }
  };

export const chainResult =
  <E2 = never, T1 = never, T2 = never>(mapperFn: (a: T1) => Result<E2, T2>) =>
  <E1>(result: Result<E1, T1>): Result<E2 | E1, T2> => {
    switch (result.type) {
      case 'loading':
      case 'error':
        return result;
      case 'mutating':
      case 'success':
        return mapperFn(result.data);
    }
  };

export const foldResult =
  <E, T, R>(match: {
    loading: () => R;
    success: (d: T) => R;
    error: (e: E) => R;
    mutating?: (d: T) => R;
  }) =>
  (r: Result<E, T>): R => {
    switch (r.type) {
      case 'loading':
        return match.loading();
      case 'success':
        return match.success(r.data);
      case 'mutating':
        return match.mutating ? match.mutating(r.data) : match.success(r.data);
      case 'error':
        return match.error(r.error);
    }
  };

export const LoadingResult = (): LoadingResult => ({
  type: 'loading',
});

export const MutatingResult = <T>(data: T): MutatingResult<T> => ({
  type: 'mutating',
  data,
});

export const SuccessResult = <T>(data: T): SuccessResult<T> => ({
  type: 'success',
  data,
});

export const ErrorResult = <E>(error: E): ErrorResult<E> => ({
  type: 'error',
  error,
});

export const isSuccessResult = <T>(
  r: Result<unknown, T>,
): r is SuccessResult<T> => r.type === 'success';

export const isMutatingResult = <T>(
  r: Result<unknown, T>,
): r is MutatingResult<T> => r.type === 'mutating';

export const isErrorResult = <E>(r: Result<E, unknown>): r is ErrorResult<E> =>
  r.type === 'error';

export const isLoadingResult = (
  r: Result<unknown, unknown>,
): r is LoadingResult => r.type === 'loading';

export function pipe<A>(a: A): A;
export function pipe<A, B>(a: A, ab: (a: A) => B): B;
export function pipe<A, B, C>(a: A, ab: (a: A) => B, bc: (b: B) => C): C;
export function pipe<A, B, C, D>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
): D;
export function pipe<A, B, C, D, E>(
  a: A,
  ab: (a: A) => B,
  bc: (b: B) => C,
  cd: (c: C) => D,
  de: (d: D) => E,
): E;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pipe(a: any, ...fns: any[]) {
  return fns.reduce((acc, fn) => fn(acc), a);
}
