import { Editor, ExternalLink } from '../TextNode.js';
import h from 'hyperscript';

interface ExternalLinkElement extends HTMLAnchorElement {
  dataset: {
    nodetype: 'external-link';
  };
}

const isExternalLinkElement = (el: HTMLElement): el is ExternalLinkElement =>
  el.dataset.nodetype === 'external-link';

export const createExternalLinkElement = (
  node: Editor.ExternalLink,
  innerHTML: string,
) => {
  const externalLink = h('a', {
    'data-nodetype': 'external-link',
    href: node.url,
    target: '_blank',
    rel: 'noreferrer',
  });

  externalLink.innerHTML = innerHTML;

  return externalLink;
};

export const getExternalLinkFromElement = (
  el: HTMLElement,
  content: Editor.Text[],
) => {
  if (isExternalLinkElement(el)) {
    return ExternalLink(el.href, content);
  }

  return null;
};

export default ExternalLinkElement;
