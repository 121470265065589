import cx from 'classnames';
import React from 'react';
import Pill from '../../atoms/Pill.tsx';
import styles from './Vote.module.scss';

type VoteResultPillProps = {
  approved: boolean;
};

export const VoteResultPill: React.FunctionComponent<VoteResultPillProps> = ({
  approved,
}) => {
  return (
    <Pill
      className={styles.voteResultPill}
      data-testid="vote-result-pill"
      color={approved ? 'green' : 'danger'}
      label={approved ? 'approved' : 'not approved'}
      uppercase
    />
  );
};

type VoteRowProps = {
  children: React.ReactNode;
};

export const VoteRow: React.FunctionComponent<VoteRowProps> = ({
  children,
}) => {
  return <div className={styles.voteBody__row}>{children}</div>;
};

type VoteHeadingProps = {
  className?: string;
  children?: React.ReactNode;
} & React.ComponentProps<'div'>;

export const VoteHeading = React.forwardRef<HTMLDivElement, VoteHeadingProps>(
  function VoteHeading({ children, className, ...otherProps }, forwardedRef) {
    return (
      <div
        {...otherProps}
        className={cx(styles.vote__heading, className)}
        ref={forwardedRef}
        data-testid="vote-heading"
      >
        {children}
      </div>
    );
  },
);

type VoteDetailsProps = {
  className?: string;
  children?: React.ReactNode;
} & React.ComponentProps<'p'>;

export const VoteDetails = React.forwardRef<
  HTMLParagraphElement,
  VoteDetailsProps
>(function VoteDetails({ children, className, ...otherProps }, forwardedRef) {
  return (
    <p
      {...otherProps}
      className={cx(styles.vote__details, className)}
      ref={forwardedRef}
    >
      {children}
    </p>
  );
});
