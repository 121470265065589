import styles from './UnauthorizedPage.module.scss';
import {
  AccessPageContent,
  AccessPageLayout,
} from '../../../design-system/organisms/AccessPage.js';
import { hardNavigateToHome } from '../../../userAndCompany/FetchUserAndCompany.js';

type UnauthorizedPageProps = {
  email: string;
  onClickLogout: () => void;
};

const UnauthorizedPage: React.VFC<UnauthorizedPageProps> = ({
  email,
  onClickLogout,
}) => {
  return (
    <AccessPageLayout>
      <AccessPageContent
        {...{
          title: "You Don't Have Access",
          description:
            "You don't currently have access to this content. Please contact a company administrator to request access.",
          footnote: (
            <>
              You’re signed in as{' '}
              <span className={styles.unauthorizedPage__email}>{email}</span>.{' '}
              <a
                href="/signin"
                onClick={(e) => {
                  e.preventDefault();
                  onClickLogout();
                }}
              >
                Sign out
              </a>{' '}
              to try a different account or{' '}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  hardNavigateToHome();
                }}
              >
                Go to Home.
              </a>
            </>
          ),
        }}
      />
    </AccessPageLayout>
  );
};

export default UnauthorizedPage;
