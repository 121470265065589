import { Result } from '../../../../services/useFetch/useFetch.js';

import useApi from '../../../../api/useApi.js';
import useAnalyticsEvents from '../../../../services/useAnalyticsEvents.js';
import { CompanyRole } from '../../../../types/CompanyLite.js';
import { logError } from '../../../../logging/logger.ts';
import useExecuteOnLoad from '../../../../result/useExecuteOnLoad.js';
import { PublishedZeck } from '../../../../types.ts';

const useRegisterViewEventsOnLoad = ({
  publishedZeckResult,
  userId,
  companyId,
  role,
  boardDirector,
}: {
  publishedZeckResult: Result<PublishedZeck>;
  userId: string;
  companyId: string;
  role: CompanyRole;
  boardDirector: boolean;
}) => {
  const { createPublishedZeckView } = useApi();

  const { trackViewZeck } = useAnalyticsEvents({
    companyId: companyId,
    userId: userId,
    companyRole: role,
    boardDirector,
  });

  const registerEvents = (publishedZeck: PublishedZeck): void => {
    trackViewZeck(publishedZeck.zeckId);

    createPublishedZeckView({
      companyId: publishedZeck.companyId,
      zeckId: publishedZeck.zeckId,
    }).catch((e) => {
      logError(
        new Error(
          `exception occurred while registering published zeck view: ${e.message}`,
        ),
      );
    });
  };

  useExecuteOnLoad({
    result: publishedZeckResult,
    onLoad: registerEvents,
  });
};

export default useRegisterViewEventsOnLoad;
