import React, { useRef, useState } from 'react';
import styles from './CompletedVoteEditable.module.scss';
import HoverNextToElement from '../../../../domHelpers/hoverNextTo/HoverNextToElement.tsx';
import SpeechBubble from '../../../../design-system/molecules/SpeechBubble.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import { centeredBelowElement } from '../../../../domHelpers/hoverNextTo/positionStrategy/centeredBelow.ts';
import CompletedVoteView from '../../voting/CompletedVoteView.tsx';
import { useUserInfo } from '../../../../services/useUserInfo.tsx';
import { isPointInRect } from '../../../../domHelpers/rectHelpers.ts';
import { CompletedVoteBlock } from 'editor-content/VoteBlock.js';

type CompletedVoteEditableProps = {
  className?: string;
  block: CompletedVoteBlock;
  tabIndex?: number;
};

const CompletedVoteEditable = React.forwardRef<
  HTMLDivElement,
  CompletedVoteEditableProps
>(function CompletedVoteEditable({ block, ...otherProps }, forwardedRef) {
  const ref = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const userInfo = useUserInfo();

  let tooltip: React.ReactNode;
  if (userInfo && userInfo.permissions.canManageMinutes) {
    tooltip = (
      <div>
        A vote that has been taken cannot be edited or deleted here. In order to
        make changes please go to&nbsp;
        <a
          aria-label="go to site"
          href={`/company/${userInfo.activeCompanyId}/voting`}
        >
          Voting
        </a>
        &nbsp;from the company home screen.
      </div>
    );
  } else {
    tooltip = 'A vote that has been taken cannot be edited or deleted.';
  }

  const gapBetweenTooltip = 16;
  return (
    <>
      <CompletedVoteView
        {...{
          ...otherProps,
          className: styles.completedVoteEditable,
          onMouseEnter: () => {
            setShowTooltip(true);
          },
          onMouseLeave: (e: MouseEvent) => {
            const element: Element = e.currentTarget as Element;
            if (element.nextSibling) {
              const tooltipElement = element.nextSibling as Element;
              const siblingIsTooltip =
                tooltipElement.getAttribute('class') === 'tooltip-container';
              if (siblingIsTooltip) {
                const tooltipRect = tooltipElement.getBoundingClientRect();
                if (
                  isPointInRect(
                    { x: e.clientX, y: e.clientY },
                    {
                      x: tooltipRect.left,
                      y: tooltipRect.top - gapBetweenTooltip,
                      width: tooltipRect.width,
                      height: tooltipRect.height,
                    },
                  )
                ) {
                  return;
                }
              }
            }

            setShowTooltip(false);
          },
          ref: mergeRefs([forwardedRef, ref]),
          block,
          editVote: null,
        }}
      />
      {showTooltip && (
        <HoverNextToElement
          elementRef={ref}
          positionStrategy={centeredBelowElement(gapBetweenTooltip)}
          onMouseLeave={() => {
            setShowTooltip(false);
          }}
        >
          <SpeechBubble data-testid="completed-vote-tooltip">
            {tooltip}
          </SpeechBubble>
        </HoverNextToElement>
      )}
    </>
  );
});

export default CompletedVoteEditable;
