import { Section } from '../../types.ts';
import hydrateBlock from './hydrateBlock.js';
import HydratedSection from './types/HydratedSection.ts';

export default function hydrateSection(section: Section): HydratedSection {
  return {
    ...section,
    body: section.body.map(hydrateBlock(section.votes)),
  };
}
