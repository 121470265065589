import {
  callHandlers,
  handleKey,
  preventDefault,
  stopPropagation,
} from '../../../../editor/domFacing/events/isKeyMatch.js';
import { KeyboardEvent, KeyboardEventHandler } from 'react';

const handleNonTextBlockKeyEvents = <E extends HTMLElement>({
  onEnter,
  onNavLeft,
  onNavRight,
  onDelete = null,
}: {
  onEnter: KeyboardEventHandler<E>;
  onNavLeft: KeyboardEventHandler<E>;
  onNavRight: KeyboardEventHandler<E>;
  onDelete?: KeyboardEventHandler<E> | null;
}) =>
  callHandlers<KeyboardEvent<E>>([
    handleKey({ key: 'Enter' }, preventDefault(stopPropagation(onEnter))),
    handleKey({ key: 'ArrowUp' }, onNavLeft),
    handleKey({ key: 'ArrowDown' }, onNavRight),
    handleKey({ key: 'ArrowLeft' }, onNavLeft),
    handleKey({ key: 'ArrowRight' }, onNavRight),
    handleKey({ key: 'Backspace' }, (e) => {
      onDelete && preventDefault(stopPropagation(onDelete))(e);
    }),
    handleKey({ key: 'Delete' }, (e) => {
      onDelete && preventDefault(stopPropagation(onDelete))(e);
    }),
  ]);

export default handleNonTextBlockKeyEvents;
