import useElementArrayRef from '../useElementArrayRef.js';
import useDetectCollision from '../useDetectCollision.js';
import useAnimationLoop from '../../../services/useAnimationLoop.js';

function useHideStickyTitle(sectionBody: unknown[]) {
  const { getElementAt, setElementAt } = useElementArrayRef();

  const { isColliding, targetElementRef } = useDetectCollision<HTMLElement>(
    sectionBody.map((block, i) => () => getElementAt(i)),
  );

  useAnimationLoop(() => {
    if (!targetElementRef.current) return;

    if (isColliding()) {
      targetElementRef.current.style.opacity = '0';
    } else {
      targetElementRef.current.style.opacity = '1';
    }
  }, [isColliding, targetElementRef]);

  return { setElementAt, targetElementRef };
}
export default useHideStickyTitle;
