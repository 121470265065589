import Toolbar, { ToolbarButton, ToolbarGroup } from './Toolbar.tsx';
import IconButton from '../atoms/IconButton.tsx';

type FileFormattingMenuProps = {
  onClickDelete: () => void;
  onClickReplace: () => void;
  onClickComment: () => void;
};

const FileFormattingMenu: React.FunctionComponent<FileFormattingMenuProps> = ({
  onClickDelete,
  onClickReplace,
  onClickComment,
}) => {
  return (
    <Toolbar>
      <ToolbarGroup>
        <IconButton
          onClick={onClickDelete}
          name="trash"
          aria-label="delete file"
          type="dark"
        />
        <IconButton
          onClick={onClickReplace}
          name="replace"
          aria-label="replace file"
          type="dark"
        />
      </ToolbarGroup>
      <ToolbarGroup>
        <ToolbarButton iconName="speechBubbleWithText" onClick={onClickComment}>
          Comment
        </ToolbarButton>
      </ToolbarGroup>
    </Toolbar>
  );
};

export default FileFormattingMenu;
