import styles from './FileInput.module.scss';

type FileInputProps = {
  children: React.ReactNode;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'multiple'>;

const FileInput = ({ children, ...props }: FileInputProps) => {
  return (
    <label className={styles.fileInput}>
      <span className={styles.fileInput_fakeButton}>{children}</span>
      <input
        {...props}
        {...{
          type: 'file',
          multiple: false,
        }}
      />
    </label>
  );
};

export default FileInput;
