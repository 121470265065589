import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.ts';

export function AiStarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M6.23703 3.88652L6.31703 3.92652C6.64037 4.09319 6.9037 4.35652 7.07036 4.67986L7.11036 4.75986C7.27037 5.07652 7.7237 5.07652 7.88703 4.75986L7.92703 4.67986C8.0937 4.35652 8.35703 4.09319 8.68037 3.92652L8.76036 3.88652C9.07703 3.72652 9.07703 3.27319 8.76036 3.10986L8.68037 3.06986C8.35703 2.90319 8.0937 2.63986 7.92703 2.31652L7.88703 2.23652C7.72703 1.91986 7.2737 1.91986 7.11036 2.23652L7.07036 2.31652C6.9037 2.63986 6.64037 2.90319 6.31703 3.06986L6.23703 3.10986C5.92036 3.26986 5.92036 3.72319 6.23703 3.88652Z"
        fill="#5445FF"
      />
      <path
        d="M6.86703 7.00986C7.1937 6.84319 7.1937 6.37986 6.86703 6.21319L6.38036 5.96319C5.94703 5.73986 5.5937 5.38652 5.37036 4.95319L5.12036 4.46652C4.9537 4.13986 4.49036 4.13986 4.3237 4.46652L4.0737 4.95319C3.85036 5.38652 3.49703 5.73986 3.0637 5.96319L2.57703 6.21319C2.25036 6.37986 2.25036 6.84319 2.57703 7.00986L3.0637 7.25986C3.49703 7.48319 3.85036 7.83652 4.0737 8.26986L4.3237 8.75652C4.49036 9.08319 4.9537 9.08319 5.12036 8.75652L5.37036 8.26986C5.5937 7.83652 5.94703 7.48319 6.38036 7.25986L6.86703 7.00986Z"
        fill="#5445FF"
      />
      <path
        d="M13.1204 9.00319L12.627 8.74986C12.0304 8.44652 11.5537 7.96986 11.2504 7.37319L10.997 6.87986C10.7737 6.43986 10.327 6.16652 9.8337 6.16652C9.34036 6.16652 8.8937 6.43986 8.67036 6.87986L8.41703 7.37319C8.1137 7.96986 7.63703 8.44652 7.04037 8.74986L6.54703 9.00319C6.10703 9.22652 5.8337 9.67319 5.8337 10.1665C5.8337 10.6599 6.10703 11.1065 6.54703 11.3299L7.04037 11.5832C7.63703 11.8865 8.1137 12.3632 8.41703 12.9599L8.67036 13.4532C8.8937 13.8932 9.34036 14.1665 9.8337 14.1665C10.327 14.1665 10.7737 13.8932 10.997 13.4532L11.2504 12.9599C11.5537 12.3632 12.0304 11.8865 12.627 11.5832L13.1204 11.3299C13.5604 11.1065 13.8337 10.6599 13.8337 10.1665C13.8337 9.67319 13.5604 9.22652 13.1204 9.00319ZM12.667 10.4399L12.1737 10.6932C11.387 11.0932 10.7604 11.7199 10.3604 12.5065L10.107 12.9999C10.0304 13.1499 9.89037 13.1665 9.8337 13.1665C9.77703 13.1665 9.63703 13.1499 9.56037 12.9999L9.30703 12.5065C8.90703 11.7199 8.28036 11.0932 7.4937 10.6932L7.00036 10.4399C6.85036 10.3632 6.8337 10.2232 6.8337 10.1665C6.8337 10.1099 6.85036 9.96986 7.00036 9.89319L7.4937 9.63986C8.28036 9.23986 8.90703 8.61319 9.30703 7.82652L9.56037 7.33319C9.63703 7.18319 9.77703 7.16652 9.8337 7.16652C9.89037 7.16652 10.0304 7.18319 10.107 7.33319L10.3604 7.82652C10.7604 8.61319 11.387 9.23986 12.1737 9.63986L12.667 9.89319C12.817 9.96986 12.8337 10.1099 12.8337 10.1665C12.8337 10.2232 12.817 10.3632 12.667 10.4399Z"
        fill="#5445FF"
      />
    </svg>
  );
}
