import { useState } from 'react';
import Button from '../atoms/Button.tsx';
import ButtonWithLoading from './ButtonWithLoading.tsx';

type AsyncButtonProps = React.ComponentProps<typeof Button> & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => Promise<unknown>;
};

const AsyncButton: React.FC<AsyncButtonProps> = ({ onClick, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ButtonWithLoading
      {...props}
      onClick={async (e) => {
        setIsLoading(true);
        await onClick(e);
        setIsLoading(false);
      }}
      isLoading={isLoading}
    />
  );
};

export default AsyncButton;
