import { Section, Zeck } from '../types.ts';
import isEqual from 'lodash/isEqual.js';
import { Block } from 'editor-content/Block.js';
import { isVoteBlock } from 'editor-content/VoteBlock.js';

const ignoreVoteMethods = (content: Block[]) => {
  return content.map((block) => {
    if (!isVoteBlock(block)) return block;

    return {
      ...block,
      takeVote: null,
      approved: null,
    };
  });
};

function isZeckChangedSinceLastPublish(
  zeck: Zeck,
  editorSectionState: Section | void,
) {
  if (!zeck.publishedZeck) {
    return true;
  }

  const { publishedZeck } = zeck;

  if (
    zeck.name !== publishedZeck.name ||
    zeck.coverImageId !== publishedZeck.coverImageId ||
    zeck.sections.length !== publishedZeck.sections.length
  ) {
    return true;
  }

  return zeck.sections.some((zeckSection) => {
    const publishedSection = publishedZeck.sections.find(
      (publishedSection) => publishedSection.sectionId === zeckSection.id,
    );

    if (!publishedSection) return true;

    const currentSectionState =
      editorSectionState && zeckSection.id === editorSectionState.id
        ? editorSectionState
        : zeckSection;

    return (
      currentSectionState.title !== publishedSection.title ||
      currentSectionState.position !== publishedSection.position ||
      currentSectionState.supplemental !== publishedSection.supplemental ||
      !isEqual(currentSectionState.headline, publishedSection.headline) ||
      !isEqual(
        ignoreVoteMethods(currentSectionState.body),
        ignoreVoteMethods(publishedSection.body),
      ) ||
      currentSectionState.coverImageId !== publishedSection.coverImageId ||
      currentSectionState.coverImageEnabled !==
        publishedSection.coverImageEnabled
    );
  });
}

export default isZeckChangedSinceLastPublish;
