import { useCallback, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './MeetingMinutesCardMenu.module.scss';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';
import HoverMenu, {
  HoverMenuButtonItem,
  HoverMenuLinkItem,
} from '../../../../design-system/organisms/HoverMenu.tsx';
import Tooltip from '../../../../design-system/molecules/Tooltip.tsx';
import MeetingMinutesModal from './MeetingMinutesModal.tsx';
import OutlineButton from '../../../../design-system/atoms/OutlineButton.tsx';
import Button from '../../../../design-system/atoms/Button.tsx';
import { meetingMinutesPrintPath } from '../../../../services/Paths.ts';
import { MeetingMinutesListItem } from '../../../../api/endpoints/createMeetingMinutesApi.ts';

const DeleteButton = ({
  onDelete,
  disabled,
}: {
  onDelete: () => void;
  disabled: boolean;
}) => {
  return (
    <HoverMenuButtonItem
      iconName="trash"
      color="danger"
      onClick={onDelete}
      disabled={disabled}
      className={styles.deleteButton}
    >
      Delete
      {disabled && (
        <div className={styles.deleteTooltip}>
          <Tooltip>
            Meeting Minutes cannot be deleted when marked as approved or signed.
          </Tooltip>
        </div>
      )}
    </HoverMenuButtonItem>
  );
};

type ModalState = null | 'delete' | 'approve' | 'unapprove' | 'duplicate';

const MeetingMinutesCardMenu = ({
  meetingMinutes,
  onDeleteMeetingMinutes,
  onApproveMeetingMinutes,
  onRenameMeetingMinutes,
  onDuplicateMeetingMinutes,
  className,
}: {
  meetingMinutes: MeetingMinutesListItem;
  onDeleteMeetingMinutes: (meetingMinutesId: string) => Promise<void>;
  onRenameMeetingMinutes: () => void;
  onApproveMeetingMinutes: (
    meetingMinutesId: string,
    approved: boolean,
  ) => Promise<void>;
  onDuplicateMeetingMinutes: (
    meetingMinutesId: string,
    keepZeck: boolean,
  ) => Promise<void>;
  className?: string;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalContentType, setModalContentType] = useState<ModalState>(null); // needed to preserve modal content during close animation
  const [menuOpen, setMenuOpen] = useState(false);
  const [actionEnabled, setActionEnabled] = useState(true);

  const closeModal = useCallback(() => setModalOpen(false), []);
  const selectMenuItem = useCallback(
    (val: ModalState) => {
      setModalContentType(val);
      setModalOpen(true);
      setMenuOpen(false);
      setActionEnabled(true);
    },
    [setModalOpen, setModalContentType, setMenuOpen],
  );

  let modalContent: Pick<
    React.ComponentProps<typeof MeetingMinutesModal>,
    'title' | 'message' | 'actionsSlot'
  >;

  const meetingMinutesId = meetingMinutes.id;

  const onDelete = useCallback(async () => {
    setActionEnabled(false);
    await onDeleteMeetingMinutes(meetingMinutesId);
    closeModal();
  }, [meetingMinutesId, onDeleteMeetingMinutes, closeModal]);

  const onApprove = useCallback(async () => {
    setActionEnabled(false);
    await onApproveMeetingMinutes(meetingMinutesId, true);
    closeModal();
  }, [meetingMinutesId, onApproveMeetingMinutes, closeModal]);

  const onUnapprove = useCallback(async () => {
    setActionEnabled(false);
    await onApproveMeetingMinutes(meetingMinutesId, false);
    closeModal();
  }, [meetingMinutesId, onApproveMeetingMinutes, closeModal]);

  const onDuplicate = useCallback(
    async (keepZeck: boolean) => {
      setActionEnabled(false);
      await onDuplicateMeetingMinutes(meetingMinutesId, keepZeck);

      closeModal();
    },
    [meetingMinutesId, onDuplicateMeetingMinutes, closeModal],
  );

  switch (modalContentType) {
    case 'delete': {
      modalContent = {
        title: 'Delete Meeting Minutes?',
        message: (
          <>
            Permanently delete <em>{meetingMinutes.title}</em>? You won’t be
            able to get it back.
          </>
        ),
        actionsSlot: (
          <>
            <OutlineButton
              data-testid="minutes-modal-cancel-button"
              onClick={closeModal}
              size="medium"
              color="secondary"
            >
              Cancel
            </OutlineButton>
            <Button
              data-testid="minutes-modal-confirmation-button"
              onClick={onDelete}
              size="medium"
              color="danger"
              disabled={!actionEnabled}
            >
              Yes, Delete
            </Button>
          </>
        ),
      };
      break;
    }
    case 'approve': {
      modalContent = {
        title: 'Mark as Approved?',
        message: (
          <>
            Meeting minutes are no longer editable once they’ve been marked as
            approved. Are you sure you want to mark{' '}
            <em>{meetingMinutes.title}</em> as approved?
          </>
        ),
        actionsSlot: (
          <>
            <Button
              data-testid="minutes-modal-cancel-button"
              onClick={closeModal}
              size="medium"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              data-testid="minutes-modal-confirmation-button"
              onClick={onApprove}
              size="medium"
              color="primary"
              disabled={!actionEnabled}
            >
              Yes, Mark Approved
            </Button>
          </>
        ),
      };
      break;
    }
    case 'unapprove': {
      modalContent = {
        title: 'Unmark Meeting Minutes as Approved?',
        message: (
          <>
            You are about to change the status of meeting minutes marked as
            approved. We recommend you consult your legal advisor to confirm
            that a board ratification is not necessary or advisable before you
            remove the approved status.
          </>
        ),
        actionsSlot: (
          <>
            <Button
              data-testid="minutes-modal-cancel-button"
              onClick={closeModal}
              size="medium"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              data-testid="minutes-modal-confirmation-button"
              onClick={onUnapprove}
              size="medium"
              color="danger"
              disabled={!actionEnabled}
            >
              Yes, Unmark Approved
            </Button>
          </>
        ),
      };
      break;
    }
    case 'duplicate': {
      modalContent = {
        title: (
          <span>
            Keep Connected to{' '}
            <span className={styles.meetingMinutesModal__zeckName}>
              {meetingMinutes.zeckName}
            </span>
            ?
          </span>
        ),
        message: (
          <>
            Do you want to keep the duplicate meeting minutes connected to the
            published Zeck,{' '}
            <span className={styles.meetingMinutesModal__zeckName}>
              {meetingMinutes.zeckName}
            </span>
            ?
          </>
        ),
        actionsSlot: (
          <>
            <OutlineButton
              data-testid="minutes-modal-cancel-button"
              onClick={() => {
                onDuplicate(false);
              }}
              size="medium"
              color="secondary"
            >
              Do Not Keep
            </OutlineButton>
            <Button
              data-testid="minutes-modal-confirmation-button"
              onClick={() => {
                onDuplicate(true);
              }}
              size="medium"
              color="primary"
              disabled={!actionEnabled}
            >
              Keep Connected
            </Button>
          </>
        ),
      };
      break;
    }
    default: {
      modalContent = {
        title: null,
        message: null,
        actionsSlot: null,
      };
    }
  }

  return (
    <>
      <IconButton
        ref={buttonRef}
        name="kebab"
        onClick={() => setMenuOpen(true)}
        aria-label="Manage Meeting Minutes"
        data-testid="meeting-minutes-card-menu-button"
        className={cx(styles.meetingMinutesCard__menuTrigger, className)}
      />
      <HoverMenu
        elementRef={buttonRef}
        positionStrategy={(el) => {
          const rect = el.getBoundingClientRect();
          return [rect.x + rect.width - 86 - 16, rect.y + rect.height + 2];
        }}
        isOpen={menuOpen}
        onClose={function (): void {
          setMenuOpen(false);
        }}
      >
        {!meetingMinutes.approved && (
          <HoverMenuButtonItem
            iconName="stamp"
            color="normal"
            onClick={() => {
              selectMenuItem('approve');
            }}
          >
            Mark as Approved
          </HoverMenuButtonItem>
        )}

        {meetingMinutes.approved && (
          <HoverMenuButtonItem
            iconName="stamp"
            color="normal"
            onClick={() => {
              selectMenuItem('unapprove');
            }}
          >
            Unmark as Approved
          </HoverMenuButtonItem>
        )}

        <HoverMenuButtonItem
          iconName="rename"
          color="normal"
          disabled={meetingMinutes.approved || meetingMinutes.hasSignatures}
          onClick={() => {
            onRenameMeetingMinutes();
            setMenuOpen(false);
          }}
        >
          Rename
        </HoverMenuButtonItem>

        <HoverMenuButtonItem
          iconName="copy"
          color="normal"
          onClick={() => {
            meetingMinutes.zeckId
              ? selectMenuItem('duplicate')
              : onDuplicateMeetingMinutes(meetingMinutes.id, false);
          }}
        >
          Duplicate
        </HoverMenuButtonItem>

        <DeleteButton
          onDelete={() => {
            selectMenuItem('delete');
          }}
          disabled={meetingMinutes.approved}
        />

        <HoverMenuLinkItem
          iconName="export"
          color="normal"
          to={meetingMinutesPrintPath(meetingMinutes)}
        >
          Export to PDF
        </HoverMenuLinkItem>
      </HoverMenu>

      <MeetingMinutesModal
        isOpen={modalOpen}
        onClose={closeModal}
        {...modalContent}
      />
    </>
  );
};

export default MeetingMinutesCardMenu;
