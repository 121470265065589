import InputEmail from '../../design-system/atoms/InputEmail.tsx';
import { useState } from 'react';
import Button from '../../design-system/atoms/Button.tsx';
import emailRegex from '../../emailRegex.ts';
import styles from './RequestMagicLinkPage.module.scss';
import { hasStatus } from '../../api/makeMakeApiRequest.ts';

export type RequestMagicLinkFormProps = {
  onSubmit: (email: string) => Promise<void>;
  error?: string;
};

export default function RequestMagicLinkForm({
  error,
  onSubmit,
}: RequestMagicLinkFormProps) {
  const [email, setEmail] = useState('');
  const [uiState, setUiState] = useState<'editing' | 'error' | 'submitting'>(
    'editing',
  );

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        if (!emailRegex.test(email)) {
          setUiState('error');
          return;
        }

        setUiState('submitting');

        try {
          await onSubmit(email);
        } catch (error) {
          if (hasStatus(error) && error.status === 400) {
            setUiState('error');
          } else {
            throw error;
          }
        }
      }}
      noValidate
      className={styles.form}
    >
      <InputEmail
        label="Email"
        value={email}
        onChange={(value) => {
          setUiState('editing');
          setEmail(value);
        }}
        error={
          uiState === 'error' ? 'Please enter a valid email address.' : null
        }
        className={styles.input}
      />

      <Button
        size="large"
        color="primary"
        disabled={!email || uiState === 'submitting'}
        className={styles.submit}
      >
        Send Me Secure Link
      </Button>

      {error && (
        <span className={styles.error}>
          If you’re already trying to sign in from a link, the link may have
          expired. Please re-enter your email address and we’ll send you a new
          one.
        </span>
      )}
    </form>
  );
}
