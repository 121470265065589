import { CartaCapTableBlock } from 'editor-content/CartaBlock.ts';
import { TableBlockCellFormat } from 'editor-content/TableBlock.ts';
import {
  FormattedTableCell,
  FormattedTableData,
} from '../../table/formattedTableData.ts';

const baseFormat: TableBlockCellFormat = {
  alignHorizontal: 'right',
  alignVertical: 'top',
  wrap: 'wrap',
};

const leftFormat: TableBlockCellFormat = {
  ...baseFormat,
  alignHorizontal: 'left',
};

const bgFormat = {
  ...baseFormat,
  bgColor: '#F2F2F5',
};

const headerRowCells: FormattedTableCell[] = [
  '',
  'Authorized Shares',
  'Outstanding',
  'Ownership',
  'Fully Diluted',
  'Ownership',
  'Amount Raised',
].map((value) => ({
  content: value,
  format: { ...baseFormat, border: { bottom: 'thin' } },
}));

const formattedShareClassTableData = (
  cartaBlock: CartaCapTableBlock,
): FormattedTableData => {
  const shareClassRows = cartaBlock.data.capTable.shareClasses.map(
    (shareClass) => {
      const cells: FormattedTableCell[] = [
        { content: shareClass.name, format: leftFormat },
        { content: shareClass.authorizedShares, format: baseFormat },
        { content: shareClass.outstandingShares, format: baseFormat },
        { content: shareClass.outstandingOwnershipPercent, format: baseFormat },
        { content: shareClass.fullyDilutedShares, format: baseFormat },
        {
          content: shareClass.fullyDilutedOwnershipPercent,
          format: baseFormat,
        },
        { content: shareClass.amountRaised, format: baseFormat },
      ];

      return { cells };
    },
  );

  const optionPoolRows = cartaBlock.data.capTable.optionPools.map(
    (optionPool) => {
      const cells: FormattedTableCell[] = [
        { content: optionPool.name, format: leftFormat },
        { content: optionPool.authorizedShares, format: baseFormat },
        {
          content: optionPool.outstandingEquityAwardDerivatives,
          format: baseFormat,
        },
        { content: '0.000%', format: baseFormat },
        { content: optionPool.fullyDilutedShares, format: baseFormat },
        {
          content: optionPool.availableSharesOwnershipPercent,
          format: baseFormat,
        },
        { content: '0', format: baseFormat },
      ];

      return { cells };
    },
  );

  const totalRowCells: FormattedTableCell[] = [
    { content: 'Total', format: { ...leftFormat, bgColor: '#F2F2F5' } },
    { content: '', format: bgFormat },
    { content: cartaBlock.data.capTable.outstandingShares, format: bgFormat },
    { content: '100.000%', format: bgFormat },
    {
      content: cartaBlock.data.capTable.fullyDilutedShares,
      format: bgFormat,
    },
    { content: '100.000%', format: bgFormat },
    { content: '', format: bgFormat },
  ];

  return {
    rows: [
      { cells: headerRowCells },
      ...shareClassRows,
      ...optionPoolRows,
      {
        cells: totalRowCells,
      },
    ],
  };
};

export default formattedShareClassTableData;
