import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

const AiChartGenerateDTO = z.object({
  data: z.array(z.array(z.union([z.number(), z.string()]))),
  currentMessage: z.string(),
  companyId: z.string().uuid(),
  sectionId: z.string().uuid(),
  userId: z.string().uuid(),
});

export type AiChartGenerateDTO = z.infer<typeof AiChartGenerateDTO>;

const AuthHeadersSchema = z.object({
  authorization: z.string().startsWith('Bearer '),
});

export const AiChartGenerateErrorType = z.union([
  z.literal('unknown'),
  z.literal('missingData'),
  z.literal('calculationsRequired'),
  z.literal('unknownChartType'),
]);

export type AiChartGenerateErrorType = z.infer<typeof AiChartGenerateErrorType>;

export const AiChartGenerateErrorResponse = z.object({
  errorBody: z.object({
    errorType: AiChartGenerateErrorType,
  }),
});

export type AiChartGenerateErrorResponse = z.infer<
  typeof AiChartGenerateErrorResponse
>;

export const isAiChartGenerateErrorResponse = (
  response: unknown,
): response is AiChartGenerateErrorResponse => {
  return (
    typeof response === 'object' && response !== null && 'errorBody' in response
  );
};

export const AiChartGenerateSuccessResponse = z.object({
  jobUrl: z.string(),
});

export const aiContract = c.router({
  createChartJob: {
    summary: 'Create an AI chart job',
    method: 'POST',
    path: '/ai/chart',
    pathParams: z.object({}),
    body: AiChartGenerateDTO,
    responses: {
      200: z.union([
        AiChartGenerateSuccessResponse,
        AiChartGenerateErrorResponse,
      ]),
      400: c.type(),
      401: c.type(),
      500: c.type(),
    },
    headers: z.intersection(
      AuthHeadersSchema,
      z.object({
        'content-type': z.literal('application/json'),
      }),
    ),
    strictStatusCodes: true,
  },
});
