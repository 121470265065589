import cx from 'classnames';
import styles from './TransparentList.module.scss';

const TransparentOrderedList: React.FC<React.ComponentProps<'ol'>> = (
  props,
) => {
  return (
    <ol {...props} className={cx(props.className, styles.transparentList)} />
  );
};

export const TransparentListItem: React.FC<React.ComponentProps<'li'>> = (
  props,
) => {
  return <li {...props} />;
};

export default TransparentOrderedList;
