import React, { useState } from 'react';
import OutlineButton from '../../design-system/atoms/OutlineButton.js';
import emailRegex from '../../emailRegex.js';
import ButtonWithLoading from '../../design-system/molecules/ButtonWithLoading.js';
import InputError from '../../design-system/atoms/InputError.js';
import ProfileLabel from './ProfileLabel.js';
import styles from './UserProfileForm.module.scss';
import cx from 'classnames';

const UserEmailChangeForm: React.FC<{
  email: string;
  onSubmit: (
    newEmail: string,
  ) => Promise<{ type: 'success' } | { type: 'error'; userMessage: string }>;
  onCancel: () => void;
}> = (props) => {
  const [value, setValue] = useState(props.email);
  const [requestError, setRequestError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const error = !emailRegex.test(value)
    ? 'Please enter a valid email address.'
    : requestError;

  const emailChanged = value !== props.email;

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const result = await props.onSubmit(value);
        setIsLoading(false);

        if (result.type === 'error') {
          setRequestError(result.userMessage);
        }
      }}
      className={styles.profileForm}
    >
      <div className={styles.profileForm__inputSection}>
        {!error ? (
          <ProfileLabel>Email</ProfileLabel>
        ) : (
          <InputError size="small">{error}</InputError>
        )}
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          aria-label="Change your email"
          disabled={isLoading}
          className={cx(
            styles.profileForm__input,
            error && styles.profileForm__input_error,
          )}
        />
      </div>

      <div className={styles.profileForm__actionSection}>
        <OutlineButton
          onClick={props.onCancel}
          size="medium"
          color="secondary"
          type="button"
          disabled={isLoading}
          className={styles.profileForm__buttonCancel}
        >
          Cancel
        </OutlineButton>
        <ButtonWithLoading
          isLoading={isLoading}
          disabled={!emailChanged}
          size="medium"
          color="primary"
          type="submit"
          className={styles.profileForm__buttonSubmit}
        >
          Confirm Change
        </ButtonWithLoading>
      </div>
    </form>
  );
};

export default UserEmailChangeForm;
