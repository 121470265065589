import { TableBlock } from 'editor-content/TableBlock.ts';
import { useAtom } from 'jotai';
import { AiContext } from '../../pages/zeck/editor/AiChartFlow/createAiContext.ts';
import Toolbar, { ToolbarButton, ToolbarGroup } from './Toolbar.tsx';

type TableFormattingMenuProps = {
  showSyncButton: boolean;
  onClickSync: () => void;
  block: TableBlock;
  aiContext: AiContext;
};

const TableFormattingMenu: React.FC<TableFormattingMenuProps> = ({
  showSyncButton,
  onClickSync,
  block,
  aiContext,
}) => {
  const [chartMakingTable, setChartMakingTable] = useAtom(
    aiContext.getChartMakingFamily(block),
  );

  const showChartButton = !chartMakingTable;

  if (!showChartButton && !showSyncButton) {
    return null;
  }

  return (
    <Toolbar>
      <ToolbarGroup>
        {showSyncButton && (
          <ToolbarButton
            iconName="refresh"
            onMouseDown={(e) => {
              // doing this to prevent changing active element focus
              e.preventDefault();
              e.stopPropagation();
              onClickSync();
            }}
            aria-label="sync table"
          >
            Sync
          </ToolbarButton>
        )}
        {showChartButton && (
          <ToolbarButton
            iconName="aiPencil"
            aria-label="generate a chart with ai"
            onClick={() => {
              setChartMakingTable(true);
              // call the ai service, get the chart and add to the editor
            }}
          >
            Generate Chart
          </ToolbarButton>
        )}
      </ToolbarGroup>
    </Toolbar>
  );
};

export default TableFormattingMenu;
