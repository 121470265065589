import { makeMakeApiRequest } from './makeMakeApiRequest.js';

const createUserEmailChangeRequestApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createUserEmailChangeRequest: async (
    newEmail: string,
  ): Promise<{ emailChangeRequestId: string }> => {
    return makeLambdaApiRequest(`/user/email-change-request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        newEmail,
      }),
    });
  },

  confirmUserEmailChangeRequest: async (params: {
    confirmationCode: string;
    emailChangeRequestId: string;
  }): Promise<void> => {
    await makeLambdaApiRequest(
      `/user/email-change-request/${params.emailChangeRequestId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          confirmationCode: params.confirmationCode,
        }),
      },
    );
  },

  resendEmailChangeRequest: async (
    emailChangeRequestId: string,
  ): Promise<void> => {
    await makeLambdaApiRequest(
      `/user/email-change-request/${emailChangeRequestId}/resend`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  },
});

export default createUserEmailChangeRequestApi;
