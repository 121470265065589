import {
  MaybePendingUser,
  PublishedSection,
  PublishedZeck,
} from '../../../../../../types.ts';
import { useEffect, useMemo, useState } from 'react';
import { UserAndCompany } from '../../../../../../userAndCompany/FetchUserAndCompany.js';
import useApi from '../../../../../../api/useApi.js';
import { CommentContent } from 'editor-content/CommentContent.js';
import useActionsForComments from './useActionsForComments.js';
import useCommentsUIState, { FilterState } from './useCommentsUIState.js';
import { Comment, CommentReply } from 'api-client/types.js';
import isFilteredCommentsExisty from './isFilteredCommentsExisty.js';
import filterComments from './filterComments.js';
import { isImageBlock } from 'editor-content/Block.ts';
import createCommentsServerStateAtoms from './createCommentsServerStateAtom.js';
import { Atom, atom, useAtomValue, useSetAtom } from 'jotai';

export type CommentsStateForSection = {
  canAddNewComment: boolean;
  cancelNewComment: () => void;
  commentCount: number;
  comments: CommentWithActions[];
  forBlock: (blockId: string) => CommentWithActions[];
  getCommentsCountForBlock: (blockId: string) => number;
  isNewCommentFormOpen: boolean;
  openComment: (commentId: string) => void;
  openNewComment: () => void;
  openSectionComments: () => void;
  postComment: (
    content: CommentContent,
    asDirectMessage: boolean,
    selection?: Comment['selection'],
  ) => Promise<void>;
  scrollSectionIntoViewIfNeeded: () => void;
  sectionId: string;
  sectionTitle: string;
  setCommentElementRef: (commentId: string) => (el: HTMLElement | null) => void;
  setCommentSectionRef: React.RefCallback<HTMLElement>;
  setSectionContentRef: React.RefCallback<HTMLElement>;
  sidebarOpen: boolean;
  userName: string;
  viewers: MaybePendingUser[];
  selectCommentThread: (commentId: string, scrollToSelection?: boolean) => void;
  getSelectedCommentThreadId: () => string | null;
  unselectComment: () => void;
};

export type CommentsState = {
  sidebarOpen: boolean;
  totalCommentCountAtom: Atom<number>;
  filteredCommentsExistAtom: Atom<boolean>;
  isCommentsLoaded: boolean;
  filterState: FilterState;
  setCommentsPanelRef: React.RefCallback<HTMLElement>;
  setZeckScrollContainerRef: React.RefCallback<HTMLElement>;
  setFilter: (filterState: FilterState) => void;
  closeComments: () => void;
  forSection: (publishedSection: PublishedSection) => CommentsStateForSection;
} | null;

function useLoadComments(publishedZeck: PublishedZeck) {
  const api = useApi();
  const { getZeckViewers } = api;

  const [viewers, setViewers] = useState<MaybePendingUser[]>([]);
  const [isCommentsLoaded, setIsCommentsLoaded] = useState(false);
  const commentsAtoms = useMemo(
    () => createCommentsServerStateAtoms(api, publishedZeck),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, publishedZeck.id],
  );

  const loadComments = useSetAtom(commentsAtoms.loadCommentsAtom);

  const zeckId = publishedZeck.zeckId;
  const zeckDisabledComments = publishedZeck.settings.disableComments;

  useEffect(() => {
    if (zeckDisabledComments) {
      return;
    }

    loadComments().then(() => setIsCommentsLoaded(true));
    getZeckViewers(zeckId).then(setViewers);
  }, [
    loadComments,
    getZeckViewers,
    zeckId,
    zeckDisabledComments,
    publishedZeck.sections,
  ]);

  return {
    isCommentsLoaded,
    viewers,
    commentsAtoms,
  };
}

export function useCommentsForZeck(
  userAndCompany: UserAndCompany,
  publishedZeck: PublishedZeck,
): CommentsState | null {
  const { isCommentsLoaded, viewers, commentsAtoms } =
    useLoadComments(publishedZeck);
  const [selectedCommentThreadId, setSelectedCommentThreadId] = useState<
    string | null
  >(null);

  const {
    setCommentSectionRef,
    setSectionContentRef,
    setCommentElementRef,
    setZeckScrollContainerRef,
    openNewCommentFor,
    openCommentsToSection,
    scrollToComment,
    closeComments,
    isSidebarOpen,
    filterState,
    setFilter,
    setCommentsPanelRef,
    forSection: uiStateForSection,
  } = useCommentsUIState();

  const {
    getActionsForComment: _getActionsForComment,
    getActionsForCommentReply: _getActionsForCommentReply,
  } = useActionsForComments(userAndCompany, commentsAtoms);

  const addComment = useSetAtom(commentsAtoms.addComment);
  const commentsAndDMsBySection = useAtomValue(
    commentsAtoms.commentsBySectionAtom,
  );

  const totalCommentCountAtom = useMemo(
    () =>
      atom((get) =>
        Object.values(get(commentsAtoms.commentsBySectionAtom)).reduce(
          (count, comments) =>
            count +
            comments.length +
            comments.reduce(
              (count, comment) => count + comment.replies.length,
              0,
            ),
          0,
        ),
      ),
    [commentsAtoms.commentsBySectionAtom],
  );

  const doFilteredCommentsExistAtom = useMemo(
    () =>
      atom((get) =>
        isFilteredCommentsExisty(
          filterState,
          get(commentsAtoms.commentsBySectionAtom),
        ),
      ),
    [filterState, commentsAtoms.commentsBySectionAtom],
  );

  if (publishedZeck.settings.disableComments) {
    return null;
  }

  const wasImageEdited = (comment: Comment) => {
    if (!comment.selection) return false;

    const commentBlock = comment.selection?.block;
    const section = publishedZeck.sections.find(
      (section) => section.sectionId === comment.sectionId,
    );

    if (!section) return false;

    const block = section.body.find(
      (block) => block.id === comment.selection?.block.id,
    );

    if (!block || !isImageBlock(block) || !isImageBlock(commentBlock))
      return false;

    return block.guid !== commentBlock.guid;
  };

  const wasSelectionDeleted = (comment: Comment) => {
    if (!comment.selection) return false;
    const section = publishedZeck.sections.find(
      (section) => section.sectionId === comment.sectionId,
    );

    if (!section) return false;

    return !section.body.some(
      (block) => block.id === comment.selection?.block.id,
    );
  };

  return {
    isCommentsLoaded,
    filterState,
    totalCommentCountAtom: totalCommentCountAtom,
    filteredCommentsExistAtom: doFilteredCommentsExistAtom,
    setFilter,
    sidebarOpen: isSidebarOpen,
    setZeckScrollContainerRef,
    setCommentsPanelRef,
    closeComments: () => {
      closeComments();
      setSelectedCommentThreadId(null);
    },
    forSection(publishedSection: PublishedSection): CommentsStateForSection {
      const sectionId = publishedSection.sectionId;

      const {
        isNewCommentFormOpen,
        openSectionComments,
        closeNewComment,
        scrollSectionIntoViewIfNeeded,
        canAddNewComment,
        scrollToCommentInZeck,
      } = uiStateForSection(sectionId);

      const commentsForSection = commentsAndDMsBySection[sectionId] || [];

      const userName =
        userAndCompany.user.firstName + ' ' + userAndCompany.user.lastName;

      const { activeComments, resolvedComments, starredComments } =
        filterComments(commentsForSection);

      const filteredComments = (() => {
        switch (filterState) {
          case 'resolved':
            return resolvedComments;
          case 'starred':
            return starredComments;
          case null:
            return activeComments;
        }
      })();

      const comments = filteredComments.map((comment): CommentWithActions => {
        return {
          ...comment,
          selectionEdited: wasImageEdited(comment),
          selectionDeleted: wasSelectionDeleted(comment),
          replies: comment.replies.map((commentReply) => ({
            ...commentReply,
            actions: _getActionsForCommentReply(commentReply),
          })),
          actions: _getActionsForComment(comment),
        };
      });

      const forBlock = (blockId: string) => {
        return comments.filter((c) => c.selection?.block.id === blockId);
      };

      const postComment = async (
        content: CommentContent,
        asDirectMessage = false,
        selection?: Comment['selection'],
      ) => {
        await addComment(sectionId, content, asDirectMessage, selection);
        closeNewComment();
      };

      const commentCount =
        activeComments.length +
        activeComments.reduce((count, c) => count + c.replies.length, 0);

      const getCommentsCountForBlock = (blockId: string) => {
        const commentsForBlock = comments.filter(
          (c) => c.selection?.block.id === blockId,
        );

        const commentRepliesCount =
          commentsForBlock?.reduce((acc, comment) => {
            return acc + comment.replies.length;
          }, 0) ?? 0;

        const commentCount =
          (commentsForBlock?.length ?? 0) + commentRepliesCount;
        return commentCount;
      };

      return {
        setCommentElementRef,
        canAddNewComment,
        cancelNewComment: closeNewComment,
        commentCount,
        comments,
        forBlock,
        getCommentsCountForBlock,
        isNewCommentFormOpen,
        getSelectedCommentThreadId: () => selectedCommentThreadId,
        unselectComment: () => setSelectedCommentThreadId(null),
        selectCommentThread: (commentId, scrollToSelection = false) => {
          setSelectedCommentThreadId(commentId);

          if (scrollToSelection) {
            scrollToCommentInZeck(commentId);
          }
        },
        openComment: (commentId) => {
          openCommentsToSection(sectionId);
          setSelectedCommentThreadId(commentId);

          setTimeout(() => {
            scrollToComment(commentId);
          }, 20);
        },
        openNewComment: () => openNewCommentFor(sectionId),
        openSectionComments: openSectionComments,
        postComment,
        scrollSectionIntoViewIfNeeded,
        sectionId: sectionId,
        sectionTitle: publishedSection.title,
        setCommentSectionRef: setCommentSectionRef(sectionId),
        setSectionContentRef: setSectionContentRef(sectionId),
        sidebarOpen: isSidebarOpen,
        userName,
        viewers,
      };
    },
  };
}

export type CommentReplyWithActions = CommentReply & {
  actions: {
    edit?: (content: CommentContent) => Promise<void>;
    delete?: () => Promise<void>;
  };
};

export type CommentWithActions = Omit<Comment, 'replies'> & {
  replies: CommentReplyWithActions[];
  selectionEdited: boolean;
  selectionDeleted: boolean;
  actions: {
    toggleStar: (() => void) | undefined;
    edit?: (content: CommentContent) => Promise<void>;
    delete?: () => Promise<void>;
    reply: (content: CommentContent) => Promise<void>;
    resolve?: () => void;
    unresolve?: () => void;
  };
};
