import {
  callHandlers,
  handleKey,
} from '../../../../editor/domFacing/events/isKeyMatch.js';

const keyDownHandlerLetsInputsWork = callHandlers<React.KeyboardEvent>([
  handleKey({ key: 'Enter' }, (e) => {
    e.preventDefault();
    // don't allow pressing enter. for contenteditables
  }),
  handleKey({ key: 'Backspace' }, (e) => {
    e.stopPropagation();
    // allow input to handle backspace
  }),
  handleKey({ key: 'Delete' }, (e) => {
    e.stopPropagation();
    // allow input to handle delete
  }),
]);

export default keyDownHandlerLetsInputsWork;
