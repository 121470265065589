import { useRef } from 'react';
import IconButton from '../atoms/IconButton.tsx';
import KebabMenu, { KebabItem } from './KebabMenu.tsx';
import { PositionStrategyRect } from '../../domHelpers/hoverNextTo/positionStrategy/PositionStrategy.js';
import { IconName } from 'icons';

type KebabMenuButtonProps = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  enableDrawer?: boolean;
  drawerHeading?: string;
  items: KebabItem[];
  ariaLabel: string;
  className?: string;
  iconName?: IconName;
  positionStrategy?: PositionStrategyRect;
  usePortal?: boolean;
} & Omit<React.ComponentProps<typeof KebabMenu>, 'elementRef'>;

const KebabMenuButton: React.FC<KebabMenuButtonProps> = ({
  onOpen,
  ariaLabel: ariaLabel,
  className,
  iconName,
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      <IconButton
        ref={buttonRef}
        className={className}
        name={iconName ?? 'verticalKebab'}
        aria-label={ariaLabel}
        onClick={(e) => {
          e.preventDefault();
          onOpen();
        }}
      />

      <KebabMenu {...props} elementRef={buttonRef} />
    </>
  );
};

export default KebabMenuButton;
