import styles from './MagicLinkSignUpPage.module.scss';
import Button from '../../design-system/atoms/Button.tsx';
import { useState } from 'react';
import InputText from '../../design-system/atoms/InputText.tsx';
import {
  AccessPageContent,
  AccessPageLayout,
} from '../../design-system/organisms/AccessPage.js';

export type MagicLinkSignUpPageProps = {
  companyName: string;
  onSubmit: (firstName: string, lastName: string) => void;
};

export default function MagicLinkSignUpPage({
  companyName,
  onSubmit,
}: MagicLinkSignUpPageProps) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  return (
    <AccessPageLayout>
      <AccessPageContent
        {...{
          title: 'Complete Your Sign Up',
          description: (
            <>
              We need just a couple pieces of information before you can join{' '}
              <span className={styles.companyName}>{companyName}</span> on Zeck
            </>
          ),
          actions: (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                onSubmit(firstName, lastName);
              }}
              className={styles.form}
            >
              <InputText
                label="First name"
                value={firstName}
                onChange={setFirstName}
              />
              <InputText
                label="Last name"
                value={lastName}
                onChange={setLastName}
              />
              <span className={styles.terms}>
                By continuing, you agree to the Zeck{' '}
                <a
                  href="https://support.zeck.app/article/19-terms-of-use"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  terms
                </a>{' '}
                and acknowledge receipt of our{' '}
                <a
                  href="https://support.zeck.app/article/17-privacy-policy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  privacy policy
                </a>
                .
              </span>
              <Button
                size="large"
                color="primary"
                disabled={!firstName || !lastName}
                className={styles.submit}
              >
                Complete Sign Up and Continue
              </Button>
            </form>
          ),
        }}
      />
    </AccessPageLayout>
  );
}
