import { baseBlock, createBlock } from './BaseBlock.js';
import { BlockContent } from './Block.js';

export type BulletedListItemBlock = {
  type: 'bulleted-list-item';
  id: string;
  content: BlockContent;
  indent: number;
};

export const bulletedListItemBlock = (
  id: string,
  content: BlockContent,
  indent: number,
): BulletedListItemBlock =>
  baseBlock('bulleted-list-item', id, { content, indent });

export const createBulletedListItemBlock = (
  content: BlockContent,
  indent: number,
): BulletedListItemBlock =>
  createBlock('bulleted-list-item', { content, indent });
