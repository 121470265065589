import { makeMakeApiRequest } from '../makeMakeApiRequest.js';
import SelectionComment from '../../SelectionComment.js';
import pick from 'lodash/pick.js';
import { Block } from 'editor-content/Block.js';
import { Editor } from 'editor-content/TextNode.js';

const createSelectionCommentApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createSelectionComment: async (
    sectionId: SelectionComment['sectionId'],
    selectionId: SelectionComment['selectionId'],
    content: SelectionComment['content'],
    selection: Block | Editor.Highlight,
  ): Promise<SelectionComment> => {
    return await makeLambdaApiRequest(
      `/section/${sectionId}/selection-comment`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ content, selectionId, selection }),
      },
    );
  },
  updateSelectionComment: async (
    comment: SelectionComment,
  ): Promise<SelectionComment> => {
    return await makeLambdaApiRequest(
      `/section/${comment.sectionId}/selection-comment/${comment.id}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(pick(comment, ['content'])),
      },
    );
  },
  deleteSelectionComment: async (
    commentId: SelectionComment['id'],
    sectionId: SelectionComment['sectionId'],
  ): Promise<void> => {
    return await makeLambdaApiRequest(
      `/section/${sectionId}/selection-comment/${commentId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  },
  listSelectionComments: async (
    sectionId: string,
  ): Promise<SelectionComment[]> => {
    const { selectionComments } = await makeLambdaApiRequest(
      `/section/${sectionId}/selection-comment`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return selectionComments;
  },
});

export default createSelectionCommentApi;
