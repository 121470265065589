import { Result } from '../../../services/useFetch/useFetch.ts';
import { ZeckFolderWithCount } from '../../../types/ZeckFolder.ts';
import { useState } from 'react';
import SidebarHeading from '../../../design-system/molecules/SidebarHeading.tsx';
import IconButton from '../../../design-system/atoms/IconButton.tsx';
import NewFolderModal from '../../../design-system/molecules/NewFolderModal.tsx';
import FolderSidebarItem from '../../../design-system/molecules/FolderSidebarItem.tsx';
import { isSuccessResult } from '../../../result/Result.js';
import FolderListLoading from '../../../design-system/molecules/FolderListLoading.tsx';
import TransparentOrderedList, {
  TransparentListItem,
} from '../../../design-system/atoms/TransparentList.tsx';
import styles from './Sidebar.module.scss';
import sortFolders from '../sortFolders.ts';
import FolderSidebarItemEditableDroppable from '../../../design-system/molecules/FolderSidebarItemEditableDroppable.tsx';

type SidebarFoldersProps = {
  collapse?: boolean;
  onUpdateFolder?: (folderId: string, name: string) => void;
  onDeleteFolder?: (folderId: string) => void;
  onCreateFolder?: (name: string) => Promise<void>;
  onClickCollapsedFolder: () => void;
  onClickLink?: () => void;
  zeckFoldersResult: Result<ZeckFolderWithCount[]>;
  className?: string;
  companyId: string;
  activePage: string;
};

const SidebarFolders: React.FC<SidebarFoldersProps> = ({
  collapse,
  onCreateFolder,
  onUpdateFolder,
  onDeleteFolder,
  zeckFoldersResult,
  onClickCollapsedFolder,
  onClickLink,
  companyId,
  activePage,
  className,
}) => {
  const [isAddingFolder, setIsAddingFolder] = useState(false);

  return (
    <div className={className}>
      <SidebarHeading>
        {!collapse && (
          <>
            Folders
            {onCreateFolder && (
              <IconButton
                name="plus"
                aria-label="add folder"
                onClick={() => {
                  setIsAddingFolder(true);
                }}
              />
            )}
          </>
        )}
      </SidebarHeading>
      <NewFolderModal
        isOpen={isAddingFolder && !!onCreateFolder}
        onClose={() => setIsAddingFolder(false)}
        onSubmit={async (name) => {
          if (!onCreateFolder) return;
          await onCreateFolder(name);

          setIsAddingFolder(false);
        }}
      />
      {collapse ? (
        <FolderSidebarItem
          label={null}
          collapse={true}
          href="#"
          active={
            isSuccessResult(zeckFoldersResult) &&
            zeckFoldersResult.data.some((folder) => folder.id === activePage)
          }
          {...{ onClick: onClickCollapsedFolder }}
          isEditing={false}
        />
      ) : isSuccessResult(zeckFoldersResult) ? (
        <TransparentOrderedList
          data-testid="zeck-folders-list"
          className={styles.sidebar__zeckFoldersList}
        >
          {sortFolders(zeckFoldersResult.data).map((folder) => (
            <TransparentListItem key={folder.id}>
              <FolderSidebarItemEditableDroppable
                folderId={folder.id}
                name={folder.name}
                active={folder.id === activePage}
                onUpdateFolder={onUpdateFolder}
                onDeleteFolder={onDeleteFolder}
                onClick={onClickLink}
                href={`/company/${companyId}/folder/${folder.id}`}
              />
            </TransparentListItem>
          ))}
        </TransparentOrderedList>
      ) : (
        <FolderListLoading />
      )}
    </div>
  );
};

export default SidebarFolders;
