import cx from 'classnames';
import { ReactNode } from 'react';
import Tooltip from '../../../../../design-system/molecules/Tooltip.tsx';
import { useFeatureFlags } from '../../../../../feature-flags/FeatureFlagsContext.ts';
import { TableCell } from '../Table.tsx';
import styles from './ViewerEngagementModal.module.scss';
import { AnalyticsZeck } from '../AnalyticsTypes.ts';

export const ZeckViewCell: React.FC<{
  zeck: AnalyticsZeck;
  dataTestId?: string;
  alignRight?: boolean;
  children: ReactNode;
  goLiveDate: Date;
  contentType: 'Viewer' | 'Time Spent';
  className?: string;
}> = ({
  zeck,
  dataTestId,
  children,
  contentType,
  alignRight,
  goLiveDate,
  className,
}) => {
  const { showAllAnalytics } = useFeatureFlags();
  if (!zeck.firstPublishedAt) {
    console.error('No publishedAt date for', zeck);
    return null;
  }

  const dataAvailable =
    showAllAnalytics || new Date(zeck.firstPublishedAt) > goLiveDate;

  if (!dataAvailable) {
    return (
      <TableCell
        data-testid={dataTestId}
        className={cx(
          !dataAvailable && styles.analyticsModal__noData,
          className,
        )}
      >
        No data
        <div className={styles.infoToolTip}>
          <Tooltip>
            {' '}
            Zecks published before {goLiveDate.toLocaleDateString()} will have
            no {contentType} data.
          </Tooltip>
        </div>
      </TableCell>
    );
  }

  return (
    <TableCell
      alignRight={alignRight}
      data-testid={dataTestId}
      className={className}
    >
      {children}
    </TableCell>
  );
};
