import React, { SVGProps } from 'react';

export function MicrosoftExcelIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6855 11.5125L6.38379 10.05V20.8568C6.38389 20.9742 6.40711 21.0904 6.45213 21.1989C6.49715 21.3073 6.56308 21.4058 6.64617 21.4887C6.72925 21.5717 6.82786 21.6374 6.93636 21.6823C7.04487 21.7271 7.16114 21.7501 7.27854 21.75H21.6035C21.7211 21.7503 21.8375 21.7275 21.9462 21.6827C22.0549 21.638 22.1536 21.5722 22.2369 21.4893C22.3201 21.4063 22.3862 21.3077 22.4313 21.1992C22.4764 21.0907 22.4997 20.9743 22.4998 20.8568V16.875L14.6855 11.5125Z"
        fill="#185C37"
      />
      <path
        d="M14.6855 2.25H7.27854C7.16114 2.2499 7.04487 2.27293 6.93636 2.31776C6.82786 2.3626 6.72925 2.42837 6.64617 2.51132C6.56308 2.59426 6.49715 2.69276 6.45213 2.80119C6.40711 2.90962 6.38389 3.02585 6.38379 3.14325V7.125L14.6855 12L19.0813 13.4625L22.4998 12V7.125L14.6855 2.25Z"
        fill="#21A366"
      />
      <path d="M6.38379 7.125H14.6855V12H6.38379V7.125Z" fill="#107C41" />
      <path
        opacity="0.1"
        d="M12.3253 6.1499H6.38379V18.3374H12.3253C12.562 18.3362 12.7888 18.2418 12.9564 18.0746C13.124 17.9074 13.219 17.6809 13.2208 17.4442V7.04315C13.219 6.80641 13.124 6.57989 12.9564 6.4127C12.7888 6.2455 12.562 6.15108 12.3253 6.1499Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.837 6.63745H6.38379V18.825H11.837C12.0738 18.8238 12.3005 18.7294 12.4682 18.5622C12.6358 18.395 12.7308 18.1684 12.7325 17.9317V7.5307C12.7308 7.29396 12.6358 7.06744 12.4682 6.90024C12.3005 6.73305 12.0738 6.63863 11.837 6.63745Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.837 6.63745H6.38379V17.85H11.837C12.0738 17.8488 12.3005 17.7544 12.4682 17.5872C12.6358 17.42 12.7308 17.1934 12.7325 16.9567V7.5307C12.7308 7.29396 12.6358 7.06744 12.4682 6.90024C12.3005 6.73305 12.0738 6.63863 11.837 6.63745Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.3488 6.63745H6.38379V17.85H11.3488C11.5855 17.8488 11.8123 17.7544 11.9799 17.5872C12.1475 17.42 12.2425 17.1934 12.2443 16.9567V7.5307C12.2425 7.29396 12.1475 7.06744 11.9799 6.90024C11.8123 6.73305 11.5855 6.63863 11.3488 6.63745Z"
        fill="black"
      />
      <path
        d="M2.3955 6.63745H11.349C11.5862 6.63725 11.8137 6.73123 11.9816 6.89872C12.1496 7.06622 12.2441 7.29353 12.2445 7.5307V16.4692C12.2441 16.7064 12.1496 16.9337 11.9816 17.1012C11.8137 17.2687 11.5862 17.3627 11.349 17.3625H2.3955C2.27804 17.3626 2.16168 17.3397 2.05309 17.2949C1.9445 17.2501 1.84581 17.1844 1.76264 17.1014C1.67948 17.0184 1.61348 16.9199 1.56841 16.8114C1.52334 16.703 1.5001 16.5867 1.5 16.4692V7.5307C1.5001 7.41324 1.52334 7.29694 1.56841 7.18847C1.61348 7.07999 1.67948 6.98146 1.76264 6.8985C1.84581 6.81555 1.9445 6.74979 2.05309 6.705C2.16168 6.66021 2.27804 6.63726 2.3955 6.63745Z"
        fill="url(#paint0_linear_12579_271002)"
      />
      <path
        d="M4.27539 14.9047L6.15864 11.9917L4.43364 9.09521H5.81889L6.76014 10.95C6.84714 11.1255 6.91014 11.256 6.93864 11.343H6.95139C7.01289 11.202 7.07814 11.0662 7.14639 10.9335L8.15289 9.09821H9.42789L7.65864 11.9782L9.47289 14.907H8.11614L7.02864 12.8737C6.97815 12.7863 6.93524 12.6947 6.90039 12.6H6.88239C6.85068 12.6923 6.8084 12.7806 6.75639 12.8632L5.63664 14.9047H4.27539Z"
        fill="white"
      />
      <path
        d="M21.6043 2.25H14.6855V7.125H22.4998V3.14325C22.4997 3.02579 22.4765 2.90949 22.4314 2.80102C22.3863 2.69254 22.3203 2.59401 22.2372 2.51105C22.154 2.4281 22.0553 2.36234 21.9467 2.31755C21.8381 2.27276 21.7218 2.2498 21.6043 2.25Z"
        fill="#33C481"
      />
      <path d="M14.6855 12H22.4998V16.875H14.6855V12Z" fill="#107C41" />
      <defs>
        <linearGradient
          id="paint0_linear_12579_271002"
          x1="3.3705"
          y1="5.93545"
          x2="10.374"
          y2="18.0645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18884F" />
          <stop offset="0.5" stopColor="#117E43" />
          <stop offset="1" stopColor="#0B6631" />
        </linearGradient>
      </defs>
    </svg>
  );
}
