import {
  bulletedListItemBlock,
  BulletedListItemBlock,
  createBulletedListItemBlock,
} from 'editor-content/BulletedListItemBlock.js';
import splitTextNodesFromContentSelection from '../textNode/splitTextNodesFromContentSelection.js';

import { ContentPatch } from '../../ContentPatch.js';
import ContentSelection, {
  contentSelection,
} from '../../selection/contentSelection/ContentSelection.js';
import { textSelection } from '../../selection/TextSelection.js';
import { TextNode } from 'editor-content/TextNode.js';
import isTextBlockEmpty from '../isTextBlockEmpty.js';

export default function bulletedListItemPressEnterStrategy<B>(
  block: BulletedListItemBlock,
  selection: ContentSelection,
  createDefaultBlock: (textNodes: TextNode[]) => B,
): ContentPatch<[B] | [BulletedListItemBlock, BulletedListItemBlock]> {
  if (isTextBlockEmpty(block)) {
    return {
      contentSubset: [createDefaultBlock([])],
      selection: {
        index: 0,
        offset: {
          anchorOffset: 0,
          focusOffset: 0,
        },
      },
    };
  } else {
    const [beforeSelectionTextNodes, , afterSelectionTextNodes] =
      splitTextNodesFromContentSelection(block.content, selection);

    return {
      contentSubset: [
        bulletedListItemBlock(block.id, beforeSelectionTextNodes, block.indent),
        createBulletedListItemBlock(afterSelectionTextNodes, block.indent),
      ],
      selection: textSelection(1, contentSelection(0)),
    };
  }
}
