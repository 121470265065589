import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function LeftKeyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M11.3004 16.1314L11.9396 15.4957L9.72017 13.2798H15.2955V12.3565H9.72017L11.9396 10.1371L11.3004 9.50497L7.98722 12.8182L11.3004 16.1314Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="white" />
    </svg>
  );
}
