import { DragEventHandler, RefObject, useCallback, useRef } from 'react';
import ContentSelection, {
  contentSelectionIsEqual,
} from './selection/contentSelection/ContentSelection.js';
import BaseAndExtent from './selection/contentSelection/BaseAndExtent.js';
import { SafeHTMLString } from './useContentEditable/SafeHTMLString.js';
import { getContentSelectionFromContainer } from './useContentEditable/getContentSelectionFromContainer.js';
import useUpdateBrowserOnContentChange from './useContentEditable/useUpdateBrowserOnContentChange.js';
import useUpdateBrowserOnSelectionChange from './useContentEditable/useUpdateBrowserOnSelectionChange.js';
import useOnBrowserSelectionChange from './useContentEditable/useOnBrowserSelectionChange.js';
import { setContentSelectionOnContainer } from './useContentEditable/setContentSelectionOnContainer.js';

type ContentEditableHookReturn<ElementType> = {
  ref: RefObject<ElementType>;
  style: React.CSSProperties;
  onInput(): void;
  onInput(e: InputEvent): void;
  onDragOver: DragEventHandler<ElementType>;
  onDrop: DragEventHandler<ElementType>;
};
const createUseContentEditableWithSelection =
  <ContentType, ContextType>(
    adapter: {
      toHTMLString: (
        content: ContentType,
        context: ContextType,
      ) => SafeHTMLString;
      fromHTMLString: (html: string) => ContentType;
      toBaseAndExtent: (
        selection: ContentSelection,
        el: HTMLElement,
      ) => BaseAndExtent;
      fromBaseAndExtent: (
        baseAndExtent: BaseAndExtent,
        el: HTMLElement,
      ) => ContentSelection;
    },
    fullyControlled = false,
  ) =>
  <ElementType extends HTMLElement>(
    value: {
      content: ContentType;
      selection: ContentSelection | null;
    },
    onChange: (newValue: {
      content: ContentType;
      selection: ContentSelection;
    }) => void,
    onSelect: (newSelection: ContentSelection | null) => void,
    context: ContextType,
  ): ContentEditableHookReturn<ElementType> => {
    const ref = useRef<ElementType | null>(null);

    const htmlString = adapter.toHTMLString(value.content, context);

    // React -> Browser
    useUpdateBrowserOnContentChange(
      ref,
      htmlString,
      value.selection,
      adapter.toBaseAndExtent,
    );
    useUpdateBrowserOnSelectionChange(
      ref,
      value.selection,
      adapter.toBaseAndExtent,
    );

    // Browser -> React
    useOnBrowserSelectionChange(
      ref,
      (newSelection) => {
        // probably because typing sends selectionchange events and change events,
        // so useOnChangeWithSelection will also be trying to redundantly set selection
        if (!contentSelectionIsEqual(value.selection, newSelection)) {
          onSelect(newSelection);
        }
      },
      adapter.fromBaseAndExtent,
    );

    const onChangeWithSelection = useCallback(
      (newContent: string) => {
        const containerElement = ref.current;

        if (!containerElement) return;

        const newSelection = getContentSelectionFromContainer(
          containerElement,
          adapter.fromBaseAndExtent,
        );

        if (!newSelection) return;

        onChange({
          content: adapter.fromHTMLString(newContent),
          selection: newSelection,
        });
      },
      [ref, onChange],
    );

    return {
      ref,
      style: {
        whiteSpace: 'pre-wrap',
        cursor: 'text',
      },
      onInput() {
        const el = ref.current;
        if (!el) return;

        onChangeWithSelection(el.innerHTML);
        if (fullyControlled) {
          el.innerHTML = htmlString;
          setContentSelectionOnContainer(
            value.selection,
            el,
            adapter.toBaseAndExtent,
          );
        }
      },
      onDragOver(e) {
        e.preventDefault();
      },
      onDrop(e) {
        e.preventDefault();
      },
    };
  };

export default createUseContentEditableWithSelection;
