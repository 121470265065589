import { makeMakeApiRequest } from '../makeMakeApiRequest.js';
import pick from 'lodash/pick.js';
import { MeetingMinutesBlock } from 'editor-content/MeetingMinutes/MeetingMinutesBlock.ts';

export type MinutesSignature = {
  consentText: string;
  name: string;
  title: string | null;
};

export type Signature = {
  id: string;
  signedName: string;
  signedTitle: string | null;
};

export type MeetingMinutesListItem = {
  id: string;
  title: string;
  approved: boolean;
  zeckId: string | null;
  companyId: string;
  zeckName: string | null;
  version: string;
  hasSignatures: boolean;
};

export type MeetingMinutes = {
  id: string;
  companyId: string;
  zeckId: string | null;
  title: string;
  approved: boolean;
  archivedHtml: string | null;
  version: string;
  updatedById: string | null;
  content: MeetingMinutesBlock[];
  createdAt: number;
  updatedAt: number;
  // TODO make this non-undefinable
  signatures?: Signature[];
};

const createMeetingMinutesApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  fetchMeetingMinutes: async (
    meetingMinutesId: string,
  ): Promise<MeetingMinutes> => {
    return makeLambdaApiRequest(`/meeting-minutes/${meetingMinutesId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  fetchAllMeetingMinutes: async (
    companyId: string,
  ): Promise<MeetingMinutesListItem[]> => {
    return makeLambdaApiRequest(`/company/${companyId}/meeting-minutes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  deleteMeetingMinutes: async (meetingMinutesId: string): Promise<void> => {
    await makeLambdaApiRequest(`/meeting-minutes/${meetingMinutesId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ meetingMinutesId }),
    });
  },
  createMeetingMinutes: async (
    companyId: string,
    meetingMinutes: Pick<MeetingMinutes, 'title' | 'content'>,
  ): Promise<MeetingMinutes> => {
    return makeLambdaApiRequest(`/company/${companyId}/meeting-minutes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        title: meetingMinutes.title,
        content: meetingMinutes.content,
      }),
    });
  },
  createMeetingMinutesFromZeck: async (
    companyId: string,
    zeckId: string,
  ): Promise<MeetingMinutes> => {
    return makeLambdaApiRequest(
      `/company/${companyId}/meeting-minutes-from-zeck`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          zeckId,
        }),
      },
    );
  },
  updateMeetingMinutes: async (
    meetingMinutes: Partial<MeetingMinutes> &
      Pick<MeetingMinutes, 'id' | 'version'>,
  ): Promise<MeetingMinutes> => {
    return makeLambdaApiRequest(`/meeting-minutes/${meetingMinutes.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(
        pick(meetingMinutes, ['title', 'content', 'version']),
      ),
    });
  },

  approvalMeetingMinutes: async (
    meetingMinutes: Pick<MeetingMinutes, 'id' | 'version' | 'approved'>,
  ): Promise<MeetingMinutes> => {
    return makeLambdaApiRequest(
      `/meeting-minutes/${meetingMinutes.id}/approval`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(pick(meetingMinutes, ['version', 'approved'])),
      },
    );
  },

  signMeetingMinutes: async (
    meetingMinutes: Pick<MeetingMinutes, 'id' | 'version'>,
    signature: MinutesSignature,
  ): Promise<MeetingMinutes> => {
    return makeLambdaApiRequest(`/meeting-minutes/${meetingMinutes.id}/sign`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        version: meetingMinutes.version,
        ...signature,
      }),
    });
  },

  duplicateMeetingMinutes: async (
    meetingMinutesId: string,
    keepZeck: boolean,
  ): Promise<MeetingMinutes> => {
    return makeLambdaApiRequest(
      `/meeting-minutes/${meetingMinutesId}/duplicate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ keepZeck }),
      },
    );
  },
});

export default createMeetingMinutesApi;
