import ThreeDotLoading from '../../../../design-system/molecules/ThreeDotLoading.tsx';
import { AiChartFlowBuildingContainer } from './AiChartFlowBuildingContainer.tsx';
import styles from './AiChartFlowLoading.module.scss';
import { AiChartWizardBadge } from './AiChartWizardBadge.tsx';

export type AiChartFlowLoadingState = {
  type: 'loading';
};

export const AiChartFlowLoading: React.FC = () => {
  return (
    <AiChartFlowBuildingContainer>
      <div className={styles.loadingContainer}>
        <AiChartWizardBadge />

        <ThreeDotLoading className={styles.loadingIcon} />
      </div>
    </AiChartFlowBuildingContainer>
  );
};
