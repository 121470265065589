import { isTextBlock } from 'editor-content/Block.js';
import { EditorContent } from '../../edit/useEditorState.js';
import ZeckEditorSelection from '../ZeckEditorSelection.js';
import { isCollapsed } from '../../../../editor/selection/contentSelection/ContentSelection.js';
import getHighlightIdsAtOffset from './getHighlightIdsAtOffset.js';
import { isBlockSelection } from '../../../../editor/selection/BlockSelection.js';

export default function getCurrentHighlightId(
  content: EditorContent,
  selection: ZeckEditorSelection,
): string[] {
  if (!selection) return [];

  switch (selection.target) {
    case 'title':
      return [];
    case 'headline':
      if (!isCollapsed(selection)) return [];

      return getHighlightIdsAtOffset(content.headline, selection.anchorOffset);
    case 'body': {
      if (isBlockSelection(selection)) return [];
      if (!isCollapsed(selection.offset)) return [];
      const block = content.body[selection.index];
      if (!block) return [];
      if (!isTextBlock(block)) return [];

      return getHighlightIdsAtOffset(
        block.content,
        selection.offset.anchorOffset,
      );
    }
  }

  return [];
}
