import { findFirstTextNode } from './RangeHelpers.js';

export const isOnFirstLine = (
  containerNode: HTMLElement,
  range: Range,
): boolean => {
  const referenceRange = new Range();
  const firstTextNode = findFirstTextNode(containerNode);
  if (!firstTextNode) {
    return true;
  }
  referenceRange.selectNodeContents(firstTextNode);
  referenceRange.collapse(true);

  return (
    referenceRange.getBoundingClientRect().y === range.getBoundingClientRect().y
  );
};
