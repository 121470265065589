import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function StarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <rect width="24" height="24" rx="4" />
      <path
        d="M7.6248 6.39961L11.9998 0.724609L16.3748 6.39961L23.2248 8.69961L18.8998 14.8246L19.0748 21.6496L11.9998 19.6746L4.9248 21.6496L5.0998 14.7996L0.799805 8.69961L7.6248 6.39961ZM8.8498 8.12461L3.9998 9.72461L7.0998 14.1996L6.9998 18.9746L11.9998 17.5996L16.9998 18.9996L16.8998 14.1996L19.9998 9.77461L15.1498 8.12461L11.9998 3.99961L8.8498 8.12461Z"
        fill="#8F90A6"
      />
    </svg>
  );
}
