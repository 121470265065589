import Linkable from 'editor-content/html/Linkable.ts';
import { Fragment } from 'react';
import { HeadlineLine } from '../../../design-system/zeck/Headline.js';
import { indexIsInSelection } from '../../../editor/selection/BlockSelection.js';
import ContentSelection from '../../../editor/selection/contentSelection/ContentSelection.js';
import { isTextSelection } from '../../../editor/selection/TextSelection.js';
import { HydratedBlock } from '../../../types/HydratedBlock.js';
import { EditorContent } from '../edit/useEditorState.js';
import {
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
} from '../voting/VoteCapability.ts';
import { AiContext } from './AiChartFlow/createAiContext.ts';
import BlockEditable from './BlockEditable.js';
import { getSpacing } from './blockPreprocessing/addSpacing.js';
import { renderGroupListRenderables } from './blockPreprocessing/groupListRenderables.js';
import { SelectionCommentsBehavior } from './comments/SelectionCommentsBehavior.ts';
import {
  addTransientHighlights,
  withRemoveTransientHighlights,
} from './comments/TransientHighlights.js';
import HeadlineEditableWithKeyboard from './editableBlocks/HeadlineEditableWithKeyboard.js';
import TextSelectionFormattingMenu from './editableBlocks/TextSelectionFormattingMenu.js';
import TitleEditableWithSelection from './editableBlocks/TitleEditableWithSelection.js';
import useFancyNav from './FancyNav/useFancyNav.ts';
import useZeckEditor from './useZeckEditor.js';
import withBlockKeys from './withBlockKeys.ts';
import styles from './ZeckEditable.module.scss';
import ZeckEditorSelection, {
  getBodySelection,
  getHeadlineSelection,
  getTitleSelection,
} from './ZeckEditorSelection.js';

const ZeckEditableContent: React.FC<{
  editor: Omit<
    ReturnType<typeof useZeckEditor>,
    'bodySelection' | 'bodyContent'
  >;
  editorContent: EditorContent;
  editorSelection: ZeckEditorSelection;
  setBlockEl: (blockIndex: number) => (el: HTMLElement) => void;
  fancyNavBehavior: ReturnType<typeof useFancyNav>;
  selectionCommentsBehavior: SelectionCommentsBehavior;
  linkables: Linkable[];
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  aiContext: AiContext;
  renderBlockControls: (block: HydratedBlock, index: number) => React.ReactNode;
}> = ({
  editor,
  editorContent,
  setBlockEl,
  editorSelection,
  fancyNavBehavior,
  selectionCommentsBehavior,
  linkables,
  zeckFinalizeVoteCapability,
  zeckPrevoteCapability,
  aiContext,
  renderBlockControls,
}) => {
  const { title, headline, body } = addTransientHighlights(
    editorContent,
    selectionCommentsBehavior.transientHighlightSelection,
  );
  const { editBlock, editHeadline } = withRemoveTransientHighlights(editor);

  const bodySelection = getBodySelection(editorSelection);

  const blocksWithKey = withBlockKeys(body);

  return (
    <>
      <TitleEditableWithSelection
        {...{
          className: styles.zeckEditable__sectionTitle,
          'data-testid': 'title-editable',
          ref: fancyNavBehavior.title.ref,
          value: title,
          onChange: (content, selection) =>
            editor.editTitle({ content, selection }),
          onSelect: editor.selectTitle,
          selection: getTitleSelection(editorSelection),
          onNavRight: editor.navRight,
          onPressEnter: editor.pressEnter,
          onNavLeft() {
            return;
          },
          onNavUp: fancyNavBehavior.title.onNavUp,
          onNavDown: fancyNavBehavior.title.onNavDown,
        }}
      />
      <HeadlineEditableWithKeyboard
        {...{
          ref: fancyNavBehavior.headline.ref,
          'data-testid': 'block-1',
          value: headline,
          onChange: (content, selection) =>
            editHeadline({ content, selection }),
          linkables,
          selection: getHeadlineSelection(editorSelection),
          onPressEnter: editor.pressEnter,
          onNavUp: fancyNavBehavior.headline.onNavUp,
          onNavDown: fancyNavBehavior.headline.onNavDown,
          onNavLeft: editor.navLeft,
          onNavRight: editor.navRight,
          onSelectOut: () => null,
          onSelect: editor.selectHeadline,
          onToggleFormat: editor.toggleFormat,
          onAddLink: editor.addLink,
          selectionCommentsBehavior,
        }}
      />
      <HeadlineLine />

      {renderGroupListRenderables(
        blocksWithKey.map((item) => ({
          ...item,
          data: { block: item.block },
        })),

        ({ block, key, spacing, groupedRenderableType, i }) => {
          const blockScopedSelection =
            bodySelection &&
            isTextSelection(bodySelection) &&
            bodySelection.index === i
              ? bodySelection.offset
              : null;

          return (
            <Fragment key={key}>
              {renderBlockControls(block, i)}
              <BlockEditable
                {...{
                  className:
                    groupedRenderableType === 'item' ? getSpacing(spacing) : '',
                  block,
                  linkables,
                  ref: setBlockEl(i),
                  'data-testid': `block-${i + 2}`,

                  formattingMenu: (
                    <TextSelectionFormattingMenu
                      linkables={linkables}
                      block={block}
                      selection={blockScopedSelection}
                      onIndent={editor.indent}
                      onToggleFormat={editor.toggleFormat}
                      onAddLink={editor.addLink}
                      onTurnInto={editor.turnInto}
                      selectionCommentsBehavior={selectionCommentsBehavior}
                    />
                  ),

                  isInBlockSelection:
                    !!bodySelection &&
                    !isTextSelection(bodySelection) &&
                    indexIsInSelection(bodySelection, i),

                  selection: blockScopedSelection,
                  onChange: (
                    newBlock: HydratedBlock,
                    contentSelection: ContentSelection,
                  ) => editBlock(i, newBlock, contentSelection),
                  onSelect(contentSelection: ContentSelection) {
                    editor.selectBody({
                      index: i,
                      offset: contentSelection,
                    });
                  },
                  onEnter: editor.pressEnter,
                  onNavRight: editor.navRight,
                  onNavLeft: editor.navLeft,
                  onNavUp: fancyNavBehavior.body.onNavUp,
                  onNavDown: fancyNavBehavior.body.onNavDown,
                  onSelectOut: editor.selectUp,
                  onDelete: editor.pressBackspace,
                  zeckFinalizeVoteCapability,
                  zeckPrevoteCapability,
                  aiContext,
                  onInsertChartBlock: (chartBlock) =>
                    editor.AddBlock.addNewBlock(i, chartBlock),
                  pressSpacebar: editor.pressSpacebar,
                }}
              />
            </Fragment>
          );
        },
      )}
    </>
  );
};

export default ZeckEditableContent;
