import styles from './FolderListLoading.module.scss';
const FolderListLoading: React.FC = () => {
  return (
    <div className={styles.folderListLoading}>
      <div className={styles.folderListLoading__item}></div>
      <div className={styles.folderListLoading__item}></div>
      <div className={styles.folderListLoading__item}></div>
    </div>
  );
};

export default FolderListLoading;
