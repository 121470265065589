import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function TextIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M2.6514 17.7441C2.5874 17.7441 2.5394 17.7227 2.5074 17.6801C2.4754 17.6267 2.4754 17.5574 2.5074 17.4721L6.6514 6.09607C6.6834 6.01074 6.72073 5.95207 6.7634 5.92007C6.80607 5.88807 6.8754 5.87207 6.9714 5.87207H8.2194C8.42207 5.87207 8.54473 5.95207 8.5874 6.11207L12.6834 17.4721L12.6994 17.5521C12.6994 17.6801 12.6407 17.7441 12.5234 17.7441H11.3874C11.2594 17.7441 11.1741 17.6907 11.1314 17.5841L10.0274 14.4801C10.0061 14.4374 9.98473 14.4054 9.9634 14.3841C9.94207 14.3627 9.8994 14.3521 9.8354 14.3521H5.1794C5.0834 14.3521 5.0194 14.3947 4.9874 14.4801L3.8514 17.6001C3.8194 17.6961 3.7394 17.7441 3.6114 17.7441H2.6514ZM9.4194 13.1361C9.53673 13.1361 9.5794 13.0667 9.5474 12.9281L7.6274 7.66407C7.5954 7.56807 7.55807 7.52007 7.5154 7.52007C7.47273 7.52007 7.4354 7.56807 7.4034 7.66407L5.4514 12.9601L5.4194 13.0561C5.4194 13.1094 5.46207 13.1361 5.5474 13.1361H9.4194Z"
        fill="#1C1C28"
      />
      <path
        d="M19.5564 16.7201C18.7351 17.5094 17.7857 17.9041 16.7084 17.9041C15.8764 17.9041 15.2204 17.6961 14.7404 17.2801C14.2711 16.8534 14.0364 16.2881 14.0364 15.5841C14.0364 14.7307 14.3937 14.0587 15.1084 13.5681C15.8337 13.0774 16.8791 12.7894 18.2444 12.7041L19.4924 12.6081C19.6737 12.5867 19.7644 12.5067 19.7644 12.3681V11.9361C19.7644 11.3601 19.5831 10.9067 19.2204 10.5761C18.8684 10.2347 18.3884 10.0641 17.7804 10.0641C16.8417 10.0641 16.0737 10.4907 15.4764 11.3441C15.4444 11.3974 15.4124 11.4347 15.3804 11.4561C15.3484 11.4667 15.3057 11.4614 15.2524 11.4401L14.3884 11.1521C14.3351 11.1307 14.3084 11.0934 14.3084 11.0401C14.3084 11.0081 14.3244 10.9601 14.3564 10.8961C14.6764 10.3307 15.1297 9.8934 15.7164 9.58407C16.3137 9.26407 17.0177 9.10407 17.8284 9.10407C18.8737 9.10407 19.6737 9.3494 20.2284 9.84007C20.7831 10.3201 21.0604 10.9867 21.0604 11.8401V17.5041C21.0604 17.6001 21.0444 17.6641 21.0124 17.6961C20.9804 17.7281 20.9217 17.7441 20.8364 17.7441H20.0364C19.9084 17.7441 19.8337 17.6587 19.8124 17.4881L19.7804 16.7521C19.7697 16.6774 19.7431 16.6401 19.7004 16.6401C19.6577 16.6401 19.6097 16.6667 19.5564 16.7201ZM19.7644 13.6801C19.7644 13.5094 19.6844 13.4241 19.5244 13.4241L18.4844 13.5201C17.4924 13.5841 16.7244 13.7761 16.1804 14.0961C15.6471 14.4161 15.3804 14.8587 15.3804 15.4241C15.3804 15.8827 15.5404 16.2454 15.8604 16.5121C16.1911 16.7787 16.6284 16.9121 17.1724 16.9121C17.5031 16.9121 17.8284 16.8534 18.1484 16.7361C18.4684 16.6187 18.7511 16.4587 18.9964 16.2561C19.2417 16.0641 19.4284 15.8614 19.5564 15.6481C19.6951 15.4241 19.7644 15.2107 19.7644 15.0081V13.6801Z"
        fill="#1C1C28"
      />
    </svg>
  );
}
