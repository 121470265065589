import { makeMakeApiRequest } from '../makeMakeApiRequest.js';

export type CredentialListItem = {
  id: string;
  displayName: string;
};

export type CredentialResponse =
  | ValidCredentialResponse
  | ErrorCredentialResponse;

export type ValidCredentialResponse = {
  id: string;
  status: 'valid';
  accessToken: string;
  type: 'microsoft' | 'google';
};

export type ErrorCredentialResponse = {
  id: string;
  status: 'error';
  errorCode: 'missing_scopes' | 'no_access_token' | 'unknown_error';
  authUrl: string | null;
  displayName: string;
  type: 'microsoft' | 'google';
};

export type CreateCredential = {
  id: string;
  authUrl: string;
};

const createCredentialApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createCredential: async (
    type: 'microsoft' | 'google' | 'carta',
  ): Promise<CreateCredential> => {
    return makeLambdaApiRequest(`/credential`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ type }),
    });
  },
  fetchAllCredentials: async (
    type: 'microsoft' | 'google',
  ): Promise<CredentialListItem[]> => {
    const response = await makeLambdaApiRequest(`/credential?type=${type}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.credentials;
  },
  fetchCredentialResponse: async (
    credentialId: string,
  ): Promise<CredentialResponse> => {
    return makeLambdaApiRequest(`/credential/${credentialId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
});

export default createCredentialApi;
