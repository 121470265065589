import styles from './EditorTopBar.module.scss';
import cx from 'classnames';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';
import StatusIndicator from '../../../../design-system/molecules/StatusIndicator.tsx';
import SavedStatusIndicator from '../SavedStatusIndicator.tsx';
import {
  sectionPreviewPath,
  zeckPreviewPath,
} from '../../../../services/Paths.ts';
import { Zeck, Section } from '../../../../types.ts';
import AddContributorsModal from './share/AddContributorsModal.js';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'icons';
import WithTooltip from '../../../../design-system/organisms/WithTooltip.tsx';
import { truncate } from '../../../../junkDrawer/truncate.ts';
import OutlineButton from '../../../../design-system/atoms/OutlineButton.tsx';
import { memo, useState } from 'react';
import TopBarLayout from '../../../../design-system/layouts/TopBarLayout.tsx';
import readTimeForSection from '../../../../readTime/readTimeForSection.ts';

type EditMenuState = 'not-open' | 'add-contributors';

export const useUIState = () => {
  const [editMenuState, setEditMenuState] = useState<EditMenuState>('not-open');

  const actions = {
    onRequestAddContributors() {
      setEditMenuState('add-contributors');
    },
    onAddContributorsClose() {
      setEditMenuState('not-open');
    },
  };

  switch (editMenuState) {
    case 'not-open':
      return {
        ...actions,
        showAddContributors: false,
      };
    case 'add-contributors':
      return {
        ...actions,
        showAddContributors: true,
      };
  }
};

type EditorTopBarProps = {
  zeck: Zeck;
  section?: Section;
  isSaved: boolean;
  menuIsOpen: boolean;
  setMenuIsOpen(value: boolean): void;
};

const EditorTopBar = ({
  zeck,
  section: currentSection,
  isSaved,
  menuIsOpen,
  setMenuIsOpen,
}: EditorTopBarProps) => {
  const {
    showAddContributors,
    onRequestAddContributors,
    onAddContributorsClose,
  } = useUIState();

  const navigate = useNavigate();

  function onClickPreviewSection() {
    if (currentSection) {
      navigate(sectionPreviewPath(currentSection, zeck.companyId));
    } else {
      navigate(zeckPreviewPath(zeck));
    }
  }

  return (
    <TopBarLayout
      className={styles.editorTopBar__header}
      data-testid="editor-top-bar"
      {...{
        leftSlot: (
          <div className={styles.editorTopBar__headerLeft}>
            {!menuIsOpen && (
              <IconButton
                {...{
                  name: 'sidebar',
                  'aria-label': 'open sidebar',
                  onClick: (e) => {
                    e.stopPropagation();
                    setMenuIsOpen(true);
                  },
                }}
              />
            )}
            <div
              className={cx(
                styles.editorTopBar__headerStatusSection,
                menuIsOpen &&
                  styles.editorTopBar__headerStatusSection_sidebarOpen,
              )}
            >
              <StatusIndicator iconName="lock">Secure</StatusIndicator>
              <SavedStatusIndicator isSaved={isSaved} />
            </div>
          </div>
        ),
        rightSlot: (
          <div className={styles.editorTopBar__headerRight}>
            <WithTooltip<HTMLButtonElement> text="Preview Site">
              {(ref, listeners) => (
                <OutlineButton
                  size="medium"
                  color="secondary"
                  onClick={onClickPreviewSection}
                  ref={ref}
                  {...listeners}
                >
                  <Icon
                    name="eye"
                    className={styles.editorTopBar__headerRight__icon}
                  />
                  Preview
                </OutlineButton>
              )}
            </WithTooltip>

            {zeck.isAdmin && currentSection && (
              <WithTooltip<HTMLButtonElement> text="Add or remove Section Contributors">
                {(ref, listeners) => (
                  <OutlineButton
                    size="medium"
                    color="primary"
                    onClick={onRequestAddContributors}
                    ref={ref}
                    {...listeners}
                  >
                    <Icon
                      name="personAdd"
                      className={styles.editorTopBar__headerRight__icon}
                    />
                    Add Contributors
                  </OutlineButton>
                )}
              </WithTooltip>
            )}
          </div>
        ),
      }}
    >
      <div
        data-testid="top-bar-section-title"
        className={styles.editorTopBar__headerSectionTitle}
      >
        {currentSection ? truncate(currentSection.title, 25) : ''}
        {currentSection && !currentSection.supplemental && (
          <span className={styles.readTime}>
            {readTimeForSection(currentSection)} min read time
          </span>
        )}
      </div>
      {currentSection && (
        <AddContributorsModal
          isOpen={showAddContributors}
          onClose={onAddContributorsClose}
          zeck={zeck}
          section={currentSection}
        />
      )}
    </TopBarLayout>
  );
};

export default memo(EditorTopBar);
