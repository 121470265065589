import StatusIndicator from '../../../design-system/molecules/StatusIndicator.tsx';
import { AnimatePresence, motion } from 'framer-motion';

const StatusIndicatorMotion = motion(StatusIndicator);

const SavedStatusIndicator = ({ isSaved }: { isSaved: boolean }) => {
  return (
    <AnimatePresence>
      {isSaved && (
        <StatusIndicatorMotion
          key="status"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          iconName="checkmark"
        >
          Saved
        </StatusIndicatorMotion>
      )}
    </AnimatePresence>
  );
};

export default SavedStatusIndicator;
