import { MouseEventHandler, RefCallback, useRef } from 'react';
import { useRootClickListener } from './useRootEventListener.tsx';

type DetectsOutsideClickProps<ElementType> = {
  onClick?: MouseEventHandler;
  children: (containerRef: RefCallback<ElementType>) => JSX.Element;
};

const DetectsOutsideClick = <ElementType extends HTMLElement>({
  onClick,
  children,
}: DetectsOutsideClickProps<ElementType>) => {
  const containerRef = useRef<ElementType | null>(null);

  useRootClickListener((e) => {
    const el = containerRef.current;
    if (
      e.target instanceof Element &&
      e.target.closest('body') &&
      el &&
      !el.contains(e.target)
    ) {
      onClick && onClick(e);
    }
  });

  return children((el) => {
    containerRef.current = el;
  });
};

export default DetectsOutsideClick;
