import styles from './SessionLayout.module.scss';
import { ReactComponent as ZeckLogo } from '../../images/Zeck-logo.svg';
import useBreakpoints from '../../services/useBreakpoints.ts';
import { useState } from 'react';
import cx from 'classnames';
import { testimonialRandomizer } from './testimonialRandomizer.ts';

type SessionLayoutProps = {
  children?: React.ReactNode;
  breakpointOverrides?: ReturnType<typeof useBreakpoints>;
  getTestimonial?: () => {
    name: string;
    title: string;
    company: string;
    quote: string;
    imageClass: string;
  };
};

const SessionLayout: React.FunctionComponent<SessionLayoutProps> = ({
  children,
  breakpointOverrides,
  getTestimonial,
}) => {
  const breakpoints = useBreakpoints();
  const { isMobile, isTablet } = breakpointOverrides || breakpoints;
  const testimonialFunction = getTestimonial ?? testimonialRandomizer;
  const [testimonial] = useState(testimonialFunction());

  const testimonialBgClass = styles[`sessionLayout__${testimonial.imageClass}`];

  if (isMobile) {
    return (
      <div className={styles.sessionLayout_mobile}>
        <header className={styles.sessionLayout__header_mobile}>
          <ZeckLogo height={24} />
        </header>
        <main className={styles.sessionLayout__main_mobile}>{children}</main>
      </div>
    );
  }

  if (isTablet) {
    return (
      <div className={styles.sessionLayout__tablet}>
        <header className={styles.sessionLayout__header_tablet}>
          <ZeckLogo height={32} />
        </header>
        <main className={styles.sessionLayout__main}>{children}</main>
      </div>
    );
  }

  return (
    <div className={styles.sessionLayout}>
      <aside className={cx([styles.sessionLayout__aside, testimonialBgClass])}>
        <figure className={styles.sessionLayout__quote}>
          <blockquote className={styles.sessionLayout__quoteText}>
            "{testimonial.quote}"
          </blockquote>
          <figcaption className={styles.sessionLayout__quoteAuthor}>
            <div>{testimonial.name}</div>
            <div>{testimonial.title}</div>
            <div>{testimonial.company}</div>
          </figcaption>
        </figure>
      </aside>
      <header className={styles.sessionLayout__header}>
        <ZeckLogo height={32} />
      </header>
      <main className={styles.sessionLayout__main}>{children}</main>
    </div>
  );
};

export default SessionLayout;
