import assertUnreachable from '../../../../junkDrawer/assertUnreachable.js';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.js';
import splitTextNodesFromContentSelection from '../../../../editor/blocks/textNode/splitTextNodesFromContentSelection.js';
import {
  Editor,
  NonHighlightTextNode,
  TextNode,
} from 'editor-content/TextNode.js';

function toLinkNode(link: Editor.LinkType, textNodes: NonHighlightTextNode[]) {
  return {
    ...link,
    // wrap content of highlight in a link
    content: textNodes.flatMap((textNode) => {
      switch (textNode.type) {
        case 'text':
          return textNode;
        // replace old links with new link
        case 'external-link':
        case 'section-link':
        case 'meeting-minutes-link':
          return textNode.content;
      }
      return assertUnreachable(textNode);
    }),
  };
}

export default function addLinkToTextNodes(
  textNodes: TextNode[],
  selection: ContentSelection,
  link: Editor.LinkType,
): TextNode[] {
  const [beforeSelectionContent, inSelectionContent, afterSelectionContent] =
    splitTextNodesFromContentSelection(textNodes, selection);

  return [
    ...beforeSelectionContent,
    ...inSelectionContent.map((textNode): TextNode => {
      switch (textNode.type) {
        case 'highlight': {
          const textNodes = textNode.content;
          return {
            ...textNode,
            content: [toLinkNode(link, textNodes)],
          };
        }
        case 'text':
        case 'external-link':
        case 'section-link':
        case 'meeting-minutes-link': {
          return toLinkNode(link, [textNode]);
        }
      }

      return assertUnreachable(textNode);
    }),
    ...afterSelectionContent,
  ];
}
