import Button from '../../atoms/Button.tsx';
import OutlineButton from '../../atoms/OutlineButton.tsx';
import { OutstandingVoteBlock, Vote } from 'editor-content/VoteBlock.ts';
import { useState } from 'react';
import VoteSectionLayout from '../vote/VoteSectionLayout.tsx';
import VoteControlsRow from '../vote/VoteControlsRow.js';

type UIState = 'not_taking_vote' | 'taking_vote';

const FINALIZE_VOTE_DISABLED_TOOLTIP_TEXT =
  'The Take Vote button is enabled for Admins in a published Zeck. Finalizing a vote saves the vote outcome securely in the minutes book.';

const FINALIZE_VOTE_ENABLED_TOOLTIP_TEXT =
  'Finalizing a vote saves the vote outcome securely in the minutes book.';

const FinalizeVoteControls: React.FC<{
  block: OutstandingVoteBlock;
  takeVote:
    | ((
        vote: Pick<
          Vote,
          'title' | 'details' | 'blockId' | 'approved' | 'voteType'
        >,
      ) => Promise<void>)
    | null;
  className?: string;
}> = ({ block, className, takeVote }) => {
  const [uiState, setUIState] = useState<UIState>('not_taking_vote');
  const [isProcessingVote, setIsProcessingVote] = useState(false);

  const heading = 'Finalize Vote';

  if (!takeVote) {
    return (
      <VoteSectionLayout
        tooltip={FINALIZE_VOTE_DISABLED_TOOLTIP_TEXT}
        heading={heading}
        className={className}
      >
        <VoteControlsRow>
          <Button size="large" color="primary" disabled>
            Take Vote
          </Button>
        </VoteControlsRow>
      </VoteSectionLayout>
    );
  }

  return (
    <VoteSectionLayout
      tooltip={FINALIZE_VOTE_ENABLED_TOOLTIP_TEXT}
      heading={heading}
      className={className}
    >
      {uiState === 'not_taking_vote' ? (
        <VoteControlsRow>
          <Button
            size="large"
            color="primary"
            onClick={() => setUIState('taking_vote')}
            key="takeVote" // added to stop transition
          >
            Take Vote
          </Button>
        </VoteControlsRow>
      ) : (
        <>
          <VoteControlsRow>
            <Button
              size="large"
              color="green"
              onClick={async () => {
                setIsProcessingVote(true);
                try {
                  await takeVote({
                    title: block.title,
                    details: block.details,
                    blockId: block.id,
                    approved: true,
                    voteType: block.voteType,
                  });
                } catch (err) {
                  setIsProcessingVote(false);
                }
              }}
              disabled={isProcessingVote}
            >
              Approved
            </Button>
            <Button
              size="large"
              color="danger"
              onClick={async () => {
                setIsProcessingVote(true);
                try {
                  await takeVote({
                    title: block.title,
                    details: block.details,
                    blockId: block.id,
                    approved: false,
                    voteType: block.voteType,
                  });
                } catch (err) {
                  setIsProcessingVote(false);
                }
              }}
              disabled={isProcessingVote}
            >
              Not Approved
            </Button>
          </VoteControlsRow>
          <VoteControlsRow>
            <OutlineButton
              size="large"
              color="secondary"
              onClick={() => setUIState('not_taking_vote')}
            >
              Cancel
            </OutlineButton>
          </VoteControlsRow>
        </>
      )}
    </VoteSectionLayout>
  );
};

export default FinalizeVoteControls;
