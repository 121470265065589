import { Editor, SectionLink } from '../TextNode.js';
import h from 'hyperscript';
import { AppContext } from '../textNodesToHtmlString.js';

export const SECTION_LINK_ELEMENT_NODE_TYPE = 'section-link';

interface SectionLinkElement extends HTMLAnchorElement {
  dataset: {
    nodetype: 'section-link';
    sectionid: string;
  };
}

export const createSectionLinkElement = (
  node: Editor.SectionLink,
  context: AppContext,
  innerHTML: string,
) => {
  const sectionLink = context.linkables.find(
    ({ itemId }) => node.sectionId === itemId,
  );

  const dataItemMissing = sectionLink
    ? {}
    : {
        'data-itemMissing': true,
      };

  const internalLink = h('a', {
    target: '_blank',
    rel: 'noreferrer',
    'data-nodetype': 'section-link',
    'data-sectionid': node.sectionId,
    href: sectionLink?.url,
    ...dataItemMissing,
  });
  internalLink.innerHTML = innerHTML;
  return internalLink;
};

export const getSectionLinkFromElement = (
  element: HTMLElement,
  content: Editor.Text[],
) => {
  if (isSectionLinkElement(element)) {
    return SectionLink(element.dataset.sectionid, content);
  }

  return null;
};

export const isSectionLinkElement = (
  el: HTMLElement,
): el is SectionLinkElement =>
  el.dataset.nodetype === SECTION_LINK_ELEMENT_NODE_TYPE;

export default SectionLinkElement;
