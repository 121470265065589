import { arrayMove as arrayMoveIndex } from '@dnd-kit/sortable';
import { DragEndEvent, DragOverEvent } from '@dnd-kit/core';
import { useEffect, useState, useRef } from 'react';
import isEqual from 'lodash/isEqual.js';

export default function useReorderSections(
  sectionOrder: SectionOrder,
  onReorder: (sectionOrder: SectionOrder) => void,
) {
  const [draggingSectionOrder, setDraggingSectionOrder] =
    useState(sectionOrder);

  const sectionOrderRef = useRef(sectionOrder);
  useEffect(() => {
    if (!isEqual(sectionOrderRef.current, sectionOrder)) {
      sectionOrderRef.current = sectionOrder;
      setDraggingSectionOrder(sectionOrder);
    }
  }, [sectionOrder]);

  return {
    onDragStart() {
      setDraggingSectionOrder(sectionOrder);
    },
    onDragOver(event: Pick<DragOverEvent, 'active' | 'over'>) {
      const over = event.over;
      const active = event.active;
      if (!over) return;
      const activeId = active.id as string;
      const overId = over.id as string;

      setDraggingSectionOrder((sectionOrder) =>
        moveBetweenContainers(sectionOrder, activeId, overId),
      );
    },
    onDragEnd(event: Pick<DragEndEvent, 'active' | 'over'>) {
      const over = event.over;
      const active = event.active;
      if (!over) return;
      const activeId = active.id as string;
      const overId = over.id as string;

      const newSectionOrder = moveWithinContainers(
        draggingSectionOrder,
        activeId,
        overId,
      );

      onReorder(newSectionOrder);
      setDraggingSectionOrder(newSectionOrder);
    },
    moveToContainer(id: string, containerId: 'appendix' | 'table-of-contents') {
      const newOrder = moveBetweenContainers(
        draggingSectionOrder,
        id,
        containerId,
      );
      onReorder(newOrder);
      setDraggingSectionOrder(newOrder);
    },
    sectionOrder: draggingSectionOrder,
  };
}

export const TABLE_OF_CONTENTS_CONTAINER_ID = 'table-of-contents';
export const APPENDIX_CONTAINER_ID = 'appendix';
export type SectionOrder = { appendix: string[]; tableOfContents: string[] };

// HERE BE UGLY CODE
// exhaustive checking of source and destination

function moveBetweenContainers(
  sectionOrder: SectionOrder,
  activeId: string,
  overId: string,
) {
  const fromContainer = sectionOrder.tableOfContents.includes(activeId)
    ? TABLE_OF_CONTENTS_CONTAINER_ID
    : APPENDIX_CONTAINER_ID;

  switch (fromContainer) {
    case TABLE_OF_CONTENTS_CONTAINER_ID: {
      return moveFromTableOfContents(sectionOrder, activeId, overId);
    }

    case APPENDIX_CONTAINER_ID: {
      return moveFromAppendix(sectionOrder, activeId, overId);
    }
  }
}

function moveFromTableOfContents(
  sectionOrder: SectionOrder,
  activeId: string,
  overId: string,
) {
  // Dropping on container
  switch (overId) {
    case TABLE_OF_CONTENTS_CONTAINER_ID:
      // same container, let dnd kit handle it
      return sectionOrder;
    case APPENDIX_CONTAINER_ID:
      return {
        tableOfContents: arrayRemove(sectionOrder.tableOfContents, activeId),
        appendix: [activeId, ...sectionOrder.appendix],
      };
  }

  // Dropping on item
  const toContainer = sectionOrder.tableOfContents.includes(overId)
    ? TABLE_OF_CONTENTS_CONTAINER_ID
    : APPENDIX_CONTAINER_ID;

  switch (toContainer) {
    case TABLE_OF_CONTENTS_CONTAINER_ID:
      // same container, let dnd kit handle it
      return sectionOrder;
    case APPENDIX_CONTAINER_ID:
      return {
        tableOfContents: arrayRemove(sectionOrder.tableOfContents, activeId),
        appendix: [activeId, ...sectionOrder.appendix],
      };
  }
}

function moveFromAppendix(
  sectionOrder: SectionOrder,
  activeId: string,
  overId: string,
) {
  // Dropping on container

  switch (overId) {
    case TABLE_OF_CONTENTS_CONTAINER_ID:
      return {
        tableOfContents: [...sectionOrder.tableOfContents, activeId],
        appendix: arrayRemove(sectionOrder.appendix, activeId),
      };
    case APPENDIX_CONTAINER_ID:
      // same container, let dnd kit handle it
      return sectionOrder;
  }

  // Dropping on item
  const toContainer = sectionOrder.tableOfContents.includes(overId)
    ? TABLE_OF_CONTENTS_CONTAINER_ID
    : APPENDIX_CONTAINER_ID;

  switch (toContainer) {
    case TABLE_OF_CONTENTS_CONTAINER_ID:
      return {
        tableOfContents: [...sectionOrder.tableOfContents, activeId],
        appendix: arrayRemove(sectionOrder.appendix, activeId),
      };
    case APPENDIX_CONTAINER_ID:
      // same container, let dnd kit handle it
      return sectionOrder;
  }
}

// exhaustive checking of source and destination
function moveWithinContainers(
  sectionOrder: SectionOrder,
  activeId: string,
  overId: string,
) {
  const fromContainer = sectionOrder.tableOfContents.includes(activeId)
    ? TABLE_OF_CONTENTS_CONTAINER_ID
    : APPENDIX_CONTAINER_ID;

  switch (fromContainer) {
    case TABLE_OF_CONTENTS_CONTAINER_ID:
      return moveWithinTableOfContents(sectionOrder, activeId, overId);

    case APPENDIX_CONTAINER_ID: {
      return moveWithinAppendix(sectionOrder, activeId, overId);
    }
  }
}

function moveWithinTableOfContents(
  sectionOrder: SectionOrder,
  activeId: string,
  overId: string,
) {
  // moving containers is not handled here
  switch (overId) {
    case TABLE_OF_CONTENTS_CONTAINER_ID:
      return sectionOrder;
    case APPENDIX_CONTAINER_ID:
      return sectionOrder;
  }

  const toContainer = sectionOrder.tableOfContents.includes(overId)
    ? TABLE_OF_CONTENTS_CONTAINER_ID
    : APPENDIX_CONTAINER_ID;

  switch (toContainer) {
    case TABLE_OF_CONTENTS_CONTAINER_ID:
      return {
        tableOfContents: arrayMove(
          sectionOrder.tableOfContents,
          activeId,
          overId,
        ),
        appendix: sectionOrder.appendix,
      };
    case APPENDIX_CONTAINER_ID:
      return sectionOrder;
  }
}

function moveWithinAppendix(
  sectionOrder: SectionOrder,
  activeId: string,
  overId: string,
) {
  switch (overId) {
    case TABLE_OF_CONTENTS_CONTAINER_ID:
      return sectionOrder;
    case APPENDIX_CONTAINER_ID:
      return sectionOrder;
  }

  const toContainer = sectionOrder.tableOfContents.includes(overId)
    ? TABLE_OF_CONTENTS_CONTAINER_ID
    : APPENDIX_CONTAINER_ID;

  switch (toContainer) {
    case TABLE_OF_CONTENTS_CONTAINER_ID:
      return sectionOrder;
    case APPENDIX_CONTAINER_ID:
      return {
        tableOfContents: sectionOrder.tableOfContents,
        appendix: arrayMove(sectionOrder.appendix, activeId, overId),
      };
  }
}

// Array helpers

function arrayMove<T>(array: T[], activeItem: T, overItem: T): T[] {
  const fromIndex = array.findIndex((item) => item === activeItem);
  const toIndex = array.findIndex((item) => item === overItem);

  return arrayMoveIndex(array, fromIndex, toIndex);
}

function arrayRemove<T>(array: T[], item: T): T[] {
  return array.filter((currentItem) => {
    return currentItem !== item;
  });
}
