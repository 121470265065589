import styles from './spacing.module.scss';

export const getSpacing = (spacing?: Spacing, isMobile?: boolean): string => {
  switch (spacing) {
    case 'l':
      return isMobile
        ? (styles.spaceLarge_mobile as string)
        : (styles.spaceLarge as string);
    case 'm':
      return isMobile
        ? (styles.spaceMedium_mobile as string)
        : (styles.spaceMedium as string);
    case 's':
      return isMobile
        ? (styles.spaceSmall_mobile as string)
        : (styles.spaceSmall as string);
    default:
      return '';
  }
};
export type Spacing = 'l' | 'm' | 's';

const addSpacing = <Item extends { data: { block: { type: string } } }>(
  renderNodes: Array<Item>,
): Array<Item & { spacing?: Spacing }> => {
  return renderNodes.map((renderNode, i) => {
    const prevNode = renderNodes[i - 1];
    const nextNode = renderNodes[i + 1];
    if (i === 0) {
      return renderNode;
    } else if (prevNode?.data?.block?.type === 'heading') {
      return { ...renderNode, spacing: 's' };
    } else if (prevNode?.data?.block?.type === 'label') {
      return { ...renderNode, spacing: 's' };
    } else if (renderNode.data?.block.type === 'heading') {
      return { ...renderNode, spacing: 'l' };
    } else if (renderNode.data.block.type === 'label') {
      if (nextNode?.data?.block?.type === 'heading') {
        return { ...renderNode, spacing: 'l' };
      }
      return { ...renderNode, spacing: 'm' };
    } else {
      return { ...renderNode, spacing: 'm' };
    }
  });
};

export default addSpacing;
