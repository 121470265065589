import React from 'react';
import styles from './ZeckCover.module.scss';
import Button from '../atoms/Button.tsx';
import useBreakpoints from '../../services/useBreakpoints.ts';
import cx from 'classnames';
import { PublishedZeck, Zeck } from '../../types.ts';
import UploadedImage from '../../pages/zeck/UploadedImage.tsx';
import defaultImagePath from '../../images/defaultZeckCoverBackground.jpg';

type ZeckCoverProps = {
  companyName: string;
  zeck: Zeck | PublishedZeck;
  navigation?: () => void;
  className?: string;
};

const ZeckCover = React.forwardRef<HTMLDivElement, ZeckCoverProps>(
  (props, ref) => {
    const { isMobile } = useBreakpoints();

    return (
      <div
        ref={ref}
        data-testid="zeck-cover"
        className={cx(
          styles.zeckCover,
          isMobile && styles.zeckCover_mobile,
          props.className,
        )}
      >
        {props.zeck.coverImageId ? (
          <UploadedImage
            imageId={props.zeck.coverImageId}
            spinnerSize="large"
            className={styles.coverImage}
            alt="zeck cover"
          />
        ) : (
          <img
            src={defaultImagePath}
            className={styles.coverImage}
            alt="zeck cover"
          />
        )}
        <div className={styles.companyName}>{props.companyName}</div>
        <div
          className={cx(styles.zeckName, isMobile && styles.zeckName_mobile)}
        >
          {props.zeck.name}
        </div>
        {props.navigation && (
          <Button
            color="primary"
            size="large"
            className={cx(
              styles.sectionLinkButton,
              isMobile && styles.sectionLinkButton_mobile,
            )}
            onClick={props.navigation}
          >
            Get Started
          </Button>
        )}
      </div>
    );
  },
);

export default ZeckCover;
