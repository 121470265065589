import { useState } from 'react';
import Button from '../../../../design-system/atoms/Button.tsx';
import Modal from '../../../../design-system/organisms/Modal.tsx';
import styles from './EmbedVideoModal.module.scss';
import parseVideoUrl from './parseVideoUrl.ts';
import keyDownHandlerLetsInputsWork from '../editableBlocks/keyDownHandlerLetsInputsWork.ts';
import {
  callHandlers,
  handleKey,
} from '../../../../editor/domFacing/events/isKeyMatch.ts';

export type EmbedVideoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onEmbedVideo: (video: { url: string }) => void;
};

export default function EmbedVideoModal({
  isOpen,
  onClose,
  onEmbedVideo,
}: EmbedVideoModalProps) {
  const [url, setUrl] = useState('');
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      dark
      onRequestClose={onClose}
      className={styles.modal}
    >
      <div className={styles.header}>
        <div className={styles.headerItem}>Embed Video</div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (parseVideoUrl(url)) {
            onEmbedVideo({ url });
            setUrl('');
          } else {
            setIsInvalidUrl(true);
          }
        }}
        onKeyDown={callHandlers<React.KeyboardEvent>([
          handleKey({ key: 'Enter' }, (e) => {
            e.stopPropagation();
          }),
          keyDownHandlerLetsInputsWork,
        ])}
        data-testid="embed-video-modal"
      >
        <p className={styles.description}>
          Embed a video from YouTube or Vimeo
        </p>

        <input
          name="videoUrl"
          aria-label="Video URL"
          placeholder="Paste video link..."
          value={url}
          onChange={(e) => {
            setIsInvalidUrl(false);
            setUrl(e.target.value);
          }}
          onPaste={(e) => {
            e.stopPropagation();
          }}
          className={styles.input}
        />

        {isInvalidUrl && (
          <span className={styles.error}>
            That doesn’t appear to be a valid video URL. Please enter a valid
            YouTube or Vimeo video URL.
          </span>
        )}

        <Button
          color="primary"
          size="medium"
          type="submit"
          disabled={url.length === 0}
          className={styles.submit}
        >
          Embed Video
        </Button>
      </form>
    </Modal>
  );
}
