import { EditorStateGeneric } from '../../../../editor/EditorStateGeneric.js';
import {
  BlockSelection,
  getStartOfBlockSelection,
  isBlockSelection,
} from '../../../../editor/selection/BlockSelection.js';
import {
  textSelection,
  TextSelection,
} from '../../../../editor/selection/TextSelection.js';
import ContentSelection, {
  contentSelection,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import { BlockContent } from 'editor-content/Block.js';
import removeItem from '../../../../junkDrawer/sortable/removeItem.js';
import replaceBlocksAt from '../../../../editor/blocks/replaceBlocksAt.js';
import splitContentByBlockSelection from '../../../../editor/selection/splitContentByBlockSelection.js';

type PressDeleteResult<BlockType> = EditorStateGeneric<BlockType> | void;

export type PressDeleteBlockStrategies<BlockType> = {
  pressDelete: (
    selection: ContentSelection,
  ) => { type: 'merge' } | { type: 'remove' } | { type: 'browser-handled' };
  askToMerge: () =>
    | { type: 'accept-merge'; content: BlockContent }
    | { type: 'accept-selection' };
  receiveMerge: (content: BlockContent) => BlockType;
  receiveSelectionFromLeft: () => ContentSelection;
};

// model: take content from second block and move into first
const pressDelete = <BlockType>(
  generateBlockEditor: (b: BlockType) => PressDeleteBlockStrategies<BlockType>,
  createDefaultBlock: (content: BlockContent) => BlockType,
) => {
  const pressDeleteTextSelection = (
    content: BlockType[],
    selection: TextSelection,
  ) => {
    const selectedBlock = content[selection.index];

    if (!selectedBlock) return;
    const selectedBlockEditor = generateBlockEditor(selectedBlock);

    const deleteResult = selectedBlockEditor.pressDelete(selection.offset);

    const nextBlock = content[selection.index + 1];

    if (!nextBlock) {
      switch (deleteResult.type) {
        case 'merge':
          // can't merge to the right
          return { content, selection };
        case 'remove':
          // just remove this block
          return {
            content: replaceBlocksAt(
              content,
              [createDefaultBlock([])],
              selection.index,
              1,
            ),
            selection: textSelection(selection.index, contentSelection(0)),
          };

        case 'browser-handled':
          return;
      }
    }

    const nextBlockEditor = generateBlockEditor(nextBlock);

    switch (deleteResult.type) {
      case 'merge': {
        const nextBlockResult = nextBlockEditor.askToMerge();

        switch (nextBlockResult.type) {
          case 'accept-selection': {
            const newSelection = nextBlockEditor.receiveSelectionFromLeft();

            return {
              content,
              selection: textSelection(selection.index + 1, newSelection),
            };
          }
          case 'accept-merge': {
            const newBlock = selectedBlockEditor.receiveMerge(
              nextBlockResult.content,
            );

            return {
              content: replaceBlocksAt(content, [newBlock], selection.index, 2),
              selection,
            };
          }
        }

        return;
      }
      case 'remove': {
        const newSelection = nextBlockEditor.receiveSelectionFromLeft();

        return {
          content: removeItem(content, selection.index),
          selection: textSelection(selection.index, newSelection),
        };
      }
      case 'browser-handled':
        return;
    }
  };

  const pressDeleteBlockSelection = (
    content: BlockType[],
    selection: BlockSelection,
  ) => {
    const [blocksBefore, , blocksAfter] = splitContentByBlockSelection(
      content,
      selection,
    );

    const newContent = [
      ...blocksBefore,
      createDefaultBlock([]),
      ...blocksAfter,
    ];

    return {
      content: newContent,
      selection: textSelection(
        getStartOfBlockSelection(selection),
        contentSelection(0),
      ),
    };
  };

  return ({
    content,
    selection,
  }: EditorStateGeneric<BlockType>): PressDeleteResult<BlockType> => {
    if (!selection) return;

    if (isBlockSelection(selection)) {
      return pressDeleteBlockSelection(content, selection);
    }

    return pressDeleteTextSelection(content, selection);
  };
};

export default pressDelete;
