import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../../../design-system/organisms/Modal.tsx';
import styles from './MeetingMinutesModal.module.scss';

const MeetingMinutesModal = ({
  onClose,
  actionsSlot,
  title,
  message,
  isOpen,
}: {
  onClose: () => void;
  title: React.ReactNode;
  message: React.ReactNode;
  actionsSlot: React.ReactNode;
  isOpen: boolean;
}) => {
  return (
    <Modal isOpen={isOpen} className={styles.meetingMinutesModal}>
      <ModalHeader onClickClose={onClose}>{title}</ModalHeader>

      <ModalBody>{message}</ModalBody>

      <ModalActions>{actionsSlot}</ModalActions>
    </Modal>
  );
};

export default MeetingMinutesModal;
