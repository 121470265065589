import styles from './AdminPanel.module.scss';
import cx from 'classnames';
import Button from '../../../design-system/atoms/Button.tsx';

export const AdminPanelFormContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <div className={styles.adminPanelFormContainer}>{children}</div>;
};

type AdminPanelSubmitProps = {
  children: React.ReactNode;
} & Partial<React.ComponentProps<typeof Button>>;

export const AdminPanelSubmit: React.FC<AdminPanelSubmitProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <Button
      size="large"
      color="primary"
      {...otherProps}
      className={styles.adminPanelSubmit}
    >
      {children}
    </Button>
  );
};

type AdminPanelFieldProps = {
  description?: React.ReactNode;
  children: React.ReactNode;
};
export const AdminPanelField: React.FC<AdminPanelFieldProps> = ({
  description,
  children,
}) => {
  return (
    <div className={styles.adminPanelField}>
      {description && (
        <p className={styles.adminPanelField__description}>{description}</p>
      )}
      {children}
    </div>
  );
};

type AdminPanelProps = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  innerWrapClassname?: string;
};

const AdminPanel: React.FC<AdminPanelProps> = ({
  title,
  subtitle,
  children,
  innerWrapClassname,
}) => {
  return (
    <article className={cx(styles.wrapper)}>
      <h3 className={styles.title}>{title}</h3>
      <h5 className={styles.subtitle}>{subtitle}</h5>
      <div className={styles.childWrap}>
        <div className={cx(styles.innerWrap, innerWrapClassname)}>
          {children}
        </div>
      </div>
    </article>
  );
};

export default AdminPanel;
