import { useCallback, useEffect, useRef, useState } from 'react';

export type PaginationOptions = { cursor: string | null };

type PaginatedQueryState =
  | 'loading'
  | 'noResults'
  | 'fetchingNextPage'
  | 'loaded';

export const usePaginatedFetch = <ModelType>(
  fn: (cursor: string | null) => Promise<{
    models: ModelType[];
    pagination: PaginationOptions;
  }>,
  deps: Array<unknown>,
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [queryState, setQueryState] = useState<PaginatedQueryState>('loading');
  const [cursor, setCursor] = useState<string | null>(null);
  const [nextPageMayExist, setNextPageMayExist] = useState<boolean>(true);
  const [models, setModels] = useState<ModelType[]>([]);

  const loadNextPage = useCallback(() => {
    setLoading(true);
    if (models.length) {
      setQueryState('fetchingNextPage');
    }
    fn(cursor).then((response) => {
      setCursor(response.pagination.cursor);
      setModels((prevModels) => [...prevModels, ...response.models]);
      setLoading(false);
      if (!response.pagination.cursor) {
        setNextPageMayExist(false);
      }
      if (models.length === 0 && response.models.length === 0) {
        setQueryState('noResults');
        return;
      }

      setQueryState('loaded');
    });
  }, [cursor, fn, models.length]);

  useEffect(loadNextPage, deps); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    loadNextPage,
    models,
    queryState,
    nextPageMayExist,
  };
};

const usePaginatedFetchOnScroll = <ModelType>(
  fn: (cursor?: string | null) => Promise<{
    models: ModelType[];
    pagination: PaginationOptions;
  }>,
  deps: Array<unknown>,
) => {
  const { models, queryState, loading, loadNextPage, nextPageMayExist } =
    usePaginatedFetch(fn, deps);
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting && nextPageMayExist && !loading) {
          loadNextPage();
        }
      },
      { threshold: 1 },
    );

    // @ts-expect-error - IntersectionObserver types can be wrong in test
    if (ref.current && observer.unobserve) {
      observer.observe(ref.current);
    }

    return () => {
      if (currentRef && observer.unobserve) {
        observer.unobserve(currentRef);
      }
    };
  }, [loadNextPage, ref, nextPageMayExist, loading]);

  return {
    models,
    queryState,
    ref,
  };
};

export default usePaginatedFetchOnScroll;
