import ContentSelection, {
  contentSelectionToRange,
  isCollapsed,
} from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import HoverNextToPoint from '../../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import { RefCallback, useCallback, useRef } from 'react';
import { useGetDomRangeFromSelection } from '../../../../editor/selection/contentSelection/selectionHelpers.ts';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import { Atom, useStore } from 'jotai';
import Point from '../../../../domHelpers/Point.js';

export const WithHoverNextToSelection: React.FC<{
  children: (ref: RefCallback<HTMLElement>) => React.ReactNode;
  hoverContent: React.ReactNode;
  selection: ContentSelection | null;
}> = ({ children, selection, hoverContent }) => {
  const { ref, getDomRange } = useGetDomRangeFromSelection(selection);

  const showHover = selection && !isCollapsed(selection);
  return (
    <>
      {showHover && (
        <HoverNextToSelection getDomRange={getDomRange}>
          {hoverContent}
        </HoverNextToSelection>
      )}
      {children(mergeRefs([ref]))}
    </>
  );
};

export const WithHoverNextToSelectionAtom: React.FC<{
  usePortal?: boolean;
  children: (ref: RefCallback<HTMLElement>) => React.ReactNode;
  hoverContent: React.ReactNode;
  selectionAtom: Atom<ContentSelection | null>;
}> = ({ children, selectionAtom, usePortal, hoverContent }) => {
  const ref = useRef<HTMLElement>(null);

  const store = useStore();

  const getDomRange = useCallback(() => {
    const selection = store.get(selectionAtom);
    const containerElement = ref.current;

    if (!(containerElement && selection)) {
      return null;
    }

    return contentSelectionToRange(containerElement, selection);
  }, [selectionAtom, store]);

  return (
    <>
      <MaybeHoverNextToSelection
        usePortal={usePortal}
        getDomRange={getDomRange}
      >
        {hoverContent}
      </MaybeHoverNextToSelection>
      {children(mergeRefs([ref]))}
    </>
  );
};

const MaybeHoverNextToSelection: React.FC<{
  children: React.ReactNode;
  getDomRange(): Range | null;
  usePortal?: boolean;
}> = ({ getDomRange, children, usePortal }) => {
  const getPoint = (container: HTMLElement): Point | null => {
    const selection = getDomRange();
    if (!selection) {
      return null;
    }

    const rect = selection.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    return [
      (rect.left + rect.right) / 2 - containerRect.width / 2,
      rect.bottom + 16,
    ];
  };
  if (!(usePortal ?? true)) {
    return children;
  }
  return (
    <HoverNextToPoint
      usePortal={usePortal ?? true}
      viewportPolicy="position-x"
      containerStyles={{ zIndex: 'initial' }}
      getPoint={getPoint}
    >
      {children}
    </HoverNextToPoint>
  );
};

const HoverNextToSelection: React.FC<{
  children: React.ReactNode;
  getDomRange(): Range | null;
}> = ({ getDomRange, children }) => {
  const getPoint = (container: HTMLElement): Point | null => {
    const selection = getDomRange();
    if (!selection) {
      return null;
    }

    const rect = selection.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    return [
      (rect.left + rect.right) / 2 - containerRect.width / 2,
      rect.bottom + 16,
    ];
  };

  return (
    <HoverNextToPoint
      usePortal
      viewportPolicy="position-x"
      containerStyles={{ zIndex: 'initial' }}
      getPoint={getPoint}
    >
      {children}
    </HoverNextToPoint>
  );
};
