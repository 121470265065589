import ContentSelection from '../selection/contentSelection/ContentSelection.js';
import { ContentPatch } from '../ContentPatch.js';
import replaceSelectionWith from '../../pages/zeck/editor/BodyEditor/replaceSelectionWith.js';
import { EditorConfiguration, EditorState } from '../EditorAction.js';

type PressShiftEnterBlockEditorInterface<BlockType> = {
  pressShiftEnter: (
    selection: ContentSelection,
  ) => ContentPatch<BlockType[]> | void;
};

const pressShiftEnter =
  <BlockType>({
    generateBlockEditor,
  }: EditorConfiguration<
    BlockType,
    PressShiftEnterBlockEditorInterface<BlockType>
  >) =>
  (initialState: EditorState<BlockType>) =>
    replaceSelectionWith(initialState, {
      textSelection: (selectedBlock, selection) => {
        const targetBlockEditor = generateBlockEditor(selectedBlock);

        return targetBlockEditor.pressShiftEnter(selection);
      },
      blockSelection: () => {
        return;
      },
    });

export default pressShiftEnter;
