import { EditorStateGeneric } from '../EditorStateGeneric.js';
import { blockSelection } from '../selection/BlockSelection.js';

export const dropDraggedBlock = <BlockType>(
  initialState: EditorStateGeneric<BlockType>,
  startIndex: number,
  endIndex: number,
) => {
  const newContent = [...initialState.content];
  const [draggedBlock] = newContent.splice(startIndex, 1);

  if (!draggedBlock) return initialState;

  const newEndIndex = startIndex < endIndex ? endIndex - 1 : endIndex;

  newContent.splice(newEndIndex, 0, draggedBlock);

  return {
    content: newContent,
    selection: blockSelection(newEndIndex, newEndIndex),
  };
};
