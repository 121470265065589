import Button from '../../design-system/atoms/Button.tsx';
import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../design-system/organisms/Modal.tsx';
import styles from './TouchDeviceWarningModal.module.scss';

type TouchDeviceWarningModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function TouchDeviceWarningModal({
  isOpen,
  onClose,
}: TouchDeviceWarningModalProps) {
  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader onClickClose={onClose}>You're on a Touch Device</ModalHeader>
      <ModalBody>
        Just a heads up… some editing features don’t function as well with touch
        interaction compared to a mouse and keyboard.
      </ModalBody>
      <ModalActions>
        <Button onClick={onClose} color="danger" size="medium">
          Got it
        </Button>
      </ModalActions>
    </Modal>
  );
}
