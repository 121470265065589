import { CredentialListItem } from '../../../../../api/endpoints/createCredentialApi.ts';
import {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../../../../design-system/organisms/Modal.tsx';
import Button from '../../../../../design-system/atoms/Button.tsx';
import styles from './CredentialsList.module.scss';
import { ReactComponent as GoogleDriveIcon } from '../../../../../images/GoogleDriveIcon.svg';
import OutlineButtonWithLoading from '../../../../../design-system/molecules/OutlineButtonWithLoading.tsx';
import { OneDriveIcon } from 'icons';

type CredentialListItemProps = {
  displayName: string;
  onClickSelect: () => void;
  type: 'microsoft' | 'google';
};
const CredentialsListItem: React.FC<CredentialListItemProps> = ({
  displayName,
  onClickSelect,
  type,
}) => {
  return (
    <div className={styles.credentialsListItem}>
      {type === 'microsoft' ? (
        <OneDriveIcon className={styles.credentialsListItem_icon} />
      ) : (
        <GoogleDriveIcon className={styles.credentialsListItem_icon} />
      )}
      <span className={styles.credentialsListItem_name}>{displayName}</span>
      <Button
        size="medium"
        color="primary"
        onClick={onClickSelect}
        className={styles.credentialsListItem_button}
      >
        Select a File
      </Button>
    </div>
  );
};

type CredentialsListProps = {
  credentials: CredentialListItem[];
  onPickCredential: (credentialId: string) => void;
  onClickAddAccount: () => void;
  isAddingCredential: boolean;
  type: 'microsoft' | 'google';
};
const CredentialsList: React.FC<CredentialsListProps> = ({
  credentials,
  onPickCredential,
  onClickAddAccount,
  isAddingCredential,
  type,
}) => {
  return (
    <div className={styles.credentialsListContainer}>
      <ModalHeader>Select an Account</ModalHeader>
      <ModalBody className={styles.credentialsList_body}>
        <ul className={styles.credentialsList_list}>
          {credentials.map(({ id, displayName }) => (
            <li key={id}>
              <CredentialsListItem
                displayName={displayName}
                onClickSelect={() => onPickCredential(id)}
                type={type}
              />
            </li>
          ))}
        </ul>
      </ModalBody>
      <ModalActions className={styles.credentialsList_footer}>
        <OutlineButtonWithLoading
          size="medium"
          color="primary"
          onClick={onClickAddAccount}
          isLoading={isAddingCredential}
        >
          Connect Another Account
        </OutlineButtonWithLoading>
      </ModalActions>
    </div>
  );
};

export default CredentialsList;
