import React from 'react';
import styles from './UserProfilePage.module.scss';

const ProfileLabel: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <div className={styles.userProfile__label}>{children}</div>
);

export default ProfileLabel;
