import styles from './Tooltip.module.scss';
import BlackBox from '../atoms/BlackBox.tsx';
import cx from 'classnames';

export default function Tooltip({
  className,
  ...props
}: React.ComponentProps<typeof BlackBox>) {
  return <BlackBox {...props} className={cx(className, styles.tooltip)} />;
}
