export default function formatVotedAt(votedAt: number) {
  return Intl.DateTimeFormat(navigator.language, {
    timeZoneName: 'short',
    hour: 'numeric',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    minute: '2-digit',
  }).format(votedAt);
}
