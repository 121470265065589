import { SidebarLink } from '../../../design-system/molecules/SidebarItem.tsx';
import { Icon } from 'icons';
import { useHomeFolderDroppable } from '../FolderDragAndDrop.tsx';

const HomeSidebarItemDroppable: React.FC<{
  companyId: string;
  collapse?: boolean;
  active?: boolean;
  onClick?: () => void;
}> = ({ companyId, collapse, active, onClick }) => {
  const { isOver, setNodeRef } = useHomeFolderDroppable();

  return (
    <SidebarLink
      {...{
        ref: setNodeRef,
        hover: isOver,
        href: `/company/${companyId}`,
        icon: <Icon name="home" />,
        label: 'Home',
        iconOnly: collapse,
        active,
        isEditing: false,
        onClick,
      }}
    />
  );
};

export default HomeSidebarItemDroppable;
