import { Text } from 'editor-content/TextNode.js';
import ContentSelection from '../../selection/contentSelection/ContentSelection.js';
import { EditorStateGeneric } from '../../EditorStateGeneric.js';
import BaseBlock from 'editor-content/BaseBlock.js';
import replaceSelectionWith from '../../../pages/zeck/editor/BodyEditor/replaceSelectionWith.js';
import { textSelection } from '../../selection/TextSelection.js';
import { ContentPatch } from '../../ContentPatch.js';
import { EditorConfiguration } from '../../EditorAction.js';

type PastePlaintextBlockInterface<BlockType extends BaseBlock> = {
  getEndOfBlockSelection: () => ContentSelection;
  pastePlaintext: (
    plaintext: string,
    selection: ContentSelection,
  ) => void | ContentPatch<BlockType[]>;
};

const pastePlaintext =
  <BlockType extends BaseBlock>({
    generateBlockEditor,
    createDefaultBlock,
  }: EditorConfiguration<BlockType, PastePlaintextBlockInterface<BlockType>>) =>
  (initialState: EditorStateGeneric<BlockType>, plainText: string) => {
    return replaceSelectionWith(initialState, {
      textSelection(selectedBlock, selection) {
        const selectedBlockEditor = generateBlockEditor(selectedBlock);

        const insertResult = selectedBlockEditor.pastePlaintext(
          plainText,
          selection,
        );
        if (!insertResult) {
          const newBlock = createDefaultBlock([Text(plainText)]);
          const newBlockSelection =
            generateBlockEditor(newBlock).getEndOfBlockSelection();

          return {
            contentSubset: [newBlock],
            selection: textSelection(0, newBlockSelection),
          };
        }

        return insertResult;
      },
      blockSelection() {
        const lines = plainText.split('\n');

        const newBlocks = lines.map((s) => createDefaultBlock([Text(s)]));

        const lastBlock = newBlocks[newBlocks.length - 1];

        if (!lastBlock) return;

        const lastBlockSelection =
          generateBlockEditor(lastBlock).getEndOfBlockSelection();

        return {
          contentSubset: newBlocks,
          selection: textSelection(newBlocks.length - 1, lastBlockSelection),
        };
      },
    });
  };

export default pastePlaintext;
