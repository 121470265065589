import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import useApi from '../../api/useApi.js';
import InputError from '../../design-system/atoms/InputError.tsx';
import OutlineButton from '../../design-system/atoms/OutlineButton.tsx';
import ButtonWithLoading from '../../design-system/molecules/ButtonWithLoading.tsx';
import isRequestErrorNotPanic from '../../junkDrawer/isRequestErrorNotPanic.ts';
import { User } from '../../types.ts';
import ProfileLabel from './ProfileLabel.js';
import profileFormStyles from './UserProfileForm.module.scss';

const UserNameChangeForm: React.FC<{
  firstName: string;
  lastName: string;
  onClose: () => void;
  onChangeName: (name: Pick<User, 'firstName' | 'lastName'>) => void;
}> = ({ firstName, lastName, onClose, onChangeName }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [newFirstName, setFirstName] = useState<string>(firstName);
  const [newLastName, setLastName] = useState<string>(lastName);
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const { updateUser } = useApi();

  useEffect(() => {
    if (!newFirstName.trim().length) {
      setFirstNameError('A First Name is required.');
    } else {
      setFirstNameError(null);
    }

    if (!newLastName.trim().length) {
      setLastNameError('A Last Name is required.');
    } else {
      setLastNameError(null);
    }
  }, [newFirstName, newLastName]);

  const changeName = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await updateUser(newFirstName, newLastName);
      onChangeName({
        firstName: newFirstName.trim(),
        lastName: newLastName.trim(),
      });
      onClose();
    } catch (e) {
      if (isRequestErrorNotPanic(e) && e.userMessage) {
        setFirstNameError(e.userMessage);
      } else {
        throw e;
      }
      setIsSubmitting(false);
    }
  };

  const buttonDisabled: boolean =
    (newFirstName === firstName && newLastName === lastName) ||
    isSubmitting ||
    !!firstNameError ||
    !!lastNameError;

  return (
    <form onSubmit={changeName} className={profileFormStyles.profileForm}>
      <div className={profileFormStyles.profileForm__multiInputSection}>
        <div className={profileFormStyles.profileForm__inputSection}>
          {!firstNameError ? (
            <ProfileLabel>First Name</ProfileLabel>
          ) : (
            <InputError size="small" id="firstNameError">
              {firstNameError}
            </InputError>
          )}
          <input
            value={newFirstName}
            onChange={(e) => setFirstName(e.currentTarget.value)}
            aria-label="Change your first name"
            aria-describedby={firstNameError ? 'firstNameError' : undefined}
            disabled={isSubmitting}
            className={cx([
              profileFormStyles.profileForm__input,
              firstNameError && profileFormStyles.profileForm__input_error,
            ])}
            data-1p-ignore
          />
        </div>
        <div className={profileFormStyles.profileForm__inputSection}>
          {!lastNameError ? (
            <ProfileLabel>Last Name</ProfileLabel>
          ) : (
            <InputError size="small" id="lastNameError">
              {lastNameError}
            </InputError>
          )}
          <input
            value={newLastName}
            onChange={(e) => setLastName(e.currentTarget.value)}
            aria-label="Change your last name"
            aria-describedby={lastNameError ? 'lastNameError' : undefined}
            disabled={isSubmitting}
            className={cx([
              profileFormStyles.profileForm__input,
              lastNameError && profileFormStyles.profileForm__input_error,
            ])}
            data-1p-ignore
          />
        </div>
      </div>

      <div className={profileFormStyles.profileForm__actionSection}>
        <OutlineButton
          onClick={onClose}
          size="medium"
          color="secondary"
          type="button"
          className={profileFormStyles.profileForm__buttonCancel}
        >
          Cancel
        </OutlineButton>
        <ButtonWithLoading
          isLoading={isSubmitting}
          disabled={buttonDisabled}
          size="medium"
          color="primary"
          type="submit"
          className={profileFormStyles.profileForm__buttonSubmit}
        >
          Confirm Change
        </ButtonWithLoading>
      </div>
    </form>
  );
};

export default UserNameChangeForm;
