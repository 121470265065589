import type { ChartConfiguration } from 'chart.js';
import { ChartBlockData } from '../ChartBlock.js';

import { z } from 'zod';
import { assertUnreachable } from '../Block.js';

const chartJsTitleSchema = z.object({
  text: z.string(),
  position: z.union([
    z.literal('top'),
    z.literal('bottom'),
    z.literal('left'),
    z.literal('right'),
  ]),
});

const chartJsSubtitleSchema = z.object({
  text: z.string(),
});

const chartJsLegendSchema = z.object({
  display: z.boolean(),
  position: z.union([
    z.literal('top'),
    z.literal('bottom'),
    z.literal('left'),
    z.literal('right'),
  ]),
});

const chartJsPluginsSchema = z.object({
  title: chartJsTitleSchema,
  subtitle: chartJsSubtitleSchema,
  legend: chartJsLegendSchema,
});

export const chartJsLineSchema = z.object({
  type: z.literal('line'),
  options: z.object({
    plugins: chartJsPluginsSchema,
    scales: z.object({
      x: z.object({
        title: z.object({
          text: z.string(),
        }),
        position: z.union([z.literal('top'), z.literal('bottom')]),
        type: z.union([
          z.literal('linear'),
          z.literal('logarithmic'),
          z.literal('category'),
        ]),
        min: z.union([z.number(), z.null()]),
      }),
      y: z.object({
        title: z.object({
          text: z.string(),
        }),
        position: z.union([z.literal('left'), z.literal('right')]),
        type: z.union([
          z.literal('linear'),
          z.literal('logarithmic'),
          z.literal('category'),
        ]),
        min: z.union([z.number(), z.null()]),
      }),
    }),
  }),
  data: z.object({
    labels: z.array(z.string()),
    datasets: z.array(
      z.object({
        label: z.string(),
        data: z.array(z.number()),
        backgroundColor: z.union([z.string(), z.array(z.string())]),
        borderColor: z.union([z.string(), z.array(z.string())]),
        borderWidth: z.number(),
        order: z.number(),
      }),
    ),
  }),
});

export const chartJsBarSchema = z.object({
  type: z.literal('bar'),
  options: z.object({
    indexAxis: z.union([z.literal('x'), z.literal('y')]),
    plugins: chartJsPluginsSchema,

    scales: z.object({
      x: z.object({
        title: z.object({
          text: z.string(),
        }),
        position: z.union([z.literal('top'), z.literal('bottom')]),
        type: z.union([
          z.literal('linear'),
          z.literal('logarithmic'),
          z.literal('category'),
        ]),
        min: z.union([z.number(), z.null()]),
        stacked: z.boolean(),
      }),
      y: z.object({
        title: z.object({
          text: z.string(),
        }),
        position: z.union([z.literal('left'), z.literal('right')]),
        type: z.union([
          z.literal('linear'),
          z.literal('logarithmic'),
          z.literal('category'),
        ]),
        min: z.union([z.number(), z.null()]),
        stacked: z.boolean(),
      }),
    }),
  }),
  data: z.object({
    labels: z.array(z.string()),
    datasets: z.array(
      z.object({
        label: z.string(),
        data: z.array(z.number()),
        backgroundColor: z.union([z.string(), z.array(z.string())]),
        borderColor: z.union([z.string(), z.array(z.string())]),
        borderWidth: z.number(),
        order: z.number(),
      }),
    ),
  }),
});

export const chartJsPieSchema = z.object({
  type: z.literal('pie'),
  options: z.object({
    plugins: chartJsPluginsSchema,
    cutout: z.union([
      z.literal('0%'),
      z.literal('30%'),
      z.literal('50%'),
      z.literal('70%'),
    ]),
  }),
  data: z.object({
    labels: z.array(z.string()),
    datasets: z.array(
      z.object({
        label: z.string(),
        data: z.array(z.number()),
        backgroundColor: z.union([z.literal(''), z.array(z.string())]),
        borderWidth: z.number(),
        order: z.number(),
      }),
    ),
  }),
});

export const chartJsUnionSchema = z.union([
  chartJsLineSchema,
  chartJsBarSchema,
  chartJsPieSchema,
]);

export type ChartJsLineSchema = z.infer<typeof chartJsLineSchema>;
export type ChartJsBarSchema = z.infer<typeof chartJsBarSchema>;
export type ChartJsPieSchema = z.infer<typeof chartJsPieSchema>;

const isPieChartSchema = (
  chartJsSchema: ChartJsSchema,
): chartJsSchema is ChartJsPieSchema => {
  return chartJsSchema.type === 'pie';
};

const isBarChartSchema = (
  chartJsSchema: ChartJsSchema,
): chartJsSchema is ChartJsBarSchema => {
  return chartJsSchema.type === 'bar';
};

const isLineChartSchema = (
  chartJsSchema: ChartJsSchema,
): chartJsSchema is ChartJsLineSchema => {
  return chartJsSchema.type === 'line';
};

export type ChartJsSchema =
  | ChartJsLineSchema
  | ChartJsBarSchema
  | ChartJsPieSchema;

type CustomChartJSDefaults = {
  defaults: {
    font: {
      family: string;
    };
    color: string;
  };
};

type CustomChartJsConfiguration =
  | ChartConfiguration<'line'>
  | ChartConfiguration<'bar'>
  | (ChartConfiguration<'pie'> & {
      options: {
        cutout: string;
      };
    });

export type ChartJsConfigWithDefaults = CustomChartJsConfiguration &
  CustomChartJSDefaults;

export const makeChartJsConfig = (
  chartBlockData: ChartBlockData,
  { brandColorPrimary }: { brandColorPrimary: string | null },
): ChartJsConfigWithDefaults => {
  const sharedOptions = {
    options: {
      plugins: {
        title: {
          position: chartBlockData.title.position,
          text: chartBlockData.title.text,
          display: !!chartBlockData.title.text,
          align: 'start' as const,
          color: brandColorPrimary ?? '#1c1c28', // default to color-dark-1 like headings do if unset
          font: {
            size: 32,
            weight: 'bold' as const,
          },
        },
        subtitle: {
          text: chartBlockData.subtitle.text,
          display: !!chartBlockData.subtitle.text,
        },
        legend: {
          ...chartBlockData.legend,
        },
      },
      aspectRatio: 668 / 412,
      maintainAspectRatio: false,
    },
    data: {
      labels: chartBlockData.labels,
      datasets: chartBlockData.datasets,
    },
    defaults: {
      color: '#555770', // default to color-dark-3
      font: {
        family: 'Libre Franklin',
      },
    },
  };

  if (chartBlockData.type === 'pie') {
    return {
      ...sharedOptions,
      type: 'pie' as const,
      options: {
        ...sharedOptions.options,
        cutout: chartBlockData.cutout,
      },
    };
  }

  if (chartBlockData.type === 'line') {
    return {
      ...sharedOptions,
      type: 'line' as const,
      options: {
        ...sharedOptions.options,
        scales: {
          x: {
            ...chartBlockData.scales.x,
            title: {
              text: chartBlockData.scales.x.title.text,
              display: !!chartBlockData.scales.x.title.text,
            },
            min: chartBlockData.scales.x.min ?? undefined,
          },
          y: {
            ...chartBlockData.scales.y,
            title: {
              text: chartBlockData.scales.y.title.text,
              display: !!chartBlockData.scales.y.title.text,
            },
            min: chartBlockData.scales.y.min ?? undefined,
          },
        },
      },
    };
  }

  if (chartBlockData.type === 'bar') {
    return {
      ...sharedOptions,
      type: 'bar' as const,
      options: {
        ...sharedOptions.options,
        indexAxis: chartBlockData.indexAxis,
        scales: {
          x: {
            ...chartBlockData.scales.x,
            title: {
              text: chartBlockData.scales.x.title.text,
              display: !!chartBlockData.scales.x.title.text,
            },
            min: chartBlockData.scales.x.min ?? undefined,
            stacked: chartBlockData.scales.x.stacked,
          },
          y: {
            ...chartBlockData.scales.y,
            title: {
              text: chartBlockData.scales.y.title.text,
              display: !!chartBlockData.scales.y.title.text,
            },
            min: chartBlockData.scales.y.min ?? undefined,
            stacked: chartBlockData.scales.y.stacked,
          },
        },
      },
    };
  }

  assertUnreachable(chartBlockData);
};

export const makeChartBlockConfig = (
  chartJsConfig: ChartJsSchema,
): ChartBlockData => {
  const sharedChartBlock = {
    version: 1 as const,
    title: chartJsConfig.options.plugins.title,
    subtitle: chartJsConfig.options.plugins.subtitle,
    legend: chartJsConfig.options.plugins.legend,
    labels: chartJsConfig.data.labels,
  };

  if (isPieChartSchema(chartJsConfig)) {
    return {
      ...sharedChartBlock,
      type: 'pie' as const,
      cutout: chartJsConfig.options.cutout,
      datasets: chartJsConfig.data.datasets, // HACK: TS needs this...
    };
  }

  if (isLineChartSchema(chartJsConfig)) {
    return {
      ...sharedChartBlock,
      type: 'line' as const,
      scales: chartJsConfig.options.scales,
      datasets: chartJsConfig.data.datasets, // HACK: TS needs this...
    };
  }

  if (isBarChartSchema(chartJsConfig)) {
    return {
      ...sharedChartBlock,
      type: 'bar' as const,
      indexAxis: chartJsConfig.options.indexAxis,
      scales: chartJsConfig.options.scales,
      datasets: chartJsConfig.data.datasets, // HACK: TS needs this...
    };
  }

  assertUnreachable(chartJsConfig);
};
