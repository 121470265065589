import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function ExportIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M12 15.5556L15.6364 12H12.9091V4H11.0909V12H8.36364L12 15.5556ZM20.1818 4H14.7273V5.76889H20.1818V18.24H3.81818V5.76889H9.27273V4H3.81818C2.81818 4 2 4.8 2 5.77778V18.2222C2 19.2 2.81818 20 3.81818 20H20.1818C21.1818 20 22 19.2 22 18.2222V5.77778C22 4.8 21.1818 4 20.1818 4ZM12 15.5556L15.6364 12H12.9091V4H11.0909V12H8.36364L12 15.5556ZM20.1818 4H14.7273V5.76889H20.1818V18.24H3.81818V5.76889H9.27273V4H3.81818C2.81818 4 2 4.8 2 5.77778V18.2222C2 19.2 2.81818 20 3.81818 20H20.1818C21.1818 20 22 19.2 22 18.2222V5.77778C22 4.8 21.1818 4 20.1818 4Z"
        fill="black"
      />
    </svg>
  );
}
