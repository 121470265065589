import { DocumentMeta } from '../../integrationModalTypes.ts';

const getDocumentMetaFromSheetResponse = (
  result: gapi.client.sheets.Spreadsheet,
): DocumentMeta => {
  if (!result.spreadsheetId) {
    console.error('No spreadsheetId found in result', result);
    throw new Error('No spreadsheetId found in result');
  }

  return {
    documentId: result.spreadsheetId,
    documentName: result.properties?.title ?? '(Unknown Document)',
    namedRanges: result.namedRanges ?? [],
    sheets:
      result?.sheets
        ?.filter((sheet) => typeof sheet?.properties !== 'undefined')
        .map((sheet) => ({
          title: sheet?.properties?.title ?? '',
          index: sheet?.properties?.index ?? 0,
          sheetId: sheet?.properties?.sheetId ?? 0,
        }))
        .sort((a, b) => {
          if (a.index < b.index) {
            return -1;
          }
          if (b.index < a.index) {
            return 1;
          }
          return 0;
        }) ?? [],
  };
};

export default getDocumentMetaFromSheetResponse;
