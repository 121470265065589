import ContentSelection, {
  contentSelectionToRange,
} from './ContentSelection.js';
import { useRef } from 'react';
import BaseAndExtent from './BaseAndExtent.js';

const getWindowSelection = (): Selection => {
  const selection = window.getSelection();

  if (selection) {
    return selection;
  }

  throw new Error('Window does not have selection for some reason');
};

export function useGetDomRangeFromSelection<T extends HTMLElement>(
  selection: ContentSelection | null,
) {
  const ref = useRef<T>(null);

  return {
    ref,
    getDomRange: () => {
      const containerElement = ref.current;

      if (containerElement && selection) {
        return contentSelectionToRange(containerElement, selection);
      }

      return null;
    },
  };
}

export function setContentSelectionOnBlockElement(
  containerElement: HTMLElement,
  currentSelection: ContentSelection,
  toBaseAndExtent: (
    contentSelection: ContentSelection,
    containerElement: HTMLElement,
  ) => BaseAndExtent,
) {
  const domSelection = getWindowSelection();

  const { anchorNode, anchorOffset, focusNode, focusOffset } = toBaseAndExtent(
    currentSelection,
    containerElement,
  );

  domSelection.setBaseAndExtent(
    anchorNode,
    anchorOffset,
    focusNode,
    focusOffset,
  );
}
