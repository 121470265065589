import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function FullscreenIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M10.5837 14.5739L6.04292 19.1471L8.86695 22H2V15.0839L4.82403 17.9282L9.37339 13.3549L10.5837 14.5739Z"
        fill="#323232"
      />
      <path
        d="M13.4163 9.42611L17.9571 4.85287L15.133 2H22V8.91605L19.176 6.07182L14.6266 10.6451L13.4163 9.42611Z"
        fill="#323232"
      />
    </svg>
  );
}
