import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../../design-system/organisms/Modal.js';
import OutlineButton from '../../../design-system/atoms/OutlineButton.js';
import Button from '../../../design-system/atoms/Button.js';
import styles from './ConfirmPasteModal.module.scss';
import { Icon } from 'icons';
import Spinner from '../../../design-system/atoms/Spinner.js';

type ConfirmPasteModalProps = {
  state: 'initial' | 'confirming' | 'loading';
  onConfirm(): void;
  onCancel(): void;
};

export default function ConfirmPasteModal({
  state,
  onConfirm,
  onCancel,
}: ConfirmPasteModalProps) {
  return (
    <Modal
      className={styles.confirmPasteModal}
      isOpen={state === 'confirming' || state === 'loading'}
    >
      <ModalHeader
        className={styles.confirmPasteModal__header}
        onClickClose={onCancel}
      >
        <Icon name="alert" className={styles.confirmPasteModal__icon} /> Copy
        Content From Another Company?
      </ModalHeader>
      {state === 'confirming' ? (
        <>
          <ModalBody>
            The content you’re trying to paste is being copied from another
            company. Admins and contributors of this company will be able to see
            it. Do you want to proceed?
          </ModalBody>
          <ModalActions>
            <OutlineButton onClick={onCancel} color="secondary" size="medium">
              Cancel
            </OutlineButton>
            <Button onClick={onConfirm} color="danger" size="medium">
              Yes, Paste
            </Button>
          </ModalActions>
        </>
      ) : (
        <ModalBody className={styles.confirmPasteModal__loading}>
          <Spinner />
          <p className={styles.confirmPasteModal__loadingText}>
            Copying Content
          </p>
          <p className={styles.confirmPasteModal__loadingSubText}>
            This may take a minute
          </p>
        </ModalBody>
      )}
    </Modal>
  );
}
