import React from 'react';
import cx from 'classnames';
import styles from './SectionView.module.scss';
import zeckStyles from '../../../design-system/zeck/ZeckStyles.module.scss';

type SectionViewLayoutProps = {
  headingContent: React.ReactNode;
  sidebarContent: React.ReactNode;
  children: React.ReactNode;
  hideSidebar: boolean;
  mainContentRef?: React.Ref<HTMLDivElement>;
};

// UI Component
//  - Layout Component (aka scaffolding)
const SectionViewLayout = React.forwardRef<
  HTMLDivElement,
  SectionViewLayoutProps
>(function SectionViewLayout(
  { headingContent, sidebarContent, children, hideSidebar, mainContentRef },
  forwardedRef,
) {
  return (
    <div
      className={cx(
        styles.sectionView,
        hideSidebar && styles.sectionView__mobile,
      )}
      ref={forwardedRef}
    >
      {headingContent}
      <div
        className={cx(
          zeckStyles.zeck,
          hideSidebar && zeckStyles.zeck_mobile,
          styles.sectionView__body,
          hideSidebar && styles.sectionView__body_mobile,
        )}
      >
        <div className={styles.sectionView__sidebar}>{sidebarContent}</div>

        <div className={styles.sectionView__main} ref={mainContentRef}>
          {children}
        </div>
      </div>
    </div>
  );
});

export default SectionViewLayout;
