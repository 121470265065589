import { Comment } from 'api-client/types.js';

export default function filterComments(comments: Comment[]) {
  const resolvedComments = comments.filter((comment) => comment.resolved);

  const starredComments = comments.filter(
    (comment) => comment.starred && !comment.resolved,
  );

  const activeComments = comments.filter((comment) => !comment.resolved);

  return {
    resolvedComments,
    starredComments,
    activeComments,
  };
}
