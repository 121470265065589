const getHoursSpent = (secondsSpent: number) => {
  if (!secondsSpent) return '';

  if (secondsSpent < 3600) {
    return `${Math.ceil(secondsSpent / 60.0)} min`;
  }

  const hours = Math.floor(secondsSpent / 3600);

  return `${hours} hr${hours > 1 ? 's' : ''} ${Math.ceil(
    (secondsSpent % 3600) / 60,
  )} min`;
};

export default getHoursSpent;
