import {
  BlockSelection,
  getEndOfSelection,
} from '../selection/BlockSelection.js';
import { isTextSelection } from '../EditorSelection.js';
import { TextSelection, textSelection } from '../selection/TextSelection.js';
import ContentSelection, {
  contentSelection,
} from '../selection/contentSelection/ContentSelection.js';
import getSelectedBlock from '../../pages/zeck/editor/BodyEditor/getSelectedBlock.js';
import { EditorConfiguration, EditorState } from '../EditorAction.js';

function blockSelectionAction<BlockType>(
  generateBlockEditor: (b: BlockType) => {
    getEndOfBlockSelection: () => ContentSelection;
  },
  { content, selection }: EditorState<BlockType, BlockSelection>,
) {
  const endOfSelection = getEndOfSelection(selection);
  const nextBlockIndex = endOfSelection + 1;
  const nextBlock = content[nextBlockIndex];

  if (nextBlock) {
    return {
      content,
      selection: textSelection(nextBlockIndex, contentSelection(0)),
    };
  }

  const lastBlock = content[endOfSelection];

  if (!lastBlock) {
    return {
      content,
      selection: textSelection(endOfSelection, contentSelection(0)),
    };
  }

  const lastBlockEditor = generateBlockEditor(lastBlock);

  return {
    content,
    selection: textSelection(
      endOfSelection,
      lastBlockEditor.getEndOfBlockSelection(),
    ),
  };
}

function textSelectionAction<BlockType>(
  generateBlockEditor: (b: BlockType) => {
    pressArrowDown: (isOnLastLineOfBlock: () => boolean) => boolean;
  },
  { content, selection }: EditorState<BlockType, TextSelection>,
  isOnLastLineOfBlock: (blockIndex: number) => boolean,
  fancyNavDown: (
    currentBlockIndex: number,
    newBlockIndex: number,
  ) => ContentSelection | false,
) {
  const selectedBlock = getSelectedBlock(content, selection);
  if (!selectedBlock) return;
  const targetBlockEditor = generateBlockEditor(selectedBlock);

  const isExitingBlock = targetBlockEditor.pressArrowDown(() =>
    isOnLastLineOfBlock(selection.index),
  );

  if (!isExitingBlock) return;
  const nextIndex = selection.index + 1;
  const nextBlock = content[nextIndex];

  if (!nextBlock) return;

  let newSelection: ContentSelection | false;

  try {
    newSelection = fancyNavDown(selection.index, nextIndex);
    // fancyNavUp will throw if point is offscreen.
    // For example: you are trying to arrow up to a block that has been scrolled offscreen
  } catch {
    newSelection = contentSelection(0);
  }

  if (!newSelection) return;

  return {
    content,
    selection: textSelection(nextIndex, newSelection),
  };
}

type PressArrowDownBlockEditorInterface = {
  getEndOfBlockSelection: () => ContentSelection;
  pressArrowDown: (isOnLastLineOfBlock: () => boolean) => boolean;
};

export default function pressArrowDown<BlockType>({
  generateBlockEditor,
}: EditorConfiguration<BlockType, PressArrowDownBlockEditorInterface>) {
  return (
    { content, selection }: EditorState<BlockType>,
    isOnLastLineOfBlock: (blockIndex: number) => boolean,
    fancyNavDown: (
      currentBlockIndex: number,
      newBlockIndex: number,
    ) => ContentSelection | false,
  ): EditorState<BlockType, TextSelection> | void => {
    // only handles block selection currently

    if (!selection) return;
    if (isTextSelection(selection))
      return textSelectionAction(
        generateBlockEditor,
        {
          selection,
          content,
        },
        isOnLastLineOfBlock,
        fancyNavDown,
      );
    return blockSelectionAction(generateBlockEditor, { selection, content });
  };
}
