import Button from '../../../design-system/atoms/Button.tsx';
import OutlineButton from '../../../design-system/atoms/OutlineButton.tsx';
import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../../design-system/organisms/Modal.tsx';
import styles from './ConfirmDeleteVoteModal.module.scss';

type ConfirmDeleteVoteModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export default function ConfirmDeleteVoteModal({
  isOpen,
  onCancel,
  onConfirm,
}: ConfirmDeleteVoteModalProps) {
  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader onClickClose={onCancel}>Delete Vote?</ModalHeader>
      <ModalBody>
        You are about to delete a Vote that was already taken. It will be gone
        forever and you will not be able to get it back. If this vote was
        included in meeting minutes, deleting it here will not remove it from
        the minutes. We recommend you consult your legal advisor to confirm that
        a board ratification is not necessary or advisable before you delete.
      </ModalBody>
      <ModalActions>
        <OutlineButton onClick={onCancel} color="secondary" size="medium">
          Cancel
        </OutlineButton>
        <Button onClick={onConfirm} color="danger" size="medium">
          Yes, Delete Vote
        </Button>
      </ModalActions>
    </Modal>
  );
}
