import cx from 'classnames';
import { useState } from 'react';
import Button from '../design-system/atoms/Button.tsx';
import { Icon } from 'icons';
import IconButton from '../design-system/atoms/IconButton.tsx';
import TopBarLayout from '../design-system/layouts/TopBarLayout.tsx';
import WithTooltip from '../design-system/organisms/WithTooltip.tsx';
import useBreakpoints from '../services/useBreakpoints.ts';
import { Result } from '../services/useFetch/useFetch.ts';
import { ZeckFolderWithCount } from '../types/ZeckFolder.ts';
import { UserAndCompany } from '../userAndCompany/FetchUserAndCompany.tsx';
import styles from './PageLayout.module.scss';
import { Sidebar } from './home/sidebar/Sidebar.tsx';
import { SidebarTop } from './home/sidebar/SidebarTop.tsx';
import { AnimatePresence, motion } from 'framer-motion';

export type PageType = string;

type HomepageLayoutProps = {
  title: React.ReactNode;
  userAndCompany: UserAndCompany;
  onClickLogout(): void;
  onClickCreateZeck?: () => void;
  activePage: PageType;
  children: React.ReactNode;
  onUpdateFolder?: (folderId: string, folderName: string) => void;
  onCreateFolder?: (folderName: string) => Promise<void>;
  onDeleteFolder?: (folderId: string) => Promise<void>;
  zeckFoldersResult: Result<ZeckFolderWithCount[]>;
};

const PageLayout = ({
  title,
  userAndCompany,
  onClickLogout,
  onClickCreateZeck,
  activePage,
  children,
  onCreateFolder,
  onUpdateFolder,
  onDeleteFolder,
  zeckFoldersResult,
}: HomepageLayoutProps) => {
  const { isMobile, isTablet } = useBreakpoints();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  if (isMobile) {
    return (
      <div className={styles.homepage_mobile}>
        <TopBarLayout
          className={styles.homepage__header_mobile}
          {...{
            leftSlot: (
              <IconButton
                {...{
                  onClick() {
                    setMenuIsOpen(!menuIsOpen);
                  },
                  'aria-label': 'Open Menu',
                  name: 'hamburger',
                }}
              />
            ),
          }}
        >
          <h1 className={styles.homepage__heading}>{title}</h1>
        </TopBarLayout>
        <HomepageContent isMobile>{children}</HomepageContent>
        {menuIsOpen && (
          <div
            {...{
              'data-testid': 'sidebar-scrim',
              className: styles.sidebarScrim,
              onClick: () => {
                setMenuIsOpen(false);
              },
            }}
          />
        )}
        <AnimatePresence>
          {menuIsOpen && (
            <motion.div
              key="sidebar"
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ duration: 0.2 }}
              className={styles.homepage__sidebar_mobile}
            >
              <div className={styles.homepage__sidebarHeaderContainer_mobile}>
                <SidebarTop
                  userAndCompany={userAndCompany}
                  menuIsOpen={menuIsOpen}
                  setMenuIsOpen={setMenuIsOpen}
                />
              </div>
              <Sidebar
                {...{
                  userAndCompany,
                  className: styles.homepage__sidebarBody_mobile,
                  onExpand: () => setMenuIsOpen(true),
                  onClickLink: () => setMenuIsOpen(false),
                  onClickLogout,
                  activePage,
                  onCreateFolder,
                  onUpdateFolder,
                  onDeleteFolder,
                  zeckFoldersResult,
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }

  if (isTablet) {
    return (
      <div
        className={cx(
          styles.homepage,
          !menuIsOpen && styles.homepage_menuClosed,
        )}
      >
        <SidebarTop
          {...{
            className: styles.homepage__sidebarHeader,
            menuIsOpen,
            setMenuIsOpen,
            userAndCompany,
          }}
        />
        <Sidebar
          {...{
            userAndCompany,
            className: styles.homepage__sidebar,
            collapse: !menuIsOpen,
            onExpand: () => setMenuIsOpen(true),
            onClickLogout,
            activePage,
            onCreateFolder,
            onUpdateFolder,
            onDeleteFolder,
            zeckFoldersResult,
          }}
        />
        <div className={styles.homepage__topbar} />
        <HomepageContent>{children}</HomepageContent>
      </div>
    );
  }

  return (
    <div className={styles.homepage}>
      <SidebarTop
        {...{
          className: styles.homepage__sidebarHeader,
          userAndCompany,
          menuIsOpen,
          setMenuIsOpen,
        }}
      />
      <Sidebar
        {...{
          userAndCompany,
          className: styles.homepage__sidebar,
          onExpand: () => setMenuIsOpen(true),
          onClickLogout,
          activePage,
          onCreateFolder,
          onUpdateFolder,
          onDeleteFolder,
          zeckFoldersResult,
        }}
      />
      <div className={styles.homepage__topbar}>
        <WithTooltip<HTMLAnchorElement>
          text="Refer a friend and you could get
$1,000 for you and $1,000 for them"
        >
          {(ref, listeners) => (
            <a
              className={styles.referFriend}
              href="https://www.zeck.app/refer-a-friend-to-zeck-get-1000"
              target="_blank"
              rel="noreferrer"
              ref={ref}
              {...listeners}
            >
              Refer a Friend
            </a>
          )}
        </WithTooltip>

        {onClickCreateZeck && (
          <Button
            size="medium"
            color="primary"
            onClick={onClickCreateZeck}
            className={styles.createZeckButton}
          >
            <Icon name="plus" /> Create A New Zeck
          </Button>
        )}
      </div>
      <HomepageContent>{children}</HomepageContent>
    </div>
  );
};

const HomepageContent: React.FC<
  React.ComponentProps<'div'> & { isMobile?: boolean }
> = ({ isMobile, ...otherProps }) => {
  return (
    <div
      {...otherProps}
      data-testid="homepage-content"
      className={cx(
        isMobile ? styles.homepage__content_mobile : styles.homepage__content,
        otherProps.className,
      )}
    />
  );
};

export default PageLayout;
