import { OutstandingVoteBlock } from 'editor-content/VoteBlock.js';
import React from 'react';
import { FinalizeVoteCapability, PrevoteCapability } from './VoteCapability.js';
import OutstandingVote from '../../../design-system/zeck/OutstandingVote.js';
import {
  VoteDetails,
  VoteHeading,
} from '../../../design-system/zeck/vote/Vote.js';

export type OutstandingVoteViewProps = {
  className?: string;
  block: OutstandingVoteBlock;
  prevoteCapability: PrevoteCapability | null;
  finalizeVoteCapability: FinalizeVoteCapability | null;
};

const OutstandingVoteView = React.forwardRef<
  HTMLDivElement,
  OutstandingVoteViewProps
>(function OutstandingVoteView(
  { prevoteCapability, finalizeVoteCapability, block, className },
  forwardedRef,
) {
  return (
    <OutstandingVote
      id={block.id}
      {...{
        ref: forwardedRef,
        prevoteCapability,
        finalizeVoteCapability,
        block,
        detailsContent: block.details && (
          <VoteDetails data-testid="vote-details">{block.details}</VoteDetails>
        ),
        titleContent: <VoteHeading>{block.title || 'Untitled'}</VoteHeading>,
        additionalContent: null,
        kebabContent: null,
        className,
      }}
    />
  );
});

export default OutstandingVoteView;
