import cx from 'classnames';
import styles from './ZeckListEmpty.module.scss';
import useBreakpoints from '../../services/useBreakpoints.ts';
import { ReactComponent as NoZecksImage } from '../../images/NoZecksPlaceholder.svg';

const PublishedZeckListEmpty = () => {
  const { isMobile } = useBreakpoints();

  return (
    <div className={cx(styles.zeckList, isMobile && styles.zeckList_mobile)}>
      <div className={styles.zeckList__emptyContainer}>
        <div className={styles.zeckList__emptyImageContainer}>
          <NoZecksImage />
        </div>
        <div className={styles.zeckList__emptyTextContainer}>
          <p>
            <strong>You Don't Have Any Zecks</strong>
          </p>
          <p>
            This company either doesn't have any Zecks yet or you haven't been
            given access to ‘em. When a Zeck goes live you'll see it here.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PublishedZeckListEmpty;
