import styles from './InputCheckbox.module.scss';
import cx from 'classnames';

type InputCheckboxProps = {
  label: string;
  value: boolean;
  onChange: (v: boolean) => void;
  className?: string;
  backgroundColor?: 'white';
  disabled?: boolean;
};

const InputCheckbox: React.FunctionComponent<InputCheckboxProps> = ({
  label,
  value,
  onChange,
  className,
  backgroundColor,
  disabled,
}) => {
  const backgroundStyle =
    backgroundColor === 'white'
      ? styles.inputCheckbox__input__bg_white
      : undefined;
  return (
    <label className={cx(styles.inputCheckbox, className)}>
      <input
        {...{
          type: 'checkbox',
          disabled,
          className: styles.inputCheckbox__input,
          checked: value,
          onChange(e) {
            onChange(e.currentTarget.checked);
          },
        }}
      />
      <span className={cx(styles.inputCheckbox__control, backgroundStyle)} />
      {label}
    </label>
  );
};

export default InputCheckbox;
