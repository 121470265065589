import { TextBlock } from 'editor-content/Block.ts';
import PublishedSelectionCommentForm from './PublishedSelectionCommentForm.tsx';
import { CommentsStateForSection } from '../commentsSidebar/useComments/useComments.ts';
import Toolbar, {
  ToolbarButton,
  ToolbarGroup,
} from '../../../../../design-system/organisms/Toolbar.tsx';
import { PublishedCommentSelectionUIStateAtom } from './PublishedCommentSelectionUIStateAtom.js';
import { useAtomValue, useSetAtom } from 'jotai';

const PublishedCommentMenu = ({
  commentsStateForSection,
  block,
  publishedCommentSelectionUIStateAtom,
}: {
  commentsStateForSection?: CommentsStateForSection;
  block: TextBlock;
  publishedCommentSelectionUIStateAtom: PublishedCommentSelectionUIStateAtom;
}) => {
  const atoms = useAtomValue(publishedCommentSelectionUIStateAtom);
  const shouldShowToolbar = useAtomValue(atoms.shouldShowToolbar);
  const shouldShowForm = useAtomValue(atoms.shouldShowForm);
  const snippetSelection = useAtomValue(atoms.selection);
  const closeCommentForm = useSetAtom(atoms.closeCommentForm);
  const openCommentForm = useSetAtom(atoms.openCommentForm);

  return (
    <>
      {shouldShowForm && (
        <PublishedSelectionCommentForm
          onClose={closeCommentForm}
          commentsStateForSection={commentsStateForSection}
          selection={{ block, snippetSelection }}
        />
      )}

      {shouldShowToolbar && (
        <Toolbar>
          <ToolbarGroup>
            <ToolbarButton
              data-testid="comment-button"
              iconName="speechBubbleWithText"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openCommentForm();
              }}
            >
              Comment
            </ToolbarButton>
          </ToolbarGroup>
        </Toolbar>
      )}
    </>
  );
};

export default PublishedCommentMenu;
