import { v4 } from 'uuid';

export type NonHighlightTextNode =
  | Editor.Text
  | Editor.ExternalLink
  | Editor.SectionLink
  | Editor.MeetingMinutesLink;

export type TextNode = NonHighlightTextNode | Editor.Highlight;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Editor {
  export type Text = {
    type: 'text';
    text: string;
    format?: TextFormat;
  };

  export type ExternalLink = {
    type: 'external-link';
    content: Text[];
    url: string;
  };

  export type SectionLink = {
    type: 'section-link';
    content: Text[];
    sectionId: string;
  };

  export type MeetingMinutesLink = {
    type: 'meeting-minutes-link';
    meetingMinutesId: string;
    content: Text[];
  };

  export type Highlight = {
    type: 'highlight';
    ids: string[];
    content: NonHighlightTextNode[];
  };

  export type LinkType =
    | Omit<ExternalLink, 'content'>
    | Omit<SectionLink, 'content'>
    | Omit<MeetingMinutesLink, 'content'>;
}

export const isText = (textNode: TextNode): textNode is Editor.Text => {
  return Object.prototype.hasOwnProperty.call(textNode, 'text');
};

export type TextFormat = {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
};

export const Text = (text: string, format: TextFormat = {}): Editor.Text => {
  return {
    type: 'text',
    text,
    format,
  };
};

export const ExternalLink = (
  url: string,
  content: Editor.Text[],
): Editor.ExternalLink => {
  return {
    type: 'external-link',
    url,
    content,
  };
};

export const isExternalLink = (
  textNode: TextNode,
): textNode is Editor.ExternalLink => textNode.type === 'external-link';

export const MeetingMinutesLink = (
  meetingMinutesId: string,
  content: Editor.Text[],
): Editor.MeetingMinutesLink => {
  return {
    type: 'meeting-minutes-link',
    meetingMinutesId,
    content,
  };
};

export const isMeetingMinutesLink = (
  textNode: TextNode,
): textNode is Editor.MeetingMinutesLink =>
  textNode.type === 'meeting-minutes-link';

export const SectionLink = (
  sectionId: string,
  content: Editor.Text[],
): Editor.SectionLink => {
  return {
    type: 'section-link',
    sectionId,
    content,
  };
};

export const isSectionLink = (
  textNode: TextNode,
): textNode is Editor.SectionLink => textNode.type === 'section-link';

export const Highlight = (
  ids: string[],
  content: Editor.Highlight['content'],
): Editor.Highlight => {
  return {
    type: 'highlight',
    ids,
    content,
  };
};

export const createHighlight = (content: NonHighlightTextNode[]) => {
  return Highlight([v4()], content);
};

export const isHighlight = (
  textNode: TextNode,
): textNode is Editor.Highlight => {
  return textNode.type === 'highlight';
};

export function* allTextNodes(nodes: TextNode[]): Generator<Editor.Text, void> {
  for (const node of nodes) {
    if (isText(node)) {
      yield node;
    } else {
      yield* allTextNodes(node.content);
    }
  }
}

export const getTextFromNodes = (textNodes: TextNode[]) => {
  return Array.from(allTextNodes(textNodes)).reduce(
    (acc, { text }) => `${acc}${text}`,
    '',
  );
};

export const getTextNodesLength = (textNodes: TextNode[]): number => {
  return getTextFromNodes(textNodes).length;
};

export const areTextNodesEmpty = (textNodes: TextNode[]): boolean => {
  return getTextNodesLength(textNodes) === 0;
};
