import React from 'react';
import cx from 'classnames';
import styles from './BlockLayout.module.scss';
import BlockLayout from './BlockLayout.tsx';

type EditorLayoutProps = {
  className?: string;
  children: React.ReactNode;
  onClickGutter?: React.MouseEventHandler<HTMLDivElement>;
  leftGutterRef?: React.RefObject<HTMLDivElement>;
} & React.HTMLAttributes<HTMLDivElement>;

const EditorLayout = React.forwardRef<HTMLDivElement, EditorLayoutProps>(
  function EditorLayout(
    { className, onClickGutter, children, leftGutterRef, ...otherProps },
    forwardedRef,
  ) {
    return (
      <BlockLayout
        {...otherProps}
        ref={forwardedRef}
        className={cx(styles.editorLayout, className)}
      >
        <div
          ref={leftGutterRef}
          className={cx(styles.editorLayout__leftGutter)}
          onClick={onClickGutter}
        />
        <div
          className={cx(styles.editorLayout__rightGutter)}
          onClick={onClickGutter}
        />

        {children}
      </BlockLayout>
    );
  },
);

export default EditorLayout;
