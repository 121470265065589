import React, { useState } from 'react';
import CommentEditorAdapter from '../../../../../../commentEditor/CommentEditorAdapter.js';
import IconButton from '../../../../../../design-system/atoms/IconButton.tsx';
import WithTooltip from '../../../../../../design-system/organisms/WithTooltip.tsx';
import {
  CommentReplyWithActions,
  CommentWithActions,
} from '../useComments/useComments.ts';
import CommentKebab from './../CommentKebab.js';
import CommentNameplate from './../CommentNameplate.js';
import PublishedCommentForm from './../PublishedCommentForm.js';
import PublishedCommentLayout from './../PublishedCommentLayout.tsx';
import styles from './CommentOrReply.module.scss';
import { CommentQuote } from './CommentQuote/CommentQuote.tsx';

const isCommentReply = (
  comment: CommentWithActions | CommentReplyWithActions,
): comment is CommentReplyWithActions => {
  return (
    comment.type === 'CommentReply' || comment.type == 'DirectMessageReply'
  );
};
const handleClickIfActionExists =
  (fn: (() => void) | undefined) => (event: React.MouseEvent) => {
    event.stopPropagation();
    if (fn) {
      fn();
    }
  };

export const CommentOrReply: React.FC<{
  className?: string;
  comment: CommentWithActions | CommentReplyWithActions;
}> = ({ className, comment }) => {
  const isReply = isCommentReply(comment);
  const [isEditing, setIsEditing] = useState(false);
  const showStar = !isReply && !comment.actions.unresolve;

  const dataTestId = isReply ? 'reply' : 'comment';
  const commentLabel =
    comment.type === 'DirectMessage' ? 'Direct Message' : 'Comment';

  return (
    <PublishedCommentLayout className={className} data-testid={dataTestId}>
      {!isReply && <CommentQuote comment={comment} />}
      <CommentNameplate
        userName={comment.userName}
        timestamp={comment.createdAt}
        actionsSlot={
          <>
            {!isReply && comment.actions.resolve && (
              <WithTooltip<HTMLButtonElement> text="Mark as resolved and hide">
                {(ref, listeners) => (
                  <IconButton
                    name="resolve"
                    aria-label="resolve"
                    className={`${styles.commentOrReply__resolveButton} exposeOnAncestorHover`}
                    onClick={handleClickIfActionExists(comment.actions.resolve)}
                    ref={ref}
                    {...listeners}
                  />
                )}
              </WithTooltip>
            )}
            {showStar && (
              <WithTooltip<HTMLButtonElement>
                text={comment.starred ? 'Starred' : 'Not Starred'}
              >
                {(ref, listeners) => (
                  <IconButton
                    name={comment.starred ? 'yellowStar' : 'star'}
                    aria-label="star"
                    className={`${comment.starred ? styles.commentOrReply__starButton__starred : styles.commentOrReply__starButton__unstarred} exposeOnAncestorHover`}
                    onClick={handleClickIfActionExists(
                      comment.actions.toggleStar,
                    )}
                    ref={ref}
                    {...listeners}
                  />
                )}
              </WithTooltip>
            )}

            <CommentKebab
              commentTypeLabel={commentLabel}
              onUnresolve={!isReply ? comment.actions.unresolve : undefined}
              onClickEdit={
                comment.actions.edit &&
                (() => {
                  setIsEditing(true);
                })
              }
              onDelete={comment.actions.delete}
            />
          </>
        }
      />
      {isEditing ? (
        <PublishedCommentForm
          className={styles.commentOrReply__editForm}
          availableTags={[]}
          onCancel={() => setIsEditing(false)}
          onSubmit={async (value) => {
            if (!comment.actions.edit) return;
            await comment.actions.edit(value.content);
            setIsEditing(false);
          }}
          testId="edit-comment"
          initialValue={{
            isDirectMessage: false,
            content: comment.content,
          }}
          canToggleDirectMessage={false}
          submitText="Save"
        />
      ) : (
        <div
          data-testid="comment-content"
          className={styles.commentOrReply__content}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: CommentEditorAdapter.toHTMLString(comment.content),
            }}
          />
          {comment.updatedAt > comment.createdAt && (
            <span className={styles.commentOrReply__edited}> (edited)</span>
          )}
        </div>
      )}
    </PublishedCommentLayout>
  );
};
