import Toggle from '../../design-system/atoms/toggle/Toggle.tsx';

export const PeopleListBoardDirectorToggle = ({
  isBoardDirector,
  onChange,
}: {
  isBoardDirector: boolean;
  onChange: (state: boolean) => void;
}) => (
  <Toggle label="board director" on={isBoardDirector} onChange={onChange} />
);
