import ZackLogo from '../../design-system/atoms/ZackLogo.tsx';
import { ReactComponent as ZeckLogo } from '../../images/ZeckBlocksLogoBlack.svg';
import { getDate, getMonth } from 'date-fns';

const Logo =
  getMonth(new Date()) === 3 &&
  getDate(new Date()) >= 1 &&
  getDate(new Date()) <= 8
    ? ZackLogo
    : ZeckLogo;
export default function DashboardPageLogo(props: { className?: string }) {
  return <Logo {...props} />;
}
