import ContentSelection, {
  isCollapsed,
} from '../../selection/contentSelection/ContentSelection.js';

export default function textBlockPressArrowLeftStrategy(
  selection: ContentSelection | null,
) {
  if (!selection || !isCollapsed(selection)) {
    return false;
  }

  return selection.anchorOffset === 0;
}
