import { useRef } from 'react';
import IconButton from '../../../../../design-system/atoms/IconButton.tsx';
import HoverMenu, {
  HoverMenuButtonItem,
} from '../../../../../design-system/organisms/HoverMenu.tsx';
import { useSignal } from '../../../../../signals/index.js';
import { ManageZeckModalActiveType } from '../ZeckSettingsModal.tsx';
import styles from './ZeckMenu.module.scss';

type ZeckMenuProps = {
  setOpenModal: (type: ManageZeckModalActiveType) => void;
};

export default function ZeckMenu({ setOpenModal }: ZeckMenuProps) {
  const button = useRef<HTMLButtonElement | null>(null);
  const menuOpen = useSignal(false);
  const menuOpenValue = menuOpen.useValue();

  return (
    <>
      <IconButton
        ref={button}
        name="verticalKebab"
        aria-label="Open Zeck Menu"
        onClick={() => {
          menuOpen.set(true);
        }}
      />
      <HoverMenu
        elementRef={button}
        positionStrategy={(button, menu) => {
          const buttonRect = button.getBoundingClientRect();
          const menuRect = menu.getBoundingClientRect();
          return [
            buttonRect.x - menuRect.width + buttonRect.width,
            buttonRect.y + buttonRect.height + 8,
          ];
        }}
        onClose={() => {
          menuOpen.set(false);
        }}
        isOpen={menuOpenValue}
      >
        <HoverMenuButtonItem
          iconName="people"
          iconClassName={styles.menuButtonIcon}
          color="normal"
          onClick={(e) => {
            e.stopPropagation();
            menuOpen.set(false);
            setOpenModal('manageViewers');
          }}
        >
          Manage Viewers
        </HoverMenuButtonItem>

        <HoverMenuButtonItem
          iconName="chart"
          iconClassName={styles.menuButtonIcon}
          color="normal"
          onClick={(e) => {
            e.stopPropagation();
            menuOpen.set(false);
            setOpenModal('analytics');
          }}
        >
          Analytics
        </HoverMenuButtonItem>

        <HoverMenuButtonItem
          iconName="gear"
          iconClassName={styles.menuButtonIcon}
          color="normal"
          onClick={(e) => {
            e.stopPropagation();
            menuOpen.set(false);
            setOpenModal('settings');
          }}
        >
          Settings
        </HoverMenuButtonItem>
      </HoverMenu>
    </>
  );
}
