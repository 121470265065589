import { baseBlock, createBlock } from './BaseBlock.js';
import { Block } from './Block.js';

export type ChartBlockLineData = {
  version: 1;
  type: 'line';
  lineAxis?: 'x' | 'y';
  title: {
    text: string;
    position: 'top' | 'left' | 'right' | 'bottom';
  };
  subtitle: {
    text: string;
  };
  legend: {
    display: boolean;
    position: 'top' | 'left' | 'right' | 'bottom';
  };
  scales: {
    x: {
      title: {
        text: string;
      };
      position: 'top' | 'bottom';
      type: 'category' | 'linear' | 'logarithmic';
      min: number | null;
    };
    y: {
      title: {
        text: string;
      };
      position: 'left' | 'right';
      type: 'category' | 'linear' | 'logarithmic';
      min: number | null;
    };
  };
  labels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    backgroundColor: string | string[];
    borderColor: string | string[];
    borderWidth: number;
    order: number;
  }>;
};

export type ChartBlockBarData = {
  version: 1;
  type: 'bar';
  indexAxis: 'x' | 'y';
  title: {
    text: string;
    position: 'top' | 'left' | 'right' | 'bottom';
  };
  subtitle: {
    text: string;
  };
  legend: {
    display: boolean;
    position: 'top' | 'left' | 'right' | 'bottom';
  };
  scales: {
    x: {
      title: {
        text: string;
      };
      position: 'top' | 'bottom';
      type: 'category' | 'linear' | 'logarithmic';
      min: number | null;
      stacked: boolean;
    };
    y: {
      title: {
        text: string;
      };
      position: 'left' | 'right';
      type: 'category' | 'linear' | 'logarithmic';
      min: number | null;
      stacked: boolean;
    };
  };
  labels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    backgroundColor: string | string[];
    borderColor: string | string[];
    borderWidth: number;
    order: number;
  }>;
};

export type ChartBlockPieData = {
  version: 1;
  type: 'pie';
  cutout: string;
  title: {
    text: string;
    position: 'top' | 'left' | 'right' | 'bottom';
  };
  subtitle: {
    text: string;
  };
  legend: {
    display: boolean;
    position: 'top' | 'left' | 'right' | 'bottom';
  };
  labels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    backgroundColor: string | string[];
    borderWidth: number;
    order: number;
  }>;
};

export type ChartBlockData =
  | ChartBlockLineData
  | ChartBlockBarData
  | ChartBlockPieData;

export const isChartBlock = (block: Block): block is ChartBlock =>
  block.type === 'chart';

export type ChartBlockSourceData = Array<Array<string | number>>;

export type ChartBlock = {
  id: string;
  type: 'chart';
  guid: string;
  data: {
    chartData: ChartBlockData;
    sourceData: ChartBlockSourceData;
  };
};

export const ChartBlock = (
  id: string,
  guid: string,
  chartData: ChartBlockData,
  sourceData: ChartBlockSourceData,
): ChartBlock =>
  baseBlock('chart', id, {
    guid,
    data: {
      chartData,
      sourceData,
    },
  });

export const createChartBlock = (
  guid: string,
  chartData: ChartBlockData,
  sourceData: ChartBlockSourceData,
): ChartBlock =>
  createBlock('chart', {
    guid, // must be globally unique, used for artifacts
    data: {
      chartData,
      sourceData,
    },
  });

export type AssetContainingBlock = ChartBlock;

export const isAssetContainingBlock = (
  block: Block,
): block is AssetContainingBlock => isChartBlock(block);
