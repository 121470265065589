import React from 'react';
import cx from 'classnames';
import styles from './ZeckList.module.scss';
import useBreakpoints from '../../services/useBreakpoints.ts';
import { ManagementPageTitle } from '../ManagementPage.tsx';

export const ZeckListItem = React.forwardRef<
  HTMLLIElement,
  {
    children: React.ReactNode;
  }
>(function ZeckListItem({ children, ...props }, forwardedRef) {
  return (
    <li {...props} className={styles.zeckList__item} ref={forwardedRef}>
      {children}
    </li>
  );
});

type ZeckListProps = {
  children: React.ReactNode;
  className?: string;
};

const ZeckList = ({ children, className }: ZeckListProps) => {
  const { isMobile } = useBreakpoints();

  return (
    <ol
      className={cx(
        className,
        styles.zeckList,
        isMobile && styles.zeckList_mobile,
      )}
    >
      {children}
    </ol>
  );
};

export default ZeckList;

export const ZeckListWrapper = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cx(styles.zeckListWrapper, className)}>{children}</div>
  );
};

export const ZeckListTitle = ({ title }: { title: string }) => (
  <ManagementPageTitle title={title} className={styles.zeckListTitle} />
);
