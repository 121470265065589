import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function HighlightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M20.707 5.82622L17.172 2.29322C16.9856 2.10654 16.733 2.00113 16.4692 2.00001C16.2055 1.99889 15.9519 2.10213 15.764 2.28722L7.096 10.8202C6.96089 10.954 6.86626 11.1231 6.823 11.3082L5.799 15.7452L4 18.0002H6.828L7.97 16.8712L11.558 16.0432C11.738 16.0012 11.903 15.9102 12.035 15.7812L20.702 7.24622C20.7961 7.15359 20.8708 7.04325 20.922 6.92157C20.9732 6.79989 20.9998 6.66928 21.0003 6.53726C21.0008 6.40525 20.9751 6.27445 20.9247 6.15242C20.8744 6.03038 20.8004 5.91951 20.707 5.82622ZM11.338 13.6592L9.217 11.5392L16.46 4.40822L18.58 6.52822L11.338 13.6592ZM4 20.0002H20V22.0002H4V20.0002Z"
        fill="#8F90A6"
      />
    </svg>
  );
}
