import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function ReplaceIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C2 2.89544 2.89544 2 4 2H8C9.10456 2 10 2.89544 10 4V8C10 9.10456 9.10456 10 8 10H4C2.89544 10 2 9.10456 2 8V4ZM8 4H4V8H8V4Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 16C14 14.8954 14.8954 14 16 14H20C21.1046 14 22 14.8954 22 16V20C22 21.1046 21.1046 22 20 22H16C14.8954 22 14 21.1046 14 20V16ZM20 16H16V20H20V16Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7071 2.29289C17.0976 2.68342 17.0976 3.31658 16.7071 3.70711L15.4142 5H19C19.7956 5 20.5587 5.31607 21.1213 5.87866C21.684 6.44128 22 7.20437 22 8V11C22 11.5523 21.5523 12 21 12C20.4477 12 20 11.5523 20 11V8C20 7.73477 19.8946 7.48044 19.7071 7.29293L19.7071 7.29292C19.5195 7.10534 19.2652 7 19 7H15.4142L16.7071 8.29289C17.0976 8.68342 17.0976 9.31658 16.7071 9.70711C16.3166 10.0976 15.6834 10.0976 15.2929 9.70711L12.2929 6.70711C11.9024 6.31658 11.9024 5.68342 12.2929 5.29289L15.2929 2.29289C15.6834 1.90237 16.3166 1.90237 16.7071 2.29289Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3.55228 12 4 12.4477 4 13V16C4 16.2652 4.10534 16.5195 4.29292 16.7071L4.29292 16.7071C4.48044 16.8946 4.73477 17 5 17H8.58579L7.29289 15.7071C6.90237 15.3166 6.90237 14.6834 7.29289 14.2929C7.68342 13.9024 8.31658 13.9024 8.70711 14.2929L11.7071 17.2929C12.0976 17.6834 12.0976 18.3166 11.7071 18.7071L8.70711 21.7071C8.31658 22.0976 7.68342 22.0976 7.29289 21.7071C6.90237 21.3166 6.90237 20.6834 7.29289 20.2929L8.58579 19H5C4.20437 19 3.44128 18.684 2.87866 18.1213C2.31608 17.5587 2 16.7956 2 16V13C2 12.4477 2.44772 12 3 12Z"
        fill="black"
      />
    </svg>
  );
}
