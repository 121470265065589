import { FormEventHandler, useState } from 'react';
import useBreakpoints from '../../services/useBreakpoints.ts';
import styles from './ResetPasswordPage.module.scss';
import cx from 'classnames';
import Button from '../../design-system/atoms/Button.tsx';
import { Icon } from 'icons';
import SessionLayout from '../../design-system/layouts/SessionLayout.tsx';
import InputPassword from '../../design-system/atoms/InputPassword.tsx';
import { validatePassword } from '../../services/validations.ts';
import FormState from '../auth/FormState.ts';
import { hasStatus } from '../../api/makeMakeApiRequest.ts';

type ResetPasswordFormProps = {
  onResetPassword(password: string): Promise<void>;
};

const ResetPasswordForm = ({ onResetPassword }: ResetPasswordFormProps) => {
  const { isMobile } = useBreakpoints();
  const [formState, setFormState] = useState<
    FormState<'password' | 'passwordConfirm'>
  >({
    password: {
      value: '',
      error: null,
    },
    passwordConfirm: {
      value: '',
      error: null,
    },
  });

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    setFormState({
      ...formState,
      password: {
        ...formState.password,
        error: validatePassword(formState.password.value)
          ? null
          : 'Passwords must be 9 characters and a mix of letters and numbers',
      },
      passwordConfirm: {
        ...formState.passwordConfirm,
        error:
          formState.password.value === formState.passwordConfirm.value
            ? null
            : 'The passwords you entered do not match',
      },
    });

    if (
      validatePassword(formState.password.value) &&
      formState.password.value &&
      formState.password.value === formState.passwordConfirm.value
    ) {
      try {
        await onResetPassword(formState.password.value);
      } catch (error) {
        if (hasStatus(error) && error.status === 422) {
          setFormState({
            ...formState,
            password: {
              ...formState.password,
              error:
                'Passwords must be 9 characters and a mix of letters and numbers',
            },
          });
        } else {
          throw error;
        }
      }
    }
  };

  const setFormValue = (name: string) => (value: string) => {
    setFormState({
      ...formState,
      [name]: { value, error: null },
    });
  };

  return (
    <SessionLayout>
      <h1 className={cx(isMobile ? styles.title_mobile : styles.title)}>
        Reset Your Password
      </h1>
      <p className={styles.resetPassword__description}>
        Choose a new password for your Zeck account.
      </p>
      <form onSubmit={handleSubmit} className={styles.form}>
        <InputPassword
          {...{
            label: 'New Password',
            ...formState.password,
            onChange: setFormValue('password'),
          }}
        />
        <InputPassword
          {...{
            label: 'Confirm New Password',
            ...formState.passwordConfirm,
            onChange: setFormValue('passwordConfirm'),
          }}
        />
        <Button
          {...{
            className: styles.resetPassword__submit,
            size: 'large',
            color: 'primary',
          }}
        >
          <Icon name="lock" /> Change Password
        </Button>
      </form>
    </SessionLayout>
  );
};

export default ResetPasswordForm;
