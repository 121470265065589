import { Icon } from 'icons';
import OutlineButton from './OutlineButton.tsx';
import styles from './SelectViewersButton.module.scss';
import WithTooltip from '../organisms/WithTooltip.tsx';

type SelectViewersButtonProps = {
  color: 'primary' | 'secondary';
  onClick: () => void;
  tooltipText?: string;
};

const SelectViewersButton: React.FunctionComponent<
  SelectViewersButtonProps
> = ({ color, onClick, tooltipText = '' }) => {
  if (!tooltipText) {
    return (
      <OutlineButton
        size="medium"
        color={color}
        onClick={onClick}
        className={styles.selectViewersButton__button}
      >
        <Icon name="people" className={styles.selectViewersButton__icon} />
        Select Viewers
      </OutlineButton>
    );
  }

  return (
    <WithTooltip<HTMLButtonElement> text={tooltipText}>
      {(ref, listeners) => (
        <OutlineButton
          size="medium"
          color={color}
          onClick={onClick}
          className={styles.selectViewersButton__button}
          ref={ref}
          {...listeners}
        >
          <Icon name="people" className={styles.selectViewersButton__icon} />
          Select Viewers
        </OutlineButton>
      )}
    </WithTooltip>
  );
};

export default SelectViewersButton;
