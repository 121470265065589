import {
  isTextSelection,
  textSelection,
  TextSelection,
} from '../../../../editor/selection/TextSelection.js';
import {
  blockSelection,
  BlockSelection,
  getStartOfSelection,
} from '../../../../editor/selection/BlockSelection.js';
import getSelectedBlock from './getSelectedBlock.js';
import replaceBlockAt from './replaceBlockAt.js';
import splitContentByBlockSelection from '../../../../editor/selection/splitContentByBlockSelection.js';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.js';

export type ContentPatch<B extends unknown[]> = {
  contentSubset: B;
  selection: TextSelection | BlockSelection;
};

export const applySelection = (
  initialSelection: TextSelection | BlockSelection,
  selectionPatch: TextSelection | BlockSelection,
): TextSelection | BlockSelection => {
  const offsetIndex = isTextSelection(initialSelection)
    ? initialSelection.index
    : getStartOfSelection(initialSelection);

  if (isTextSelection(selectionPatch)) {
    return textSelection(
      offsetIndex + selectionPatch.index,
      selectionPatch.offset,
    );
  } else {
    return blockSelection(
      selectionPatch.anchorIndex + offsetIndex,
      selectionPatch.focusIndex + offsetIndex,
    );
  }
};

const replaceSelectionWith: <BlockType>(
  state: {
    content: BlockType[];
    selection: TextSelection | BlockSelection | null;
  },
  handlers: {
    textSelection: (
      selectedBlock: BlockType,
      selection: ContentSelection,
    ) => ContentPatch<BlockType[]> | void;
    blockSelection: (
      selectedBlocks: BlockType[],
    ) => ContentPatch<BlockType[]> | void;
  },
) => {
  content: BlockType[];
  selection: TextSelection | BlockSelection;
} | void = (state, handlers) => {
  const selection = state.selection;
  const content = state.content;

  if (selection == null) return;

  if (isTextSelection(selection)) {
    const currentBlock = getSelectedBlock(content, selection);

    if (!currentBlock) return;

    const strategyResult = handlers.textSelection(
      currentBlock,
      selection.offset,
    );

    if (!strategyResult) return;

    return {
      content: replaceBlockAt(
        content,
        strategyResult.contentSubset,
        selection.index,
      ),
      selection: applySelection(selection, strategyResult.selection),
    };
  }

  const [beforeBlocks, selectedBlocks, afterBlocks] =
    splitContentByBlockSelection(content, selection);

  const strategyResult = handlers.blockSelection(selectedBlocks);

  if (!strategyResult) return;

  return {
    content: [...beforeBlocks, ...strategyResult.contentSubset, ...afterBlocks],
    selection: applySelection(selection, strategyResult.selection),
  };
};

export default replaceSelectionWith;
