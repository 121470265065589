import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import { textSelection } from '../../../../editor/selection/TextSelection.js';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.js';
import updateBlockAt from './updateBlockAt.js';
import { BodyStateSelected } from './BodyEditor.js';

export default function editBlock(
  initialState: BodyStateSelected,
  blockIndex: number,
  newBlock: HydratedBlock,
  newContentSelection: ContentSelection,
): BodyStateSelected {
  const { content } = initialState;

  return {
    content: updateBlockAt(content, blockIndex, newBlock),
    selection: textSelection(blockIndex, newContentSelection),
  };
}
