import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function RightKeyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M12.245 16.1314L15.5582 12.8182L12.245 9.50497L11.6058 10.1406L13.8253 12.3565H8.25V13.2798H13.8253L11.6058 15.4993L12.245 16.1314Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="white" />
    </svg>
  );
}
