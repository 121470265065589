import { KeyboardEventHandler, useRef } from 'react';
import { rangeToContentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.js';
import getSelectionRangeSafely from '../../../../editor/selection/contentSelection/getSelectionRangeSafely.js';
import { isOnFirstLine } from '../../../../editor/selection/contentSelection/lineDetection/isOnFirstLine.js';
import { isOnLastLine } from '../../../../editor/selection/contentSelection/lineDetection/isOnLastLine.js';

const isAtStartOfBlock = (
  containerNode: HTMLElement,
  range: Range,
): boolean => {
  const { anchorOffset } = rangeToContentSelection(containerNode, range);
  return anchorOffset === 0;
};

const isAtEndOfBlock = (containerNode: HTMLElement, range: Range): boolean => {
  const { anchorOffset } = rangeToContentSelection(containerNode, range);
  const refRange = new Range();
  refRange.selectNodeContents(containerNode);
  return anchorOffset === refRange.toString().length;
};

const selectionReachesStartOfBlock = (
  containerNode: HTMLElement,
  range: Range,
) => {
  const rangeForMeasurement = range.cloneRange();
  rangeForMeasurement.collapse(true);
  rangeForMeasurement.setStartBefore(containerNode);
  return rangeForMeasurement.toString().length === 0;
};
const selectionReachesEndOfBlock = (
  containerNode: HTMLElement,
  range: Range,
) => {
  const rangeForMeasurement = range.cloneRange();
  rangeForMeasurement.collapse(false);
  rangeForMeasurement.setEndAfter(containerNode);
  return rangeForMeasurement.toString().length === 0;
};

type UseTextBlockKeyboardEventsHandlers = {
  onEnter(): void;
  onArrowLeftOut(): void;
  onArrowRightOut(): void;
  onArrowUpOut(): void;
  onArrowDownOut(): void;
  onSelectOut(): void;
  pressSpacebar?(): boolean | void;
  onTab?: (shiftKey: boolean) => void;
};

const useTextBlockKeyboardEvents = <ElementType extends HTMLElement>({
  onEnter,
  onArrowLeftOut,
  onArrowRightOut,
  onArrowUpOut,
  onArrowDownOut,
  onSelectOut,
  pressSpacebar,
  onTab,
}: UseTextBlockKeyboardEventsHandlers) => {
  const ref = useRef<ElementType>(null);

  const handleKeyDown: KeyboardEventHandler = (e) => {
    const containerNode = ref.current;
    const range = getSelectionRangeSafely();
    if (!range || !containerNode) return;

    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onEnter();
    } else if (e.key === 'ArrowUp') {
      if (e.getModifierState('Shift')) {
        if (selectionReachesStartOfBlock(containerNode, range)) {
          e.preventDefault();
          onSelectOut();
          return;
        }

        return;
      }
      if (range.collapsed) {
        if (isOnFirstLine(containerNode, range)) {
          e.preventDefault(); //stops the caret from first jumping to the top
          onArrowUpOut();
        }
      }
    } else if (e.key === 'ArrowDown') {
      if (e.getModifierState('Shift')) {
        if (selectionReachesEndOfBlock(containerNode, range)) {
          e.preventDefault();
          onSelectOut();
          return;
        }

        return;
      }
      if (range.collapsed) {
        if (isOnLastLine(containerNode, range)) {
          e.preventDefault(); //stops the caret from first jumping to the top
          onArrowDownOut();
        }
      }
    } else if (e.key === 'ArrowLeft') {
      if (range.collapsed) {
        if (isAtStartOfBlock(containerNode, range)) {
          e.preventDefault();
          onArrowLeftOut();
        }
      }
    } else if (e.key === 'ArrowRight') {
      if (range.collapsed) {
        if (isAtEndOfBlock(containerNode, range)) {
          e.preventDefault();
          onArrowRightOut();
        }
      }
    } else if (e.key === ' ') {
      if (pressSpacebar && pressSpacebar()) {
        e.preventDefault();
      }
    } else if (e.key === 'Tab') {
      if (onTab) {
        e.preventDefault();
        onTab(e.shiftKey);
      }
    }
  };

  return { handleKeyDown, ref };
};

export default useTextBlockKeyboardEvents;
