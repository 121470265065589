import pressDelete from './pressDelete.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import {
  createParagraphBlock,
  isTextBlock,
  updateTextBlock,
} from 'editor-content/Block.js';
import {
  contentSelection,
  isCollapsed,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import getTextBlockLength from '../../../../editor/blocks/textBlocksStrategies/getTextBlockLength.js';

export default pressDelete<HydratedBlock>((block) => {
  if (isTextBlock(block)) {
    return {
      pressDelete: (selection) => {
        const isSelectionCollapsedAtEndOfBlock =
          isCollapsed(selection) &&
          selection.anchorOffset >= getTextBlockLength(block);

        if (!isSelectionCollapsedAtEndOfBlock) {
          return { type: 'browser-handled' };
        }

        return { type: 'merge' };
      },
      askToMerge: () => ({ type: 'accept-merge', content: block.content }),
      receiveMerge: (content) =>
        updateTextBlock(block, [...block.content, ...content]),
      receiveSelectionFromLeft: () => contentSelection(0),
    };
  } else {
    return {
      pressDelete: () => ({ type: 'remove' }),
      askToMerge: () => ({ type: 'accept-selection' }),
      receiveMerge: () => block,
      receiveSelectionFromLeft: () => contentSelection(0),
    };
  }
}, createParagraphBlock);
