import { fromElement, PositionStrategyRect } from './PositionStrategy.js';

export const centeredBelow =
  (padding: number): PositionStrategyRect =>
  (targetRect, popoverRect) => {
    return [
      targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
      targetRect.bottom + padding,
    ];
  };

export const centeredBelowElement = (padding: number) =>
  fromElement(centeredBelow(padding));
