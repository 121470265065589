import React from 'react';

import { CommentWithActions } from '../../useComments/useComments.ts';
import { isImageBlock, isTextBlock, TextBlock } from 'editor-content/Block.ts';
import QuotedImageBlock from './QuotedImageBlock.tsx';
import { getTextFromNodes } from 'editor-content/TextNode.ts';
import ContentSelection from '../../../../../../../editor/selection/contentSelection/ContentSelection.ts';
import splitTextNodesFromContentSelection from '../../../../../../../editor/blocks/textNode/splitTextNodesFromContentSelection.ts';
import styles from './CommentQuote.module.scss';
import { truncate } from '../../../../../../../junkDrawer/truncate.ts';

const getQuotedTextFromBlock = (
  block: TextBlock,
  snippetSelection: ContentSelection,
) => {
  const [, selectedTextNodes] = splitTextNodesFromContentSelection(
    block.content,
    snippetSelection,
  );
  const text = getTextFromNodes(selectedTextNodes);
  return truncate(text, 960);
};

export const CommentQuote: React.FC<{
  comment: Pick<
    CommentWithActions,
    'selection' | 'selectionEdited' | 'selectionDeleted'
  >;
}> = ({ comment }) => {
  const block = comment.selection?.block;
  const snippetSelection = comment.selection?.snippetSelection;
  if (!block) {
    return null;
  }

  if (isImageBlock(block)) {
    return (
      <QuotedImageBlock
        block={block}
        imageEdited={comment.selectionEdited}
        imageDeleted={comment.selectionDeleted}
      />
    );
  }

  if (isTextBlock(block) && snippetSelection) {
    return (
      <div data-testid="comment-quote" className={styles.commentOrReply__quote}>
        {getQuotedTextFromBlock(block, snippetSelection)}
      </div>
    );
  }

  return null;
};

export default CommentQuote;
