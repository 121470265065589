import React, { SVGProps } from 'react';

export function CartaIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="18"
      viewBox="0 0 99 43"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      display="block"
      {...props}
      className={props.className}
    >
      <g>
        <path
          d="M97.926 1.077H1.074v40.846h96.852V1.077Z"
          stroke="#000"
          strokeWidth="3"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M21.816 30.34a7.627 7.627 0 0 1-5.455-2.267 7.669 7.669 0 0 1-2.218-5.486c0-4.92 4.124-7.81 7.819-7.81 2.643 0 5.115.994 6.437 3.314l-2.472 1.444a4.648 4.648 0 0 0-3.916-2.09c-2.174 0-4.762 1.71-4.762 5.099 0 3.313 2.472 5.125 4.957 5.125a4.59 4.59 0 0 0 4.028-2.478l2.528 1.18c-1.424 2.587-3.978 3.969-6.946 3.969ZM37.9 27.537c2.819 0 4.758-2.12 4.758-4.97 0-2.85-1.94-4.97-4.758-4.97-2.819 0-4.825 2.094-4.825 4.923 0 2.867 1.943 5.017 4.825 5.017Zm-.298 2.803c-4.464 0-7.643-3.39-7.643-7.783 0-4.48 3.15-7.78 7.643-7.78 2.062 0 4.048.872 5 2.21v-1.825h3.053v14.764h-3.053v-1.83c-1.001 1.33-2.941 2.244-5 2.244ZM61.08 29.92V18.014h-2.35v-2.95h2.38v-4.363h3.146v4.364h3.261v2.949h-3.261v11.904H61.08ZM76.52 27.537c2.818 0 4.761-2.12 4.761-4.97 0-2.85-1.943-4.97-4.762-4.97-2.818 0-4.82 2.094-4.82 4.923 0 2.867 1.939 5.017 4.82 5.017Zm-.298 2.803c-4.468 0-7.64-3.39-7.64-7.783 0-4.48 3.146-7.78 7.64-7.78 2.062 0 4.048.872 5 2.21v-1.825h3.056v14.764h-3.056v-1.83c-1.001 1.33-2.941 2.244-5 2.244ZM48.8 29.902V15.138h2.909v2.565c.601-1.657 1.873-2.8 3.559-2.8.366-.007.731.034 1.087.12v2.855c-2.373-.275-4.379 1.389-4.379 5.189v6.835h-3.175Z"
          fill="#000"
        ></path>
      </g>
    </svg>
  );
}
