import Modal, {
  ModalBody,
  ModalHeader,
} from '../../../design-system/organisms/Modal.tsx';
import { ReactComponent as GenerateFromAPublishedZeck } from './GenerateFromAPublishedZeck.svg';
import { ReactComponent as StartFromBlankTemplate } from './StartFromBlankTemplate.svg';
import styles from './CreateNewMeetingMinutes.module.scss';
import { CreateModalBody } from '../../../design-system/molecules/CreateModal.tsx';
import { useEffect, useState } from 'react';
import useFetch from '../../../services/useFetch/useFetch.ts';
import useApi from '../../../api/useApi.ts';
import { Company, CompanyLite, ZeckLite } from '../../../types.ts';
import GenerateMinutesFromZeck from './GenerateMinutesFromZeck.tsx';

type CreateNewMeetingMinutesProps = {
  company: Company | CompanyLite;
  isOpen: boolean;
  onClose: () => void;
  onStartFromBlankTemplate: (name: string) => Promise<void>;
  onGenerateFromPublishedZeck: (zeck: ZeckLite) => void;
};

export default function CreateNewMeetingMinutes({
  company,
  isOpen,
  onClose,
  onStartFromBlankTemplate,
  onGenerateFromPublishedZeck,
}: CreateNewMeetingMinutesProps) {
  const [startingFromBlankTemplate, setStartingFromBlankTemplate] =
    useState(false);
  const [generatingFromZeck, setGeneratingFromZeck] = useState(false);

  const resetState = () => {
    setStartingFromBlankTemplate(false);
    setGeneratingFromZeck(false);
  };

  useEffect(() => {
    if (isOpen) {
      // if closed, revert to starting point on new open, otherwise get stuck creating blank
      resetState();
    }
  }, [isOpen]);

  const api = useApi();
  const result = useFetch(
    () => api.listZecks(company, undefined, true),
    [company.id],
  );

  return (
    <Modal isOpen={isOpen} dark={generatingFromZeck}>
      {((!startingFromBlankTemplate && !generatingFromZeck) ||
        (generatingFromZeck && result.type !== 'success')) && (
        <>
          <ModalHeader onClickClose={onClose}>
            Create New Meeting Minutes
          </ModalHeader>
          <ModalBody className={styles.body}>
            <button
              onClick={() => setGeneratingFromZeck(true)}
              className={styles.action}
              data-testid="create-zeck-minutes-button"
            >
              <GenerateFromAPublishedZeck className={styles.fromZeckImage} />
              <span>Generate From A Published Zeck</span>
            </button>
            <button
              onClick={() => setStartingFromBlankTemplate(true)}
              className={styles.action}
              data-testid="create-blank-minutes-button"
            >
              <StartFromBlankTemplate />
              <span>Start From Blank Template</span>
            </button>
          </ModalBody>
        </>
      )}

      {startingFromBlankTemplate && (
        <CreateModalBody
          title="Give your meeting minutes a name"
          placeholder="Board Meeting Minutes"
          onClose={onClose}
          onSubmit={onStartFromBlankTemplate}
        />
      )}

      {generatingFromZeck && result.type === 'success' && (
        <GenerateMinutesFromZeck
          zecks={result.data}
          onSubmit={(zeck) => {
            onGenerateFromPublishedZeck(zeck);
            setGeneratingFromZeck(false);
          }}
          onCancel={() => {
            setGeneratingFromZeck(false);
          }}
        />
      )}
    </Modal>
  );
}
