function* allTextNodes(element: HTMLElement): Generator<Text, void> {
  for (const childNode of element.childNodes) {
    if (childNode.nodeType === Node.TEXT_NODE) {
      yield childNode as Text;
    } else if (childNode.nodeType === Node.ELEMENT_NODE) {
      yield* allTextNodes(childNode as HTMLElement);
    }
  }
}

export default allTextNodes;
