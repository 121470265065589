type ParsedVideoUrl = {
  provider: 'youtube' | 'vimeo';
  videoId: string;
  queryString: string;
};

// Converts a time query string to the format that YouTube's embed API expects
const convertTimeQueryString = (t: string) => {
  const unitsToSeconds = {
    h: 3600,
    m: 60,
    s: 1,
  };

  const unitToSeconds = (time: string, unit: 's' | 'm' | 'h') =>
    parseInt(time) * unitsToSeconds[unit];

  const timeMatches = Array.from(t.matchAll(/(\d+)(h|m|s)?/g));

  const totalSeconds = timeMatches.reduce((sum, match) => {
    const [, time, unit] = match;

    if (!time) return sum;
    if (!unit || !['s', 'm', 'h'].includes(unit))
      return sum + unitToSeconds(time, 's');

    return sum + unitToSeconds(time, unit as 's' | 'm' | 'h');
  }, 0);

  return `start=${totalSeconds}`;
};

export default function parseVideoUrl(url: string): ParsedVideoUrl | null {
  let parsedUrl;
  try {
    parsedUrl = new URL(url);
  } catch {
    return null;
  }

  if (isYouTubeUrl(parsedUrl)) {
    const vSearchParam = parsedUrl.searchParams.get('v');
    const timeStampParam = parsedUrl.searchParams.get('t');
    const startParam = parsedUrl.searchParams.get('start');
    const maybeStartQueryString = startParam ? `start=${startParam}` : null;
    const queryString =
      maybeStartQueryString ??
      (timeStampParam ? convertTimeQueryString(timeStampParam) : '');

    if (vSearchParam) {
      return {
        provider: 'youtube',
        videoId: vSearchParam,
        queryString,
      };
    } else {
      const pathParts = parsedUrl.pathname.split('/');
      const videoId = pathParts[pathParts.length - 1] || '';
      return {
        provider: 'youtube',
        videoId,
        queryString,
      };
    }
  }

  if (isVimeoUrl(parsedUrl)) {
    return {
      provider: 'vimeo',
      videoId: parsedUrl.pathname.slice(1),
      queryString: '',
    };
  }

  return null;
}

function isVimeoUrl(url: URL) {
  return url.host.includes('vimeo.com');
}

function isYouTubeUrl(url: URL) {
  return url.host.includes('youtube.com') || url.host.includes('youtu.be');
}
