import styles from './Toast.module.scss';
import BlackBox from '../../atoms/BlackBox.tsx';
import IconButton from '../../atoms/IconButton.tsx';
import { AnimatePresence, motion } from 'framer-motion';

const BlackBoxMotion = motion(BlackBox);

export type ToastProps = {
  message: string;
  isOpen: boolean;
  onClose: () => void;
};

export default function Toast({ message, isOpen, onClose }: ToastProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <BlackBoxMotion
          key="toast"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={styles.toast}
        >
          {message}
          <IconButton
            onClick={onClose}
            name="close"
            aria-label="close toast"
            className={styles.closeToast}
          />
        </BlackBoxMotion>
      )}
    </AnimatePresence>
  );
}
