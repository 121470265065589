import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.js';

export function ProhibitionIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <g id="block" clipPath="url(#clip0_12915_26424)">
        <path
          id="Vector"
          d="M7.99967 1.33398C4.31967 1.33398 1.33301 4.32065 1.33301 8.00065C1.33301 11.6807 4.31967 14.6673 7.99967 14.6673C11.6797 14.6673 14.6663 11.6807 14.6663 8.00065C14.6663 4.32065 11.6797 1.33398 7.99967 1.33398ZM2.66634 8.00065C2.66634 5.05398 5.05301 2.66732 7.99967 2.66732C9.23301 2.66732 10.3663 3.08732 11.2663 3.79398L3.79301 11.2673C3.08634 10.3673 2.66634 9.23398 2.66634 8.00065ZM7.99967 13.334C6.76634 13.334 5.63301 12.914 4.73301 12.2073L12.2063 4.73398C12.913 5.63398 13.333 6.76732 13.333 8.00065C13.333 10.9473 10.9463 13.334 7.99967 13.334Z"
          fill="#1C1C28"
        />
      </g>
      <defs>
        <clipPath id="clip0_12915_26424">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
