import * as Sentry from '@sentry/react';
import { consoleError } from './consoleLog.ts';
import type { CaptureContext } from '@sentry/types';

export class ErrorWithContext extends Error {
  public context: Record<string, unknown>;
  public name: string;

  constructor(
    message: string,
    context: Record<string, unknown>,
    name: string,
    options?: ErrorOptions,
  ) {
    super(message, options);
    this.context = context;
    this.name = name;
  }
}

export const logError = (error: Error | ErrorWithContext | string) => {
  if (typeof error === 'string') {
    error = new Error(error);
  }

  consoleError(error);
  if (error instanceof ErrorWithContext) {
    Sentry.captureException(error, {
      fingerprint: ['{{ default }}', error.name],
      contexts: {
        'Custom Details': error.context,
      },
    });
  } else {
    Sentry.captureException(error);
  }
};

type ExtraInfo = Record<string, unknown> & CaptureContext;

export const logInfo = (message: string, extra?: ExtraInfo) => {
  Sentry.captureMessage(message, {
    ...extra,
  });
};

export const startTransaction = (name: string) => {
  return Sentry.startTransaction({ name });
};

export const setUiVersion = (uiVersion: string) => {
  Sentry.setContext('uiVersion', { uiVersion });
};

export const setUserId = (userId: null | string) => {
  Sentry.setUser({ id: userId ? userId : undefined, ip_address: '{{auto}}' });
};

export const setCompanyId = (companyId: string | null) => {
  Sentry.setContext('companyId', { companyId });
};
