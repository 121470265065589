import styles from './EditAppearanceButton.module.scss';
import { Icon } from 'icons';
import cx from 'classnames';

type EditAppearanceButtonProps = {
  onClick: () => void;
  className?: string;
};

export default function EditAppearanceButton({
  onClick,
  className,
}: EditAppearanceButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(styles.editAppearanceButton, className)}
    >
      <Icon name="paintBrush" /> <span>Edit Appearance</span>
    </button>
  );
}
