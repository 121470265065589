import { isBlockSelection } from '../../../../editor/selection/BlockSelection.js';
import {
  isTextSelection,
  textSelection,
} from '../../../../editor/selection/TextSelection.js';
import getEndOfBlock from '../../../../editor/blocks/textBlocksStrategies/getEndOfBlock.js';
import { BodyActionResult, BodyStateSelected } from './BodyEditor.js';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.js';

export function navLeft(initialState: BodyStateSelected): BodyActionResult {
  const { content, selection } = initialState;

  if (isBlockSelection(selection)) {
    // nav left in block selection currently does nothing
    return { content, selection };
  }

  if (selection.index === 0) {
    return;
  }
  const newIndex = selection.index - 1;
  const block = content[newIndex];
  if (!block) {
    return;
  }

  return {
    content,
    selection: textSelection(newIndex, getEndOfBlock(block)),
  };
}

export function navRight(initialState: BodyStateSelected): BodyActionResult {
  const { content, selection } = initialState;

  if (!isTextSelection(selection)) return { content, selection };

  const i = selection.index + 1;
  if (i >= content.length) return;
  if (!content[i]) {
    return { content, selection };
  }

  return {
    content,
    selection: textSelection(i, contentSelection(0)),
  };
}
