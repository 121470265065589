import styles from './LoaderSpinner.module.scss';
import Spinner from '../design-system/atoms/Spinner.tsx';
import cx from 'classnames';

type LoaderSpinnerProps = React.ComponentProps<'div'> & {
  out?: boolean;
};
const LoaderSpinnerOverlay = ({
  className,
  out,
  ...props
}: LoaderSpinnerProps) => (
  <div
    data-testid="loader-spinner-overlay"
    {...props}
    className={cx(
      className,
      styles.loaderSpinner,
      out && styles.loaderSpinner_out,
    )}
  >
    <div className={styles.loaderSpinner__spinner}>
      <Spinner />
    </div>
  </div>
);

export default LoaderSpinnerOverlay;
