import { Block, isTextBlock } from 'editor-content/Block.js';
import ContentSelection, {
  contentSelection,
} from '../../selection/contentSelection/ContentSelection.js';
import getTextBlockLength from './getTextBlockLength.js';

export default function getEndOfBlock(block: Block): ContentSelection {
  if (isTextBlock(block)) {
    const offset = getTextBlockLength(block);
    return contentSelection(offset);
  }

  return contentSelection(0);
}
