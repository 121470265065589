import { makeMakeApiRequest } from './makeMakeApiRequest.js';

const lambdaApiUrl =
  globalThis.process?.env?.ZECK_LAMBDA_URL ??
  import.meta.env.UI_LOCAL_LAMBDA_URL ??
  `https://api-${document.location.host}`;

const makeApiRequest = makeMakeApiRequest(lambdaApiUrl);

export default makeApiRequest;
