import { useCallback } from 'react';
import InputAutoSaveCheckbox from '../../../../design-system/atoms/InputAutoSaveCheckbox.tsx';
import ZeckWithActions from '../zeckCover/ZeckWithActions.ts';
import AccessRequestList from './AccessRequestList.tsx';
import styles from './AccessRequests.module.scss';

type AccessRequestsProps = {
  zeck: Pick<ZeckWithActions, 'id' | 'settings' | 'actions'>;
  showToast: (message: string, duration: number) => void;
  onClose: () => void;
};

const AccessRequests = ({ zeck, showToast }: AccessRequestsProps) => {
  const updateEnableRequestAccess = useCallback(
    async (checked: boolean) => {
      await zeck.actions.update({
        settings: {
          ...zeck.settings,
          allowRequestAccess: checked,
        },
      });
    },
    [zeck],
  );

  return (
    <div className={styles.accessRequestsContainer}>
      <div className={styles.toggleWrapper}>
        <InputAutoSaveCheckbox
          label={
            <div>
              <div className={styles.toggleHeader}>
                Allow People to Request Viewer Access
              </div>
              <div className={styles.toggleDescription}>
                People with a link to your Zeck will be able to request Viewer
                access. Admins will be emailed the request.
              </div>
            </div>
          }
          value={zeck.settings.allowRequestAccess}
          onChange={async (toggled) => {
            await updateEnableRequestAccess(toggled);
          }}
        />
      </div>

      <AccessRequestList
        showToast={showToast}
        zeck={zeck}
        className={styles.accessRequestsExpand}
      />
    </div>
  );
};

export default AccessRequests;
