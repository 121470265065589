import React, { MouseEventHandler } from 'react';
import styles from './AddBlockButton.module.scss';
import { ReactComponent as AddBlockSVG } from '../../images/AddBlock.svg';
import cx from 'classnames';

type AddBlockButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
};

const AddBlockButton = React.forwardRef<
  HTMLButtonElement,
  AddBlockButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(function AddBlockButton(props, forwardedRef) {
  return (
    <button
      {...props}
      aria-label="Add block"
      className={cx(props.className, styles.addBlockButton)}
      ref={forwardedRef}
    >
      <AddBlockSVG />
    </button>
  );
});

export default AddBlockButton;
