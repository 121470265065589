import { createParagraphBlock } from 'editor-content/Block.js';
import { Text } from 'editor-content/TextNode.js';
import { Section } from '../types.js';

export const createDefaultSectionHeadline = (): Section['headline'] => [];

export const createDefaultSectionBody = (): Section['body'] => [
  createParagraphBlock([]),
];

export const createDefaultSectionTitle = () => 'Untitled';

export const createDefaultZeckHeadline = (): Section['headline'] => [];

export const createDefaultZeckBody = (): Section['body'] => [
  createParagraphBlock([
    Text(
      'This is the body. Here is where you begin to tell the story of your update. You can use common formatting here like ',
    ),
    Text('bold', { bold: true }),
    Text(', '),
    Text('italic', { italic: true }),
    Text(', and '),
    Text('underlined', { underline: true }),
    Text(' text.'),
  ]),
];

export const createDefaultZeckSectionTitle = () => 'CEO Summary';
