import React from 'react';
import styles from './OutstandingVote.module.scss';
import { OutstandingVoteBlock } from 'editor-content/VoteBlock.js';
import {
  FinalizeVoteCapability,
  PrevoteCapability,
} from '../../pages/zeck/voting/VoteCapability.js';
import VoteLayout from './vote/VoteLayout.js';
import PrevoteControls from './outstandingVote/PrevoteControls.js';
import PrevoteTally from './outstandingVote/PrevoteTally.js';
import FinalizeVoteControls from './outstandingVote/FinalizeVoteControls.js';

const OutstandingVotePrevoteAndFinalizeView = React.forwardRef<
  HTMLDivElement,
  {
    className?: string;
    block: OutstandingVoteBlock;
    prevoteCapability: PrevoteCapability;
    finalizeVoteCapability: FinalizeVoteCapability;
    titleContent: React.ReactNode;
    detailsContent: React.ReactNode;
    additionalContent: React.ReactNode;
    kebabContent: React.ReactNode;
  } & Omit<
    React.ComponentProps<typeof VoteLayout>,
    'className' | 'titleContent' | 'additionalContents' | 'children'
  >
>(function OutstandingVotePrevoteAndFinalizeView(
  {
    block,
    prevoteCapability,
    finalizeVoteCapability,
    className,
    titleContent,
    detailsContent,
    additionalContent,
    ...otherProps
  },
  forwardedRef,
) {
  return (
    <VoteLayout
      ref={forwardedRef}
      {...{
        ...otherProps,
        className,
        titleContent,
        additionalContents: [
          block.prevoteEnabled && (
            <PrevoteControls
              isPrimaryAction={false}
              onPrevote={prevoteCapability.takePrevote}
              onRemovePrevote={prevoteCapability.removePrevote}
              currentUserPrevote={prevoteCapability.currentUserPrevote}
            />
          ),
          additionalContent,
        ],
      }}
    >
      {detailsContent}
      <FinalizeVoteControls
        takeVote={finalizeVoteCapability.takeVote}
        block={block}
        className={styles.votePrimaryControls}
      />
      {block.prevoteEnabled && (
        <PrevoteTally
          prevoteTally={finalizeVoteCapability.prevoteTally}
          prevoteResults={finalizeVoteCapability.prevoteResults}
          className={styles.voteTally}
        />
      )}
    </VoteLayout>
  );
});

const OutstandingVoteFinalizeView = React.forwardRef<
  HTMLDivElement,
  {
    className?: string;
    block: OutstandingVoteBlock;
    finalizeVoteCapability: FinalizeVoteCapability;
    titleContent: React.ReactNode;
    detailsContent: React.ReactNode;
    additionalContent: React.ReactNode;
    kebabContent: React.ReactNode;
  } & Omit<
    React.ComponentProps<typeof VoteLayout>,
    'className' | 'titleContent' | 'additionalContents' | 'children'
  >
>(function OutstandingVotePrevoteAndFinalizeView(
  {
    block,
    finalizeVoteCapability,
    className,
    titleContent,
    detailsContent,
    additionalContent,
    ...otherProps
  },
  forwardedRef,
) {
  return (
    <VoteLayout
      {...{
        ...otherProps,
        className,
        ref: forwardedRef,
        titleContent: titleContent,
        additionalContents: [additionalContent],
      }}
    >
      {detailsContent}
      <FinalizeVoteControls
        takeVote={finalizeVoteCapability.takeVote}
        block={block}
        className={styles.votePrimaryControls}
      />
      {block.prevoteEnabled && (
        <PrevoteTally
          prevoteTally={finalizeVoteCapability.prevoteTally}
          prevoteResults={finalizeVoteCapability.prevoteResults}
          className={styles.voteTally}
        />
      )}
    </VoteLayout>
  );
});

const OutstandingVotePrevoteView = React.forwardRef<
  HTMLDivElement,
  {
    className?: string;
    block: OutstandingVoteBlock;
    prevoteCapability: PrevoteCapability;
    titleContent: React.ReactNode;
    detailsContent: React.ReactNode;
    additionalContent: React.ReactNode;
    kebabContent: React.ReactNode;
  } & Omit<
    React.ComponentProps<typeof VoteLayout>,
    'className' | 'titleContent' | 'additionalContents' | 'children'
  >
>(function OutstandingVotePrevoteAndFinalizeView(
  {
    block,
    prevoteCapability,
    className,
    titleContent,
    detailsContent,
    additionalContent,
    ...otherProps
  },
  forwardedRef,
) {
  return (
    <VoteLayout
      {...{
        ...otherProps,
        ref: forwardedRef,
        className,
        blockTitle: 'Pre-Vote',
        titleContent,
        additionalContents: [additionalContent],
      }}
    >
      {detailsContent}

      {block.prevoteEnabled && (
        <PrevoteControls
          isPrimaryAction
          className={styles.votePrimaryControls}
          onPrevote={prevoteCapability.takePrevote}
          onRemovePrevote={prevoteCapability.removePrevote}
          currentUserPrevote={prevoteCapability.currentUserPrevote}
        />
      )}
      {block.prevoteEnabled && (
        <PrevoteTally
          prevoteTally={prevoteCapability.prevoteTally}
          className={styles.voteTally}
        />
      )}

      <p className={styles.preVoteDescription}>
        The pre-vote function is intended to be a non-binding survey of director
        sentiment to make for more efficient board meetings.
      </p>
    </VoteLayout>
  );
});

const OutstandingVoteNoPermissionsView = React.forwardRef<
  HTMLDivElement,
  {
    className?: string;
    titleContent: React.ReactNode;
    detailsContent: React.ReactNode;
    additionalContent: React.ReactNode;
    kebabContent: React.ReactNode;
  } & Omit<
    React.ComponentProps<typeof VoteLayout>,
    'className' | 'titleContent' | 'additionalContents' | 'children'
  >
>(function OutstandingVotePrevoteAndFinalizeView(
  { className, titleContent, detailsContent, additionalContent, ...otherProps },
  forwardedRef,
) {
  return (
    <VoteLayout
      {...{
        ...otherProps,
        className,
        ref: forwardedRef,
        titleContent,
        additionalContents: [additionalContent],
      }}
    >
      {detailsContent}
    </VoteLayout>
  );
});

export type OutstandingVoteProps = {
  className?: string;
  block: OutstandingVoteBlock;
  prevoteCapability: PrevoteCapability | null;
  finalizeVoteCapability: FinalizeVoteCapability | null;
  titleContent: React.ReactNode;
  detailsContent: React.ReactNode;
  additionalContent: React.ReactNode;
  kebabContent: React.ReactNode;
} & Omit<
  React.ComponentProps<typeof VoteLayout>,
  'className' | 'titleContent' | 'additionalContents' | 'children'
>;

const OutstandingVote = React.forwardRef<HTMLDivElement, OutstandingVoteProps>(
  function OutstandingVote(
    {
      prevoteCapability,
      finalizeVoteCapability,
      block,
      titleContent,
      detailsContent,
      additionalContent,
      className,
      ...otherProps
    },
    forwardedRef,
  ) {
    if (prevoteCapability && finalizeVoteCapability) {
      return (
        <OutstandingVotePrevoteAndFinalizeView
          {...otherProps}
          ref={forwardedRef}
          className={className}
          block={block}
          titleContent={titleContent}
          detailsContent={detailsContent}
          additionalContent={additionalContent}
          prevoteCapability={prevoteCapability}
          finalizeVoteCapability={finalizeVoteCapability}
        />
      );
    }

    if (finalizeVoteCapability) {
      return (
        <OutstandingVoteFinalizeView
          {...otherProps}
          ref={forwardedRef}
          className={className}
          block={block}
          titleContent={titleContent}
          detailsContent={detailsContent}
          additionalContent={additionalContent}
          finalizeVoteCapability={finalizeVoteCapability}
        />
      );
    }

    if (prevoteCapability) {
      return (
        <OutstandingVotePrevoteView
          {...otherProps}
          ref={forwardedRef}
          className={className}
          block={block}
          titleContent={titleContent}
          detailsContent={detailsContent}
          additionalContent={additionalContent}
          prevoteCapability={prevoteCapability}
        />
      );
    }

    return (
      <OutstandingVoteNoPermissionsView
        {...otherProps}
        ref={forwardedRef}
        className={className}
        titleContent={titleContent}
        detailsContent={detailsContent}
        additionalContent={additionalContent}
      />
    );
  },
);

export default OutstandingVote;
