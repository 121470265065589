import styles from './Divider.module.scss';
import cx from 'classnames';
import { DividerBlock } from 'editor-content/DividerBlock.js';

export type DividerProps = {
  className?: string;
  block: DividerBlock;
};

export default function Divider({ className, block }: DividerProps) {
  return <hr id={block.id} className={cx(styles.divider, className)}></hr>;
}
