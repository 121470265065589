import getSelectionRangeSafely from '../getSelectionRangeSafely.js';
import { getColumnMatchedContentSelectionFromTop } from './getColumnMatchedContentSelection.js';
import { ElementAndData } from '../../../../junkDrawer/useElementAndDataArray.js';

export const fancyNavDown =
  <BlockEditor>(blockEditorsWithEl: ElementAndData<BlockEditor>[]) =>
  (blockIndex: number, newBlockIndex: number) => {
    const selectedBlockEditorWithEl = blockEditorsWithEl[blockIndex];
    const selectionRange = getSelectionRangeSafely();

    if (!(selectionRange && selectedBlockEditorWithEl)) return false;

    const blockEl = selectedBlockEditorWithEl.getEl();
    if (!blockEl) return false;

    const prevBlockEditorWithEl = blockEditorsWithEl[newBlockIndex];

    if (!prevBlockEditorWithEl) return false;

    const prevBlockEl = prevBlockEditorWithEl.getEl();

    if (!prevBlockEl) return false;

    return getColumnMatchedContentSelectionFromTop(selectionRange, prevBlockEl);
  };
