import { makeMakeApiRequest } from '../makeMakeApiRequest.js';
import { PublishedZeck } from '../../types.ts';
import hydratePublishedZeck from './hydratePublishedZeck.js';
import { MeetingMinutes } from './createMeetingMinutesApi.ts';
import HydratedPublishedZeck from './types/HydratedPublishedZeck.ts';

const createPublishedZeckApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  fetchPublishedZeck: async (
    zeckId: string,
  ): Promise<HydratedPublishedZeck> => {
    return hydratePublishedZeck(
      await makeLambdaApiRequest(`/zeck/${zeckId}/published-zeck`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    );
  },

  getZeckPublishedZeckMeetingMinutes: async (
    zeckId: string,
  ): Promise<MeetingMinutes[]> => {
    const { meetingMinutes } = await makeLambdaApiRequest(
      `/zeck/${zeckId}/published-zeck/meeting-minutes`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return meetingMinutes;
  },

  fetchPublishedZeckFromSection: async (
    sectionId: string,
  ): Promise<HydratedPublishedZeck> => {
    return hydratePublishedZeck(
      await makeLambdaApiRequest(`/section/${sectionId}/published-zeck`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    );
  },

  publishZeck: async (id: string): Promise<HydratedPublishedZeck> => {
    const publishedZeck = await makeLambdaApiRequest(
      `/zeck/${id}/published-zeck`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return hydratePublishedZeck(publishedZeck);
  },

  createPublishedZeckView: async ({
    companyId,
    zeckId,
  }: {
    companyId: string;
    zeckId: string;
  }): Promise<PublishedZeck> => {
    return await makeLambdaApiRequest(
      `/company/${companyId}/zeck/${zeckId}/published-zeck-view`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  },

  getPublishedZeckPdf: async ({
    zeckId,
    timeZone,
  }: {
    zeckId: string;
    timeZone: string;
  }): Promise<string> => {
    const { url } = await makeLambdaApiRequest(
      `/zeck/${zeckId}/published-zeck-pdf?timeZone=${encodeURIComponent(
        timeZone,
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return url;
  },
});

export default createPublishedZeckApi;
