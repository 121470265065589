import { AiStarIcon, ChevronDownIcon } from 'icons';
import styles from './AiControls.module.scss';
import { useRef, useState } from 'react';
import AiModal from './AiModal/AiModal.tsx';
import { Block } from 'editor-content/Block.ts';
import Linkable from 'editor-content/html/Linkable.ts';
import { rightAlignedBelow } from '../../../../domHelpers/hoverNextTo/positionStrategy/rightAlignedBelow.ts';
import Button from '../../../../design-system/atoms/Button.tsx';
import KebabMenu from '../../../../design-system/organisms/KebabMenu.tsx';
import { EditorContent } from '../useEditorState.tsx';
import { Company } from '../../../../types.ts';

type AiControlsProps = {
  editorContent: EditorContent;
  onInsertAbove: (content: Block[]) => void;
  company: Pick<Company, 'id' | 'brandColorPrimary'>;
  userId: string;
  sectionId: string;
  linkables: Linkable[];
};

const AiControls: React.FC<AiControlsProps> = ({
  onInsertAbove,
  sectionId,
  editorContent,
  company,
  userId,
  linkables,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      {isModalOpen && (
        <AiModal
          editorContent={editorContent}
          onInsertAbove={(content) => {
            onInsertAbove(content);
            setIsModalOpen(false);
          }}
          onClose={() => {
            setIsModalOpen(false);
          }}
          sectionId={sectionId}
          linkables={linkables}
          userId={userId}
          company={company}
        />
      )}

      <Button
        size="medium"
        color="transparent"
        onClick={() => setIsMenuOpen((val) => !val)}
        className={styles.aiControlButton}
        ref={buttonRef}
        data-testid="editor-ai-controls"
      >
        <AiStarIcon className={styles.aiIcon} />
        Zeck AI <ChevronDownIcon className={styles.chevronIcon} />
      </Button>

      <KebabMenu
        onClose={() => setIsMenuOpen(false)}
        items={[
          {
            label: 'Optimize Content',
            hide: false,
            iconName: 'aiPencil',
            onClick: () => {
              setIsMenuOpen(false);
              setIsModalOpen(true);
            },
          },
        ]}
        isOpen={isMenuOpen}
        elementRef={buttonRef}
        positionStrategy={rightAlignedBelow(8)}
        className={styles.aiControlMenu}
      />
    </>
  );
};

export default AiControls;
