import { ZeckLite } from '../../../types/ZeckLite.ts';
import ZeckCard from './ZeckCard.tsx';
import { ReactComponent as MoveToFolderSVG } from '../../../images/moveToFolder.svg';
import styles from './ZeckCardDragOverlay.module.scss';
import formatTimeAgo from '../../../services/formatTimeAgo.ts';

type ZeckCardDragOverlayProps = {
  zeck: ZeckLite;
};
const ZeckCardDragOverlay: React.FC<ZeckCardDragOverlayProps> = ({ zeck }) => (
  <ZeckCard
    name={zeck.name}
    coverImage={
      <div className={styles.zeckCardDragOverlay__cover}>
        <MoveToFolderSVG className={styles.zeckCardDragOverlay__coverImage} />
        <p className={styles.zeckCardDragOverlay__coverImageCaption}>
          Drag to Folder
        </p>
      </div>
    }
    bottomSlot={
      zeck.publishedAt
        ? `Published ${formatTimeAgo(zeck.publishedAt)}`
        : undefined
    }
    to={'#'}
    onClick={() => {}}
  />
);

export default ZeckCardDragOverlay;
