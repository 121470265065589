import React from 'react';
import { Icon } from 'icons';
import useBreakpoints from '../../../services/useBreakpoints.ts';
import styles from './VoteLayout.module.scss';
import cx from 'classnames';

type VoteLayoutProps = {
  className?: string;
  blockTitle?: string;
  titleContent: React.ReactNode;
  children: React.ReactNode;
  additionalContents?: React.ReactNode[];
  kebabContent?: React.ReactNode;
} & React.ComponentProps<'div'>;

const VoteLayout = React.forwardRef<HTMLDivElement, VoteLayoutProps>(
  function Vote(
    {
      className,
      blockTitle = 'Vote',
      titleContent,
      kebabContent,
      additionalContents = [],
      children,
      ...otherProps
    },
    forwardedRef,
  ) {
    const { isMobile } = useBreakpoints();

    return (
      <div
        {...otherProps}
        ref={forwardedRef}
        className={cx(styles.vote, isMobile && styles.vote_mobile, className)}
        data-testid="vote-block"
      >
        <div className={styles.vote__main}>
          <div className={styles.vote__label}>
            <Icon name="vote" /> <span>{blockTitle}</span>
            {kebabContent && (
              <div className={styles.vote__kebab}>{kebabContent}</div>
            )}
          </div>
          <div className={styles.vote__title}>{titleContent}</div>
          {children && <div className={styles.vote__body}>{children}</div>}
        </div>

        {additionalContents.map(
          (additionalContent, index) =>
            additionalContent && (
              <div key={index} className={styles.vote__additionalContent}>
                {additionalContent}
              </div>
            ),
        )}
      </div>
    );
  },
);

export default VoteLayout;
