import useFetch, { Result } from './useFetch.js';
import { useState } from 'react';

const useFetchUpdate = <T>(
  fn: () => Promise<T>,
  deps: Array<unknown>,
): { result: Result<T>; update(newData: T): void } => {
  const [updatedData, setUpdatedData] = useState<T | null>(null);
  const result = useFetch(fn, deps);

  return {
    result: updatedData ? { type: 'success', data: updatedData } : result,
    update(data) {
      setUpdatedData(data);
    },
  };
};

export default useFetchUpdate;
