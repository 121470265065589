import ContentSelection from '../../selection/contentSelection/ContentSelection.js';
import { ContentPatch } from '../../ContentPatch.js';
import splitTextNodesFromContentSelection from '../textNode/splitTextNodesFromContentSelection.js';
import arrayIs from '../../../junkDrawer/arrayIs.js';
import { textSelection } from '../../selection/TextSelection.js';
import assertUnreachable from '../../../junkDrawer/assertUnreachable.js';
import BaseTextBlock from 'editor-content/BaseTextBlock.js';
import {
  createHighlight,
  isHighlight,
  NonHighlightTextNode,
} from 'editor-content/TextNode.js';
import { updateTextBlock } from 'editor-content/Block.js';

export function textBlockIsHighlightedStrategy<BlockType extends BaseTextBlock>(
  block: BlockType,
  selection: ContentSelection,
): boolean {
  const [, inSelectionNodes] = splitTextNodesFromContentSelection(
    block.content,
    selection,
  );

  return inSelectionNodes.some(isHighlight);
}

export default function textBlockToggleHighlightStrategy<
  BlockType extends BaseTextBlock,
>(block: BlockType, selection: ContentSelection): ContentPatch<[BlockType]> {
  const [beforeSelectionNodes, inSelectionNodes, afterSelectionNodes] =
    splitTextNodesFromContentSelection(block.content, selection);

  const newNodes = arrayIs(
    inSelectionNodes,
    (a): a is NonHighlightTextNode => !isHighlight(a),
  )
    ? [createHighlight(inSelectionNodes)]
    : inSelectionNodes.flatMap<NonHighlightTextNode>((textNode) => {
        switch (textNode.type) {
          case 'highlight':
            return textNode.content;
          case 'text':
          case 'external-link':
          case 'section-link':
          case 'meeting-minutes-link':
            return textNode;
        }

        return assertUnreachable(textNode);
      });

  const newContent = [
    ...beforeSelectionNodes,
    ...newNodes,
    ...afterSelectionNodes,
  ];

  return {
    contentSubset: [updateTextBlock(block, newContent)],
    selection: textSelection(0, selection),
  };
}
