import React from 'react';
import styles from './Pill.module.scss';
import cx from 'classnames';

const colorMap = Object.freeze({
  danger: styles.pill_danger,
  yellow: styles.pill_yellow,
  green: styles.pill_green,
  gray: styles.pill_gray,
  primary: styles.pill_primary,
});

const sizeMap = Object.freeze({
  small: styles.pill_small,
  medium: styles.pill_medium,
  bubble: styles.pill_bubble,
});

type PillProps = {
  color: keyof typeof colorMap;
  label: React.ReactNode;
  size?: keyof typeof sizeMap;
  ['data-testid']?: string;
  className?: string;
  uppercase?: boolean;
};

const Pill = React.forwardRef<HTMLElement, PillProps>(function Pill(
  {
    color,
    label,
    size,
    'data-testid': testId,
    className,
    uppercase,
    ...otherProps
  },
  ref,
) {
  return (
    <span
      {...otherProps}
      ref={ref}
      data-testid={testId ?? 'pill'}
      className={cx(
        styles.pill,
        uppercase && styles.pill_uppercase,
        colorMap[color],
        sizeMap[size ?? 'medium'],
        className,
      )}
    >
      {label}
    </span>
  );
});

export default Pill;
