import {
  TextNode,
  Editor,
  ExternalLink,
  Highlight,
  isExternalLink,
  isHighlight,
  isSectionLink,
  isText,
  NonHighlightTextNode,
  SectionLink,
  Text,
  TextFormat,
} from './TextNode.js';
import difference from 'lodash/difference.js';

const textIsMergeable = (node1: Editor.Text, node2: Editor.Text): boolean =>
  !node1.format?.bold === !node2.format?.bold &&
  !node1.format?.italic === !node2.format?.italic &&
  !node1.format?.underline === !node2.format?.underline;

const normalizeFormat = (format: TextFormat | undefined): TextFormat => {
  const normalizedFormat: TextFormat = {};

  if (!format) {
    return normalizedFormat;
  }

  if (format.bold) {
    normalizedFormat['bold'] = true;
  }

  if (format.italic) {
    normalizedFormat['italic'] = true;
  }

  if (format.underline) {
    normalizedFormat['underline'] = true;
  }

  return normalizedFormat;
};

const highlightIsMergeable = (
  node1: Editor.Highlight,
  node2: Editor.Highlight,
): boolean =>
  difference(node1.ids, node2.ids).length === 0 &&
  difference(node2.ids, node1.ids).length === 0;

const externalLinkIsMergeable = (
  node1: Editor.ExternalLink,
  node2: Editor.ExternalLink,
): boolean => node1.url === node2.url;

const sectionLinkIsMergeable = (
  node1: Editor.SectionLink,
  node2: Editor.SectionLink,
): boolean => node1.sectionId === node2.sectionId;

export default function normalizeTextNodes(
  textNodes: Editor.Text[],
): Editor.Text[];
export default function normalizeTextNodes(
  textNodes: NonHighlightTextNode[],
): NonHighlightTextNode[];
export default function normalizeTextNodes(textNodes: TextNode[]): TextNode[];
export default function normalizeTextNodes(textNodes: TextNode[]): TextNode[] {
  return textNodes
    .reduce<TextNode[]>((acc, node) => {
      if (arrayIsNotEmpty(acc)) {
        const [lastNode, ...rest] = acc;

        if (
          isText(lastNode) &&
          isText(node) &&
          textIsMergeable(lastNode, node)
        ) {
          return [
            Text(lastNode.text + node.text, normalizeFormat(lastNode.format)),
            ...rest,
          ];
        } else if (
          isExternalLink(lastNode) &&
          isExternalLink(node) &&
          externalLinkIsMergeable(lastNode, node)
        ) {
          return [
            ExternalLink(
              lastNode.url,
              normalizeTextNodes([...lastNode.content, ...node.content]),
            ),
            ...rest,
          ];
        } else if (
          isSectionLink(lastNode) &&
          isSectionLink(node) &&
          sectionLinkIsMergeable(lastNode, node)
        ) {
          return [
            SectionLink(
              lastNode.sectionId,
              normalizeTextNodes([...lastNode.content, ...node.content]),
            ),
            ...rest,
          ];
        } else if (
          isHighlight(lastNode) &&
          isHighlight(node) &&
          highlightIsMergeable(lastNode, node)
        ) {
          return [
            Highlight(
              lastNode.ids,
              normalizeTextNodes([...lastNode.content, ...node.content]),
            ),
            ...rest,
          ];
        }
      }

      return [node, ...acc];
    }, [])
    .reverse();
}

const arrayIsNotEmpty = <T>(array: T[]): array is [T, ...T[]] => {
  return array.length > 0;
};
