const clamp = (min: number, max: number, t: number): number =>
  Math.max(Math.min(t, max), min);

export const lerp = (start: number, end: number, t: number): number =>
  start + clamp(0, 1, t) * (end - start);

export const cubic = (start: number, end: number, t: number): number =>
  lerp(start, end, t * t * t);

export const toUnit = (start: number, end: number, value: number): number =>
  clamp(0, 1, (value - start) / (end - start));
