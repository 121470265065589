import Button from '../../../design-system/atoms/Button.tsx';
import OutlineButton from '../../../design-system/atoms/OutlineButton.tsx';
import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../../design-system/organisms/Modal.tsx';
import styles from './ConfirmEditVoteModal.module.scss';

type ConfirmEditVoteModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSave: () => void;
};

export default function ConfirmEditVoteModal({
  isOpen,
  onCancel,
  onSave,
}: ConfirmEditVoteModalProps) {
  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader onClickClose={onCancel}>
        Save edits to a vote that was already taken?
      </ModalHeader>
      <ModalBody>
        You are about to save edits to a Vote that was already taken. If this
        vote was included in meeting minutes, any updates made will not be
        reflected in the minutes. We recommend you consult your legal advisor to
        confirm that a board ratification is not necessary or advisable before
        you save edits.
      </ModalBody>
      <ModalActions>
        <OutlineButton onClick={onCancel} color="secondary" size="medium">
          Don't Save Edits
        </OutlineButton>
        <Button onClick={onSave} color="danger" size="medium">
          Yes, Save Edits
        </Button>
      </ModalActions>
    </Modal>
  );
}
