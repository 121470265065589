import { TextBlock } from 'editor-content/Block.js';
import { TurnIntoable } from '../../../../design-system/molecules/TurnIntoMenu.js';

const createZeckEditorTurnIntoables = (
  onTurnInto: (type: TextBlock['type']) => void,
): TurnIntoable[] => [
  {
    displayName: 'Text',
    iconName: 'text',
    onTurnInto: () => onTurnInto('paragraph'),
  },
  {
    displayName: 'Heading',
    iconName: 'h1',
    onTurnInto: () => onTurnInto('heading'),
  },
  {
    displayName: 'Label',
    iconName: 'label',
    onTurnInto: () => onTurnInto('label'),
  },
  {
    displayName: 'Bulleted List',
    iconName: 'bulletedList',
    onTurnInto: () => onTurnInto('bulleted-list-item'),
  },
  {
    displayName: 'Numbered List',
    iconName: 'numberedList',
    onTurnInto: () => onTurnInto('numbered-list-item'),
  },
];

export default createZeckEditorTurnIntoables;
