import React from 'react';
import styles from './AddBlockMenu.module.scss';

type AddBlockMenuProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

const AddBlockMenu = React.forwardRef<HTMLDivElement, AddBlockMenuProps>(
  function AddBlockMenu({ children, ...props }, ref) {
    return (
      <div
        data-testid="add-block-menu"
        {...props}
        ref={ref}
        className={styles.addBlockMenu}
      >
        {children}
      </div>
    );
  },
);

export default AddBlockMenu;
