import ContentSelection from '../selection/contentSelection/ContentSelection.js';
import BaseAndExtent from '../selection/contentSelection/BaseAndExtent.js';
import { useEffect } from 'react';
import { setContentSelectionOnContainer } from './setContentSelectionOnContainer.js';

function useUpdateBrowserOnSelectionChange<ElementType extends HTMLElement>(
  ref: React.RefObject<ElementType | null>,
  selection: ContentSelection | null,
  toBaseAndExtent: (
    selection: ContentSelection,
    el: ElementType,
  ) => BaseAndExtent,
) {
  useEffect(() => {
    const el = ref.current;

    if (!el) return;

    setContentSelectionOnContainer(selection, el, toBaseAndExtent);
    // this should only re-execute when selection changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);
}

export default useUpdateBrowserOnSelectionChange;
