import Modal, {
  ModalBody,
  ModalHeader,
} from '../../../design-system/organisms/Modal.tsx';
import styles from './ConfirmDuplicatePermissions.module.scss';
import Button from '../../../design-system/atoms/Button.tsx';
import OutlineButton from '../../../design-system/atoms/OutlineButton.tsx';

export type ConfirmDuplicatePermissionsProps = {
  name: string;
  isOpen: boolean;
  onClose(): void;
  onDuplicate(): void;
  onNotDuplicate(): void;
};

export default function ConfirmDuplicatePermissions({
  isOpen,
  onClose,
  name,
  onDuplicate,
  onNotDuplicate,
}: ConfirmDuplicatePermissionsProps) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.modal}>
      <ModalHeader onClickClose={onClose}>
        Keep Contributor and Viewer Permissions?
      </ModalHeader>
      <ModalBody>
        Do you want to keep the same permissions for contributors and viewers
        when duplicating <i>{name}</i>?
      </ModalBody>
      <div className={styles.modal__actions}>
        <OutlineButton size="medium" onClick={onNotDuplicate} color="secondary">
          Do Not Keep
        </OutlineButton>
        <Button size="medium" color="primary" onClick={onDuplicate}>
          Keep Permissions
        </Button>
      </div>
    </Modal>
  );
}
