import React, { MouseEventHandler } from 'react';
import styles from './Toolbar.module.scss';
import cx from 'classnames';
import { Icon, IconName } from 'icons';
import BlackBox from '../atoms/BlackBox.tsx';

export const ToolbarGroup: React.VFC<React.ComponentProps<'div'>> = (props) => {
  return <div {...props} className={styles.toolbar__group} />;
};

type ToolbarDropdownButtonProps = {
  className?: string;
  active?: boolean;
  children: React.ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const ToolbarDropdownButton = React.forwardRef<
  HTMLButtonElement,
  ToolbarDropdownButtonProps
>(function ToolbarDropdownButton(
  { className, children, onClick, active },
  forwardedRef,
) {
  return (
    <button
      ref={forwardedRef}
      className={cx(
        styles.toolbarDropdownButton,
        active && styles.toolbarDropdownButton_active,
        className,
      )}
      onClick={onClick}
    >
      {children} <Icon name="chevronDown" />
    </button>
  );
});

type ToolbarButtonProps = {
  className?: string;
  active?: boolean;
  iconName: IconName;
  children: React.ReactNode;
} & React.ComponentProps<'button'>;

export const ToolbarButton = React.forwardRef<
  HTMLButtonElement,
  ToolbarButtonProps
>(function ToolbarDropdownButton(
  { className, children, active, iconName, ...otherProps },
  forwardedRef,
) {
  return (
    <button
      {...otherProps}
      ref={forwardedRef}
      className={cx(
        styles.toolbarButton,
        active && styles.toolbarButton_active,
        className,
      )}
    >
      <Icon name={iconName} className={styles.toolbarButton_icon} />
      {children}
    </button>
  );
});

type ToolbarProps = {
  className?: string;
  children: React.ReactNode;
};

const Toolbar = ({ className, children }: ToolbarProps) => {
  return (
    <BlackBox className={cx(styles.toolbar, className)}>{children}</BlackBox>
  );
};

export default Toolbar;
