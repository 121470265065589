import { useState } from 'react';
import ButtonWithIcon from '../../../design-system/atoms/ButtonWithIcon.tsx';
import ConfirmModal from '../../../design-system/organisms/ConfirmationModal.tsx';
import { DeletionRequest } from '../../../types.js';
import styles from './DeletionRequests.module.scss';

const statusMap: Record<DeletionRequest['status'], string> = {
  pending: 'Pending',
  cancelled: 'Cancelled',
  in_flight: 'In-flight',
  completed: 'Completed',
};

const DeletionRequestList = ({
  deleteRequests,
  cancelDeletionRequest,
}: {
  deleteRequests: DeletionRequest[];
  cancelDeletionRequest: (companyId: string, jobId: string) => Promise<void>;
}) => {
  const [cancellingRequest, setCancellingRequest] =
    useState<DeletionRequest | null>(null);

  if (!deleteRequests.length) return null;

  return (
    <>
      <table className={styles.deletionRequestTable}>
        <thead>
          <tr className={styles.deletionRequestHeader}>
            <th>Deletion Request</th>
            <th>Deletion Date</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {deleteRequests.map((deleteRequest) => {
            const deletionDateString = new Date(
              deleteRequest.requestedDeletionDate,
            ).toLocaleDateString();
            return (
              <tr key={deleteRequest.jobId}>
                <td>
                  <div className={styles.deletionRequestCompanyName}>
                    {deleteRequest.companyName}
                  </div>

                  <div className={styles.deletionRequestId}>
                    {deleteRequest.companyId}
                  </div>
                </td>
                <td>
                  <div
                    className={styles.deletionRequestCompanyName}
                    title={`Requested By ${deleteRequest.requesterEmail}`}
                  >
                    {deletionDateString}
                  </div>
                  <div className={styles.deletionRequestId}>
                    Job ID: {deleteRequest.jobId}
                  </div>
                </td>
                <td>
                  <div className={styles.deletionRequestStatus}>
                    {/* we will want to check the status and then either display a cancel button with icon (maybe?) OR the actual status text */}
                    {statusMap[deleteRequest.status] === statusMap.pending ? (
                      <ButtonWithIcon
                        size={'small'}
                        color={'danger'}
                        iconName={'delete'}
                        position={'left'}
                        onClick={() => setCancellingRequest(deleteRequest)}
                      >
                        Cancel
                      </ButtonWithIcon>
                    ) : (
                      statusMap[deleteRequest.status]
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {cancellingRequest && (
        <ConfirmModal
          isOpen={!!cancellingRequest}
          onClose={() => setCancellingRequest(null)}
          onClickConfirm={() => {
            cancelDeletionRequest(
              cancellingRequest.companyId,
              cancellingRequest.jobId,
            );
            setCancellingRequest(null);
          }}
          body={
            <div>
              This Company’s data will NOT be deleted and we may violate our
              data retention policy as a result.
            </div>
          }
          confirmText="Yes, Cancel"
          title="Cancel This Deletion Request?"
        />
      )}
    </>
  );
};

export default DeletionRequestList;
