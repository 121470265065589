import styles from './edit/MeetingMinutesEditorPage.module.scss';
import Spinner from '../../design-system/atoms/Spinner.tsx';

const MeetingMinutesLoading: React.FC = () => (
  <div className={styles.spinnerWrap}>
    <Spinner className={styles.spinner} />
    <p>One moment...</p>
  </div>
);

export default MeetingMinutesLoading;
