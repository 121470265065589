export type Tagged<TypeName extends string, Data> = {
  type: TypeName;
  data: Data;
};

export const Tagged = <TypeName extends string, Data>(
  type: TypeName,
  data: Data,
): Tagged<TypeName, Data> => ({
  type,
  data,
});
