import { Vote } from 'editor-content/VoteBlock.js';
import { Prevote, PrevoteChoice } from '../../../types/Prevote.ts';

export type ZeckPrevoteCapability = {
  takePrevote:
    | ((blockId: string, choice: PrevoteChoice) => Promise<void>)
    | null;
  removePrevote: ((id: string) => Promise<void>) | null;
  prevoteTallies: Array<BlockPrevoteTally>;
  boardDirectorCount: number;
  currentUserPrevotes: Pick<Prevote, 'id' | 'blockId' | 'choice'>[];
};

export type BlockPrevoteTally = {
  blockId: string;
  votedBoardDirectorCount: number;
};

export type BlockPrevoteResult = {
  blockId: string;
  prevoteResult: PrevoteResult;
};

export type ZeckFinalizeVoteCapability = {
  takeVote:
    | ((
        vote: Pick<
          Vote,
          'title' | 'details' | 'blockId' | 'approved' | 'voteType'
        >,
      ) => Promise<void>)
    | null;
  editVote:
    | ((
        vote: Pick<
          Vote,
          'title' | 'details' | 'blockId' | 'approved' | 'voteType'
        >,
      ) => Promise<void>)
    | null;
  prevoteTallies: Array<BlockPrevoteTally>;
  prevoteResults: Array<BlockPrevoteResult>;
  boardDirectorCount: number;
};

export type PrevoteTally = {
  boardDirectorCount: number;
  votedBoardDirectorCount: number;
};

export type PrevoteResult = {
  approvedCount: number;
  notApprovedCount: number;
  abstainedCount: number;
};

export type PrevoteCapability = {
  prevoteTally: PrevoteTally;
  takePrevote: ((choice: PrevoteChoice) => Promise<void>) | null;
  removePrevote: ((id: string) => Promise<void>) | null;
  currentUserPrevote: Pick<Prevote, 'id' | 'blockId' | 'choice'> | null;
};

export type FinalizeVoteCapability = {
  prevoteTally: PrevoteTally;
  prevoteResults: PrevoteResult;
  takeVote:
    | ((
        vote: Pick<
          Vote,
          'title' | 'details' | 'blockId' | 'approved' | 'voteType'
        >,
      ) => Promise<void>)
    | null;
};

type GenerateZeckVoteCapabilities = {
  userIsAdmin: boolean;
  userCanManageMinutes: boolean;
  userIsBoardDirector: boolean;

  takeVote:
    | ((
        vote: Pick<
          Vote,
          'title' | 'details' | 'blockId' | 'approved' | 'voteType'
        >,
      ) => Promise<void>)
    | null;
  takePrevote:
    | ((blockId: string, choice: PrevoteChoice) => Promise<void>)
    | null;
  removePrevote: ((id: string) => Promise<void>) | null;
  prevoteData: {
    tallies: BlockPrevoteTally[];
    results: BlockPrevoteResult[];
    currentUserPrevotes: Pick<Prevote, 'id' | 'blockId' | 'choice'>[];
    boardDirectorCount: number;
  };
};

export const generateZeckVoteCapabilities = ({
  userIsAdmin,
  userCanManageMinutes,
  userIsBoardDirector,
  prevoteData: {
    tallies: prevoteTallies,
    results: prevoteResults,
    boardDirectorCount,
    currentUserPrevotes,
  },
  takeVote,
  takePrevote,
  removePrevote,
}: GenerateZeckVoteCapabilities): {
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
} => {
  const zeckPrevoteCapability: ZeckPrevoteCapability | null =
    userIsBoardDirector
      ? {
          prevoteTallies,
          takePrevote,
          removePrevote,
          boardDirectorCount,
          currentUserPrevotes,
        }
      : null;

  if (userIsAdmin) {
    return {
      zeckFinalizeVoteCapability: {
        prevoteResults,
        prevoteTallies,
        takeVote,
        editVote: takeVote,
        boardDirectorCount,
      },
      zeckPrevoteCapability,
    };
  }

  if (userCanManageMinutes) {
    return {
      zeckFinalizeVoteCapability: {
        prevoteResults,
        prevoteTallies,
        takeVote: null,
        editVote: takeVote,
        boardDirectorCount,
      },
      zeckPrevoteCapability,
    };
  }

  return {
    zeckFinalizeVoteCapability: null,
    zeckPrevoteCapability,
  };
};

const ifNotNull = <A, B>(value: A | null, fn: (value: A) => B): B | null =>
  value && fn(value);

export type GenerateVoteCapabilitiesForBlock = {
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  block: { id: string; type: 'vote' };
};

export const generateVoteCapabilitiesForBlock = ({
  zeckPrevoteCapability,
  zeckFinalizeVoteCapability,
  block,
}: GenerateVoteCapabilitiesForBlock): {
  prevoteCapability: PrevoteCapability | null;
  finalizeVoteCapability: FinalizeVoteCapability | null;
} => {
  let prevoteCapability = null;
  let finalizeVoteCapability = null;

  if (zeckFinalizeVoteCapability) {
    const prevoteResults = zeckFinalizeVoteCapability.prevoteResults.find(
      (result) => result.blockId === block.id,
    )?.prevoteResult ?? {
      approvedCount: 0,
      notApprovedCount: 0,
      abstainedCount: 0,
    };

    finalizeVoteCapability = {
      takeVote: zeckFinalizeVoteCapability.takeVote,
      prevoteResults: prevoteResults,
      prevoteTally: {
        boardDirectorCount: zeckFinalizeVoteCapability.boardDirectorCount,
        votedBoardDirectorCount:
          zeckFinalizeVoteCapability.prevoteTallies.find(
            (result) => result.blockId === block.id,
          )?.votedBoardDirectorCount || 0,
      },
    };
  }

  if (zeckPrevoteCapability) {
    prevoteCapability = {
      takePrevote: ifNotNull(zeckPrevoteCapability.takePrevote, (fn) =>
        fn.bind(null, block.id),
      ),
      removePrevote: zeckPrevoteCapability.removePrevote,
      prevoteTally: {
        boardDirectorCount: zeckPrevoteCapability.boardDirectorCount,
        votedBoardDirectorCount:
          zeckPrevoteCapability.prevoteTallies.find(
            (result) => result.blockId === block.id,
          )?.votedBoardDirectorCount || 0,
      },
      currentUserPrevote:
        zeckPrevoteCapability.currentUserPrevotes.find(
          (result) => result.blockId === block.id,
        ) || null,
    };
  }

  return {
    prevoteCapability,
    finalizeVoteCapability,
  };
};
