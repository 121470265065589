import { initClient } from '@ts-rest/core';
import { assetContract } from 'api-client/assetContract.js';
import { version } from '../../version.js';

const lambdaApiUrl =
  globalThis.process?.env?.ZECK_LAMBDA_URL ??
  import.meta.env.UI_LOCAL_LAMBDA_URL ??
  `https://api-${document.location.host}`;

const AssetApiClient = initClient(assetContract, {
  baseUrl: lambdaApiUrl,
  baseHeaders: {
    Version: version,
  },
});

export default AssetApiClient;
