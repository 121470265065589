import {
  Editor,
  NonHighlightTextNode,
  TextNode,
} from 'editor-content/TextNode.ts';

export function removeHighlights(nodes: TextNode[]): NonHighlightTextNode[] {
  return nodes.reduce<NonHighlightTextNode[]>((acc, node) => {
    switch (node.type) {
      case 'highlight':
        // For highlight nodes, process their content recursively and add to accumulator
        return [...acc, ...removeHighlights(node.content)];

      case 'external-link':
      case 'section-link':
      case 'meeting-minutes-link':
        // For link nodes, process their text content recursively
        return [
          ...acc,
          {
            ...node,
            content: removeHighlights(node.content) as Editor.Text[],
          },
        ];

      case 'text':
        // Text nodes can be added directly
        return [...acc, node];

      default:
        throw new Error(`Unhandled node type: ${node}`);
    }
  }, []);
}
